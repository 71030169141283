import React, {useContext, useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {Scrollbars} from "react-custom-scrollbars";
import {Button, Col, Popconfirm, Row, Table, Tooltip} from "antd";
import * as GlobalConfig from "../../../../constants/GlobalConfig";
import {ListContext} from "./EffectModuleCurriculum";
import {useDispatch} from "react-redux";
import * as Actions from "../../../../redux/actions";
import {LoadingOutlined} from "@ant-design/icons";

ModuleCurriculumList.propTypes = {};

function ModuleCurriculumList(props) {
    const dispatch = useDispatch();
    const [list, setList] = useContext(ListContext);
    const [loading, setLoading] = useState(true);
    const [pagination, setPagination] = useState({
        showTotal: (total, range) => {
            return (`全${total}件中 ${range[0]}-${range[1]} 件`)
        },
        showSizeChanger: true,
        pageSizeOptions: GlobalConfig.PageSizeOptions,
        pageSize: 20,
        onShowSizeChange: onChangePageSize,
        total: 0,
    });

    useEffect(() => {
        setLoading(list.loading);
    }, [list])


    const onChangePageSize = (current, pageSize) => {
        const pager = {...pagination};
        //pageSizeの更新
        pager.pageSize = pageSize;
        setPagination({
            ...pagination
        });
    };

    const handleTableChange = (pagination, filters, sorter) => {
        setState({
            pagination
        }, () => {

        });
    };

    const getScheduleType = (record) => {
        //判断基準： reason キーがあれば休講
        //判断基準： extra_teacher キーがあれば補講
        //判断基準： new_room キーがあれば授業変更
        if (record.hasOwnProperty("reason")) {
            return {className: "cancel", label: <span>休講</span>};
        }
        if (record.hasOwnProperty("extra_teacher")) {
            return {className: "extra", label: <span>補講</span>};
        }
        if (record.hasOwnProperty("new_room")) {
            return {className: "change", label: <span>授業<br/>変更</span>};
        }
        return {className: "none", label: ""};
    }

    const generateDate = (dateStr) => {
        console.log(dateStr);
        if (dateStr.length === 14) {
            return `${dateStr.substr(0, 4)}年${dateStr.substr(4, 2)}月${dateStr.substr(6, 2)}日 ${dateStr.substr(8, 2)}時${dateStr.substr(10, 2)}分${dateStr.substr(12, 2)}秒`
        } else {
            return `${dateStr.substr(0, 4)}年${dateStr.substr(4, 2)}月${dateStr.substr(6, 2)}日`
        }
    }

    const generateDetailSchedule = (record) => {
        if (record.hasOwnProperty("reason")) {
            return (
                <ul className={"schedule_body_list"}>
                    <li>
                        <div className={"header"}>
                            <span>休講日</span>
                        </div>
                        <div className={"body"}>
                            <span>{generateDate(record.execute_date)}</span>
                        </div>
                    </li>

                    <li>
                        <div className={"header"}>
                            <span>教員名</span>
                        </div>
                        <div className={"body"}>
                            <span>{record.teacher_name}</span>
                        </div>
                    </li>

                    <li>
                        <div className={"header"}>
                            <span>教員名</span>
                        </div>
                        <div className={"body"}>
                            <span>{record.room}</span>
                        </div>
                    </li>
                    <li>
                        <div className={"header"}>
                            <span>休講理由</span>
                        </div>
                        <div className={"body"}>
                            <span>{record.reason}</span>
                        </div>
                    </li>
                    <li>
                        <div className={"header"}>
                            <span>休講理由詳細</span>
                        </div>
                        <div className={"body"}>
                            <span>{record.reason_detail}</span>
                        </div>
                    </li>
                    <li>
                        <div className={"header"}>
                            <span>登録日</span>
                        </div>
                        <div className={"body"}>
                            <span>{generateDate(record.published_start_at)}</span>
                        </div>
                    </li>
                </ul>

            );
        }
        //補講
        if (record.hasOwnProperty("extra_teacher")) {
            return (
                <ul className={"schedule_body_list"}>
                    <li>
                        <div className={"header"}>
                            <span>補講日</span>
                        </div>
                        <div className={"body"}>
                            <span>{generateDate(record.execute_date)}</span>
                        </div>
                    </li>
                    <li>
                        <div className={"header"}>
                            <span>補講を行う教員</span>
                        </div>
                        <div className={"body"}>
                            <span>{record.extra_teacher}</span>
                        </div>
                    </li>
                    <li>
                        <div className={"header"}>
                            <span>補講を行う教室</span>
                        </div>
                        <div className={"body"}>
                            <span>{record.extra_room}</span>
                        </div>
                    </li>
                    <li>
                        <div className={"header"}>
                            <span>登録日</span>
                        </div>
                        <div className={"body"}>
                            <span>{generateDate(record.published_start_at)}</span>
                        </div>
                    </li>
                </ul>
            );
        }
        //教室変更
        if (record.hasOwnProperty("new_room")) {
            return (
                <ul className={"schedule_body_list"}>
                    <li>
                        <div className={"header"}>
                            <span>授業日</span>
                        </div>
                        <div className={"body"}>
                            <span>{generateDate(record.execute_date)}</span>
                        </div>
                    </li>
                    <li>
                        <div className={"header"}>
                            <span>変更後授業日</span>
                        </div>
                        <div className={"body"}>
                            <span>{generateDate(record.new_execute_date)}</span>
                        </div>
                    </li>
                    <li>
                        <div className={"header"}>
                            <span>曜日</span>
                        </div>
                        <div className={"body"}>
                            <span>{record.day_of_week}</span>
                        </div>
                    </li>
                    <li>
                        <div className={"header"}>
                            <span>変更後の曜日</span>
                        </div>
                        <div className={"body"}>
                            <span>{record.new_day_of_week}</span>
                        </div>
                    </li>
                    <li>
                        <div className={"header"}>
                            <span>時限</span>
                        </div>
                        <div className={"body"}>
                            <span>{record.time_number}</span>
                        </div>
                    </li>
                    <li>
                        <div className={"header"}>
                            <span>変更後の時限</span>
                        </div>
                        <div className={"body"}>
                            <span>{record.new_time_number}</span>
                        </div>
                    </li>
                    <li>
                        <div className={"header"}>
                            <span>教員名</span>
                        </div>
                        <div className={"body"}>
                            <span>{record.teacher_name}</span>
                        </div>
                    </li>
                    <li>
                        <div className={"header"}>
                            <span>変更後の教員名</span>
                        </div>
                        <div className={"body"}>
                            <span>{record.teacher_name}</span>
                        </div>
                    </li>
                    <li>
                        <div className={"header"}>
                            <span>教室名</span>
                        </div>
                        <div className={"body"}>
                            <span>{record.room}</span>
                        </div>
                    </li>
                    <li>
                        <div className={"header"}>
                            <span>変更後の教室名</span>
                        </div>
                        <div className={"body"}>
                            <span>{record.new_room}</span>
                        </div>
                    </li>
                    <li>
                        <div className={"header"}>
                            <span>期間</span>
                        </div>
                        <div className={"body"}>
                            <span>{record.period}</span>
                        </div>
                    </li>
                    <li>
                        <div className={"header"}>
                            <span>登録日</span>
                        </div>
                        <div className={"body"}>
                            <span>{generateDate(record.published_start_at)}</span>
                        </div>
                    </li>
                </ul>
            );
        }
        return null;
    };

    const columns = [
        {
            dataIndex: 'class_room',
            key: 'class_room',
            render: (text, record) => {
                const date = generateDate(record.execute_date)

                //all  //cancel //extra //change


                return (
                    <div className={"schedule_wrapper"}>
                        <div className={"schedule_header_wrapper"}>
                            <div className={"schedule_type_wrapper"}>
                                <div className={getScheduleType(record).className}>
                                    {getScheduleType(record).label}
                                </div>
                            </div>
                            <div className={"schedule_label_wrapper"}>
                                <div className={"schedule_date"}><span>{date}</span><span>{record.day_of_week}曜日</span>
                                    <span>{record.time_number}限</span></div>
                                <div className={"schedule_label_title"}>{record.class_name}</div>
                            </div>
                        </div>
                        <div className={"schedule_body_wrapper"}>
                            {generateDetailSchedule(record)}
                        </div>
                    </div>
                )
            }
        }
    ];


    return (
        <Scrollbars>
            <Table
                className="general-table"
                locale={{
                    filterTitle: 'フィルタ',
                    filterConfirm: '確定',
                    filterReset: 'リセット',
                    emptyText:
                        <span>情報がありません。<br/>（教職員は休講情報登録画面から確認ください）</span>,
                }}
                showHeader={false}
                title={() => <div style={{textAlign: "center"}}><span
                    style={{color: "red"}}>登録日時に留意し、最新の情報を確認してください。</span></div>}
                columns={columns}
                ellipsis={true}
                size="middle"
                loading={{spinning: loading, indicator: <span><LoadingOutlined/></span>}}
                onChange={handleTableChange}
                pagination={false}
                onRow={(record, rowIndex) => {
                    return {
                        onClick: event => {
                            console.log(record);
                        }
                    };
                }}
                dataSource={list.curriculumList}

            />
        </Scrollbars>
    );
}

export default ModuleCurriculumList;