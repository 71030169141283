import React from 'react'
import PropTypes from 'prop-types';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import {
    Modal,
    Radio,
    Select,
    Input,
    Row,
    Col,
    Button,
    DatePicker,
    Tooltip,
    Popconfirm,
    Switch,
} from 'antd';


const FormItem = Form.Item;
const Option = Select.Option;
const { TextArea } = Input;

class AuthProfileFormModal extends React.Component {
    constructor(props) {
        super(props);

        const modalTitle = props.isEditMode? "認証プロファイルの編集":"認証プロファイルの新規作成";
        const category_title = props.data? props.data.name:"";
        const category_description = props.data? props.data.description:"";

        this.state = {
            modalTitle: modalTitle,
            visible: props.visible,
            data: props.data,
            title: category_title,
            description:category_description
        }
    }

    componentWillReceiveProps(nextProps) {

        const modalTitle = nextProps.isEditMode? "認証プロファイルの編集":"認証プロファイルの新規作成";
        const category_title = nextProps.data? nextProps.data.title:"";
        const category_url = nextProps.data? nextProps.data.url:"";

        this.state = {
            modalTitle: modalTitle,
            visible: nextProps.visible,
            data: nextProps.data,
            title: category_title,
            url: category_url,
            confirmLoading: false
        };

        if (!nextProps.visible) {
            this.resetFormAll()
        }
    }

    resetFormAll = () => {
        this.props.form.resetFields();
        this.setState({
            confirmLoading: false
        })
    };

    handleOk = (fieldValue,data) => {
        //バリデーション
        this.props.form.validateFields((err, values) => {
            if (!err) {
                this.setState({
                    confirmLoading: true,
                });
                this.props.handleOk(fieldValue,data);
            }
        });

    };

    // URI形式のバリデーションチェック
    checkUri = (rule, value, callback) => {

        let pattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
            '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // domain name
            '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
            '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
            '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
            '(\\#[-a-z\\d_]*)?$','i'); // fragment locator

        if (value && !pattern.test(value)) {
            callback('正しいURI形式を入力してください');
        } else {
            callback();
        }
    };

    handleCancel = () => {
        this.props.handleCancel();
    };

    render() {
        const {visible, confirmLoading} = this.state;

        const formItemLayout = {
            labelCol: {
                xs: {span: 24},
                sm: {span: 4},
            },
            wrapperCol: {
                xs: {span: 24},
                sm: {span: 20},
            },
        };

        const {getFieldDecorator} = this.props.form;


        let ldapForm = (
            <div>
                <h2>LDAP(s)認証プロファイルオプション</h2>
                <FormItem
                    {...formItemLayout}
                    label="プライマリDSN"
                    layout="inline"
                    help="記入例） ldaps://ldaphostname.sample-university.ac.jp:636">
                    {getFieldDecorator('primary_dsn',{rules: [{required: true,message: 'プライマリDSNを入力してください'}]})(
                        <Input placeholder="プライマリDSNを入力してください"/>
                    )}
                </FormItem>
                <FormItem
                    {...formItemLayout}
                    label="セカンダリDSN"
                    layout="inline">
                    {getFieldDecorator('secondary_dsn')(
                        <Input placeholder="セカンダリDSNを入力してください"/>
                    )}
                </FormItem>
                <FormItem
                    {...formItemLayout}
                    label="ベースDN"
                    layout="inline">
                    {getFieldDecorator('secondary_dsn')(
                        <Input placeholder="ベースDNを入力してください"/>
                    )}
                </FormItem>
                <FormItem
                    {...formItemLayout}
                    label="接続先をランダムに分散"
                >
                    {getFieldDecorator('random-dsn', { valuePropName: 'checked' })(
                        <Switch />
                    )}
                </FormItem>
                <FormItem
                    {...formItemLayout}
                    label="匿名バインド"
                >
                    {getFieldDecorator('anonymous-bind', { valuePropName: 'checked' })(
                        <Switch />
                    )}
                </FormItem>
                <FormItem
                    {...formItemLayout}
                    label="ユーザID"
                    layout="inline">
                    {getFieldDecorator('secondary_dsn')(
                        <Input placeholder="LDAP認証を実施するユーザIDを入力してください"/>
                    )}
                </FormItem>
                <FormItem
                    {...formItemLayout}
                    label="パスワード"
                    layout="inline">
                    {getFieldDecorator('secondary_dsn')(
                        <Input placeholder="LDAP認証を実施するユーザパスワードを入力してください"/>
                    )}
                </FormItem>
            </div>

        );


        let authForm = ldapForm;

        return (
            <Modal
                className="common-modal select-target-form"
                title={this.state.modalTitle}
                visible={visible}
                maskClosable={false}
                onOk={() => this.handleOk(this.props.form.getFieldsValue(),this.state.data)}
                confirmLoading={confirmLoading}
                onCancel={this.handleCancel}>

                <div className='wrapper'>
                    <Form className="search-form" onSubmit={this.onSubmit}>
                        <div>
                            <h2>認証プロファイル全般項目</h2>
                            <FormItem
                                {...formItemLayout}
                                label="認証プロファイル名称"
                                layout="inline">
                                {getFieldDecorator('title',{rules: [{required: true,message: '認証プロファイル名称を入力してください'}]})(
                                    <Input placeholder="カテゴリ名称を入力してください"/>
                                )}
                            </FormItem>
                            <FormItem
                                {...formItemLayout}
                                label="認証方式"
                                layout="inline"
                                help="プロファイル作成後の方式変更はできません">
                                {getFieldDecorator('url',{rules: [{required: true,message: '認証方式を選択してください'}]})(
                                    <Select placeholder="認証方式を選択してください">
                                        <Option key="type" value="ldap">
                                            <div className="select-title">LDAP(s)</div>
                                            <div className="select-description">LDAP及びLDAPs互換サーバとの認証連携方式</div>
                                        </Option>
                                    </Select>
                                )}
                            </FormItem>
                            <FormItem
                                {...formItemLayout}
                                label="摘要"
                                layout="inline">
                                {getFieldDecorator('memo')(
                                    <Input placeholder="摘要を入力してください"/>
                                )}
                            </FormItem>
                        </div>

                        {authForm}

                    </Form>
                </div>

            </Modal>
        );
    }
}


export default Form.create()(AuthProfileFormModal)

AuthProfileFormModal.propTypes = {
    visible: PropTypes.bool.isRequired,
    isEditMode: PropTypes.bool.isRequired,
    handleOk: PropTypes.func.isRequired,
    handleCancel: PropTypes.func.isRequired,
    data: PropTypes.object
};