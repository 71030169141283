import React from 'react'
import PropTypes from 'prop-types';
import { CopyOutlined, InboxOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Modal, Radio, Input, Button, Upload } from 'antd';

import log from 'components/utils/Logger.js'


const FormItem = Form.Item;
const RadioGroup = Radio.Group;

class NotificationCategoryFormCSVModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            visible: props.visible,
        }
    }

    componentWillReceiveProps(nextProps) {
        this.state = {
            visible: nextProps.visible,
        }
    }


    handleOk = () => {
        setTimeout(() => {
            this.setState({
                confirmLoading: false,
            });
        }, 2000);
        this.props.handleOk();
    }

    handleCancel = () => {
        this.props.handleCancel();
    }

    render() {

        const {visible, confirmLoading} = this.state;

        const formItemLayout = {
            labelCol: {
                xs: {span: 24},
                sm: {span: 4},
            },
            wrapperCol: {
                xs: {span: 24},
                sm: {span: 20},
            },
        };


        const {getFieldDecorator} = this.props.form;

        return (
            <Modal
                className="common-modal select-target-form"
                title={<span><CopyOutlined /> CSVインポート</span>}
                visible={visible}
                maskClosable={false}
                onOk={this.handleOk}
                confirmLoading={confirmLoading}
                onCancel={this.handleCancel}
                okText="実行">


                <div className='wrapper'>
                    <Form>
                        <FormItem
                            {...formItemLayout}
                            label="エンコード形式"
                        >
                            {/* FIXME value Keyを実際の値に即したものに変更 */}
                            {getFieldDecorator('radio_code', {
                                    initialValue: "a"
                                }
                            )(
                                <RadioGroup>
                                    <Radio value="a">SJIS</Radio>
                                    <Radio value="b">UTF-8</Radio>
                                    <Radio value="c">EUC-JP</Radio>
                                </RadioGroup>
                            )}
                        </FormItem>

                        <FormItem
                            {...formItemLayout}
                            label="ファイル添付"
                        >
                            <div className="dropbox">
                                {getFieldDecorator('dragger', {
                                    valuePropName: 'fileList',
                                    initialValue: this.state.fileList,
                                    getValueFromEvent: this.normFile
                                })(
                                    <Upload.Dragger
                                        name="files"
                                        action="/" >
                                        <p className="ant-upload-drag-icon">
                                            <InboxOutlined />
                                        </p>
                                        <p className="ant-upload-text">ファイルをドラッグ＆ドロップするか、下の「ファイルを選択」から選択してください</p>
                                        <p className="ant-upload-hint">
                                            最大xxMBまで　対応形式:csv
                                        </p>
                                        <Button type="primary" className="button">ファイルを選択</Button>
                                    </Upload.Dragger>
                                )}
                            </div>
                        </FormItem>
                    </Form>
                </div>

            </Modal>
        );
    }
}


export default Form.create()(NotificationCategoryFormCSVModal)

NotificationCategoryFormCSVModal.propTypes = {
    visible: PropTypes.bool.isRequired,
    handleOk: PropTypes.func.isRequired,
    handleCancel: PropTypes.func.isRequired
};