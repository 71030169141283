import React from 'react'
import {connect, store} from 'react-redux'
import {instanceOf} from 'prop-types';
import log from 'components/utils/Logger.js'

import {Card} from 'antd';


const mapStateToProps = (state, props) => {
    return {
        screen: state.screen,
    }
}

const mapDispatchToProps = dispatch => {
    return {}
}

class SelectTargetIPAddressList extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            networkData: props.networkData
        }
    }

    componentWillReceiveProps(nextProps){
        this.setState({
            networkData: nextProps.networkData
        })
    }

    render() {
        /*
        const null_data = [];
        // IPアドレス指定は/32として処理する
        const data = [{
            ip_address: '192.168.3.0',
            ip_prefix: 24
        },{
            ip_address: '192.168.3.0',
            ip_prefix: 24
        },{
            ip_address: '192.168.3.0',
            ip_prefix: 24
        },{
            ip_address: '192.168.3.0',
            ip_prefix: 24
        }, {
            ip_address: '192.168.4.1',
            ip_prefix: 32
        }];


        const columns = [{
            title: 'IPアドレス/サブネット',
            dataIndex: 'ip_address',
            key: 'ip_address',
        }, {
            title: '種別',
            dataIndex: 'ip_category',
            key: 'ip_category',
        }, {
            title: "",
            width: "85px",
            key: 'action',
            render: (text, record) => {

                return (
                    <Row type="flex" justify="space-around" align="middle">
                        <Col>
                            <Tooltip placement="topLeft" title="編集">
                                <Button type="default" shape="circle" icon="edit" />
                            </Tooltip>
                        </Col>
                        <Col>
                            <Tooltip placement="topLeft" title="削除">
                                <Button type="default" shape="circle" icon="delete" />
                            </Tooltip>
                        </Col>
                    </Row>
                )
            },
        }

        ];
        */

        return (
            <Card>
                <div>
                    {this.state.networkData}
                </div>
            </Card>
        );
    }
}


export default connect(
    mapStateToProps,
    mapDispatchToProps
)(SelectTargetIPAddressList)
