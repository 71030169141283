import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {Button, Card, Tabs} from 'antd';
import ModuleCurriculumList from "./ModuleCurriculumList";
import EffectModuleCurriculum from "./EffectModuleCurriculum";
import {useDispatch, useSelector} from "react-redux";
import * as Actions from "../../../../redux/actions";
import {Scrollbars} from "react-custom-scrollbars";

const {TabPane} = Tabs;

ModuleCurriculum.propTypes = {};

ModuleCurriculum.defaultProps = {
    title: "",
    seeMore: false
}

function ModuleCurriculum(props) {

    const dispatch = useDispatch();
    const [currentKey,setCurrentKey] = useState(0);

    const getCurriculum = (type) =>{
        switch (type) {
            case "1":
            case 1:
                dispatch(Actions.http.connection.curriculum.all());
                break;
            case "2":
            case 2:
                dispatch(Actions.http.connection.curriculum.cancel());
                break;
            case "3":
            case 3:
                dispatch(Actions.http.connection.curriculum.extra());
                break;
            case "4":
            case 4:
                dispatch(Actions.http.connection.curriculum.change());
                break;
            default:
                dispatch(Actions.http.connection.curriculum.all());
                break;
        }
    }


    useEffect(()=>{
        const defaultType = Math.min.apply([],props.parts.map((value)=>{
            return value.type
        }))
        getCurriculum(currentKey ? currentKey : String(defaultType))

    },[props.parts])

    const titleNode = (
        <div>
            <span className="module-title">{props.title}</span>
            {props.seeMore ?
                <Button className="module-title-button" onClick={this.handleClickSeeMore}>すべて表示</Button> :
                <span/>}
        </div>
    );

    function callback(key) {
        setCurrentKey(key);
        getCurriculum(key)
    };

    return (
        <Card className="module-card" title={titleNode}>
            <EffectModuleCurriculum>

                <Tabs onChange={callback} type="card" className={"module-schedule-tabs"}>
                    {props.parts.some(val => val.type === 1) &&
                        <TabPane tab="すべて" key="1">
                            <ModuleCurriculumList key={"1"}/>
                        </TabPane>
                    }
                    {props.parts.some(val => val.type === 2) &&
                        <TabPane tab="休講" key="2">
                            <ModuleCurriculumList key={"2"}/>
                        </TabPane>
                    }
                    {props.parts.some(val => val.type === 3) &&
                        <TabPane tab="補講" key="3">
                            <ModuleCurriculumList key={"3"}/>
                        </TabPane>
                    }

                    {props.parts.some(val => val.type === 4) &&
                        <TabPane tab="授業変更" key="4">
                            <ModuleCurriculumList key={"4"}/>
                        </TabPane>
                    }
                </Tabs>
            </EffectModuleCurriculum>
        </Card>
    );
}

export default ModuleCurriculum;