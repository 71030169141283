import React from 'react';
import {connect, store} from 'react-redux';
import {instanceOf} from 'prop-types';
import * as Actions from '../../redux/actions';
import * as GlobalConfig from '../../constants/GlobalConfig';

import moment from 'moment';
import 'moment/locale/ja';
import { DeleteOutlined, EditOutlined, PlusCircleOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import {
    Table,
    Tag,
    Radio,
    Select,
    Row,
    Input,
    Col,
    Button,
    Tooltip,
    DatePicker,
    Collapse,
    Popconfirm,
} from 'antd';

import MenuAddModal from './MenuAddModal';
import log from 'components/utils/Logger'
moment.locale('ja');


const FormItem = Form.Item;
const Panel = Collapse.Panel;
const Option = Select.Option;
const RadioButton = Radio.Button;
const RadioGroup = Radio.Group;
const RangePicker = DatePicker.RangePicker;

const mapStateToProps = (state, props) => {
    return {
        screen: state.screen,
        menuSP:state.menuSP,
        menuSPCreate: state.menuSPCreate,
        menuSPUpdate: state.menuSPUpdate,
        menuSPDelete: state.menuSPDelete,
        modules:state.modules,
    }
};

const mapDispatchToProps = dispatch => {
    return {
        getMenu: (data) => {
            dispatch(Actions.AdminConnection.menu.sp.search.request(data));
        },
        createMenu : (data) => {
            dispatch(Actions.AdminConnection.menu.sp.create.request(data));
        },
        updateMenu: (fieldValue,data) => {
            dispatch(Actions.AdminConnection.menu.sp.update.request(data.uuid,fieldValue));
        },
        searchModules: (data) => {
            dispatch(Actions.AdminConnection.modules.search.request(data));
        },
        deleteMenu: (uuid) => {
            dispatch(Actions.AdminConnection.menu.sp.delete.request(uuid));
        }
    }
};


class ServicesList extends React.Component {

    constructor(props) {
        super(props);
        const modalTitle = props.isEditMode ? "お知らせの編集" : "お知らせの新規作成";
        const target_uuid = props.data ? props.data.target_uuid: "";

        this.state = {
            loading: false,
            editMenuData : null,
            resultMenuData : [],
            modulesArray: [],
            pagination: {
                showTotal: (total, range) => {
                    return (`全${total}件中 ${range[0]}-${range[1]} 件`)
                },
                showSizeChanger: true,
                pageSizeOptions: GlobalConfig.PageSizeOptions,
                pageSize: 20,
                onShowSizeChange: this.onChangePageSize
            }
        }
    }

    componentWillMount(){
        this.search();
        this.props.searchModules({page_size:300});
    }


    componentWillReceiveProps(nextProps) {
        if (nextProps.menuSP !== this.props.menuSP) {
            if (!nextProps.menuSP.meta.fetch) {
                if (!nextProps.menuSP.error) {
                    if (nextProps.menuSP.payload !== null) {
                        this.setState({
                            loading: nextProps.menuSP.meta.fetch,
                            resultMenuData: nextProps.menuSP.payload.result.items,
                        })
                    }
                }else {
                    this.setState({
                        loading: nextProps.menuSP.meta.fetch
                    })
                }
            } else {
                this.setState({
                    loading: nextProps.menuSP.meta.fetch
                })
            }
        }

        if (nextProps.menuSPCreate !== this.props.menuSPCreate) {
            if (!nextProps.menuSPCreate.meta.fetch) {
                if (!nextProps.menuSPCreate.error) {
                    if (nextProps.menuSPCreate.payload !== null) {
                        this.setState({
                            loading: nextProps.menuSPCreate.meta.fetch,
                            resultMenuData: nextProps.menuSPCreate.payload.result.items,
                            addModalIsOpen: false
                        })
                        this.search();
                    }
                }else {
                    this.setState({
                        loading: nextProps.menuSPCreate.meta.fetch
                    })
                }
            } else {
                this.setState({
                    loading: nextProps.menuSPCreate.meta.fetch
                })
            }
        }
        if (nextProps.menuSPUpdate !== this.props.menuSPUpdate) {
            if (!nextProps.menuSPUpdate.meta.fetch) {
                if (!nextProps.menuSPUpdate.error) {
                    if (nextProps.menuSPUpdate.payload !== null) {
                        this.setState({
                            loading: nextProps.menuSPUpdate.meta.fetch,
                            resultMenuData: nextProps.menuSPUpdate.payload.result.items,
                            editModalIsOpen: false
                        })
                        this.search();
                    }
                }else {
                    this.setState({
                        loading: nextProps.menuSPUpdate.meta.fetch
                    })
                }
            } else {
                this.setState({
                    loading: nextProps.menuSPUpdate.meta.fetch
                })
            }
        }

        if (nextProps.menuSPDelete !== this.props.menuSPDelete) {
            if (!nextProps.menuSPDelete.meta.fetch) {
                if (!nextProps.menuSPDelete.error) {
                    if (nextProps.menuSPDelete.payload !== null) {
                        this.setState({
                            loading: nextProps.menuSPDelete.meta.fetch,
                            resultMenuData: nextProps.menuSPDelete.payload.result.items,
                            editModalIsOpen: false
                        })
                        this.search();
                    }
                }else {
                    this.setState({
                        loading: nextProps.menuSPDelete.meta.fetch
                    })
                }
            } else {
                this.setState({
                    loading: nextProps.menuSPDelete.meta.fetch
                })
            }
        }


        if (nextProps.modules !== this.props.modules) {
            if (!nextProps.modules.meta.fetch) {
                if (!nextProps.modules.error) {
                    if (nextProps.modules.payload !== null) {
                        this.setState({
                            loading: nextProps.modules.meta.fetch,
                            modulesArray: nextProps.modules.payload.result.items,
                        })
                    }
                }else {
                    this.setState({
                        loading: nextProps.modules.meta.fetch
                    })
                }
            } else {
                this.setState({
                    loading: nextProps.modules.meta.fetch
                })
            }
        }

    }


    search() {
        let fieldsValue = this.props.form.getFieldsValue();
        this.props.getMenu(fieldsValue);
    }

    onSubmit = (e) => {
        e.preventDefault();
        this.search();
        return false;
    };


    handleResetSearchForm = () => {
        this.props.form.resetFields();
        this.search();
    };

    handleOpenEditModal = (data) => {
        this.setState({
            editModalIsOpen: true,
            data: data
        });
    };

    handleOpenAddModal() {
        log.debug("add")
        this.setState({
            addModalIsOpen: true,
        });
    };

    handleDeleteMenu(record) {
        this.props.deleteMenu(record.uuid);
    };

    render() {

        const {getFieldDecorator} = this.props.form;

        const formItemLayout = {
            labelCol: {
                xs: {span: 24},
                sm: {span: 4},
            },
            wrapperCol: {
                xs: {span: 24},
                sm: {span: 20},
            },
        };

        let availableSearchForm = (
            <Form className="search-form" onSubmit={this.onSubmit}>

                <FormItem
                    {...formItemLayout}
                    label="メニュー名称"
                    layout="inline">
                    {getFieldDecorator('name')(
                        <Input placeholder="メニュー名称を入力してください"/>
                    )}
                </FormItem>

                <FormItem
                    {...formItemLayout}
                    label="摘要"
                    layout="inline">
                    {getFieldDecorator('description')(
                        <Input placeholder="摘要を入力してください"/>
                    )}
                </FormItem>

                <div className="submit-container">

                    <Button className="search-form-button" onClick={this.handleResetSearchForm}>
                        リセット
                    </Button>

                    <Button type="primary" htmlType="submit" className="search-form-button">
                        検索
                    </Button>

                </div>
            </Form>
        );

        let {sortedInfo, filteredInfo} = this.state;
        sortedInfo = sortedInfo || {};
        filteredInfo = filteredInfo || {};

        const columns = [

            {
                title: 'メニュー名称',
                dataIndex: 'name',
                key: 'name',
            },{
                title: "摘要",
                dataIndex: "description",
                key:"description"
            },{
                title: "作成時間/更新時間",
                dataIndex: "updated_at",
                key: "update_at",
                width: 130,
                render: (text, record) => {
                    const update_at = text ? moment.unix(text).format("YYYY/MM/DD HH:mm") : "更新なし";
                    const created_at = moment.unix(record.created_at).format("YYYY/MM/DD HH:mm");
                    return (
                        <div>
                            {created_at}<br/>
                            {update_at}
                        </div>
                    )
                }
            },{
                title: "",
                width: "85px",
                key: 'action',
                render: (text, record) => {

                    return (
                        <Row type="flex" justify="space-around" align="middle">
                            <Col>
                                <Tooltip placement="topLeft" title="編集">
                                    <Button type="default" shape="circle" icon={<EditOutlined />}
                                            onClick={() => this.handleOpenEditModal(record)}/>
                                </Tooltip>
                            </Col>
                            <Col>
                                <Popconfirm
                                    title={<span>サービスを削除するとサービス内に設定されているモジュール構成が削除されます。<br />
                                        <strong>削除すると復元することはできません。</strong></span>}
                                    onConfirm={() => this.handleDeleteMenu(record)}
                                    onCancel=""
                                    placement="left"
                                    okText="削除" cancelText="キャンセル">
                                    <Tooltip placement="topLeft" title="削除">
                                        <Button type="default" shape="circle" icon={<DeleteOutlined />} />
                                    </Tooltip>
                                </Popconfirm>
                            </Col>
                        </Row>
                    );
                }
            }
        ];
        return (
            <div id="notification-list-container" alt="SSO連携管理"  className="tab-container">
                <div className="card general-search">
                    {availableSearchForm}
                </div>

                <div className="table-header-wrapper">
                    <div className="button-container">
                        <Button type="primary" icon={<PlusCircleOutlined />}
                                onClick={() => this.handleOpenAddModal()}>
                            新規作成
                        </Button>
                    </div>
                </div>

                <Table
                    rowKey="uuid"
                    className="general-table"
                    locale={{
                        filterTitle: 'フィルタ',
                        filterConfirm: '確定',
                        filterReset: 'リセット',
                        emptyText: '該当するものはありません',
                    }}
                    bordered={true}
                    size="middle"
                    loading={this.state.loading}
                    onChange={null}
                    columns={columns} dataSource={this.state.resultMenuData}
                    pagination={this.state.pagination}
                />

                <MenuAddModal
                    isEditMode={false}
                    visible={this.state.addModalIsOpen}
                    handleCancel={() => {
                        this.setState({
                            addModalIsOpen: false
                        })
                    }}
                    handleOk={(fieldValue, data) => {
                        this.props.createMenu(fieldValue);
                    }}
                    modulesArray={this.state.modulesArray}
                    reloadModules={
                        ()=>{
                            this.props.searchModules({page_size:300});
                        }
                    }
                />

                <MenuAddModal
                    isEditMode={true}
                    visible={this.state.editModalIsOpen}
                    handleCancel={() => {
                        this.setState({
                            editModalIsOpen: false
                        })
                    }}
                    modulesArray={this.state.modulesArray}
                    handleOk={(fieldValue, data) => {
                        //編集
                        this.props.updateMenu(fieldValue,data);
                    }}
                    data={this.state.data}
                    reloadModules={
                        ()=>{
                            this.props.searchModules({page_size:300});
                        }
                    }
                />
            </div>
        );
    }

}


export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Form.create()(ServicesList))
