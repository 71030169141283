import React from 'react'
import {connect, store} from 'react-redux'
import {instanceOf} from 'prop-types';
import log from '../../components/utils/Logger.js'

import moment from 'moment';
import 'moment/locale/ja';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Tabs, Collapse } from 'antd';
import ContentHeader from "../../components/ContentHeader";

import SSOList from './SSOList';

moment.locale('ja');

const TabPane = Tabs.TabPane;
const FormItem = Form.Item;
const Panel = Collapse.Panel;

const mapStateToProps = (state, props) => {
    return {
    }
};

const mapDispatchToProps = dispatch => {
    return {
    }
};

class SSO extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
        };
    }


    onChangeTab = (key) =>{
    };

    render() {
        return (
            <div className='container clearfix' id="notification">
                <ContentHeader title="SSO連携管理" breadCrumbItems={[
                    {title:"SSO連携管理",icon:"link",path:"/console/sso/"}
                ]}/>
                <SSOList />
            </div>
        );
    }
}


SSO.propTypes = {

};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(SSO);
