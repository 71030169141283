import React from 'react';
import {connect, store} from 'react-redux';
import {instanceOf} from 'prop-types';
import log from 'components/utils/Logger.js';
import {withRouter,Link} from 'react-router-dom';
import moment from 'moment';
import 'moment/locale/ja';

import {
    AppstoreOutlined,
    CalendarOutlined,
    ExceptionOutlined,
    FolderOutlined,
    LinkOutlined,
    NotificationOutlined,
    SettingOutlined,
    SolutionOutlined,
    TeamOutlined,
} from '@ant-design/icons';

import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';

import { Tabs, Collapse, Row, Col } from 'antd';
import ContentHeader from "../../components/ContentHeader";

moment.locale('ja');

const TabPane = Tabs.TabPane;
const FormItem = Form.Item;
const Panel = Collapse.Panel;

const mapStateToProps = (state, props) => {
    return {
        screen: state.screen,
        session: state.session
    }
}

const mapDispatchToProps = dispatch => {
    return {
    }
}

class AdminHome extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }


    componentWillReceiveProps(nextProps) {

    }

    onChangeTab = (key) =>{

    };

    render() {
        let component = null;

        if(this.props.session.payload.isAuthenticated){
            switch(this.props.session.payload.user.permission){
                case "admin":
                    component = (
                        <div className='container clearfix'>
                            <ContentHeader title="インフォメーションシステム 管理メニュー" breadCrumbItems={[]}/>

                            <div className="home-grid">
                                <Row type="flex" justify="start" align="middle">
                                    <Col span={6}>
                                        <Link to="/console/notifications/">
                                            <div className="home-grid-cell">
                                                <div className="home-gird-icon">
                                                    <NotificationOutlined />
                                                </div>
                                                <div className="home-grid-title">お知らせ管理</div>
                                                <div className="home-grid-description">お知らせ・個人伝言の配信や、配信状況の確認をすることができます</div>
                                            </div>
                                        </Link>
                                    </Col>
                                    {/*
                                    <Col span={6}>
                                        <Link to="/console/questionnaires/">
                                            <div className="home-grid-cell">
                                                <div className="home-gird-icon">
                                                    <SolutionOutlined />
                                                </div>
                                                <div className="home-grid-title">アンケート管理</div>
                                                <div className="home-grid-description">アンケートを作成・実施します.</div>
                                            </div>
                                        </Link>
                                    </Col>
                                    <Col span={6}>
                                        <Link to="/console/fileshare/">
                                            <div className="home-grid-cell">
                                                <div className="home-gird-icon">
                                                    <FolderOutlined />
                                                </div>
                                                <div className="home-grid-title">ファイル共有</div>
                                                <div className="home-grid-description">ファイルを共有します</div>
                                            </div>
                                        </Link>
                                    </Col>
                                    */}
                                    <Col span={6}>
                                        <Link to="/console/calendars/">
                                            <div className="home-grid-cell">
                                                <div className="home-gird-icon">
                                                    <CalendarOutlined />
                                                </div>
                                                <div className="home-grid-title">学年暦管理</div>
                                                <div className="home-grid-description">学年暦データのカテゴリ情報と同期元となるCalDAVサーバを設定します.</div>
                                            </div>
                                        </Link>
                                    </Col>
                                    <Col span={6}>
                                        <Link to="/console/services/">
                                            <div className="home-grid-cell">
                                                <div className="home-gird-icon">
                                                    <AppstoreOutlined />
                                                </div>
                                                <div className="home-grid-title">メニュー管理</div>
                                                <div className="home-grid-description">インフォメーションシステムのメニュー・モジュール構成を設定します.</div>
                                            </div>
                                        </Link>
                                    </Col>
                                    <Col span={6}>
                                        <Link to="/console/sso/">
                                            <div className="home-grid-cell">
                                                <div className="home-gird-icon">
                                                    <LinkOutlined />
                                                </div>
                                                <div className="home-grid-title">SSO連携管理</div>
                                                <div className="home-grid-description">インフォメーションシステムから外部サイトへのSSO連携先を設定します.</div>
                                            </div>
                                        </Link>
                                    </Col>
                                    <Col span={6}>
                                        <Link to="/console/users/">
                                            <div className="home-grid-cell">
                                                <div className="home-gird-icon">
                                                    <TeamOutlined />
                                                </div>
                                                <div className="home-grid-title">利用者管理</div>
                                                <div className="home-grid-description">インフォメーションシステムへログインすることができる利用者情報の管理とグループ属性を設定します.</div>
                                            </div>
                                        </Link>
                                    </Col>
                                    <Col span={6}>
                                        <Link to="/console/log/">
                                            <div className="home-grid-cell">
                                                <div className="home-gird-icon">
                                                    <ExceptionOutlined />
                                                </div>
                                                <div className="home-grid-title">ログ検索</div>
                                                <div className="home-grid-description">インフォメーションシステム利用者のログイン・ログアウトログを検索します.</div>
                                            </div>
                                        </Link>
                                    </Col>
                                    <Col span={6}>
                                        <Link to="/console/maintenance/">
                                            <div className="home-grid-cell">
                                                <div className="home-gird-icon">
                                                    <SettingOutlined />
                                                </div>
                                                <div className="home-grid-title">メンテナンス</div>
                                                <div className="home-grid-description">インフォメーションシステムの全般に関する設定や、定期メンテナンスポリシーを設定します.</div>
                                            </div>
                                        </Link>
                                    </Col>
                                </Row>
                            </div>
                        </div>
                    );
                    break;
                case "manage":
                    component = (
                        <div className='container clearfix'>
                            <ContentHeader title="インフォメーションシステム 管理メニュー" breadCrumbItems={[]}/>

                            <div className="home-grid">
                                <Row type="flex" justify="start" align="middle">
                                    <Col span={6}>
                                        <Link to="/console/notifications/">
                                            <div className="home-grid-cell">
                                                <div className="home-gird-icon">
                                                    <NotificationOutlined />
                                                </div>
                                                <div className="home-grid-title">お知らせ管理</div>
                                                <div className="home-grid-description">お知らせ・個人伝言の配信や、配信状況の確認をすることができます</div>
                                            </div>
                                        </Link>
                                    </Col>
                                    {/*
                                    <Col span={6}>
                                        <Link to="/console/questionnaires/">
                                            <div className="home-grid-cell">
                                                <div className="home-gird-icon">
                                                    <SolutionOutlined />
                                                </div>
                                                <div className="home-grid-title">アンケート管理</div>
                                                <div className="home-grid-description">アンケートを作成・実施します.</div>
                                            </div>
                                        </Link>
                                    </Col>
                                    */}
                                </Row>
                            </div>


                        </div>
                    );
                    break;
                case "student":
                default:
                    component = (
                        <div className='container clearfix'>
                            <ContentHeader title="インフォメーションシステム 管理メニュー" breadCrumbItems={[]}/>

                            <div className="home-grid">

                            </div>

                        </div>
                    );
                    break;
            }
        }

        return (
            component
        );
    }
}


AdminHome.propTypes = {

};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AdminHome);