import React, { Component } from 'react'
import {connect, store} from 'react-redux'
import {notification,Button} from 'antd';
import {withRouter,Link} from 'react-router-dom'
import log from '../../components/utils/Logger.js'
import moment from 'moment';
import * as Actions from 'redux/actions'
import 'moment/locale/ja';
import * as GlobalConfig from '../../constants/GlobalConfig';
import IntervalTimer from 'components/utils/IntervalTimer'

moment.locale('ja');

const mapStateToProps = (state, props) => {
    return {
        session: state.session,
        timer:  state.timer,
        check: state.check
    }
};

const mapDispatchToProps = dispatch => {
    return {
        checkContinue : () => {
            dispatch(Actions.Connection.check.request());
        }
    }
};

class Account extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            name: "インフォメーションシステム利用者",
            timer: props.timer
        };
    }

    componentWillReceiveProps(nextProps){
        this.setState({
            name: nextProps.session.payload.user.name
        })

        if(nextProps.timer !== this.state.timer) {
            this.calculateSessionTime(false);
        };

        if(nextProps.check !== this.props.check) {
            if (!nextProps.check.meta.fetch) {
                if (!nextProps.check.error) {
                    if (nextProps.check.payload !== null) {
                        this.calculateSessionTime(false);
                    }
                }else{
                    this.calculateSessionTime(true);
                }
            }
        }
    }


    resetSessionTime = () => {
        const key = "sessionAlert";
        notification.close(key);
        const sessionUnixTime = this.props.timer.payload.gmt;

        let minus = moment().unix() - sessionUnixTime;
        let diffSecond = 0;
        if(minus < 7200){
            diffSecond = 7199 - minus;
        }

        let m = ('000' + Math.floor(diffSecond / 60)).slice( -3 );

        if(m <= 0){
            m = 0;
        }
        if(m < 59){
            this.openNotificationWithIcon('warning',m);
        }


    };

    calculateSessionTime = (notificationBool) => {
        const sessionUnixTime = this.props.timer.payload.gmt;
        const key = "sessionAlert";

        let minus = moment().unix() - sessionUnixTime;
        let diffSecond = 0;
        if(minus < 7200){
            diffSecond = 7200 - minus;
        }

        let m = ('000' + Math.floor(diffSecond / 60)).slice( -3 );

        if(m <= 0){
            m = 0;
        }
        if(m < 11){
            if(notificationBool){
                this.openNotificationWithIcon('warning',m);
            }
        }else{
            notification.close(key);
        }
        const sessionTimer = `${m}分`;
        this.setState({
            sessionTimer
        })
    };


    btnClick = () => {
        const key = "sessionAlert";
        this.props.checkContinue();
        notification.close(key);
    };

    openNotificationWithIcon = (type,m) => {
        const key = "sessionAlert";
        const btn = (
            <Button type="primary" size="small" onClick={this.btnClick}>
                { m<=0 ? "ログイン画面へ" : "継続して利用" }
            </Button>
        );
        notification.close(key)
        notification[type]({
            message: 'セッション残時間',
            description: `セッション時間が残り${m}分を切りました`,
            key,
            btn,
            duration: 0
        })
    };

    handleTimer = () => {
        this.calculateSessionTime(true);
    }

    render() {
        return (
            <div className="account">
                <span> {this.state.name} さん</span>
                    {/*
                    <br/>
                    セッション残り時間: {this.state.sessionTimer}
                    */}
                {/*
                <IntervalTimer
                    enabled={true}
                    callback = {this.handleTimer}
                    timeout={30000}
                />
                */}
            </div>
        )
    }
}


export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Account);