import React from 'react'
import {connect, store} from 'react-redux'
import {instanceOf} from 'prop-types';

import moment from 'moment';
import 'moment/locale/ja';
import ContentHeader from "../../components/ContentHeader";
import LogSearchList from './LogSearchList'

moment.locale('ja');


const mapStateToProps = (state, props) => {
    return {
        screen: state.screen,
    }
};

const mapDispatchToProps = dispatch => {
    return {
    }
};

class LogSearch extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }

    componentWillReceiveProps(nextProps) {
    }

    onChangeTab = (key) =>{

    };

    render() {
        return (
            <div className='container clearfix' id="notification">
                <ContentHeader title="ログ検索" breadCrumbItems={[
                    {title:"ログ検索",icon:"exception",path:"/console/notifications/"}
                ]}/>
                <LogSearchList />
            </div>
        );
    }
}

LogSearch.propTypes = {

};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(LogSearch);

