import React from 'react'
import {connect, store} from 'react-redux'
import PropTypes from 'prop-types';
import {instanceOf} from 'prop-types';
import log from 'components/utils/Logger.js'

import moment from 'moment';
import 'moment/locale/ja';
import { EditOutlined, InboxOutlined, LineChartOutlined, LoadingOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Table, Tabs, Collapse, Card, Button, Row, Col, Modal } from 'antd';
import ReactGridLayout from 'react-grid-layout'
import * as Actions from 'redux/actions';
import ModuleQuestionnaireModalSingle from './ModuleQuestionnaireModalSingle';
import ForceModuleQuestionnaireModalList from './ForceModuleQuestionnaireModalList';
import ModuleQuestionnaireModalList from './ModuleQuestionnaireModalList'
import QuestionnaireResultModal from '../../../questionnaire/QuestionnaireResultModal';
import FroalaEditorView from 'react-froala-wysiwyg/FroalaEditorView';
import { Scrollbars } from 'react-custom-scrollbars';
import * as TagNodeGenerator from 'components/utils/TagNodeGenerator'
moment.locale('ja');

const TabPane = Tabs.TabPane;
const FormItem = Form.Item;
const Panel = Collapse.Panel;

const mapStateToProps = (state, props) => {
    return {
        screen: state.screen,
        paramCategory: state.paramQuestionnaireCategory,
        questionnaire: state.questionnaire,
        questionnaireAnswer: state.questionnaireAnswer
    }
}

const mapDispatchToProps = dispatch => {
    return {
        getCategory: () => {
            dispatch(Actions.Connection.questionnaire.category.parameter.request());
        },
        searchQuestionnaire: (data, currentPageNum,moduleUUID) => {
            dispatch(Actions.Connection.questionnaire.search.request(data, currentPageNum,moduleUUID));
        },
        answerQuestionnaire: (uuid,data) => {
            dispatch(Actions.Connection.questionnaire.answer.search.request(uuid,data));
        }
    }
};

class ModuleQuestionnaire extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            singleModalIsOpen: false,
            forceModalIsOpen: false,
            listModalIsOpen: false,
            paramCategoryArray: [],
            resultQuestionnaireArray: [],
            resultModalIsOpen:false,
            detailData:null,
            parts: props.parts,
        };
    }
    componentWillMount() {
        this.props.getCategory();
        this.search();
    }

    componentDidMount() {
        this.setState({
            forceModalIsOpen: true
        })
    }

    componentWillReceiveProps(nextProps) {
        const pagination = {...this.state.pagination};
        const {paramCategory, questionnaire,questionnaireAnswer} = {...this.props};
        this.setState({
            parts: nextProps.parts
        });


        if (nextProps.paramCategory !== paramCategory) {
            if (!nextProps.paramCategory.meta.fetch) {
                if (!nextProps.paramCategory.error) {
                    if (nextProps.paramCategory.payload !== null) {
                        this.setState({
                            loading: nextProps.paramCategory.meta.fetch,
                            paramCategoryArray: nextProps.paramCategory.payload.result.items,
                        })
                    }
                }
            } else {
                this.setState({
                    loading: nextProps.paramCategory.meta.fetch
                })
            }
        }

        if (nextProps.questionnaire !== questionnaire) {
            if (!nextProps.questionnaire.meta.fetch) {
                if (!nextProps.questionnaire.error) {
                    if (nextProps.questionnaire.payload !== null) {
                        if(nextProps.questionnaire.meta.module_id === this.props.data.uuid) {
                            pagination.total = nextProps.questionnaire.payload.result.page_total * nextProps.questionnaire.payload.result.page_size;
                            this.setState({
                                searchLoading: nextProps.questionnaire.meta.fetch,
                                resultQuestionnaireArray: nextProps.questionnaire.payload.result.items,
                                //通信後
                                pagination
                            })
                        }
                    }
                }
            } else {
                this.setState({
                    searchLoading: nextProps.questionnaire.meta.fetch
                })
            }
        }

        if (nextProps.questionnaireAnswer !== questionnaireAnswer) {
            if (!nextProps.questionnaireAnswer.meta.fetch) {
                if (!nextProps.questionnaireAnswer.error) {
                    if (nextProps.questionnaireAnswer.payload !== null) {
                        if(nextProps.questionnaireAnswer.payload.hasOwnProperty("result") &&
                            nextProps.questionnaireAnswer.payload.result.hasOwnProperty("after_message") &&
                            nextProps.questionnaireAnswer.payload.result.after_message){
                            if(this.state.singleModalIsOpen) {
                                Modal.info({
                                    content: (
                                        <div>
                                            <p><FroalaEditorView model={nextProps.questionnaireAnswer.payload.result.after_message} /></p>
                                        </div>
                                    ),
                                    onOk() {
                                    },
                                });
                            }
                        }
                        this.setState({
                            singleModalIsOpen: false
                        });
                        this.search()
                    }
            }
            } else {
                this.setState({
                    searchLoading: nextProps.questionnaire.meta.fetch
                })
            }
        }
    }


    search = () => {
        //OPTIMIZE カテゴリの絞込実装 unansweredをtrue/falseにした方がいい気がする
        if(this.state.parts){
            log.debug(this.state.parts);
            const page_size =  this.state.parts.item_num;
            const status = this.state.parts.unanswered_only !==  0?  "unanswered" : undefined;
            let category_uuid = "";
            this.state.parts.categories.forEach((value,index) => {
                category_uuid += value;
                if(this.state.parts.categories.length > index + 1){
                    category_uuid += ",";
                }
            });
            this.props.searchQuestionnaire({ status: status, page_size:page_size,category_uuid:category_uuid },undefined,this.props.data.uuid);
        }else{
            this.props.searchQuestionnaire();
        }
    };


    handleClickSeeMore = () => {
        this.setState({
            listModalIsOpen: true
        })
    };

    handleRowClick = (record, index, event) => {
        this.setState({
            uuid: record.uuid,
            selectRowTitle: record.name,
        },()=>{
            this.setState({
                singleModalIsOpen: true
            })
        })
    };

    handleOpenResultModal = (value) => {
        this.setState({
            resultModalIsOpen: true,
            detailData: value,
            result_uuid: value.uuid
        })
    };

    render() {
        const titleNode = (
            <div className="draggable-header">
                <span className="module-title">{this.props.title}</span>
                {this.props.seeMore? <Button className="module-title-button" onClick={this.handleClickSeeMore}>すべて表示</Button> : <span /> }
            </div>
        );

        const columns = [{
            dataIndex: 'key',
            key: 'key',
            render: (text, record) => {
                log.debug(record,"record");
                const isAnsweredTag = TagNodeGenerator.generate(TagNodeGenerator.TYPES_QUESTIONNAIRE_ANSWERED,record.is_answered);

                let resultButton = null;
                // アンケートの結果公開可否と結果公開期間チェック
                if (record.is_public_result === true && moment().isBetween(moment.unix(record.publish_result_start_at), moment.unix(record.publish_result_end_at))) {
                    resultButton = <Button type="primary" size={"small"} icon={<LineChartOutlined />} onClick={() => this.handleOpenResultModal(record)}>集計結果を見る</Button>;
                }

                let answerButton;
                if (record.is_answered && record.can_be_answered_only_once){
                    answerButton = null;
                }else{
                    answerButton = <Button type="primary" size={"small"} icon={<EditOutlined />} onClick={() => {this.handleRowClick(record)}}>回答する</Button>
                }


                return(
                    <div className="module-cell module-questionnaire">
                        <div className="module-cell-head module-questionnaire-head">
                            <div className="date">{moment.unix(record.publish_start_at).format("YYYY.MM.DD")} - {moment.unix(record.publish_end_at).format("YYYY.MM.DD")}</div>
                            <div className="tags-wrapper">
                                {isAnsweredTag}
                            </div>
                        </div>
                        <div className="module-cell-body module-questionnaire-body">{record.name}</div>
                        <div className="publish-from-wapper">{record.publish_from}</div>
                        <div style={{textAlign:"right",paddingTop:"5px"}}>
                            {answerButton}&nbsp;
                            {resultButton}
                        </div>
                    </div>
                )
            }
        }];

        return (
            <Card className="module-card" title={titleNode}>
                <Scrollbars autoHide>
                    <div className="module-inner module-questionnaire">
                        <Table
                            className="general-table"
                            locale={{
                                filterTitle: 'フィルタ',
                                filterConfirm: '確定',
                                filterReset: 'リセット',
                                emptyText: this.state.searchLoading ?
                                    <span><LoadingOutlined />&nbsp;読み込み中</span>:
                                    <span><InboxOutlined />&nbsp;現在実施中のアンケートはありません</span>,
                            }}
                            pagination={false}
                            bordered={true}
                            showHeader={false}
                            columns={columns} dataSource={this.state.resultQuestionnaireArray}
                            size="small"
                        />
                    </div>
                </Scrollbars>

                <ModuleQuestionnaireModalList
                    visible={this.state.listModalIsOpen}
                    handleCancel={() => {
                        this.setState({
                            listModalIsOpen: false
                        })
                    }}
                    handleRowClick={(record, index, event) => {
                        this.handleRowClick(record,index,event);
                    }}
                    handleOpenResultModal={this.handleOpenResultModal}
                    handleOk={() => {

                    }}
                />

                <ModuleQuestionnaireModalSingle
                    visible={this.state.singleModalIsOpen}
                    handleCancel={() => {
                        this.setState({
                            singleModalIsOpen: false
                        })
                    }}
                    handleOk={(uuid,data) => {
                        this.props.answerQuestionnaire(uuid,data);
                    }}
                    uuid={this.state.uuid}
                />

                <QuestionnaireResultModal
                    isAdmin={false}
                    visible={this.state.resultModalIsOpen}
                    paramCategory={this.state.paramCategoryArray}
                    handleCancel={() => {
                        this.setState({
                            resultModalIsOpen: false
                        })
                    }}
                    handleOk={(uuid, data) => {
                    }}
                    uuid={this.state.result_uuid}
                />


            </Card>
        );
    }
}


export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ModuleQuestionnaire)
