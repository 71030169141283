import React from 'react'
import PropTypes from 'prop-types';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Modal, Radio, Input, Button } from 'antd';


const FormItem = Form.Item;
const { TextArea } = Input;

class CalendarCategoryFormModal extends React.Component {
    constructor(props) {
        super(props);

        const modalTitle = props.isEditMode? "学年暦の編集":"学年暦の新規作成";
        const category_title = props.data? props.data.name:"";
        const category_description = props.data? props.data.description:"";

        this.state = {
            modalTitle: modalTitle,
            visible: props.visible,
            data: props.data,
            title: category_title,
            description:category_description
        }
    }

    componentWillReceiveProps(nextProps) {

        const modalTitle = nextProps.isEditMode? "学年暦の編集":"学年暦の新規作成";
        const category_title = nextProps.data? nextProps.data.title:"";
        const category_url = nextProps.data? nextProps.data.url:"";

        this.state = {
            modalTitle: modalTitle,
            visible: nextProps.visible,
            data: nextProps.data,
            title: category_title,
            url: category_url,
            confirmLoading: false
        };

        if (!nextProps.visible) {
            this.resetFormAll()
        }
    }

    resetFormAll = () => {
        this.props.form.resetFields();
        this.setState({
            confirmLoading: false
        })
    };

    handleOk = (fieldValue,data) => {
        //バリデーション
        this.props.form.validateFields((err, values) => {
            if (!err) {
                this.setState({
                    confirmLoading: true,
                });
                this.props.handleOk(fieldValue,data);
            }
        });

    };

    // URI形式のバリデーションチェック
    checkUri = (rule, value, callback) => {

        let pattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
            '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // domain name
            '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
            '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
            '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
            '(\\#[-a-z\\d_]*)?$','i'); // fragment locator

        if (value && !pattern.test(value)) {
            callback('正しいURI形式を入力してください');
        } else {
            callback();
        }
    };

    handleCancel = () => {
        this.props.handleCancel();
    };

    render() {
        const {visible, confirmLoading} = this.state;

        const formItemLayout = {
            labelCol: {
                xs: {span: 24},
                sm: {span: 4},
            },
            wrapperCol: {
                xs: {span: 24},
                sm: {span: 20},
            },
        };


        const {getFieldDecorator} = this.props.form;

        return (
            <Modal
                className="common-modal select-target-form"
                title={this.state.modalTitle}
                visible={visible}
                maskClosable={false}
                onOk={() => this.handleOk(this.props.form.getFieldsValue(),this.state.data)}
                confirmLoading={confirmLoading}
                onCancel={this.handleCancel}>

                <div className='wrapper'>
                    <Form className="search-form" onSubmit={this.onSubmit}>
                        <FormItem
                            {...formItemLayout}
                            label="カテゴリ名称"
                            layout="inline">
                            {getFieldDecorator('title' ,{
                                initialValue: this.state.title,
                                rules: [{
                                    required: true, message: 'カテゴリ名称を入力してください',
                                }],
                            })(
                                <Input placeholder="カテゴリ名称を入力してください"/>
                            )}
                        </FormItem>

                        <FormItem
                            {...formItemLayout}
                            label="CalDAV URI"
                            layout="inline">
                            {getFieldDecorator('url' ,{
                                initialValue: this.state.url,
                                rules: [{
                                    required: true, message: 'CalDAV URIを入力してください',
                                },{
                                    validator: this.checkUri,
                                }],
                            })(
                                <Input placeholder="CalDAV URIを入力してください"/>
                            )}
                        </FormItem>
                    </Form>
                </div>

            </Modal>
        );
    }
}


export default Form.create()(CalendarCategoryFormModal)

CalendarCategoryFormModal.propTypes = {
    visible: PropTypes.bool.isRequired,
    isEditMode: PropTypes.bool.isRequired,
    handleOk: PropTypes.func.isRequired,
    handleCancel: PropTypes.func.isRequired,
    data: PropTypes.object
};