import React from 'react';
import {connect, store} from 'react-redux';
import {instanceOf} from 'prop-types';
import * as GlobalConfig from '../../constants/GlobalConfig';
import * as url from '../../constants/api';
import * as Actions from '../../redux/actions';
import moment from 'moment';
import 'moment/locale/ja';
import log from 'components/utils/Logger';
import {DeleteOutlined, EditOutlined, PlusCircleOutlined} from '@ant-design/icons';
import {Form} from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import {
    Table,
    Radio,
    Select,
    Row,
    Input,
    Col,
    Button,
    Tooltip,
    DatePicker,
    Collapse,
    Popconfirm,
    Badge,
    Tag,
} from 'antd';
import jaJP from 'antd/lib/date-picker/locale/ja_JP';
import UserViewModal from "./UserViewModal";
import DownloadCSVConfirm from '../../containers/common/DownloadCSVConfirm'
import UserAddFormModal from './UserAddFormModal';
import UserEditFormModal from './UserEditFormModal';
import {getDefaultLayout} from "../../constants/GlobalConfig";

moment.locale('ja');

const FormItem = Form.Item;
const Panel = Collapse.Panel;
const Option = Select.Option;
const RadioButton = Radio.Button;
const RadioGroup = Radio.Group;
const RangePicker = DatePicker.RangePicker;

const mapStateToProps = (state, props) => {
    return {
        screen: state.screen,
        user: state.user,
        userCreate: state.userCreate,
        userUpdate: state.userUpdate,
        userDelete: state.userDelete
    }
};

const mapDispatchToProps = dispatch => {
    return {
        searchUser: (data, currentPageNum) => {
            dispatch(Actions.AdminConnection.user.search.request(data, currentPageNum));
        },
        createUser: (data) => {
            dispatch(Actions.AdminConnection.user.create.request(data));
        },
        updateUser: (uuid, data) => {
            dispatch(Actions.AdminConnection.user.update.request(uuid, data));
        },
        deleteUser: (uuid) => {
            dispatch(Actions.AdminConnection.user.delete.request(uuid));
        },
        getUserAuthProfile: () => {
            dispatch(Actions.http.connection.auth.authProfile());
        }
    }
};

class UsersList extends React.Component {

    constructor(props) {
        super(props);

        props.getUserAuthProfile();
        this.state = {
            pagination: {
                showTotal: (total, range) => {
                    return (`全${total}件中 ${range[0]}-${range[1]} 件`)
                },
                showSizeChanger: true,
                pageSizeOptions: GlobalConfig.PageSizeOptions,
                pageSize: 20,
                onShowSizeChange: this.onChangePageSize,
                total: 0,
            },
            userListArray: [],
            selectedRowKeys: [],
            loading: false,
            addModalIsOpen: false,
            editModalIsOpen: false,
            editModalUUID: null,
        }
    }

    componentWillReceiveProps(nextProps) {

        const pagination = {...this.state.pagination};
        const {user, userCreate, userUpdate, userDelete} = {...this.props};

        if (nextProps.user !== user) {
            if (!nextProps.user.meta.fetch) {
                if (!nextProps.user.error) {
                    if (nextProps.user.payload !== null) {
                        pagination.total = nextProps.user.payload.result.item_count;

                        this.setState({
                            loading: nextProps.user.meta.fetch,
                            userListArray: nextProps.user.payload.result.items,
                            pagination
                        })
                    }
                }
            } else {
                this.setState({
                    loading: nextProps.user.meta.fetch
                })
            }
        }

        if (nextProps.userCreate !== userCreate) {
            if (!nextProps.userCreate.meta.fetch) {
                if (!nextProps.userCreate.error) {
                    if (nextProps.userCreate.payload !== null) {
                        this.search();
                        this.setState({
                            addModalIsOpen: false,
                            loading: nextProps.userCreate.meta.fetch
                        })
                    } else {

                        this.setState({
                            loading: nextProps.userCreate.meta.fetch
                        })
                    }
                } else {
                    this.setState({
                        loading: nextProps.userCreate.meta.fetch
                    })
                }
            } else {
                this.setState({
                    loading: nextProps.userCreate.meta.fetch
                })
            }
        }

        if (nextProps.userUpdate !== userUpdate) {
            if (!nextProps.userUpdate.meta.fetch) {
                if (!nextProps.userUpdate.error) {
                    if (nextProps.userUpdate.payload !== null) {
                        this.search();
                        this.setState({
                            editModalIsOpen: false,
                            loading: nextProps.userUpdate.meta.fetch
                        })
                    } else {
                        this.setState({
                            loading: nextProps.userUpdate.meta.fetch
                        })
                    }
                } else {
                    this.setState({
                        loading: nextProps.userUpdate.meta.fetch
                    })
                }
            } else {
                this.setState({
                    loading: nextProps.userUpdate.meta.fetch
                })
            }
        }

        if (nextProps.userDelete !== userDelete) {
            if (!nextProps.userDelete.meta.fetch) {
                if (!nextProps.userDelete.error) {
                    if (nextProps.userDelete.payload !== null) {
                        this.search();
                        this.setState({
                            loading: nextProps.userDelete.meta.fetch
                        })
                    } else {
                        this.setState({
                            loading: nextProps.userDelete.meta.fetch
                        })
                    }
                } else {
                    this.setState({
                        loading: nextProps.userDelete.meta.fetch
                    })
                }
            } else {
                this.setState({
                    loading: nextProps.userDelete.meta.fetch
                })
            }
        }

    }

    componentWillMount() {
        this.props.searchUser("");
    }

    onChangePageSize = (current, pageSize) => {
        const pager = {...this.state.pagination};
        //pageSizeの更新
        pager.pageSize = pageSize;
        this.setState({
            pagination: pager,
        });
    };

    search(value) {

        let fieldsValue = this.props.form.getFieldsValue();
        let pagination;

        if (typeof value === "undefined") {
            pagination = {...this.state.pagination};
        } else {
            pagination = value;
        }

        fieldsValue.page_size = pagination.pageSize;


        // 有効期限処理
        const expire_range = fieldsValue.expire_range;
        if (typeof expire_range !== 'undefined' && expire_range.length !== 0) {
            fieldsValue.start_at = expire_range[0].unix();
            fieldsValue.end_at = expire_range[1].unix();
            delete fieldsValue.expire_range;
        }

        // 登録日時
        const created_range = fieldsValue.created_range;
        if (typeof created_range !== 'undefined' && created_range.length !== 0) {
            fieldsValue.created_start = created_range[0].unix();
            fieldsValue.created_end = created_range[1].unix();
            delete fieldsValue.created_range;
        }

        // 更新日時
        const updated_range = fieldsValue.updated_range;
        if (typeof updated_range !== 'undefined' && updated_range.length !== 0) {
            fieldsValue.updated_start = updated_range[0].unix();
            fieldsValue.updated_end = updated_range[1].unix();
            delete fieldsValue.updated_range;
        }

        this.props.searchUser(fieldsValue, pagination.current);

    }

    onSubmit = (e) => {
        e.preventDefault();
        this.setState(preview=> ({
            pagination: {
                ...preview.pagination,
                current: 1
            }
        }),()=>{
            this.search();
        })
        return false;
    };

    handleResetSearchForm = () => {
        this.props.form.resetFields();
        this.search();
    };

    handleOpenAddModal = (e) => {
        e.preventDefault();
        this.setState({
            addModalIsOpen: true
        })
    };

    handleOpenEditModal = (value) => {
        this.setState({
            editModalIsOpen: true,
            editModalUUID: value.uuid,
        });
    };

    handleTableChange = (pagination, filters, sorter) => {
        this.setState((preview) => ({
            pagination: {
                ...preview.pagination,
                ...pagination
            }
        }));
        this.search(pagination);
    };

    // 検索結果をCSVでダウンロードさせる
    handleCsvDownload = (encoding) => {

        let fieldsValue = {
            encoding: encoding,
            ...this.props.form.getFieldsValue()
        };

        let urlString = `${process.env.REACT_APP_FILE_DOMAIN}${url.API_ADMIN_ACTION_USER}/download-csv?` + this.serialize(fieldsValue);
        window.open(urlString);
    };

    serialize = function (obj) {
        let str = [];
        for (let p in obj)
            if (obj.hasOwnProperty(p) && obj[p] !== undefined) {
                str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
            }
        return str.join("&");
    }


    userAdd = (fieldValue, data) => {
        log.debug(fieldValue, data);
        this.props.createUser(fieldValue, data);
    };

    userEdit = (fieldValue, data) => {
        this.props.updateUser(data.uuid, fieldValue);
    };
    userDelete = (record) => {
        this.props.deleteUser(record.uuid);
    };


    render() {

        const {getFieldDecorator} = this.props.form;

        const formItemLayout = getDefaultLayout(true)

        let availableSearchForm = (
            <Form className="search-form" onSubmit={this.onSubmit}>

                <FormItem
                    {...formItemLayout}
                    label="利用者ID"
                    layout="inline">
                    {getFieldDecorator('user_id')(
                        <Input placeholder="利用者IDを入力してください"/>
                    )}
                </FormItem>

                <FormItem
                    {...formItemLayout}
                    label="学籍番号10桁コード"
                    layout="inline">
                    {getFieldDecorator('personal_id')(
                        <Input placeholder="学籍番号10桁コードを入力してください"/>
                    )}
                </FormItem>
                <FormItem
                    {...formItemLayout}
                    label="日本語学籍番号（例：法２１−１）">
                    {getFieldDecorator('option_1')(
                        <Input placeholder="日本語学籍番号（例：法２１−１）を入力してください"/>
                    )}
                </FormItem>
                <FormItem
                    {...formItemLayout}
                    label="教職員番号">
                    {getFieldDecorator('option_2')(
                        <Input placeholder="教職員番号を入力してください"/>
                    )}
                </FormItem>
                <FormItem
                    {...formItemLayout}
                    label="氏名"
                    layout="inline">
                    {getFieldDecorator('name')(
                        <Input placeholder="氏名を入力してください"/>
                    )}
                </FormItem>

                <FormItem
                    {...formItemLayout}
                    label="氏名(カナ)"
                    layout="inline">
                    {getFieldDecorator('name_kana')(
                        <Input placeholder="氏名(カナ)を入力してください"/>
                    )}
                </FormItem>

                <FormItem
                    {...formItemLayout}
                    label="氏名(英)"
                    layout="inline">
                    {getFieldDecorator('name_alphabet')(
                        <Input placeholder="氏名(英)を入力してください"/>
                    )}
                </FormItem>
                <FormItem
                    {...formItemLayout}
                    label="有効期限"
                >
                    {getFieldDecorator('expire_range', {
                        rules: [{type: 'array', message: '検索期間を選択してください'}]
                    })(
                        <RangePicker
                            locale={jaJP}
                            showTime={{format: 'HH:mm'}}
                            format="YYYY/MM/DD HH:mm"
                            ranges={GlobalConfig.DatePickerRanges()}/>
                    )}
                </FormItem>

                <FormItem
                    {...formItemLayout}
                    label="登録日時"
                >
                    {getFieldDecorator('created_range', {
                        rules: [{type: 'array', message: '検索期間を選択してください'}]
                    })(
                        <RangePicker
                            locale={jaJP}
                            showTime={{format: 'HH:mm'}}
                            format="YYYY/MM/DD HH:mm"
                            ranges={GlobalConfig.DatePickerRanges()}/>
                    )}
                </FormItem>

                <FormItem
                    {...formItemLayout}
                    label="更新日時"
                >
                    {getFieldDecorator('updated_range', {
                        rules: [{type: 'array', message: '検索期間を選択してください'}]
                    })(
                        <RangePicker
                            locale={jaJP}
                            showTime={{format: 'HH:mm'}}
                            format="YYYY/MM/DD HH:mm"
                            ranges={GlobalConfig.DatePickerRanges()}/>
                    )}
                </FormItem>

                <FormItem
                    {...formItemLayout}
                    label="認証プロファイル"
                    layout="inline">
                    {getFieldDecorator('auth_profile_uuid')(
                        <Select placeholder="認証プロファイルを選択してください" allowClear={true}>
                            <Option key="651faa83-a4b6-48fe-a385-d91dfdc037f4"
                                    value="651faa83-a4b6-48fe-a385-d91dfdc037f4">
                                <div className="select-title">関西大学認証システム(OpenAM)</div>
                                <div className="select-description">関西大学が提供する統合認証アカウントです</div>
                            </Option>
                            <Option key="b23b40b8-bd18-11e7-abc4-cec278b6b50a" value="portal-site">
                                <div className="select-title">インフォメーションシステム</div>
                                <div className="select-description">インフォメーションシステム内でのみ有効となるローカルユーザ認証です</div>
                            </Option>
                        </Select>
                    )}
                </FormItem>

                <div className="submit-container">

                    <Button className="search-form-button" onClick={this.handleResetSearchForm}>
                        リセット
                    </Button>

                    <Button type="primary" htmlType="submit" className="search-form-button">
                        検索
                    </Button>

                </div>

            </Form>
        );

        let {sortedInfo, filteredInfo} = this.state;
        sortedInfo = sortedInfo || {};
        filteredInfo = filteredInfo || {};

        const columns = [
            {
                title: '認証先',
                dataIndex: 'auth_profile',
                key: 'auth_profile',
                width: "130px",
                render: (text, record) => {
                    return <Tag className="tag blue-gray">{record.auth_profiles.name}</Tag>;
                }
            }, {
                title: '利用者ID',
                dataIndex: 'user_id',
                key: 'user_id',
                width: "110px",
                render: (text, record) => {
                    return (
                        <Tooltip mouseEnterDelay={1} title={"UUID:" + record.uuid}>
                            {text}
                        </Tooltip>
                    )
                }
            }, {
                title: <span>学籍番号<br/>10桁コード</span>,
                dataIndex: 'personal_id',
                key: 'personal_id',
                width: "110px"
            }, {
                title: <span>学籍番号／<br/>教職員表示番号</span>,
                dataIndex: 'option_1',
                key: 'option_1',
                width: "110px"
            }, {
                title: '氏名',
                dataIndex: 'name',
                key: 'name',
                width: "200px",
            }, {
                title: '氏名(カナ/英)',
                dataIndex: 'name_kana',
                key: 'name_kana',
                width: "200px",
                render: (text, record) => {
                    return (
                        <div>
                            {record.name_kana}<br/>
                            {record.name_alphabet}
                        </div>
                    )
                }
            }, {
                title: '教職員番号',
                dataIndex: 'option_2',
                key: 'option_2',
                width: "110px",
            }, {
                title: '状態',
                dataIndex: 'is_login_enabled',
                key: 'is_login_enabled',
                width: "90px",
                render: (text, record) => {
                    if (record.is_login_enabled === undefined) {
                        return (
                            <Badge status="warning" text="不明"/>
                        )
                    }
                    if (record.is_login_enabled === true) {
                        return (
                            <Badge status="success" text="有効"/>
                        )
                    }

                    if (record.is_login_enabled === false) {
                        return (
                            <Badge status="error" text="無効"/>
                        )
                    }
                }
            }, {
                title: '有効期限',
                dataIndex: 'start_at',
                key: 'start_at',
                width: "200px",
                render: (text, record) => {
                    const publish_start = moment.unix(record.start_at).format("YYYY/MM/DD HH:mm");
                    const publish_end = moment.unix(record.end_at).format("YYYY/MM/DD HH:mm");
                    const isIndefinitePeriod = record.end_at === 2147483647;
                    const isIndefinitePeriodStart = record.start_at === 0;
                    return (
                        <div>
                            {isIndefinitePeriodStart ? `ー` : `${publish_start} から`}<br/>
                            {isIndefinitePeriod ? `ー` : `${publish_end} まで`}
                        </div>
                    )
                }
            }, {
                title: '登録日時 / 更新日時',
                dataIndex: 'created_at',
                key: 'created_at',
                width: "180px",
                render: (text, record) => {
                    const update_at = record.updated_at ? moment.unix(record.updated_at).format("YYYY/MM/DD HH:mm") : "更新なし";
                    const created_at = moment.unix(record.created_at).format("YYYY/MM/DD HH:mm");
                    return (
                        <div>
                            {created_at}<br/>
                            {update_at}
                        </div>
                    )
                }
            }, {
                title: "",
                width: 85,
                key: 'action',
                fixed: 'right',
                render: (text, record) => {
                    return (
                        <Row type="flex" justify="space-around" align="middle">
                            <Col>
                                <Tooltip placement="topLeft" title="編集">
                                    <Button type="default" shape="circle" icon={<EditOutlined/>}
                                            onClick={() => this.handleOpenEditModal(record)}/>
                                </Tooltip>
                            </Col>
                            <Col>
                                <Popconfirm
                                    title={
                                        <span>
                                            <strong>ユーザ「{record.name}」の削除</strong><br/>
                                            ユーザを削除するとユーザの環境データ及びアクセス権限が削除されます。削除すると復元することはできません。<br/>
                                            <strong>削除後に同一IDのユーザを再追加した場合は別ユーザとして登録されますので再度権限設定が必要となります。</strong>
                                        </span>
                                    }
                                    onConfirm={() => this.userDelete(record)}
                                    onCancel=""
                                    placement="left"
                                    okText="削除" cancelText="キャンセル">
                                    <Tooltip placement="topLeft" title="削除">
                                        <Button type="default" shape="circle" icon={<DeleteOutlined/>}/>
                                    </Tooltip>
                                </Popconfirm>
                            </Col>
                        </Row>
                    );
                }
            }

        ];


        const {selectedRowKeys} = this.state;

        return (
            <div id="notification-list-container" alt="ユーザ検索" className="tab-container">

                <div className="card general-search">
                    {availableSearchForm}
                </div>

                <div className="table-header-wrapper">
                    <div className="button-container">

                        <DownloadCSVConfirm
                            visible={true}
                            handleOk={(encoding) => this.handleCsvDownload(encoding)}/>

                        <Button type="primary" icon={<PlusCircleOutlined/>}
                                onClick={this.handleOpenAddModal}>
                            新規作成
                        </Button>
                    </div>
                </div>

                <Table
                    className="general-table"
                    rowKey="uuid"
                    locale={{
                        filterTitle: 'フィルタ',
                        filterConfirm: '確定',
                        filterReset: 'リセット',
                        emptyText: '該当するものはありません',
                    }}
                    bordered={true}
                    size="middle"
                    loading={this.state.loading}
                    onChange={this.handleTableChange}
                    columns={columns}
                    dataSource={this.state.userListArray}
                    pagination={this.state.pagination}
                    scroll={{x: 1500}}
                    footer={() => {
                        if (selectedRowKeys.length !== 0) {
                            return (
                                <div>
                                    <Popconfirm
                                        title={<span>
                                            <strong>選択されたユーザの削除</strong><br/>
                                            ユーザを削除するとユーザの環境データ及びアクセス権限が削除されます。削除すると復元することはできません。<br/>
                                            <strong>削除後に同一IDのユーザを再追加した場合は別ユーザとして登録されますので再度権限設定が必要となります。</strong>
                                        </span>}
                                        onConfirm={() => {
                                            alert('この機能は現在利用できません。後日アップデートにて提供されます。');
                                        }}
                                        onCancel=""
                                        placement="left"
                                        okText="削除" cancelText="キャンセル">
                                        <Tooltip placement="topLeft" title="削除">
                                            <Button type="primary"
                                                    onClick={this.handleDelete}>選択中の{selectedRowKeys.length}件を削除</Button>
                                        </Tooltip>
                                    </Popconfirm>
                                </div>
                            )
                        } else {
                            return null;
                        }
                    }}
                />


                <UserAddFormModal
                    modalTitle="新規作成"
                    visible={this.state.addModalIsOpen}
                    handleCancel={() => {
                        this.setState({
                            addModalIsOpen: false
                        })
                    }}
                    handleOk={(fieldValue, data) => {
                        this.userAdd(fieldValue, data);
                    }}
                    uuid={null}
                />

                <UserEditFormModal
                    modalTitle="編集"
                    visible={this.state.editModalIsOpen}
                    handleCancel={() => {
                        this.setState({
                            editModalIsOpen: false
                        })
                    }}
                    handleOk={(fieldValue, data) => {
                        this.userEdit(fieldValue, data);
                    }}
                    uuid={this.state.editModalUUID}
                />

            </div>
        );
    }

}


export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Form.create()(UsersList))
