import React from 'react';
import {connect, store} from 'react-redux';
import PropTypes from 'prop-types';
import * as Actions from '../../redux/actions'
import { SolutionOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import {
    Modal,
    Radio,
    Input,
    Button,
    Table,
    Badge,
    Tooltip,
    Row,
    Col,
    Card,
    Progress,
} from 'antd';

import * as GlobalConfig from '../../constants/GlobalConfig';

import UserFormModal from '../users/UserViewModal';

import moment from 'moment';
import 'moment/locale/ja';
const FormItem = Form.Item;
const RadioGroup = Radio.Group;

const mapStateToProps = (state, props) => {
    return {
        questionnaireAnswerResultFreeForm: state.questionnaireAnswerResultFreeForm
    }
};
const mapDispatchToProps = dispatch => {
    return {
        getResult: (uuid,data) => {
            dispatch(Actions.AdminConnection.questionnaire.answer.freeform.request(uuid,data));
        },
        getResultUser: (uuid,data) => {
            dispatch(Actions.Connection.questionnaire.answer.freeform.request(uuid,data));
        }
    }
};

class QuestionnaireResultModalFreeInput extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            modalTitle: <span><SolutionOutlined />&nbsp;自由入力回答</span>,
            visible: props.visible,
            dataArray: [],
            totalUser: 0,
            sent_count: 0,
            userModalIsOpen: false,
            loading: false,
            questionnaireUUID: null,
            confirnLoading: false,
            pagination: {
                showTotal: (total, range) => {

                    return (`全${total}件中 ${range[0]}-${range[1]} 件`)
                },
                showSizeChanger: true,
                pageSizeOptions: GlobalConfig.PageSizeOptions,
                pageSize: 20,
                onShowSizeChange: this.onChangePageSize
            },
            isAdmin: props.isAdmin,
            isAnonymous: props.isAnonymous
        }
    }

    componentWillReceiveProps(nextProps){

        const pagination = {...this.state.pagination};



        this.setState({
            isAdmin: nextProps.isAdmin,
            data: nextProps.data,
            uuid: nextProps.uuid,
            isAnonymous: nextProps.isAnonymous
        })
        if(nextProps.visible !== this.props.visible && nextProps.visible){
            this.search();
        }

        if (nextProps.questionnaireAnswerResultFreeForm !== this.props.questionnaireAnswerResultFreeForm) {
            if (!nextProps.questionnaireAnswerResultFreeForm.meta.fetch) {
                if (!nextProps.questionnaireAnswerResultFreeForm.error) {
                    if (nextProps.questionnaireAnswerResultFreeForm.payload !== null && nextProps.questionnaireAnswerResultFreeForm.payload.hasOwnProperty("result")) {

                        pagination.total = nextProps.questionnaireAnswerResultFreeForm.payload.result.item_count;
                        this.setState({
                            loading: nextProps.questionnaireAnswerResultFreeForm.meta.fetch,
                            dataArray: nextProps.questionnaireAnswerResultFreeForm.payload.result.items,
                            pagination

                        })
                    }
                }else{

                    this.setState({
                        loading: nextProps.questionnaireAnswerResultFreeForm.meta.fetch
                    })
                }
            }else{
                this.setState({
                    loading: nextProps.questionnaireAnswerResultFreeForm.meta.fetch
                })
            }
        }

    }


    onChangePageSize = (current, pageSize) => {
        const pager = {...this.state.pagination};
        //pageSizeの更新
        pager.pageSize = pageSize;
        this.setState({
            pagination: pager,
        });
    };

    handleTableChange = (pagination, filters, sorter) => {
        const pager = {...this.state.pagination};
        this.search(pagination);
    };

    search(value) {
        this.setState({
            loading: true
        })
        let pagination;
        if (typeof value === "undefined") {
            pagination = {...this.state.pagination};
        } else {
            pagination = value;
        }

        if(this.state.uuid) {
            if(this.state.isAdmin) {
                this.props.getResult(this.state.uuid, pagination.current);
            }else{
                this.props.getResultUser(this.state.uuid, pagination.current);
            }

        }
    }

    render() {
        let columns;


        if(this.state.isAnonymous) {
            columns = [
                 {
                    title: '回答',
                    dataIndex: 'content',
                    key: 'content',
                }, {
                    title: '回答日時',
                    dataIndex: 'updated_at',
                    key: 'updated_at',
                    width: 150,
                    render: (text, record) => {
                        const update_at = text ? moment.unix(text).format("YYYY/MM/DD HH:mm") : "更新なし";
                        const created_at = moment.unix(record.created_at).format("YYYY/MM/DD HH:mm");
                        return (
                            <div>
                                {created_at}<br/>
                                {update_at}
                            </div>
                        )
                    }
                },
            ];
        }else{
            columns = [
                {
                    title: '利用者ID',
                    dataIndex: 'user_id',
                    key: 'user_id',
                    width: 110,
                    render: (text, record) => {
                        return (
                            <span>{record.user.personal_id}</span>
                        )
                    }
                }, {
                    title: '氏名',
                    dataIndex: 'name',
                    key: 'name',
                    width: 150,
                    render: (text, record) => {
                        return (
                            <span>{record.user.name}</span>
                        )
                    }
                }, {
                    title: '回答',
                    dataIndex: 'content',
                    key: 'content'
                }, {
                    title: '回答日時',
                    dataIndex: 'updated_at',
                    key: 'updated_at',
                    width: 150,
                    render: (text, record) => {
                        const update_at = text ? moment.unix(text).format("YYYY/MM/DD HH:mm") : "更新なし";
                        const created_at = moment.unix(record.created_at).format("YYYY/MM/DD HH:mm");
                        return (
                            <div>
                                {created_at}<br/>
                                {update_at}
                            </div>
                        )
                    }
                },
            ];
        }


        const footer =
            [
                <Button key="back" size="large" onClick={this.props.handleCancel}>閉じる</Button>,
            ]
        ;
        return (
            <Modal
                className="common-modal notification-status-modal"
                title={this.state.modalTitle}
                visible={this.props.visible}
                maskClosable={false}
                onOk={() => this.props.handleOk}
                confirmLoading={this.state.confirmLoading}
                onCancel={this.props.handleCancel}
                footer={footer}
            >

                <div className='wrapper'>

                    <Table
                        className="general-table"
                        locale={{
                            filterTitle: 'フィルタ',
                            filterConfirm: '確定',
                            filterReset: 'リセット',
                            emptyText: '該当するものはありません',
                        }}
                        bordered={true}
                        size="middle"
                        loading={this.state.loading}
                        onChange={this.handleTableChange}
                        columns={columns}
                        dataSource={this.state.dataArray}
                        pagination={this.state.pagination}
                    />


                </div>

            </Modal>
        );
    }
}


export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Form.create()(QuestionnaireResultModalFreeInput))

QuestionnaireResultModalFreeInput.propTypes = {
    visible: PropTypes.bool.isRequired,
    handleOk: PropTypes.func.isRequired,
    handleCancel: PropTypes.func.isRequired,
    data: PropTypes.object
};
