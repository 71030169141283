import React from 'react'
import PropTypes from 'prop-types';
import { DownloadOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Radio, Button, Popconfirm } from 'antd';

const RadioGroup = Radio.Group;

class DownloadCSVConfirm extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            visible: this.props.visible,
            encoding: "sjis",
        }
    }



    componentWillReceiveProps(nextProps) {
        this.setState({
            visible: nextProps.visible,
        });
    }

    handleOk = () => {
        if(typeof this.props.handleOk === "function") {
            this.props.handleOk(this.state.encoding);
        }
    };

    handleCancel = () => {
        if(typeof this.props.handleCancel === "function") {
            this.props.handleCancel()
        }
    };

    handleChangeEncodeValue = (e) => {
        this.setState({
            encoding: e.target.value
        });
    };

    render() {

        if(this.state.visible) {
            return (
                <Popconfirm placement="leftBottom"
                            title={
                                <div>
                                    <p>CSVをファイルダウンロードします<br />対象件数により、CSVのダウンロードには時間がかかることがあります。</p>
                                    <p>CSVファイルの文字エンコードを指定してください。Excelで表示・編集するには「ShiftJIS形式」を選択してください。</p>
                                    <p>ShiftJIS形式でダウンロードした場合は、UTF-8形式で表現される一部の文字が表示されないことがあります。</p>
                                    <div style={{padding:"10px 0px"}}>
                                        <RadioGroup defaultValue="sjis" onChange={this.handleChangeEncodeValue}>
                                            <Radio value="sjis">ShiftJIS形式</Radio>
                                            <Radio value="utf8">UTF-8形式</Radio>
                                        </RadioGroup>
                                    </div>
                                </div>}
                            onConfirm={this.handleOk}
                            onCancel={this.handleCancel}
                            okText="CSVダウンロード"
                            cancelText="キャンセル">
                    <Button type="primary" icon={<DownloadOutlined />}>
                        CSVダウンロード
                    </Button>
                </Popconfirm>
            );
        }else{
            return(null);
        }
    }
}

export default Form.create()(DownloadCSVConfirm)



DownloadCSVConfirm.propTypes = {
    visible: PropTypes.bool,
    handleOk: PropTypes.func.isRequired,
    handleCancel: PropTypes.func,
};