import React from 'react'
import {connect, store} from 'react-redux'
import PropTypes from 'prop-types';
import {instanceOf} from 'prop-types';
import * as GlobalConfig from 'constants/GlobalConfig'
import moment from 'moment';
import 'moment/locale/ja';
import { EditOutlined, PieChartOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Modal, Table, Tabs, Collapse, Card, Button, Tooltip } from 'antd';
import log from 'components/utils/Logger'
import * as TagNodeGenerator from 'components/utils/TagNodeGenerator'
import * as Actions from 'redux/actions';
import FroalaEditorView from 'react-froala-wysiwyg/FroalaEditorView';
moment.locale('ja');

const mapStateToProps = (state, props) => {
    return {
        screen: state.screen,
        session: state.session,
        questionnaire: state.questionnaireMore,
        questionnaireAnswer: state.questionnaireAnswer
    }
};

const mapDispatchToProps = dispatch => {
    return {
        requestLogout: () => {
            dispatch(Actions.Connection.logout.request());
        },
        getCategory: () => {
            dispatch(Actions.Connection.questionnaire.category.parameter.request());
        },
        searchQuestionnaire: (data, currentPageNum) => {
            dispatch(Actions.Connection.questionnaire.searchMore.request(data, currentPageNum));
        }
    }
};

class ForceModuleQuestionnaireModalList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            visible: props.visible,
            modalTitle: props.title,
            resultQuestionnaireArray: [],
            pagination: {
                showTotal: (total, range) => {
                    return (`全${total}件中 ${range[0]}-${range[1]} 件`)
                },
                showSizeChanger: true,
                pageSizeOptions: GlobalConfig.PageSizeOptions,
                pageSize: 20,
                onShowSizeChange: this.onChangePageSize,
                total: 0,
            },
            name: "",
            loading: true
        };
    }

    onChangePageSize = (current, pageSize) => {
        const pager = {...this.state.pagination};
        //pageSizeの更新
        pager.pageSize = pageSize;
        this.setState({
            pagination: pager,
        });
    }

    componentWillReceiveProps(nextProps) {
        const {paramCategory, questionnaire} = {...this.props};

        this.setState({
            visible: nextProps.visible
        });

        if (nextProps.questionnaire !== questionnaire) {
            if (!nextProps.questionnaire.meta.fetch) {
                if (!nextProps.questionnaire.error) {
                    if (nextProps.questionnaire.payload !== null) {
                        this.setState({
                            loading: nextProps.questionnaire.meta.fetch,
                            resultQuestionnaireArray: nextProps.questionnaire.payload.result.items,
                            pagination: {
                                ...this.state.pagination,
                                total: nextProps.questionnaire.payload.result.item_count
                            }
                        })
                    }else{

                    }
                }
            }
            this.setState({
                loading: nextProps.questionnaire.meta.fetch
            })
        }

        if(nextProps.session && nextProps.session.hasOwnProperty("payload") && nextProps.session.payload.hasOwnProperty("user") && nextProps.session.payload.user){
            const name = nextProps.session.payload.user.name;
            this.setState({
                name
            })
        }

        if(nextProps.questionnaireAnswer !== this.props.questionnaireAnswer){
            if(nextProps.visible) {
                this.search()
            }
        }


        if(this.props.visible !== nextProps.visible && nextProps.visible){
            this.search()
        }

    }


    componentDidMount(){

    }




    handleTableChange = (pagination, filters, sorter) => {

        this.search(pagination);
    };

    search(value,category_uuid){
        let fieldsValue = {};
        let pagination;
        if(typeof value === "undefined"){
            pagination = { ...this.state.pagination };
        }else{
            pagination = value;
        }
        fieldsValue.page_size = pagination.pageSize;
        fieldsValue.is_forced = 1;
        fieldsValue.status= "unanswered";

        //ほぼそのまま検索
        this.props.searchQuestionnaire(fieldsValue,pagination.current);
    }

    handleOk = () => {
        this.props.handleOk();
    };


    render() {
        const {visible, searchLoading} = this.state;
        const columns = [{
            dataIndex: 'key',
            key: 'key',
            render: (text, record) => {
                log.debug(record);
                const isAnsweredTag = TagNodeGenerator.generate(TagNodeGenerator.TYPES_QUESTIONNAIRE_ANSWERED,record.is_answered);
                let resultButton;
                return (
                    <div className="module-cell module-questionnaire">
                        <div className="module-cell-head module-questionnaire-head">
                            <div className="date">{moment.unix(record.publish_start_at).format("YYYY.MM.DD")} - {moment.unix(record.publish_end_at).format("YYYY.MM.DD")}</div>
                            <div className="tags-wrapper">
                                {isAnsweredTag}
                            </div>
                        </div>
                        <div className="module-cell-body module-questionnaire-body">{record.name}</div>
                        <div className="publish-from-wapper">{record.publish_from}</div>
                        <FroalaEditorView model={record.content} />
                        <div style={{textAlign:"right",paddingTop:"5px"}}>
                            <Button type="primary" size={"small"} icon={<EditOutlined />} onClick={() => {this.props.handleRowClick(record)}}>回答する</Button>&nbsp;
                            {resultButton}
                        </div>
                    </div>
                );
            }
        }];

        const answeredArray = this.state.resultQuestionnaireArray.filter((element,index,array) => {
            return !element.is_answered
        });

        const footer = answeredArray.length > 0 ?
            [
                <Button key={"logout"} size="large" type="primary" style={{marginRight: 8 }} onClick={()=>{this.props.requestLogout()}}>ログアウト</Button>,
                <Tooltip placement="topLeft" title={"回答必須アンケートに全て答える必要があります"}><Button key="back" size="large" disabled onClick={this.handleOk}>閉じる</Button></Tooltip>]
            :
            [<Button key="back" size="large" onClick={this.handleOk} loading={this.state.loading}>閉じる</Button>];

        return (
            <Modal
                className="common-modal"
                zIndex={900}
                style={{top: 20}}
                title={<span><PieChartOutlined />&nbsp;{this.state.name}さんの回答必須アンケート</span>}
                visible={visible}
                maskClosable={false}
                closable={false}
                footer={footer}>
                <div className='wrapper questionnaire-modal-list'>
                    <h4>インフォメーションシステムを利用する前に下記のアンケートに回答する必要があります。</h4>
                    <Table
                        loading={this.state.loading}
                        className="general-table"
                        locale={{
                            filterTitle: 'フィルタ',
                            filterConfirm: '確定',
                            filterReset: 'リセット',
                            emptyText: '該当するものはありません',
                        }}
                        pagination={this.state.pagination}
                        showHeader={false}
                        columns={columns}
                        dataSource={this.state.resultQuestionnaireArray}
                        size="small"
                        onChange={this.handleTableChange}
                    />
                </div>
            </Modal>
        );
    }
}


ForceModuleQuestionnaireModalList.propTypes = {
    handleOk: PropTypes.func.isRequired
};


export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ForceModuleQuestionnaireModalList)
