import React from 'react'
import {connect, store} from 'react-redux'
import {instanceOf} from 'prop-types';
import log from '../../components/utils/Logger.js'

import moment from 'moment';
import 'moment/locale/ja';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Tabs, Collapse } from 'antd';
import ContentHeader from "../../components/ContentHeader";
import ComingSoon from "../../components/ComingSoon"
import MasterPassChange from './MasterPassChange';
import LoginMessageComponent from "./login-message-setting/LoginMessageComponent";


moment.locale('ja');

const TabPane = Tabs.TabPane;
const FormItem = Form.Item;
const Panel = Collapse.Panel;

const mapStateToProps = (state, props) => {
    return {
    }
}

const mapDispatchToProps = dispatch => {
    return {
    }
}

class Maintenance extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
        };
    }


    onChangeTab = (key) =>{

    }

    render() {
        return (
            <div className='container clearfix' id="notification">
                <ContentHeader title="メンテナンス" breadCrumbItems={[
                    {title:"メンテナンス",icon:"setting",path:"/maintenance/"}
                ]}/>
                <Tabs
                    className="content-tab"
                    defaultActiveKey="1"
                    onChange={this.onChangeTab}
                    animated={false}>

                    <TabPane tab="マスターパスワード変更" key="1">
                        <MasterPassChange />
                    </TabPane>

                    <TabPane tab="ログイン後メッセージ" key="2">
                        <LoginMessageComponent />
                    </TabPane>
                </Tabs>
            </div>
        );
    }
}


Maintenance.propTypes = {

};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Maintenance)
