import React from 'react'
import ReactDOM from 'react-dom';
import {connect, store} from 'react-redux'
import {instanceOf} from 'prop-types';
import * as Actions from '../../redux/actions'
import * as Constants from '../../constants/api'
import log from '../../components/utils/Logger.js'
import Progress from "react-progress-2";
import LoginGuestNotification from './LoginGuestNotification'
import {withRouter} from "react-router-dom";

import Cookies from 'universal-cookie';

import ContentHeader from "../../components/ContentHeader";

import * as GlobalConfig from '../../constants/GlobalConfig';

import Helmet from 'react-helmet'

import moment from 'moment';
import 'moment/locale/ja';
import {LockOutlined, QuestionCircleOutlined, UserOutlined, WarningOutlined} from '@ant-design/icons';
import {Form} from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import {Input, Button, Checkbox, Select, Modal} from 'antd';

import Footer from '../../components/Footer';
import Message from 'containers/message/Message'
//ログイン画面
import 'sass/Login.scss';
import ForceModuleQuestionnaire from "../services/modules/questionnaire/ForceModuleQuestionnaire";
import ModuleLoginMessage from "../services/modules/login/ModuleLoginMessage";
import LoginSso from "./LoginSso";
import LoginForm from "./LoginForm";
import LoginEffectComponent from "./LoginEffectComponent";

moment.locale('ja');
const FormItem = Form.Item;
const Option = Select.Option;

const mapStateToProps = (state, props) => {
    return {
        screen: state.screen,
        login: state.login,
        session: state.session,
        checkFirst: state.checkFirst,
        previewURL: state.previewURL
    }
}

const mapDispatchToProps = dispatch => {
    return {
        requestLogin: data => {
            dispatch(Actions.Connection.login.request("post", data));
        },
        locationChange: data => {

        },
        checkLoggedInFirst: () => {
            dispatch(Actions.http.connection.auth.checkLoggedIn());
        },
        windowResize: (dataHeight, dataWidth) => {
            dispatch(Actions.Config.screen(dataHeight, dataWidth))
        }

    }
}

class Login extends React.Component {

    constructor(props) {
        super(props);
        props.checkLoggedInFirst();
        this.state = {
            items: [],
            checkLoading: false,
            buttonState: '',
            _notificationSystem: null,
            component: 'loading',
            login_failed: false,
            account_id_error_text: "",
            account_pass_error_text: "",
            date: moment('2017/01/01', "YYYY/MM/DD"),
            passwordForgetModal: false,
            show_force_questionnaire: true,
            has_force_questionnaire: false,
            show_login_message: false,
            has_login_message: false,
            login_message: {},


            server_error_modal_is_open: false
        };

        this.props.windowResize(window.innerHeight, window.innerWidth);
        window.addEventListener('resize', () => {
            this.props.windowResize(window.innerHeight, window.innerWidth);
        });

    }


    componentWillMount() {
        document.body.classList.add('login-body');
        if (this.props.session.payload.hasOwnProperty("isAuthenticated")) {
            if (this.props.session.payload.isAuthenticated) {
                if (this.props.session.payload.user.has_force_questionnaire) {
                    this.setState({
                        has_force_questionnaire: this.props.session.payload.user.has_force_questionnaire
                    })
                } else {
                }
            }
        }
    }

    componentWillUnmount() {
        document.body.classList.remove('login-body');
    }

    componentDidMount() {
    }


    componentWillReceiveProps(nextProps) {
        this.setState({
            checkLoading: nextProps.checkFirst.meta.fetch
        });
        if (!nextProps.checkFirst.meta.fetch) {
            if (!nextProps.checkFirst.error) {
                if (nextProps.checkFirst.payload !== null) {
                    this.setState({
                        checked: true,
                        loggedIn: nextProps.checkFirst.payload.is_successful
                    })
                    //ログイン自体に成功している場合dashboardに飛ばす
                    if (nextProps.checkFirst.payload.is_successful) {
                        if (
                            nextProps.previewURL.payload !== "" && nextProps.previewURL.payload !== "/login") {
                            nextProps.history.push(nextProps.previewURL.payload);
                        } else {
                            nextProps.history.push("/dashboard")
                        }
                    }
                }
            }
        }
        if (this.props.session !== nextProps.session) {
            if (nextProps.session.payload.hasOwnProperty("isAuthenticated") && nextProps.session.payload.isAuthenticated) {
                if (nextProps.session.payload.user.has_force_questionnaire || nextProps.session.payload.result.login_message) {
                    this.setState({
                        has_force_questionnaire: nextProps.session.payload.user.has_force_questionnaire,
                        show_login_message: !nextProps.session.payload.user.has_force_questionnaire,
                        has_login_message: typeof nextProps.session.payload.result.login_message !== "undefined" && nextProps.session.payload.result.login_message !== null,
                        login_message: nextProps.session.payload.result.login_message
                    })
                } else {
                    if (this.props.previewURL.payload !== "" && this.props.previewURL.payload !== "/login") {
                        this.props.history.push(this.props.previewURL.payload);
                    } else {
                        this.props.history.push("/dashboard")
                    }
                }
            }
        }


        if (this.props.login !== nextProps.login) {
            if (nextProps.login.payload.code === 503) {
                this.setState({
                    server_error_403_modal: true
                })
            }
        }
    }


    handleOpenAdvice = () => {
        this.setState({
            passwordForgetModal: true
        });
    }


    render() {
        const {
            show_force_questionnaire,
            has_force_questionnaire,
            show_login_message,
            has_login_message,
            checkLoading
        } = this.state;

        if (checkLoading) {
            return null;
        } else {

            return (
                <div className='container clearfix' id="login">
                    <Helmet>
                        <title>{GlobalConfig.LoginTitle}</title>
                    </Helmet>
                    <Progress.Component/>
                    <ContentHeader
                        title={GlobalConfig.LoginTitle}
                        breadCrumbItems={[
                            {title: "ログイン", icon: "lock", path: "/login/"}
                        ]}
                        disableHomeLink={true}
                    />
                    <div id="login-body">
                        <div id="login-left">
                            <LoginGuestNotification/>
                        </div>
                        <div id="login-right">
                            {(() => {
                                switch (this.props.session.payload.authenticatedFrom) {
                                    case "sso":
                                        return (
                                            <LoginSso/>
                                        )
                                        break;
                                    default:
                                        return (
                                            <div className="card login-form">
                                                <Progress.Component/>
                                                {has_force_questionnaire || has_login_message ?
                                                    null
                                                    :
                                                    <LoginForm
                                                        handleOpenAdvice={this.handleOpenAdvice}
                                                    />
                                                }
                                            </div>
                                        )
                                }
                            })()}
                            {(() => {
                                if (GlobalConfig.LoginAd != null) {
                                    return (
                                        <div className="card ad-form">
                                            {GlobalConfig.LoginAd}
                                        </div>
                                    );
                                }
                            })()}

                        </div>
                    </div>
                    <Footer/>
                    <Message/>
                    <Modal
                        className="common-modal select-target-form login-password-forget-modal"
                        title={<span><QuestionCircleOutlined/> ログインでお困りの方</span>}
                        visible={this.state.passwordForgetModal}
                        onCancel={
                            () => {
                                this.setState({
                                    passwordForgetModal: false,
                                });
                            }
                        }
                        footer={[
                            <Button key="submit" type="primary" size="large" onClick={
                                () => {
                                    this.setState({
                                        passwordForgetModal: false,
                                    });
                                }
                            }>
                                閉じる
                            </Button>,
                        ]}
                    >
                        <div className='wrapper'>
                            {GlobalConfig.LoginPasswordForget}
                        </div>
                    </Modal>

                    <Modal
                        className="common-modal select-target-form"
                        title={<span><WarningOutlined/> ログイン制限</span>}
                        visible={this.state.server_error_403_modal}
                        onCancel={
                            () => {
                                this.setState({
                                    server_error_403_modal: false,
                                });
                            }
                        }
                        footer={[
                            <Button key="submit" type="primary" size="large" onClick={
                                () => {
                                    this.setState({
                                        server_error_403_modal: false,
                                    });
                                }
                            }>
                                閉じる
                            </Button>,
                        ]}
                    >
                        <div className='wrapper'>
                            {GlobalConfig.LoginServerError}
                        </div>
                    </Modal>


                    {/* 強制アンケートが優先、has_login_message を */}
                    {show_force_questionnaire ?
                        <ForceModuleQuestionnaire exitForceModuleQuestionnaire={() => {
                            this.setState({
                                show_force_questionnaire: false
                            });
                            if (!has_login_message) {
                                this.props.history.push("/dashboard")
                            } else {
                                this.setState({
                                    show_login_message: true
                                })
                            }
                        }}/>
                        :
                        null
                    }

                    {show_login_message ?
                        <ModuleLoginMessage visible={has_login_message} data={this.state.login_message}/>
                        :
                        null
                    }

                </div>
            );
        }
    }
}


Login.propTypes = {};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Form.create()(withRouter(Login)))
