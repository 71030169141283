import React from 'react'
import {connect, store} from 'react-redux'
import PropTypes from 'prop-types';
import {instanceOf} from 'prop-types';
import log from '../../../../components/utils/Logger.js'

import moment from 'moment';
import 'moment/locale/ja';

import {
    ExceptionOutlined,
    FileOutlined,
    FolderOutlined,
    InboxOutlined,
    LoadingOutlined,
    UpOutlined,
} from '@ant-design/icons';

import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';

import { Table, Tabs, Collapse, Card, Button } from 'antd';
import * as Actions from 'redux/actions';

import ModuleFileShareModalList from './ModuleFileShareModalList';
import FileShareAddFileModal from "../../../fileshare/FileShareAddFileModal";

import {Scrollbars} from 'react-custom-scrollbars';
import * as GlobalConfig from "../../../../constants/GlobalConfig";
import FileShareAgreementModal from "./FileShareAgreementModal";
import {fileShareConfirmAgreement} from "../../../../redux/reducers";

moment.locale('ja');

const TabPane = Tabs.TabPane;
const FormItem = Form.Item;
const Panel = Collapse.Panel;

const mapStateToProps = (state, props) => {
    return {
        screen: state.screen,
        menu: state.menu,
        fileShareSearch: state.fileShareSearch,
        fileShareConfirmAgreement: state.fileShareConfirmAgreement
    }
};

const mapDispatchToProps = dispatch => {
    return {
        searchFile: (data, currentPageNum, uuid, moduleUUID) => {
            log.debug(moduleUUID);
            dispatch(Actions.Connection.fileshare.search.request(data, currentPageNum, uuid, moduleUUID));
        }
    }
};

class ModuleFileShare extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            data: props.data,
            parts: props.parts,
            fileShareArray: [],
            loading: false,
            folderMeta: null,
            agreementModalIsOpen: false,
            currentUUID: props.parts.hasOwnProperty("shared_folder_uuid") ? props.parts.shared_folder_uuid : "",
            //keyword
        };
        this.search();
    }

    componentWillMount() {
        //this.props.searchSSO();
    }

    componentWillReceiveProps(nextProps) {
        const {fileShareSearch,fileShareConfirmAgreement} = this.props;
        log.debug(nextProps.data);
        this.setState({
            data: nextProps.data,
            parts: nextProps.parts,
            currentUUID: nextProps.parts.hasOwnProperty("shared_folder_uuid") ? nextProps.parts.shared_folder_uuid : ""
        });
        if (this.props.data !== nextProps.data) {
            this.search();
        }
        if (nextProps.fileShareSearch !== fileShareSearch) {
            log.debug(nextProps.fileShareSearch);
            if (!nextProps.fileShareSearch.meta.fetch) {
                if (!nextProps.fileShareSearch.error) {
                    if (nextProps.fileShareSearch.payload !== null) {
                        if(nextProps.fileShareSearch.meta.module_id === this.state.data.uuid){
                            this.setState({
                                fileShareArray: nextProps.fileShareSearch.payload.result.items,
                                folderMeta: nextProps.fileShareSearch.payload.result.folder_meta,
                            })
                        }
                    }
                } else {
                    if (nextProps.fileShareSearch.payload !== null) {
                        if (nextProps.fileShareSearch.payload.code === 412) {
                            this.setState({
                                agreementVisible: true
                            })
                        }
                    }
                }
            }
            this.setState({
                loading: nextProps.fileShareSearch.meta.fetch
            })
        }


        if(nextProps.fileShareConfirmAgreement !== fileShareConfirmAgreement){
            if (!nextProps.fileShareConfirmAgreement.meta.fetch) {
                if (!nextProps.fileShareConfirmAgreement.error) {
                    this.setState({
                        agreementVisible: false
                    })
                    this.search();
                }
            }
            this.setState({
                loading: nextProps.fileShareConfirmAgreement.meta.fetch
            })
        }

        if (this.state.searchLoading !== nextProps.menu.meta.fetch) {
            this.setState({
                searchLoading: nextProps.menu.meta.fetch
            })
        }
    }

    handleClickSeeMore = () => {
        this.setState({
            listModalIsOpen: true
        })
    };


    handleRowClick = (record, index, event) => {
        this.setState({
            singleModalIsOpen: true,
            selectRowData: record,
            selectRowTitle: record.title
        })
    };


    search = (value) => {
        let fieldsValue = {};
        let pagination;
        if (typeof value === "undefined") {
            pagination = {...this.state.pagination};
        } else {
            pagination = value;
        }
        fieldsValue.page_size = pagination.pageSize;

        this.props.searchFile(fieldsValue, pagination.current, this.state.currentUUID, this.state.data.uuid);
    };


    moveDirectory = (uuid) => {
        this.setState({
            currentUUID: uuid
        }, () => {
            this.search();
        })

    };


    render() {
        const titleNode = (
            <div>
                <span className="module-title">{this.props.title}</span>
                {this.props.seeMore && !this.state.agreementVisible ?
                    <Button className="module-title-button" onClick={this.handleClickSeeMore}>すべて表示</Button> :
                    <span/>}
            </div>
        );

        const columns = [{
            title: '氏名',
            dataIndex: 'name',
            key: 'name',
            onCellClick: (record, event) => {
                if (!record.is_file) {
                    this.moveDirectory(record.uuid)
                } else {
                    window.open(`${process.env.REACT_APP_FILE_DOMAIN}/shared-file/view/${record.uuid}`, '_blank');
                }
            },
            render: (text, record, index) => {
                let fileName = "";
                if (!record.is_file) {
                    fileName = (
                        <div className={"file-share-list-row"}>
                            <FolderOutlined style={{fontSize: 17, color: '#08c', marginRight: '5px'}} />{text}
                        </div>
                    );
                } else {
                    fileName = (
                        <div className={"file-share-list-row"}>
                            <FileOutlined style={{fontSize: 17, color: '#08c', marginRight: '5px'}} />{text}
                        </div>
                    );
                }
                return fileName
            }
        }, {
            title: "摘要",
            dataIndex: "description",
            key: 'description',
            onCellClick: (record, event) => {
                if (!record.is_file) {
                    this.moveDirectory(record.uuid)
                }
            },
            render: (text, record) => {
                return (
                    <div>
                        {record.description}
                    </div>
                )
            }
        }];

        let upParentFolder = null;
        log.debug(this.state.folderMeta);
        if (this.state.folderMeta && this.state.folderMeta.parent) {
            log.debug(this.state.folderMeta);
            upParentFolder = (
                <div className="button-container">
                    <div className={"button-container-left"}>
                        <div>
                            <Button onClick={() => this.moveDirectory(this.state.folderMeta.parent.uuid)} icon={<UpOutlined />}>
                                上のフォルダへ
                            </Button>
                        </div>
                    </div>
                </div>
            )
        }


        return (
            <Card className="module-card" title={titleNode}>
                {this.state.agreementVisible ?
                    <div id="general-alert-content" className={"general-alert-content-module"}>
                        <div className="explain-box">
                            <div className="explain-box-left">
                                <ExceptionOutlined className="icon" />
                            </div>
                            <div className="explain_box-right">
                                <div>
                                    <h3>規約に同意する必要があります</h3>
                                    <Button onClick={() =>
                                        this.setState({
                                            agreementModalIsOpen: true
                                        })}>
                                        規約に同意する
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </div>
                    :
                    <Scrollbars autoHide>
                        <div className="module-inner module-file-share">
                            <Table
                                className="general-table"
                                locale={{
                                    filterTitle: 'フィルタ',
                                    filterConfirm: '確定',
                                    filterReset: 'リセット',
                                    emptyText: this.state.searchLoading ?
                                        <span><LoadingOutlined />&nbsp;読み込み中</span> :
                                        <span><InboxOutlined />&nbsp;ファイル/フォルダは存在しません</span>,
                                }}
                                pagination={false}
                                bordered={true}
                                showHeader={false}
                                columns={columns}
                                dataSource={this.state.fileShareArray}
                                title={() => {
                                    return upParentFolder
                                }}
                                size="small"
                                onRow={(record, rowIndex) => {
                                    return {
                                        onClick: event => {
                                            this.handleRowClick(record)
                                        }
                                    };
                                }}
                            />
                        </div>
                    </Scrollbars>
                }

                <ModuleFileShareModalList
                    visible={this.state.listModalIsOpen}
                    currentUUID={this.state.currentUUID}
                    handleCancel={() => {
                        this.setState({
                            listModalIsOpen: false
                        })
                    }}
                    handleOk={() => {
                        this.setState({
                            listModalIsOpen: false
                        })
                    }}
                />
                <FileShareAgreementModal
                    visible={this.state.agreementModalIsOpen}
                    onCancel={() => {
                        this.setState({
                            agreementModalIsOpen: false
                        })
                    }}
                />
            </Card>
        );
    }
}


export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ModuleFileShare)
