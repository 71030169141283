import React from "react";
import {Tree} from "antd";
const TreeNode = Tree.TreeNode;

export const generate = (data,searchValue) => {
    return data.map((item) => {
        if(item.is_active === 0){
            return null
        }
        const index = item.name.indexOf(searchValue);
        const beforeStr = item.name.substr(0, index);
        const afterStr = item.name.substr(index + searchValue.length);
        const title = index > -1 ? (
            <span>
                    {beforeStr}
                <span style={{color: '#f50'}}>{searchValue}</span>
                {afterStr}
                </span>
        ) : <span>{item.name}</span>;
        if (item.children.length !== 0) {
            return (
                <TreeNode key={item.uuid} title={title}>
                    {generate(item.children,searchValue)}
                </TreeNode>
            );
        }
        return <TreeNode key={item.uuid} title={title}/>;
    });
}