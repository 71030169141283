import React, {useEffect, useRef, useState} from 'react';
import PropTypes from 'prop-types';
import {useSelector} from "react-redux";
import {message, Modal} from 'antd';

ErrorNoticeComponent.propTypes = {};

function isEmpty(obj) {
    return obj ? !Object.keys(obj).length : true;
}

function analyzeJSON(data, callback) {
    for (let key in data) {
        if (typeof data[key] === "object") {
            analyzeJSON(data[key], callback);
        } else {
            callback(data[key])
        }
    }
}

function replaceAlertMessage(string){
    let replacedString = "";
    const loginIdRegex = /ログインID/gi;
    replacedString = string.replace(loginIdRegex, "ユーザーID");

    const urlRegex = /Url/gi;
    replacedString = replacedString.replace(urlRegex, "URL");

    return replacedString;
}


function ErrorNoticeComponent(props) {
    const [isOpen, setIsOpen] = useState(false)
    const isFirstRender = useRef(false)
    const failure = useSelector(state => state.failure);

    useEffect(() => {
        //refを使ってfirstRenderを制御
        isFirstRender.current = true;
    }, []);

    useEffect(() => {
        if (!isFirstRender.current) {
            if (failure.meta.isShow && !isOpen) {
                let content = [];
                if (isEmpty(failure.payload)) {
                    content.push("要求エラー: エラーが発生しました")
                } else {
                    if (failure.payload?.result.message) {
                        content.push(replaceAlertMessage(failure.payload.result.message))
                    }

                    if (failure.payload.result.errors) {
                        analyzeJSON(failure.payload.result.errors, (rVal) => {
                            content.push(replaceAlertMessage(rVal))
                        })
                    }
                }
                message.error(content)

                //FIXME 403コード関連が新旧仕様で分離しているので危険かもしれない
                //どこかできれいにする必要が間違いなくある
                if(failure.payload.hasOwnProperty("code") && failure.payload.code === 403){
                    document.location.href = "/login";
                }
                /*
                setIsOpen(true)
                Modal.error({
                    title: 'エラー',
                    content: (
                        <div>
                            <br/>
                            {content.map((v,index) => {
                                return <p key={index}>{v}</p>
                            })}
                        </div>
                    ),
                    onOk() {
                        setIsOpen(false)
                    }
                });
                */
            }


        } else {
            isFirstRender.current = false;
        }
    }, [failure])


    return null
}

export default ErrorNoticeComponent;