import React from 'react'
import {connect, store} from 'react-redux'
import {instanceOf} from 'prop-types';
import log from 'components/utils/Logger.js'
import * as Actions from '../../redux/actions';
import {withRouter} from 'react-router-dom';
import moment from 'moment';
import 'moment/locale/ja';
import { LoadingOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Tabs, Collapse } from 'antd';
import ContentHeader from "../../components/ContentHeader";
import ModuleWrapper from '../../containers/services/ModuleWrapper'
import * as GlobalConfig from '../../constants/GlobalConfig';
import NoContent from "../../components/NoContent";

import Helmet from 'react-helmet';

moment.locale('ja');

const TabPane = Tabs.TabPane;
const FormItem = Form.Item;
const Panel = Collapse.Panel;

const mapStateToProps = (state, props) => {
    return {
        screen: state.screen,
        menu: state.menu,
        paramMenu: state.paramMenu
    }
};

const mapDispatchToProps = dispatch => {
    return {}
};

class DashboardHome extends React.Component {
    constructor(props) {
        log.debug(props.match);
        super(props);
        const {pathname} = props.match.url;
        let uuid;
        if (props.isHome) {
            uuid = "/"
        } else {
            uuid = props.match.params.uuid;
        }

        log.debug(props.match, "menuコンストラクタ");
        this.state = {
            uuid: uuid,
            title: "",
            menuListArray: props.paramMenu.payload.menu,
            menuListObject: props.paramMenu.payload.object,
            menuDetail: {}
        };
    }

    componentWillReceiveProps(nextProps) {
        console.log(nextProps.paramMenu)
        if (nextProps.paramMenu !== this.props.paramMenu) {
            if (nextProps.paramMenu.payload !== null) {
                let uuid;
                if (nextProps.isHome) {
                    uuid = "/"
                } else {
                    uuid = nextProps.match.params.uuid;
                }
                this.generateModuleData(uuid, nextProps.paramMenu.payload,nextProps);
            }
        }

        if (nextProps.match !== this.props.match) {
            let uuid;
            if (nextProps.isHome) {
                uuid = "/"
            } else {
                uuid = nextProps.match.params.uuid;
            }
            this.generateModuleData(uuid, nextProps.paramMenu.payload,nextProps);
        }

    }

    generateModuleData = (uuid, payload, nextProps) => {
        let tempUuid = uuid;
        const menuListArray = payload.menu;
        const menuListObject = payload.object;

        if (!nextProps.isHome && menuListArray.length !== 0 && !menuListObject.hasOwnProperty(tempUuid)) {
            //パラメータが存在しないので404へ
            this.props.history.push("/dashboard/404");
            return;
        }

        if (menuListArray.length !== 0) {
            if (nextProps.isHome) {
                tempUuid = menuListArray[0].uuid;
            }
            const menuDetail = menuListObject.hasOwnProperty(tempUuid) ? menuListObject[tempUuid] : {};

            const title = menuDetail.hasOwnProperty("name") ? menuDetail.name : "";

            this.setState({
                menuListArray,
                menuListObject,
                menuDetail,
                title
            })

        }
    };


    onChangeTab = (key) => {

    };

    render() {
        let component;
        let test = this.state.menuDetail;


        if (this.props.menu.meta.fetch) {
            component = (<div style={{padding:"200px 10px",textAlign:"center"}}><LoadingOutlined /> コンテンツを読み込み中...</div>);
        } else {
            if (Object.keys(this.state.menuDetail).length === 0) {
                component = (
                    <NoContent/>
                );
            } else {
                component = (
                    <ModuleWrapper menuDetail={test}/>
                );
            }
        }
        return (
            <div className='container clearfix' id="dashboard-home">
                <Helmet>
                    <title>{this.state.title} - {GlobalConfig.ServiceTitle}</title>
                </Helmet>
                <ContentHeader title={this.state.title} breadCrumbItems={[]}/>
                {component}
            </div>
        );
    }
}


export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(DashboardHome))
