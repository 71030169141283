import React, { Component } from 'react'
import { MessageOutlined } from '@ant-design/icons';
import { Button } from 'antd';

class RedirectComponent extends Component {
    render() {
        return (
            <div id="redirect">
                <div className="explain-box">
                    <div className="explain-box-left">
                        <MessageOutlined className="redirect-icon" />
                    </div>
                    <div className="explain_box-right">
                        <h3>11月27日からインフォメーションシステムが新しくなりました。</h3>
                        <p>URLが変更になっておりますので、これまでインフォメーションシステムのURLをブックマークやお気に入りに登録している場合は、以下の新しいURLに変更してください。</p>
                        <br/>
                        <p>関西大学インフォメーションシステムURL<br/><a href="/">https://info.kansai-u.ac.jp</a></p>
                        <Button type="primary" className="button" onClick={()=>{window.location.href=("/")}}>
                            関西大学インフォメーションシステムへ
                        </Button>
                    </div>
                </div>

            </div>
        );
    }
}

export default RedirectComponent