import axios from "axios";
import {generateApiDomain} from "../../constants/GlobalConfig";
import * as url from "../../constants/api";



const apiAccess = axios.create({
});

apiAccess.interceptors.request.use((config) => {
    config.headers = {'Content-Type':'application/json'};
    config.timeout = 30000;
    config.responseType = 'json';
    config.baseURL = "/";
    config.withCredentials = true;
    return config;
}, (error) => {
    return Promise.reject(error);
});


const apiLongTimeoutAccess = axios.create({
});
apiLongTimeoutAccess.interceptors.request.use((config) => {
    config.headers = {'Content-Type':'application/json'};
    config.responseType = 'json';
    config.timeout= 0;
    config.baseURL = "/";
    config.withCredentials = true;
    return config;
}, (error) => {
    return Promise.reject(error);
});



export { apiAccess,apiLongTimeoutAccess };