import React from 'react';
import {connect, store} from 'react-redux';
import PropTypes from 'prop-types';
import * as Actions from '../../redux/actions'
import { SearchOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import {
    Modal,
    Radio,
    Input,
    Button,
    Table,
    Badge,
    Tooltip,
    Row,
    Col,
    Card,
    Progress,
} from 'antd';
import log from 'components/utils/Logger';
import * as GlobalConfig from '../../constants/GlobalConfig';
import UserViewModal from "../users/UserViewModal";

import DownloadCSVConfirm from '../../containers/common/DownloadCSVConfirm'

import * as url from '../../constants/api';
const FormItem = Form.Item;
const RadioGroup = Radio.Group;



const mapStateToProps = (state, props) => {
    return {
        screen: state.screen,
        userSearchTarget: state.userSearchTarget,
        userSearchPermission: state.userSerchPermission
    }
};

const mapDispatchToProps = dispatch => {
    return {
        searchUsers: (data, currentPageNum)  => {
            dispatch(Actions.AdminConnection.user.searchTarget.request({...data,is_login_enabled: 1}, currentPageNum) );
        }
    }
};

class SelectTargetPreview extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            type: props.type,
            self_uuid: props.self_uuid,
            viewModalUUID:null,
            viewUserModalIsOpen: false,
            selectedRowKeys: props.checkedKeys,
            selectedRows: props.checkedRows,
            userList: [],
            pagination: {
                showTotal: (total, range) => {
                    return (`全${total}件中 ${range[0]}-${range[1]} 件`)
                },
                showSizeChanger: true,
                pageSizeOptions: GlobalConfig.PageSizeOptions,
                pageSize: 20,
                onShowSizeChange: this.onChangePageSize
            },
            visible: props.visible,
            modalTitle: "選択中の対象者確認"
        }
    }



    componentWillReceiveProps(nextProps) {
        const pagination = { ...this.state.pagination };
        this.setState({
            type: nextProps.type,
            selectedRowKeys: nextProps.checkedKeys,
            selectedRows: nextProps.checkedRows,
            self_uuid: nextProps.self_uuid,
            visible: nextProps.visible
        });


        if (nextProps.userSearchTarget !== this.props.userSearchTarget) {
            if (!nextProps.userSearchTarget.meta.fetch) {
                if (!nextProps.userSearchTarget.error) {
                    if (nextProps.userSearchTarget.payload !== null) {
                        pagination.total = nextProps.userSearchTarget.payload.result.item_count;
                        this.setState({
                            loading: nextProps.userSearchTarget.meta.fetch,
                            userSearchTargetListArray: nextProps.userSearchTarget.payload.result.items,
                            //通信後
                            pagination
                        })
                    }
                }else{
                    this.setState({
                        loading: nextProps.userSearchTarget.meta.fetch
                    })
                }
            } else {
                this.setState({
                    loading: nextProps.userSearchTarget.meta.fetch
                })
            }
        };

        if(nextProps.visible !== this.props.visible){
            //Paginationのリセット
            this.setState({
                pagination: {
                    showTotal: (total, range) => {
                        return (`全${total}件中 ${range[0]}-${range[1]} 件`)
                    },
                    showSizeChanger: true,
                    pageSizeOptions: GlobalConfig.PageSizeOptions,
                    pageSize: 20,
                    onShowSizeChange: this.onChangePageSize
                },
            })
            this.search(undefined, nextProps.self_uuid);
        }
    }

    onChangePageSize = (current, pageSize) => {
        const pagination = {...this.state.pagination};
        //pageSizeの更新
        pagination.pageSize = pageSize;
        this.setState({
            pagination
        });
    };


    search(value,uuid) {

        let fieldsValue = {};
        let pagination;

        if (typeof value === "undefined") {
            pagination = {...this.state.pagination};
        } else {
            pagination = value;
        }

        fieldsValue.page_size = pagination.pageSize;
        if(this.state.type === "target") {
            fieldsValue.target_uuid = uuid;
        }else{
            fieldsValue.permission_uuid = uuid;
        }
        log.debug(fieldsValue,"FieldsValue");

        //ターゲット絞込ができないと駄目なので
        if(uuid) {
            this.props.searchUsers(fieldsValue, pagination.current);
        }
    }


    handleTableChange = (pagination, filters, sorter) => {
        this.search(pagination,this.props.self_uuid);
    };

    onSubmit = (e) => {
        e.preventDefault();
        this.search();
        return false;
    };

    handleResetSearchForm = () => {
        this.props.form.resetFields();
    };

    handleOk = (fieldValue,data) => {
        //バリデーション
        this.props.form.validateFields((err, values) => {
            if (!err) {
                this.setState({
                    confirmLoading: true,
                });
                this.props.handleOk(fieldValue,data);
            }
        });

    };

    handleCancel = () => {
        this.props.handleCancel();
    };

    handleOpenUserModal = (record) => {
        this.setState({
            viewUserModalIsOpen : true,
            viewModalUUID: record.uuid,
        });
    };

    handleDownloadCSV = (encoding) => {
        let urlString = `${url.API_DOMAIN}${url.API_ADMIN_ACTION_USER}/download-csv?${this.state.type}_uuid=${this.state.self_uuid}&encoding=${encoding}&is_login_enabled=1`;
        window.open(urlString);
    }

    render() {
        const {visible, confirmLoading} = this.state;

        const formItemLayout = {
            labelCol: {
                xs: {span: 24},
                sm: {span: 4},
            },
            wrapperCol: {
                xs: {span: 24},
                sm: {span: 20},
            },
        };

        const {getFieldDecorator} = this.props.form;

        const stubData = [

        ];

        const columns = [
            {
                title: '利用者ID',
                dataIndex: 'user_id',
                key: 'user_id',
                width: "110px",
            },  {
                title: <span>学籍番号<br/>10桁コード</span>,
                dataIndex: 'personal_id',
                key: 'personal_id',
                width: "110px"
            }, {
                title: <span>学籍番号／<br/>教職員表示番号</span>,
                dataIndex: 'option_1',
                key: 'option_1',
                width: "110px"
            },  {
                title: '氏名',
                dataIndex: 'name',
                key: 'name',
            }, {
                title: '氏名(カナ/英)',
                dataIndex: 'name_kana',
                key: 'name_kana',
                width: "200px",
                render: (text, record) => {
                    return (
                        <div>
                            {record.name_kana}<br/>
                            {record.name_alphabet}
                        </div>
                    )
                }
            }, {
                title: "",
                width: "85px",
                key: 'action',
                render: (text, record) => {
                    return (
                        <Row type="flex" justify="space-around" align="middle">
                            <Col>
                                <Tooltip placement="topLeft" title="ユーザ情報">
                                    <Button type="default" shape="circle" icon={<SearchOutlined />} onClick={() => this.handleOpenUserModal(record)}/>
                                </Tooltip>
                            </Col>
                        </Row>
                    );
                },
            }
        ];

        const footer =
            [
                <Button key="back" size="large" onClick={this.handleCancel}>閉じる</Button>,
            ]
        ;
        return (
            <Modal
                className="common-modal notification-status-modal"
                title={this.state.modalTitle}
                visible={visible}
                maskClosable={false}
                footer={footer}
                confirmLoading={confirmLoading}
                onCancel={this.handleCancel}>

                <div className='wrapper'>


                    <div className="card general-search">
                        <p>現在選択されている対象者リストです。<br />グループ属性に一致する利用者及び個別に選択された利用者が含まれます。</p>
                    </div>


                    <div className="table-header-wrapper">
                        <div className="button-container">
                            <DownloadCSVConfirm
                                visible={true}
                                handleOk={(encoding)=> this.handleDownloadCSV(encoding)} />
                        </div>
                    </div>


                    <Table
                        className="general-table"
                        locale={{
                            filterTitle: 'フィルタ',
                            filterConfirm: '確定',
                            filterReset: 'リセット',
                            emptyText: '該当するものはありません',
                        }}
                        bordered={true}
                        size="middle"
                        loading={this.state.loading}
                        onChange={this.handleTableChange}
                        columns={columns}
                        dataSource={this.state.userSearchTargetListArray}
                        pagination={this.state.pagination}
                    />
                    <UserViewModal
                        visible={this.state.viewUserModalIsOpen}
                        handleCancel={() => {
                            this.setState({
                                viewUserModalIsOpen: false
                            })
                        }}
                        handleOk={(fieldValue, data) => {
                            this.setState({
                                viewUserModalIsOpen: false
                            })
                        }}
                        uuid={this.state.viewModalUUID}
                    />

                </div>

            </Modal>
        );
    }
}


export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Form.create()(SelectTargetPreview))

SelectTargetPreview.propTypes = {
    visible: PropTypes.bool.isRequired,
    handleOk: PropTypes.func.isRequired,
    handleCancel: PropTypes.func.isRequired,
    data: PropTypes.object
};