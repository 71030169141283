import React from 'react'
import {connect, store} from 'react-redux'
import PropTypes from 'prop-types';
import {instanceOf} from 'prop-types';
import log from 'components/utils/Logger.js'

import moment from 'moment';
import 'moment/locale/ja';
import { CalendarOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Modal, Table, Tabs, Collapse, Card, Button, Calendar } from 'antd';
import * as Actions from 'redux/actions';
moment.locale('ja')

const TabPane = Tabs.TabPane;
const FormItem = Form.Item;
const Panel = Collapse.Panel;

const mapStateToProps = (state, props) => {
    return {
        screen: state.screen,
    }
}

const mapDispatchToProps = dispatch => {
    return {}
}

class ModuleCalendarModalList extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
          modalTitle: props.title,
        };
    }

    ////////////////////////////////

    onClick(state) {
        this.setState(state)
    }

    getListData = (value) =>{
        let listData;
        switch (value.date()) {
            case 8:
                listData = [
                    { type: 'warning', content: 'イベント内容.' },
                    { type: 'normal', content: '体育祭.' },
                ]; break;
            case 10:
                listData = [
                    { type: 'warning', content: 'メンテナンスのお知らせ' },
                    { type: 'normal', content: 'キャリア面談予約日' },
                    { type: 'error', content: '重要なお知らせ' },
                ]; break;
            case 15:
                listData = [
                    { type: 'warning', content: '休校日' },
                    { type: 'normal', content: 'ちょっと長めのカレンダータイトル' },
                    { type: 'error', content: '重要なイベント 1' },
                    { type: 'error', content: '重要なイベント 2' },
                    { type: 'error', content: '重要なイベント 3' },
                    { type: 'error', content: '重要なイベント 4' },
                ]; break;
            default:
        }
        return listData || [];
    }

    dateCellRender = (value) => {
        const listData = this.getListData(value);
        return (
            <ul className="events">
                {
                    listData.map(item => (
                        <li key={item.content} onClick={()=>(alert(item.content))}>
                            <span className={`event-${item.type}`}>●</span>
                            {item.content}
                        </li>
                    ))
                }
            </ul>
        );
    }

    getMonthData = (value) => {
        if (value.month() === 8) {
            return 1394;
        }
    }

    monthCellRender = (value) => {
        const num = this.getMonthData(value);
        return num ? <div className="notes-month">
            <section>{num}</section>
            <span>Backlog number</span>
        </div> : null;
    }

    ////////////////////////////////

    componentWillReceiveProps(nextProps) {
        this.setState({
          modalTitle: nextProps.title,
          visible: nextProps.visible
        })
    }

    handleOk = () => {

        this.props.handleOk();
    }

    handleCancel = () => {

        this.props.handleCancel();
    }
    render() {
        const {visible, confirmLoading} = this.state;
        return (
            <Modal
                className="common-modal"
                style={{top: 20}}
                title={<span><CalendarOutlined /> {this.state.modalTitle}</span>}
                visible={visible}
                maskClosable={false}
                onOk={this.handleOk}
                confirmLoading={confirmLoading}
                onCancel={this.handleCancel}
                footer={[
                    <Button key="back" size="large" onClick={this.handleCancel}>閉じる</Button>
                ]}>
                <div className='wrapper calendar-modal-list'>

                <Tabs
                    className="content-tab"
                    defaultActiveKey="1"
                    onChange={this.onChangeTab}
                    animated={false}>
                    <TabPane tab="全ての学年暦" key="1">
                      <Calendar dateCellRender={this.dateCellRender} monthCellRender={this.monthCellRender} />
                    </TabPane>
                    <TabPane tab="大宮/深草 [大学]" key="2">
                      <h2>大宮/深草 [大学]</h2>
                    </TabPane>
                    <TabPane tab="瀬田 [大学]" key="3">
                      <h2>瀬田 [大学]</h2>
                    </TabPane>
                    <TabPane tab="大学院" key="4">
                      <h2>大学院</h2>
                    </TabPane>
                    <TabPane tab="深草 [短期大学部]" key="5">
                        <h2>深草 [短期大学部]</h2>
                    </TabPane>
                </Tabs>


                </div>
            </Modal>
        );
    }
}


ModuleCalendarModalList.propTypes = {
    title: PropTypes.string.isRequired,
    handleOk: PropTypes.func.isRequired,
    handleCancel: PropTypes.func.isRequired,
};


export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ModuleCalendarModalList)
