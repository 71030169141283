import React, {Component} from 'react';
import {connect, store} from 'react-redux';
import { Menu, Button, Modal, Popconfirm } from 'antd';
import {withRouter, Link} from 'react-router-dom';
import log from '../../components/utils/Logger.js';
import * as Actions from '../../redux/actions';
import 'moment/locale/ja';
import * as GlobalConfig from '../../constants/GlobalConfig';
import Account from './Account';
import {
    LogoutOutlined,
    HomeOutlined
} from '@ant-design/icons';


const mapStateToProps = (state, props) => {
    return {
        screen: state.screen,
        session: state.session,
        logout: state.logout
    }
};

const mapDispatchToProps = dispatch => {
    return {
        requestLogout: () => {
            dispatch(Actions.Connection.logout.request());
        },
    }
};

class AdminMenu extends React.Component {

    constructor(props) {
        super(props);
        const path = props.pathName.replace(GlobalConfig.AdminSubDirectory, "");
        this.state = {
            pathName: path ? path : "/"
        }
    };

    handleSelect = (item, key, selectedKeys) => {
        this.props.history.push(GlobalConfig.AdminSubDirectory + item.key);
    };


    componentWillReceiveProps(nextProps) {
        if (nextProps.pathName !== this.props.pathName) {
            this.setState({
                pathName: nextProps.pathName.replace(GlobalConfig.AdminSubDirectory, "")
            })
        }

    }

    render() {
        let permission = this.props.session.payload.isAuthenticated?this.props.session.payload.user.permission :"";

        let component;
        switch(permission){
            case "admin":
                component = (
                    <Menu
                        defaultSelectedKeys={["/"]}
                        selectedKeys={[this.state.pathName]}
                        defaultOpenKeys={['/']}
                        onSelect={this.handleSelect}
                        mode="inline"
                        theme="dark"
                        inlineCollapsed={this.state.collapsed}
                    >
                        <Menu.Item key="/">
                            <span>ホーム</span>
                        </Menu.Item>
                        <Menu.Item key="/notifications/">
                            <span>お知らせ管理</span>
                        </Menu.Item>
                        <Menu.Item key="/calendars/">
                            <span>学年暦管理</span>
                        </Menu.Item>
                        <Menu.Item key="/services/">
                            <span>メニュー管理</span>
                        </Menu.Item>
                        <Menu.Item key="/sso/">
                            <span>SSO連携管理</span>
                        </Menu.Item>
                        <Menu.Item key="/users/">
                            <span>利用者管理</span>
                        </Menu.Item>
                        <Menu.Item key="/log/">
                            <span>ログ検索</span>
                        </Menu.Item>
                        <Menu.Item key="/maintenance/">
                            <span>メンテナンス</span>
                        </Menu.Item>
                    </Menu>
                );
                break;
            case "manage":
                component = (
                    <Menu
                        defaultSelectedKeys={["/"]}
                        selectedKeys={[this.state.pathName]}
                        defaultOpenKeys={['/']}
                        onSelect={this.handleSelect}
                        mode="inline"
                        theme="dark"
                        inlineCollapsed={this.state.collapsed}>
                        <Menu.Item key="/">
                            <span>ホーム</span>
                        </Menu.Item>
                        <Menu.Item key="/notifications/">
                            <span>お知らせ管理</span>
                        </Menu.Item>
                        {
                            /* //todo 今度の追加を想定しておきましょう。
                            <Menu.Item key="/questionnaires/">
                                <span>アンケート管理</span>
                            </Menu.Item>
                            <Menu.Item key="/fileshare/">
                                <span>ファイル共有</span>
                                <Icon type="arrow-right"/>
                            </Menu.Item>
                            */
                        }
                    </Menu>
                );
                break;
            default:
                component = (
                    <Menu
                        defaultSelectedKeys={["/"]}
                        selectedKeys={[this.state.pathName]}
                        defaultOpenKeys={['/']}
                        onSelect={this.handleSelect}
                        mode="inline"
                        theme="dark"
                        inlineCollapsed={this.state.collapsed}>
                        <Menu.Item key="/">
                            <span>ホーム</span>
                        </Menu.Item>
                    </Menu>
                );
                break;
        }
        return (
            <div id="menu">
                <div className="header-logo">

                    <img src={GlobalConfig.MenuLogo}
                         srcSet={GlobalConfig.MenuLogoSvg + ' ,' +GlobalConfig.MenuLogo + ' 1x,'}
                         alt={GlobalConfig.ServiceName}
                         style={{padding:"4px"}}
                    />

                    <Account/>

                    <Button type="secondary" className="login-form-button"
                            onClick={() => {
                                this.props.history.push(GlobalConfig.UserSubDirectory)
                            }}>
                        <HomeOutlined /><span>インフォメーションシステムへ</span>
                    </Button>

                    <Popconfirm
                        title={
                            <span>
                            <strong>{GlobalConfig.ServiceName}からログアウトします。</strong><br/>
                                    よろしいですか？
                            </span>
                        }
                        onConfirm={() => {
                            this.props.requestLogout()
                        }}
                        onCancel=""
                        placement="left"
                        okText="ログアウト" cancelText="キャンセル">
                        <Button type="danger" className="login-form-button">
                            <LogoutOutlined /> <span>ログアウト</span>
                        </Button>
                    </Popconfirm>

                </div>
                {component}
            </div>
        );
    }
}


AdminMenu.propTypes = {};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(AdminMenu));