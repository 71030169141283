import React, {useEffect} from 'react';
import { Redirect, Route, Switch, useLocation} from "react-router-dom";
import Login from "./containers/login/Login";
import * as GlobalConfig from "./constants/GlobalConfig";
import Admin from "./Admin";
import Dashboard from "./Dashboard";
import RedirectComponent from "./components/RedirectComponent";
import GroupList from "./containers/users/GroupListDummy";
import Helmet from "react-helmet";
import NotFound from "./components/NotFound";
import {initializeGA} from "./components/utils/GoogleAnalyticsUtil";

InitializeRouter.propTypes = {
    
};

initializeGA(GlobalConfig.GATag);

function InitializeRouter(props) {
    const location = useLocation();

    useEffect(()=>{
        initializeGA(GlobalConfig.GATag);
    },[])

    useEffect(() => {
        if (!window.gtag) return;
        if (GlobalConfig.GATag === '') return;
        window.setTimeout(() => {
            console.log('new_title', document.title); // この時点では変更済み
            window.gtag('config', GlobalConfig.GATag, {
                page_path: location.pathname
            });
        });
    }, [location]);

    return (
        <Switch>
            <Route exact path="/" render={props => {
                return (
                    <Redirect to={{
                        pathname: '/login',
                        state: {from: props.location}
                    }}/>
                )
            }}/>
            <Route path="/login" exact component={Login}/>
            <Route path={GlobalConfig.AdminSubDirectory}
                   component={Admin}/>
            <Route path={GlobalConfig.UserSubDirectory}
                   component={Dashboard}/>
            <Route path={GlobalConfig.RedirectSubDirectory}
                   component={RedirectComponent} />
            <Route path={"/dev"}
                   component={GroupList} />
            <Route status={404}
                   render={props => (
                       <div>
                           <Helmet>
                               <title>ページが見つかりません - {GlobalConfig.ServiceTitle}</title>
                               <meta name="robots" content="noindex" />
                           </Helmet>
                           <NotFound/>
                       </div>
                   )}/>
        </Switch>
    );
}

export default InitializeRouter;