import React from 'react'
import {connect, store} from 'react-redux'
import PropTypes from 'prop-types';
import {instanceOf} from 'prop-types';
import log from 'components/utils/Logger.js'

import moment from 'moment';
import 'moment/locale/ja';
import { SolutionOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Modal, Table, Tabs, Collapse, Card, Button } from 'antd';

moment.locale('ja')

const TabPane = Tabs.TabPane;
const FormItem = Form.Item;
const Panel = Collapse.Panel;

const mapStateToProps = (state, props) => {
    return {
        screen: state.screen,
    }
}

const mapDispatchToProps = dispatch => {
    return {}
}

class ModuleCalendarModalSingle extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            data: props.data,
            modalTitle: props.title,
            visible: props.visible,
        };
    }

    componentWillReceiveProps(nextProps) {

        this.setState({
            data: nextProps.data,
            modalTitle : nextProps.title,
            visible: nextProps.visible
        })
    }

    handleOk = () => {
        this.props.handleOk();
    }

    handleCancel = () => {
        this.props.handleCancel();
    }

    render() {
        const {visible, confirmLoading} = this.state;
        return (
            <Modal
                className="common-modal"
                style={{top: 20}}
                title={<span><SolutionOutlined /> {this.state.modalTitle}</span>}
                visible={visible}
                maskClosable={false}
                onOk={this.handleOk}
                confirmLoading={confirmLoading}
                onCancel={this.handleCancel}
                footer={[
                    <Button key="back" size="large" onClick={this.handleCancel}>閉じる</Button>
                ]}>
                <div className='wrapper calendar-modal-single'>

                </div>
            </Modal>
        );
    }
}


ModuleCalendarModalSingle.propTypes = {
    title: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    handleOk: PropTypes.func.isRequired,
    handleCancel: PropTypes.func.isRequired,
    data: PropTypes.object.isRequired
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ModuleCalendarModalSingle)
