import React from 'react'
import {connect, store} from 'react-redux'
import {instanceOf} from 'prop-types';
import log from 'components/utils/Logger.js'

import moment from 'moment';
import 'moment/locale/ja';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Tabs, Collapse } from 'antd';
import ContentHeader from "../../components/ContentHeader";
import NotificationList from './NotificationList'
import NotificationCategoryList from './NotificationCategoryList'

moment.locale('ja');

const TabPane = Tabs.TabPane;
const FormItem = Form.Item;
const Panel = Collapse.Panel;

const mapStateToProps = (state, props) => {
    return {
        screen: state.screen,
        session: state.session
    }
};

const mapDispatchToProps = dispatch => {
    return {
    }
};

class Notification extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
        };
    }

    onChangeTab = (key) =>{

    };

    render() {
        let permission = this.props.session.payload.isAuthenticated?this.props.session.payload.user.permission :"";
        switch(permission){
            case "admin":
                return (
                    <div className='container clearfix' id="notification">
                        <ContentHeader title="お知らせ管理" breadCrumbItems={[
                            {title:"お知らせ管理",icon:"notification",path:"/console/notifications/"}
                        ]}/>
                        <Tabs
                            className="content-tab"
                            defaultActiveKey="1"
                            onChange={this.onChangeTab}
                            animated={false}>
                            <TabPane tab="お知らせ一覧" key="1">
                                <NotificationList />
                            </TabPane>
                            <TabPane tab="お知らせカテゴリ一覧" key="2">
                                <NotificationCategoryList />
                            </TabPane>
                        </Tabs>
                    </div>
                );
            case "manager":
                return (
                    <div className='container clearfix' id="notification">
                        <ContentHeader title="お知らせ管理" breadCrumbItems={[
                            {title:"お知らせ管理",icon:"notification",path:"/console/notifications/"}
                        ]}/>
                        <Tabs
                            className="content-tab"
                            defaultActiveKey="1"
                            onChange={this.onChangeTab}
                            animated={false}>
                            <TabPane tab="お知らせ一覧" key="1">
                                <NotificationList />
                            </TabPane>
                        </Tabs>
                    </div>
                );
        }

        return (
            <div className='container clearfix' id="notification">
                <ContentHeader title="お知らせ管理" breadCrumbItems={[
                    {title:"お知らせ管理",icon:"notification",path:"/console/notifications/"}
                ]}/>
                <Tabs
                    className="content-tab"
                    defaultActiveKey="1"
                    onChange={this.onChangeTab}
                    animated={false}>
                    <TabPane tab="お知らせ一覧" key="1">
                        <NotificationList />
                    </TabPane>
                </Tabs>
            </div>
        );
    }
}


Notification.propTypes = {

};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Notification)
