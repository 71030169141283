import React from 'react'
import PropTypes from 'prop-types';
import {connect, store} from 'react-redux'
import { FileAddOutlined, InboxOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import {
    Modal,
    Table,
    Tabs,
    Card,
    Radio,
    Select,
    Input,
    InputNumber,
    Col,
    Row,
    Button,
    Checkbox,
    DatePicker,
    Collapse,
    Tooltip,
    Upload,
} from 'antd';

import log from '../../components/utils/Logger.js'
import * as GlobalConfig from '../../constants/GlobalConfig'
import * as Actions from '../../redux/actions'
import moment from 'moment';
import 'moment/locale/ja';
import jaJP from 'antd/lib/date-picker/locale/ja_JP';
import SelectTargetForm from "../common/SelectTargetForm";
import SelectPermissionForm from '../common/SelectPermissionForm';
import * as UUID from '../../components/utils/UUID.js';
import {message} from "antd/lib/index";

moment.locale('ja');

const TabPane = Tabs.TabPane;
const FormItem = Form.Item;
const Panel = Collapse.Panel;
const Option = Select.Option;
const RadioButton = Radio.Button;
const RadioGroup = Radio.Group;
const RangePicker = DatePicker.RangePicker;
const {TextArea} = Input;

const mapStateToProps = (state, props) => {
    return {
        session: state.session,
        images: state.images,
        fileShareCreateFile: state.fileShareCreateFile,
        fileShareUpdateFile: state.fileShareUpdateFile
    }
};

const mapDispatchToProps = dispatch => {
    return {
        createFile: (data) => {
            dispatch(Actions.AdminConnection.fileshare.file.create.request(data));
        },
        updateFile: (uuid,data) => {
            dispatch(Actions.AdminConnection.fileshare.file.update.request(uuid,data));
        },
        uploadImages: (fileName, contentType, data) => {
            dispatch(Actions.Connection.images.request(fileName, contentType, data));
        }
    }
};


class FileShareAddFileModal extends React.Component {
    constructor(props) {
        super(props);
        const modalTitle = props.isEditMode ? "ファイル情報の編集" : "ファイルの追加";
        this.state = {
            modalTitle: modalTitle,
            isEditMode: props.isEditMode,
            visible: props.visible,
            folderMeta: props.folderMeta,
            data: props.data,
            fileList: []
        }
    }

    componentWillReceiveProps(nextProps) {
        const {fileShareCreateFile, fileShareUpdateFile} = this.props;
        const modalTitle = nextProps.isEditMode ? "ファイル情報の編集" : "ファイルの追加";
        this.setState({
            modalTitle,
            visible: nextProps.visible,
            isEditMode: nextProps.isEditMode,
            folderMeta: nextProps.folderMeta,
            data: nextProps.data
        });

        //data更新時差分
        if (nextProps.visible !== this.props.visible && !this.props.visible && nextProps.isEditMode) {
            log.debug(nextProps.data);

            let extension = "";
            let name = "";
            if(nextProps.data){
                const reg=/(.*)(?:\.([^.]+$))/;
                name= nextProps.data.name;
                extension = "."+nextProps.data.extension;
                if(reg.test(name)){
                    name = name.match(reg)[1];
                }
            }
            const description = nextProps.data ? nextProps.data.description : "";
            const publish_start_at = nextProps.data ? nextProps.data.publish_start_at : "";
            const publish_end_at = nextProps.data ? nextProps.data.publish_end_at : "";
            const range_picker = nextProps.data ? [moment.unix(publish_start_at), moment.unix(publish_end_at)] : undefined;

            const have_term = !(publish_start_at === 0 && publish_end_at === 2147483647);
            this.setState({
                name,
                extension,
                description,
                publish_start_at,
                publish_end_at,
                range_picker,
                have_term
            })
        }
        if (nextProps.fileShareCreateFile !== fileShareCreateFile) {
            this.setState({
                loading: nextProps.fileShareCreateFile.meta.fetch,
            });
            if (!nextProps.fileShareCreateFile.meta.fetch) {
                if (!nextProps.fileShareCreateFile.error) {
                    if (nextProps.fileShareCreateFile.payload !== null) {
                        //問題なく終了したので更新制御とmodal除去
                        this.props.handleOk();
                    }
                }else{

                }
            }
        }

        if (nextProps.fileShareUpdateFile !== fileShareUpdateFile) {
            this.setState({
                loading: nextProps.fileShareUpdateFile.meta.fetch,
            });
            if (!nextProps.fileShareUpdateFile.meta.fetch) {
                if (!nextProps.fileShareUpdateFile.error) {
                    if (nextProps.fileShareUpdateFile.payload !== null) {
                        //問題なく終了したので更新制御とmodal除去
                        this.props.handleOk();
                    }
                }else{

                }
            }
        }
        //imagesアップデート差分
        if (nextProps.images !== this.props.images) {
            if (!nextProps.images.meta.fetch) {
                if (!nextProps.images.error) {
                    if (nextProps.images.payload !== null && nextProps.images.payload.hasOwnProperty("result")) {
                        //画像のアップロード
                        const fileList = [];
                        const map = fileList.some((value) => {
                            return value === nextProps.images.payload.result;
                        });
                        //OPTIMIZE fileListが単体ではない可能性
                        if (!map) {
                            let constObject = nextProps.images.payload.result;
                            constObject.name = constObject.file_name;
                            constObject.uid = Math.floor(Math.random() * 10000);
                            constObject.response = nextProps.images.payload;
                            constObject.status = 'done';
                            constObject.url = `${process.env.REACT_APP_FILE_DOMAIN}/file/view/${constObject.uuid}`;
                            if(constObject.hasOwnProperty("mime_type") && constObject.mime_type.match("image")){
                                constObject.thumbUrl = `${process.env.REACT_APP_FILE_DOMAIN}/file/view/${constObject.uuid}`;
                            }else{
                                constObject.thumbUrl = process.env.PUBLIC_URL + '/static/media/attach.svg';
                            }
                            let constArray = fileList.concat();
                            constArray[constArray.length] = constObject;
                            this.setState({
                                fileList: constArray
                            })
                        }
                    }
                }
            }
            this.setState({
                loading: nextProps.images.meta.fetch
            })
        }
        if(!nextProps.visible) {
            this.resetForm();
        }
    }

    //FileUpload
    handleUpload = (file) => {
        const {fileList} = this.state;
        const fileReader = new FileReader();
        fileReader.onload = (event) => {
            this.props.uploadImages(file.name, file.type, event.target.result);
        }
        this.setState({
            uploading: true,
        });
        fileReader.readAsArrayBuffer(file);
    };

    handleCloseForm = () => {
        log.debug("close")
    };

    handleOk = () => {
        this.props.form.validateFields((err, values) => {
            if (!err) {
                log.debug(values);
                log.debug(this.state.fileList);
                if(this.state.isEditMode){
                    const fieldsValue = values;
                    if (fieldsValue.have_term) {
                        const range_picker = fieldsValue.range_picker;
                        if (typeof range_picker !== 'undefined') {
                            fieldsValue.publish_start_at = range_picker[0].unix();
                            fieldsValue.publish_end_at = range_picker[1].unix();
                            delete fieldsValue.range_picker;
                        }
                    } else {
                        fieldsValue.publish_start_at = 0;
                        fieldsValue.publish_end_at = 2147483647;
                    }
                    delete fieldsValue.have_term;
                    fieldsValue.name = values.name + this.state.extension;

                    if(this.state.folderMeta !== null && typeof this.state.folderMeta !== "undefined") {
                        fieldsValue.parent_folder_uuid = this.state.folderMeta.uuid;
                        this.setState({
                            loading: true
                        });
                        this.props.updateFile(this.state.data.uuid, fieldsValue);
                    }else{

                    }
                }else {
                    if (this.state.fileList.length !== 0) {
                        const fieldsValue = values;
                        if (fieldsValue.have_term) {
                            const range_picker = fieldsValue.range_picker;
                            if (typeof range_picker !== 'undefined') {
                                fieldsValue.publish_start_at = range_picker[0].unix();
                                fieldsValue.publish_end_at = range_picker[1].unix();
                                delete fieldsValue.range_picker;
                            }
                        } else {
                            fieldsValue.publish_start_at = 0;
                            fieldsValue.publish_end_at = 2147483647;
                        }
                        delete fieldsValue.have_term;
                        fieldsValue.name = this.state.fileList[0].file_name;
                        fieldsValue.file_uuid = this.state.fileList[0].uuid;

                        if(this.state.folderMeta !== null && typeof this.state.folderMeta !== "undefined") {
                            fieldsValue.parent_folder_uuid = this.state.folderMeta.uuid;
                            this.setState({
                                loading: true
                            });
                            this.props.createFile(fieldsValue);
                        }else{

                        }
                    } else {
                        message.error('ファイルを選択してください');
                    }
                }
            }
        });
    };

    handleCancel = () => {
        this.props.handleCancel();
    };

    resetForm = () => {
        this.props.form.resetFields();
        this.setState({
            name: "",
            description: "",
            have_term: false,
            range_picker: undefined,
            fileList: []
        })
    }
    render() {
        const {visible, confirmLoading} = this.state;
        const uploadProps = {
            onRemove: (file) => {
                this.setState(({fileList}) => {
                    const index = fileList.indexOf(file);
                    const newFileList = fileList.slice();
                    newFileList.splice(index, 1);
                    return {
                        fileList: newFileList,
                    };
                });
            },
            beforeUpload: (file) => {
                // this.setState(({ fileList }) => ({
                //     fileList: [...fileList, file],
                // }));
                this.handleUpload(file);
                return false;
            },
            handleChange: this.handleChange,
            fileList: this.state.fileList,
            listType: 'picture',
        };

        const formItemLayout = {
            labelCol: {
                xs: {span: 24},
                sm: {span: 24},
            },
            wrapperCol: {
                xs: {span: 24},
                sm: {span: 24},
            },
        };
        const {getFieldDecorator} = this.props.form;

        return (
            <Modal
                className={"common-modal-small"}
                style={{top: 20}}
                title={<span><FileAddOutlined /> {this.state.modalTitle}</span>}
                visible={visible}
                maskClosable={false}
                onOk={this.handleOk}
                confirmLoading={confirmLoading}
                onCancel={this.handleCancel}
                footer={[
                    <Button key="back" size="large" onClick={this.handleCancel}>閉じる</Button>,
                    <Button key="add" type={"primary"} loading={this.state.loading} size="large"
                            onClick={this.handleOk}>追加</Button>,
                ]}>
                <div className='wrapper'>
                    <div className="dropbox">
                        {!this.state.isEditMode ?
                            <Upload.Dragger
                                name="files"
                                {...uploadProps} >
                                <p className="ant-upload-drag-icon">
                                    <InboxOutlined />
                                </p>
                                <p className="ant-upload-text">ファイルをドラッグ＆ドロップするか、ここをクリックして選択してください</p>
                                <div className="ant-upload-hint">
                                    {GlobalConfig.FormHelpTextOverride.attachHint.Default}
                                </div>
                            </Upload.Dragger>
                            :
                            <FormItem
                                {...formItemLayout}
                                label="ファイル名">
                                {getFieldDecorator('name', {
                                    initialValue: this.state.name,
                                    rules: [{
                                        required: true,
                                        message: "ファイル名を入力してください"
                                    }]
                                })(
                                    <Input placeholder="ファイル名を入力してください"  addonAfter={this.state.extension} onChange={(e) => {
                                        this.setState({name: e.target.value})
                                    }}/>
                                )}
                            </FormItem>
                        }

                        <FormItem
                            {...formItemLayout}
                            label="摘要">
                            {getFieldDecorator('description', {
                                initialValue: this.state.description,
                                rules: [{
                                    message: "ファイルの摘要を入力してください"
                                }]
                            })(
                                <TextArea placeholder="ファイルの摘要を入力してください" onChange={(e) => {
                                    this.setState({description: e.target.value})
                                }}/>
                            )}
                        </FormItem>


                        <FormItem
                            {...formItemLayout}
                            label="ファイル期限設定"
                            extra="「設定しない」を選択した場合、ファイルに期限は設定されません">
                            {getFieldDecorator('have_term', {
                                initialValue: typeof this.state.have_term !== "undefined" ? this.state.have_term : false,
                            })(
                                <RadioGroup onChange={(value) => {
                                    if(value.target.value){
                                        this.setState({
                                            have_term: value.target.value,
                                            range_picker: undefined
                                        })
                                    }else{
                                        this.setState({
                                            have_term: value.target.value
                                        })
                                    }
                                }}>
                                    <Radio value={true}>設定する</Radio>
                                    <Radio value={false}>設定しない</Radio>
                                </RadioGroup>
                            )}
                        </FormItem>

                        {this.state.have_term ?
                            <FormItem
                                {...formItemLayout}
                                label="フォルダ期限">
                                {getFieldDecorator('range_picker', {
                                    initialValue: this.state.range_picker,
                                    rules: [{type: 'array', required: true, message: '期限を選択してください'}]
                                })(
                                    <RangePicker
                                        locale={jaJP}
                                        format="YYYY/MM/DD"
                                        ranges={GlobalConfig.DatePickerRanges()}/>
                                )}
                            </FormItem>
                            :
                            null
                        }

                    </div>
                </div>
            </Modal>
        );
    }
}


export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Form.create()(FileShareAddFileModal));


FileShareAddFileModal.propTypes = {
    visible: PropTypes.bool.isRequired,
    handleOk: PropTypes.func.isRequired,
    handleCancel: PropTypes.func.isRequired,
};


// Specifies the default values for props:
FileShareAddFileModal.defaultProps = {
    data: {}
};
