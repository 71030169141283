import React from 'react'
import {connect, store} from 'react-redux'
import {instanceOf} from 'prop-types';
import log from '../../components/utils/Logger.js'
import * as Actions from '../../redux/actions'
import * as GlobalConfig from '../../constants/GlobalConfig'

import moment from 'moment';
import 'moment/locale/ja';
import { DeleteOutlined, EditOutlined, FileTextOutlined, PlusCircleOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Table, Input, Col, Button, Row, Tooltip, Popconfirm } from 'antd';
import NotificationCategoryFormModal from './NotificationCategoryFormModal';
import NotificationCategoryFormCSVModal from './NotificationCategoryFormCSVModal';

moment.locale('ja');

const FormItem = Form.Item;

const mapStateToProps = (state, props) => {
    return {
        screen: state.screen,
        notificationCategory: state.notificationCategory,
        notificationCategoryCreate: state.notificationCategoryCreate,
        notificationCategoryUpdate: state.notificationCategoryUpdate,
        notificationCategoryDelete: state.notificationCategoryDelete,
    }
};

const mapDispatchToProps = dispatch => {
    return {
        searchCategory: (data,currentPageNum) => {
            dispatch(Actions.AdminConnection.notification.category.search.request(data,currentPageNum));
        },
        createCategory: (data) => {
            dispatch(Actions.AdminConnection.notification.category.create.request(data));
        },
        updateCategory: (uuid,data) => {
            dispatch(Actions.AdminConnection.notification.category.update.request(uuid,data));
        },
        deleteCategory: (uuid) => {
            dispatch(Actions.AdminConnection.notification.category.delete.request(uuid));
        }
    }
};

class NotificationCategoryList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            editModalIsOpen:false,
            addModalIsOpen: false,
            csvModalIsOpen: false,
            searchCategoryArray:[],
            editNotificationCategoryData: {},
            pagination: {
                showTotal: (total, range) => {

                    return (`全${total}件中 ${range[0]}-${range[1]} 件`)
                },
                showSizeChanger: true,
                pageSizeOptions: GlobalConfig.PageSizeOptions,
                pageSize: 20,
                onShowSizeChange: this.onChangePageSize
            }
        };
        this.props.searchCategory("");
    }


    componentWillReceiveProps(nextProps) {

        const pagination = { ...this.state.pagination };

        if(nextProps.notificationCategory !== this.props.notificationCategory) {
            if (!nextProps.notificationCategory.meta.fetch) {
                if (!nextProps.notificationCategory.error) {
                    if (nextProps.notificationCategory.payload !== null) {
                        this.setState({
                            loading: nextProps.notificationCategory.meta.fetch,
                            searchCategoryArray: nextProps.notificationCategory.payload.result.items,
                        })
                    }
                }
            } else {
                this.setState({
                    loading: nextProps.notificationCategory.meta.fetch
                })
            }
        }

        if(nextProps.notificationCategoryCreate !== this.props.notificationCategoryCreate) {
            if (!nextProps.notificationCategoryCreate.meta.fetch) {
                if (!nextProps.notificationCategoryCreate.error) {
                    if (nextProps.notificationCategoryCreate.payload !== null) {
                        //再読込
                        const fieldsValue = this.props.form.getFieldsValue();
                        this.props.searchCategory(fieldsValue);

                        this.setState({
                            //loading: nextProps.notificationCategoryCreate.meta.fetch,
                            //searchCategoryArray: nextProps.notificationCategoryCreate.payload.result.items,
                            addModalIsOpen: false
                        })
                    }
                }
            } else {
                this.setState({
                    //loading: nextProps.notificationCategoryCreate.meta.fetch
                })
            }
        }


        if(nextProps.notificationCategoryUpdate !== this.props.notificationCategoryUpdate) {
            if (!nextProps.notificationCategoryUpdate.meta.fetch) {
                if (!nextProps.notificationCategoryUpdate.error) {
                    if (nextProps.notificationCategoryUpdate.payload !== null) {
                        //再読込
                        const fieldsValue = this.props.form.getFieldsValue();
                        this.props.searchCategory(fieldsValue);

                        this.setState({
                            editModalIsOpen: false
                        })
                    }
                }
            } else {
                this.setState({

                })
            }
        }

        if(nextProps.notificationCategoryDelete !== this.props.notificationCategoryDelete) {
            if (!nextProps.notificationCategoryDelete.meta.fetch) {
                if (!nextProps.notificationCategoryDelete.error) {
                    if (nextProps.notificationCategoryDelete.payload !== null) {
                        //再読込
                        const fieldsValue = this.props.form.getFieldsValue();
                        this.props.searchCategory(fieldsValue);

                    }
                }
            } else {
                this.setState({
                    //loading: nextProps.notificationCategoryCreate.meta.fetch
                })
            }
        }

    }


    onSubmit = (e) => {
        e.preventDefault();
        const fieldsValue = this.props.form.getFieldsValue();

        this.props.searchCategory(fieldsValue);
        return false;
    };

    handleResetSearchForm = () => {
        this.props.form.resetFields();
        this.search();
    };

    search(value){
        let fieldsValue = this.props.form.getFieldsValue();

        let pagination;
        if(typeof value === "undefined"){
            pagination = { ...this.state.pagination };
        }else{
            pagination = value;
        }

        fieldsValue.page_size = pagination.pageSize;
        //ほぼそのまま検索
        this.props.searchCategory(fieldsValue,pagination.current);
    }

    handleOpenAddCategoryModal = () =>{
        this.setState({
            addModalIsOpen: true
        })
    };
    handleOpenEditCategoryModal = (value) =>{

        this.setState({
            editNotificationCategoryData: value,
            editModalIsOpen: true
        })
    };

    handleOpenCSVCategoryModal = () =>{
        this.setState({
            csvModalIsOpen: true
        })
    };

    onChangePageSize = (current, pageSize) => {
        const pager = { ...this.state.pagination };
        //pageSizeの更新
        pager.pageSize = pageSize;
        this.setState({
            pagination: pager,
        });
    };

    handleTableChange = (pagination, filters, sorter) => {
        /*
        if(pager.current !== pagination.current){
            this.setState({
                pagination: pagination,
            });
        }*/
        //FIXME ページ切り替え都度の更新にするかどうかによって処理が変わる 現在はひとまず全件読み込み後のページ切り替えになっている。
        //this.search(pagination);
    };


    createCategory = (fieldValue,data) => {
        this.props.createCategory(fieldValue);
    };

    updateCategory = (fieldValue,data) =>{
        this.props.updateCategory(data.uuid,fieldValue);
    };

    deleteCategory = (data) => {
        this.props.deleteCategory(data.uuid);
    };

    render() {

        const {getFieldDecorator} = this.props.form;

        const formItemLayout = {
            labelCol: {
                xs: {span: 24},
                sm: {span: 4},
            },
            wrapperCol: {
                xs: {span: 24},
                sm: {span: 20},
            },
        };

        let {sortedInfo, filteredInfo} = this.state;
        sortedInfo = sortedInfo || {};
        filteredInfo = filteredInfo || {};

        const columns = [ {
            title: 'カテゴリ名称',
            dataIndex: 'name',
            key: 'name'
        }, {
            title: '概要',
            dataIndex: 'description',
            key: 'description'
        }, {
            title: "",
            width: "85px",
            key: 'action',
            render: (text, record) => {

                return (
                    <Row type="flex" justify="space-around" align="middle">
                        <Col>
                            <Tooltip placement="topLeft" title="編集">
                                <Button type="default" shape="circle" icon={<EditOutlined />} onClick={() => this.handleOpenEditCategoryModal(record)}/>
                            </Tooltip>
                        </Col>
                        <Col>
                            <Popconfirm
                                title={<span>お知らせカテゴリを削除するとカテゴリ内の全てのお知らせが削除されます。<br />
                                    <strong>削除すると復元することはできません。</strong></span>}
                                onConfirm={() => this.deleteCategory(record)}
                                onCancel=""
                                placement="left"
                                okText="削除" cancelText="キャンセル">
                                <Tooltip placement="topLeft" title="削除">
                                    <Button type="default" shape="circle" icon={<DeleteOutlined />} />
                                </Tooltip>
                            </Popconfirm>
                        </Col>
                    </Row>
                );
            },
        }

        ];

        return (
            <div id="notification-list-container" className="tab-container" alt="お知らせ一覧">
                <div className="card general-search">
                    <Form className="search-form" onSubmit={this.onSubmit}>
                        <FormItem
                            {...formItemLayout}
                            label="カテゴリ名称"
                            layout="inline">
                            {getFieldDecorator('name')(
                                <Input placeholder="カテゴリ名称を入力してください"/>
                            )}
                        </FormItem>
                        <FormItem
                            {...formItemLayout}
                            label="概要"
                            layout="inline">
                            {getFieldDecorator('description')(
                                <Input placeholder="概要を入力してください"/>
                            )}
                        </FormItem>
                        <div className="submit-container">

                            <Button className="search-form-button" onClick={this.handleResetSearchForm}>
                                リセット
                            </Button>

                            <Button type="primary" htmlType="submit" className="search-form-button">
                                検索
                            </Button>

                        </div>
                    </Form>
                </div>

                <div className="table-header-wrapper">
                    <div className="button-container">
                        {/*
                        <Button type="primary" icon="download">
                            CSVダウンロード
                        </Button>
                        <Button type="primary" onClick={this.handleOpenCSVCategoryModal} icon={<FileTextOutlined />}>
                            CSVインポート
                        </Button>
                        */}
                        <Button type="primary" onClick={this.handleOpenAddCategoryModal} icon={<PlusCircleOutlined />}>
                            新規作成
                        </Button>
                    </div>
                </div>
                <Table
                    className="general-table"
                    locale={{
                        filterTitle: 'フィルタ',
                        filterConfirm: '確定',
                        filterReset: 'リセット',
                        emptyText: '該当するものはありません',
                    }}
                    bordered={true}
                    size="middle"
                    loading={this.state.loading}
                    onChange={this.handleTableChange}
                    columns={columns} dataSource={this.state.searchCategoryArray}
                    pagination={this.state.pagination}
                    />

                <NotificationCategoryFormModal
                    isEditMode={false}
                    visible={this.state.addModalIsOpen}
                    handleCancel={()=>{
                        this.setState({
                            addModalIsOpen: false
                        })
                    }}
                    handleOk={this.createCategory}/>
                <NotificationCategoryFormModal
                    isEditMode={true}
                    visible={this.state.editModalIsOpen}
                    handleCancel={()=>{
                        this.setState({
                            editModalIsOpen: false
                        })
                    }}
                    handleOk={this.updateCategory}
                    data={this.state.editNotificationCategoryData}
                />

                <NotificationCategoryFormCSVModal
                    visible={this.state.csvModalIsOpen}
                    handleCancel={()=>{
                        this.setState({
                            csvModalIsOpen: false
                        })
                    }}
                    handleOk={()=>{

                    }}
                />
            </div>
        );
    }
}


export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Form.create()(NotificationCategoryList))
