import React from 'react'
import PropTypes from 'prop-types';
import {connect, store} from 'react-redux'
import {EnvironmentOutlined, TeamOutlined, UserOutlined} from '@ant-design/icons';
import {Form} from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import {Modal, Tabs, Input, Collapse, Radio, Tree, Spin, Button} from 'antd';

import log from 'components/utils/Logger.js'

import * as GlobalConfig from '../../constants/GlobalConfig'
import * as Actions from '../../redux/actions'
import moment from 'moment';
import 'moment/locale/ja';
import SelectTargetGroupList from 'containers/common/SelectTargetGroupList'
import SelectTargetFromUsers from 'containers/common/SelectTargetFormUsers'
import SelectTargetFormIPAddress from "./SelectTargetIPAddress";
import SelectTargetPreview from './SelectTargetPreview';

moment.locale('ja')
const TabPane = Tabs.TabPane;
const RadioButton = Radio.Button;
const RadioGroup = Radio.Group;


const mapStateToProps = (state, props) => {
    return {
        permission: state.permission,
        permissionCreate: state.permissionCreate,
        permissionUpdate: state.permissionUpdate,
        permissionDelete: state.permissionDelete,
        permissionCopy: state.permissionCopy,
        userSearchTargetUser: state.userSearchTargetUser,


        permissionUserAdd: state.permissionUserAdd,
        permissionUserDelete: state.permissionUserDelete,
        permissionUserDeleteAll: state.permissionUserDeleteAll,

        permissionUserAddAll: state.permissionUserAddAll,
        permissionUserAddCSV: state.permissionUserAddCSV

    }
};

const mapDispatchToProps = dispatch => {
    return {
        getPermission: (uuid) => {
            dispatch(Actions.AdminConnection.permission.request(uuid));
        },
        copyPermission: (uuid) => {
            dispatch(Actions.AdminConnection.permission.copy.request(uuid));
        },
        createPermission: (data) => {
            dispatch(Actions.AdminConnection.permission.create.request(data));
        },
        updatePermission: (uuid, data) => {
            dispatch(Actions.AdminConnection.permission.update.request(uuid, data));
        },
        addUserPermission: (uuid, data) => {
            dispatch(Actions.AdminConnection.permission.adduser.request(uuid, data));
        },
        addUserAllPermission: (uuid, data) => {
            dispatch(Actions.AdminConnection.permission.addall.request(uuid, data));
        },
        addUserCsvPermission: (uuid, data) => {
            dispatch(Actions.AdminConnection.permission.addallcsv.request(uuid, data));
        },
        deleteUserPermission: (uuid, data) => {
            dispatch(Actions.AdminConnection.permission.deleteuser.request(uuid, data));
        },
        deleteUserAllPermission: (uuid) => {
            dispatch(Actions.AdminConnection.permission.deleteuserall.request(uuid));
        },
        deletePermission: (uuid) => {
            dispatch(Actions.AdminConnection.permission.delete.request(uuid));
        }
    }
};


class SelectPermissionForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            visible: props.visible,
            type: props.type, //カテゴリ (1: お知らせ 2: アンケート 3: モジュールリンク)

            self_uuid: props.self_uuid,
            foreign_uuid: props.foreign_uuid,

            previewModalIsOpen: false,

            loading: true,
            expandedKeys: [],
            groupCheckedKeys: [],
            groupCheckedRows: [],
            userCheckedKeys: [],
            userCheckedRows: [],
            object: [],
            userNetworkKeys: [],
            currentActiveKey: "2",
            etag: 0,
            title: props.title !== undefined ? props.title : "編集",
        }
    }


    componentWillReceiveProps(nextProps) {
        const {
            permission,
            permissionCreate,
            permissionUpdate,
            permissionCopy,
            permissionUserAdd,
            permissionUserDelete,
            permissionUserDeleteAll,
            permissionUserAddAll,
            permissionUserAddCSV
        } = this.props;

        this.setState({
            title: nextProps.title !== undefined ? nextProps.title : "編集",
            visible: nextProps.visible,
            type: nextProps.type
        });


        if (nextProps.visible !== this.props.visible) {
            if (nextProps.visible) {
                this.setState({
                    foreign_uuid: nextProps.foreign_uuid,
                    self_uuid: nextProps.self_uuid,
                    type: nextProps.type,
                }, () => {
                    if (nextProps.self_uuid) {
                        this.setState({
                            loading: true
                        })
                        this.props.copyPermission(nextProps.self_uuid);
                    } else {
                        this.props.createPermission(this.generatePostData());

                        this.setState({
                            loading: false
                        })
                    }
                });
            } else {
                this.setState({
                    etag: 0
                })
            }
        }


        if (nextProps.permissionCopy !== permissionCopy) {
            if (!nextProps.permissionCopy.meta.fetch) {
                if (!nextProps.permissionCopy.error) {
                    if (nextProps.permissionCopy.payload !== null) {
                        const groupCheckedKeys = nextProps.permissionCopy.payload.result.group_clusters.map((value, index) => {
                            let checkedKey = [];
                            if (value.trees.length !== 0) {
                                value.trees.forEach((value) => {
                                    value.permission_groups.forEach((value2) => {
                                        checkedKey.push(value2);
                                    })
                                })
                            }
                            return {permission_groups: checkedKey};
                        });

                        const groupCheckedRows = nextProps.permissionCopy.payload.result.group_clusters.map((value, index) => {
                            return value;
                        });
                        log.debug(nextProps.permissionCopy.payload.result.foreign_uuid);
                        log.debug(nextProps.permissionCopy.payload.result.foreign_uuid, "foreign_uuidの変更")
                        this.setState({
                            loading: nextProps.permissionCopy.meta.fetch,
                            groupCheckedKeys,
                            groupCheckedRows,
                            rowPermissionData: nextProps.permissionCopy.payload.result,
                            //uuidがないとそもそもpermissionを読みに行けないのでredundant
                            //uuid: "",
                            self_uuid: nextProps.permissionCopy.payload.result.uuid ? nextProps.permissionCopy.payload.result.uuid : undefined,
                            foreign_uuid: nextProps.permissionCopy.payload.result.foreign_uuid ? nextProps.permissionCopy.payload.result.foreign_uuid : this.props.foreign_uuid
                        })
                    }
                } else {
                    //権限エラーなので強制クローズ
                    this.props.handleCancel();
                }
            } else {
                this.setState({
                    loading: nextProps.permissionCopy.meta.fetch
                })
            }
        }


        if (nextProps.permissionCreate !== permissionCreate) {
            if (!nextProps.permissionCreate.meta.fetch) {
                if (!nextProps.permissionCreate.error) {
                    if (nextProps.permissionCreate.payload !== null) {
                        const groupCheckedKeys = nextProps.permissionCreate.payload.result.group_clusters.map((value, index) => {
                            let checkedKey = [];
                            if (value.trees.length !== 0) {
                                value.trees.forEach((value) => {
                                    value.permission_groups.forEach((value2) => {
                                        checkedKey.push(value2);
                                    })
                                })
                            }
                            return {permission_groups: checkedKey};
                        });
                        const groupCheckedRows = nextProps.permissionCreate.payload.result.group_clusters.map((value, index) => {
                            return value;
                        });
                        this.setState({
                            loading: nextProps.permissionCreate.meta.fetch,
                            groupCheckedKeys,
                            groupCheckedRows,
                            rowPermissionData: nextProps.permissionCreate.payload.result,
                            //uuidがないとそもそもpermissionを読みに行けないのでredundant
                            //uuid: "",
                            self_uuid: nextProps.permissionCreate.payload.result.uuid ? nextProps.permissionCreate.payload.result.uuid : undefined,
                            foreign_uuid: nextProps.permissionCreate.payload.result.foreign_uuid ? nextProps.permissionCreate.payload.result.foreign_uuid : this.props.foreign_uuid
                        })
                        //handleOK
                        //this.props.handleOk(nextProps.permissionCreate.payload.result, "permission");
                    }
                } else {
                    this.setState({
                        loading: nextProps.permissionCreate.meta.fetch
                    })
                }
            } else {
                this.setState({
                    loading: nextProps.permissionCreate.meta.fetch
                })
            }
        }


        if (nextProps.permissionUpdate !== permissionUpdate) {
            if (!nextProps.permissionUpdate.meta.fetch) {
                if (!nextProps.permissionUpdate.error) {
                    if (nextProps.permissionUpdate.payload !== null) {
                        //handleOK
                        const groupCheckedKeys = nextProps.permissionUpdate.payload.result.group_clusters.map((value, index) => {
                            let checkedKey = [];
                            if (value.trees.length !== 0) {
                                value.trees.forEach((value) => {
                                    value.permission_groups.forEach((value2) => {
                                        checkedKey.push(value2);
                                    })
                                })
                            }
                            return {permission_groups: checkedKey};
                        });
                        const groupCheckedRows = nextProps.permissionUpdate.payload.result.group_clusters.map((value, index) => {
                            return value;
                        });

                        this.setState({
                            loading: nextProps.permissionUpdate.meta.fetch,
                            groupCheckedKeys,
                            groupCheckedRows,
                            rowPermissionData: nextProps.permissionUpdate.payload.result,
                            //uuidがないとそもそもpermissionを読みに行けないのでredundant
                            //uuid: "",
                            self_uuid: nextProps.permissionUpdate.payload.result.uuid ? nextProps.permissionUpdate.payload.result.uuid : undefined,
                            foreign_uuid: nextProps.permissionUpdate.payload.result.foreign_uuid ? nextProps.permissionUpdate.payload.result.foreign_uuid : this.props.foreign_uuid
                        })
                        //this.props.handleOk(nextProps.permissionUpdate.payload.result, "permission");
                    }
                } else {
                    this.setState({
                        loading: nextProps.permissionUpdate.meta.fetch
                    })
                }
            } else {
                this.setState({
                    loading: nextProps.permissionUpdate.meta.fetch
                })
            }
        }

        if (nextProps.permissionUserAdd !== permissionUserAdd) {
            if (!nextProps.permissionUserAdd.meta.fetch) {
                if (!nextProps.permissionUserAdd.error) {
                    if (nextProps.permissionUserAdd.payload !== null) {
                        //handleOK
                        const groupCheckedKeys = nextProps.permissionUserAdd.payload.result.group_clusters.map((value, index) => {
                            let checkedKey = [];
                            if (value.trees.length !== 0) {
                                value.trees.forEach((value) => {
                                    value.permission_groups.forEach((value2) => {
                                        checkedKey.push(value2);
                                    })
                                })
                            }
                            return {permission_groups: checkedKey};
                        });
                        const groupCheckedRows = nextProps.permissionUserAdd.payload.result.group_clusters.map((value, index) => {
                            return value;
                        });

                        this.setState({
                            loading: nextProps.permissionUserAdd.meta.fetch,
                            groupCheckedKeys,
                            groupCheckedRows,
                            etag: this.state.etag + 1,
                            rowPermissionData: nextProps.permissionUserAdd.payload.result,
                            //uuidがないとそもそもpermissionを読みに行けないのでredundant
                            //uuid: "",
                            self_uuid: nextProps.permissionUserAdd.payload.result.uuid ? nextProps.permissionUserAdd.payload.result.uuid : undefined,
                            foreign_uuid: nextProps.permissionUserAdd.payload.result.foreign_uuid ? nextProps.permissionUserAdd.payload.result.foreign_uuid : this.props.foreign_uuid
                        })
                        //this.props.handleOk(nextProps.permissionUserAdd.payload.result, "permission");
                    }
                } else {
                    this.setState({
                        loading: nextProps.permissionUserAdd.meta.fetch
                    })
                }
            } else {
                this.setState({
                    loading: nextProps.permissionUserAdd.meta.fetch
                })
            }
        }

        if (nextProps.permissionUserDelete !== permissionUserDelete) {
            if (!nextProps.permissionUserDelete.meta.fetch) {
                if (!nextProps.permissionUserDelete.error) {
                    if (nextProps.permissionUserDelete.payload !== null) {
                        //handleOK
                        const groupCheckedKeys = nextProps.permissionUserDelete.payload.result.group_clusters.map((value, index) => {
                            let checkedKey = [];
                            if (value.trees.length !== 0) {
                                value.trees.forEach((value) => {
                                    value.permission_groups.forEach((value2) => {
                                        checkedKey.push(value2);
                                    })
                                })
                            }
                            return {permission_groups: checkedKey};
                        });
                        const groupCheckedRows = nextProps.permissionUserDelete.payload.result.group_clusters.map((value, index) => {
                            return value;
                        });

                        this.setState({
                            loading: nextProps.permissionUserDelete.meta.fetch,
                            groupCheckedKeys,
                            groupCheckedRows,
                            etag: this.state.etag + 1,
                            rowPermissionData: nextProps.permissionUserDelete.payload.result,
                            //uuidがないとそもそもpermissionを読みに行けないのでredundant
                            //uuid: "",
                            self_uuid: nextProps.permissionUserDelete.payload.result.uuid ? nextProps.permissionUserDelete.payload.result.uuid : undefined,
                            foreign_uuid: nextProps.permissionUserDelete.payload.result.foreign_uuid ? nextProps.permissionUserDelete.payload.result.foreign_uuid : this.props.foreign_uuid
                        })
                        //this.props.handleOk(nextProps.permissionUserDelete.payload.result, "permission");
                    }
                } else {
                    this.setState({
                        loading: nextProps.permissionUserDelete.meta.fetch
                    })
                }
            } else {
                this.setState({
                    loading: nextProps.permissionUserDelete.meta.fetch
                })
            }
        }

        if (nextProps.permissionUserAddAll !== permissionUserAddAll) {
            if (!nextProps.permissionUserAddAll.meta.fetch) {
                if (!nextProps.permissionUserAddAll.error) {
                    if (nextProps.permissionUserAddAll.payload !== null) {
                        //handleOK
                        const groupCheckedKeys = nextProps.permissionUserAddAll.payload.result.group_clusters.map((value, index) => {
                            return {target_groups: value.all_group_ids};
                        });
                        const groupCheckedRows = nextProps.permissionUserAddAll.payload.result.group_clusters.map((value, index) => {
                            return value;
                        });
                        const networkData = nextProps.permissionUserAddAll.payload.result.remote_addresses;

                        this.setState({
                            loading: nextProps.permissionUserAddAll.meta.fetch,
                            groupCheckedKeys,
                            groupCheckedRows,
                            networkData,
                            etag: this.state.etag + 1,
                            rowTargetData: nextProps.permissionUserAddAll.payload.result,
                            self_uuid: nextProps.permissionUserAddAll.payload.result.uuid ? nextProps.permissionUserAddAll.payload.result.uuid : undefined,
                            foreign_uuid: nextProps.permissionUserAddAll.payload.result.foreign_uuid ? nextProps.permissionUserAddAll.payload.result.foreign_uuid : this.props.foreign_uuid
                        })

                    }
                } else {
                    this.setState({
                        loading: nextProps.permissionUserAddAll.meta.fetch
                    })
                }
            } else {
                this.setState({
                    loading: nextProps.permissionUserAddAll.meta.fetch
                })
            }
        }

        if (nextProps.permissionUserAddCSV !== permissionUserAddCSV) {
            if (!nextProps.permissionUserAddCSV.meta.fetch) {
                if (!nextProps.permissionUserAddCSV.error) {
                    if (nextProps.permissionUserAddCSV.payload !== null) {
                        //handleOK
                        const groupCheckedKeys = nextProps.permissionUserAddCSV.payload.result.group_clusters.map((value, index) => {
                            return {target_groups: value.all_group_ids};
                        });
                        const groupCheckedRows = nextProps.permissionUserAddCSV.payload.result.group_clusters.map((value, index) => {
                            return value;
                        });
                        const networkData = nextProps.permissionUserAddCSV.payload.result.remote_addresses;

                        this.setState({
                            loading: nextProps.permissionUserAddCSV.meta.fetch,
                            groupCheckedKeys,
                            groupCheckedRows,
                            networkData,
                            etag: this.state.etag + 1,
                            rowTargetData: nextProps.permissionUserAddCSV.payload.result,
                            self_uuid: nextProps.permissionUserAddCSV.payload.result.uuid ? nextProps.permissionUserAddCSV.payload.result.uuid : undefined,
                            foreign_uuid: nextProps.permissionUserAddCSV.payload.result.foreign_uuid ? nextProps.permissionUserAddCSV.payload.result.foreign_uuid : this.props.foreign_uuid
                        })

                    }
                } else {
                    this.setState({
                        loading: nextProps.permissionUserAddCSV.meta.fetch
                    })
                }
            } else {
                this.setState({
                    loading: nextProps.permissionUserAddCSV.meta.fetch
                })
            }
        }


        if (nextProps.permissionUserDeleteAll !== permissionUserDeleteAll) {
            if (!nextProps.permissionUserDeleteAll.meta.fetch) {
                if (!nextProps.permissionUserDeleteAll.error) {
                    if (nextProps.permissionUserDeleteAll.payload !== null) {
                        //handleOK
                        const groupCheckedKeys = nextProps.permissionUserDeleteAll.payload.result.group_clusters.map((value, index) => {
                            let checkedKey = [];
                            if (value.trees.length !== 0) {
                                value.trees.forEach((value) => {
                                    value.permission_groups.forEach((value2) => {
                                        checkedKey.push(value2);
                                    })
                                })
                            }
                            return {permission_groups: checkedKey};
                        });
                        const groupCheckedRows = nextProps.permissionUserDeleteAll.payload.result.group_clusters.map((value, index) => {
                            return value;
                        });

                        this.setState({
                            loading: nextProps.permissionUserDeleteAll.meta.fetch,
                            groupCheckedKeys,
                            groupCheckedRows,
                            etag: this.state.etag + 1,
                            rowPermissionData: nextProps.permissionUserDeleteAll.payload.result,
                            //uuidがないとそもそもpermissionを読みに行けないのでredundant
                            //uuid: "",
                            self_uuid: nextProps.permissionUserDeleteAll.payload.result.uuid ? nextProps.permissionUserDeleteAll.payload.result.uuid : undefined,
                            foreign_uuid: nextProps.permissionUserDeleteAll.payload.result.foreign_uuid ? nextProps.permissionUserDeleteAll.payload.result.foreign_uuid : this.props.foreign_uuid
                        })
                        //this.props.handleOk(nextProps.permissionUserDeleteAll.payload.result, "permission");
                    }
                } else {
                    this.setState({
                        loading: nextProps.permissionUserDeleteAll.meta.fetch
                    })
                }
            } else {
                this.setState({
                    loading: nextProps.permissionUserDeleteAll.meta.fetch
                })
            }
        }


        if (!nextProps.visible) {
            //初期化処理
            this.setState({
                groupCheckedKeys: [],
                groupCheckedRows: [],
                userCheckedKeys: [],
                userCheckedRows: [],
                userNetworkKeys: [],
                self_uuid: null,
                foreign_uuid: null,
                type: "",
                currentActiveKey: "2",
                loading: false,
                confirmLoading: false,
            })
        }
    }


    generatePostData() {

        const permission_group_clusters = this.state.groupCheckedKeys;
        const permission_users = this.state.userCheckedKeys;
        const foreign_uuid = this.state.foreign_uuid;

        let object = {
            type: this.state.type,
            permission_group_clusters,
            permission_users
        }

        return object;
    }

    handleOk = () => {

        this.props.handleOk(this.state.rowPermissionData, "permission", this.props.key);
        /*
        this.setState({
            confirmLoading: true,
        });
        if (!this.state.self_uuid && this.state.foreign_uuid) {
            //自身のuuidが無いので新規作成
            this.props.createPermission(this.generatePostData());
        } else if (this.state.self_uuid && this.state.foreign_uuid) {
            //自身のuuidは存在するので上書き
            this.props.updatePermission(this.state.self_uuid, this.generatePostData());
        }else{
            //要素も新規、対象選択も新規で構築　（構築後、元画面に対象選択のuuidを渡すのでFormで送る必要あり）
            this.props.createPermission(this.generatePostData());
        }
        */
    };

    addUserAllTarget = (data) => {
        log.debug(data);
        this.props.addUserAllPermission(this.state.self_uuid, data)
    };

    addUserCSVTarget = (data) => {
        log.debug(data);
        this.props.addUserCsvPermission(this.state.self_uuid, data)
    };


    handleCancel = () => {
        this.props.handleCancel()
    };


    //ここから
    setGroup = (keys, data) => {

        this.setState({
            groupCheckedKeys: keys,
            groupCheckedRows: data,
            loading: true
        }, () => {
            if (!this.state.self_uuid && this.state.foreign_uuid) {
                //自身のuuidが無いので target_uuidのみ新規作成
                this.props.createPermission(this.generatePostData());
            } else if (this.state.self_uuid) {
                //自身のuuidは存在するので上書き
                this.props.updatePermission(this.state.self_uuid, this.generatePostData());
            } else {
                //要素も新規、対象選択も新規で構築　（構築後、元画面に対象選択のuuidを渡す）
                this.props.createPermission(this.generatePostData());
            }
        });
    };

    setUsers = (keys, data) => {
        this.setState({
            userCheckedKeys: keys,
            userCheckedRows: data
        }, () => {
            if (!this.state.self_uuid && this.state.foreign_uuid) {
                //自身のuuidが無いので target_uuidのみ新規作成
                this.props.createPermission(this.generatePostData());
            } else if (this.state.self_uuid) {
                this.props.addUserPermission(this.state.self_uuid, {
                    user_uuids: this.state.userCheckedKeys
                });
            } else {
                //要素も新規、対象選択も新規で構築　（構築後、元画面に対象選択のuuidを渡す）
                this.props.createPermission(this.generatePostData());
            }
        });
    };

    deleteUsers = (userArray) => {
        this.props.deleteUserPermission(this.state.self_uuid, {
            user_uuids: userArray
        });
    };

    deleteUserAll = () => {
        this.props.deleteUserAllPermission(this.state.self_uuid);
    }


    setNetwork = (data) => {
        log.debug(data);
        this.setState({
            networkData: data
        });
    };


    handleOpenPreview = () => {
        this.setState({
            previewModalIsOpen: true
        })
    };

    onChangeTab = (value) => {
        this.setState({
            currentActiveKey: value,
            etag: this.state.etag + 1
        })
    }

    //ここまで
    render() {
        const {visible, confirmLoading} = this.state;
        return (
            <Modal
                forceRender
                className="common-modal select-target-form"
                title={<span><UserOutlined/> {this.state.title}権限者選択</span>}
                visible={visible}
                onOk={this.handleOk}
                maskClosable={false}
                confirmLoading={confirmLoading}
                onCancel={this.handleCancel}
                footer={[
                    <Button key="back-select-permission-form" size="large" onClick={this.handleCancel}>保存せずに閉じる</Button>,
                    <Button key="preview-select-permission-form" size="large"
                            onClick={this.handleOpenPreview}>{this.state.title}権限者を確認</Button>,
                    <Button key="create-select-permission-form" type={"primary"} size="large"
                            onClick={this.handleOk}>保存</Button>
                ]}>
                <Tabs
                    className="content-tab"
                    defaultActiveKey="2"
                    activeKey={this.state.currentActiveKey}
                    onChange={this.onChangeTab}
                    animated={false}>
                    <TabPane tab={<span><TeamOutlined/>{this.state.title}権限グループ</span>} key="1">

                        <SelectTargetGroupList
                            type="permission"
                            visible={visible}
                            setObject={this.setGroup}
                            loading={this.state.loading}
                            checkedKeys={this.state.groupCheckedKeys}
                            checkedRows={this.state.groupCheckedRows}/>

                    </TabPane>
                    <TabPane tab={<span><UserOutlined/>{this.state.title}権限ユーザ</span>} key="2">
                        <SelectTargetFromUsers
                            setObject={this.setUsers}
                            type="permission"
                            visible={visible}
                            loading={this.state.loading}
                            self_uuid={this.state.self_uuid}
                            etag={this.state.etag}
                            deleteUsers={this.deleteUsers}
                            addUserAll={this.addUserAllTarget}
                            addUserCSV={this.addUserCSVTarget}
                            deleteUserAll={this.deleteUserAll}
                        />
                    </TabPane>

                    {this.props.type === 3 || this.props.type === 4 || this.props.type === 5 ?
                        <TabPane tab={<span><EnvironmentOutlined/>ネットワーク制限</span>} key="3">
                            <SelectTargetFormIPAddress
                                visible={visible}
                                setObject={this.setNetwork}
                                netWorkData={this.state.networkData}/>
                        </TabPane>
                        :
                        null
                    }
                </Tabs>


                <SelectTargetPreview
                    visible={this.state.previewModalIsOpen}
                    self_uuid={this.state.self_uuid}
                    type="permission"
                    handleOk={() => {
                        this.setState({
                            previewModalIsOpen: false
                        })
                    }}
                    handleCancel={() => {
                        this.setState({
                            previewModalIsOpen: false
                        })
                    }}
                />
            </Modal>
        );
    };
}


SelectPermissionForm.propTypes = {
    visible: PropTypes.bool.isRequired,
    handleOk: PropTypes.func.isRequired,
    handleCancel: PropTypes.func.isRequired,
    self_uuid: PropTypes.string,
    foreign_uuid: PropTypes.string
};


export default connect(
    mapStateToProps,
    mapDispatchToProps
)(SelectPermissionForm);