import "./polyfills";
import React from 'react'
import ReactDOM from 'react-dom'
import {compose, createStore, combineReducers, applyMiddleware} from 'redux'
import {Provider} from 'react-redux'
import {
    BrowserRouter as Router,
} from "react-router-dom";
import { Progress, reducer } from 'react-progress-2-redux'
import * as middleware from './redux/middleware'
import * as reducers from './redux/reducers'
import thunk from 'redux-thunk'
import { ConfigProvider } from 'antd';
import jaJP from 'antd/lib/locale-provider/ja_JP';
import "./sass/antd.less";
import './sass/index.scss';
import ErrorNoticeComponent from "./components/ErrorNoticeComponent";
import InitializeRouter from "./InitializeRouter";
import LoginEffectComponent from "./containers/login/LoginEffectComponent";



const store = createStore(
    combineReducers({
        ...reducers
    }),
    applyMiddleware(
        thunk,
        middleware.connection,
        middleware.middleware,
        middleware.nextConnection
    )
)

process.env.NODE_ENV !== "development" && (console.log = () => {});

ReactDOM.render(
    <Provider store={store}>
        <ConfigProvider locale={jaJP}>
            <div>
                <LoginEffectComponent/>
                <ErrorNoticeComponent />
                <Progress />
                <Router>
                    <InitializeRouter />
                </Router>
            </div>
        </ConfigProvider>
    </Provider>,
    document.getElementById('root')
);
