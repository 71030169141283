import React, { Component } from 'react'
import PropTypes from 'prop-types';

import {Link} from 'react-router-dom';

import { Icon as LegacyIcon } from '@ant-design/compatible';
import { HomeOutlined } from '@ant-design/icons';

import { Breadcrumb } from 'antd';
import log from 'components/utils/Logger';

/**
 * コンテンツヘッダ
 */
class ContentHeader extends Component {

    constructor(props) {
        super(props);
    }

    componentWillReceiveProps(nextProps){
        log.debug(nextProps);
    }

    render() {
        // パンくずリスト処理
        let breadCrumbNode = [];
        let {breadCrumbItems} = this.props;

        for(let i in breadCrumbItems) {
            breadCrumbNode.push(
                <Breadcrumb.Item key={i}>
                    <Link to={breadCrumbItems[i].path}>
                        <LegacyIcon type={breadCrumbItems[i].icon} />
                        <span> {breadCrumbItems[i].title}</span>
                    </Link>
                </Breadcrumb.Item>
            );
        }

        return (
            <div id="content-header">
                <div className="logo" />
                <h1>{this.props.title}</h1>
                <Breadcrumb separator=">">
                    <Breadcrumb.Item>
                        {this.props.disableHomeLink ?
                            <>
                                <HomeOutlined/>
                                <span> ホーム</span>
                            </>
                            :
                            <Link to="/dashboard/">
                                <HomeOutlined/>
                                <span> ホーム</span>
                            </Link>
                        }
                    </Breadcrumb.Item>
                    {breadCrumbNode}
                </Breadcrumb>
            </div>
        );
    }

}

ContentHeader.propTypes = {
    breadCrumbItems: PropTypes.array,
    disableHomeLink: PropTypes.bool
};

ContentHeader.defaultProps = {
    breadCrumbItems : [],
    disableHomeLink: false
};

export default ContentHeader