import React from 'react'
import {connect, store} from 'react-redux'
import PropTypes from 'prop-types';
import {instanceOf} from 'prop-types';
import log from 'components/utils/Logger.js'

import moment from 'moment';
import 'moment/locale/ja';
import { InboxOutlined, LinkOutlined, LoadingOutlined, FolderFilled} from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Table, Tabs, Collapse, Card, Button } from 'antd';
import * as Actions from 'redux/actions';
import * as TagNodeGenerator from 'components/utils/TagNodeGenerator'

import { Scrollbars } from 'react-custom-scrollbars';
moment.locale('ja')

const TabPane = Tabs.TabPane;
const FormItem = Form.Item;
const Panel = Collapse.Panel;

const mapStateToProps = (state, props) => {
    return {
        screen: state.screen,
        menu: state.menu
    }
};

const mapDispatchToProps = dispatch => {
    return {
    }
};

class ModuleLink extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            data: props.data,
            parts: props.parts,
            loading: false,
        };
    }

    componentWillMount(){
        //this.props.searchSSO();
    }

    componentWillReceiveProps(nextProps) {
        log.debug(nextProps.parts);
        this.setState({
            data: nextProps.data,
            parts: nextProps.parts
        });

        if (this.state.searchLoading !== nextProps.menu.meta.fetch) {
            this.setState({
                searchLoading: nextProps.menu.meta.fetch
            })
        }
    }

    handleClickSeeMore = () => {

    };


    handleRowClick = (record, index, event) => {
        log.debug(record.open_new_window);

        this.setState({
            singleModalIsOpen: true,
            selectRowData: record,
            selectRowTitle: record.title
        })
    };


    render() {
        /*
        const parts = this.state.parts.map((value) => {
            if(!value.uuid){
                const ssoValue =  this.state.sso.find((ssoValue,index)=> {
                    return ssoValue.uuid === value.uuid;
                })
                const object = {};
                object.url = '/api/sso-link/jump/' + ssoValue.uuid;
                object.text = ssoValue.name;
            }else{
                return value;
            }
        });
        */

        const titleNode = (
            <div>
                <span className="module-title"><FolderFilled /> {this.props.title}</span>
                {this.props.seeMore? <Button className="module-title-button" onClick={this.handleClickSeeMore}>すべて表示</Button> : <span /> }
            </div>
        );


        const columns = [{
            dataIndex: 'key',
            key: 'key',
            render: (text, record) => {
                if(record.open_new_window === true) {
                    // 新しいウインドウで開く
                    return (
                        <div className="module-cell module-link module-notification">
                            <div className="module-cell-body module-link-body　module-notification" onClick={() => {
                                window.open(record.url ,'_blank');
                            }}><LinkOutlined />&nbsp;{record.text}</div>
                        </div>
                    );

                }else{
                    // 現在のウインドウで開く
                    return (
                        <div className="module-cell module-link module-notification">
                            <div className="module-cell-body module-link-body　module-notification" onClick={() => {
                                window.location.assign(record.url);
                            }}><LinkOutlined />&nbsp;{record.text}</div>
                        </div>
                    );
                }

            }
        }];

        return (
            <Card className="module-card" title={titleNode}>
                <Scrollbars autoHide>
                    <div className="module-inner module-link">
                        <Table
                            className="general-table"
                            locale={{
                                filterTitle: 'フィルタ',
                                filterConfirm: '確定',
                                filterReset: 'リセット',
                                emptyText: this.state.searchLoading ?
                                    <span><LoadingOutlined />&nbsp;読み込み中</span>:
                                    <span><InboxOutlined />&nbsp;リンクはありません</span>,
                            }}
                            pagination={false}
                            bordered={true}
                            showHeader={false}
                            columns={columns}
                            dataSource={this.state.parts}
                            size="small"
                            onRow={(record, rowIndex) => {
                                return {
                                    onClick: event => {
                                        this.handleRowClick(record)
                                    }
                                };
                            }}
                        />
                    </div>
                </Scrollbars>

            </Card>
        );
    }
}



export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ModuleLink)
