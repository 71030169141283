import React from 'react';
import {instanceOf} from 'prop-types';
import {connect} from 'react-redux'
import * as Actions from 'redux/actions'
import {
    Route,
    Switch,
    Redirect,
    withRouter
} from 'react-router-dom'
import * as GlobalConfig from './constants/GlobalConfig';

import AdminMenu from "./containers/menu/AdminMenu";
import AdminHome from './containers/home/AdminHome'
import Notification from './containers/notification/Notifcation'
import Users from './containers/users/Users'
import Services from './containers/services/Services'
import SSO from './containers/sso/SSO'

import LogSearch from './containers/log/LogSearch'
import CalendarComponent from './containers/calendars/Calendar'
import Maintenance from './containers/maintenance/Maintenance'
import NotFound from './components/NotFound'
import Footer from './components/Footer'

import Helmet from 'react-helmet'

import Message from 'containers/message/Message'
import moment from 'moment';
import 'moment/locale/ja';

moment.locale('ja')


const mapStateToProps = (state, props) => {
    return {
        logout: state.logout,
        session: state.session,
        check: state.check
    }
};

const mapDispatchToProps = dispatch => {
    return {
        windowResize: (dataHeight, dataWidth) => {
            dispatch(Actions.Config.screen(dataHeight, dataWidth));
        },
        checkLoggedIn: () => {
            dispatch(Actions.Connection.check.request());
        },
        setPreviewURL: (url)=> {
            dispatch(Actions.setPreviewURL(url));
        }
    }
};


class Admin extends React.Component {
    constructor(props) {
        super(props);

        this.props.windowResize(window.innerHeight, window.innerWidth);
        window.addEventListener('resize', () => {
            this.props.windowResize(window.innerHeight, window.innerWidth);
        });
        this.state = {
            session: props.session,
            checked: false,
            loggedIn: false,
            pathName: props.location.pathname
        }
        props.setPreviewURL(props.location.pathname);
        this.props.checkLoggedIn();
    }


    componentWillReceiveProps(nextProps) {
        if (!nextProps.check.meta.fetch){
            if (!nextProps.check.error) {
                if (nextProps.check.payload !== null) {
                    this.setState({
                        checked: true,
                        loggedIn: nextProps.check.payload.is_successful
                    })
                }
            }
        }
        if (nextProps.location.pathname !== this.props.location.pathname) {
            this.setState({
                pathName: nextProps.location.pathname
            })
        }

    }

    componentWillMount(){
        document.body.classList.add('default-body');
    }

    componentWillUnmount(){
        document.body.classList.remove('default-body');
    }


    render() {

        const authenticatedComponent = (
            <div id="body-wrapper">
                <div id="app">
                    <AdminMenu pathName={this.state.pathName}/>
                    <div id="app-contents">
                        <Switch>
                            <Route exact path={`${GlobalConfig.AdminSubDirectory}`} render={props => (
                                <div>
                                    <Helmet>
                                        <title>インフォメーションシステム管理 - {GlobalConfig.ServiceTitle}</title>
                                    </Helmet>
                                    <AdminHome/>
                                </div>
                            )}/>
                            <Route exact path={`${GlobalConfig.AdminSubDirectory}/notifications`} render={props => (
                                <div>
                                    <Helmet>
                                        <title>お知らせ管理 - {GlobalConfig.ServiceTitle}</title>
                                    </Helmet>
                                    <Notification/>
                                </div>
                            )}/>
                            <Route exact path={`${GlobalConfig.AdminSubDirectory}/calendars`} render={props => (
                                <div>
                                    <Helmet>
                                        <title>学年暦管理 - {GlobalConfig.ServiceTitle}</title>
                                    </Helmet>
                                    <CalendarComponent/>
                                </div>
                            )}/>
                            <Route exact path={`${GlobalConfig.AdminSubDirectory}/services`} render={props => (
                                <div>
                                    <Helmet>
                                        <title>メニュー管理 - {GlobalConfig.ServiceTitle}</title>
                                    </Helmet>
                                    <Services/>
                                </div>
                            )}/>
                            <Route exact path={`${GlobalConfig.AdminSubDirectory}/sso`} render={props => (
                                <div>
                                    <Helmet>
                                        <title>SSO連携管理 - {GlobalConfig.ServiceTitle}</title>
                                    </Helmet>
                                    <SSO/>
                                </div>
                            )}/>
                            <Route exact path={`${GlobalConfig.AdminSubDirectory}/users`} render={props => (
                                <div>
                                    <Helmet>
                                        <title>利用者管理 - {GlobalConfig.ServiceTitle}</title>
                                    </Helmet>
                                    <Users/>
                                </div>
                            )}/>
                            <Route exact path={`${GlobalConfig.AdminSubDirectory}/log`} render={props => (
                                <div>
                                    <Helmet>
                                        <title>ログ検索 - {GlobalConfig.ServiceTitle}</title>
                                    </Helmet>
                                    <LogSearch/>
                                </div>
                            )}/>
                            <Route exact path={`${GlobalConfig.AdminSubDirectory}/maintenance`} render={props => (
                                <div>
                                    <Helmet>
                                        <title>メンテナンス - {GlobalConfig.ServiceTitle}</title>
                                    </Helmet>
                                    <Maintenance/>
                                </div>
                            )}/>
                            <Route status={404}
                                   render={props => (
                                       <div>
                                           <Helmet>
                                               <title>ページが見つかりません - {GlobalConfig.ServiceTitle}</title>
                                               <meta name="robots" content="noindex"/>
                                           </Helmet>
                                           <NotFound/>
                                       </div>
                                   )}
                            />
                        </Switch>
                    </div>
                </div>
                <Footer/>
                <Message/>
            </div>
        );

        if(this.props.session.payload.isAuthenticated){
            switch(this.props.session.payload.user.permission){
                case "student":
                    this.props.history.push(GlobalConfig.UserSubDirectory)
                    break;
                default:
                    break;
            }
        }



        return (
            <Route render={props => {
                return (
                    this.state.checked ? (
                            !this.state.loggedIn ? (
                                    <Redirect
                                        to={{
                                            pathname: '/login'
                                        }}
                                    />
                                ) :
                            (authenticatedComponent)

                        )
                        :
                        <Message />
                )
            }
            }/>
        );
    }
}


Admin.propTypes = {};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(Admin))