import React, {Component} from 'react';
import PropTypes from 'prop-types';
import { DownloadOutlined, InboxOutlined, LoadingOutlined } from '@ant-design/icons';
import { Collapse, Tooltip, Table, Button } from 'antd';

import {Scrollbars} from 'react-custom-scrollbars';
import * as Actions from '../../redux/actions'
import {connect, store} from 'react-redux'
import * as TagNodeGenerator from '../../components/utils/TagNodeGenerator'
import renderHTML from 'react-render-html';
import moment from 'moment';
import 'moment/locale/ja';
import log from "components/utils/Logger";

moment.locale('ja');
const Panel = Collapse.Panel;

const mapStateToProps = (state, props) => {
    return {
        screen: state.screen,
        notification: state.notificationGuest,
    }
};

const mapDispatchToProps = dispatch => {
    return {
        getNotificationGuest: (data) => {
            dispatch(Actions.Connection.notification.guest.request(data));
        }
    }
};

class ListAdapterLogin extends Component {
    constructor(props) {
        super(props)
        this.state = {
            resultNotificationArray: []
        }
    }

    componentDidMount() {
        this.props.getNotificationGuest("");
    }

    componentWillReceiveProps(nextProps) {

        if (!nextProps.notification.meta.fetch) {
            if (!nextProps.notification.error) {
                if (nextProps.notification.payload !== null) {
                    this.setState({
                        searchLoading: nextProps.notification.meta.fetch,
                        resultNotificationArray: nextProps.notification.payload.result.items,
                        //通信後
                    })
                }
            }
        } else {
            this.setState({
                searchLoading: nextProps.notification.meta.fetch
            })
        }

        this.setState({
            screenSize: nextProps.screen
        })

    }

    render() {
        let list_user_panel;

        const customPanelStyle = {
            borderRadius: 0,
            border: 0,
        };
        const columns = [{
            title: '添付ファイル',
            dataIndex: "file_name",
            key: "file_name"
        }, {
            dataIndex: 'attachment',
            key: 'attachment',
            width: "50px",
            render: (text, record) => {

                return (
                    <Tooltip placement="topLeft" title="ファイルダウンロード">
                        <Button type="default" shape="circle" icon={<DownloadOutlined />}
                                onClick={() => window.open(`${process.env.REACT_APP_FILE_DOMAIN}/file/view/${record.uuid}`, '_blank')}/>
                    </Tooltip>
                );
            }
        }];

        list_user_panel = this.state.resultNotificationArray.map((value, index) => {

                const tagNode = TagNodeGenerator.generate(TagNodeGenerator.TYPES_NOTIFICATION_PRIORITY, value.priority);
                const created_at = moment.unix(value.created_at).format("YYYY.MM.DD");

                const test = (
                    <div className="login-notification-header">
                        <div className="login-notification-header-box">
                            <div className="login-notification-header-date">{created_at}</div>
                            <div className="tags-wrapper">
                                {tagNode}
                            </div>
                        </div>
                        <div className="login-notification-header-title">{value.title}</div>
                        <div className="publish-from-wapper">{value.publish_from}</div>
                    </div>
                );

                return (
                    <Panel header={test} key={index} style={customPanelStyle} className="login-notification-body">
                        <div className="login-notification-body-content">{renderHTML(value.content)}</div>
                        {value.files !== null ?
                            <div className="notification-preview-download">
                                <Table
                                    className="general-table"
                                    locale={{
                                        filterTitle: 'フィルタ',
                                        filterConfirm: '確定',
                                        filterReset: 'リセット',
                                        emptyText: '該当するものはありません',
                                    }}
                                    bordered={true}
                                    pagination={false}
                                    columns={columns}
                                    dataSource={value.files}
                                    size="middle"
                                />
                            </div> :
                            null}
                    </Panel>
                )
            }
        );

        if (this.state.searchLoading === true) {
            // お知らせ読み込み中
            return <div><LoadingOutlined /> お知らせを読み込んでいます...</div>;
        } else {
            //読み込み完了
            return (
                //横幅サイズに応じてScrollbarsに内包させるさせないを決定
                //スマホサイズの場合は全て縦並びにするためにスクロールバーを使う必要がなくなる為
                this.props.screen.payload.screenWidth <= 1023 ?
                    this.state.resultNotificationArray.length === 0 ?
                        <div><InboxOutlined /> 現在配信されているお知らせはありません</div> :
                        <Collapse defaultActiveKey={[]} className="collapse_panel_wrapper">
                            {list_user_panel}
                        </Collapse>
                         :
                    this.state.resultNotificationArray.length === 0 ?
                        <div><InboxOutlined /> 現在配信されているお知らせはありません</div> :
                        <Scrollbars autoHide>
                            <Collapse defaultActiveKey={[]} className="collapse_panel_wrapper">
                                {list_user_panel}
                            </Collapse>
                        </Scrollbars>
            );
        }

    }
}

ListAdapterLogin.propTypes = {
    list: PropTypes.array,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ListAdapterLogin)
