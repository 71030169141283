import React from 'react'
import {connect, store} from 'react-redux'
import {instanceOf} from 'prop-types';
import log from 'components/utils/Logger.js';
import AddGroupModal from 'containers/common/AddGroupModal';

import * as Actions from '../../redux/actions';
import moment from 'moment';
import 'moment/locale/ja';
import { UserOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Modal, Spin, Radio, Select, Input, Table, Button, DatePicker, Checkbox } from 'antd';
import * as GlobalConfig from '../../constants/GlobalConfig'
import jaJP from 'antd/lib/date-picker/locale/ja_JP';

moment.locale('ja');
const FormItem = Form.Item;
const Option = Select.Option;
const RadioButton = Radio.Button;
const RadioGroup = Radio.Group;
const RangePicker = DatePicker.RangePicker;
const {TextArea} = Input;
const CheckboxGroup = Checkbox.Group;

const mapStateToProps = (state, props) => {
    return {
        userData: state.userView
    }
};

const mapDispatchToProps = dispatch => {
    return {
        userView: (uuid) => {
            dispatch(Actions.AdminConnection.user.view.request(uuid));
        }
    }
};

class UserEditFormModal extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            visible: props.visible,
            loading: true,
            selectedRows: [],
            selectedKeys: [],
        };
    }

    componentWillReceiveProps(nextProps) {
        this.setState({
            visible: nextProps.visible,
            uuid: nextProps.uuid
        });

        if (nextProps.visible && !this.props.visible) {
            if (this.state.uuid !== undefined) {
                this.props.userView(nextProps.uuid);
            } else {
                // Error
                this.setState({
                    visible: false
                });
            }
        };

        if (nextProps.userData !== this.props.userData) {
            if (!nextProps.userData.meta.fetch) {
                if (!nextProps.userData.error) {
                    if (nextProps.userData.payload !== null) {
                        const userData = nextProps.userData.payload.result;
                        const loading = nextProps.userData.meta.fetch;
                        const uuid = userData.uuid;
                        const user_id = userData.user_id;
                        const personal_id = userData.personal_id;
                        const name = userData.name;
                        const name_kana = userData.name_kana;
                        const name_alphabet = userData.name_alphabet;
                        const is_login_enabled = userData.is_login_enabled;
                        const has_force_questionnaire = userData.has_force_questionnaire;
                        const groups = userData.groups;
                        const auth_profiles = userData.auth_profiles;
                        const auth_profiles_uuid = userData.auth_profiles.uuid;
                        const memo = userData.memo;
                        const email = userData.email;
                        const start_at = userData.start_at ? userData.start_at : "";
                        const end_at = userData.end_at ? userData.end_at : "";
                        const range_picker = userData ? [moment.unix(start_at), moment.unix(end_at)] : undefined;
                        const checkedRows = userData.groups ? userData.groups: [];
                        const checkedKeys = checkedRows.map((value) => {
                            return value.uuid
                        });
                        const option_1 = userData.option_1 ? userData.option_1 : "";
                        const option_2 = userData.option_2 ? userData.option_2 : "";
                        this.setState({
                            userData,
                            loading,
                            uuid,
                            user_id,
                            personal_id,
                            name,
                            name_kana,
                            name_alphabet,
                            email,
                            is_login_enabled,
                            has_force_questionnaire,
                            auth_profiles,
                            auth_profiles_uuid,
                            groups,
                            memo,
                            start_at,
                            end_at,
                            range_picker,
                            checkedRows,
                            checkedKeys,
                            option_1,
                            option_2
                        });

                    } else {
                        this.setState({
                            loading: nextProps.userData.meta.fetch
                        })
                    }
                } else {
                    this.setState({
                        loading: nextProps.userData.meta.fetch
                    })
                }
            } else {
                this.setState({
                    loading: nextProps.userData.meta.fetch
                })
            }
        }

        if (!nextProps.visible) {
            const userData = {};
            const uuid = "";
            const user_id = "";
            const personal_id = "";
            const name = "";
            const name_kana = "";
            const name_alphabet = "";
            const is_login_enabled = "";
            const has_force_questionnaire = "";
            const groups = "";
            const auth_profiles = "";
            const auth_profiles_uuid = "";
            const memo = "";
            const email = "";
            const password = "";
            const confirm = "";

            const option_1 = "";
            const option_2 = "";

            const start_at = "";
            const end_at = "";
            const range_picker = undefined;
            const checkedRows = [];
            const checkedKeys = [];

            this.setState({
                userData,
                loading: true,
                uuid,
                user_id,
                personal_id,
                name,
                email,
                password,
                confirm,
                name_kana,
                name_alphabet,
                is_login_enabled,
                has_force_questionnaire,
                auth_profiles,
                auth_profiles_uuid,
                groups,
                memo,
                start_at,
                end_at,
                range_picker,
                checkedKeys,
                checkedRows,
                option_1,
                option_2
            });
            this.props.form.resetFields();
        }
    };


    handleOk = (fieldsValue,data) => {
        this.props.form.validateFields((err, values) => {
            if (!err) {
                this.setState({
                    confirmLoading: true,
                });
                const range_picker = fieldsValue.range_picker;
                if (typeof range_picker !== 'undefined' && range_picker.length !== 0) {
                    fieldsValue.start_at = range_picker[0].unix();
                    fieldsValue.end_at = range_picker[1].unix();
                    delete fieldsValue.range_picker;
                }
                fieldsValue.groups = this.state.checkedKeys;
                if(fieldsValue.auth_profile_uuid === "portal-site"){
                    //ローカルユーザのさいはauth_profiles_uuidを送らない
                    delete fieldsValue.auth_profile_uuid;
                }
                delete fieldsValue.confirm;

                this.props.handleOk(fieldsValue,data);
            }
        });
    };


    handleCancel = () => {
        this.props.handleCancel();
    };


    checkPassword = (rule, value, callback) => {
        const form = this.props.form;
        if (value && value !== form.getFieldValue('password')) {
            callback('パスワードが一致しません');
        } else {
            callback();
        }
    }
    checkConfirm = (rule, value, callback) => {
        const form = this.props.form;
        if (value && this.state.confirmDirty) {
            form.validateFields(['confirm'], { force: true });
        }
        callback();
    }

    render() {
        const {getFieldDecorator, getFieldValue} = this.props.form;
        const formItemLayout = {
            labelCol: {
                xs: {span: 24},
                sm: {span: 4},
            },
            wrapperCol: {
                xs: {span: 24},
                sm: {span: 20},
            },
        };

        const columns = [{
            title: '所属グループ名',
            dataIndex: 'name',
            key: 'name',
        },{
            title: '所属グループUUID',
            dataIndex: 'uuid',
            key: 'uuid',
        }];

        return (
            <Modal
                className="common-modal select-target-form"
                title={<span><UserOutlined />&nbsp;ユーザ情報の編集</span>}
                visible={this.state.visible}
                maskClosable={false}
                onOk={() => this.handleOk(this.props.form.getFieldsValue(),this.state.userData)}
                onCancel={this.handleCancel}>
                <div className='wrapper'>

                    <Spin spinning={this.state.loading}>
                        <Form className="search-form" onSubmit={this.onSubmit}>
                            <FormItem
                                {...formItemLayout}
                                label="利用者ID">
                                {getFieldDecorator('user_id', {
                                    initialValue: this.state.user_id,
                                    rules: [{required: true, message: '利用者IDが必須です'}],
                                })(
                                    <Input placeholder="利用者IDを入力してください"
                                           onChange={(e) => {
                                               this.setState({
                                                   user_id: e.target.value
                                               })
                                           }}/>
                                )}
                            </FormItem>
                            <FormItem
                                {...formItemLayout}
                                label="学籍番号10桁コード">
                                {getFieldDecorator('personal_id', {
                                    initialValue: this.state.personal_id,
                                    rules: [{required: false, message: '学籍番号10桁コードが必須です'}],
                                })(
                                    <Input placeholder="学籍番号10桁コードを入力してください"
                                           onChange={(e)=>{
                                               this.setState({
                                                   personal_id: e.target.value
                                               })
                                           }}/>
                                )}
                            </FormItem>

                            <FormItem
                                {...formItemLayout}
                                label="日本語学籍番号（例：法２１−１）">
                                {getFieldDecorator('option_1', {
                                    initialValue: this.state.option_1,
                                    rules: [{required: false, message: '日本語学籍番号（例：法２１−１）が必須です'}],
                                })(
                                    <Input placeholder="日本語学籍番号（例：法２１−１）を入力してください"
                                           onChange={(e)=>{
                                               this.setState({
                                                   option_1: e.target.value
                                               })
                                           }}/>
                                )}
                            </FormItem>

                            <FormItem
                                {...formItemLayout}
                                label="教職員番号">
                                {getFieldDecorator('option_2', {
                                    initialValue: this.state.option_2,
                                    rules: [{required: false, message: '教職員番号が必須です'}],
                                })(
                                    <Input placeholder="教職員番号を入力してください"
                                           onChange={(e)=>{
                                               this.setState({
                                                   option_2: e.target.value
                                               })
                                           }}/>
                                )}
                            </FormItem>



                            <FormItem
                                {...formItemLayout}
                                label="氏名">
                                {getFieldDecorator('name', {
                                    initialValue: this.state.name,
                                    rules: [{required: true, message: '氏名が必須です'}],
                                })(
                                    <Input placeholder="氏名を入力してください"
                                           onChange={(e)=>{
                                               this.setState({
                                                   name: e.target.value
                                               })
                                           }}/>
                                )}
                            </FormItem>

                            <FormItem
                                {...formItemLayout}
                                label="氏名カナ">
                                {getFieldDecorator('name_kana', {
                                    initialValue: this.state.name_kana,
                                    rules: [{required: true, message: '氏名カナが必須です'}],
                                })(
                                    <Input placeholder="氏名カナを入力してください"
                                           onChange={(e)=>{
                                               this.setState({
                                                   name_kana: e.target.value
                                               })
                                           }}/>
                                )}
                            </FormItem>

                            <FormItem
                                {...formItemLayout}
                                label="氏名アルファベット">
                                {getFieldDecorator('name_alphabet', {
                                    initialValue: this.state.name_alphabet,
                                    rules: [{required: false}],
                                })(
                                    <Input placeholder="氏名アルファベットを入力してください"
                                           onChange={(e)=>{
                                               this.setState({
                                                   name_alphabet: e.target.value
                                               })
                                           }}/>
                                )}
                            </FormItem>


                            <FormItem
                                {...formItemLayout}
                                label="メールアドレス">
                                {getFieldDecorator('email', {
                                    initialValue: this.state.email,
                                    rules: [{required: false}],
                                })(
                                    <Input placeholder="メールアドレスを入力してください"
                                           onChange={(e)=>{
                                               this.setState({
                                                   email: e.target.value
                                               })
                                           }}/>
                                )}
                            </FormItem>


                            <FormItem
                                {...formItemLayout}
                                label="ログインの有効性">
                                {getFieldDecorator('is_login_enabled', {
                                    initialValue: this.state.is_login_enabled,
                                    rules: [{required: true, message: 'ログインの有効性が必須です'}],
                                })(
                                    <RadioGroup onChange={(value) => {
                                        log.debug(value);
                                        this.setState({
                                            is_login_enabled: value
                                        })
                                    }}>
                                        <Radio value={true}>有効</Radio>
                                        <Radio value={false}>無効</Radio>
                                    </RadioGroup>
                                )}
                            </FormItem>
                            <FormItem
                                {...formItemLayout}
                                label="有効期限">

                                {getFieldDecorator('range_picker', {
                                    initialValue: this.state.range_picker,
                                    rules: [{required: true, message: '有効期限設定は必須です'}],
                                })(
                                    <RangePicker
                                        locale={jaJP}
                                        onChange={(value) => {
                                            this.setState({
                                                range_picker: value
                                            })
                                        }}
                                        showTime={{ format: 'HH:mm' }}
                                        format="YYYY/MM/DD HH:mm"
                                        ranges={GlobalConfig.DatePickerRanges()}/>
                                )}
                            </FormItem>


                            <FormItem
                                {...formItemLayout}
                                label="認証プロファイル"
                                layout="inline"
                                >
                                {getFieldDecorator('auth_profile_uuid', {
                                    initialValue: this.state.auth_profiles_uuid,
                                    rules: [{required: true, message: "認証プロファイルの選択は必須です"}]
                                })(
                                    <Select placeholder="認証プロファイルを選択してください"
                                            allowClear={true}
                                            disabled={true}
                                            onChange={(e)=>{
                                                this.setState({
                                                    auth_profiles_uuid: e
                                                })
                                            }}
                                    >
                                        <Option key="651faa83-a4b6-48fe-a385-d91dfdc037f4"
                                                value="651faa83-a4b6-48fe-a385-d91dfdc037f4">
                                            <div className="select-title">関西大学統合認証システム</div>
                                            <div className="select-description">関西大学が提供する統合認証アカウントです</div>
                                        </Option>
                                        <Option key="b23b40b8-bd18-11e7-abc4-cec278b6b50a" value="portal-site">
                                            <div className="select-title">インフォメーションシステム</div>
                                            <div className="select-description">インフォメーションシステム内でのみ有効となるローカルユーザ認証です</div>
                                        </Option>
                                    </Select>
                                )}
                            </FormItem>

                            {this.state.auth_profiles_uuid === "portal-site" ?
                                <FormItem
                                    {...formItemLayout}
                                    label="パスワード"
                                    hasFeedback
                                >
                                    {getFieldDecorator('password', {
                                        initialValue: this.state.password,
                                        rules: [{
                                            validator: this.checkConfirm,
                                        }],
                                    })(
                                        <Input type="password"
                                               onChange={(e)=>{
                                                   this.setState({
                                                       password: e.target.value
                                                   })
                                               }}/>
                                    )}
                                </FormItem>
                                :
                                null
                            }

                            {this.state.auth_profiles_uuid === "portal-site" ?
                                <FormItem
                                    {...formItemLayout}
                                    label="パスワード再入力"
                                    hasFeedback>
                                    {getFieldDecorator('confirm', {
                                        initialValue: this.state.confirm,
                                        rules: [{
                                            validator: this.checkPassword,
                                        }],
                                    })(
                                        <Input type="password" onBlur={this.handleConfirmBlur}
                                               onChange={(e)=>{
                                                   this.setState({
                                                       confirm: e.target.value
                                                   })
                                               }}/>
                                    )}
                                </FormItem>
                                :
                                null
                            }




                            <FormItem
                                {...formItemLayout}
                                label="摘要"
                                layout="inline">
                                {getFieldDecorator('memo', {
                                    initialValue: this.state.memo,
                                    rules: [{required: false}]
                                })(
                                    <TextArea placeholder="摘要を入力してください" autosize={{minRows: 5, maxRows: 12,}}
                                              onChange={(e) => {
                                                  log.debug(e)
                                                  this.setState({
                                                      memo: e.target.value
                                                  })
                                              }}/>
                                )}
                            </FormItem>


                            {/*グループ*/}
                            <FormItem
                                {...formItemLayout}
                                label="所属グループ">
                                {getFieldDecorator('groups', {
                                    initialValue: this.state.checkedKeys,
                                    rules: [{required: true, message: '所属グループの選択が必須です'}],
                                })(
                                    <div>
                                        <Button type="primary" className="button" icon={<UserOutlined />}
                                                onClick={() => {
                                                    this.setState({
                                                        editGroupModalIsOpen: true
                                                    })
                                                }}>
                                            所属グループの選択
                                        </Button>
                                        <Table columns={columns} dataSource={this.state.checkedRows} size="small"/>
                                    </div>
                                )}
                            </FormItem>


                            <AddGroupModal
                                isEditMode={true}
                                visible={this.state.editGroupModalIsOpen}
                                handleOk={(checkedKeys, checkedRows) => {
                                    this.setState({
                                        checkedKeys,
                                        checkedRows,
                                        editGroupModalIsOpen: false
                                    })
                                    this.props.form.setFieldsValue({
                                        groups: checkedKeys
                                    });
                                }}
                                handleCancel={() => {
                                    this.setState({
                                        editGroupModalIsOpen: false
                                    })
                                }}
                                checkedKeys={this.state.checkedKeys}
                            />

                        </Form>
                    </Spin>


                </div>
            </Modal>
        );
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Form.create()(UserEditFormModal));
