/**
 * Created by sibaservice on 2017/05/15.
 */
import * as Actions from './actions'
import log from 'components/utils/Logger'
import {handleActions, handleAction, combineActions} from 'redux-actions';
import moment from 'moment';
import 'moment/locale/ja';


const defaultState = ({
    payload: {},
    meta: {
        fetch: false,
        status: ""
    }
});
const defaultReducer = {
    next: (state, action) => {
        return {...action}
    },
    throw: (state, action) => {
        return (Object.assign(action, {
            meta: {
                fetch: false,
                status: Actions.statusEnum.FAILURE
            }
        }))
    }
};
export const loginConnection = handleAction(
    Actions.http.connection.auth.login,
    defaultReducer,
    defaultState
);
export const loginSsoConnection = handleAction(
    Actions.http.connection.auth.loginSso,
    defaultReducer,
    defaultState
);
export const checkLoginConnection = handleAction(
    Actions.http.connection.auth.checkLoggedIn,
    defaultReducer,
    defaultState
);

export const scheduleConnection = handleAction(
    combineActions(
        Actions.http.connection.curriculum.all,
        Actions.http.connection.curriculum.cancel,
        Actions.http.connection.curriculum.extra,
        Actions.http.connection.curriculum.change,
    ),
    defaultReducer,
    defaultState
);


//失敗
const defaultFailureState = ({
    payload: {},
    meta: {isShow: false}
})
export const failure = handleAction(
    Actions.data.failure,
    (state, action) => {
        return action
    },
    defaultFailureState
);


export const myTargetSearchConnection = handleAction(
    Actions.http.connection.target.myTarget.search,
    defaultReducer,
    defaultState
)

export const myTargetEditConnection = handleAction(
    combineActions(
        Actions.http.connection.target.myTarget.create,
        Actions.http.connection.target.myTarget.update,
        Actions.http.connection.target.myTarget.delete
    ),
    defaultReducer,
    defaultState
);

export const likeEditConnection = handleAction(
    combineActions(
        Actions.http.connection.notification.like,
        Actions.http.connection.notification.unlike
    ),
    defaultReducer,
    defaultState
);


//以上新しいの
//以下古いの

const arrayDefaultState = ({
    payload: {
        data: []
    },
    meta: {
        fetch: false
    }
});

const connectDefaultState = {
    payload: {},
    meta: {
        fetch: false
    }
};

const licenseDefaultState = {
    payload: {},
    meta: {
        fetch: false
    }
}


const previewURLDefaultState = {
    payload: "",
};
export const previewURL = handleActions({
    "PREVIEW/URL": (state, action) => ({
        ...action
    })
}, previewURLDefaultState);


const imageUpload = {};

export const license = handleActions({
    "LICENSE/REQUEST": (state, action) => ({
        ...action
    }),
    "LICENSE/RECEIVE": (state, action) => ({
        ...action
    })
}, licenseDefaultState)

export const check = handleActions({
    "CHECK/REQUEST": (state, action) => ({
        ...action,
    }),
    "CHECK/RECEIVE": (state, action) => ({
        ...action
    })
}, connectDefaultState);


export const checkFirst = handleActions({
    "CHECK_FIRST/REQUEST": (state, action) => ({
        ...action,
    }),
    "CHECK_FIRST/RECEIVE": (state, action) => ({
        ...action
    })
}, connectDefaultState);


export const login = handleActions({
    "LOGIN/REQUEST": (state, action) => ({
        ...action,
    }),
    "LOGIN/RECEIVE": (state, action) => ({
        ...action
    })
}, connectDefaultState);

export const logout = handleActions({
    "LOGOUT/REQUEST": (state, action) => ({
        ...action,
    }),
    "LOGOUT/RECEIVE": (state, action) => ({
        ...action
    })
}, connectDefaultState);

export const loginMessageSettingView = handleActions({
    "LOGIN_MESSAGE_SETTING/VIEW/REQUEST": (state, action) => ({
        ...action
    }),
    "LOGIN_MESSAGE_SETTING/VIEW/RECEIVE": (state, action) => ({
        ...action
    }),
}, connectDefaultState);

export const loginMessageSettingSave = handleActions({
    "LOGIN_MESSAGE_SETTING/SAVE/REQUEST": (state, action) => ({
        ...action
    }),
    "LOGIN_MESSAGE_SETTING/SAVE/RECEIVE": (state, action) => ({
        ...action
    }),
}, connectDefaultState);


const categoryDefaultState = arrayDefaultState;
const notificationDefaultState = arrayDefaultState;
const menuDefaultState = arrayDefaultState;


////////////////////////////////////////////////////////////////MENU系列
export const menu = handleActions({
    "MENU/SEARCH/REQUEST": (state, action) => ({
        ...action
    }),
    "MENU/SEARCH/RECEIVE": (state, action) => ({
        ...action
    })
}, menuDefaultState);

export const menuCreate = handleActions({
    "MENU/CREATE/REQUEST": (state, action) => ({
        ...action
    }),
    "MENU/CREATE/RECEIVE": (state, action) => ({
        ...action
    })
}, menuDefaultState);

export const menuUpdate = handleActions({
    "MENU/UPDATE/REQUEST": (state, action) => ({
        ...action
    }),
    "MENU/UPDATE/RECEIVE": (state, action) => ({
        ...action
    })
}, menuDefaultState);

export const menuDelete = handleActions({
    "MENU/DELETE/REQUEST": (state, action) => ({
        ...action
    }),
    "MENU/DELETE/RECEIVE": (state, action) => ({
        ...action
    })
}, menuDefaultState);


//以下SPメニュー
export const menuSP = handleActions({
    "MENU/SP/SEARCH/REQUEST": (state, action) => ({
        ...action
    }),
    "MENU/SP/SEARCH/RECEIVE": (state, action) => ({
        ...action
    })
}, menuDefaultState);

export const menuSPCreate = handleActions({
    "MENU/SP/CREATE/REQUEST": (state, action) => ({
        ...action
    }),
    "MENU/SP/CREATE/RECEIVE": (state, action) => ({
        ...action
    })
}, menuDefaultState);

export const menuSPUpdate = handleActions({
    "MENU/SP/UPDATE/REQUEST": (state, action) => ({
        ...action
    }),
    "MENU/SP/UPDATE/RECEIVE": (state, action) => ({
        ...action
    })
}, menuDefaultState);

export const menuSPDelete = handleActions({
    "MENU/SP/DELETE/REQUEST": (state, action) => ({
        ...action
    }),
    "MENU/SP/DELETE/RECEIVE": (state, action) => ({
        ...action
    })
}, menuDefaultState);

////////////////////////////////////////////////////////////////MENU系列

////////////////////////////////////////////////////////////////MODULES系列
//MENU取得
export const modules = handleActions({
    "MODULES/SEARCH/REQUEST": (state, action) => ({
        ...action
    }),
    "MODULES/SEARCH/RECEIVE": (state, action) => ({
        ...action
    })
}, menuDefaultState);

export const modulesCreate = handleActions({
    "MODULES/CREATE/REQUEST": (state, action) => ({
        ...action
    }),
    "MODULES/CREATE/RECEIVE": (state, action) => ({
        ...action
    })
}, menuDefaultState);

export const modulesUpdate = handleActions({
    "MODULES/UPDATE/REQUEST": (state, action) => ({
        ...action
    }),
    "MODULES/UPDATE/RECEIVE": (state, action) => ({
        ...action
    })
}, menuDefaultState);

export const modulesDelete = handleActions({
    "MODULES/DELETE/REQUEST": (state, action) => ({
        ...action
    }),
    "MODULES/DELETE/RECEIVE": (state, action) => ({
        ...action
    })
}, menuDefaultState);
////////////////////////////////////////////////////////////////MODULES系列

//お知らせ検索
export const notification = handleActions({
    "NOTIFICATION/SEARCH/REQUEST": (state, action) => ({
        ...action,
    }),
    "NOTIFICATION/SEARCH/RECEIVE": (state, action) => ({
        ...action
    })
}, notificationDefaultState);

//お知らせ検索
export const notificationModule = handleActions({
    "NOTIFICATION/MODULE/REQUEST": (state, action) => ({
        ...action,
    }),
    "NOTIFICATION/MODULE/RECEIVE": (state, action) => ({
        ...action
    })
}, notificationDefaultState);

//お知らせ検索 moduleリスト
export const notificationModuleList = handleActions({
    "NOTIFICATION/MODULE_LIST/REQUEST": (state, action) => ({
        ...action,
    }),
    "NOTIFICATION/MODULE_LIST/RECEIVE": (state, action) => ({
        ...action
    })
}, notificationDefaultState);

//お知らせ検索ゲスト
export const notificationGuest = handleActions({
    "NOTIFICATION/GUEST/REQUEST": (state, action) => ({
        ...action,
    }),
    "NOTIFICATION/GUEST/RECEIVE": (state, action) => ({
        ...action
    }),
}, notificationDefaultState);

//お知らせ詳細確認(既読処理
export const notificationView = handleActions({
    "NOTIFICATION/VIEW/REQUEST": (state, action) => ({
        ...action,
    }),
    "NOTIFICATION/VIEW/RECEIVE": (state, action) => ({
        ...action
    }),
    "NOTIFICATION/VIEW/RESET": (state, action) => ({
        ...action
    }),
}, notificationDefaultState);

//お知らせカテゴリごとまたは全件既処理
export const notificationSetRead = handleActions({
    "NOTIFICATION/SETREAD/REQUEST": (state, action) => ({
        ...action,
    }),
    "NOTIFICATION/SETREAD/RECEIVE": (state, action) => ({
        ...action
    })
}, notificationDefaultState);
//お知らせカテゴリごとまたは全件既処理
export const notificationSetReadSelected = handleActions({
    "NOTIFICATION/SETREADSELECTED/REQUEST": (state, action) => ({
        ...action,
    }),
    "NOTIFICATION/SETREADSELECTED/RECEIVE": (state, action) => ({
        ...action
    })
}, notificationDefaultState);
//お知らせカテゴリごとまたは全件既処理
export const notificationSetUnReadSelected = handleActions({
    "NOTIFICATION/SETUNREADSELECTED/REQUEST": (state, action) => ({
        ...action,
    }),
    "NOTIFICATION/SETUNREADSELECTED/RECEIVE": (state, action) => ({
        ...action
    })
}, notificationDefaultState);
//お知らせカテゴリごとまたは全件既処理
export const notificationSetPinSelected = handleActions({
    "NOTIFICATION/SETPINSELECTED/REQUEST": (state, action) => ({
        ...action,
    }),
    "NOTIFICATION/SETPINSELECTED/RECEIVE": (state, action) => ({
        ...action
    })
}, notificationDefaultState);
//お知らせカテゴリごとまたは全件既処理
export const notificationSetUnpinSelected = handleActions({
    "NOTIFICATION/SETUNPINSELECTED/REQUEST": (state, action) => ({
        ...action,
    }),
    "NOTIFICATION/SETUNPINSELECTED/RECEIVE": (state, action) => ({
        ...action
    })
}, notificationDefaultState);


//お知らせ作成
export const notificationCreate = handleActions({
    "NOTIFICATION/CREATE/REQUEST": (state, action) => ({
        ...action
    }),
    "NOTIFICATION/CREATE/RECEIVE": (state, action) => ({
        ...action
    })
}, categoryDefaultState);

//お知らせ編集
export const notificationUpdate = handleActions({
    "NOTIFICATION/UPDATE/REQUEST": (state, action) => ({
        ...action
    }),
    "NOTIFICATION/UPDATE/RECEIVE": (state, action) => ({
        ...action
    })
}, categoryDefaultState);

//お知らせ対象者検索
export const notificationTarget = handleActions({
    "NOTIFICATION/TARGET/REQUEST": (state, action) => ({
        ...action
    }),
    "NOTIFICATION/TARGET/RECEIVE": (state, action) => ({
        ...action
    })
}, categoryDefaultState);

//お知らせ削除
export const notificationDelete = handleActions({
    "NOTIFICATION/DELETE/REQUEST": (state, action) => ({
        ...action
    }),
    "NOTIFICATION/DELETE/RECEIVE": (state, action) => ({
        ...action
    })
}, notificationDefaultState);

//お知らせコピー
export const notificationCopy = handleActions({
    "NOTIFICATION/COPY/REQUEST": (state, action) => ({
        ...action
    }),
    "NOTIFICATION/COPY/RECEIVE": (state, action) => ({
        ...action
    })
}, notificationDefaultState);

//お知らせカテゴリ検索
export const notificationCategory = handleActions({
    "NOTIFICATION/CATEGORY/SEARCH/REQUEST": (state, action) => ({
        ...action
    }),
    "NOTIFICATION/CATEGORY/SEARCH/RECEIVE": (state, action) => ({
        ...action
    })
}, categoryDefaultState);

//お知らせカテゴリ作成
export const notificationCategoryCreate = handleActions({
    "NOTIFICATION/CATEGORY/CREATE/REQUEST": (state, action) => ({
        ...action
    }),
    "NOTIFICATION/CATEGORY/CREATE/RECEIVE": (state, action) => ({
        ...action
    })
}, categoryDefaultState);

//お知らせカテゴリ編集
export const notificationCategoryUpdate = handleActions({
    "NOTIFICATION/CATEGORY/UPDATE/REQUEST": (state, action) => ({
        ...action
    }),
    "NOTIFICATION/CATEGORY/UPDATE/RECEIVE": (state, action) => ({
        ...action
    })
}, categoryDefaultState);

//お知らせカテゴリ削除
export const notificationCategoryDelete = handleActions({
    "NOTIFICATION/CATEGORY/DELETE/REQUEST": (state, action) => ({
        ...action
    }),
    "NOTIFICATION/CATEGORY/DELETE/RECEIVE": (state, action) => ({
        ...action
    })
}, categoryDefaultState);


//アンケート検索
export const questionnaire = handleActions({
    "QUESTIONNAIRE/SEARCH/REQUEST": (state, action) => ({
        ...action,
    }),
    "QUESTIONNAIRE/SEARCH/RECEIVE": (state, action) => ({
        ...action
    })
}, arrayDefaultState);

//アンケート検索 showmore部
export const questionnaireMore = handleActions({
    "QUESTIONNAIRE/SEARCH_MORE/REQUEST": (state, action) => ({
        ...action,
    }),
    "QUESTIONNAIRE/SEARCH_MORE/RECEIVE": (state, action) => ({
        ...action
    })
}, arrayDefaultState);

//アンケート詳細確認(自分の回答確認
export const questionnaireView = handleActions({
    "QUESTIONNAIRE/VIEW/REQUEST": (state, action) => ({
        ...action,
    }),
    "QUESTIONNAIRE/VIEW/RECEIVE": (state, action) => ({
        ...action
    })
}, arrayDefaultState);

//アンケート作成
export const questionnaireCreate = handleActions({
    "QUESTIONNAIRE/CREATE/REQUEST": (state, action) => ({
        ...action,
    }),
    "QUESTIONNAIRE/CREATE/RECEIVE": (state, action) => ({
        ...action
    })
}, arrayDefaultState);

//アンケート編集
export const questionnaireUpdate = handleActions({
    "QUESTIONNAIRE/UPDATE/REQUEST": (state, action) => ({
        ...action,
    }),
    "QUESTIONNAIRE/UPDATE/RECEIVE": (state, action) => ({
        ...action
    })
}, arrayDefaultState);

//アンケート削除
export const questionnaireDelete = handleActions({
    "QUESTIONNAIRE/DELETE/REQUEST": (state, action) => ({
        ...action,
    }),
    "QUESTIONNAIRE/DELETE/RECEIVE": (state, action) => ({
        ...action
    })
}, arrayDefaultState);

//アンケートコピー
export const questionnaireCopy = handleActions({
    "QUESTIONNAIRE/COPY/REQUEST": (state, action) => ({
        ...action,
    }),
    "QUESTIONNAIRE/COPY/RECEIVE": (state, action) => ({
        ...action
    })
}, arrayDefaultState);

//アンケートコピー
export const questionnaireCopyReview = handleActions({
    "QUESTIONNAIRE/COPY_REVIEW/REQUEST": (state, action) => ({
        ...action,
    }),
    "QUESTIONNAIRE/COPY_REVIEW/RECEIVE": (state, action) => ({
        ...action
    })
}, arrayDefaultState);

//アンケート回答
export const questionnaireAnswer = handleActions({
    "QUESTIONNAIRE/ANSWER/SEARCH/REQUEST": (state, action) => ({
        ...action,
    }),
    "QUESTIONNAIRE/ANSWER/SEARCH/RECEIVE": (state, action) => ({
        ...action
    })
}, arrayDefaultState);

//アンケート結果表示
export const questionnaireAnswerResult = handleActions({
    "QUESTIONNAIRE/ANSWER/RESULT/REQUEST": (state, action) => ({
        ...action,
    }),
    "QUESTIONNAIRE/ANSWER/RESULT/RECEIVE": (state, action) => ({
        ...action
    })
}, arrayDefaultState);

//アンケート結果表示
export const questionnaireAnswerResultFreeForm = handleActions({
    "QUESTIONNAIRE/ANSWER/FREEFORM/REQUEST": (state, action) => ({
        ...action,
    }),
    "QUESTIONNAIRE/ANSWER/FREEFORM/RECEIVE": (state, action) => ({
        ...action
    })
}, arrayDefaultState);

//アンケート結果表示
export const questionnaireAnswerResultAttachment = handleActions({
    "QUESTIONNAIRE/ANSWER/ATTACHMENT/REQUEST": (state, action) => ({
        ...action,
    }),
    "QUESTIONNAIRE/ANSWER/ATTACHMENT/RECEIVE": (state, action) => ({
        ...action
    })
}, arrayDefaultState);


//アンケート対象者検索
export const questionnaireTarget = handleActions({
    "QUESTIONNAIRE/TARGET/REQUEST": (state, action) => ({
        ...action
    }),
    "QUESTIONNAIRE/TARGET/RECEIVE": (state, action) => ({
        ...action
    })
}, arrayDefaultState);

//アンケートカテゴリ検索
export const questionnaireCategory = handleActions({
    "QUESTIONNAIRE/CATEGORY/SEARCH/REQUEST": (state, action) => ({
        ...action
    }),
    "QUESTIONNAIRE/CATEGORY/SEARCH/RECEIVE": (state, action) => ({
        ...action
    })
}, arrayDefaultState);

//アンケートカテゴリ作成
export const questionnaireCategoryCreate = handleActions({
    "QUESTIONNAIRE/CATEGORY/CREATE/REQUEST": (state, action) => ({
        ...action
    }),
    "QUESTIONNAIRE/CATEGORY/CREATE/RECEIVE": (state, action) => ({
        ...action
    })
}, arrayDefaultState);

//アンケートカテゴリ編集
export const questionnaireCategoryUpdate = handleActions({
    "QUESTIONNAIRE/CATEGORY/UPDATE/REQUEST": (state, action) => ({
        ...action
    }),
    "QUESTIONNAIRE/CATEGORY/UPDATE/RECEIVE": (state, action) => ({
        ...action
    })
}, arrayDefaultState);

//アンケートカテゴリ削除
export const questionnaireCategoryDelete = handleActions({
    "QUESTIONNAIRE/CATEGORY/DELETE/REQUEST": (state, action) => ({
        ...action
    }),
    "QUESTIONNAIRE/CATEGORY/DELETE/RECEIVE": (state, action) => ({
        ...action
    })
}, arrayDefaultState);

//学年暦検索
export const calendarSearch = handleActions({
    "CALENDAR/SEARCH/REQUEST": (state, action) => ({
        ...action
    }),
    "CALENDAR/SEARCH/RECEIVE": (state, action) => ({
        ...action
    })
}, arrayDefaultState);
//学年暦カテゴリ検索
export const calendarCategorySearch = handleActions({
    "CALENDAR/CATEGORY/SEARCH/REQUEST": (state, action) => ({
        ...action
    }),
    "CALENDAR/CATEGORY/SEARCH/RECEIVE": (state, action) => ({
        ...action
    })
}, arrayDefaultState);

//学年暦カテゴリ作成
export const calendarCategoryCreate = handleActions({
    "CALENDAR/CATEGORY/CREATE/REQUEST": (state, action) => ({
        ...action
    }),
    "CALENDAR/CATEGORY/CREATE/RECEIVE": (state, action) => ({
        ...action
    })
}, arrayDefaultState);

//学年暦カテゴリ編集
export const calendarCategoryUpdate = handleActions({
    "CALENDAR/CATEGORY/UPDATE/REQUEST": (state, action) => ({
        ...action
    }),
    "CALENDAR/CATEGORY/UPDATE/RECEIVE": (state, action) => ({
        ...action
    })
}, arrayDefaultState);

//学年暦カテゴリ削除
export const calendarCategoryDelete = handleActions({
    "CALENDAR/CATEGORY/DELETE/REQUEST": (state, action) => ({
        ...action
    }),
    "CALENDAR/CATEGORY/DELETE/RECEIVE": (state, action) => ({
        ...action
    })
}, arrayDefaultState);

////////////////////////////////////////////////////////////////ファイル共有ここから
export const fileShareAgreement = handleActions({
    "FILESHARE/AGREEMENT/REQUEST": (state, action) => ({
        ...action
    }),
    "FILESHARE/AGREEMENT/RECEIVE": (state, action) => ({
        ...action
    }),
}, arrayDefaultState);
//規約同意
export const fileShareConfirmAgreement = handleActions({
    "FILESHARE/SHARED_SETTING_AGREEMENT/REQUEST": (state, action) => ({
        ...action
    }),
    "FILESHARE/SHARED_SETTING_AGREEMENT/RECEIVE": (state, action) => ({
        ...action
    }),
}, arrayDefaultState);

//ファイル検索
export const fileShareFetch = handleActions({
    "FILESHARE/FETCH/REQUEST": (state, action) => ({
        ...action
    }),
    "FILESHARE/FETCH/RECEIVE": (state, action) => ({
        ...action
    }),
}, arrayDefaultState);
//ファイル検索
export const fileShareSearch = handleActions({
    "FILESHARE/SEARCH/REQUEST": (state, action) => ({
        ...action
    }),
    "FILESHARE/SEARCH/RECEIVE": (state, action) => ({
        ...action
    }),
}, arrayDefaultState);
//ファイル検索SeeMore
export const fileShareSearchSeeMore = handleActions({
    "FILESHARE/SEE_MORE/REQUEST": (state, action) => ({
        ...action
    }),
    "FILESHARE/SEE_MORE/RECEIVE": (state, action) => ({
        ...action
    }),
}, arrayDefaultState);
//ファイル検索 Recursive
export const fileShareSearchRecursive = handleActions({
    "FILESHARE/RECURSIVE/REQUEST": (state, action) => ({
        ...action
    }),
    "FILESHARE/RECURSIVE/RECEIVE": (state, action) => ({
        ...action
    }),
}, arrayDefaultState);
export const fileShareCreateFile = handleActions({
    "FILESHARE/FILE/CREATE/REQUEST": (state, action) => ({
        ...action
    }),
    "FILESHARE/FILE/CREATE/RECEIVE": (state, action) => ({
        ...action
    }),
}, arrayDefaultState);
//fileアップデート
export const fileShareUpdateFile = handleActions({
    "FILESHARE/FILE/UPDATE/REQUEST": (state, action) => ({
        ...action
    }),
    "FILESHARE/FILE/UPDATE/RECEIVE": (state, action) => ({
        ...action
    }),
}, arrayDefaultState);
//folder追加
export const fileShareCreateFolder = handleActions({
    "FILESHARE/FOLDER/CREATE/REQUEST": (state, action) => ({
        ...action
    }),
    "FILESHARE/FOLDER/CREATE/RECEIVE": (state, action) => ({
        ...action
    }),
}, arrayDefaultState);
//folderアップデート
export const fileShareUpdateFolder = handleActions({
    "FILESHARE/FOLDER/UPDATE/REQUEST": (state, action) => ({
        ...action
    }),
    "FILESHARE/FOLDER/UPDATE/RECEIVE": (state, action) => ({
        ...action
    }),
}, arrayDefaultState);
//DELETE
export const fileShareDelete = handleActions({
    "FILESHARE/DELETE/REQUEST": (state, action) => ({
        ...action
    }),
    "FILESHARE/DELETE/RECEIVE": (state, action) => ({
        ...action
    }),
}, arrayDefaultState);

//設定
export const fileShareGetSetting = handleActions({
    "FILESHARE/SHARED_SETTING/REQUEST": (state, action) => ({
        ...action
    }),
    "FILESHARE/SHARED_SETTING/RECEIVE": (state, action) => ({
        ...action
    }),
}, arrayDefaultState);
//設定 更新
export const fileShareUpdateSetting = handleActions({
    "FILESHARE/SHARED_SETTING_UPDATE/REQUEST": (state, action) => ({
        ...action
    }),
    "FILESHARE/SHARED_SETTING_UPDATE/RECEIVE": (state, action) => ({
        ...action
    }),
}, arrayDefaultState);


////////////////////////////////////////////////////////////////ファイル共有ここまで


////////////////////////////////////////////////////////////////ユーザここから
//ユーザ検索
export const user = handleActions({
    "USER/SEARCH/REQUEST": (state, action) => ({
        ...action
    }),
    "USER/SEARCH/RECEIVE": (state, action) => ({
        ...action
    }),
}, arrayDefaultState);

export const userView = handleActions({
    "USER/VIEW/REQUEST": (state, action) => ({
        ...action
    }),
    "USER/VIEW/RECEIVE": (state, action) => ({
        ...action
    }),
}, arrayDefaultState);

export const userSearchTarget = handleActions({
    "USER/SEARCH_TARGET/REQUEST": (state, action) => ({
        ...action
    }),
    "USER/SEARCH_TARGET/RECEIVE": (state, action) => ({
        ...action
    }),
}, arrayDefaultState);

export const userSearchTargetUser = handleActions({
    "USER/SEARCH_TARGET_USER/REQUEST": (state, action) => ({
        ...action
    }),
    "USER/SEARCH_TARGET_USER/RECEIVE": (state, action) => ({
        ...action
    }),
}, arrayDefaultState);

//ユーザ作成
export const userCreate = handleActions({
    "USER/CREATE/REQUEST": (state, action) => ({
        ...action
    }),
    "USER/CREATE/RECEIVE": (state, action) => ({
        ...action
    })
}, arrayDefaultState);

//ユーザ編集
export const userUpdate = handleActions({
    "USER/UPDATE/REQUEST": (state, action) => ({
        ...action
    }),
    "USER/UPDATE/RECEIVE": (state, action) => ({
        ...action
    })
}, arrayDefaultState);

//ユーザ削除
export const userDelete = handleActions({
    "USER/DELETE/REQUEST": (state, action) => ({
        ...action
    }),
    "USER/DELETE/RECEIVE": (state, action) => ({
        ...action
    })
}, arrayDefaultState);


//ユーザCSVダウンロード
export const userCsv = handleActions({
    "USER/CSV/REQUEST": (state, action) => ({
        ...action
    }),
    "USER/CSV/RECEIVE": (state, action) => ({
        ...action
    }),
}, arrayDefaultState);
////////////////////////////////////////////////////////////////ユーザここまで


////////////////////////////////////////////////////////////////グループ権限ここから
//グループ
export const group = handleActions({
    "GROUP/SEARCH/REQUEST": (state, action) => ({
        ...action
    }),
    "GROUP/SEARCH/RECEIVE": (state, action) => ({
        ...action
    })
}, arrayDefaultState);

//グループ作成
export const groupCreate = handleActions({
    "GROUP/CREATE/REQUEST": (state, action) => ({
        ...action
    }),
    "GROUP/CREATE/RECEIVE": (state, action) => ({
        ...action
    })
}, arrayDefaultState);

//グループ編集
export const groupUpdate = handleActions({
    "GROUP/UPDATE/REQUEST": (state, action) => ({
        ...action
    }),
    "GROUP/UPDATE/RECEIVE": (state, action) => ({
        ...action
    })
}, arrayDefaultState);

//グループ削除
export const groupDelete = handleActions({
    "GROUP/DELETE/REQUEST": (state, action) => ({
        ...action
    }),
    "GROUP/DELETE/RECEIVE": (state, action) => ({
        ...action
    })
}, arrayDefaultState);
////////////////////////////////////////////////////////////////グループ権限ここまで


////////////////////////////////////////////////////////////////権限管理ここから
//権限管理
export const role = handleActions({
    "ROLE/SEARCH/REQUEST": (state, action) => ({
        ...action
    }),
    "ROLE/SEARCH/RECEIVE": (state, action) => ({
        ...action
    }),
}, arrayDefaultState);

//権限作成
export const roleCreate = handleActions({
    "ROLE/CREATE/REQUEST": (state, action) => ({
        ...action
    }),
    "ROLE/CREATE/RECEIVE": (state, action) => ({
        ...action
    })
}, arrayDefaultState);

//権限編集
export const roleUpdate = handleActions({
    "ROLE/UPDATE/REQUEST": (state, action) => ({
        ...action
    }),
    "ROLE/UPDATE/RECEIVE": (state, action) => ({
        ...action
    })
}, arrayDefaultState);

//権限削除
export const roleDelete = handleActions({
    "ROLE/DELETE/REQUEST": (state, action) => ({
        ...action
    }),
    "ROLE/DELETE/RECEIVE": (state, action) => ({
        ...action
    })
}, arrayDefaultState);

////////////////////////////////////////////////////////////////権限管理ここまで


////////////////////////////////////////////////////////////////マスターパスワードここから

//権限削除
export const passwordChange = handleActions({
    "PASSWORD/CHANGE/REQUEST": (state, action) => ({
        ...action
    }),
    "PASSWORD/CHANGE/RECEIVE": (state, action) => ({
        ...action
    })
}, arrayDefaultState);


////////////////////////////////////////////////////////////////マスターパスワードここまで


////////////////////////////////////////////////////////////////認証プロファイルここから
//認証プロファイル検索
export const authProfileSearch = handleActions({
    "AUTHPROFILE/SEARCH/REQUEST": (state, action) => ({
        ...action
    }),
    "AUTHPROFILE/SEARCH/RECEIVE": (state, action) => ({
        ...action
    })
}, arrayDefaultState);

//認証プロファイル作成
export const authProfileCreate = handleActions({
    "AUTHPROFILE/CREATE/REQUEST": (state, action) => ({
        ...action
    }),
    "AUTHPROFILE/CREATE/RECEIVE": (state, action) => ({
        ...action
    })
}, arrayDefaultState);

//認証プロファイル編集
export const authProfileUpdate = handleActions({
    "AUTHPROFILE/UPDATE/REQUEST": (state, action) => ({
        ...action
    }),
    "AUTHPROFILE/UPDATE/RECEIVE": (state, action) => ({
        ...action
    })
}, arrayDefaultState);

//認証プロファイル削除
export const authProfileDelete = handleActions({
    "AUTHPROFILE/DELETE/REQUEST": (state, action) => ({
        ...action
    }),
    "AUTHPROFILE/DELETE/RECEIVE": (state, action) => ({
        ...action
    })
}, arrayDefaultState);
////////////////////////////////////////////////////////////////認証プロファイルここまで


////////////////////////////////////////////////////////////////SSOここから
//SSO検索
export const ssoSearch = handleActions({
    "SSO/SEARCH/REQUEST": (state, action) => ({
        ...action
    }),
    "SSO/SEARCH/RECEIVE": (state, action) => ({
        ...action
    })
}, arrayDefaultState);

//SSOリンク作成
export const ssoCreate = handleActions({
    "SSO/CREATE/REQUEST": (state, action) => ({
        ...action
    }),
    "SSO/CREATE/RECEIVE": (state, action) => ({
        ...action
    })
}, arrayDefaultState);

//SSOリンク編集
export const ssoUpdate = handleActions({
    "SSO/UPDATE/REQUEST": (state, action) => ({
        ...action
    }),
    "SSO/UPDATE/RECEIVE": (state, action) => ({
        ...action
    })
}, arrayDefaultState);

//SSOリンク削除
export const ssoDelete = handleActions({
    "SSO/DELETE/REQUEST": (state, action) => ({
        ...action
    }),
    "SSO/DELETE/RECEIVE": (state, action) => ({
        ...action
    })
}, arrayDefaultState);
////////////////////////////////////////////////////////////////SSOここまで

////////////////////////////////////////////////////////////////権限管理ここから

//パーミッション取得
export const permission = handleActions({
    "PERMISSION/REQUEST": (state, action) => ({
        ...action
    }),
    "PERMISSION/RECEIVE": (state, action) => ({
        ...action
    })
}, arrayDefaultState);

//permission作成
export const permissionCreate = handleActions({
    "PERMISSION/CREATE/REQUEST": (state, action) => ({
        ...action
    }),
    "PERMISSION/CREATE/RECEIVE": (state, action) => ({
        ...action
    })
}, arrayDefaultState);

//パーミッション編集
export const permissionUpdate = handleActions({
    "PERMISSION/UPDATE/REQUEST": (state, action) => ({
        ...action
    }),
    "PERMISSION/UPDATE/RECEIVE": (state, action) => ({
        ...action
    })
}, arrayDefaultState);

//パーミッション削除
export const permissionDelete = handleActions({
    "PERMISSION/DELETE/REQUEST": (state, action) => ({
        ...action
    }),
    "PERMISSION/DELETE/RECEIVE": (state, action) => ({
        ...action
    })
}, arrayDefaultState);


//パーミッション削除
export const permissionCopy = handleActions({
    "PERMISSION/COPY/REQUEST": (state, action) => ({
        ...action
    }),
    "PERMISSION/COPY/RECEIVE": (state, action) => ({
        ...action
    })
}, arrayDefaultState);


//対象追加
export const permissionUserAdd = handleActions({
    "PERMISSION/ADDUSER/REQUEST": (state, action) => ({
        ...action
    }),
    "PERMISSION/ADDUSER/RECEIVE": (state, action) => ({
        ...action
    })
}, arrayDefaultState);
//対象削除
export const permissionUserDelete = handleActions({
    "PERMISSION/DELETEUSER/REQUEST": (state, action) => ({
        ...action
    }),
    "PERMISSION/DELETEUSER/RECEIVE": (state, action) => ({
        ...action
    })
}, arrayDefaultState);
//対象全体削除
export const permissionUserDeleteAll = handleActions({
    "PERMISSION/DELETEUSERALL/REQUEST": (state, action) => ({
        ...action
    }),
    "PERMISSION/DELETEUSERALL/RECEIVE": (state, action) => ({
        ...action
    })
}, arrayDefaultState);


export const permissionUserAddAll = handleActions({
    "PERMISSION/ADDALL/REQUEST": (state, action) => ({
        ...action
    }),
    "PERMISSION/ADDALL/RECEIVE": (state, action) => ({
        ...action
    })
}, arrayDefaultState)


export const permissionUserAddCSV = handleActions({
    "PERMISSION/ADDALLCSV/REQUEST": (state, action) => ({
        ...action
    }),
    "PERMISSION/ADDALLCSV/RECEIVE": (state, action) => ({
        ...action
    })
}, arrayDefaultState)

////////////////////////////////////////////////////////////////権限管理ここまで


////////////////////////////////////////////////////////////////権限対象管理ここから

//対象取得
export const target = handleActions({
    "TARGET/SEARCH/REQUEST": (state, action) => ({
        ...action
    }),
    "TARGET/SEARCH/RECEIVE": (state, action) => ({
        ...action
    })
}, arrayDefaultState);

//対象作成
export const targetCreate = handleActions({
    "TARGET/CREATE/REQUEST": (state, action) => ({
        ...action
    }),
    "TARGET/CREATE/RECEIVE": (state, action) => ({
        ...action
    })
}, arrayDefaultState);

//対象編集
export const targetUpdate = handleActions({
    "TARGET/UPDATE/REQUEST": (state, action) => ({
        ...action
    }),
    "TARGET/UPDATE/RECEIVE": (state, action) => ({
        ...action
    })
}, arrayDefaultState);

//対象削除
export const targetDelete = handleActions({
    "TARGET/DELETE/REQUEST": (state, action) => ({
        ...action
    }),
    "TARGET/DELETE/RECEIVE": (state, action) => ({
        ...action
    })
}, arrayDefaultState);

//対象コピー
export const targetCopy = handleActions({
    "TARGET/COPY/REQUEST": (state, action) => ({
        ...action
    }),
    "TARGET/COPY/RECEIVE": (state, action) => ({
        ...action
    })
}, arrayDefaultState);


//対象追加
export const targetUserAdd = handleActions({
    "TARGET/ADDUSER/REQUEST": (state, action) => ({
        ...action
    }),
    "TARGET/ADDUSER/RECEIVE": (state, action) => ({
        ...action
    })
}, arrayDefaultState);

export const targetUserAddAll = handleActions({
    "TARGET/ADDALL/REQUEST": (state, action) => ({
        ...action
    }),
    "TARGET/ADDALL/RECEIVE": (state, action) => ({
        ...action
    })
}, arrayDefaultState)

//対象削除
export const targetUserDelete = handleActions({
    "TARGET/DELETEUSER/REQUEST": (state, action) => ({
        ...action
    }),
    "TARGET/DELETEUSER/RECEIVE": (state, action) => ({
        ...action
    })
}, arrayDefaultState);
//対象全体削除
export const targetUserDeleteAll = handleActions({
    "TARGET/DELETEUSERALL/REQUEST": (state, action) => ({
        ...action
    }),
    "TARGET/DELETEUSERALL/RECEIVE": (state, action) => ({
        ...action
    })
}, arrayDefaultState);

export const targetUserAddCSV = handleActions({
    "TARGET/ADDALLCSV/REQUEST": (state, action) => ({
        ...action
    }),
    "TARGET/ADDALLCSV/RECEIVE": (state, action) => ({
        ...action
    })
}, arrayDefaultState)


////////////////////////////////////////////////////////////////権限対象管理ここまで


////////////////////////////////////////////////////////////////ログここから
export const actionLog = handleActions({
    "LOG/SEARCH/REQUEST": (state, action) => ({
        ...action
    }),
    "LOG/SEARCH/RECEIVE": (state, action) => ({
        ...action
    })
}, arrayDefaultState);
////////////////////////////////////////////////////////////////ログここまで


/* パラメータ系列　*/
//アンケートカテゴリ一覧
export const paramQuestionnaireCategory = handleActions({
    "QUESTIONNAIRE/CATEGORY/PARAMETER/REQUEST": (state, action) => ({
        ...action,
    }),
    "QUESTIONNAIRE/CATEGORY/PARAMETER/RECEIVE": (state, action) => ({
        ...action
    })
}, arrayDefaultState);

//お知らせカテゴリ一覧
export const paramNotificationCategory = handleActions({
    "NOTIFICATION/CATEGORY/PARAMETER/REQUEST": (state, action) => ({
        ...action,
    }),
    "NOTIFICATION/CATEGORY/PARAMETER/RECEIVE": (state, action) => ({
        ...action
    })
}, categoryDefaultState);

//お知らせカテゴリ一覧
export const paramCalendarCategory = handleActions({
    "CALENDAR/CATEGORY/PARAMETER/REQUEST": (state, action) => ({
        ...action,
    }),
    "CALENDAR/CATEGORY/PARAMETER/RECEIVE": (state, action) => ({
        ...action
    })
}, categoryDefaultState);


//配信元
export const paramSource = handleActions({
    "SOURCE/REQUEST": (state, action) => ({
        ...action
    }),
    "SOURCE/RECEIVE": (state, action) => ({
        ...action
    })
}, arrayDefaultState);


//画像
export const images = handleActions({
    "IMAGES/REQUEST": (state, action) => ({
        ...action
    }),
    "IMAGES/RECEIVE": (state, action) => ({
        ...action
    })
}, arrayDefaultState);

//サムネイルthumbnail
export const thumbnail = handleActions({
    "THUMBNAIL/REQUEST": (state, action) => ({
        ...action
    }),
    "THUMBNAIL/RECEIVE": (state, action) => ({
        ...action
    })
}, arrayDefaultState);

//CSV
export const csv = handleActions({
    "CSV/REQUEST": (state, action) => ({
        ...action
    }),
    "CSV/RECEIVE": (state, action) => ({
        ...action
    })
}, arrayDefaultState);

//TEXT
export const text = handleActions({
    "TEXT/REQUEST": (state, action) => ({
        ...action
    }),
    "TEXT/RECEIVE": (state, action) => ({
        ...action
    })
}, arrayDefaultState);

const layoutDefaultStatus = {
    payload: {
        data: []
    },
    meta: {
        fetch: false
    }
};

export const layoutSave = handleActions({
    "LAYOUT/SAVE/REQUEST": (state, action) => ({
        ...action
    }),
    "LAYOUT/SAVE/RECEIVE": (state, action) => ({
        ...action
    })
}, layoutDefaultStatus);

export const layoutLoad = handleActions({
    "LAYOUT/LOAD/REQUEST": (state, action) => ({
        ...action
    }),
    "LAYOUT/LOAD/RECEIVE": (state, action) => ({
        ...action
    })
}, layoutDefaultStatus);


const sessionDefaultStatus = {
    payload: {
        isAuthenticated: false,
    }
};

export const session = handleActions({
    "SESSION": (state, action) => ({
        ...action
    })
}, sessionDefaultStatus);


const timerDefaultStatus = {
    type: "TIMER",
    payload: {
        gmt: moment().unix()
    }
};
export const timer = handleActions({
    "TIMER": (state, action) => ({
        ...action
    })
}, timerDefaultStatus);


const menuParamDefaultState = {
    payload: {
        menu: [],
        object: {}
    }
};

export const paramMenu = handleActions({
    "PARAM/MENU": (state, action) => ({
        ...action
    })
}, menuParamDefaultState);


const errorDefaultStatus = {
    error: false
};
export const errors = handleActions({
    "ERRORS": (state, action) => ({
        ...action
    })
}, errorDefaultStatus);

const successDefaultStatus = {
    success: false
};

export const successes = handleActions({
    "SUCCESSES": (state, action) => ({
        ...action
    })
}, successDefaultStatus);

const screenDefaultStatus = {
    screenWidth: window.innerWidth,
    screenHeight: window.innerHeight
};

export const screen = handleActions({
    "SCREEN": (state, action) => ({
        ...action
    })
}, screenDefaultStatus);


