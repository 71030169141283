import React from 'react'
import {connect, store} from 'react-redux'
import PropTypes from 'prop-types';
import {instanceOf} from 'prop-types';
import log from 'components/utils/Logger.js'

import moment from 'moment';
import 'moment/locale/ja';
import { CalendarOutlined, LoadingOutlined,CalendarFilled } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Table, Tabs, Collapse, Card, Button } from 'antd';
import ReactGridLayout from 'react-grid-layout';
import ModuleCalendarModalSingle from './ModuleCalendarModalSingle';
import ModuleCalendarModalList from './ModuleCalendarModalList'

import {Scrollbars} from 'react-custom-scrollbars';

import * as Actions from 'redux/actions';
moment.locale('ja');

const TabPane = Tabs.TabPane;
const FormItem = Form.Item;
const Panel = Collapse.Panel;

const mapStateToProps = (state, props) => {
    return {
        screen: state.screen,
        calendarSearch: state.calendarSearch

    }
}

const mapDispatchToProps = dispatch => {

    return {
        getCalendar: (param) => {
            dispatch(Actions.Connection.calendar.search.request(
                {
                    page_size:400,
                    start_at: moment().startOf("day").unix() + 1 //今日 1を入れておかないと前日も入る
                }
                ));
        }
    }
}

class ModuleCalendar extends React.Component {
    constructor(props) {
        super(props);
        log.debug(props);
        this.state = {
            title: props.title,
            data: props.data,
            parts: props.parts,
            calenderResultArray : []
        };
        this.props.getCalendar();
    }


    componentWillReceiveProps(nextProps) {

        this.setState({
            data: nextProps.data,
            parts: nextProps.parts
        });

        if (nextProps.calendarSearch !== this.props.calendarSearch) {
            if (!nextProps.calendarSearch.meta.fetch) {
                if (!nextProps.calendarSearch.error) {
                    if (nextProps.calendarSearch.payload !== null) {

                        //fixme 重複を一時的に排除する
                        let mergedCalendarArray = nextProps.calendarSearch.payload.result.items.filter(function (record, i, self) {
                            return self.findIndex(({summary,start_at,end_at}) =>
                                summary === record.summary && start_at === record.start_at && end_at === record.end_at) === i;
                        });


                        this.setState({
                            loading: nextProps.calendarSearch.meta.fetch,
                            calendarResultArray: mergedCalendarArray,
                        })
                    }
                }
            } else {
                this.setState({
                    loading: nextProps.calendarSearch.meta.fetch
                })
            }
        }

    }

    onChangeTab = (key) => {

    };

    handleClickSeeMore = () => {
        this.setState({
            listModalIsOpen: true
        })
    };

    handleRowClick = (record, index, event) => {
        this.setState({
            singleModalIsOpen: true
        })
    };


    render() {

        // const titleNode = (
        //     <div >
        //         <span className="module-title">{this.props.title}</span>
        //         {
        //             this.props.seeMore?
        //             <Button className="module-title-button" onClick={this.handleClickSeeMore}>すべて表示</Button> :
        //             <span />
        //         }
        //     </div>
        // );

        //fixme 一時的に学年暦の詳細ページを表示させない
        const titleNode = (
            <div >
                <span className="module-title"><CalendarFilled /> {this.props.title}</span>
            </div>
        );

        const columns = [{
            dataIndex: 'key',
            key: 'key',
            render: (text, record) => {

                let dateString = moment.unix(record.start_at).format("YYYY.MM.DD");
                if((record.end_at - record.start_at) > 60*60*24) {
                    // 翌日0時までのスケジュールは同日一杯までと見なして範囲を表示させない
                    if(moment.unix(record.end_at).isSame(moment.unix(record.end_at).startOf('day')) === true){
                        // 終了予定日が午前0時で終わっている場合１秒戻す（前日一杯で終了している予定）
                        dateString += " - " + (moment.unix(record.end_at).add(-1, 'seconds').format("YYYY.MM.DD"));
                    }else{
                        dateString += " - " + (moment.unix(record.end_at).format("YYYY.MM.DD"));
                    }

                }

                return(
                    <div className="module-cell module-link">
                        <div className="module-cell-head module-notification-head">
                            <div className="date">{dateString}</div>
                            <div className="tags-wrapper"></div>
                        </div>
                        <div className="module-cell-body module-notification-body">
                            {record.summary}
                        </div>
                    </div>
                );


            }
        }];

        return (
            <Card className="module-card" title={titleNode}>
                <Scrollbars autoHide>
                    <div className="module-inner module-calendar">
                        <Table
                            className="general-table"
                            locale={{
                                filterTitle: 'フィルタ',
                                filterConfirm: '確定',
                                filterReset: 'リセット',
                                emptyText: this.state.searchLoading ?
                                    <span><LoadingOutlined />&nbsp;読み込み中</span>:
                                    <span><CalendarOutlined />&nbsp;直近で表示できる学年暦はありません</span>,
                            }}
                            pagination={false}
                            bordered={true}
                            showHeader={false}
                            columns={columns} dataSource={this.state.calendarResultArray}
                            size="middle"
                            onRow={(record, rowIndex) => {
                                return {
                                    onClick: event => {
                                        this.handleRowClick(record)
                                    }
                                };
                            }}
                        />
                    </div>
                </Scrollbars>

                <ModuleCalendarModalList
                    title = {this.state.title}
                    visible={this.state.listModalIsOpen}
                    handleCancel={() => {
                        this.setState({
                            listModalIsOpen: false
                        })
                    }}
                    handleRowClick={(record, index, event) => {
                        this.handleRowClick(record,index,event);
                    }}
                    handleOk={() => {

                    }
                  }
                />

            </Card>
        );
    }
}


ModuleCalendar.propTypes = {
    title: PropTypes.string.isRequired,
    seeMore: PropTypes.bool.isRequired,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ModuleCalendar)
