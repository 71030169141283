import React from 'react'
import {connect, store} from 'react-redux'
import PropTypes from 'prop-types';
import {instanceOf} from 'prop-types';
import * as Actions from 'redux/actions'
import log from 'components/utils/Logger.js'
import * as TagNodeGenerator from 'components/utils/TagNodeGenerator'
import moment from 'moment';
import 'moment/locale/ja';
import {Icon as LegacyIcon} from '@ant-design/compatible';
import {ArrowRightOutlined, DownloadOutlined, LikeFilled, LikeOutlined} from '@ant-design/icons';
import {Modal, Button, Table, Tooltip, Spin} from 'antd';
import renderHTML from 'react-render-html';
import FroalaEditorView from 'react-froala-wysiwyg/FroalaEditorView';
import {likeEditConnection} from "../../../../redux/reducers";
import {statusEnum} from "redux/actions";

moment.locale('ja');

const mapStateToProps = (state, props) => {
    return {
        screen: state.screen,
        paramCategory: state.paramNotificationCategory,
        notification: state.notifioncation,
        notificationView: state.notificationView,
        notificationSetPinSelected: state.notificationSetPinSelected,
        notificationSetUnpinSelected: state.notificationSetUnpinSelected,
        likeEditConnection: state.likeEditConnection
    }
};

const mapDispatchToProps = dispatch => {
    return {
        viewNotification: (uuid) => {
            dispatch(Actions.Connection.notification.view.request(uuid));
        },
        resetViewNotification: () => {
            dispatch(Actions.Connection.notification.view.reset());
        },
        setMultiPin: (uuidArray) => {
            const object = {uuid: uuidArray};
            dispatch(Actions.Connection.notification.setpinselected.request(object));
        },
        setMultiUnpin: (uuidArray) => {
            const object = {uuid: uuidArray};
            dispatch(Actions.Connection.notification.setunpinselected.request(object));
        },
        setLike: (uuid) => {
            console.log(uuid)
            dispatch(Actions.http.connection.notification.like(uuid))
        },
        setUnLike: (uuid) => {
            dispatch(Actions.http.connection.notification.unlike(uuid))
        }
    }
};

class ModuleNotificationModalSingle extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: undefined,
            uuid: props.uuid,
            modalTitle: props.title,
            visible: props.visible,
            is_pinned: false,
            good_count: 0,
            is_reacted_good: false
        };
    }

    componentDidMount() {

    }

    componentWillReceiveProps(nextProps) {
        this.setState({
            modalTitle: nextProps.title,
            visible: nextProps.visible,
        });


        if (nextProps.visible !== this.props.visible && nextProps.visible) {
            if (typeof nextProps.uuid !== "undefined") {
                this.props.viewNotification(nextProps.uuid);
            }
        }



        if (nextProps.notificationView !== this.props.notificationView) {
            if (!nextProps.notificationView.meta.fetch) {
                if (!nextProps.notificationView.error) {
                    if (nextProps.notificationView.payload !== null) {
                        this.setState({
                            data: nextProps.notificationView.payload.result,
                            is_pinned: nextProps.notificationView.payload.result ? nextProps.notificationView.payload.result.is_pinned : false,
                            good_count: nextProps.notificationView.payload.result ? nextProps.notificationView.payload.result.good_count : 0,
                            is_reacted_good:  nextProps.notificationView.payload.result ? nextProps.notificationView.payload.result.is_reacted_good : false,
                        })
                    }
                }
            }
            this.setState({
                loading: nextProps.notificationView.meta.fetch
            })
        }

        if (nextProps.notificationSetPinSelected !== this.props.notificationSetPinSelected) {
            if (!nextProps.notificationSetPinSelected.meta.fetch) {
                if (!nextProps.notificationSetPinSelected.error) {
                    if (nextProps.notificationSetPinSelected.payload !== null) {
                        this.setState({
                            is_pinned: true
                        })
                    }
                }
            }
            this.setState({
                loading: nextProps.notificationSetPinSelected.meta.fetch
            })

        }

        if (nextProps.notificationSetUnpinSelected !== this.props.notificationSetUnpinSelected) {
            if (!nextProps.notificationSetUnpinSelected.meta.fetch) {
                if (!nextProps.notificationSetUnpinSelected.error) {
                    if (nextProps.notificationSetUnpinSelected.payload !== null) {
                        this.setState({
                            is_pinned: false
                        })
                    }
                }
            }
            this.setState({
                loading: nextProps.notificationSetUnpinSelected.meta.fetch
            })
        }

        if(nextProps.likeEditConnection !== this.props.likeEditConnection ){
            console.log(nextProps.likeEditConnection.payload.result)
            if(nextProps.likeEditConnection.meta.status  === statusEnum.SUCCESS){
                console.log(nextProps.likeEditConnection.payload.result)
                this.setState({
                    good_count: nextProps.likeEditConnection.payload.result.count,
                    is_reacted_good: !this.state.is_reacted_good
                })

            }
        }

    }

    handleOk = () => {
        this.props.handleOk();
    };

    handleCancel = () => {
        this.setState({
            data: undefined
        }, () => {
            this.props.resetViewNotification();
            this.props.handleCancel();
        })
    };

    handlePin = () => {
        if (this.state.data) {
            if (this.state.is_pinned) {
                //pin留め済み
                this.props.setMultiUnpin([this.state.data.uuid])
            } else {
                //pin留めされてない
                log.debug(this.state.data)
                this.props.setMultiPin([this.state.data.uuid])
            }
        }
    }

    reactiveGood = () => {
        if(this.state.is_reacted_good){
            this.props.setUnLike(this.state.data.uuid)
        }else{
            this.props.setLike(this.state.data.uuid)
        }
    }


    render() {
        const {visible, confirmLoading, data} = this.state;
        let titleNode = (
            <div className="module-notification-single-title">
                <div className="title"></div>
            </div>
        );
        const columns = [{
            title: '添付ファイル',
            dataIndex: "file_name",
            key: "file_name"
        }, {
            dataIndex: 'attachment',
            key: 'attachment',
            width: "50px",
            render: (text, record) => {
                return (
                    <Tooltip placement="topLeft" title="ファイルダウンロード">
                        <Button type="default"
                                shape="circle"
                                icon={<DownloadOutlined/>}
                                onClick={() => window.open(`${process.env.REACT_APP_FILE_DOMAIN}/file/view/${record.uuid}`, '_blank')}/>
                    </Tooltip>
                );
            }
        }];

        if (typeof data !== "undefined" && !this.state.loading) {
            const tagNode = TagNodeGenerator.generate(TagNodeGenerator.TYPES_NOTIFICATION_PRIORITY, data.priority);
            const publish_start = moment.unix(data.publish_start_at).format("YYYY.MM.DD HH:mm");
            const publish_end = moment.unix(data.publish_end_at).format("YYYY.MM.DD HH:mm");

            let publish_lastmod;
            if (typeof data.created_at === "undefined") {
                publish_lastmod = "プレビュー記事";
            } else {
                publish_lastmod = !!data.updated_at ? moment.unix(data.updated_at).format("最終更新 YYYY.MM.DD HH:mm") : moment.unix(data.created_at).format("最終更新 YYYY.MM.DD HH:mm");
            }

            let category;
            //previewの際はcategory情報を持っていないのでparamCategoryから検索
            if (data.category) {
                category = data.category.name;
            } else {
                this.props.paramCategory.payload.result.items.map((value, index) => {
                    if (value.uuid === data.category_uuid) {
                        category = value.name;
                    }
                })
            }


            titleNode = (
                <div className="module-notification-single-title">
                    <div className={"module-header-separate"}>
                        <div className="title">
                            <div>{tagNode}</div>
                            <div>{data.title}</div>
                        </div>
                        <div onClick={this.handlePin}
                             className={this.state.is_pinned ? "button-pin" : "button-pin unpin"}><LegacyIcon
                            type={"pushpin"}/></div>
                    </div>
                </div>
            );

            return (
                <Modal
                    className="common-modal module-notification-single"
                    style={{top: 20}}
                    title={titleNode}
                    visible={visible}
                    maskClosable={false}
                    onOk={this.handleOk}
                    confirmLoading={confirmLoading}
                    onCancel={this.handleCancel}
                    footer={[
                        <div className={"module-footer-separate"}>
                            <div className={"button-pin"} onClick={this.handlePin}>
                                <LegacyIcon type={"pushpin"} className={"pin"}/>
                                <span> {this.state.is_pinned ? "このお知らせをピン留め解除" : "このお知らせをピン留め"}</span>
                            </div>
                            <Button key="back" size="large" onClick={this.handleCancel}>閉じる</Button>
                        </div>
                    ]}
                >
                    <div className="meta">
                        <div className={'meta-flex'}>
                            <div>
                                <div className="date">公開期間 : {publish_start} <ArrowRightOutlined/> {publish_end}</div>
                                <div className="category">カテゴリ : {category}</div>
                                <div className="publish-from">配信元 : {data.publish_from}</div>
                            </div>
                            {data.images?.length > 0 &&
                                <img src={`${process.env.REACT_APP_FILE_DOMAIN}/file/view/${data.images[0].uuid}`}
                                     style={{height:78,width:"auto"}}/>
                            }
                        </div>
                    </div>

                    <div className='wrapper'>
                        <div className={"like-button"}>
                            <Button onClick={this.reactiveGood} className={this.state.is_reacted_good ? "like" : "unlike"} shape="round"
                                    icon={this.state.is_reacted_good ?"気になる！" : "気になる！"} > {this.state.good_count} </Button>
                        </div>
                        <div className="notification-preview">
                            <FroalaEditorView model={data.content}/>
                        </div>

                        <div className="last-modified">
                            {publish_lastmod}
                        </div>

                        {data && data.files !== null ?
                            <div className="notification-preview-download">
                                <Table
                                    className="general-table"
                                    locale={{
                                        filterTitle: 'フィルタ',
                                        filterConfirm: '確定',
                                        filterReset: 'リセット',
                                        emptyText: '該当するものはありません',
                                    }}
                                    bordered={true}
                                    pagination={false}
                                    columns={columns} dataSource={data.files}
                                    size="middle"
                                />
                            </div> :
                            null
                        }
                    </div>
                </Modal>
            );
        } else {
            return (
                <Modal
                    className="common-modal module-notification-single"
                    style={{top: 20}}
                    title={titleNode}
                    visible={visible}
                    maskClosable={false}
                    onOk={this.handleOk}
                    confirmLoading={confirmLoading}
                    onCancel={this.handleCancel}
                    footer={[
                        <Button key="back" size="large" onClick={this.handleCancel}>閉じる</Button>
                    ]}>
                    <Spin spinning={true}>
                        <div className='wrapper' style={{height: 200}}/>
                    </Spin>
                </Modal>
            );
        }
    }
}


export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ModuleNotificationModalSingle)
