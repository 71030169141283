import React from 'react'
import {connect, store} from 'react-redux'
import {instanceOf} from 'prop-types';
import log from 'components/utils/Logger.js'
import * as Actions from 'redux/actions'

import moment from 'moment';
import 'moment/locale/ja';
import { LoadingOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Tabs, Collapse, notification, Button, Spin } from 'antd';
import ContentHeader from "../../components/ContentHeader";
import ReactGridLayout from 'react-grid-layout'
import ResponsiveReactGridLayout from 'react-grid-layout'
import 'react-grid-layout/css/styles.css'
import 'react-resizable/css/styles.css'
import ModuleQuestionnaire from './modules/questionnaire/ModuleQuestionnaire'
import ModuleNotification from './modules/notification/ModuleNotification'
import ModuleLink from './modules/link/ModuleLink'
import ModuleCalendar from './modules/calendar/ModuleCalendar'
import ModuleFileShare from './modules/fileshare/ModuleFileShare'
import NoContent from "../../components/NoContent";
import ModuleCurriculum from "./modules/curriculum/ModuleCurriculum";


moment.locale('ja')

const TabPane = Tabs.TabPane;
const FormItem = Form.Item;
const Panel = Collapse.Panel;

const mapStateToProps = (state, props) => {
    return {
        screen: state.screen,
        layoutLoad: state.layoutLoad,
        layoutSave: state.layoutSave
    }
};

const mapDispatchToProps = dispatch => {
    return {
        windowResize: (dataHeight, dataWidth) => {
            dispatch(Actions.Config.screen(dataHeight, dataWidth));
        },
        loadLayout: (uuid) => {
            dispatch(Actions.Connection.layout.load.request(uuid));
        },
        saveLayout: (uuid, data) => {
            dispatch(Actions.Connection.layout.save.request(uuid, data));
        }
    }
};

class ModuleWrapper extends React.Component {
    constructor(props) {
        super(props);
        log.debug(props);

        this.state = {
            menuDetail: typeof props.menuDetail !== "undefined" ? props.menuDetail : {},
            layoutLoading: true
        };

    }


    componentWillMount() {
        if (this.props.menuDetail && this.props.menuDetail.hasOwnProperty("uuid")) {
            this.props.loadLayout(this.props.menuDetail.uuid);
        }
    }


    openNotification = () => {
        const key = "layoutsave";
        const btnClick = () => {
            const object = {
                layout: JSON.stringify(this.state.currentLayoutArray)
            }
            if (this.state.menuDetail && this.state.menuDetail.hasOwnProperty("uuid")) {
                this.props.saveLayout(this.state.menuDetail.uuid, object);
            }
            // to hide notification box
            notification.close(key);
        };
        const btn = (
            <Button type="primary" size="small" onClick={btnClick}>
                保存
            </Button>
        );
        notification.open({
            message: 'レイアウトの保存',
            description: '保存ボタンを押して、現在のレイアウトの並びを修正',
            duration: 0,
            btn,
            key,
            onClose: () => {
                return;
            },
        });

    };

    componentWillUnmount() {
        log.debug("unmount");
    }

    componentWillReceiveProps(nextProps) {

        if (nextProps.menuDetail !== this.props.menuDetail) {
            this.setState({
                currentLayoutArray: undefined,
                menuDetail: nextProps.menuDetail,
                layoutLoading: true
            })
            if (nextProps.menuDetail && nextProps.menuDetail.hasOwnProperty("uuid")) {
                this.props.loadLayout(nextProps.menuDetail.uuid);
            }
        }

        if (nextProps.layoutLoad !== this.props.layoutLoad) {
            if (!nextProps.layoutLoad.meta.fetch) {
                if (!nextProps.layoutLoad.error) {
                    if (nextProps.layoutLoad.payload !== null) {
                        if (nextProps.layoutLoad.payload.result.hasOwnProperty("layout")) {
                            this.setState({
                                layoutLoading: nextProps.layoutLoad.meta.fetch,
                                layoutLoadArray: JSON.parse(nextProps.layoutLoad.payload.result.layout),
                            })
                        } else {
                            this.setState({
                                layoutLoading: nextProps.layoutLoad.meta.fetch,
                                layoutLoadArray: [],
                            })
                        }
                    }else{
                        this.setState({
                            layoutLoading: nextProps.layoutLoad.meta.fetch
                        })
                    }
                } else {
                    this.setState({
                        layoutLoading: nextProps.layoutLoad.meta.fetch
                    })
                }
            } else {
                this.setState({
                    layoutLoading: nextProps.layoutLoad.meta.fetch
                })
            }
        }

    }

    onChangeTab = (key) => {

    };

    onLayoutChange = (layout, allLayouts) => {
        log.debug(layout);
        log.debug(this.state.layoutLoadArray);

        if (this.state.currentLayoutArray) {
            if (layout !== this.state.currentLayoutArray) {
                this.openNotification();
            }
        }

        this.setState({
            currentLayoutArray: layout
        })

        this.props.windowResize(document.body.clientHeight, document.body.clientWidth);
    };

    render() {
        const menuDetail = this.state.menuDetail;


        if (menuDetail.hasOwnProperty("modules") && menuDetail.modules.length !== 0) {
            log.debug(menuDetail.modules, "menu");


            const component = menuDetail.modules.map((value, index) => {
                let module;
                switch (value.type) {
                    //モジュール種別(1: リンクBOX 2: お知らせ 3: アンケート 4: 学年暦)
                    case 1:
                        module = (
                            <ModuleLink data={value} parts={value.parts} seeMore={false} title={value.name}/>
                        )
                        break;
                    case 2:
                        module = (
                            <ModuleNotification data={value} parts={value.parts} seeMore={true} title={value.name}/>
                        )
                        break;
                    case 3:
                        module = (
                            <ModuleQuestionnaire data={value} parts={value.parts} seeMore={true} title={value.name}/>
                        )
                        break;
                    case 4:
                        if(value.uuid === process.env.REACT_APP_DUMMY_UUID || value.uuid === process.env.REACT_APP_DUMMY_UUID2){
                            module = (
                                <ModuleFileShare data={value} parts={value.parts} seeMore={false} title={value.name}/>
                            );
                        }else{
                            module = (
                                <ModuleCalendar data={value} parts={value.parts} seeMore={true} title={value.name}/>
                            )
                        }
                        break;
                    case 5:
                        module = (
                            <ModuleFileShare data={value} parts={value.parts} seeMore={true} title={value.name}/>
                        );
                        break;
                    case 6:
                        module = (
                            <ModuleCurriculum data={value} parts={value.parts} seeMore={false} title={value.name}/>
                        );
                        break;

                    default:
                        break;
                }
                return (
                    <div key={value.uuid}>
                        <div className="drag-handle"></div>
                        {module}
                    </div>
                )
            })


            return (
                <div className='container clearfix' id="module-wrapper">
                    {this.state.layoutLoading ?
                        <div style={{padding:"200px 10px",textAlign:"center"}}><LoadingOutlined /> 読み込み中...</div> :
                        <ReactGridLayout
                            className="layout"
                            layout={this.state.layoutLoadArray}
                            cols={3}
                            onLayoutChange={this.onLayoutChange}
                            isDraggable={true}
                            draggableHandle=".drag-handle"
                            width={this.props.screen.payload.screenWidth - 250}>
                            {component}
                        </ReactGridLayout>
                    }
                </div>
            );


        } else {
            return (
                <div>
                    <NoContent/>
                </div>
            )
        }
    }
}


ModuleWrapper.propTypes = {};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ModuleWrapper)
