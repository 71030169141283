import React, {useContext, useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {DeleteOutlined, EditOutlined, UserOutlined} from "@ant-design/icons";
import {Button, Col, Empty, Modal, Popconfirm, Row, Table, Tooltip} from "antd";
import {ListContext, SearchContext} from "./MyTargetEffectComponent";
import * as GlobalConfig from "../../constants/GlobalConfig";
import {useDispatch} from "react-redux";
import * as Actions from '../../redux/actions'
import moment from "moment";

MyTargetList.propTypes = {};

function MyTargetList(props) {
    console.log("constructor")
    const dispatch = useDispatch();
    const [list, setList] = useContext(ListContext);
    const [search, setSearch] = useContext(SearchContext);
    const [loading, setLoading] = useState(true);
    const [openModal, setOpenModal] = useState(false);
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [pagination, setPagination] = useState({
            showTotal: (total, range) => {
                return (`全${total}件中 ${range[0]}-${range[1]} 件`)
            },
            pageSize: 20,
            total: list.targetListItemCount,
            position: ["topRight", "bottomRight"],
            showSizeChanger: true,
            pageSizeOptions: GlobalConfig.PageSizeOptions,
            onShowSizeChange: (page, page_size) => {
                setPagination(pagination => ({
                    ...pagination,
                    page,
                    pageSize: page_size
                }));
                setSearch(search => ({
                    ...search,
                    page,
                    page_size
                }))
            },
            onChange: (page, page_size) => {
                setPagination(pagination => ({
                    ...pagination,
                    current: page,
                    pageSize: page_size
                }));
                setSearch(search => ({
                    ...search,
                    page,
                    page_size
                }))
            }
        }
    );
    const columns = [
        {
            title: 'グループ名',
            dataIndex: 'name',
            key: 'name',
            ellipsis: true,
        },
        {
            title: "概要",
            dataIndex: "description",
            key: "description",
            ellipsis: true,
        }, {
            title: "作成時間",
            dataIndex: "updated_at",
            key: "update_at",
            width: "150px",
            render: (text, record) => {
                const created_at = moment.unix(record.created_at).format("YYYY/MM/DD HH:mm");
                return (
                    <span>
                        {created_at}
                    </span>
                )
            }
        },{
            title: "",
            width: "80px",
            key: 'action',
            ellipsis: true,
            render: (text, record) => {
                return (
                    <Row type="flex" justify="space-around" align="middle">
                        <Col>
                            <Popconfirm
                                title={<span>保存済み対象グループを削除します。</span>}
                                onConfirm={() => {
                                    dispatch(Actions.http.connection.target.myTarget.delete(record.uuid))
                                }}
                                onCancel="" placement="left" okText="削除" cancelText="キャンセル">
                                <Tooltip placement="topLeft" title="削除">
                                    <Button type="default" shape="circle" icon={<DeleteOutlined/>}/>
                                </Tooltip>
                            </Popconfirm>
                        </Col>
                    </Row>
                )
            }
        }
    ];

    useEffect(() => {
        dispatch(Actions.http.connection.target.myTarget.search());
    }, []);

    useEffect(() => {
        setLoading(list.loading)
        setPagination(pagination => ({
            ...pagination,
            total: list.targetListItemCount
        }))
    }, [list])

    useEffect(() => {
        const page = search.page ? search.page : pagination.current;
        delete search.page;
        search.page_size = pagination.pageSize ? pagination.pageSize : 20;
        dispatch(Actions.http.connection.target.myTarget.search(search, page));
        setPagination(pagination => ({
            ...pagination,
            current: page
        }));
    }, [search])

    useEffect(() => {
        setSelectedRowKeys([]);
        setSearch({})
    }, [props.visible])

    const rowSelection = {
        onChange: (selectedRowKeys, selectedRows) => {
            console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
            setSelectedRowKeys(selectedRowKeys);
            props.setGroup(selectedRows[0]);
        },
        selectedRowKeys
    };

    return (
        <Table
            onRow={(record, rowIndex) => {
                return {
                    onClick: event => {
                        console.log(`selectedRowKeys: ${record.uuid}`, 'selectedRows: ', record);
                        setSelectedRowKeys([record.uuid]);
                        props.setGroup(record);
                    }
                };
            }}
            rowSelection={{
                type: "radio",
                ...rowSelection,
            }}
            pagination={pagination}
            locale={{
                filterConfirm: '確定',
                filterReset: 'リセット',
                emptyText: <Empty image={Empty.PRESENTED_IMAGE_DEFAULT} description={'対象グループはありません'}/>,
            }}
            rowKey={"uuid"}
            loading={loading}
            bordered
            columns={columns}
            scroll={{X: "100%"}}
            dataSource={list.targetList}
            className="general-table"
        />
    );
};

export default MyTargetList;

