import React from 'react'
import {connect, store} from 'react-redux'
import PropTypes from 'prop-types';
import {instanceOf} from 'prop-types';
import * as TagNodeGenerator from 'components/utils/TagNodeGenerator'
import moment from 'moment';
import * as Actions from 'redux/actions'
import 'moment/locale/ja';

import {
    ArrowRightOutlined,
    DownloadOutlined,
    QuestionCircleOutlined,
    UploadOutlined,
} from '@ant-design/icons';

import { Form, Icon as LegacyIcon } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import {
    Modal,
    Table,
    Tabs,
    Collapse,
    Card,
    Input,
    Button,
    Select,
    Radio,
    Tooltip,
    Upload,
    Spin,
} from 'antd';
import FroalaEditorView from 'react-froala-wysiwyg/FroalaEditorView';
import log from "components/utils/Logger"

moment.locale('ja');
const TabPane = Tabs.TabPane;
const FormItem = Form.Item;
const Panel = Collapse.Panel;
const Option = Select.Option;
const RadioButton = Radio.Button;
const {TextArea} = Input;


const mapStateToProps = (state, props) => {
    return {
        screen: state.screen,
        images: state.images,
        questionnaireView: state.questionnaireView,
        questionnaireAnswer: state.questionnaireAnswer
    }
};

const mapDispatchToProps = dispatch => {
    return {
        uploadImages: (fileName, contentType, data) => {
            dispatch(Actions.Connection.images.request(fileName, contentType, data));
        },
        getQuestionnaire: (uuid) => {
            dispatch(Actions.Connection.questionnaire.view.request(uuid))
        }
    }
};

class ModuleQuestionnaireModalSingle extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: undefined,
            modalTitle: props.title,
            visible: props.visible,
            questionItemUuidArray: [],
            questionItemArray: [],
            answerState: {}
        };
    }

    componentWillReceiveProps(nextProps) {

        if(nextProps.visible !== this.props.visible && nextProps.visible){
            if(this.state.isAdmin) {
                this.props.getQuestionnaire(nextProps.uuid);
            }else{
                this.props.getQuestionnaire(nextProps.uuid);
            }
        }

        this.setState({
            visible: nextProps.visible
        });

        if (nextProps.questionnaireView !== this.props.questionnaireView) {
            if (!nextProps.questionnaireView.meta.fetch) {
                if (!nextProps.questionnaireView.error) {
                    if (nextProps.questionnaireView.payload !== null && nextProps.questionnaireView.payload.hasOwnProperty("result")) {
                        //questionnaireView
                        log.debug(nextProps.questionnaireView.payload,"ModuleQuestionnaireSingle");
                        const data = nextProps.questionnaireView.payload.result;
                        const questionItemArray = nextProps.questionnaireView.payload.result.items;
                        let questionItemUuidArray = [];
                        let answerState = {};
                        questionItemUuidArray = questionItemArray.map((value, index) => {
//                            question_answer_candidate_uuid:"97eb9cd4-d525-11e7-a8bf-0242ac120005"
                            if(value.answers.length !== 0){
                                const answerObject = value.answers[0];
                                switch(answerObject.type){
                                    case 1:
                                        answerState[value.uuid] = answerObject.answer_choice[0].question_answer_candidate_uuid;
                                        break;
                                    case 2:
                                        const answer_choices = answerObject.answer_choice.map((value)=>{
                                           return value.question_answer_candidate_uuid;
                                        });
                                        answerState[value.uuid] = answer_choices;
                                        break;
                                    case 3:
                                        answerState[value.uuid] = answerObject.content;
                                        break;
                                    case 4:
                                        //ここは確実に空の場合はnull
                                        if (answerObject.file !== null) {
                                            let constObject = answerObject.file;
                                            constObject.name = constObject.file_name;
                                            constObject.uid = Math.floor(Math.random()*10000);
                                            constObject.status = 'done';
                                            constObject.url = `${process.env.REACT_APP_FILE_DOMAIN}/file/view/${constObject.uuid}`;
                                            if(constObject.hasOwnProperty("mime_type") && constObject.mime_type.match("image")){
                                                constObject.thumbUrl = `${process.env.REACT_APP_FILE_DOMAIN}/file/view/${constObject.uuid}`;
                                            }else{
                                                constObject.thumbUrl = process.env.PUBLIC_URL + '/static/media/attach.svg';
                                            }
                                            answerState[value.uuid] = [constObject];
                                        //    keys_questions_state[`keys_questions_${value.uuid}_fileList`] = [constObject];
                                        }
                                        break;
                                }
                            }
                            return (
                                value.uuid
                            )
                        });
                        const isAnonymous = nextProps.questionnaireView.payload.result.is_anonymous;
                        this.setState({
                            answerState,
                            questionItemArray,
                            questionItemUuidArray,
                            data,
                            isAnonymous
                        })
                    }
                }else{
                    this.handleCancel();
                }
            }
            this.setState({
                loading: nextProps.questionnaireView.meta.fetch
            });
        }


        //imagesアップデート差分
        if (nextProps.images !== this.props.images) {
            if (!nextProps.images.meta.fetch) {
                if (!nextProps.images.error) {
                    if (nextProps.images.payload !== null && nextProps.images.payload.hasOwnProperty("result")) {
                        //const fileList = typeof this.state.answerState[this.state.uploading_key] === "undefined" ? [] : this.state.answerState[this.state.uploading_key];
                        const fileList = [];
                        const map = fileList.some((value) => {
                            return value === nextProps.images.payload.result;
                        });

                        //OPTIMIZE fileListが単体ではない可能性
                        if (!map) {
                            let constObject = nextProps.images.payload.result;
                            constObject.name = constObject.file_name;
                            constObject.uid = Math.floor(Math.random() * 10000);
                            constObject.response = nextProps.images.payload;
                            constObject.status = 'done';
                            constObject.url = `${process.env.REACT_APP_FILE_DOMAIN}/file/view/${constObject.uuid}`;
                            if (constObject.hasOwnProperty("mime_type") && constObject.mime_type.match("image")) {
                                constObject.thumbUrl = `${process.env.REACT_APP_FILE_DOMAIN}/file/view/${constObject.uuid}`;
                            } else {
                                constObject.thumbUrl = process.env.PUBLIC_URL + '/static/media/attach.svg';
                            }
                            let constArray = fileList.concat();
                            constArray[constArray.length] = constObject;

                            const key = this.state.uploading_key;

                            const answerState = this.state.answerState;
                            answerState[key] = constArray;

                            this.setState({
                                answerState,
                                uploading_key: ""
                            })
                        }

                    }
                }
            }
            this.setState({
                uploading: nextProps.images.meta.fetch
            })
        }

        //ボタンのloading殺す
        this.setState({
            submitLoading : nextProps.questionnaireAnswer.meta.fetch
        });

        if (!nextProps.visible) {
            this.props.form.resetFields();
            this.setState({
                questionItemUuidArray: [],
                questionItemArray: [],
                answerState: {},
                submitLoading: false
            })
        }
    }

    handleOk = () => {
        this.props.form.validateFields((err, values) => {

            log.debug(this.state.questionItemUuidArray);

            if (!err) {
                let postArray = this.state.questionItemUuidArray.map((value, index) => {
                    let object = {};
                    object.question_item_uuid = value;

                    log.debug(value);

                    if (typeof this.state.answerState[value] !== "undefined") {
                        switch (this.state.questionItemArray[index].type) {
                            case 1:
                                object.answer_choices = [
                                    {
                                        question_answer_candidate_uuid: this.state.answerState[value]
                                    }
                                ];
                                break;

                            case 2:
                                object.answer_choices = this.state.answerState[value].map((v, index) => {
                                    return {
                                        question_answer_candidate_uuid: v
                                    }
                                });
                                break;
                            case 3:
                                object.content = this.state.answerState[value];
                                break;
                            case 4:
                                object.file = this.state.answerState[value][0].uuid;
                                break;
                        }
                    }
                    return object;

                });

                const answerObject = {
                    answers: postArray
                };

                this.setState({
                    submitLoading: true
                },()=>{
                    this.props.handleOk(this.state.data.uuid, answerObject);
                });
            }
        });
    };

    handleCancel = () => {
        this.setState({
            data: undefined
        },()=> {
            this.props.handleCancel();
        });
    };

    //image
    handleUpload = (file, key) => {
        const fileReader = new FileReader();
        fileReader.onload = (event) => {
            this.props.uploadImages(file.name, file.type, event.target.result);
        };
        this.setState({
            uploading: true,
            uploading_key: key
        });
        fileReader.readAsArrayBuffer(file);
    };

    handleSelect = (value, key) => {
        //key 設問uuid
        //value 選んだ回答uuid
        const {answerState,questionItemArray} = this.state;

        let editedAnswerState = answerState;
        questionItemArray.forEach((itemValue, index) => {
            if (itemValue.item_branch_choice.length !== 0) {
                if (itemValue.item_branch_choice[0].parent_question_item_uuid === key) {
                    if (itemValue.item_branch_choice[0].question_answer_candidate_uuid !== value) {
                        //回答のステートから削除
                        delete answerState[itemValue.uuid];
                        //また、その回答を親にもつ子要素の削除
                        questionItemArray.forEach((itemValue2) => {
                            if (itemValue2.item_branch_choice.length !== 0) {
                                if (itemValue2.item_branch_choice[0].parent_question_item_uuid === itemValue.uuid) {
                                    delete editedAnswerState[itemValue2.uuid];
                                }
                            }
                        });
                    }
                }
            }
        });
        log.debug(editedAnswerState);

        editedAnswerState[key] = value;
        this.setState({
            answerState: editedAnswerState
        })
    };


    render() {
        const formItemLayout = {
            labelCol: {
                xs: {span: 24},
                sm: {span: 4},
            },
            wrapperCol: {
                xs: {span: 24},
                sm: {span: 20},
            },
        };
        const {getFieldDecorator, setFieldsValue} = this.props.form;
        const {data, visible, confirmLoading, questionItemUuidArray, questionItemArray} = this.state;


        const columns = [{
            title: '添付ファイル',
            dataIndex: "file_name",
            key: "file_name"
        }, {
            dataIndex: 'attachment',
            key: 'attachment',
            width: "50px",
            render: (text, record) => {

                return (
                    <Tooltip placement="topLeft" title="ファイルダウンロード">
                        <Button type="default" shape="circle" icon={<DownloadOutlined />}
                                onClick={() => window.open(`${process.env.REACT_APP_FILE_DOMAIN}/file/view/${record.uuid}`, '_blank')}/>
                    </Tooltip>
                );
            }
        }];

        const elementItem = questionItemArray.map((itemValue, index) => {

            const commonElement = (
                <div className="question-form-questionnaire-header">

                    <h3>{itemValue.content}</h3>
                    <span className="plain-text">{itemValue.detail}</span>

                    {itemValue.file !== null ?
                        <div className="notification-preview-download">
                            <Table
                                className="general-table"
                                locale={{
                                    filterTitle: 'フィルタ',
                                    filterConfirm: '確定',
                                    filterReset: 'リセット',
                                    emptyText: '該当するものはありません',
                                }}
                                bordered={true}
                                pagination={false}
                                columns={columns} dataSource={[itemValue.file]}
                                size="middle"
                            />
                        </div> :
                        null}
                </div>
            );

            if (itemValue.item_branch_choice.length === 0) {
                switch (itemValue.type) {
                    case 1:

                        const Options = itemValue.answer_candidates.map((value, index) => {
                            return (
                                <Option key={value.uuid} value={value.uuid}>{value.content}</Option>
                            )
                        });

                        return (
                            <div key={itemValue.uuid} className="question_form">
                                <div>{commonElement}</div>
                                <FormItem
                                    {...formItemLayout}
                                    label={
                                        <span>
                                     単一回答&nbsp;&nbsp;
                                            <Tooltip title={(<div>回答を１つ選択してください</div>)}>
                                        <QuestionCircleOutlined />
                                    </Tooltip>
                                 </span>
                                    }>
                                    {getFieldDecorator([itemValue.uuid], {
                                        initialValue: this.state.answerState[itemValue.uuid],
                                        rules: [
                                            {
                                                required: itemValue.is_required,
                                                message: '必須項目です。回答を選択してください。'
                                            },
                                        ],
                                    })(
                                        <Select placeholder={itemValue.content}
                                                onChange={(value) => this.handleSelect(value, itemValue.uuid)}>
                                            {Options}
                                        </Select>
                                    )}
                                </FormItem>
                            </div>
                        );
                        break;
                    case 2:

                        const multiOptions = itemValue.answer_candidates.map((value, index) => {
                            return (
                                <Option key={value.uuid} value={value.uuid}>{value.content}</Option>
                            )
                        });

                        return (
                            <div key={itemValue.uuid} className="question_form">
                                <div>{commonElement}</div>
                                <FormItem
                                    {...formItemLayout}
                                    label={
                                        <span>
                                     複数回答&nbsp;&nbsp;
                                            <Tooltip title={(<div>回答を複数選択してください</div>)}>
                                        <QuestionCircleOutlined />
                                    </Tooltip>
                                 </span>
                                    }>
                                    {getFieldDecorator([itemValue.uuid], {
                                        initialValue: this.state.answerState[itemValue.uuid],
                                        rules: [
                                            {
                                                required: itemValue.is_required, type: 'array',
                                                message: '必須項目です。回答を選択してください。'
                                            },
                                        ],
                                    })(
                                        <Select mode="multiple" placeholder={itemValue.content}
                                                onChange={(value) => this.handleSelect(value, itemValue.uuid)}>
                                            {multiOptions}
                                        </Select>
                                    )}
                                </FormItem>
                            </div>
                        );
                        break;
                    case 3:
                        return (
                            <div key={itemValue.uuid} className="question_form">
                                <div>{commonElement}</div>
                                <FormItem
                                    {...formItemLayout}
                                    label="自由入力"
                                    layout="inline">
                                    {getFieldDecorator([itemValue.uuid], {
                                        initialValue: this.state.answerState[itemValue.uuid],
                                        rules: [{
                                            required: itemValue.is_required,
                                            message: '必須項目です。内容を入力してください。'
                                        }]
                                    })(
                                        <TextArea placeholder="回答を入力してください" autosize={{minRows: 5, maxRows: 12,}}
                                                  onChange={(value) => this.handleSelect(value.target.value, itemValue.uuid)}/>
                                    )}
                                </FormItem>
                            </div>
                        );
                        break;
                    case 4:
                        return (
                            <div key={itemValue.uuid} className="question_form">
                                <div>{commonElement}</div>
                                <FormItem
                                    {...formItemLayout}
                                    label="ファイル添付">
                                    {getFieldDecorator([itemValue.uuid], {
                                        initialValue: this.state.answerState[itemValue.uuid],
                                        rules: [{
                                            required: itemValue.is_required,
                                            message: '必須項目です。ファイルを添付してください。'
                                        }]
                                    })(
                                        <Upload
                                            onRemove={(file) => {
                                                const answerState = this.state.answerState;
                                                const fileList = answerState[itemValue.uuid];
                                                const index = fileList.indexOf(file);
                                                let newFileList = fileList.slice();
                                                newFileList.splice(index, 1);

                                                if (newFileList.length === 0) {
                                                    newFileList = null;
                                                }
                                                answerState[itemValue.uuid] = newFileList;
                                                this.setState({
                                                    answerState
                                                })
                                                setFieldsValue({
                                                    [itemValue.uuid]: null
                                                });
                                            }}
                                            beforeUpload={(file) => {
                                                this.handleUpload(file, [itemValue.uuid]);
                                                return false;
                                            }}
                                            handleChange={this.handleChange}
                                            fileList={this.state.answerState[itemValue.uuid]}
                                        >
                                            <Button loading={this.state.uploading} icon={<LegacyIcon type={'upload'} />}>
                                                ファイルを添付
                                            </Button>
                                        </Upload>
                                    )}
                                </FormItem>


                            </div>
                        );


                        break;
                    default:
                        return (
                            <div key={itemValue.uuid} className="question_form">


                            </div>
                        );

                        break;
                }
            } else {

                if (typeof this.state.answerState[itemValue.item_branch_choice[0].parent_question_item_uuid] !== "undefined") {
                    if (this.state.answerState[itemValue.item_branch_choice[0].parent_question_item_uuid] === itemValue.item_branch_choice[0].question_answer_candidate_uuid) {
                        switch (itemValue.type) {
                            case 1:
                                const Options = itemValue.answer_candidates.map((value, index) => {
                                    return (
                                        <Option key={value.uuid} value={value.uuid}>{value.content}</Option>
                                    )
                                });
                                return (
                                    <div key={itemValue.uuid} className="question_form">
                                        <div>{commonElement}</div>
                                        <FormItem
                                            {...formItemLayout}
                                            label={
                                                <span>
                                     単一回答&nbsp;&nbsp;
                                                    <Tooltip title={(<div>回答を１つ選択してください</div>)}>
                                        <QuestionCircleOutlined />
                                    </Tooltip>
                                 </span>
                                            }>
                                            {getFieldDecorator([itemValue.uuid], {
                                                initialValue: this.state.answerState[itemValue.uuid],
                                                rules: [
                                                    {
                                                        required: itemValue.is_required,
                                                        message: '必須項目です。回答を選択してください。'
                                                    },
                                                ],
                                            })(
                                                <Select placeholder={itemValue.content}
                                                        onChange={(value) => this.handleSelect(value, itemValue.uuid)}>
                                                    {Options}
                                                </Select>
                                            )}
                                        </FormItem>
                                    </div>
                                );
                                break;
                            case 2:

                                const multiOptions = itemValue.answer_candidates.map((value, index) => {
                                    return (
                                        <Option key={value.uuid} value={value.uuid}>{value.content}</Option>
                                    )
                                });
                                return (
                                    <div key={itemValue.uuid} className="question_form">
                                        <div>{commonElement}</div>
                                        <FormItem
                                            {...formItemLayout}
                                            label={
                                                <span>
                                     複数回答&nbsp;&nbsp;
                                                    <Tooltip title={(<div>回答を複数選択してください</div>)}>
                                        <QuestionCircleOutlined />
                                    </Tooltip>
                                 </span>
                                            }>
                                            {getFieldDecorator([itemValue.uuid], {
                                                initialValue: this.state.answerState[itemValue.uuid],
                                                rules: [
                                                    {
                                                        required: itemValue.is_required, type: 'array',
                                                        message: '必須項目です。回答を選択してください。'
                                                    },
                                                ],
                                            })(
                                                <Select mode="multiple" placeholder={itemValue.content}
                                                        onChange={(value) => this.handleSelect(value, itemValue.uuid)}>
                                                    {multiOptions}
                                                </Select>
                                            )}
                                        </FormItem>
                                    </div>
                                );
                                break;
                            case 3:
                                return (
                                    <div key={itemValue.uuid} className="question_form">
                                        <div>{commonElement}</div>
                                        <FormItem
                                            {...formItemLayout}
                                            label="自由入力"
                                            layout="inline">
                                            {getFieldDecorator([itemValue.uuid], {
                                                initialValue: this.state.answerState[itemValue.uuid],
                                                rules: [{
                                                    required: itemValue.is_required,
                                                    message: '必須項目です。回答を入力してください。'
                                                }]
                                            })(
                                                <TextArea placeholder="回答を入力してください"
                                                          autosize={{minRows: 5, maxRows: 12,}}
                                                          onChange={(value) => this.handleSelect(value.target.value, itemValue.uuid)}/>
                                            )}
                                        </FormItem>
                                    </div>
                                );
                                break;
                            case 4:
                                return (
                                    <div key={itemValue.uuid} className="question_form">
                                        <div>{commonElement}</div>
                                        <FormItem
                                            {...formItemLayout}
                                            label="ファイル添付">
                                            {getFieldDecorator([itemValue.uuid], {
                                                initialValue: this.state.answerState[itemValue.uuid],
                                                rules: [{
                                                    required: itemValue.is_required,
                                                    message: '必須項目です。ファイルを添付してください。'
                                                }]
                                            })(
                                                <Upload
                                                    onRemove={(file) => {
                                                        const answerState = this.state.answerState;
                                                        const fileList = answerState[itemValue.uuid];
                                                        const index = fileList.indexOf(file);
                                                        let newFileList = fileList.slice();
                                                        newFileList.splice(index, 1);

                                                        if (newFileList.length === 0) {
                                                            newFileList = null;
                                                        }
                                                        answerState[itemValue.uuid] = newFileList;
                                                        this.setState({
                                                            answerState
                                                        });
                                                        setFieldsValue({
                                                            [itemValue.uuid]: null
                                                        });
                                                    }}
                                                    beforeUpload={(file) => {
                                                        this.handleUpload(file, [itemValue.uuid]);
                                                        return false;
                                                    }}
                                                    handleChange={this.handleChange}
                                                    fileList={this.state.answerState[itemValue.uuid]}
                                                >
                                                    <Button>
                                                        <UploadOutlined /> ファイルを添付
                                                    </Button>
                                                </Upload>
                                            )}
                                        </FormItem>


                                    </div>
                                );


                                break;
                            default:
                                return (
                                    <div key={itemValue.uuid} className="question_form">


                                    </div>
                                );

                                break;
                        }
                    }else{
                        log.debug(itemValue,"アンケートdebug");
                        log.debug("debug","アンケートdebug");
                    }
                }
                /*
                created_at:1509958005
                parent_question_item_uuid:"848ca1fc-3e0e-3a51-bff4-a899159dc779"
                question_answer_candidate_uuid:"f7cffaa8-40a7-32be-acd7-23a44a1f3d0e"
                updated_at:null
                uuid:"b64681be-771b-31f3-aa0b-885c15f79cb3"
                */
            }
        });

        if (typeof data !== "undefined" && !this.state.loading) {
            const tagNode = TagNodeGenerator.generate(TagNodeGenerator.TYPES_QUESTIONNAIRE_PUBLIC, data.is_public);
            const tagNode2 = TagNodeGenerator.generate(TagNodeGenerator.TYPES_QUESTIONNAIRE_ANONYMOUS, data.is_anonymous);
            const tagNode3 = TagNodeGenerator.generate(TagNodeGenerator.TYPES_QUESTIONNAIRE_PUBLIC_RESULT, data.is_public_result);


            const publish_start = moment.unix(data.publish_start_at).format("YYYY.MM.DD HH:mm");
            const publish_end = moment.unix(data.publish_end_at).format("YYYY.MM.DD HH:mm");
            const perform_start = moment.unix(data.perform_start_at).format("YYYY.MM.DD HH:mm");
            const perform_end = moment.unix(data.perform_end_at).format("YYYY.MM.DD HH:mm");
            const publish_result_start = moment.unix(data.publish_result_start_at).format("YYYY.MM.DD HH:mm");
            const publish_result_end = moment.unix(data.publish_result_end_at).format("YYYY.MM.DD HH:mm");

            let publish_lastmod;
            if (typeof data.created_at === "undefined") {
                publish_lastmod = "プレビュー記事";
            } else {
                publish_lastmod = !!data.updated_at ? moment.unix(data.updated_at).format("最終更新 YYYY.MM.DD HH:mm") : moment.unix(data.created_at).format("最終更新 YYYY.MM.DD HH:mm");
            }

            let category;
            if (data.category) {
                category = data.category.name;
            } else {
                this.props.paramCategory.payload.result.items.map((value, index) => {
                    if (value.uuid === data.category_uuid) {
                        category = value.name;
                    }
                })
            }

            let titleNode;
            titleNode = (
                <div className="module-single-title">
                    <div className="title">{tagNode}{tagNode2}{tagNode3}<span>{data.name}</span></div>
                </div>
            )

            log.debug(this.state.submitLoading);
            const footer = this.state.isPreviewMode ?
                [<Button key="back" size="large" onClick={this.handleCancel}>閉じる</Button>]
                : [
                    <Button key="back" size="large" onClick={this.handleCancel}>閉じる</Button>,
                    <Button loading={this.state.submitLoading} key="answer" type="primary" size="large" onClick={this.handleOk}>回答する</Button>
                ];

            return (
                <Modal
                    className="common-modal module-questionnaire-single"
                    style={{top: 20}}
                    title={titleNode}
                    visible={visible}
                    maskClosable={false}
                    onOk={this.handleOk}
                    confirmLoading={confirmLoading}
                    onCancel={this.handleCancel}
                    footer={footer}
                >

                    <div className="meta">

                        <div className="date">公開期間 : {publish_start} <ArrowRightOutlined /> {publish_end}</div>
                        <div className="date">実施期間 : {perform_start} <ArrowRightOutlined /> {perform_end}</div>
                        {data.is_public_result ?
                            <div className="date">結果公開期間 : {publish_result_start} <ArrowRightOutlined /> {publish_result_end}</div>
                            :
                            null
                        }
                        <div className="category">配信元 : {data.publish_from} </div>
                        <div className="category">カテゴリ : {category} </div>
                    </div>

                    <div className='wrapper questionnaire-modal-single'>
                        <div className="question-modal-header">
                            <h3>{data.name}</h3>
                            <FroalaEditorView model={data.content} />
                        </div>

                        <Form>
                            {elementItem}
                        </Form>
                    </div>
                </Modal>
            );
        } else {


            let titleNode = (
                <div className="module-notification-single-title">
                    <div className="title">　</div>
                </div>
            );
            return (
                <Modal
                    className="common-modal module-questionnaire-single"
                    style={{top: 20}}
                    title={titleNode}
                    visible={visible}
                    maskClosable={false}
                    onOk={this.handleOk}
                    confirmLoading={confirmLoading}
                    onCancel={this.handleCancel}
                    footer={[
                        <Button key="back" size="large" onClick={this.handleCancel}>閉じる</Button>
                    ]}>
                    <Spin spinning={true}>
                        <div className='wrapper' style={{height: 200}} />
                    </Spin>
                </Modal>
            );
        }
    }
}


export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Form.create()(ModuleQuestionnaireModalSingle))
