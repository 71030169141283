import axios from 'axios'
import * as Actions from './actions'
import log from 'components/utils/Logger'
import * as url from 'constants/api'
import { show, hide } from 'react-progress-2-redux'
import moment from 'moment';
import 'moment/locale/ja';
import {apiAccess} from "../components/utils/AxiosWrapper";
moment.locale('ja');



export const connection = store => next => action => {
    next(action);
    // POSTリクエスト
    if (action.type.match("REQUEST$")) {
        if (action.payload.method !== null && action.payload.method === "post") {
            next(show());
            axios({
                method: 'post',
                url: action.payload.api,
                baseURL: url.API_DOMAIN,
                timeout: 120000,
                withCredentials: true,
                headers: {
                    'Content-Type': 'application/json',
                    'Cache-Control': 'no-cache',
                    'If-Modified-Since': 'Thu, 01 Jun 1970 00:00:00 GMT'
                },
                data: JSON.stringify(action.payload.data)
            })
                .then(res => {
                    //promiseのrender波及予防 timeout
                    setTimeout(() => {
                        next(hide());
                        const responseJson = res.data;
                        let type = action.type.replace(/REQUEST$/, "RECEIVE")
                        if (responseJson.is_successful) {
                            next({
                                type: type,
                                meta: {
                                    fetch: false
                                },
                                payload: responseJson
                            })
                        } else {
                            next({
                                type: type,
                                error: true,
                                meta: {
                                    fetch: false
                                },
                                payload: responseJson
                            })
                        }
                    });
                })
                .catch(error => {
                    next(hide());
                    let type = action.type.replace(/REQUEST$/, "RECEIVE");

                    let payload = {};
                    if(error.response) {
                        payload = {
                            code: error.response.status ? error.response.status : 500,
                            result: {
                                message: "APIとの通信に失敗しました。しばらく時間をおいてアクセスしてください。",
                                url: error.response.config.url
                            }
                        };
                    }else{
                        payload = {
                            code: 500,
                            result: {
                                message: "APIとの通信に失敗しました。しばらく時間をおいてアクセスしてください。",
                            }
                        };
                    }
                    next({
                        type: type,
                        meta: {
                            fetch: false
                        },
                        error: true,
                        payload: payload
                    })
                })
            ;
        }

        // GETリクエスト
        if (action.payload.method !== null && action.payload.method === 'get') {
            next(show());
            axios({
                method: 'get',
                url: action.payload.api,
                baseURL: url.API_DOMAIN,
                timeout: action.type !== Actions.AdminConnection.user.csv.request().type ? 120000 : 600000,
                responseType: 'json',
                withCredentials: true,
                headers: {
                    'Content-Type': 'application/json',
                    'Cache-Control': 'no-cache',
                    'If-Modified-Since': 'Thu, 01 Jun 1970 00:00:00 GMT'
                },
                params: action.payload.params
            })
                .then(res => {
                    //promiseのrender波及予防用timeout
                    setTimeout(() => {
                        next(hide());
                        const responseJson = res.data;
                        let type = action.type.replace(/REQUEST$/, "RECEIVE")
                        if (responseJson.is_successful) {
                            next(Object.assign(action, {
                                    type: type,
                                    payload: res.data,
                                    meta: Object.assign(action.meta, {
                                        fetch: false
                                    })
                                })
                            );
                        } else {
                            next(Object.assign(action, {
                                    type: type,
                                    error: true,
                                    payload: res.data,
                                    meta: Object.assign(action.meta, {
                                        fetch: false
                                    })
                                })
                            );
                        }
                    });
                })
                .catch(error => {
                    next(hide());
                    let type = action.type.replace(/REQUEST$/, "RECEIVE");
                    let payload = {};

                    if(error.response) {
                        payload = {
                            code: error.response.status ? error.response.status : 500,
                            result: {
                                message: "APIとの通信に失敗しました。しばらく時間をおいてアクセスしてください。",
                                url: error.response.config.url
                            }
                        };
                    }else{
                        payload = {
                            code: 500,
                            result: {
                                message: "APIとの通信に失敗しました。しばらく時間をおいてアクセスしてください。",
                            }
                        };
                    }

                    next({
                        type: type,
                        meta: {
                            fetch: false
                        },
                        error: true,
                        payload: payload
                    })
                });
        }

        // PUTリクエスト
        if (action.payload.method !== null && action.payload.method === "put") {
            next(show());
            let contentType = {'Content-Type': 'application/json'};
            let data;

            if(action.payload.hasOwnProperty("contentType")){

                contentType = {'Content-Type':action.payload.contentType};
                data = action.payload.data;
            }else{
                data = JSON.stringify(action.payload.data);
            }

            axios({
                method: 'put',
                url: action.payload.api,
                baseURL: url.API_DOMAIN,
                timeout: 120000,
                withCredentials: true,
                headers: contentType,
                data: data
            })
                .then(res => {
                    //promiseのrender波及予防 timeout
                    setTimeout(() => {
                        next(hide());
                        const responseJson = res.data;

                        let type = action.type.replace(/REQUEST$/, "RECEIVE")
                        if (responseJson.is_successful) {
                            next({
                                type: type,
                                meta: {
                                    fetch: false
                                },
                                payload: responseJson
                            })
                        } else {
                            next({
                                type: type,
                                error: true,
                                meta: {
                                    fetch: false
                                },
                                payload: responseJson
                            })
                        }
                    });
                })
                .catch(error => {
                    setTimeout(() => {
                        next(hide());
                        let type = action.type.replace(/REQUEST$/, "RECEIVE");

                        let payload = {};
                        if(error.response){

                            payload = {
                                code: error.response.status ? error.response.status : 500,
                                result: {
                                    message: "APIとの通信に失敗しました。しばらく時間をおいてアクセスしてください。",
                                    url: error.response.config.url
                                }
                            };
                        }else{
                            payload = {
                                code : 500,
                                result:{message:"APIとの通信に失敗しました。しばらく時間をおいてアクセスしてください。"}};

                        }
                        next({
                            type: type,
                            meta: {
                                fetch: false
                            },
                            error: true,
                            payload: payload
                        })
                    });
                })
            ;
        }



        // DELETEリクエスト
        if (action.payload.method !== null && action.payload.method === 'delete') {
            //store.dispatch(showLoading())
            next(show());
            axios({
                method: 'delete',
                url: action.payload.api,
                baseURL: url.API_DOMAIN,
                timeout: 120000,
                responseType: 'json',
                withCredentials: true,
                headers: {'Content-Type': 'application/json'},
            })
                .then(res => {
                    //promiseのrender波及予防用timeout
                    //setTimeout(() => {
                        next(hide());
                        const responseJson = res.data;

                        let type = action.type.replace(/REQUEST$/, "RECEIVE")
                        if (responseJson.is_successful) {
                            next({
                                type: type,
                                meta: {
                                    fetch: false
                                },
                                payload: responseJson
                            })
                        } else {
                            next({
                                type: type,
                                error: true,
                                meta: {
                                    fetch: false
                                },
                                payload: responseJson
                            })
                        }
                    //});
                })
                .catch(error => {
                    next(hide());

                    let type = action.type.replace(/REQUEST$/, "RECEIVE");
                    let payload = {};
                    if(error.response) {

                        payload = {
                            code: error.response.status ? error.response.status : 500,
                            result: {
                                message: "APIとの通信に失敗しました。しばらく時間をおいてアクセスしてください。",
                                url: error.response.config.url
                            }
                        };
                    }else{
                        payload = {
                            code: 500,
                            result: {
                                message: "APIとの通信に失敗しました。しばらく時間をおいてアクセスしてください。",
                            }
                        };
                    }

                    next({
                        type: type,
                        meta: {
                            fetch: false
                        },
                        error: true,
                        payload: payload
                    })
                });
        }

        // DELETEリクエスト
        if (action.payload.method !== null && action.payload.method === 'LICENSE') {
            axios({
                method: 'get',
                url: action.payload.api,
                baseURL: "/",
                timeout: 120000,
                withCredentials: true,
                headers: {
                    'Content-Type': 'text/*',
                    'Cache-Control': 'no-cache',
                    'If-Modified-Since': 'Thu, 01 Jun 1970 00:00:00 GMT'
                }
            })
                .then(res => {
                    //promiseのrender波及予防用timeout
                    //setTimeout(() => {
                        next(hide());
                        const text = res.data;
                        let type = action.type.replace(/REQUEST$/, "RECEIVE")
                        next({
                            type: type,
                            meta: {
                                fetch: false
                            },
                            payload: text
                        })

                    //});
                })
                .catch(error => {
                    next(hide());
                    let type = action.type.replace(/REQUEST$/, "RECEIVE");

                    let payload = {};
                    if(error.response) {
                        payload = {
                            code: error.response.status ? error.response.status : 500,
                            result: {
                                message: "APIとの通信に失敗しました。しばらく時間をおいてアクセスしてください。",
                                url: error.response.config.url
                            }
                        };
                    }else{
                        payload = {
                            code: 500,
                            result: {
                                message: "APIとの通信に失敗しました。しばらく時間をおいてアクセスしてください。",
                            }
                        };
                    }

                    next({
                        type: type,
                        meta: {
                            fetch: false
                        },
                        error: true,
                        payload: payload
                    })
                });
        }

    }

    //ここから上旧仕様


}


//ここから下新仕様
export const nextConnection = store => next => action => {
    next(action);
    if (action.type.match("^CONNECTION") && action.meta.fetch) {
        // POSTリクエスト
        if (action.payload.method !== null && action.payload.method === "post") {
            let query = {};
            if (action.payload.hasOwnProperty("query")) {
                query = action.payload.query;
            }
            apiAccess.post(`${url.API_DOMAIN}${action.payload.api}`, action.payload.data)
                .then((response) => {
                    if (response.data.is_successful) {
                        next(Object.assign(action, {
                                payload: {
                                    ...response.data
                                },
                                meta: {
                                    fetch: false,
                                    status: Actions.statusEnum.SUCCESS
                                }
                            })
                        )
                    } else {
                        next(Object.assign(action, {
                                payload: {
                                    ...response.data
                                },
                                meta: {
                                    fetch: false,
                                    status: Actions.statusEnum.FAILURE
                                }
                            })
                        )
                        store.dispatch(
                            Actions.data.failure(response.data, true)
                        )
                    }

                })
                .catch((error) => {
                    console.error("Error", error);
                    if (error.response) {
                        // The request was made and the server responded with a status code
                        // that falls out of the range of 2xx
                        if (error.response.status === 412) {
                            next(Object.assign(action, {
                                    payload: {
                                        code: error.response.status ? error.response.status : 500,
                                        ...error.response.data
                                    },
                                    meta: {
                                        fetch: false,
                                        status: Actions.statusEnum.SUCCESS
                                    }
                                })
                            );
                        } else {
                            next(Object.assign(action, {
                                    payload: {
                                        code: error.response.status ? error.response.status : 500,
                                        ...error.response.data
                                    },
                                    meta: {
                                        fetch: false,
                                        status: Actions.statusEnum.FAILURE
                                    }
                                })
                            );
                            store.dispatch(
                                Actions.data.failure(error.response.data, true)
                            )
                        }


                    } else if (error.request) {
                        // The request was made but no response was received
                        // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
                        // http.ClientRequest in node.js
                        console.error(error.request);
                        next(Object.assign(action, {
                                payload: {
                                    ...error.request
                                },
                                meta: {
                                    fetch: false,
                                    status: Actions.statusEnum.ERROR
                                }
                            })
                        );
                        store.dispatch(
                            Actions.data.failure(error.request, true)
                        )
                    } else {
                        // Something happened in setting up the request that triggered an Error
                        console.error('Error', error.message);
                        next(Object.assign(action, {
                            payload: {
                                ...error.message
                            },
                            meta: {
                                fetch: false,
                                status: Actions.statusEnum.ERROR
                            }
                        }))
                        store.dispatch(
                            Actions.data.failure(error, true)
                        )
                    }
                });

        };
        // GETリクエスト
        if (action.payload.method !== null && action.payload.method === 'get') {
            apiAccess.get(`${url.API_DOMAIN}${action.payload.api}`,{params: action.payload.data})
                .then((response) => {
                    if(response.data.is_successful){
                        next(Object.assign(action, {
                                payload: {
                                    ...response.data
                                },
                                meta: {
                                    fetch: false,
                                    status: Actions.statusEnum.SUCCESS
                                }
                            })
                        )
                    }else{
                        next(Object.assign(action, {
                                payload: {
                                    ...response.data
                                },
                                meta: {
                                    fetch: false,
                                    status: Actions.statusEnum.FAILURE
                                }
                            })
                        )
                        if(action.type !== Actions.http.connection.auth.checkLoggedIn().type){
                            store.dispatch(
                                Actions.data.failure(response.data, true)
                            )
                        }
                    }
                })
                .catch((error) => {
                    console.log(action)
                    if (error.response) {
                        // The request was made and the server responded with a status code
                        // that falls out of the range of 2xx
                        if(error.response.status === 412){
                            next(Object.assign(action, {
                                    payload: {
                                        code: error.response.status ? error.response.status : 500,
                                        ...error.response.data
                                    },
                                    meta: {
                                        fetch: false,
                                        status: Actions.statusEnum.SUCCESS
                                    }
                                })
                            );
                        }else{
                            next(Object.assign(action, {
                                    payload: {
                                        code: error.response.status ? error.response.status : 500,
                                        ...error.response.data
                                    },
                                    meta: {
                                        fetch: false,
                                        status: Actions.statusEnum.FAILURE
                                    }
                                })
                            );
                            store.dispatch(
                                Actions.data.failure(error.response.data, true)
                            )
                        }


                    } else if (error.request) {
                        // The request was made but no response was received
                        // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
                        // http.ClientRequest in node.js
                        console.error(error.request);
                        next(Object.assign(action, {
                                payload: {
                                    ...error.request
                                },
                                meta: {
                                    fetch: false,
                                    status: Actions.statusEnum.ERROR
                                }
                            })
                        );
                        store.dispatch(
                            Actions.data.failure(error.request, true)
                        )
                    } else {
                        // Something happened in setting up the request that triggered an Error
                        next(Object.assign(action, {
                            payload: {
                                ...error.message
                            },
                            meta: {
                                fetch: false,
                                status: Actions.statusEnum.ERROR
                            }
                        }))
                        store.dispatch(
                            Actions.data.failure(error, true)
                        )
                    }
                })

        }
        // PUTリクエスト
        if (action.payload.method !== null && action.payload.method === "put") {
            apiAccess.put(`${url.API_DOMAIN}${action.payload.api}` ,action.payload.data)
                .then((response) => {

                    if(response.data.is_successful){
                        next(Object.assign(action, {
                                payload: {
                                    ...response.data
                                },
                                meta: {
                                    fetch: false,
                                    status: Actions.statusEnum.SUCCESS
                                }
                            })
                        )
                    }else{
                        next(Object.assign(action, {
                                payload: {
                                    ...response.data
                                },
                                meta: {
                                    fetch: false,
                                    status: Actions.statusEnum.FAILURE
                                }
                            })
                        )
                        store.dispatch(
                            Actions.data.failure(response.data, true)
                        )
                    }
                })
                .catch((error) => {
                    if (error.response) {
                        // The request was made and the server responded with a status code
                        // that falls out of the range of 2xx
                        if(error.response.status === 412){
                            next(Object.assign(action, {
                                    payload: {
                                        code: error.response.status ? error.response.status : 500,
                                        ...error.response.data
                                    },
                                    meta: {
                                        fetch: false,
                                        status: Actions.statusEnum.SUCCESS
                                    }
                                })
                            );
                        }else{
                            next(Object.assign(action, {
                                    payload: {
                                        code: error.response.status ? error.response.status : 500,
                                        ...error.response.data
                                    },
                                    meta: {
                                        fetch: false,
                                        status: Actions.statusEnum.FAILURE
                                    }
                                })
                            );
                            store.dispatch(
                                Actions.data.failure(error.response.data, true)
                            )
                        }


                    } else if (error.request) {
                        // The request was made but no response was received
                        // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
                        // http.ClientRequest in node.js
                        console.error(error.request);
                        next(Object.assign(action, {
                                payload: {
                                    ...error.request
                                },
                                meta: {
                                    fetch: false,
                                    status: Actions.statusEnum.ERROR
                                }
                            })
                        );
                        store.dispatch(
                            Actions.data.failure(error.request, true)
                        )
                    } else {
                        // Something happened in setting up the request that triggered an Error
                        console.error('Error', error.message);
                        next(Object.assign(action, {
                            payload: {
                                ...error.message
                            },
                            meta: {
                                fetch: false,
                                status: Actions.statusEnum.ERROR
                            }
                        }))
                        store.dispatch(
                            Actions.data.failure(error, true)
                        )
                    }
                });
        }
        // DELETEリクエスト
        if (action.payload.method !== null && action.payload.method === 'delete') {
            apiAccess.delete(`${url.API_DOMAIN}${action.payload.api}`,action.payload.data)
                .then((response) => {

                    if(response.data.is_successful){
                        next(Object.assign(action, {
                                payload: {
                                    ...response.data
                                },
                                meta: {
                                    fetch: false,
                                    status: Actions.statusEnum.SUCCESS
                                }
                            })
                        )
                    }else{
                        next(Object.assign(action, {
                                payload: {
                                    ...response.data
                                },
                                meta: {
                                    fetch: false,
                                    status: Actions.statusEnum.FAILURE
                                }
                            })
                        )
                        store.dispatch(
                            Actions.data.failure(response.data, true)
                        )
                    }
                })
                .catch((error) => {
                    if (error.response) {
                        // The request was made and the server responded with a status code
                        // that falls out of the range of 2xx
                        if(error.response.status === 412){
                            next(Object.assign(action, {
                                    payload: {
                                        code: error.response.status ? error.response.status : 500,
                                        ...error.response.data
                                    },
                                    meta: {
                                        fetch: false,
                                        status: Actions.statusEnum.SUCCESS
                                    }
                                })
                            );
                        }else{
                            next(Object.assign(action, {
                                    payload: {
                                        code: error.response.status ? error.response.status : 500,
                                        ...error.response.data
                                    },
                                    meta: {
                                        fetch: false,
                                        status: Actions.statusEnum.FAILURE
                                    }
                                })
                            );
                            store.dispatch(
                                Actions.data.failure(error.response.data, true)
                            )
                        }


                    } else if (error.request) {
                        // The request was made but no response was received
                        // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
                        // http.ClientRequest in node.js
                        console.error(error.request);
                        next(Object.assign(action, {
                                payload: {
                                    ...error.request
                                },
                                meta: {
                                    fetch: false,
                                    status: Actions.statusEnum.ERROR
                                }
                            })
                        );
                        store.dispatch(
                            Actions.data.failure(error.request, true)
                        )
                    } else {
                        // Something happened in setting up the request that triggered an Error
                        next(Object.assign(action, {
                            payload: {
                                ...error.message
                            },
                            meta: {
                                fetch: false,
                                status: Actions.statusEnum.ERROR
                            }
                        }))
                        store.dispatch(
                            Actions.data.failure(error, true)
                        )
                    }
                })
        }
    }
}


//通信後middleware
export const middleware = store => next => action => {
    next(action);
    if(!action.error) {
        switch (action.type) {
            case "MENU/SEARCH/RECEIVE":
                const menuObject = {};
                const menuArray = action.payload.result.items.map((value,index) => {
                    menuObject[value.uuid] = value
                    return value;
                });
                console.log(menuArray)
                store.dispatch(
                    Actions.setParamMenu({
                        menu: menuArray,
                        object: menuObject
                    })
                );
                break;
            case "LOGIN/RECEIVE":
                store.dispatch(
                    Actions.session({
                        isAuthenticated: action.payload.result.login === "portal",
                        authenticatedFrom: action.payload.result.login,
                        user: action.payload.user,
                        result: action.payload.result,
                        gmt: action.payload.gmt
                    })
                );
                break;

            //アンケートコピー処理
            case "QUESTIONNAIRE/COPY/RECEIVE":
                store.dispatch(
                    Actions.AdminConnection.questionnaire.copyReview.request(action.payload.result.uuid)
                );
                break;
            case "CHECK/RECEIVE":
                store.dispatch(
                    Actions.session({
                        isAuthenticated: action.payload.result.login === "portal",
                        authenticatedFrom: action.payload.result.login,
                        user: action.payload.user,
                        result: action.payload.result,
                        gmt: action.payload.gmt
                    })
                );
                break;
            case "CHECK/FIRST/RECEIVE":
                store.dispatch(
                    Actions.session({
                        isAuthenticated: action.payload.result.login === "portal",
                        authenticatedFrom: action.payload.result.login,
                        user: action.payload.user,
                        result: action.payload.result,
                        gmt: action.payload.gmt
                    })
                );
                break;

            case "LOGOUT/RECEIVE":
                document.location.href="/login";
                break;

            case "FILESHARE/DOWNLOAD/RECEIVE":
                window.open(url.API_ADMIN_FILE_SHARE_DOWNLOAD)
                break;

            case "NOTIFICATION/GUEST/RECEIVE":
                break;

            case "NOTIFICATION/VIEW/RESET":
                log.debug("RESET");
                next({
                    type: action.type,
                    meta: {
                        fetch: false
                    },
                    payload: {
                        result: undefined
                    }
                })
                break;

            default:
                break;
        }
        if (action.type.match("RECEIVE$")) {
            store.dispatch(
                Actions.timer({
                    gmt: moment().unix()
                })
            )
        }

    }else{
        //限定処理の追加
        switch (action.type) {
            case "LOGOUT/RECEIVE":
                if(action.payload && action.payload.code === 403){
                    document.location.href="/login";
                }
                break;
            case "LOGIN/RECEIVE":
                if(action.payload && action.payload.code === 503){
                    //ログインで503がかえってきたときのみそのまま通して
                    //該当箇所で処理を行う
                    next(action);
                }else{
                    next({
                        type:"ERRORS",
                        from: action.type,
                        error: true,
                        payload: action.payload
                    });
                }
                break;
            //初めてのチェックの場合は存在しないのが正常値のためerrorを封殺
            case "CHECK/FIRST/RECEIVE":
                break;
            //LOGINセッションチェックのレシーブ処理
            case "CHECK/RECEIVE":
                const reGeneratePayload = action.payload;
                //403 unauthorizedに変更
                reGeneratePayload.code = 403;
                //SessionCheckの値入れ
                reGeneratePayload.isSessionCheck = true;
                next({
                    type:"ERRORS",
                    from: action.type,
                    error: true,
                    payload: reGeneratePayload
                });
                break;
            //権限・対象指定の設定
            case "PERMISSION/RECEIVE":
            case "TARGET/RECEIVE":
                if(action.payload.code !== 404) {
                    next({
                        type: "ERRORS",
                        from: action.type,
                        error: true,
                        payload: action.payload
                    });
                }
                break;

            //レイアウトコードが404の場合
            case "LAYOUT/LOAD/RECEIVE":
                //404（レイアウト未定義）の場合は何も言わない
                if(action.payload.code !== 404) {
                    next({
                        type: "ERRORS",
                        from: action.type,
                        error: true,
                        payload: action.payload
                    });
                }
                break;

            case "FILESHARE/DELETE/RECEIVE":
            case "FILESHARE/DOWNLOAD/RECEIVE":
            case "FILESHARE/FILE/CREATE/RECEIVE":
            case "FILESHARE/FILE/UPDATE/RECEIVE":
                //403（FILESHARE）の場合は何も言わない
                if(action.payload.code === 403) {
                    const reGeneratePayload = action.payload;
                    //403 unauthorizedに変更
                    reGeneratePayload.code = 401;
                    reGeneratePayload.result = {message:"ファイルに対する権限がありません"};
                    next({
                        type: "ERRORS",
                        from: action.type,
                        error: true,
                        payload: reGeneratePayload
                    });
                }else{
                    next({
                        type: "ERRORS",
                        from: action.type,
                        error: true,
                        payload: action.payload
                    });
                }
                break;
            default:
                //403が出た時にloginに飛ばすように調整
                if(action.payload && action.payload.code === 403){
                    document.location.href="/login";
                }else{
                    next({
                        type:"ERRORS",
                        from: action.type,
                        error: true,
                        payload: action.payload
                    });

                }
                break;
        }
    }
};
