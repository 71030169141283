import React from 'react';
import {connect, store} from 'react-redux';
import {instanceOf} from 'prop-types';

import log from 'components/utils/Logger';
import * as Actions from '../../redux/actions';
import * as GlobalConfig from '../../constants/GlobalConfig'
import moment from 'moment';
import 'moment/locale/ja';

import {
    CopyOutlined,
    DeleteOutlined,
    EditOutlined,
    PlusCircleOutlined,
    SelectOutlined,
} from '@ant-design/icons';

import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';

import {
    Table,
    Tag,
    Radio,
    Select,
    Row,
    Input,
    Col,
    Button,
    Tooltip,
    DatePicker,
    Collapse,
    Popconfirm,
} from 'antd';

import SSOFormModal from './SSOFormModal';
import {CopyToClipboard} from 'react-copy-to-clipboard';

moment.locale('ja');


const FormItem = Form.Item;
const Panel = Collapse.Panel;
const Option = Select.Option;
const RadioButton = Radio.Button;
const RadioGroup = Radio.Group;
const RangePicker = DatePicker.RangePicker;

const mapStateToProps = (state, props) => {
    return {
        screen: state.screen,
        sso: state.ssoSearch,
        ssoUpdate: state.ssoUpdate,
        ssoCreate: state.ssoCreate,
        ssoDelete: state.ssoDelete
    }
}

const mapDispatchToProps = dispatch => {
    return {
        searchSSO: (data, currentPageNum) => {
            dispatch(Actions.AdminConnection.sso.search.request(data, currentPageNum));
        },
        createSSO: (data) => {
            dispatch(Actions.AdminConnection.sso.create.request(data));
        },
        updateSSO: (uuid, data) => {
            dispatch(Actions.AdminConnection.sso.update.request(uuid, data));
        },
        deleteSSO: (uuid) => {
            dispatch(Actions.AdminConnection.sso.delete.request(uuid));
        }
    }
}

class SSOList extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
          pagination: {
              showTotal: (total, range) => {

                  return (`全${total}件中 ${range[0]}-${range[1]} 件`)
              },
              showSizeChanger: true,
              pageSizeOptions: GlobalConfig.PageSizeOptions,
              pageSize: 20,
              onShowSizeChange: this.onChangePageSize,
              item_count: 0,
          },
            resultArray: [],
            editModalIsOpen: false,
            addModalIsOpen: false
        }
    }

    componentDidMount(){
        this.search();
    }

    componentWillReceiveProps(nextProps) {
        const pagination = {...this.state.pagination};
        const {paramCategory, sso, ssoCreate, ssoUpdate, ssoDelete} = {...this.props};

        if (nextProps.sso !== sso) {
            if (!nextProps.sso.meta.fetch) {
                if (!nextProps.sso.error) {
                    if (nextProps.sso.payload !== null) {
                        pagination.total = nextProps.sso.payload.result.item_count;
                        this.setState({
                            searchLoading: nextProps.sso.meta.fetch,
                            resultArray: nextProps.sso.payload.result.items,
                            //通信後
                            pagination
                        })
                    }
                }
            } else {
                this.setState({
                    searchLoading: nextProps.sso.meta.fetch
                })
            }
        }

        if (nextProps.ssoCreate !== ssoCreate) {
            if (!nextProps.ssoCreate.meta.fetch) {
                if (!nextProps.ssoCreate.error) {
                    if (nextProps.ssoCreate.payload !== null) {
                        this.setState({
                            addModalIsOpen: false
                        })
                        this.search()
                    }
                }
            }
        }

        if (nextProps.ssoUpdate !== ssoUpdate) {
            if (!nextProps.ssoUpdate.meta.fetch) {
                if (!nextProps.ssoUpdate.error) {
                    if (nextProps.ssoUpdate.payload !== null) {
                        this.setState({
                            editModalIsOpen: false
                        })
                        this.search()
                    }
                }
            }
        }

        if (nextProps.ssoDelete !== ssoDelete) {
            if (!nextProps.ssoDelete.meta.fetch) {
                if (!nextProps.ssoDelete.error) {
                    if (nextProps.ssoDelete.payload !== null) {
                        this.search()
                    }
                }
            }
        }
    }

    search(value) {
        let fieldsValue = this.props.form.getFieldsValue();
        let pagination;
        if (typeof value === "undefined") {
            pagination = {...this.state.pagination};
        } else {
            pagination = value;
        }

        fieldsValue.page_size = pagination.pageSize;
        this.props.searchSSO(fieldsValue, pagination.current);

    }

    onChangePageSize = (current, pageSize) => {
        const pager = { ...this.state.pagination };
        //pageSizeの更新
        pager.pageSize = pageSize;
        this.setState({
            pagination: pager,
        });
    };

    onSubmit = (e) => {
        e.preventDefault();
        this.search();
        return false;
    };


    handleResetSearchForm = () => {
        this.props.form.resetFields();
        this.search();
    };


    handleOpenAddModal() {
        this.setState({
            addModalIsOpen: true,
        });
    }
    handleOpenEditModal(data) {

        this.setState({
            editModalIsOpen: true,
            data:data
        });
    }

    handleTableChange = (pagination, filters, sorter) => {
        this.search(pagination);
    };


    handleDeleteCategory(record) {
        this.props.deleteSSO(record.uuid);
    }

    render() {
        const {getFieldDecorator} = this.props.form;

        const formItemLayout = {
            labelCol: {
                xs: {span: 24},
                sm: {span: 4},
            },
            wrapperCol: {
                xs: {span: 24},
                sm: {span: 20},
            },
        };

        let availableSearchForm = (
                <Form className="search-form" onSubmit={this.onSubmit}>
                    <FormItem
                        {...formItemLayout}
                        label="サイト名称"
                        layout="inline">
                        {getFieldDecorator('name')(
                            <Input placeholder="サイト名称を入力してください"/>
                        )}
                    </FormItem>

                    <FormItem
                        {...formItemLayout}
                        label="連携先URI"
                        layout="inline">
                        {getFieldDecorator('url')(
                            <Input placeholder="連携先URIを入力してください"/>
                        )}
                    </FormItem>

                    <FormItem
                        {...formItemLayout}
                        label="摘要"
                        layout="inline">
                        {getFieldDecorator('memo')(
                            <Input placeholder="摘要を入力してください"/>
                        )}
                    </FormItem>


                    <FormItem
                        {...formItemLayout}
                        label="認証方式"
                        layout="inline">
                        {getFieldDecorator('type')(
                            <Select placeholder="認証方式を選択してください">
                                <Option key="from" value="1">
                                    <div className="select-title">FORM認証</div>
                                    <div className="select-description">POSTまたはGETメソッドを使用してFORMデータを送信します</div>
                                </Option>
                            </Select>
                        )}
                    </FormItem>

                    <div className="submit-container">

                        <Button className="search-form-button" onClick={this.handleResetSearchForm}>
                            リセット
                        </Button>

                        <Button type="primary" htmlType="submit" className="search-form-button">
                            検索
                        </Button>

                    </div>
                </Form>
            )

        let {sortedInfo, filteredInfo} = this.state;
        sortedInfo = sortedInfo || {};
        filteredInfo = filteredInfo || {};

        const columns = [{
            title: '連携方式',
            dataIndex: 'type',
            key: 'type',
            width: "85px",
            style: "tag",
            render: (text, record) => {
                switch(text) {
                    case 1: {
                        return <Tag className="tag normal">FORM認証</Tag>;
                    }
                    default : {
                        return <Tag className="tag gray2">不明な方式</Tag>;
                    }
                }

            },
        },{
            title: 'サイト名称',
            dataIndex: 'name',
            key: 'name',
            render: (text,record) => {
                return (
                    <Tooltip mouseEnterDelay={1} title={"UUID:" + record.uuid}>
                        {text}
                    </Tooltip>
                )
            }
        }, {
            title: "連携先URI",
            dataIndex: "url",
            key:"url"
        },{
            title: "摘要",
            dataIndex: "memo",
            key:"memo"
        },{
            title: "作成時間/更新時間",
            dataIndex: "updated_at",
            key: "update_at",
            width:"190px",
            render: (text, record) => {
                const update_at = text ? moment.unix(text).format("YYYY/MM/DD HH:mm") : "更新なし";
                const created_at = moment.unix(record.created_at).format("YYYY/MM/DD HH:mm");
                return (
                    <div>
                        {created_at}<br/>
                        {update_at}
                    </div>
                )
            }
        },{
            title: "",
            width: 140,
            key: 'action',
            render: (text, record) => {

                return (
                    <Row type="flex" justify="space-around" align="middle">
                        <Col>
                            <Tooltip placement="topLeft" title="ジャンプテスト">
                                <Button type="default" shape="circle" icon={<SelectOutlined />}
                                        onClick={() => {
                                            window.open('/api/sso-link/jump/' + record.uuid,'_blank');
                                        }}/>
                            </Tooltip>
                        </Col>
                        <Col>
                            <Tooltip placement="topLeft" title="URLコピー">
                                <CopyToClipboard text={`https://${window.location.host}/api/sso-link/jump/${record.uuid}`}>
                                    <Button
                                        type="default" shape="circle" icon={<CopyOutlined />}
                                        onClick={() =>{}
                                        }
                                    />
                                </CopyToClipboard>
                            </Tooltip>
                        </Col>
                        <Col>
                            <Tooltip placement="topLeft" title="編集">
                                <Button type="default" shape="circle" icon={<EditOutlined />}
                                        onClick={() => this.handleOpenEditModal(record)}/>
                            </Tooltip>
                        </Col>
                        <Col>
                            <Popconfirm
                                title={<span>SSO連携先を削除すると設定されているの全ての関連リンクが削除されます。<br />
                                    <strong>削除すると復元することはできません。</strong></span>}
                                onConfirm={() => this.handleDeleteCategory(record)}
                                onCancel=""
                                placement="left"
                                okText="削除" cancelText="キャンセル">
                                <Tooltip placement="topLeft" title="削除">
                                    <Button type="default" shape="circle" icon={<DeleteOutlined />} />
                                </Tooltip>
                            </Popconfirm>
                        </Col>
                    </Row>
                );
            },
        }];
        return (
            <div id="sso-list-container" alt="SSO連携管理"  className="tab-container">

                <div className="card general-search">
                    {availableSearchForm}
                </div>

                <div className="table-header-wrapper">
                    <div className="button-container">
                        <Button type="primary" icon={<PlusCircleOutlined />}
                                onClick={() => this.handleOpenAddModal()}>
                            新規作成
                        </Button>
                    </div>
                </div>

                <Table
                    className="general-table"
                    locale={{
                        filterTitle: 'フィルタ',
                        filterConfirm: '確定',
                        filterReset: 'リセット',
                        emptyText: '該当するものはありません',
                    }}
                    bordered={true}
                    size="middle"
                    loading={this.state.loading}
                    onChange={this.handleTableChange}
                    columns={columns}
                    dataSource={this.state.resultArray}
                    pagination={this.state.pagination}
                />

                <SSOFormModal
                    isEditMode={false}
                    visible={this.state.addModalIsOpen}
                    handleCancel={() => {
                        this.setState({
                            addModalIsOpen: false
                        })
                    }}
                    handleOk={(fieldValue, data) => {
                        this.props.createSSO(fieldValue);
                        /*
                        this.setState({
                            addModalIsOpen: false
                        })*/
                    }}
                />

                <SSOFormModal
                    isEditMode={true}
                    visible={this.state.editModalIsOpen}
                    handleCancel={() => {
                        this.setState({
                            editModalIsOpen: false
                        })
                    }}
                    handleOk={(fieldValue, data) => {
                        log.debug(fieldValue);
                        this.props.updateSSO(data.uuid, fieldValue);
                        /*
                        this.setState({
                            editModalIsOpen: false
                        })
                        */
                    }}
                    data={this.state.data}
                />

            </div>
        );
    }

}


export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Form.create()(SSOList))
