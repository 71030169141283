import React from 'react';
import {connect, store} from 'react-redux';
import PropTypes from 'prop-types';
import * as Actions from '../../redux/actions';
import { LoadingOutlined, UserOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Modal, Input, DatePicker, Table } from 'antd';
import 'moment/locale/ja';

const mapStateToProps = (state, props) => {
    return {
        userData: state.userView,
    }
};

const mapDispatchToProps = dispatch => {
    return {
        userView: (uuid) => {
            dispatch(Actions.AdminConnection.user.view.request(uuid));
        }
    }
};

class UserViewModal extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            dataLoading: true,

            modalTitle: ()=> {
                if(this.state.dataLoading === true) {
                    return <span><LoadingOutlined /> ユーザ情報を読み込んでいます</span>;
                }else{
                    return <span><UserOutlined /> {this.state.userDataArray.name} さんのユーザ情報</span>;
                }
            },
            visible: false,
            userDataArray: [],
            uuid: undefined,

        }
    }

    componentWillReceiveProps(nextProps) {

        this.setState({
            visible: nextProps.visible,
            uuid: nextProps.uuid,
        });

        if(nextProps.visible === false && this.props.visible === true) {
            // Close

        }

        if(nextProps.visible === true && this.props.visible === false) {
            // Open
            if(this.state.uuid !== undefined) {
                this.props.userView(nextProps.uuid);
            }else{
                // Error
                this.setState ({
                    visible: false
                });
            }


        }

        if (nextProps.userData !== this.props.userData) {
            if (!nextProps.userData.meta.fetch) {
                if (!nextProps.userData.error) {
                    if (nextProps.userData.payload !== null) {
                        this.setState({
                            dataLoading: nextProps.userData.meta.fetch,
                            userDataArray: nextProps.userData.payload.result,
                        })
                    }
                }
            } else {
                this.setState({
                    loading: nextProps.userData.meta.fetch
                })
            }
        }

    }

    handleOk = () => {
        this.props.handleOk();
    };

    handleCancel = () => {
        this.props.handleCancel();
    };

    render() {

        const {visible,} = this.state;

        const columns = [{
            title: '所属グループ名',
            dataIndex: 'name',
            key: 'name',
        },{
            title: '所属グループUUID',
            dataIndex: 'uuid',
            key: 'uuid',
        },];

        let userData;

        if(this.state.userDataArray) {

            userData =
                <div>
                    <h3>UUID</h3>
                    <p>{this.state.userDataArray.uuid}<br /><br /></p>
                    <h3>利用者ID</h3>
                    <p>{this.state.userDataArray.user_id}<br /><br /></p>
                    <h3>学籍番号10桁コード</h3>
                    <p>{this.state.userDataArray.personal_id}<br /><br /></p>

                    <h3>氏名</h3>
                    <p>{this.state.userDataArray.name} さん<br /><br /></p>
                    <h3>氏名（カナ）</h3>
                    <p>{this.state.userDataArray.name_kana} さん<br /><br /></p>
                    <h3>氏名（アルファベット）</h3>
                    <p>{this.state.userDataArray.name_alphabet} さん<br /><br /></p>
                    <h3>ログインの有効性</h3>
                    <p>{this.state.userDataArray.is_login_enabled === true ? "はい" : "いいえ"}<br /><br /></p>

                    <h3>保有権限</h3>
                    <p>{this.state.userDataArray.permission ? this.state.userDataArray.permission : "不明"}<br /><br /></p>
                    <h3>アンケート強制回答が未回答</h3>
                    <p>{this.state.userDataArray.has_force_questionnaire === true ? "はい" : "いいえ"}<br /><br /></p>


                    <Table columns={columns} dataSource={this.state.userDataArray.groups} size="small" />

                </div>
        }

        return (
            <Modal
                className="common-modal select-target-form"
                title={this.state.modalTitle()}
                visible={visible}
                maskClosable={false}
                onOk={() => this.handleOk()}
                onCancel={this.handleCancel}>

                <div className='wrapper'>
                    {userData}
                </div>

            </Modal>
        );
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(UserViewModal)


UserViewModal.propTypes = {
    visible: PropTypes.bool.isRequired,
    handleOk: PropTypes.func.isRequired,
    handleCancel: PropTypes.func.isRequired,
    uuid: PropTypes.string
};