import React, {createContext, useEffect, useRef, useState} from 'react';
import PropTypes from 'prop-types';
import {useDispatch, useSelector} from "react-redux";
import {statusEnum} from "../../redux/actions";
import * as Actions from '../../redux/actions'

MyTargetEffectComponent.propTypes = {};

export const ListContext = createContext([{}, () => {
}])
export const SearchContext = createContext([{}, () => {
}])

function MyTargetEffectComponent(props) {
    const dispatch = useDispatch();
    const [list, setList] = useState({
        targetList: [],
        targetListItemCount: 0,
        targetListPageTotal: 0,
        loading: false
    });
    const [searchRule, setSearchRule] = useState({});
    const myTargetSearchConnection = useSelector(state => state.myTargetSearchConnection);
    const myTargetEditConnection = useSelector( state => state.myTargetEditConnection);

    const isFirstRender = useRef(false)
    useEffect(()=>{
        isFirstRender.current = true;
    },[])


    useEffect(()=>{
        if (!isFirstRender.current && myTargetEditConnection.meta.status === statusEnum.SUCCESS) {
            dispatch(Actions.http.connection.target.myTarget.search(searchRule))
        }
    },[myTargetEditConnection]);


    useEffect(()=>{
        if (!isFirstRender.current && myTargetSearchConnection.meta.status === statusEnum.SUCCESS) {
            setList(preview => ({
                ...preview,
                targetList: myTargetSearchConnection.payload.result.items,
                targetListItemCount: myTargetSearchConnection.payload.result.item_count,
                targetListPageTotal: myTargetSearchConnection.payload.result.page_total,
                loading: myTargetSearchConnection.meta.fetch
            }))
        }

        if(isFirstRender.current){
            isFirstRender.current = false;
        }
    },[myTargetSearchConnection])


    useEffect(()=>{

    },[searchRule])


    return (
        <ListContext.Provider value={[list, setList]}>
            <SearchContext.Provider value={[searchRule, setSearchRule]}>
                {props.children}
            </SearchContext.Provider>
        </ListContext.Provider>
    );
}

export default MyTargetEffectComponent;