import React from 'react'
import {connect, store} from 'react-redux'
import PropTypes from 'prop-types';
import * as GlobalConfig from '../../constants/GlobalConfig';
import * as Actions from '../../redux/actions';
import { InboxOutlined, SearchOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import {
    Table,
    Badge,
    Tabs,
    Modal,
    Radio,
    Select,
    Input,
    Col,
    Button,
    Checkbox,
    DatePicker,
    Collapse,
    Row,
    Popconfirm,
    Tooltip,
    Upload,
} from 'antd';

import log from 'components/utils/Logger.js'

import moment from 'moment';
import 'moment/locale/ja';
import jaJP from 'antd/lib/date-picker/locale/ja_JP';

import UserViewModal from "../users/UserViewModal";
import {getDefaultLayout} from "../../constants/GlobalConfig";

moment.locale('ja');

const FormItem = Form.Item;
const Option = Select.Option;
const RadioButton = Radio.Button;
const RadioGroup = Radio.Group;
const RangePicker = DatePicker.RangePicker;
const { TextArea } = Input;

const mapStateToProps = (state, props) => {
    return {
        user: state.user,
        screen: state.screen,
        userCsv: state.userCsv,
        csv: state.csv,
        text: state.text
    }
};

const mapDispatchToProps = dispatch => {
    return {
        searchUser: (data, currentPageNum) => {
            dispatch(Actions.AdminConnection.user.search.request({...data,is_login_enabled: 1}, currentPageNum));
        },
        uploadCSV: (fileName, contentType, data) => {
            dispatch(Actions.AdminConnection.csv.request(fileName, contentType, data));
        },
        searchCsv: (data,currentPageNum) =>{
            dispatch(Actions.AdminConnection.user.csv.request(data, currentPageNum));
        },
        uploadText: (data) => {
            dispatch(Actions.AdminConnection.text.request(data));
        }
    }
};

class AddUserModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            visible: props.visible,
            search_type: "simple",
            pagination: {
                showTotal: (total, range) => {
                    return (`全${total}件中 ${range[0]}-${range[1]} 件`)
                },
                showSizeChanger: true,
                pageSizeOptions: GlobalConfig.PageSizeOptions,
                pageSize: 20,
                onChange: this.onChangePage,
                item_count: 0,
            },
            fileList: [],
            userList: [],
            text_uuid: "",
            selectedRowKeys:[],
            selectedRows: [],
            viewUserModalIsOpen: false,
            viewModalUUID: null,
        }
    }

    componentWillReceiveProps(nextProps) {
        const pagination = {...this.state.pagination};
        const {user} = {...this.props};
        if (nextProps.user !== user) {
            if (!nextProps.user.meta.fetch) {
                if (!nextProps.user.error) {
                    if (nextProps.user.payload !== null) {
                        pagination.total = nextProps.user.payload.result.item_count;
                        log.debug(nextProps.user.payload);
                        this.setState({
                            loading: nextProps.user.meta.fetch,
                            userList: nextProps.user.payload.result.items,
                            pagination
                        })
                    }
                }else{
                    this.setState({
                        loading: nextProps.user.meta.fetch
                    })
                }
            } else {
                this.setState({
                    loading: nextProps.user.meta.fetch
                })
            }
        }
        if (nextProps.userCsv !== this.props.userCsv) {
            log.debug(nextProps.userCsv);
            if (!nextProps.userCsv.meta.fetch) {
                if (!nextProps.userCsv.error) {
                    if (nextProps.userCsv.payload !== null) {
                        log.debug(nextProps.userCsv);
                        pagination.total = nextProps.userCsv.payload.result.item_count;
                        log.debug(nextProps.userCsv.payload);
                        this.setState({
                            loading: nextProps.userCsv.meta.fetch,
                            userList: nextProps.userCsv.payload.result.items,
                            pagination
                        })
                    }
                }else{
                    this.setState({
                        loading: nextProps.userCsv.meta.fetch
                    })
                }
            } else {
                this.setState({
                    loading: nextProps.userCsv.meta.fetch
                })
            }
        }
        const {fileList} = this.state;
        //imagesアップデート差分
        if (nextProps.csv !== this.props.csv) {
            if (!nextProps.csv.meta.fetch) {
                if (!nextProps.csv.error) {
                    if (nextProps.csv.payload !== null && nextProps.csv.payload.hasOwnProperty("result")) {
                        //画像のアップロード
                        const map = fileList.some((value) => {
                            return value === nextProps.csv.payload.result;
                        })
                        if (!map) {
                            let constObject = nextProps.csv.payload.result;
                            constObject.name = constObject.file_name;
                            constObject.uid = Math.floor(Math.random() * 10000);
                            constObject.response = nextProps.csv.payload;
                            constObject.status = 'done';
                            constObject.url = `${process.env.REACT_APP_FILE_DOMAIN}/file/view/${constObject.uuid}`;
                            constObject.thumbUrl = process.env.PUBLIC_URL + '/static/media/attach.svg';
                            let constArray = fileList.concat();
                            constArray[constArray.length] = constObject;
                            this.setState({
                                fileList: constArray
                            })
                        }
                    }
                }else{
                    this.setState({
                        loading: nextProps.csv.meta.fetch
                    })
                }
            } else {
                this.setState({
                    loading: nextProps.csv.meta.fetch
                })
            }
        }

        //imagesアップデート差分
        if (nextProps.text !== this.props.text) {
            if (!nextProps.text.meta.fetch) {
                if (!nextProps.text.error) {
                    if (nextProps.text.payload !== null && nextProps.text.payload.hasOwnProperty("result")) {
                        const uuid = nextProps.text.payload.result.uuid;
                        log.debug(uuid);
                        this.setState({
                            text_uuid: uuid
                        })
                        const pagination = {...this.state.pagination};
                        this.props.searchCsv({uuid: uuid, page_size:pagination.pageSize}, pagination.current)
                    }
                }else{
                    this.setState({
                        loading: nextProps.text.meta.fetch
                    })
                }
            } else {
                this.setState({
                    loading: nextProps.text.meta.fetch
                })
            }
        }


        this.setState({
            visible: nextProps.visible
        })

        if(!nextProps.visible){
            this.reset();
        }

    }



    onChangePage = (current,pageSize) =>{
        const pagination = { ...this.state.pagination };
        //pageSizeの更新
        pagination.pageSize = pageSize;
        pagination.current = current;
        this.setState({
            pagination
        },()=>{
            this.search(pagination,true);
        });

    }

    componentWillMount() {
    }

    reset = () => {
        this.props.form.resetFields();
        this.setState({
            pagination: {
                showTotal: (total, range) => {
                    return (`全${total}件中 ${range[0]}-${range[1]} 件`)
                },
                showSizeChanger: true,
                pageSizeOptions: GlobalConfig.PageSizeOptions,
                pageSize: 20,
                onChange: this.onChangePage,
                item_count: 0,
                total: 0
            },
            fileList: [],
            userList: [],
            text_uuid: "",
            selectedRowKeys:[],
            selectedRows: []
        })
    }

    onChangeRadio = (e) => {
        this.reset();
        this.setState({
            search_type: e.target.value
        })
    };

    handleOk = () => {
        this.props.handleOk(this.state.selectedRowKeys,this.state.selectedRows);

        this.setState({
            selectedRowKeys:[],
            selectedRows: [],
            userList: []
        })
    };

    handleOkAddSearchedAll = () => {
        let fieldsValue = this.props.form.getFieldsValue();

        if(this.state.search_type === "simple"){
            //シンプル検索の場合
            // 有効期限処理
            const expire_range = fieldsValue.expire_range;
            if (typeof expire_range !== 'undefined' && expire_range.length !== 0) {
                fieldsValue.start_at = expire_range[0].unix();
                fieldsValue.end_at = expire_range[1].unix();
                delete fieldsValue.expire_range;
            }

            // 登録日時
            const created_range = fieldsValue.created_range;
            if (typeof created_range !== 'undefined' && created_range.length !== 0) {
                fieldsValue.created_start = created_range[0].unix();
                fieldsValue.created_end = created_range[1].unix();
                delete fieldsValue.created_range;
            }

            // 更新日時
            const updated_range = fieldsValue.updated_range;
            if (typeof updated_range !== 'undefined' && updated_range.length !== 0) {
                fieldsValue.updated_start = updated_range[0].unix();
                fieldsValue.updated_end = updated_range[1].unix();
                delete fieldsValue.updated_range;
            }

            this.props.addUserAll(fieldsValue);

        }else if(this.state.search_type === "csv"){
            //CSV検索の場合
            let fieldsValue = {};
            if(this.state.fileList.length===0){
                return;
            }else{
                fieldsValue.uuid = this.state.fileList[0].uuid;

            }
            this.props.addUserCSV(fieldsValue.uuid);
        }else{
            //TEXT検索の場合
            let fieldsValue = {};
            if(this.state.text_uuid === ""){
                return;
            }else{
                fieldsValue.uuid = this.state.text_uuid;
            }
            this.props.addUserCSV(fieldsValue.uuid);
        }
    };



    handleCancel = () => {
        this.props.handleCancel();
    };

    onSubmit = (e) => {
        e.preventDefault();
        this.setState({
            pagination : {
                ...this.state.pagination,
                current : 1
            }
        },()=>{
            this.search();
        })
        return false;
    };

    search(value, only_search) {
        let fieldsValue = this.props.form.getFieldsValue();
        let pagination;
        if (typeof value === "undefined") {
            pagination = {...this.state.pagination};
        } else {
            pagination = value;
        }

        if(this.state.search_type === "simple" ){

            fieldsValue.page_size = pagination.pageSize;
            // 有効期限処理
            const expire_range = fieldsValue.expire_range;
            if (typeof expire_range !== 'undefined' && expire_range.length !== 0) {
                fieldsValue.start_at = expire_range[0].unix();
                fieldsValue.end_at = expire_range[1].unix();
                delete fieldsValue.expire_range;
            }

            // 登録日時
            const created_range = fieldsValue.created_range;
            if (typeof created_range !== 'undefined' && created_range.length !== 0) {
                fieldsValue.created_start = created_range[0].unix();
                fieldsValue.created_end = created_range[1].unix();
                delete fieldsValue.created_range;
            }

            // 更新日時
            const updated_range = fieldsValue.updated_range;
            if (typeof updated_range !== 'undefined' && updated_range.length !== 0) {
                fieldsValue.updated_start = updated_range[0].unix();
                fieldsValue.updated_end = updated_range[1].unix();
                delete fieldsValue.updated_range;
            }
            this.props.searchUser(fieldsValue, pagination.current);

        }else if(this.state.search_type === "csv"){
            fieldsValue.page_size = pagination.pageSize;

            if(this.state.fileList.length===0){

                return;
            }else{
                fieldsValue.uuid = this.state.fileList[0].uuid;
            }

            log.debug(fieldsValue);
            log.debug(pagination.current);
            this.props.searchCsv(fieldsValue, pagination.current)
        }else{
            if(this.state.text_uuid && only_search){

                fieldsValue.page_size = pagination.pageSize;
                fieldsValue.uuid = this.state.text_uuid;
                delete fieldsValue.user_text;
                this.props.searchCsv(fieldsValue, pagination.current)
                return;
            }else{

                fieldsValue.page_size = pagination.pageSize;
                if(fieldsValue.user_text){
                    //CSVの受け側の問題から一行目は見出し行のため改行を頭に挿入
                    //CSVのカンマで何列目を検索するかが決まるため、カンマを改行の後に挿入して利用者ＩＤではない検索として代用
                    const returnCode = "\r\n," + fieldsValue.user_text.replace(/(\r?\n)/g, '$&,');
                    this.props.uploadText(returnCode);
                }
            }

        }
    }


    handleResetSearchForm = () => {
        this.props.form.resetFields();
        this.search();
    };


    onSelectChange = (selectedRowKeys,selectedRows) => {
        this.setState({selectedRowKeys,selectedRows});
    };



    handleUpload = (file) => {
        const {fileList} = this.state;
        const fileReader = new FileReader();
        fileReader.onload = (event) => {
            this.props.uploadCSV(file.name, file.type, event.target.result);
        }
        this.setState({
            uploading: true,
        });
        fileReader.readAsArrayBuffer(file);
    };

    handleOpenUserModal = (record) => {
        this.setState({
            viewUserModalIsOpen : true,
            viewModalUUID: record.uuid,
        });
    };

    render() {
        const {getFieldDecorator} = this.props.form;


        const formItemLayout = getDefaultLayout(true);

        const columns = [
            {
                title: <span>学籍番号<br/>10桁コード</span>,
                dataIndex: 'personal_id',
                key: 'personal_id',
                width: "110px"
            }, {
                title: <span>学籍番号／<br/>教職員表示番号</span>,
                dataIndex: 'option_1',
                key: 'option_1',
                width: "110px"
            }, {
                title: '氏名',
                dataIndex: 'name',
                key: 'name',
                width: "200px",
            }, {
                title: '氏名(カナ/英)',
                dataIndex: 'name_kana',
                key: 'name_kana',
                width: "200px",
                render: (text, record) => {
                    return (
                        <div>
                            {record.name_kana}<br/>
                            {record.name_alphabet}
                        </div>
                    )
                }
            }, {
                title: '摘要',
                dataIndex: 'memo',
                key: 'memo',
                width: "200px",
            }, {
                title: '状態',
                dataIndex: 'is_login_enabled',
                key: 'is_login_enabled',
                width: "90px",
                render: (text, record) => {

                    if (record.is_login_enabled === undefined) {
                        return (
                            <Badge status="warning" text="不明"/>
                        )
                    }

                    if (record.is_login_enabled === true) {
                        return (
                            <Badge status="success" text="有効"/>
                        )
                    }

                    if (record.is_login_enabled === false) {
                        return (
                            <Badge status="error" text="無効"/>
                        )
                    }

                }
            }, {
                title: '有効期限',
                dataIndex: 'start_at',
                key: 'start_at',
                width: "160px",
                render: (text, record) => {
                    const publish_start = moment.unix(record.start_at).format("YYYY/MM/DD HH:mm");
                    const publish_end = moment.unix(record.end_at).format("YYYY/MM/DD HH:mm");
                    const isIndefinitePeriod = record.end_at === 2147483647;
                    const isIndefinitePeriodStart = record.start_at === 0;
                    return (
                        <div>
                            {isIndefinitePeriodStart ? `ー` : `${publish_start} から`}<br/>
                            {isIndefinitePeriod ? `ー` : `${publish_end} まで`}
                        </div>
                    )
                }
            }, {
                title: '登録日時 / 更新日時',
                dataIndex: 'created_at',
                key: 'created_at',
                width: "140px",
                render: (text, record) => {
                    const update_at = record.updated_at ? moment.unix(record.updated_at).format("YYYY/MM/DD HH:mm") : "更新なし";
                    const created_at = moment.unix(record.created_at).format("YYYY/MM/DD HH:mm");
                    return (
                        <div>
                            {created_at}<br/>
                            {update_at}
                        </div>
                    )
                }
            }, {
                title: "",
                width: "85px",
                key: 'action',
                render: (text, record) => {
                    return (
                        <Row type="flex" justify="space-around" align="middle">
                            <Col>
                                <Tooltip placement="topLeft" title="ユーザ情報">
                                    <Button type="default" shape="circle" icon={<SearchOutlined />} onClick={() => this.handleOpenUserModal(record)}/>
                                </Tooltip>
                            </Col>
                        </Row>
                    );
                },
            }

        ];



        const {selectedRowKeys} = this.state;
        const rowSelection = {
            selectedRowKeys,
            onChange: this.onSelectChange,
            hideDefaultSelections: true
        };

        let availableSearchForm;
        if (this.state.search_type === "simple") {
            availableSearchForm = (
                <Form className="search-form" onSubmit={this.onSubmit}>

                    <FormItem
                        {...formItemLayout}
                        label="学籍番号10桁コード"
                        layout="inline">
                        {getFieldDecorator('personal_id')(
                            <Input placeholder="学籍番号10桁コードを入力してください"/>
                        )}
                    </FormItem>
                    <FormItem
                        {...formItemLayout}
                        label="日本語学籍番号（例：法２１−１）">
                        {getFieldDecorator('option_1')(
                            <Input placeholder="日本語学籍番号（例：法２１−１）を入力してください"/>
                        )}
                    </FormItem>
                    <FormItem
                        {...formItemLayout}
                        label="教職員番号">
                        {getFieldDecorator('option_2')(
                            <Input placeholder="教職員番号を入力してください" />
                        )}
                    </FormItem>
                    <FormItem
                        {...formItemLayout}
                        label="氏名"
                        layout="inline">
                        {getFieldDecorator('name')(
                            <Input placeholder="氏名を入力してください"/>
                        )}
                    </FormItem>
                    <FormItem
                        {...formItemLayout}
                        label="氏名(カナ)"
                        layout="inline">
                        {getFieldDecorator('name_kana')(
                            <Input placeholder="氏名(カナ)を入力してください"/>
                        )}
                    </FormItem>
                    <FormItem
                        {...formItemLayout}
                        label="氏名(英)"
                        layout="inline">
                        {getFieldDecorator('name_alphabet')(
                            <Input placeholder="氏名(英)を入力してください"/>
                        )}
                    </FormItem>
                    <FormItem
                        {...formItemLayout}
                        label="摘要"
                        layout="inline">
                        {getFieldDecorator('memo')(
                            <Input placeholder="摘要を入力してください"/>
                        )}
                    </FormItem>
                    <FormItem
                        {...formItemLayout}
                        label="有効期限"
                    >
                        {getFieldDecorator('expire_range',{
                            rules: [{type: 'array', message: '検索期間を選択してください'}]
                        })(
                            <RangePicker
                                locale={jaJP}
                                showTime={{ format: 'HH:mm' }}
                                format="YYYY/MM/DD HH:mm"
                                ranges={GlobalConfig.DatePickerRanges()}/>
                        )}
                    </FormItem>
                    <FormItem
                        {...formItemLayout}
                        label="登録日時"
                    >
                        {getFieldDecorator('created_range',{
                            rules: [{type: 'array', message: '検索期間を選択してください'}]
                        })(
                            <RangePicker
                                locale={jaJP}
                                showTime={{ format: 'HH:mm' }}
                                format="YYYY/MM/DD HH:mm"
                                ranges={GlobalConfig.DatePickerRanges()}/>
                        )}
                    </FormItem>
                    <FormItem
                        {...formItemLayout}
                        label="更新日時"
                    >
                        {getFieldDecorator('updated_range',{
                            rules: [{type: 'array', message: '検索期間を選択してください'}]
                        })(
                            <RangePicker
                                locale={jaJP}
                                showTime={{ format: 'HH:mm' }}
                                format="YYYY/MM/DD HH:mm"
                                ranges={GlobalConfig.DatePickerRanges()}/>
                        )}
                    </FormItem>

                    <div className="submit-container">

                        <Button className="search-form-button" onClick={this.handleResetSearchForm}>
                            リセット
                        </Button>

                        <Button type="primary" htmlType="submit" className="search-form-button">
                            検索
                        </Button>

                    </div>

                </Form>
            );
        } else if(this.state.search_type === "csv") {

            const props = {
                onRemove: (file) => {
                    this.setState(({fileList}) => {
                        const index = fileList.indexOf(file);
                        const newFileList = fileList.slice();
                        newFileList.splice(index, 1);
                        return {
                            fileList: newFileList,
                        };
                    });
                },
                beforeUpload: (file) => {
                    // this.setState(({ fileList }) => ({
                    //     fileList: [...fileList, file],
                    // }));
                    this.handleUpload(file);
                    return false;
                },
                handleChange: this.handleChange,
                fileList: this.state.fileList,
            };

            availableSearchForm = (
                <Form className="search-form" onSubmit={this.onSubmit}>

                    <div className="dropbox">
                        <Upload.Dragger
                            name="files"
                            {...props} >
                            <p className="ant-upload-drag-icon">
                                <InboxOutlined />
                            </p>
                            <p className="ant-upload-text">CSVファイルをドラッグ＆ドロップするか、下の「ファイルを選択」から選択してください</p>
                            <div className="ant-upload-hint">
                                フォーマット「csv」
                            </div>
                            <Button type="primary" className="button">ファイルを選択</Button>
                        </Upload.Dragger>
                    </div>

                    <div className="submit-container">
                        <Button type="primary" htmlType="submit" className="search-form-button">
                            検索
                        </Button>
                    </div>
                </Form>
            )
        }else  {


            availableSearchForm = (
                <Form className="search-form" onSubmit={this.onSubmit}>

                    <div className="dropbox">
                        <FormItem
                            {...formItemLayout}
                            label="学籍番号10桁コード（一行１件）"
                        >
                            {getFieldDecorator('user_text',{
                                rules: [{message: '学籍番号10桁コード（一行１件）'}]
                            })(
                                <TextArea rows={4} />
                            )}
                        </FormItem>

                    </div>

                    <div className="submit-container">
                        <Button type="primary"  htmlType="submit" className="search-form-button">
                            検索
                        </Button>
                    </div>
                </Form>
            )
        }

        return (
            <Modal
                className="common-modal select-target-form"
                title="対象ユーザの追加"
                visible={this.state.visible}
                onOk={this.handleOk}
                maskClosable={false}
                confirmLoading={this.state.confirmLoading}
                onCancel={this.handleCancel}
                footer={[
                    <Button key="preview-add-user-modal" size="large" onClick={this.handleOkAddSearchedAll}>検索結果を全て追加</Button>,
                    <Button key="create-add-user-modal" type={"primary"} size="large"
                            onClick={this.handleOk}>選択されたユーザを追加</Button>
                ]}>

                <div className='wrapper'>
                    <div className="card general-search">
                        <RadioGroup
                            defaultValue="simple"
                            size="medium"
                            value={this.state.search_type}
                            onChange={this.onChangeRadio}>
                            <RadioButton value="simple">利用者条件から検索</RadioButton>
                            <RadioButton value="csv">CSVから検索</RadioButton>
                            <RadioButton value="text">学籍番号10桁コードから検索</RadioButton>
                        </RadioGroup>
                        {availableSearchForm}
                    </div>
                    <Table
                        rowKey="uuid"
                        rowSelection={rowSelection}
                        className="general-table"
                        scroll={{x: "100%"}}
                        locale={{
                            filterTitle: 'フィルタ',
                            filterConfirm: '確定',
                            filterReset: 'リセット',
                            emptyText: '該当するものはありません',
                        }}
                        bordered={true}
                        size="middle"
                        columns={columns}
                        pagination={this.state.pagination}
                        dataSource={this.state.userList}/>
                </div>

                <UserViewModal
                    visible={this.state.viewUserModalIsOpen}
                    handleCancel={() => {
                        this.setState({
                            viewUserModalIsOpen: false
                        })
                    }}
                    handleOk={(fieldValue, data) => {
                        this.setState({
                            viewUserModalIsOpen: false
                        })
                    }}
                    uuid={this.state.viewModalUUID}
                />

            </Modal>
        );
    }
}


AddUserModal.propTypes = {
    visible: PropTypes.bool,
    handleOk: PropTypes.func.isRequired,
    handleCancel: PropTypes.func.isRequired,
};
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Form.create()(AddUserModal))
