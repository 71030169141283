import React from 'react';
import {connect, store} from 'react-redux';
import {
    CheckCircleOutlined,
    CloseCircleOutlined,
    DeleteOutlined,
    EditOutlined,
    LoadingOutlined,
    PlusOutlined,
    TeamOutlined,
    FolderOutlined
} from '@ant-design/icons';
import {Modal, Tabs, Input, Tree, Button, Tooltip, Popconfirm, Spin, Tag} from 'antd';
import log from 'components/utils/Logger'
import * as Actions from '../../redux/actions';
import ModalAddGroup from './ModalAddGroup';
import ModalEditGroup from './ModalEditGroup';
import SortableTree, {
    changeNodeAtPath,
    getVisibleNodeCount,
    getNodeAtPath,
    toggleExpandedForAll
} from 'react-sortable-tree';
import ModalAddEditGroup from "./ModalAddEditGroup";

const TreeNode = Tree.TreeNode;

const getParentKey = (uuid, tree) => {
    let parentKey;
    for (let i = 0; i < tree.length; i++) {
        const node = tree[i];
        if (node.children) {
            if (node.children.some(item => item.uuid === uuid)) {
                parentKey = node.uuid;
            } else if (getParentKey(uuid, node.children)) {
                parentKey = getParentKey(uuid, node.children);
            }
        }
    }
    return parentKey;
};


const dataList = [];
const generateList = (data) => {
    //一旦初期化
    for (let i = 0; i < data.length; i++) {
        const node = data[i];
        const uuid = node.uuid;
        const expanded = node.hasOwnProperty("children");
        if (node.children) {
            generateList(node.children);
        }
    }
    return dataList;
};


const mapStateToProps = (state, props) => {
    return {
        screen: state.screen,
        group: state.group,
        groupCreate: state.groupCreate,
        groupUpdate: state.groupUpdate,
        groupDelete: state.groupDelete
    }
};

const mapDispatchToProps = dispatch => {
    return {
        searchGroup: () => {
            dispatch(Actions.AdminConnection.group.search.request());
        },
        createGroup: (uuid, data) => {
            dispatch(Actions.AdminConnection.group.create.request(uuid, data));
        },
        updateGroup: (uuid, data) => {
            dispatch(Actions.AdminConnection.group.update.request(uuid, data));
        },
        deleteGroup: (uuid) => {
            dispatch(Actions.AdminConnection.group.delete.request(uuid));
        }
    }
};

const getNodeKey = ({treeIndex}) => treeIndex;

class GroupList extends React.Component {
    constructor(props) {
        super(props);
        let loading = true;

        let groupListArray = [];
        let groupListSearchIndexArray = [];
        if (!props.group.meta.fetch) {
            if (!props.group.error) {
                if (props.group.payload !== null && props.group.payload.hasOwnProperty("result") && props.group.payload.result.hasOwnProperty("items")) {
                    groupListArray = props.group.payload.result.items;
                    dataList.splice(0, dataList.length);
                    groupListSearchIndexArray = generateList(groupListArray)
                }
            }
        } else {
            loading = props.group.meta.fetch
        }
        this.state = {
            visible: props.visible,
            expandedKeys: [],
            checkedKeys: [],
            checkedRows: [],
            loading,
            searchValue: '',
            autoExpandParent: true,
            groupListArray,
            groupListSearchIndexArray,
            selectedKeys: [],
            selectedData: {
                name: "",
                uuid: "",
            },
            selectedObjectIsFolderType: true,
            treeParentHeight: 0,
            shouldCreate: true,
        }
    }


    componentWillMount() {
        this.props.searchGroup();
    }


    componentWillReceiveProps(nextProps) {

        const {group, groupCreate, groupUpdate, groupDelete, checkedKeys} = {...this.props};

        if (this.state.visible === false) {
            this.setState({
                expandedKeys: [],
                checkedKeys: [],
                checkedRows: [],
            });
        }

        this.setState({
            checkedKeys: nextProps.checkedKeys,
            visible: nextProps.visible,
        });

        if (nextProps.group !== group) {
            if (!nextProps.group.meta.fetch) {
                if (!nextProps.group.error) {
                    if (nextProps.group.payload !== null) {
                        dataList.splice(0, dataList.length);

                        const array = this.expandedNode(nextProps.group.payload.result.items);
                        this.setState({
                            loading: nextProps.group.meta.fetch,
                            groupListArray: array,
                            groupListSearchIndexArray: generateList(nextProps.group.payload.result.items)
                        });
                    }
                }
            } else {
                this.setState({
                    loading: nextProps.group.meta.fetch
                })
            }
        }


        if (nextProps.groupCreate !== groupCreate) {
            if (!nextProps.groupCreate.meta.fetch) {
                if (!nextProps.groupCreate.error) {
                    if (nextProps.groupCreate.payload !== null) {
                        this.setState({
                            editGroupModalIsOpen: false,
                            loading: nextProps.groupCreate.meta.fetch
                        })
                        this.search();
                    } else {
                        this.setState({
                            loading: nextProps.groupCreate.meta.fetch
                        })
                    }
                } else {
                    this.setState({
                        loading: nextProps.groupCreate.meta.fetch
                    })
                }
            } else {
                this.setState({
                    loading: nextProps.groupCreate.meta.fetch
                })
            }
        }

        if (nextProps.groupUpdate !== groupUpdate) {
            if (!nextProps.groupUpdate.meta.fetch) {
                if (!nextProps.groupUpdate.error) {
                    if (nextProps.groupUpdate.payload !== null) {
                        this.setState({
                            editGroupModalIsOpen: false,
                            loading: nextProps.groupUpdate.meta.fetch
                        })
                        this.search();
                    } else {
                        this.setState({
                            loading: nextProps.groupUpdate.meta.fetch
                        })
                    }
                } else {
                    this.search();
                    this.setState({
                        loading: nextProps.groupUpdate.meta.fetch
                    })
                }
            } else {
                this.setState({
                    loading: nextProps.groupUpdate.meta.fetch
                })
            }
        }

        if (nextProps.groupDelete !== groupDelete) {
            if (!nextProps.groupDelete.meta.fetch) {
                if (!nextProps.groupDelete.error) {
                    if (nextProps.groupDelete.payload !== null) {
                        this.search();
                        this.setState({
                            loading: nextProps.groupDelete.meta.fetch
                        })
                    } else {
                        this.setState({
                            loading: nextProps.groupDelete.meta.fetch
                        })
                    }
                } else {
                    this.setState({
                        loading: nextProps.groupDelete.meta.fetch
                    })
                }
            } else {
                this.setState({
                    loading: nextProps.groupDelete.meta.fetch
                })
            }
        }
    }

    search = () => {
        this.props.searchGroup();
    }

    handleOk = () => {
        this.props.handleOk(this.state.checkedKeys, this.state.checkedRows);
        this.setState({
            confirmLoading: true,
        });
        setTimeout(() => {
            this.setState({
                confirmLoading: false,
            });
        }, 2000);
    };

    onSelect = (selectedKeys, info) => {
        this.setState({
            selectedKeys
        })
    };

    handleCancel = () => {
        this.setState({
            editGroupModalIsOpen: false
        })
    };

    createGroup = (fieldsValue, data) => {
        this.props.createGroup(
            data.uuid, fieldsValue
        )
    };

    sortGroup = (fieldsValue, data) => {
        this.props.updateGroup(
            data.uuid, fieldsValue
        )
    }

    editGroup = (fieldsValue, data) => {
        log.debug(data);
        const name = fieldsValue.name;
        this.setState(state => ({
            groupListArray: changeNodeAtPath({
                treeData: state.groupListArray,
                path: state.selectedPath,
                getNodeKey,
                newNode: {...data, name},
            }),
        }))
        this.props.updateGroup(
            data.uuid, fieldsValue
        )
    };


    deleteGroup = (data) => {
        log.debug(data);
        this.props.deleteGroup(data.uuid);
    };

    expandedNode = (array) => {
        let treeData = array;
        this.state.expandedKeys.forEach((path, index) => {
            const foundNode = getNodeAtPath({
                treeData,
                path,
                getNodeKey,
            });
            if (foundNode) {
                treeData = changeNodeAtPath({
                    treeData,
                    path,
                    getNodeKey,
                    newNode: {
                        ...foundNode.node,
                        expanded: true
                    },
                })
            }
        });
        return toggleExpandedForAll({treeData, expanded: true});
    };

    onDragEnter = (info) => {

    };

    render() {
        const {
            visible,
            confirmLoading,
            ModalText,
            searchValue,
            expandedKeys,
            autoExpandParent,
            groupListArray
        } = this.state;

        const treeParentHeight = getVisibleNodeCount({treeData: groupListArray}) * 62 + 2;
        return (
            <div id="group-list-container" alt="グループ編集" className="tab-container">
                <Spin spinning={this.state.loading}>
                    <div style={{padding: "50px", textAlign: "center", display: this.state.loading ? "hi" : "none"}}>
                        <LoadingOutlined/> グループ情報を読み込み中...
                    </div>
                    <div style={{height: treeParentHeight, visible: !this.state.loading}}>
                        <SortableTree

                            onVisibilityToggle={(e) => {
                                const array = expandedKeys;
                                if (e.expanded) {
                                    array.push(e.path);
                                    this.setState({
                                        expandedKeys: array
                                    })
                                } else {
                                    array.splice(expandedKeys.indexOf(e.path), 1)
                                    this.setState({
                                        expandedKeys: array
                                    })
                                }
                            }}
                            onMoveNode={({
                                             treeData,
                                             node,
                                             nextParentNode,
                                             prevPath,
                                             prevTreeIndex,
                                             nextPath,
                                             nextTreeIndex
                                         }) => {
                                let processingArray = nextParentNode ? nextParentNode.children : this.state.groupListArray;
                                this.setState({
                                    processingArray
                                }, () => {
                                    processingArray.forEach((value, index) => {
                                        const fieldsValue = {};
                                        fieldsValue.name = value.name;
                                        fieldsValue.is_authority = value.is_authority;
                                        fieldsValue.order = index;
                                        this.sortGroup(fieldsValue, value);
                                    })
                                })
                            }}
                            canDrop={(e) => (!e.nextParent && !e.prevParent || e.nextParent && e.prevParent && e.prevParent.uuid === e.nextParent.uuid)}
                            treeData={this.state.groupListArray}
                            onChange={groupListArray => {
                                log.debug(groupListArray)
                                this.setState({groupListArray})
                            }}
                            generateNodeProps={({node, path}) => {
                                return {
                                    title: (
                                        <div>

                                            {node?.children && node.children.length !== 0 ?
                                                    <div className="title" style={{minWidth: 200}}>
                                                        <Tooltip mouseEnterDelay={0.5} title={"UUID:" + node.uuid}><FolderOutlined /> {node.name}</Tooltip>
                                                    </div>
                                                :
                                                    <div className="title" style={{minWidth: 200}}>
                                                        <Tooltip mouseEnterDelay={0.5} title={"UUID:" + node.uuid}><TeamOutlined /> {node.name}</Tooltip>
                                                    </div>
                                               }

                                            <div style={{marginLeft: 10, alignLeft: true}}>
                                                {node.is_active ?
                                                    <Tag icon={<CheckCircleOutlined/>} color="blue" style={{backgroundColor: "#FFFFFF"}}>
                                                        対象者表示
                                                    </Tag>
                                                    :
                                                    <Tag icon={<CloseCircleOutlined/>} color="volcano" style={{backgroundColor: "#FFFFFF"}}>
                                                        対象者非表示
                                                    </Tag>
                                                }
                                            </div>
                                            <div style={{marginLeft: 3,marginRight: 10, alignLeft: true}}>
                                                {node.is_authority ?
                                                    <Tag icon={<CheckCircleOutlined/>} color="blue" style={{backgroundColor: "#FFFFFF"}}>
                                                        所属表示
                                                    </Tag>
                                                    :
                                                    <Tag icon={<CloseCircleOutlined/>} color="volcano" style={{backgroundColor: "#FFFFFF"}}>
                                                        所属非表示
                                                    </Tag>
                                                }
                                                {node?.children && node.children.length !== 0 ?
                                                    <Tooltip placement="topLeft" title="下位グループの追加">
                                                        <Button
                                                            type="default"
                                                            shape="circle"
                                                            icon={<PlusOutlined/>}
                                                            onClick={() => {
                                                                this.setState({
                                                                    shouldCreate: true
                                                                }, () => {
                                                                    this.setState({
                                                                        editGroupModalIsOpen: true,
                                                                        selectedData: node,
                                                                        selectedPath: path
                                                                    })
                                                                });
                                                            }}
                                                        />
                                                    </Tooltip>
                                                    :
                                                    null
                                                }
                                                <Tooltip placement="topLeft" title="グループの編集">
                                                    <Button
                                                        type="default"
                                                        shape="circle"
                                                        icon={<EditOutlined/>}
                                                        onClick={() => {
                                                            this.setState({
                                                                shouldCreate: false,
                                                                selectedObjectIsFolderType: node?.children && node.children.length !== 0 ? true:false,
                                                            }, () => {
                                                                this.setState({
                                                                    editGroupModalIsOpen: true,
                                                                    selectedData: node,
                                                                    selectedPath: path
                                                                })
                                                            })
                                                        }}
                                                    />
                                                </Tooltip>

                                                <Popconfirm
                                                    title={<span>グループを削除すると関連された部分からグループは全て削除されます。<br/>
                                                        <strong>グループに一人でもユーザが属す場合は削除できません</strong></span>}
                                                    onConfirm={() => this.deleteGroup(node)}
                                                    onCancel=""
                                                    placement="left"
                                                    okText="削除" cancelText="キャンセル"
                                                >
                                                    <Tooltip placement="topLeft" title="グループ削除">
                                                        <Button type="default" shape="circle" icon={<DeleteOutlined/>}/>
                                                    </Tooltip>
                                                </Popconfirm>
                                            </div>
                                        </div>
                                    )
                                };
                            }}
                        />
                    </div>
                </Spin>
                <ModalAddEditGroup
                    onOk={(value, data) => {
                        if (this.state.shouldCreate) {
                            this.createGroup(value, data);
                        } else {
                            this.editGroup(value, data)
                        }
                    }}
                    onCancel={this.handleCancel}
                    visible={this.state.editGroupModalIsOpen}
                    data={this.state.selectedData}
                    is_authority={this.state.is_authority}
                    shouldCreate={this.state.shouldCreate}
                    selectedObjectIsFolderType={this.state.selectedObjectIsFolderType}
                />
                {/*
                <ModalAddGroup
                    onOk={this.createGroup}
                    onCancel={this.handleCancel}
                    visible={this.state.addGroupModalIsOpen}
                    data={this.state.selectedData}
                    is_authority={this.state.is_authority}
                />
                */
                }

                {/*
                <ModalEditGroup
                    onOk={this.editGroup}
                    onCancel={this.handleCancel}
                    visible={this.state.editGroupModalIsOpen}
                    data={this.state.selectedData}
                    is_authority={this.state.is_authority}
                />
                */}
            </div>
        );
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(GroupList)

