import React from 'react';
import {connect, store} from 'react-redux';
import { DeleteOutlined, EditOutlined, LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import { Modal, Tabs, Input, Tree, Button, Tooltip, Popconfirm, Spin } from 'antd';
import log from 'components/utils/Logger'
import * as Actions from '../../redux/actions';
import ModalAddGroup from './ModalAddGroup';
import ModalEditGroup from './ModalEditGroup';
import SortableTree,{ changeNodeAtPath,getVisibleNodeCount,getNodeAtPath } from 'react-sortable-tree';
import ModalAddEditGroup from "./ModalAddEditGroup";

const TreeNode = Tree.TreeNode;

const getParentKey = (uuid, tree) => {
    let parentKey;
    for (let i = 0; i < tree.length; i++) {
        const node = tree[i];
        if (node.children) {
            if (node.children.some(item => item.uuid === uuid)) {
                parentKey = node.uuid;
            } else if (getParentKey(uuid, node.children)) {
                parentKey = getParentKey(uuid, node.children);
            }
        }
    }
    return parentKey;
};


const dataList = [];
const generateList = (data) => {
    //一旦初期化
    for (let i = 0; i < data.length; i++) {
        const node = data[i];
        const uuid = node.uuid;
        const expanded = node.hasOwnProperty("children");
        if (node.children) {
            generateList(node.children);
        }
    }
    return dataList;
};


const mapStateToProps = (state, props) => {
    return {
        screen: state.screen,
        group: state.group,
        groupCreate: state.groupCreate,
        groupUpdate: state.groupUpdate,
        groupDelete: state.groupDelete
    }
};

const mapDispatchToProps = dispatch => {
    return {
        searchGroup: () => {
            dispatch(Actions.AdminConnection.group.search.request());
        },
        createGroup: (uuid, data) => {
            dispatch(Actions.AdminConnection.group.create.request(uuid, data));
        },
        updateGroup: (uuid, data) => {
            dispatch(Actions.AdminConnection.group.update.request(uuid, data));
        },
        deleteGroup: (uuid) => {
            dispatch(Actions.AdminConnection.group.delete.request(uuid));
        }
    }
};

const getNodeKey = ({ treeIndex }) => treeIndex;

const testTree = {
    "is_successful": true,
    "code": 200,
    "api_version": 2,
    "gmt": 1612947748,
    "user": null,
    "result": {
        "items": [
            {
                "uuid": "2b55f985-6084-11eb-9032-0242ac150002",
                "name": "関西大学",
                "order": 0,
                "is_active": 1,
                "is_authority": 0,
                "created_at": 1611740634,
                "updated_at": 1611740634,
                "attributes": [],
                "children": [
                    {
                        "uuid": "2b572cad-6084-11eb-9032-0242ac150002",
                        "name": "教員",
                        "order": 0,
                        "is_active": 1,
                        "is_authority": 0,
                        "created_at": 1611740634,
                        "updated_at": 1611740634,
                        "attributes": [],
                        "children": [
                            {
                                "uuid": "2b58400a-6084-11eb-9032-0242ac150002",
                                "name": "資格",
                                "order": 0,
                                "is_active": 1,
                                "is_authority": 0,
                                "created_at": 1611740634,
                                "updated_at": 1611740634,
                                "attributes": [],
                                "children": [
                                    {
                                        "uuid": "2b595fd2-6084-11eb-9032-0242ac150002",
                                        "name": "専任に準ずる教員",
                                        "order": 0,
                                        "is_active": 1,
                                        "is_authority": 0,
                                        "created_at": 1611740634,
                                        "updated_at": 1611740634,
                                        "attributes": [],
                                        "children": [
                                            {
                                                "uuid": "2c6d916f-6084-11eb-9032-0242ac150002",
                                                "name": "社会安全学部",
                                                "order": 0,
                                                "is_active": 1,
                                                "is_authority": 0,
                                                "created_at": 1611740636,
                                                "updated_at": 1611740636,
                                                "attributes": [],
                                                "children": []
                                            },
                                            {
                                                "uuid": "2d79bb9a-6084-11eb-9032-0242ac150002",
                                                "name": "留学生別科",
                                                "order": 0,
                                                "is_active": 1,
                                                "is_authority": 0,
                                                "created_at": 1611740637,
                                                "updated_at": 1611740637,
                                                "attributes": [],
                                                "children": []
                                            },
                                            {
                                                "uuid": "2d6e73cf-6084-11eb-9032-0242ac150002",
                                                "name": "商学部",
                                                "order": 0,
                                                "is_active": 1,
                                                "is_authority": 0,
                                                "created_at": 1611740637,
                                                "updated_at": 1611740637,
                                                "attributes": [],
                                                "children": []
                                            },
                                            {
                                                "uuid": "2d568a0c-6084-11eb-9032-0242ac150002",
                                                "name": "文学部",
                                                "order": 0,
                                                "is_active": 1,
                                                "is_authority": 0,
                                                "created_at": 1611740637,
                                                "updated_at": 1611740637,
                                                "attributes": [],
                                                "children": []
                                            },
                                            {
                                                "uuid": "2d2b70bc-6084-11eb-9032-0242ac150002",
                                                "name": "外国語学部",
                                                "order": 0,
                                                "is_active": 1,
                                                "is_authority": 0,
                                                "created_at": 1611740637,
                                                "updated_at": 1611740637,
                                                "attributes": [],
                                                "children": []
                                            },
                                            {
                                                "uuid": "2d09614f-6084-11eb-9032-0242ac150002",
                                                "name": "社会学部",
                                                "order": 0,
                                                "is_active": 1,
                                                "is_authority": 0,
                                                "created_at": 1611740637,
                                                "updated_at": 1611740637,
                                                "attributes": [],
                                                "children": []
                                            },
                                            {
                                                "uuid": "2cd3aaa3-6084-11eb-9032-0242ac150002",
                                                "name": "人間健康学部",
                                                "order": 0,
                                                "is_active": 1,
                                                "is_authority": 0,
                                                "created_at": 1611740636,
                                                "updated_at": 1611740636,
                                                "attributes": [],
                                                "children": []
                                            },
                                            {
                                                "uuid": "2cc0ae20-6084-11eb-9032-0242ac150002",
                                                "name": "心理学研究科（臨床心理専門職大学院）",
                                                "order": 0,
                                                "is_active": 1,
                                                "is_authority": 0,
                                                "created_at": 1611740636,
                                                "updated_at": 1611740636,
                                                "attributes": [],
                                                "children": []
                                            },
                                            {
                                                "uuid": "2c953bb8-6084-11eb-9032-0242ac150002",
                                                "name": "生命工学部",
                                                "order": 0,
                                                "is_active": 1,
                                                "is_authority": 0,
                                                "created_at": 1611740636,
                                                "updated_at": 1611740636,
                                                "attributes": [],
                                                "children": []
                                            },
                                            {
                                                "uuid": "2c927010-6084-11eb-9032-0242ac150002",
                                                "name": "環境都市工学部",
                                                "order": 0,
                                                "is_active": 1,
                                                "is_authority": 0,
                                                "created_at": 1611740636,
                                                "updated_at": 1611740636,
                                                "attributes": [],
                                                "children": []
                                            },
                                            {
                                                "uuid": "2c89c55d-6084-11eb-9032-0242ac150002",
                                                "name": "会計研究科（会計専門職大学院）",
                                                "order": 0,
                                                "is_active": 1,
                                                "is_authority": 0,
                                                "created_at": 1611740636,
                                                "updated_at": 1611740636,
                                                "attributes": [],
                                                "children": []
                                            },
                                            {
                                                "uuid": "2b5a1659-6084-11eb-9032-0242ac150002",
                                                "name": "政策創造学部",
                                                "order": 0,
                                                "is_active": 1,
                                                "is_authority": 0,
                                                "created_at": 1611740634,
                                                "updated_at": 1611740634,
                                                "attributes": [],
                                                "children": []
                                            },
                                            {
                                                "uuid": "2c60d80d-6084-11eb-9032-0242ac150002",
                                                "name": "法学部",
                                                "order": 0,
                                                "is_active": 1,
                                                "is_authority": 0,
                                                "created_at": 1611740636,
                                                "updated_at": 1611740636,
                                                "attributes": [],
                                                "children": []
                                            },
                                            {
                                                "uuid": "2c36992c-6084-11eb-9032-0242ac150002",
                                                "name": "経済学部",
                                                "order": 0,
                                                "is_active": 1,
                                                "is_authority": 0,
                                                "created_at": 1611740635,
                                                "updated_at": 1611740635,
                                                "attributes": [],
                                                "children": []
                                            },
                                            {
                                                "uuid": "2c14b039-6084-11eb-9032-0242ac150002",
                                                "name": "教育推進部",
                                                "order": 0,
                                                "is_active": 1,
                                                "is_authority": 0,
                                                "created_at": 1611740635,
                                                "updated_at": 1611740635,
                                                "attributes": [],
                                                "children": []
                                            },
                                            {
                                                "uuid": "2c09233a-6084-11eb-9032-0242ac150002",
                                                "name": "システム理工学部",
                                                "order": 0,
                                                "is_active": 1,
                                                "is_authority": 0,
                                                "created_at": 1611740635,
                                                "updated_at": 1611740635,
                                                "attributes": [],
                                                "children": []
                                            },
                                            {
                                                "uuid": "2bf6eac2-6084-11eb-9032-0242ac150002",
                                                "name": "法務研究科（法科大学院）",
                                                "order": 0,
                                                "is_active": 1,
                                                "is_authority": 0,
                                                "created_at": 1611740635,
                                                "updated_at": 1611740635,
                                                "attributes": [],
                                                "children": []
                                            },
                                            {
                                                "uuid": "2beaba92-6084-11eb-9032-0242ac150002",
                                                "name": "国際部",
                                                "order": 0,
                                                "is_active": 1,
                                                "is_authority": 0,
                                                "created_at": 1611740635,
                                                "updated_at": 1611740635,
                                                "attributes": [],
                                                "children": []
                                            },
                                            {
                                                "uuid": "2b9fe5ec-6084-11eb-9032-0242ac150002",
                                                "name": "総合情報学部",
                                                "order": 0,
                                                "is_active": 1,
                                                "is_authority": 0,
                                                "created_at": 1611740634,
                                                "updated_at": 1611740634,
                                                "attributes": [],
                                                "children": []
                                            },
                                            {
                                                "uuid": "2b98cc4c-6084-11eb-9032-0242ac150002",
                                                "name": "心理学研究科",
                                                "order": 0,
                                                "is_active": 1,
                                                "is_authority": 0,
                                                "created_at": 1611740634,
                                                "updated_at": 1611740634,
                                                "attributes": [],
                                                "children": []
                                            },
                                            {
                                                "uuid": "2b912a64-6084-11eb-9032-0242ac150002",
                                                "name": "東西学術研究所",
                                                "order": 0,
                                                "is_active": 1,
                                                "is_authority": 0,
                                                "created_at": 1611740634,
                                                "updated_at": 1611740634,
                                                "attributes": [],
                                                "children": []
                                            }
                                        ]
                                    },
                                    {
                                        "uuid": "2b60a991-6084-11eb-9032-0242ac150002",
                                        "name": "教員その他",
                                        "order": 0,
                                        "is_active": 1,
                                        "is_authority": 0,
                                        "created_at": 1611740634,
                                        "updated_at": 1611740634,
                                        "attributes": [],
                                        "children": []
                                    },
                                    {
                                        "uuid": "2b73187d-6084-11eb-9032-0242ac150002",
                                        "name": "専任教員",
                                        "order": 0,
                                        "is_active": 1,
                                        "is_authority": 0,
                                        "created_at": 1611740634,
                                        "updated_at": 1611740634,
                                        "attributes": [],
                                        "children": [
                                            {
                                                "uuid": "2c1e8dc5-6084-11eb-9032-0242ac150002",
                                                "name": "システム理工学部",
                                                "order": 0,
                                                "is_active": 1,
                                                "is_authority": 0,
                                                "created_at": 1611740635,
                                                "updated_at": 1611740635,
                                                "attributes": [],
                                                "children": []
                                            },
                                            {
                                                "uuid": "2d652763-6084-11eb-9032-0242ac150002",
                                                "name": "留学生別科",
                                                "order": 0,
                                                "is_active": 1,
                                                "is_authority": 0,
                                                "created_at": 1611740637,
                                                "updated_at": 1611740637,
                                                "attributes": [],
                                                "children": []
                                            },
                                            {
                                                "uuid": "2ce2b205-6084-11eb-9032-0242ac150002",
                                                "name": "商学部",
                                                "order": 0,
                                                "is_active": 1,
                                                "is_authority": 0,
                                                "created_at": 1611740636,
                                                "updated_at": 1611740636,
                                                "attributes": [],
                                                "children": []
                                            },
                                            {
                                                "uuid": "2cbdb93b-6084-11eb-9032-0242ac150002",
                                                "name": "外国語学部",
                                                "order": 0,
                                                "is_active": 1,
                                                "is_authority": 0,
                                                "created_at": 1611740636,
                                                "updated_at": 1611740636,
                                                "attributes": [],
                                                "children": []
                                            },
                                            {
                                                "uuid": "2caf5592-6084-11eb-9032-0242ac150002",
                                                "name": "教育推進部",
                                                "order": 0,
                                                "is_active": 1,
                                                "is_authority": 0,
                                                "created_at": 1611740636,
                                                "updated_at": 1611740636,
                                                "attributes": [],
                                                "children": []
                                            },
                                            {
                                                "uuid": "2ca8ba89-6084-11eb-9032-0242ac150002",
                                                "name": "生命工学部",
                                                "order": 0,
                                                "is_active": 1,
                                                "is_authority": 0,
                                                "created_at": 1611740636,
                                                "updated_at": 1611740636,
                                                "attributes": [],
                                                "children": []
                                            },
                                            {
                                                "uuid": "2c8e34bf-6084-11eb-9032-0242ac150002",
                                                "name": "文学部",
                                                "order": 0,
                                                "is_active": 1,
                                                "is_authority": 0,
                                                "created_at": 1611740636,
                                                "updated_at": 1611740636,
                                                "attributes": [],
                                                "children": []
                                            },
                                            {
                                                "uuid": "2c847c15-6084-11eb-9032-0242ac150002",
                                                "name": "法学部",
                                                "order": 0,
                                                "is_active": 1,
                                                "is_authority": 0,
                                                "created_at": 1611740636,
                                                "updated_at": 1611740636,
                                                "attributes": [],
                                                "children": []
                                            },
                                            {
                                                "uuid": "2c59018b-6084-11eb-9032-0242ac150002",
                                                "name": "社会安全学部",
                                                "order": 0,
                                                "is_active": 1,
                                                "is_authority": 0,
                                                "created_at": 1611740636,
                                                "updated_at": 1611740636,
                                                "attributes": [],
                                                "children": []
                                            },
                                            {
                                                "uuid": "2c43b9c4-6084-11eb-9032-0242ac150002",
                                                "name": "総合情報学部",
                                                "order": 0,
                                                "is_active": 1,
                                                "is_authority": 0,
                                                "created_at": 1611740635,
                                                "updated_at": 1611740635,
                                                "attributes": [],
                                                "children": []
                                            },
                                            {
                                                "uuid": "2c33a4e0-6084-11eb-9032-0242ac150002",
                                                "name": "政策創造学部",
                                                "order": 0,
                                                "is_active": 1,
                                                "is_authority": 0,
                                                "created_at": 1611740635,
                                                "updated_at": 1611740635,
                                                "attributes": [],
                                                "children": []
                                            },
                                            {
                                                "uuid": "2b73b8c7-6084-11eb-9032-0242ac150002",
                                                "name": "心理学研究科",
                                                "order": 0,
                                                "is_active": 1,
                                                "is_authority": 0,
                                                "created_at": 1611740634,
                                                "updated_at": 1611740634,
                                                "attributes": [],
                                                "children": []
                                            },
                                            {
                                                "uuid": "2c1cd09c-6084-11eb-9032-0242ac150002",
                                                "name": "環境都市工学部",
                                                "order": 0,
                                                "is_active": 1,
                                                "is_authority": 0,
                                                "created_at": 1611740635,
                                                "updated_at": 1611740635,
                                                "attributes": [],
                                                "children": []
                                            },
                                            {
                                                "uuid": "2c065423-6084-11eb-9032-0242ac150002",
                                                "name": "国際部",
                                                "order": 0,
                                                "is_active": 1,
                                                "is_authority": 0,
                                                "created_at": 1611740635,
                                                "updated_at": 1611740635,
                                                "attributes": [],
                                                "children": []
                                            },
                                            {
                                                "uuid": "2bfc4531-6084-11eb-9032-0242ac150002",
                                                "name": "心理学研究科（臨床心理専門職大学院）",
                                                "order": 0,
                                                "is_active": 1,
                                                "is_authority": 0,
                                                "created_at": 1611740635,
                                                "updated_at": 1611740635,
                                                "attributes": [],
                                                "children": []
                                            },
                                            {
                                                "uuid": "2bf1678a-6084-11eb-9032-0242ac150002",
                                                "name": "会計研究科（会計専門職大学院）",
                                                "order": 0,
                                                "is_active": 1,
                                                "is_authority": 0,
                                                "created_at": 1611740635,
                                                "updated_at": 1611740635,
                                                "attributes": [],
                                                "children": []
                                            },
                                            {
                                                "uuid": "2bdf8e65-6084-11eb-9032-0242ac150002",
                                                "name": "経済学部",
                                                "order": 0,
                                                "is_active": 1,
                                                "is_authority": 0,
                                                "created_at": 1611740635,
                                                "updated_at": 1611740635,
                                                "attributes": [],
                                                "children": []
                                            },
                                            {
                                                "uuid": "2bdcdf3d-6084-11eb-9032-0242ac150002",
                                                "name": "法務研究科（法科大学院）",
                                                "order": 0,
                                                "is_active": 1,
                                                "is_authority": 0,
                                                "created_at": 1611740635,
                                                "updated_at": 1611740635,
                                                "attributes": [],
                                                "children": []
                                            },
                                            {
                                                "uuid": "2bba83fa-6084-11eb-9032-0242ac150002",
                                                "name": "人間健康学部",
                                                "order": 0,
                                                "is_active": 1,
                                                "is_authority": 0,
                                                "created_at": 1611740635,
                                                "updated_at": 1611740635,
                                                "attributes": [],
                                                "children": []
                                            },
                                            {
                                                "uuid": "2bb3e180-6084-11eb-9032-0242ac150002",
                                                "name": "社会学部",
                                                "order": 0,
                                                "is_active": 1,
                                                "is_authority": 0,
                                                "created_at": 1611740635,
                                                "updated_at": 1611740635,
                                                "attributes": [],
                                                "children": []
                                            },
                                            {
                                                "uuid": "2b75bd0e-6084-11eb-9032-0242ac150002",
                                                "name": "東西学術研究所",
                                                "order": 0,
                                                "is_active": 1,
                                                "is_authority": 0,
                                                "created_at": 1611740634,
                                                "updated_at": 1611740634,
                                                "attributes": [],
                                                "children": []
                                            }
                                        ]
                                    },
                                    {
                                        "uuid": "2c879ee6-6084-11eb-9032-0242ac150002",
                                        "name": "非常勤講師",
                                        "order": 0,
                                        "is_active": 1,
                                        "is_authority": 0,
                                        "created_at": 1611740636,
                                        "updated_at": 1611740636,
                                        "attributes": [],
                                        "children": []
                                    }
                                ]
                            }
                        ]
                    },
                    {
                        "uuid": "2b5ac753-6084-11eb-9032-0242ac150002",
                        "name": "職員",
                        "order": 0,
                        "is_active": 1,
                        "is_authority": 0,
                        "created_at": 1611740634,
                        "updated_at": 1611740634,
                        "attributes": [],
                        "children": [
                            {
                                "uuid": "2b5b574a-6084-11eb-9032-0242ac150002",
                                "name": "所属",
                                "order": 0,
                                "is_active": 1,
                                "is_authority": 0,
                                "created_at": 1611740634,
                                "updated_at": 1611740634,
                                "attributes": [],
                                "children": [
                                    {
                                        "uuid": "2b5c108a-6084-11eb-9032-0242ac150002",
                                        "name": "法人本部",
                                        "order": 0,
                                        "is_active": 1,
                                        "is_authority": 0,
                                        "created_at": 1611740634,
                                        "updated_at": 1611740634,
                                        "attributes": [],
                                        "children": [
                                            {
                                                "uuid": "2b5d1d4b-6084-11eb-9032-0242ac150002",
                                                "name": "管財局",
                                                "order": 0,
                                                "is_active": 1,
                                                "is_authority": 0,
                                                "created_at": 1611740634,
                                                "updated_at": 1611740634,
                                                "attributes": [],
                                                "children": [
                                                    {
                                                        "uuid": "2b5db536-6084-11eb-9032-0242ac150002",
                                                        "name": "管財局",
                                                        "order": 0,
                                                        "is_active": 1,
                                                        "is_authority": 0,
                                                        "created_at": 1611740634,
                                                        "updated_at": 1611740634,
                                                        "attributes": [],
                                                        "children": []
                                                    },
                                                    {
                                                        "uuid": "2b977cb4-6084-11eb-9032-0242ac150002",
                                                        "name": "管財課",
                                                        "order": 0,
                                                        "is_active": 1,
                                                        "is_authority": 0,
                                                        "created_at": 1611740634,
                                                        "updated_at": 1611740634,
                                                        "attributes": [],
                                                        "children": []
                                                    }
                                                ]
                                            },
                                            {
                                                "uuid": "2b5f18e0-6084-11eb-9032-0242ac150002",
                                                "name": "初等中等教育事務局",
                                                "order": 0,
                                                "is_active": 1,
                                                "is_authority": 0,
                                                "created_at": 1611740634,
                                                "updated_at": 1611740634,
                                                "attributes": [],
                                                "children": [
                                                    {
                                                        "uuid": "2b5fa39c-6084-11eb-9032-0242ac150002",
                                                        "name": "初等部・中等部・高等部事務室",
                                                        "order": 0,
                                                        "is_active": 1,
                                                        "is_authority": 0,
                                                        "created_at": 1611740634,
                                                        "updated_at": 1611740634,
                                                        "attributes": [],
                                                        "children": []
                                                    },
                                                    {
                                                        "uuid": "2b62d644-6084-11eb-9032-0242ac150002",
                                                        "name": "北陽事務室",
                                                        "order": 0,
                                                        "is_active": 1,
                                                        "is_authority": 0,
                                                        "created_at": 1611740634,
                                                        "updated_at": 1611740634,
                                                        "attributes": [],
                                                        "children": []
                                                    },
                                                    {
                                                        "uuid": "2bd9097d-6084-11eb-9032-0242ac150002",
                                                        "name": "初等中等教育事務局",
                                                        "order": 0,
                                                        "is_active": 1,
                                                        "is_authority": 0,
                                                        "created_at": 1611740635,
                                                        "updated_at": 1611740635,
                                                        "attributes": [],
                                                        "children": []
                                                    },
                                                    {
                                                        "uuid": "2c0c07a9-6084-11eb-9032-0242ac150002",
                                                        "name": "一高・一中・幼稚園事務室",
                                                        "order": 0,
                                                        "is_active": 1,
                                                        "is_authority": 0,
                                                        "created_at": 1611740635,
                                                        "updated_at": 1611740635,
                                                        "attributes": [],
                                                        "children": []
                                                    }
                                                ]
                                            },
                                            {
                                                "uuid": "2b656806-6084-11eb-9032-0242ac150002",
                                                "name": "総務局",
                                                "order": 0,
                                                "is_active": 1,
                                                "is_authority": 0,
                                                "created_at": 1611740634,
                                                "updated_at": 1611740634,
                                                "attributes": [],
                                                "children": [
                                                    {
                                                        "uuid": "2b67034a-6084-11eb-9032-0242ac150002",
                                                        "name": "総務局付",
                                                        "order": 0,
                                                        "is_active": 1,
                                                        "is_authority": 0,
                                                        "created_at": 1611740634,
                                                        "updated_at": 1611740634,
                                                        "attributes": [],
                                                        "children": []
                                                    },
                                                    {
                                                        "uuid": "2b7de42e-6084-11eb-9032-0242ac150002",
                                                        "name": "東京センター",
                                                        "order": 0,
                                                        "is_active": 1,
                                                        "is_authority": 0,
                                                        "created_at": 1611740634,
                                                        "updated_at": 1611740634,
                                                        "attributes": [],
                                                        "children": []
                                                    },
                                                    {
                                                        "uuid": "2b9455b0-6084-11eb-9032-0242ac150002",
                                                        "name": "人材開発課",
                                                        "order": 0,
                                                        "is_active": 1,
                                                        "is_authority": 0,
                                                        "created_at": 1611740634,
                                                        "updated_at": 1611740634,
                                                        "attributes": [],
                                                        "children": []
                                                    },
                                                    {
                                                        "uuid": "2c232717-6084-11eb-9032-0242ac150002",
                                                        "name": "秘書課",
                                                        "order": 0,
                                                        "is_active": 1,
                                                        "is_authority": 0,
                                                        "created_at": 1611740635,
                                                        "updated_at": 1611740635,
                                                        "attributes": [],
                                                        "children": []
                                                    },
                                                    {
                                                        "uuid": "2ca5aa4e-6084-11eb-9032-0242ac150002",
                                                        "name": "総務課",
                                                        "order": 0,
                                                        "is_active": 1,
                                                        "is_authority": 0,
                                                        "created_at": 1611740636,
                                                        "updated_at": 1611740636,
                                                        "attributes": [],
                                                        "children": []
                                                    },
                                                    {
                                                        "uuid": "2cac3d74-6084-11eb-9032-0242ac150002",
                                                        "name": "給与・福利厚生課",
                                                        "order": 0,
                                                        "is_active": 1,
                                                        "is_authority": 0,
                                                        "created_at": 1611740636,
                                                        "updated_at": 1611740636,
                                                        "attributes": [],
                                                        "children": []
                                                    },
                                                    {
                                                        "uuid": "2d007481-6084-11eb-9032-0242ac150002",
                                                        "name": "安全管理課",
                                                        "order": 0,
                                                        "is_active": 1,
                                                        "is_authority": 0,
                                                        "created_at": 1611740637,
                                                        "updated_at": 1611740637,
                                                        "attributes": [],
                                                        "children": []
                                                    },
                                                    {
                                                        "uuid": "2d10eb1d-6084-11eb-9032-0242ac150002",
                                                        "name": "総務局",
                                                        "order": 0,
                                                        "is_active": 1,
                                                        "is_authority": 0,
                                                        "created_at": 1611740637,
                                                        "updated_at": 1611740637,
                                                        "attributes": [],
                                                        "children": []
                                                    }
                                                ]
                                            },
                                            {
                                                "uuid": "2b713771-6084-11eb-9032-0242ac150002",
                                                "name": "総合企画室",
                                                "order": 0,
                                                "is_active": 1,
                                                "is_authority": 0,
                                                "created_at": 1611740634,
                                                "updated_at": 1611740634,
                                                "attributes": [],
                                                "children": [
                                                    {
                                                        "uuid": "2b71ea75-6084-11eb-9032-0242ac150002",
                                                        "name": "総合企画室",
                                                        "order": 0,
                                                        "is_active": 1,
                                                        "is_authority": 0,
                                                        "created_at": 1611740634,
                                                        "updated_at": 1611740634,
                                                        "attributes": [],
                                                        "children": []
                                                    },
                                                    {
                                                        "uuid": "2cd841f4-6084-11eb-9032-0242ac150002",
                                                        "name": "企画管理課",
                                                        "order": 0,
                                                        "is_active": 1,
                                                        "is_authority": 0,
                                                        "created_at": 1611740636,
                                                        "updated_at": 1611740636,
                                                        "attributes": [],
                                                        "children": []
                                                    },
                                                    {
                                                        "uuid": "2d37d468-6084-11eb-9032-0242ac150002",
                                                        "name": "広報課",
                                                        "order": 0,
                                                        "is_active": 1,
                                                        "is_authority": 0,
                                                        "created_at": 1611740637,
                                                        "updated_at": 1611740637,
                                                        "attributes": [],
                                                        "children": []
                                                    }
                                                ]
                                            },
                                            {
                                                "uuid": "2b9cc17f-6084-11eb-9032-0242ac150002",
                                                "name": "財務局",
                                                "order": 0,
                                                "is_active": 1,
                                                "is_authority": 0,
                                                "created_at": 1611740634,
                                                "updated_at": 1611740634,
                                                "attributes": [],
                                                "children": [
                                                    {
                                                        "uuid": "2b9d8173-6084-11eb-9032-0242ac150002",
                                                        "name": "財務局",
                                                        "order": 0,
                                                        "is_active": 1,
                                                        "is_authority": 0,
                                                        "created_at": 1611740634,
                                                        "updated_at": 1611740634,
                                                        "attributes": [],
                                                        "children": []
                                                    },
                                                    {
                                                        "uuid": "2bd54c78-6084-11eb-9032-0242ac150002",
                                                        "name": "出納課",
                                                        "order": 0,
                                                        "is_active": 1,
                                                        "is_authority": 0,
                                                        "created_at": 1611740635,
                                                        "updated_at": 1611740635,
                                                        "attributes": [],
                                                        "children": []
                                                    },
                                                    {
                                                        "uuid": "2c4bd24d-6084-11eb-9032-0242ac150002",
                                                        "name": "財務課",
                                                        "order": 0,
                                                        "is_active": 1,
                                                        "is_authority": 0,
                                                        "created_at": 1611740636,
                                                        "updated_at": 1611740636,
                                                        "attributes": [],
                                                        "children": []
                                                    }
                                                ]
                                            },
                                            {
                                                "uuid": "2bceb1df-6084-11eb-9032-0242ac150002",
                                                "name": "梅田事務局",
                                                "order": 0,
                                                "is_active": 1,
                                                "is_authority": 0,
                                                "created_at": 1611740635,
                                                "updated_at": 1611740635,
                                                "attributes": [],
                                                "children": [
                                                    {
                                                        "uuid": "2bcf8323-6084-11eb-9032-0242ac150002",
                                                        "name": "梅田キャンパス事務室",
                                                        "order": 0,
                                                        "is_active": 1,
                                                        "is_authority": 0,
                                                        "created_at": 1611740635,
                                                        "updated_at": 1611740635,
                                                        "attributes": [],
                                                        "children": []
                                                    },
                                                    {
                                                        "uuid": "2d2265f6-6084-11eb-9032-0242ac150002",
                                                        "name": "梅田事務局",
                                                        "order": 0,
                                                        "is_active": 1,
                                                        "is_authority": 0,
                                                        "created_at": 1611740637,
                                                        "updated_at": 1611740637,
                                                        "attributes": [],
                                                        "children": []
                                                    }
                                                ]
                                            },
                                            {
                                                "uuid": "2c48119d-6084-11eb-9032-0242ac150002",
                                                "name": "理事長付",
                                                "order": 0,
                                                "is_active": 1,
                                                "is_authority": 0,
                                                "created_at": 1611740635,
                                                "updated_at": 1611740635,
                                                "attributes": [],
                                                "children": []
                                            },
                                            {
                                                "uuid": "2c81e773-6084-11eb-9032-0242ac150002",
                                                "name": "法人本部",
                                                "order": 0,
                                                "is_active": 1,
                                                "is_authority": 0,
                                                "created_at": 1611740636,
                                                "updated_at": 1611740636,
                                                "attributes": [],
                                                "children": []
                                            }
                                        ]
                                    },
                                    {
                                        "uuid": "2b63e7d9-6084-11eb-9032-0242ac150002",
                                        "name": "関大パンセ",
                                        "order": 0,
                                        "is_active": 1,
                                        "is_authority": 0,
                                        "created_at": 1611740634,
                                        "updated_at": 1611740634,
                                        "attributes": [],
                                        "children": []
                                    },
                                    {
                                        "uuid": "2b6d7e5f-6084-11eb-9032-0242ac150002",
                                        "name": "大学本部",
                                        "order": 0,
                                        "is_active": 1,
                                        "is_authority": 0,
                                        "created_at": 1611740634,
                                        "updated_at": 1611740634,
                                        "attributes": [],
                                        "children": [
                                            {
                                                "uuid": "2b6df6d3-6084-11eb-9032-0242ac150002",
                                                "name": "学術情報事務局",
                                                "order": 0,
                                                "is_active": 1,
                                                "is_authority": 0,
                                                "created_at": 1611740634,
                                                "updated_at": 1611740634,
                                                "attributes": [],
                                                "children": [
                                                    {
                                                        "uuid": "2b6e7014-6084-11eb-9032-0242ac150002",
                                                        "name": "出版部出版課",
                                                        "order": 0,
                                                        "is_active": 1,
                                                        "is_authority": 0,
                                                        "created_at": 1611740634,
                                                        "updated_at": 1611740634,
                                                        "attributes": [],
                                                        "children": []
                                                    },
                                                    {
                                                        "uuid": "2b82095a-6084-11eb-9032-0242ac150002",
                                                        "name": "情報基盤グループ",
                                                        "order": 0,
                                                        "is_active": 1,
                                                        "is_authority": 0,
                                                        "created_at": 1611740634,
                                                        "updated_at": 1611740634,
                                                        "attributes": [],
                                                        "children": []
                                                    },
                                                    {
                                                        "uuid": "2c77b6bd-6084-11eb-9032-0242ac150002",
                                                        "name": "情報推進グループ",
                                                        "order": 0,
                                                        "is_active": 1,
                                                        "is_authority": 0,
                                                        "created_at": 1611740636,
                                                        "updated_at": 1611740636,
                                                        "attributes": [],
                                                        "children": []
                                                    },
                                                    {
                                                        "uuid": "2cc674ef-6084-11eb-9032-0242ac150002",
                                                        "name": "博物館事務室",
                                                        "order": 0,
                                                        "is_active": 1,
                                                        "is_authority": 0,
                                                        "created_at": 1611740636,
                                                        "updated_at": 1611740636,
                                                        "attributes": [],
                                                        "children": []
                                                    },
                                                    {
                                                        "uuid": "2cf77a44-6084-11eb-9032-0242ac150002",
                                                        "name": "学術情報事務局",
                                                        "order": 0,
                                                        "is_active": 1,
                                                        "is_authority": 0,
                                                        "created_at": 1611740637,
                                                        "updated_at": 1611740637,
                                                        "attributes": [],
                                                        "children": []
                                                    },
                                                    {
                                                        "uuid": "2d543a8c-6084-11eb-9032-0242ac150002",
                                                        "name": "図書館事務室",
                                                        "order": 0,
                                                        "is_active": 1,
                                                        "is_authority": 0,
                                                        "created_at": 1611740637,
                                                        "updated_at": 1611740637,
                                                        "attributes": [],
                                                        "children": []
                                                    }
                                                ]
                                            },
                                            {
                                                "uuid": "2b77c9d1-6084-11eb-9032-0242ac150002",
                                                "name": "高槻事務局",
                                                "order": 0,
                                                "is_active": 1,
                                                "is_authority": 0,
                                                "created_at": 1611740634,
                                                "updated_at": 1611740634,
                                                "attributes": [],
                                                "children": [
                                                    {
                                                        "uuid": "2b78c1d4-6084-11eb-9032-0242ac150002",
                                                        "name": "高槻キャンパス事務グループ（総合情報学部事務チーム）",
                                                        "order": 0,
                                                        "is_active": 1,
                                                        "is_authority": 0,
                                                        "created_at": 1611740634,
                                                        "updated_at": 1611740634,
                                                        "attributes": [],
                                                        "children": []
                                                    },
                                                    {
                                                        "uuid": "2bc185ea-6084-11eb-9032-0242ac150002",
                                                        "name": "高槻ミューズキャンパス事務グループ",
                                                        "order": 0,
                                                        "is_active": 1,
                                                        "is_authority": 0,
                                                        "created_at": 1611740635,
                                                        "updated_at": 1611740635,
                                                        "attributes": [],
                                                        "children": []
                                                    },
                                                    {
                                                        "uuid": "2be3a7c9-6084-11eb-9032-0242ac150002",
                                                        "name": "高槻キャンパス事務グループ(高槻キャンパス事務チーム)",
                                                        "order": 0,
                                                        "is_active": 1,
                                                        "is_authority": 0,
                                                        "created_at": 1611740635,
                                                        "updated_at": 1611740635,
                                                        "attributes": [],
                                                        "children": []
                                                    },
                                                    {
                                                        "uuid": "2bf9ac59-6084-11eb-9032-0242ac150002",
                                                        "name": "高槻事務局",
                                                        "order": 0,
                                                        "is_active": 1,
                                                        "is_authority": 0,
                                                        "created_at": 1611740635,
                                                        "updated_at": 1611740635,
                                                        "attributes": [],
                                                        "children": []
                                                    }
                                                ]
                                            },
                                            {
                                                "uuid": "2b7b0bfb-6084-11eb-9032-0242ac150002",
                                                "name": "学生サービス事務局",
                                                "order": 0,
                                                "is_active": 1,
                                                "is_authority": 0,
                                                "created_at": 1611740634,
                                                "updated_at": 1611740634,
                                                "attributes": [],
                                                "children": [
                                                    {
                                                        "uuid": "2b7b9bab-6084-11eb-9032-0242ac150002",
                                                        "name": "ボランティア活動支援グループ",
                                                        "order": 0,
                                                        "is_active": 1,
                                                        "is_authority": 0,
                                                        "created_at": 1611740634,
                                                        "updated_at": 1611740634,
                                                        "attributes": [],
                                                        "children": []
                                                    },
                                                    {
                                                        "uuid": "2b882fda-6084-11eb-9032-0242ac150002",
                                                        "name": "スポーツ振興グループ",
                                                        "order": 0,
                                                        "is_active": 1,
                                                        "is_authority": 0,
                                                        "created_at": 1611740634,
                                                        "updated_at": 1611740634,
                                                        "attributes": [],
                                                        "children": []
                                                    },
                                                    {
                                                        "uuid": "2bc78757-6084-11eb-9032-0242ac150002",
                                                        "name": "保健管理センター事務室",
                                                        "order": 0,
                                                        "is_active": 1,
                                                        "is_authority": 0,
                                                        "created_at": 1611740635,
                                                        "updated_at": 1611740635,
                                                        "attributes": [],
                                                        "children": []
                                                    },
                                                    {
                                                        "uuid": "2bcb57d8-6084-11eb-9032-0242ac150002",
                                                        "name": "学生サービス事務局",
                                                        "order": 0,
                                                        "is_active": 1,
                                                        "is_authority": 0,
                                                        "created_at": 1611740635,
                                                        "updated_at": 1611740635,
                                                        "attributes": [],
                                                        "children": []
                                                    },
                                                    {
                                                        "uuid": "2be7404c-6084-11eb-9032-0242ac150002",
                                                        "name": "学生生活支援グループ",
                                                        "order": 0,
                                                        "is_active": 1,
                                                        "is_authority": 0,
                                                        "created_at": 1611740635,
                                                        "updated_at": 1611740635,
                                                        "attributes": [],
                                                        "children": []
                                                    },
                                                    {
                                                        "uuid": "2c9fcf64-6084-11eb-9032-0242ac150002",
                                                        "name": "奨学支援グループ",
                                                        "order": 0,
                                                        "is_active": 1,
                                                        "is_authority": 0,
                                                        "created_at": 1611740636,
                                                        "updated_at": 1611740636,
                                                        "attributes": [],
                                                        "children": []
                                                    }
                                                ]
                                            },
                                            {
                                                "uuid": "2b84c714-6084-11eb-9032-0242ac150002",
                                                "name": "キャリアセンター事務局",
                                                "order": 0,
                                                "is_active": 1,
                                                "is_authority": 0,
                                                "created_at": 1611740634,
                                                "updated_at": 1611740634,
                                                "attributes": [],
                                                "children": [
                                                    {
                                                        "uuid": "2b856649-6084-11eb-9032-0242ac150002",
                                                        "name": "キャリアセンター事務局",
                                                        "order": 0,
                                                        "is_active": 1,
                                                        "is_authority": 0,
                                                        "created_at": 1611740634,
                                                        "updated_at": 1611740634,
                                                        "attributes": [],
                                                        "children": []
                                                    },
                                                    {
                                                        "uuid": "2bc53e8a-6084-11eb-9032-0242ac150002",
                                                        "name": "エクステンション・リードセンター事務グループ",
                                                        "order": 0,
                                                        "is_active": 1,
                                                        "is_authority": 0,
                                                        "created_at": 1611740635,
                                                        "updated_at": 1611740635,
                                                        "attributes": [],
                                                        "children": []
                                                    },
                                                    {
                                                        "uuid": "2c5dee77-6084-11eb-9032-0242ac150002",
                                                        "name": "キャリアセンター事務グループ",
                                                        "order": 0,
                                                        "is_active": 1,
                                                        "is_authority": 0,
                                                        "created_at": 1611740636,
                                                        "updated_at": 1611740636,
                                                        "attributes": [],
                                                        "children": []
                                                    },
                                                    {
                                                        "uuid": "2cf2d47b-6084-11eb-9032-0242ac150002",
                                                        "name": "キャリアセンター理工系事務グループ",
                                                        "order": 0,
                                                        "is_active": 1,
                                                        "is_authority": 0,
                                                        "created_at": 1611740637,
                                                        "updated_at": 1611740637,
                                                        "attributes": [],
                                                        "children": []
                                                    }
                                                ]
                                            },
                                            {
                                                "uuid": "2b8da7a3-6084-11eb-9032-0242ac150002",
                                                "name": "大学本部付",
                                                "order": 0,
                                                "is_active": 1,
                                                "is_authority": 0,
                                                "created_at": 1611740634,
                                                "updated_at": 1611740634,
                                                "attributes": [],
                                                "children": [
                                                    {
                                                        "uuid": "2b8e4527-6084-11eb-9032-0242ac150002",
                                                        "name": "大学本部付",
                                                        "order": 0,
                                                        "is_active": 1,
                                                        "is_authority": 0,
                                                        "created_at": 1611740634,
                                                        "updated_at": 1611740634,
                                                        "attributes": [],
                                                        "children": []
                                                    }
                                                ]
                                            },
                                            {
                                                "uuid": "2ba1cfa8-6084-11eb-9032-0242ac150002",
                                                "name": "研究推進・社会連携事務局",
                                                "order": 0,
                                                "is_active": 1,
                                                "is_authority": 0,
                                                "created_at": 1611740634,
                                                "updated_at": 1611740634,
                                                "attributes": [],
                                                "children": [
                                                    {
                                                        "uuid": "2ba26d13-6084-11eb-9032-0242ac150002",
                                                        "name": "研究所事務グループ",
                                                        "order": 0,
                                                        "is_active": 1,
                                                        "is_authority": 0,
                                                        "created_at": 1611740634,
                                                        "updated_at": 1611740634,
                                                        "attributes": [],
                                                        "children": []
                                                    },
                                                    {
                                                        "uuid": "2ba5f6d7-6084-11eb-9032-0242ac150002",
                                                        "name": "研究推進・社会連携事務局",
                                                        "order": 0,
                                                        "is_active": 1,
                                                        "is_authority": 0,
                                                        "created_at": 1611740634,
                                                        "updated_at": 1611740634,
                                                        "attributes": [],
                                                        "children": []
                                                    },
                                                    {
                                                        "uuid": "2c1a3b06-6084-11eb-9032-0242ac150002",
                                                        "name": "地域連携・社会連携グループ",
                                                        "order": 0,
                                                        "is_active": 1,
                                                        "is_authority": 0,
                                                        "created_at": 1611740635,
                                                        "updated_at": 1611740635,
                                                        "attributes": [],
                                                        "children": []
                                                    },
                                                    {
                                                        "uuid": "2c2928a3-6084-11eb-9032-0242ac150002",
                                                        "name": "研究支援・社会連携事務グループ",
                                                        "order": 0,
                                                        "is_active": 1,
                                                        "is_authority": 0,
                                                        "created_at": 1611740635,
                                                        "updated_at": 1611740635,
                                                        "attributes": [],
                                                        "children": []
                                                    },
                                                    {
                                                        "uuid": "2d4c8e98-6084-11eb-9032-0242ac150002",
                                                        "name": "先端科学技術推進機構グループ",
                                                        "order": 0,
                                                        "is_active": 1,
                                                        "is_authority": 0,
                                                        "created_at": 1611740637,
                                                        "updated_at": 1611740637,
                                                        "attributes": [],
                                                        "children": []
                                                    }
                                                ]
                                            },
                                            {
                                                "uuid": "2ba8dfb4-6084-11eb-9032-0242ac150002",
                                                "name": "学長室",
                                                "order": 0,
                                                "is_active": 1,
                                                "is_authority": 0,
                                                "created_at": 1611740634,
                                                "updated_at": 1611740634,
                                                "attributes": [],
                                                "children": [
                                                    {
                                                        "uuid": "2baa05a1-6084-11eb-9032-0242ac150002",
                                                        "name": "学長課",
                                                        "order": 0,
                                                        "is_active": 1,
                                                        "is_authority": 0,
                                                        "created_at": 1611740634,
                                                        "updated_at": 1611740634,
                                                        "attributes": [],
                                                        "children": []
                                                    },
                                                    {
                                                        "uuid": "2d1ba2dd-6084-11eb-9032-0242ac150002",
                                                        "name": "学長室",
                                                        "order": 0,
                                                        "is_active": 1,
                                                        "is_authority": 0,
                                                        "created_at": 1611740637,
                                                        "updated_at": 1611740637,
                                                        "attributes": [],
                                                        "children": []
                                                    }
                                                ]
                                            },
                                            {
                                                "uuid": "2bee4f90-6084-11eb-9032-0242ac150002",
                                                "name": "国際事務局",
                                                "order": 0,
                                                "is_active": 1,
                                                "is_authority": 0,
                                                "created_at": 1611740635,
                                                "updated_at": 1611740635,
                                                "attributes": [],
                                                "children": [
                                                    {
                                                        "uuid": "2beef788-6084-11eb-9032-0242ac150002",
                                                        "name": "国際連携グループ",
                                                        "order": 0,
                                                        "is_active": 1,
                                                        "is_authority": 0,
                                                        "created_at": 1611740635,
                                                        "updated_at": 1611740635,
                                                        "attributes": [],
                                                        "children": []
                                                    },
                                                    {
                                                        "uuid": "2c9b1804-6084-11eb-9032-0242ac150002",
                                                        "name": "国際事務局",
                                                        "order": 0,
                                                        "is_active": 1,
                                                        "is_authority": 0,
                                                        "created_at": 1611740636,
                                                        "updated_at": 1611740636,
                                                        "attributes": [],
                                                        "children": []
                                                    },
                                                    {
                                                        "uuid": "2ce7853e-6084-11eb-9032-0242ac150002",
                                                        "name": "国際教育グループ",
                                                        "order": 0,
                                                        "is_active": 1,
                                                        "is_authority": 0,
                                                        "created_at": 1611740637,
                                                        "updated_at": 1611740637,
                                                        "attributes": [],
                                                        "children": []
                                                    },
                                                    {
                                                        "uuid": "2d6bbdba-6084-11eb-9032-0242ac150002",
                                                        "name": "国際プラザグループ",
                                                        "order": 0,
                                                        "is_active": 1,
                                                        "is_authority": 0,
                                                        "created_at": 1611740637,
                                                        "updated_at": 1611740637,
                                                        "attributes": [],
                                                        "children": []
                                                    }
                                                ]
                                            },
                                            {
                                                "uuid": "2bf3da2f-6084-11eb-9032-0242ac150002",
                                                "name": "学事局",
                                                "order": 0,
                                                "is_active": 1,
                                                "is_authority": 0,
                                                "created_at": 1611740635,
                                                "updated_at": 1611740635,
                                                "attributes": [],
                                                "children": [
                                                    {
                                                        "uuid": "2bf490c5-6084-11eb-9032-0242ac150002",
                                                        "name": "学部・大学院事務グループ（理工系事務チーム）",
                                                        "order": 0,
                                                        "is_active": 1,
                                                        "is_authority": 0,
                                                        "created_at": 1611740635,
                                                        "updated_at": 1611740635,
                                                        "attributes": [],
                                                        "children": []
                                                    },
                                                    {
                                                        "uuid": "2c0fa5d9-6084-11eb-9032-0242ac150002",
                                                        "name": "教務事務グループ（学び直し）",
                                                        "order": 0,
                                                        "is_active": 1,
                                                        "is_authority": 0,
                                                        "created_at": 1611740635,
                                                        "updated_at": 1611740635,
                                                        "attributes": [],
                                                        "children": []
                                                    },
                                                    {
                                                        "uuid": "2c2cd41a-6084-11eb-9032-0242ac150002",
                                                        "name": "教務事務グループ（教職支援チーム）",
                                                        "order": 0,
                                                        "is_active": 1,
                                                        "is_authority": 0,
                                                        "created_at": 1611740635,
                                                        "updated_at": 1611740635,
                                                        "attributes": [],
                                                        "children": []
                                                    },
                                                    {
                                                        "uuid": "2c3a5877-6084-11eb-9032-0242ac150002",
                                                        "name": "教務事務グループ（庶務チーム）",
                                                        "order": 0,
                                                        "is_active": 1,
                                                        "is_authority": 0,
                                                        "created_at": 1611740635,
                                                        "updated_at": 1611740635,
                                                        "attributes": [],
                                                        "children": []
                                                    },
                                                    {
                                                        "uuid": "2c407f17-6084-11eb-9032-0242ac150002",
                                                        "name": "教務事務グループ（教育開発支援室・教学IR室）",
                                                        "order": 0,
                                                        "is_active": 1,
                                                        "is_authority": 0,
                                                        "created_at": 1611740635,
                                                        "updated_at": 1611740635,
                                                        "attributes": [],
                                                        "children": []
                                                    },
                                                    {
                                                        "uuid": "2c52cc3f-6084-11eb-9032-0242ac150002",
                                                        "name": "教務事務グループ（テクノサポートチーム）",
                                                        "order": 0,
                                                        "is_active": 1,
                                                        "is_authority": 0,
                                                        "created_at": 1611740636,
                                                        "updated_at": 1611740636,
                                                        "attributes": [],
                                                        "children": []
                                                    },
                                                    {
                                                        "uuid": "2c56f0b6-6084-11eb-9032-0242ac150002",
                                                        "name": "学生相談・支援センター事務グループ",
                                                        "order": 0,
                                                        "is_active": 1,
                                                        "is_authority": 0,
                                                        "created_at": 1611740636,
                                                        "updated_at": 1611740636,
                                                        "attributes": [],
                                                        "children": []
                                                    },
                                                    {
                                                        "uuid": "2c69088e-6084-11eb-9032-0242ac150002",
                                                        "name": "学部・大学院事務グループ（法科大学院チーム）",
                                                        "order": 0,
                                                        "is_active": 1,
                                                        "is_authority": 0,
                                                        "created_at": 1611740636,
                                                        "updated_at": 1611740636,
                                                        "attributes": [],
                                                        "children": []
                                                    },
                                                    {
                                                        "uuid": "2c7266e3-6084-11eb-9032-0242ac150002",
                                                        "name": "学事局",
                                                        "order": 0,
                                                        "is_active": 1,
                                                        "is_authority": 0,
                                                        "created_at": 1611740636,
                                                        "updated_at": 1611740636,
                                                        "attributes": [],
                                                        "children": []
                                                    },
                                                    {
                                                        "uuid": "2cd09422-6084-11eb-9032-0242ac150002",
                                                        "name": "学部・大学院事務グループ（経商社事務チーム）",
                                                        "order": 0,
                                                        "is_active": 1,
                                                        "is_authority": 0,
                                                        "created_at": 1611740636,
                                                        "updated_at": 1611740636,
                                                        "attributes": [],
                                                        "children": []
                                                    },
                                                    {
                                                        "uuid": "2d05d8b6-6084-11eb-9032-0242ac150002",
                                                        "name": "教務事務グループ（授業支援チーム）",
                                                        "order": 0,
                                                        "is_active": 1,
                                                        "is_authority": 0,
                                                        "created_at": 1611740637,
                                                        "updated_at": 1611740637,
                                                        "attributes": [],
                                                        "children": []
                                                    },
                                                    {
                                                        "uuid": "2d16b0f7-6084-11eb-9032-0242ac150002",
                                                        "name": "学部・大学院事務グループ（法文事務チーム）",
                                                        "order": 0,
                                                        "is_active": 1,
                                                        "is_authority": 0,
                                                        "created_at": 1611740637,
                                                        "updated_at": 1611740637,
                                                        "attributes": [],
                                                        "children": []
                                                    },
                                                    {
                                                        "uuid": "2d3d4a78-6084-11eb-9032-0242ac150002",
                                                        "name": "教務事務グループ（教務事務チーム）",
                                                        "order": 0,
                                                        "is_active": 1,
                                                        "is_authority": 0,
                                                        "created_at": 1611740637,
                                                        "updated_at": 1611740637,
                                                        "attributes": [],
                                                        "children": []
                                                    },
                                                    {
                                                        "uuid": "2d46431f-6084-11eb-9032-0242ac150002",
                                                        "name": "学部・大学院事務グループ（心理・会計チーム）",
                                                        "order": 0,
                                                        "is_active": 1,
                                                        "is_authority": 0,
                                                        "created_at": 1611740637,
                                                        "updated_at": 1611740637,
                                                        "attributes": [],
                                                        "children": []
                                                    },
                                                    {
                                                        "uuid": "2d5c646c-6084-11eb-9032-0242ac150002",
                                                        "name": "学部・大学院事務グループ（政外事務チーム）",
                                                        "order": 0,
                                                        "is_active": 1,
                                                        "is_authority": 0,
                                                        "created_at": 1611740637,
                                                        "updated_at": 1611740637,
                                                        "attributes": [],
                                                        "children": []
                                                    }
                                                ]
                                            },
                                            {
                                                "uuid": "2c03d9a2-6084-11eb-9032-0242ac150002",
                                                "name": "堺事務局",
                                                "order": 0,
                                                "is_active": 1,
                                                "is_authority": 0,
                                                "created_at": 1611740635,
                                                "updated_at": 1611740635,
                                                "attributes": [],
                                                "children": [
                                                    {
                                                        "uuid": "2c0487e0-6084-11eb-9032-0242ac150002",
                                                        "name": "堺キャンパス事務室",
                                                        "order": 0,
                                                        "is_active": 1,
                                                        "is_authority": 0,
                                                        "created_at": 1611740635,
                                                        "updated_at": 1611740635,
                                                        "attributes": [],
                                                        "children": []
                                                    },
                                                    {
                                                        "uuid": "2cdfce4f-6084-11eb-9032-0242ac150002",
                                                        "name": "堺事務局",
                                                        "order": 0,
                                                        "is_active": 1,
                                                        "is_authority": 0,
                                                        "created_at": 1611740636,
                                                        "updated_at": 1611740636,
                                                        "attributes": [],
                                                        "children": []
                                                    }
                                                ]
                                            },
                                            {
                                                "uuid": "2cba185f-6084-11eb-9032-0242ac150002",
                                                "name": "入試事務局",
                                                "order": 0,
                                                "is_active": 1,
                                                "is_authority": 0,
                                                "created_at": 1611740636,
                                                "updated_at": 1611740636,
                                                "attributes": [],
                                                "children": [
                                                    {
                                                        "uuid": "2cbba93c-6084-11eb-9032-0242ac150002",
                                                        "name": "入試事務局",
                                                        "order": 0,
                                                        "is_active": 1,
                                                        "is_authority": 0,
                                                        "created_at": 1611740636,
                                                        "updated_at": 1611740636,
                                                        "attributes": [],
                                                        "children": []
                                                    },
                                                    {
                                                        "uuid": "2d27c6db-6084-11eb-9032-0242ac150002",
                                                        "name": "大学院入試グループ",
                                                        "order": 0,
                                                        "is_active": 1,
                                                        "is_authority": 0,
                                                        "created_at": 1611740637,
                                                        "updated_at": 1611740637,
                                                        "attributes": [],
                                                        "children": []
                                                    },
                                                    {
                                                        "uuid": "2d331297-6084-11eb-9032-0242ac150002",
                                                        "name": "入試・高大接続グループ",
                                                        "order": 0,
                                                        "is_active": 1,
                                                        "is_authority": 0,
                                                        "created_at": 1611740637,
                                                        "updated_at": 1611740637,
                                                        "attributes": [],
                                                        "children": []
                                                    },
                                                    {
                                                        "uuid": "2d764d52-6084-11eb-9032-0242ac150002",
                                                        "name": "入試広報グループ",
                                                        "order": 0,
                                                        "is_active": 1,
                                                        "is_authority": 0,
                                                        "created_at": 1611740637,
                                                        "updated_at": 1611740637,
                                                        "attributes": [],
                                                        "children": []
                                                    }
                                                ]
                                            },
                                            {
                                                "uuid": "2cee2e21-6084-11eb-9032-0242ac150002",
                                                "name": "大学本部",
                                                "order": 0,
                                                "is_active": 1,
                                                "is_authority": 0,
                                                "created_at": 1611740637,
                                                "updated_at": 1611740637,
                                                "attributes": [],
                                                "children": []
                                            }
                                        ]
                                    },
                                    {
                                        "uuid": "2bdaf8c0-6084-11eb-9032-0242ac150002",
                                        "name": "教育後援会事務局",
                                        "order": 0,
                                        "is_active": 1,
                                        "is_authority": 0,
                                        "created_at": 1611740635,
                                        "updated_at": 1611740635,
                                        "attributes": [],
                                        "children": []
                                    },
                                    {
                                        "uuid": "2c3085e6-6084-11eb-9032-0242ac150002",
                                        "name": "校友会事務局",
                                        "order": 0,
                                        "is_active": 1,
                                        "is_authority": 0,
                                        "created_at": 1611740635,
                                        "updated_at": 1611740635,
                                        "attributes": [],
                                        "children": []
                                    },
                                    {
                                        "uuid": "2c7d1a1a-6084-11eb-9032-0242ac150002",
                                        "name": "内部監査室",
                                        "order": 0,
                                        "is_active": 1,
                                        "is_authority": 0,
                                        "created_at": 1611740636,
                                        "updated_at": 1611740636,
                                        "attributes": [],
                                        "children": []
                                    }
                                ]
                            },
                            {
                                "uuid": "2bafa159-6084-11eb-9032-0242ac150002",
                                "name": "資格",
                                "order": 0,
                                "is_active": 1,
                                "is_authority": 0,
                                "created_at": 1611740634,
                                "updated_at": 1611740634,
                                "attributes": [],
                                "children": [
                                    {
                                        "uuid": "2bb05aa3-6084-11eb-9032-0242ac150002",
                                        "name": "その他職員",
                                        "order": 0,
                                        "is_active": 1,
                                        "is_authority": 0,
                                        "created_at": 1611740634,
                                        "updated_at": 1611740634,
                                        "attributes": [],
                                        "children": []
                                    },
                                    {
                                        "uuid": "2bb7f1ed-6084-11eb-9032-0242ac150002",
                                        "name": "定時事務職員",
                                        "order": 0,
                                        "is_active": 1,
                                        "is_authority": 0,
                                        "created_at": 1611740635,
                                        "updated_at": 1611740635,
                                        "attributes": [],
                                        "children": []
                                    },
                                    {
                                        "uuid": "2c001103-6084-11eb-9032-0242ac150002",
                                        "name": "専任職員・常勤職員",
                                        "order": 0,
                                        "is_active": 1,
                                        "is_authority": 0,
                                        "created_at": 1611740635,
                                        "updated_at": 1611740635,
                                        "attributes": [],
                                        "children": []
                                    },
                                    {
                                        "uuid": "2cb5745c-6084-11eb-9032-0242ac150002",
                                        "name": "役員",
                                        "order": 0,
                                        "is_active": 1,
                                        "is_authority": 0,
                                        "created_at": 1611740636,
                                        "updated_at": 1611740636,
                                        "attributes": [],
                                        "children": []
                                    }
                                ]
                            }
                        ]
                    },
                    {
                        "uuid": "2b67f242-6084-11eb-9032-0242ac150002",
                        "name": "学生",
                        "order": 0,
                        "is_active": 1,
                        "is_authority": 0,
                        "created_at": 1611740634,
                        "updated_at": 1611740634,
                        "attributes": [],
                        "children": [
                            {
                                "uuid": "2b68be0b-6084-11eb-9032-0242ac150002",
                                "name": "学年",
                                "order": 0,
                                "is_active": 1,
                                "is_authority": 0,
                                "created_at": 1611740634,
                                "updated_at": 1611740634,
                                "attributes": [],
                                "children": [
                                    {
                                        "uuid": "2b69698d-6084-11eb-9032-0242ac150002",
                                        "name": "4年生",
                                        "order": 0,
                                        "is_active": 1,
                                        "is_authority": 0,
                                        "created_at": 1611740634,
                                        "updated_at": 1611740634,
                                        "attributes": [],
                                        "children": []
                                    },
                                    {
                                        "uuid": "2bb26e84-6084-11eb-9032-0242ac150002",
                                        "name": "2年生",
                                        "order": 0,
                                        "is_active": 1,
                                        "is_authority": 0,
                                        "created_at": 1611740634,
                                        "updated_at": 1611740634,
                                        "attributes": [],
                                        "children": []
                                    },
                                    {
                                        "uuid": "2bb59513-6084-11eb-9032-0242ac150002",
                                        "name": "非正規生",
                                        "order": 0,
                                        "is_active": 1,
                                        "is_authority": 0,
                                        "created_at": 1611740635,
                                        "updated_at": 1611740635,
                                        "attributes": [],
                                        "children": []
                                    },
                                    {
                                        "uuid": "2c166528-6084-11eb-9032-0242ac150002",
                                        "name": "1年生",
                                        "order": 0,
                                        "is_active": 1,
                                        "is_authority": 0,
                                        "created_at": 1611740635,
                                        "updated_at": 1611740635,
                                        "attributes": [],
                                        "children": []
                                    },
                                    {
                                        "uuid": "2d5f622e-6084-11eb-9032-0242ac150002",
                                        "name": "3年生",
                                        "order": 0,
                                        "is_active": 1,
                                        "is_authority": 0,
                                        "created_at": 1611740637,
                                        "updated_at": 1611740637,
                                        "attributes": [],
                                        "children": []
                                    }
                                ]
                            },
                            {
                                "uuid": "2bbd735e-6084-11eb-9032-0242ac150002",
                                "name": "学生種別",
                                "order": 0,
                                "is_active": 1,
                                "is_authority": 0,
                                "created_at": 1611740635,
                                "updated_at": 1611740635,
                                "attributes": [],
                                "children": [
                                    {
                                        "uuid": "2bbe3495-6084-11eb-9032-0242ac150002",
                                        "name": "学部生",
                                        "order": 0,
                                        "is_active": 1,
                                        "is_authority": 0,
                                        "created_at": 1611740635,
                                        "updated_at": 1611740635,
                                        "attributes": [],
                                        "children": [
                                            {
                                                "uuid": "7d48e2ed-6b7e-11eb-ad0c-0242ac150002",
                                                "name": "法学部",
                                                "order": 1,
                                                "is_active": 1,
                                                "is_authority": 0,
                                                "created_at": 1612947657,
                                                "updated_at": 1612947659,
                                                "attributes": [],
                                                "children": [
                                                    {
                                                        "uuid": "7d5d3c02-6b7e-11eb-ad0c-0242ac150002",
                                                        "name": "法学政治学科",
                                                        "order": 1,
                                                        "is_active": 1,
                                                        "is_authority": 0,
                                                        "created_at": 1612947657,
                                                        "updated_at": 1612947659,
                                                        "attributes": [],
                                                        "children": []
                                                    }
                                                ]
                                            },
                                            {
                                                "uuid": "7d496aed-6b7e-11eb-ad0c-0242ac150002",
                                                "name": "文学部",
                                                "order": 2,
                                                "is_active": 1,
                                                "is_authority": 0,
                                                "created_at": 1612947657,
                                                "updated_at": 1612947659,
                                                "attributes": [],
                                                "children": [
                                                    {
                                                        "uuid": "7d5e12ea-6b7e-11eb-ad0c-0242ac150002",
                                                        "name": "総合人文学科",
                                                        "order": 1,
                                                        "is_active": 1,
                                                        "is_authority": 0,
                                                        "created_at": 1612947657,
                                                        "updated_at": 1612947659,
                                                        "attributes": [],
                                                        "children": []
                                                    }
                                                ]
                                            },
                                            {
                                                "uuid": "7d4a001e-6b7e-11eb-ad0c-0242ac150002",
                                                "name": "経済学部",
                                                "order": 3,
                                                "is_active": 1,
                                                "is_authority": 0,
                                                "created_at": 1612947657,
                                                "updated_at": 1612947659,
                                                "attributes": [],
                                                "children": [
                                                    {
                                                        "uuid": "7d5ea010-6b7e-11eb-ad0c-0242ac150002",
                                                        "name": "経済学科",
                                                        "order": 1,
                                                        "is_active": 1,
                                                        "is_authority": 0,
                                                        "created_at": 1612947657,
                                                        "updated_at": 1612947659,
                                                        "attributes": [],
                                                        "children": []
                                                    }
                                                ]
                                            },
                                            {
                                                "uuid": "7d4aa136-6b7e-11eb-ad0c-0242ac150002",
                                                "name": "商学部",
                                                "order": 4,
                                                "is_active": 1,
                                                "is_authority": 0,
                                                "created_at": 1612947657,
                                                "updated_at": 1612947659,
                                                "attributes": [],
                                                "children": [
                                                    {
                                                        "uuid": "7d5f16ec-6b7e-11eb-ad0c-0242ac150002",
                                                        "name": "商学科",
                                                        "order": 1,
                                                        "is_active": 1,
                                                        "is_authority": 0,
                                                        "created_at": 1612947657,
                                                        "updated_at": 1612947659,
                                                        "attributes": [],
                                                        "children": []
                                                    }
                                                ]
                                            },
                                            {
                                                "uuid": "7d4b27d9-6b7e-11eb-ad0c-0242ac150002",
                                                "name": "社会学部",
                                                "order": 5,
                                                "is_active": 1,
                                                "is_authority": 0,
                                                "created_at": 1612947657,
                                                "updated_at": 1612947659,
                                                "attributes": [],
                                                "children": [
                                                    {
                                                        "uuid": "7d5f9584-6b7e-11eb-ad0c-0242ac150002",
                                                        "name": "社会学科",
                                                        "order": 1,
                                                        "is_active": 1,
                                                        "is_authority": 0,
                                                        "created_at": 1612947657,
                                                        "updated_at": 1612947659,
                                                        "attributes": [],
                                                        "children": []
                                                    }
                                                ]
                                            },
                                            {
                                                "uuid": "7d4bc10b-6b7e-11eb-ad0c-0242ac150002",
                                                "name": "政策創造学部",
                                                "order": 6,
                                                "is_active": 1,
                                                "is_authority": 0,
                                                "created_at": 1612947657,
                                                "updated_at": 1612947659,
                                                "attributes": [],
                                                "children": [
                                                    {
                                                        "uuid": "7d607696-6b7e-11eb-ad0c-0242ac150002",
                                                        "name": "政策学科",
                                                        "order": 1,
                                                        "is_active": 1,
                                                        "is_authority": 0,
                                                        "created_at": 1612947657,
                                                        "updated_at": 1612947659,
                                                        "attributes": [],
                                                        "children": []
                                                    },
                                                    {
                                                        "uuid": "7d60da24-6b7e-11eb-ad0c-0242ac150002",
                                                        "name": "国際アジア法政策学科",
                                                        "order": 2,
                                                        "is_active": 1,
                                                        "is_authority": 0,
                                                        "created_at": 1612947657,
                                                        "updated_at": 1612947659,
                                                        "attributes": [],
                                                        "children": []
                                                    },
                                                    {
                                                        "uuid": "7d601196-6b7e-11eb-ad0c-0242ac150002",
                                                        "name": "国際アジア学科",
                                                        "order": 3,
                                                        "is_active": 1,
                                                        "is_authority": 0,
                                                        "created_at": 1612947657,
                                                        "updated_at": 1612947659,
                                                        "attributes": [],
                                                        "children": []
                                                    }
                                                ]
                                            },
                                            {
                                                "uuid": "7d4c4c47-6b7e-11eb-ad0c-0242ac150002",
                                                "name": "外国語学部",
                                                "order": 7,
                                                "is_active": 1,
                                                "is_authority": 0,
                                                "created_at": 1612947657,
                                                "updated_at": 1612947659,
                                                "attributes": [],
                                                "children": [
                                                    {
                                                        "uuid": "7d6170ac-6b7e-11eb-ad0c-0242ac150002",
                                                        "name": "外国語学科",
                                                        "order": 1,
                                                        "is_active": 1,
                                                        "is_authority": 0,
                                                        "created_at": 1612947657,
                                                        "updated_at": 1612947659,
                                                        "attributes": [],
                                                        "children": []
                                                    }
                                                ]
                                            },
                                            {
                                                "uuid": "7d485c5b-6b7e-11eb-ad0c-0242ac150002",
                                                "name": "人間健康学部",
                                                "order": 8,
                                                "is_active": 1,
                                                "is_authority": 0,
                                                "created_at": 1612947657,
                                                "updated_at": 1612947659,
                                                "attributes": [],
                                                "children": [
                                                    {
                                                        "uuid": "7d621421-6b7e-11eb-ad0c-0242ac150002",
                                                        "name": "人間健康学科",
                                                        "order": 1,
                                                        "is_active": 1,
                                                        "is_authority": 0,
                                                        "created_at": 1612947657,
                                                        "updated_at": 1612947659,
                                                        "attributes": [],
                                                        "children": []
                                                    }
                                                ]
                                            },
                                            {
                                                "uuid": "7d4ce651-6b7e-11eb-ad0c-0242ac150002",
                                                "name": "総合情報学部",
                                                "order": 9,
                                                "is_active": 1,
                                                "is_authority": 0,
                                                "created_at": 1612947657,
                                                "updated_at": 1612947659,
                                                "attributes": [],
                                                "children": [
                                                    {
                                                        "uuid": "7d633733-6b7e-11eb-ad0c-0242ac150002",
                                                        "name": "総合情報学科",
                                                        "order": 1,
                                                        "is_active": 1,
                                                        "is_authority": 0,
                                                        "created_at": 1612947657,
                                                        "updated_at": 1612947659,
                                                        "attributes": [],
                                                        "children": []
                                                    }
                                                ]
                                            },
                                            {
                                                "uuid": "7d47aaa0-6b7e-11eb-ad0c-0242ac150002",
                                                "name": "社会安全学部",
                                                "order": 10,
                                                "is_active": 1,
                                                "is_authority": 0,
                                                "created_at": 1612947657,
                                                "updated_at": 1612947659,
                                                "attributes": [],
                                                "children": [
                                                    {
                                                        "uuid": "7d6430ef-6b7e-11eb-ad0c-0242ac150002",
                                                        "name": "安全マネジメント学科",
                                                        "order": 1,
                                                        "is_active": 1,
                                                        "is_authority": 0,
                                                        "created_at": 1612947657,
                                                        "updated_at": 1612947659,
                                                        "attributes": [],
                                                        "children": []
                                                    }
                                                ]
                                            },
                                            {
                                                "uuid": "7d4d6d19-6b7e-11eb-ad0c-0242ac150002",
                                                "name": "システム理工学部",
                                                "order": 11,
                                                "is_active": 1,
                                                "is_authority": 0,
                                                "created_at": 1612947657,
                                                "updated_at": 1612947659,
                                                "attributes": [],
                                                "children": [
                                                    {
                                                        "uuid": "7d65deaa-6b7e-11eb-ad0c-0242ac150002",
                                                        "name": "数学科",
                                                        "order": 1,
                                                        "is_active": 1,
                                                        "is_authority": 0,
                                                        "created_at": 1612947657,
                                                        "updated_at": 1612947659,
                                                        "attributes": [],
                                                        "children": []
                                                    },
                                                    {
                                                        "uuid": "7d66fdf6-6b7e-11eb-ad0c-0242ac150002",
                                                        "name": "物理・応用物理学科",
                                                        "order": 2,
                                                        "is_active": 1,
                                                        "is_authority": 0,
                                                        "created_at": 1612947657,
                                                        "updated_at": 1612947659,
                                                        "attributes": [],
                                                        "children": []
                                                    },
                                                    {
                                                        "uuid": "7d67a00e-6b7e-11eb-ad0c-0242ac150002",
                                                        "name": "機械工学科",
                                                        "order": 3,
                                                        "is_active": 1,
                                                        "is_authority": 0,
                                                        "created_at": 1612947657,
                                                        "updated_at": 1612947659,
                                                        "attributes": [],
                                                        "children": []
                                                    },
                                                    {
                                                        "uuid": "7d6659ca-6b7e-11eb-ad0c-0242ac150002",
                                                        "name": "電気電子情報工学科",
                                                        "order": 4,
                                                        "is_active": 1,
                                                        "is_authority": 0,
                                                        "created_at": 1612947657,
                                                        "updated_at": 1612947659,
                                                        "attributes": [],
                                                        "children": []
                                                    }
                                                ]
                                            },
                                            {
                                                "uuid": "7d4df138-6b7e-11eb-ad0c-0242ac150002",
                                                "name": "環境都市工学部",
                                                "order": 12,
                                                "is_active": 1,
                                                "is_authority": 0,
                                                "created_at": 1612947657,
                                                "updated_at": 1612947659,
                                                "attributes": [],
                                                "children": [
                                                    {
                                                        "uuid": "7d68f75f-6b7e-11eb-ad0c-0242ac150002",
                                                        "name": "建築学科",
                                                        "order": 1,
                                                        "is_active": 1,
                                                        "is_authority": 0,
                                                        "created_at": 1612947657,
                                                        "updated_at": 1612947659,
                                                        "attributes": [],
                                                        "children": []
                                                    },
                                                    {
                                                        "uuid": "7d6849e8-6b7e-11eb-ad0c-0242ac150002",
                                                        "name": "都市システム工学科",
                                                        "order": 2,
                                                        "is_active": 1,
                                                        "is_authority": 0,
                                                        "created_at": 1612947657,
                                                        "updated_at": 1612947659,
                                                        "attributes": [],
                                                        "children": []
                                                    },
                                                    {
                                                        "uuid": "7d69a0b5-6b7e-11eb-ad0c-0242ac150002",
                                                        "name": "エネルギー・環境工学科",
                                                        "order": 3,
                                                        "is_active": 1,
                                                        "is_authority": 0,
                                                        "created_at": 1612947657,
                                                        "updated_at": 1612947659,
                                                        "attributes": [],
                                                        "children": []
                                                    }
                                                ]
                                            },
                                            {
                                                "uuid": "7d4e76e6-6b7e-11eb-ad0c-0242ac150002",
                                                "name": "化学生命工学部",
                                                "order": 13,
                                                "is_active": 1,
                                                "is_authority": 0,
                                                "created_at": 1612947657,
                                                "updated_at": 1612947659,
                                                "attributes": [],
                                                "children": [
                                                    {
                                                        "uuid": "7d6abe3c-6b7e-11eb-ad0c-0242ac150002",
                                                        "name": "化学・物質工学科",
                                                        "order": 1,
                                                        "is_active": 1,
                                                        "is_authority": 0,
                                                        "created_at": 1612947657,
                                                        "updated_at": 1612947659,
                                                        "attributes": [],
                                                        "children": []
                                                    },
                                                    {
                                                        "uuid": "7d6a5072-6b7e-11eb-ad0c-0242ac150002",
                                                        "name": "生命・生物工学科",
                                                        "order": 2,
                                                        "is_active": 1,
                                                        "is_authority": 0,
                                                        "created_at": 1612947657,
                                                        "updated_at": 1612947659,
                                                        "attributes": [],
                                                        "children": []
                                                    }
                                                ]
                                            },
                                            {
                                                "uuid": "7d4ef8a0-6b7e-11eb-ad0c-0242ac150002",
                                                "name": "単位互換・社会連携",
                                                "order": 81,
                                                "is_active": 1,
                                                "is_authority": 0,
                                                "created_at": 1612947657,
                                                "updated_at": 1612947659,
                                                "attributes": [],
                                                "children": [
                                                    {
                                                        "uuid": "7d6b4886-6b7e-11eb-ad0c-0242ac150002",
                                                        "name": "大学コンソーシアム大阪",
                                                        "order": 1,
                                                        "is_active": 1,
                                                        "is_authority": 0,
                                                        "created_at": 1612947657,
                                                        "updated_at": 1612947659,
                                                        "attributes": [],
                                                        "children": []
                                                    },
                                                    {
                                                        "uuid": "7d6bb01a-6b7e-11eb-ad0c-0242ac150002",
                                                        "name": "高大連携",
                                                        "order": 1,
                                                        "is_active": 1,
                                                        "is_authority": 0,
                                                        "created_at": 1612947657,
                                                        "updated_at": 1612947659,
                                                        "attributes": [],
                                                        "children": []
                                                    }
                                                ]
                                            },
                                            {
                                                "uuid": "7d4380f4-6b7e-11eb-ad0c-0242ac150002",
                                                "name": "履修証明プログラム",
                                                "order": 82,
                                                "is_active": 1,
                                                "is_authority": 0,
                                                "created_at": 1612947657,
                                                "updated_at": 1612947659,
                                                "attributes": [],
                                                "children": [
                                                    {
                                                        "uuid": "7d6c3769-6b7e-11eb-ad0c-0242ac150002",
                                                        "name": "社会人学び直し",
                                                        "order": 1,
                                                        "is_active": 1,
                                                        "is_authority": 0,
                                                        "created_at": 1612947657,
                                                        "updated_at": 1612947659,
                                                        "attributes": [],
                                                        "children": []
                                                    },
                                                    {
                                                        "uuid": "7d6ca325-6b7e-11eb-ad0c-0242ac150002",
                                                        "name": "地域政策コーディネーター",
                                                        "order": 2,
                                                        "is_active": 1,
                                                        "is_authority": 0,
                                                        "created_at": 1612947657,
                                                        "updated_at": 1612947659,
                                                        "attributes": [],
                                                        "children": []
                                                    }
                                                ]
                                            },
                                            {
                                                "uuid": "7d508d4f-6b7e-11eb-ad0c-0242ac150002",
                                                "name": "体育",
                                                "order": 901,
                                                "is_active": 1,
                                                "is_authority": 0,
                                                "created_at": 1612947657,
                                                "updated_at": 1612947659,
                                                "attributes": [],
                                                "children": []
                                            },
                                            {
                                                "uuid": "7d500d9e-6b7e-11eb-ad0c-0242ac150002",
                                                "name": "外国語教育研究機構",
                                                "order": 902,
                                                "is_active": 1,
                                                "is_authority": 0,
                                                "created_at": 1612947657,
                                                "updated_at": 1612947659,
                                                "attributes": [],
                                                "children": []
                                            },
                                            {
                                                "uuid": "7d4f89c8-6b7e-11eb-ad0c-0242ac150002",
                                                "name": "教育推進部",
                                                "order": 903,
                                                "is_active": 1,
                                                "is_authority": 0,
                                                "created_at": 1612947657,
                                                "updated_at": 1612947659,
                                                "attributes": [],
                                                "children": []
                                            },
                                            {
                                                "uuid": "7d417c0a-6b7e-11eb-ad0c-0242ac150002",
                                                "name": "国際部",
                                                "order": 905,
                                                "is_active": 1,
                                                "is_authority": 0,
                                                "created_at": 1612947657,
                                                "updated_at": 1612947659,
                                                "attributes": [],
                                                "children": []
                                            },
                                            {
                                                "uuid": "7d4427ef-6b7e-11eb-ad0c-0242ac150002",
                                                "name": "先端科学技術推進機構",
                                                "order": 906,
                                                "is_active": 1,
                                                "is_authority": 0,
                                                "created_at": 1612947657,
                                                "updated_at": 1612947659,
                                                "attributes": [],
                                                "children": []
                                            },
                                            {
                                                "uuid": "7d44cf20-6b7e-11eb-ad0c-0242ac150002",
                                                "name": "東西学術研究所",
                                                "order": 907,
                                                "is_active": 1,
                                                "is_authority": 0,
                                                "created_at": 1612947657,
                                                "updated_at": 1612947659,
                                                "attributes": [],
                                                "children": []
                                            }
                                        ]
                                    },
                                    {
                                        "uuid": "2c31e971-6084-11eb-9032-0242ac150002",
                                        "name": "留学生別科",
                                        "order": 0,
                                        "is_active": 1,
                                        "is_authority": 0,
                                        "created_at": 1611740635,
                                        "updated_at": 1611740635,
                                        "attributes": [],
                                        "children": []
                                    },
                                    {
                                        "uuid": "2c625ef4-6084-11eb-9032-0242ac150002",
                                        "name": "大学院生",
                                        "order": 0,
                                        "is_active": 1,
                                        "is_authority": 0,
                                        "created_at": 1611740636,
                                        "updated_at": 1611740636,
                                        "attributes": [],
                                        "children": [
                                            {
                                                "uuid": "7d521bac-6b7e-11eb-ad0c-0242ac150002",
                                                "name": "法学研究科",
                                                "order": 10,
                                                "is_active": 1,
                                                "is_authority": 0,
                                                "created_at": 1612947657,
                                                "updated_at": 1612947659,
                                                "attributes": [],
                                                "children": [
                                                    {
                                                        "uuid": "7d6dfcc5-6b7e-11eb-ad0c-0242ac150002",
                                                        "name": "公法学専攻",
                                                        "order": 1,
                                                        "is_active": 1,
                                                        "is_authority": 0,
                                                        "created_at": 1612947657,
                                                        "updated_at": 1612947659,
                                                        "attributes": [],
                                                        "children": []
                                                    },
                                                    {
                                                        "uuid": "7d6e6e55-6b7e-11eb-ad0c-0242ac150002",
                                                        "name": "私法学専攻",
                                                        "order": 2,
                                                        "is_active": 1,
                                                        "is_authority": 0,
                                                        "created_at": 1612947657,
                                                        "updated_at": 1612947659,
                                                        "attributes": [],
                                                        "children": []
                                                    },
                                                    {
                                                        "uuid": "7d6ed63c-6b7e-11eb-ad0c-0242ac150002",
                                                        "name": "法学・政治学専攻",
                                                        "order": 3,
                                                        "is_active": 1,
                                                        "is_authority": 0,
                                                        "created_at": 1612947657,
                                                        "updated_at": 1612947659,
                                                        "attributes": [],
                                                        "children": []
                                                    }
                                                ]
                                            },
                                            {
                                                "uuid": "7d529dca-6b7e-11eb-ad0c-0242ac150002",
                                                "name": "文学研究科",
                                                "order": 15,
                                                "is_active": 1,
                                                "is_authority": 0,
                                                "created_at": 1612947657,
                                                "updated_at": 1612947659,
                                                "attributes": [],
                                                "children": [
                                                    {
                                                        "uuid": "7d6f6485-6b7e-11eb-ad0c-0242ac150002",
                                                        "name": "総合人文学専攻",
                                                        "order": 1,
                                                        "is_active": 1,
                                                        "is_authority": 0,
                                                        "created_at": 1612947657,
                                                        "updated_at": 1612947659,
                                                        "attributes": [],
                                                        "children": []
                                                    }
                                                ]
                                            },
                                            {
                                                "uuid": "7d531fdc-6b7e-11eb-ad0c-0242ac150002",
                                                "name": "経済学研究科",
                                                "order": 20,
                                                "is_active": 1,
                                                "is_authority": 0,
                                                "created_at": 1612947657,
                                                "updated_at": 1612947659,
                                                "attributes": [],
                                                "children": [
                                                    {
                                                        "uuid": "7d6fe51f-6b7e-11eb-ad0c-0242ac150002",
                                                        "name": "経済学専攻",
                                                        "order": 1,
                                                        "is_active": 1,
                                                        "is_authority": 0,
                                                        "created_at": 1612947657,
                                                        "updated_at": 1612947659,
                                                        "attributes": [],
                                                        "children": []
                                                    }
                                                ]
                                            },
                                            {
                                                "uuid": "7d539c7d-6b7e-11eb-ad0c-0242ac150002",
                                                "name": "商学研究科",
                                                "order": 25,
                                                "is_active": 1,
                                                "is_authority": 0,
                                                "created_at": 1612947657,
                                                "updated_at": 1612947659,
                                                "attributes": [],
                                                "children": [
                                                    {
                                                        "uuid": "7d7060a3-6b7e-11eb-ad0c-0242ac150002",
                                                        "name": "商学専攻",
                                                        "order": 1,
                                                        "is_active": 1,
                                                        "is_authority": 0,
                                                        "created_at": 1612947657,
                                                        "updated_at": 1612947659,
                                                        "attributes": [],
                                                        "children": []
                                                    },
                                                    {
                                                        "uuid": "7d70d682-6b7e-11eb-ad0c-0242ac150002",
                                                        "name": "会計学専攻",
                                                        "order": 2,
                                                        "is_active": 1,
                                                        "is_authority": 0,
                                                        "created_at": 1612947657,
                                                        "updated_at": 1612947659,
                                                        "attributes": [],
                                                        "children": []
                                                    }
                                                ]
                                            },
                                            {
                                                "uuid": "7d5417d0-6b7e-11eb-ad0c-0242ac150002",
                                                "name": "社会学研究科",
                                                "order": 30,
                                                "is_active": 1,
                                                "is_authority": 0,
                                                "created_at": 1612947657,
                                                "updated_at": 1612947659,
                                                "attributes": [],
                                                "children": [
                                                    {
                                                        "uuid": "7d714845-6b7e-11eb-ad0c-0242ac150002",
                                                        "name": "社会学専攻",
                                                        "order": 1,
                                                        "is_active": 1,
                                                        "is_authority": 0,
                                                        "created_at": 1612947657,
                                                        "updated_at": 1612947659,
                                                        "attributes": [],
                                                        "children": []
                                                    },
                                                    {
                                                        "uuid": "7d71b17e-6b7e-11eb-ad0c-0242ac150002",
                                                        "name": "社会システムデザイン専攻",
                                                        "order": 3,
                                                        "is_active": 1,
                                                        "is_authority": 0,
                                                        "created_at": 1612947657,
                                                        "updated_at": 1612947659,
                                                        "attributes": [],
                                                        "children": []
                                                    },
                                                    {
                                                        "uuid": "7d721f54-6b7e-11eb-ad0c-0242ac150002",
                                                        "name": "マス・コミュニケーション学専攻",
                                                        "order": 5,
                                                        "is_active": 1,
                                                        "is_authority": 0,
                                                        "created_at": 1612947657,
                                                        "updated_at": 1612947659,
                                                        "attributes": [],
                                                        "children": []
                                                    }
                                                ]
                                            },
                                            {
                                                "uuid": "7d5511cb-6b7e-11eb-ad0c-0242ac150002",
                                                "name": "総合情報学研究科",
                                                "order": 35,
                                                "is_active": 1,
                                                "is_authority": 0,
                                                "created_at": 1612947657,
                                                "updated_at": 1612947659,
                                                "attributes": [],
                                                "children": [
                                                    {
                                                        "uuid": "7d72cf8a-6b7e-11eb-ad0c-0242ac150002",
                                                        "name": "社会情報学専攻",
                                                        "order": 1,
                                                        "is_active": 1,
                                                        "is_authority": 0,
                                                        "created_at": 1612947657,
                                                        "updated_at": 1612947659,
                                                        "attributes": [],
                                                        "children": []
                                                    },
                                                    {
                                                        "uuid": "7d73484c-6b7e-11eb-ad0c-0242ac150002",
                                                        "name": "知識情報学専攻",
                                                        "order": 2,
                                                        "is_active": 1,
                                                        "is_authority": 0,
                                                        "created_at": 1612947657,
                                                        "updated_at": 1612947659,
                                                        "attributes": [],
                                                        "children": []
                                                    },
                                                    {
                                                        "uuid": "7d742ad8-6b7e-11eb-ad0c-0242ac150002",
                                                        "name": "総合情報学専攻",
                                                        "order": 3,
                                                        "is_active": 1,
                                                        "is_authority": 0,
                                                        "created_at": 1612947657,
                                                        "updated_at": 1612947659,
                                                        "attributes": [],
                                                        "children": []
                                                    }
                                                ]
                                            },
                                            {
                                                "uuid": "7d567df3-6b7e-11eb-ad0c-0242ac150002",
                                                "name": "理工学研究科",
                                                "order": 40,
                                                "is_active": 1,
                                                "is_authority": 0,
                                                "created_at": 1612947657,
                                                "updated_at": 1612947659,
                                                "attributes": [],
                                                "children": [
                                                    {
                                                        "uuid": "7d759465-6b7e-11eb-ad0c-0242ac150002",
                                                        "name": "システム理工学専攻",
                                                        "order": 1,
                                                        "is_active": 1,
                                                        "is_authority": 0,
                                                        "created_at": 1612947657,
                                                        "updated_at": 1612947659,
                                                        "attributes": [],
                                                        "children": []
                                                    },
                                                    {
                                                        "uuid": "7d75f43f-6b7e-11eb-ad0c-0242ac150002",
                                                        "name": "環境都市工学専攻",
                                                        "order": 2,
                                                        "is_active": 1,
                                                        "is_authority": 0,
                                                        "created_at": 1612947657,
                                                        "updated_at": 1612947659,
                                                        "attributes": [],
                                                        "children": []
                                                    },
                                                    {
                                                        "uuid": "7d764e52-6b7e-11eb-ad0c-0242ac150002",
                                                        "name": "化学生命工学専攻",
                                                        "order": 3,
                                                        "is_active": 1,
                                                        "is_authority": 0,
                                                        "created_at": 1612947657,
                                                        "updated_at": 1612947659,
                                                        "attributes": [],
                                                        "children": []
                                                    },
                                                    {
                                                        "uuid": "7d752bc9-6b7e-11eb-ad0c-0242ac150002",
                                                        "name": "総合理工学専攻",
                                                        "order": 4,
                                                        "is_active": 1,
                                                        "is_authority": 0,
                                                        "created_at": 1612947657,
                                                        "updated_at": 1612947659,
                                                        "attributes": [],
                                                        "children": []
                                                    }
                                                ]
                                            },
                                            {
                                                "uuid": "7d581236-6b7e-11eb-ad0c-0242ac150002",
                                                "name": "工学研究科",
                                                "order": 41,
                                                "is_active": 1,
                                                "is_authority": 0,
                                                "created_at": 1612947657,
                                                "updated_at": 1612947659,
                                                "attributes": [],
                                                "children": [
                                                    {
                                                        "uuid": "7d76bd3e-6b7e-11eb-ad0c-0242ac150002",
                                                        "name": "システムデザイン専攻",
                                                        "order": 12,
                                                        "is_active": 1,
                                                        "is_authority": 0,
                                                        "created_at": 1612947657,
                                                        "updated_at": 1612947659,
                                                        "attributes": [],
                                                        "children": []
                                                    },
                                                    {
                                                        "uuid": "7d771bd7-6b7e-11eb-ad0c-0242ac150002",
                                                        "name": "ソーシャルデザイン専攻",
                                                        "order": 13,
                                                        "is_active": 1,
                                                        "is_authority": 0,
                                                        "created_at": 1612947657,
                                                        "updated_at": 1612947659,
                                                        "attributes": [],
                                                        "children": []
                                                    },
                                                    {
                                                        "uuid": "7d7785a9-6b7e-11eb-ad0c-0242ac150002",
                                                        "name": "ライフ・マテリアルデザイン専攻",
                                                        "order": 14,
                                                        "is_active": 1,
                                                        "is_authority": 0,
                                                        "created_at": 1612947657,
                                                        "updated_at": 1612947659,
                                                        "attributes": [],
                                                        "children": []
                                                    },
                                                    {
                                                        "uuid": "7d780634-6b7e-11eb-ad0c-0242ac150002",
                                                        "name": "総合工学専攻",
                                                        "order": 15,
                                                        "is_active": 1,
                                                        "is_authority": 0,
                                                        "created_at": 1612947658,
                                                        "updated_at": 1612947659,
                                                        "attributes": [],
                                                        "children": []
                                                    }
                                                ]
                                            },
                                            {
                                                "uuid": "7d558df2-6b7e-11eb-ad0c-0242ac150002",
                                                "name": "外国語教育学研究科",
                                                "order": 45,
                                                "is_active": 1,
                                                "is_authority": 0,
                                                "created_at": 1612947657,
                                                "updated_at": 1612947659,
                                                "attributes": [],
                                                "children": [
                                                    {
                                                        "uuid": "7d7894bf-6b7e-11eb-ad0c-0242ac150002",
                                                        "name": "外国語教育学専攻",
                                                        "order": 1,
                                                        "is_active": 1,
                                                        "is_authority": 0,
                                                        "created_at": 1612947658,
                                                        "updated_at": 1612947659,
                                                        "attributes": [],
                                                        "children": []
                                                    }
                                                ]
                                            },
                                            {
                                                "uuid": "7d560790-6b7e-11eb-ad0c-0242ac150002",
                                                "name": "心理学研究科",
                                                "order": 50,
                                                "is_active": 1,
                                                "is_authority": 0,
                                                "created_at": 1612947657,
                                                "updated_at": 1612947659,
                                                "attributes": [],
                                                "children": [
                                                    {
                                                        "uuid": "7d7949c4-6b7e-11eb-ad0c-0242ac150002",
                                                        "name": "認知・発達心理学専攻",
                                                        "order": 2,
                                                        "is_active": 1,
                                                        "is_authority": 0,
                                                        "created_at": 1612947658,
                                                        "updated_at": 1612947659,
                                                        "attributes": [],
                                                        "children": []
                                                    },
                                                    {
                                                        "uuid": "7d7ae44d-6b7e-11eb-ad0c-0242ac150002",
                                                        "name": "社会心理学専攻",
                                                        "order": 3,
                                                        "is_active": 1,
                                                        "is_authority": 0,
                                                        "created_at": 1612947658,
                                                        "updated_at": 1612947659,
                                                        "attributes": [],
                                                        "children": []
                                                    },
                                                    {
                                                        "uuid": "7d7b3f7b-6b7e-11eb-ad0c-0242ac150002",
                                                        "name": "心理学専攻",
                                                        "order": 4,
                                                        "is_active": 1,
                                                        "is_authority": 0,
                                                        "created_at": 1612947658,
                                                        "updated_at": 1612947659,
                                                        "attributes": [],
                                                        "children": []
                                                    },
                                                    {
                                                        "uuid": "7d7ba8c5-6b7e-11eb-ad0c-0242ac150002",
                                                        "name": "心理臨床学専攻",
                                                        "order": 5,
                                                        "is_active": 1,
                                                        "is_authority": 0,
                                                        "created_at": 1612947658,
                                                        "updated_at": 1612947659,
                                                        "attributes": [],
                                                        "children": []
                                                    }
                                                ]
                                            },
                                            {
                                                "uuid": "7d511d11-6b7e-11eb-ad0c-0242ac150002",
                                                "name": "社会安全研究科",
                                                "order": 55,
                                                "is_active": 1,
                                                "is_authority": 0,
                                                "created_at": 1612947657,
                                                "updated_at": 1612947659,
                                                "attributes": [],
                                                "children": [
                                                    {
                                                        "uuid": "7d7c21ac-6b7e-11eb-ad0c-0242ac150002",
                                                        "name": "防災・減災専攻",
                                                        "order": 1,
                                                        "is_active": 1,
                                                        "is_authority": 0,
                                                        "created_at": 1612947658,
                                                        "updated_at": 1612947659,
                                                        "attributes": [],
                                                        "children": []
                                                    }
                                                ]
                                            },
                                            {
                                                "uuid": "7d51a09c-6b7e-11eb-ad0c-0242ac150002",
                                                "name": "東アジア文化研究科",
                                                "order": 60,
                                                "is_active": 1,
                                                "is_authority": 0,
                                                "created_at": 1612947657,
                                                "updated_at": 1612947659,
                                                "attributes": [],
                                                "children": [
                                                    {
                                                        "uuid": "7d7ca3f8-6b7e-11eb-ad0c-0242ac150002",
                                                        "name": "文化交渉学専攻",
                                                        "order": 1,
                                                        "is_active": 1,
                                                        "is_authority": 0,
                                                        "created_at": 1612947658,
                                                        "updated_at": 1612947659,
                                                        "attributes": [],
                                                        "children": []
                                                    }
                                                ]
                                            },
                                            {
                                                "uuid": "7d549053-6b7e-11eb-ad0c-0242ac150002",
                                                "name": "ガバナンス研究科",
                                                "order": 65,
                                                "is_active": 1,
                                                "is_authority": 0,
                                                "created_at": 1612947657,
                                                "updated_at": 1612947659,
                                                "attributes": [],
                                                "children": [
                                                    {
                                                        "uuid": "7d7d34f1-6b7e-11eb-ad0c-0242ac150002",
                                                        "name": "ガバナンス専攻",
                                                        "order": 1,
                                                        "is_active": 1,
                                                        "is_authority": 0,
                                                        "created_at": 1612947658,
                                                        "updated_at": 1612947659,
                                                        "attributes": [],
                                                        "children": []
                                                    }
                                                ]
                                            },
                                            {
                                                "uuid": "7d5a8982-6b7e-11eb-ad0c-0242ac150002",
                                                "name": "人間健康研究科",
                                                "order": 70,
                                                "is_active": 1,
                                                "is_authority": 0,
                                                "created_at": 1612947657,
                                                "updated_at": 1612947659,
                                                "attributes": [],
                                                "children": [
                                                    {
                                                        "uuid": "7d7dac92-6b7e-11eb-ad0c-0242ac150002",
                                                        "name": "人間健康専攻",
                                                        "order": 1,
                                                        "is_active": 1,
                                                        "is_authority": 0,
                                                        "created_at": 1612947658,
                                                        "updated_at": 1612947659,
                                                        "attributes": [],
                                                        "children": []
                                                    }
                                                ]
                                            },
                                            {
                                                "uuid": "7d588eb6-6b7e-11eb-ad0c-0242ac150002",
                                                "name": "法務研究科",
                                                "order": 100,
                                                "is_active": 1,
                                                "is_authority": 0,
                                                "created_at": 1612947657,
                                                "updated_at": 1612947659,
                                                "attributes": [],
                                                "children": [
                                                    {
                                                        "uuid": "7d7e255f-6b7e-11eb-ad0c-0242ac150002",
                                                        "name": "法曹養成専攻",
                                                        "order": 1,
                                                        "is_active": 1,
                                                        "is_authority": 0,
                                                        "created_at": 1612947658,
                                                        "updated_at": 1612947659,
                                                        "attributes": [],
                                                        "children": []
                                                    }
                                                ]
                                            },
                                            {
                                                "uuid": "7d590e85-6b7e-11eb-ad0c-0242ac150002",
                                                "name": "会計研究科",
                                                "order": 105,
                                                "is_active": 1,
                                                "is_authority": 0,
                                                "created_at": 1612947657,
                                                "updated_at": 1612947659,
                                                "attributes": [],
                                                "children": [
                                                    {
                                                        "uuid": "7d7e9cb8-6b7e-11eb-ad0c-0242ac150002",
                                                        "name": "会計人養成専攻",
                                                        "order": 1,
                                                        "is_active": 1,
                                                        "is_authority": 0,
                                                        "created_at": 1612947658,
                                                        "updated_at": 1612947659,
                                                        "attributes": [],
                                                        "children": []
                                                    }
                                                ]
                                            },
                                            {
                                                "uuid": "7d59a0ea-6b7e-11eb-ad0c-0242ac150002",
                                                "name": "心理学研究科（専門職大学院）",
                                                "order": 110,
                                                "is_active": 1,
                                                "is_authority": 0,
                                                "created_at": 1612947657,
                                                "updated_at": 1612947659,
                                                "attributes": [],
                                                "children": [
                                                    {
                                                        "uuid": "7d7f14db-6b7e-11eb-ad0c-0242ac150002",
                                                        "name": "心理臨床学専攻",
                                                        "order": 1,
                                                        "is_active": 1,
                                                        "is_authority": 0,
                                                        "created_at": 1612947658,
                                                        "updated_at": 1612947659,
                                                        "attributes": [],
                                                        "children": []
                                                    }
                                                ]
                                            },
                                            {
                                                "uuid": "7d5a15ef-6b7e-11eb-ad0c-0242ac150002",
                                                "name": "国際部",
                                                "order": 905,
                                                "is_active": 1,
                                                "is_authority": 0,
                                                "created_at": 1612947657,
                                                "updated_at": 1612947659,
                                                "attributes": [],
                                                "children": []
                                            }
                                        ]
                                    }
                                ]
                            }
                        ]
                    }
                ]
            },
            {
                "uuid": "2b6a74f6-6084-11eb-9032-0242ac150002",
                "name": "関西大学 併設校",
                "order": 0,
                "is_active": 1,
                "is_authority": 0,
                "created_at": 1611740634,
                "updated_at": 1611740634,
                "attributes": [],
                "children": [
                    {
                        "uuid": "2b6bc90d-6084-11eb-9032-0242ac150002",
                        "name": "資格",
                        "order": 0,
                        "is_active": 1,
                        "is_authority": 0,
                        "created_at": 1611740634,
                        "updated_at": 1611740634,
                        "attributes": [],
                        "children": [
                            {
                                "uuid": "2b6c709f-6084-11eb-9032-0242ac150002",
                                "name": "特任外国語講師",
                                "order": 0,
                                "is_active": 1,
                                "is_authority": 0,
                                "created_at": 1611740634,
                                "updated_at": 1611740634,
                                "attributes": [],
                                "children": []
                            },
                            {
                                "uuid": "2ccbcb53-6084-11eb-9032-0242ac150002",
                                "name": "非常勤",
                                "order": 0,
                                "is_active": 1,
                                "is_authority": 0,
                                "created_at": 1611740636,
                                "updated_at": 1611740636,
                                "attributes": [],
                                "children": []
                            },
                            {
                                "uuid": "2d474def-6084-11eb-9032-0242ac150002",
                                "name": "専任教員",
                                "order": 0,
                                "is_active": 1,
                                "is_authority": 0,
                                "created_at": 1611740637,
                                "updated_at": 1611740637,
                                "attributes": [],
                                "children": []
                            }
                        ]
                    },
                    {
                        "uuid": "2b89b8cc-6084-11eb-9032-0242ac150002",
                        "name": "所属",
                        "order": 0,
                        "is_active": 1,
                        "is_authority": 0,
                        "created_at": 1611740634,
                        "updated_at": 1611740634,
                        "attributes": [],
                        "children": [
                            {
                                "uuid": "2b8a9235-6084-11eb-9032-0242ac150002",
                                "name": "初等部",
                                "order": 0,
                                "is_active": 1,
                                "is_authority": 0,
                                "created_at": 1611740634,
                                "updated_at": 1611740634,
                                "attributes": [],
                                "children": []
                            },
                            {
                                "uuid": "2bae1bef-6084-11eb-9032-0242ac150002",
                                "name": "北陽高等学校・中学校",
                                "order": 0,
                                "is_active": 1,
                                "is_authority": 0,
                                "created_at": 1611740634,
                                "updated_at": 1611740634,
                                "attributes": [],
                                "children": []
                            },
                            {
                                "uuid": "2cb08880-6084-11eb-9032-0242ac150002",
                                "name": "第一高等学校・第一中学校",
                                "order": 0,
                                "is_active": 1,
                                "is_authority": 0,
                                "created_at": 1611740636,
                                "updated_at": 1611740636,
                                "attributes": [],
                                "children": []
                            },
                            {
                                "uuid": "2cca9c86-6084-11eb-9032-0242ac150002",
                                "name": "中等部・高等部",
                                "order": 0,
                                "is_active": 1,
                                "is_authority": 0,
                                "created_at": 1611740636,
                                "updated_at": 1611740636,
                                "attributes": [],
                                "children": []
                            },
                            {
                                "uuid": "2d5d80a3-6084-11eb-9032-0242ac150002",
                                "name": "幼稚園",
                                "order": 0,
                                "is_active": 1,
                                "is_authority": 0,
                                "created_at": 1611740637,
                                "updated_at": 1611740637,
                                "attributes": [],
                                "children": []
                            }
                        ]
                    }
                ]
            },
            {
                "uuid": "2b88aabd-6084-11eb-9032-0242ac150002",
                "name": "利用者区分",
                "order": 0,
                "is_active": 1,
                "is_authority": 0,
                "created_at": 1611740634,
                "updated_at": 1611740634,
                "attributes": [],
                "children": [
                    {
                        "uuid": "2b8911cf-6084-11eb-9032-0242ac150002",
                        "name": "教員",
                        "order": 0,
                        "is_active": 1,
                        "is_authority": 0,
                        "created_at": 1611740634,
                        "updated_at": 1611740634,
                        "attributes": [],
                        "children": []
                    },
                    {
                        "uuid": "2bbc6e1b-6084-11eb-9032-0242ac150002",
                        "name": "教諭",
                        "order": 0,
                        "is_active": 1,
                        "is_authority": 0,
                        "created_at": 1611740635,
                        "updated_at": 1611740635,
                        "attributes": [],
                        "children": []
                    },
                    {
                        "uuid": "2c59a03a-6084-11eb-9032-0242ac150002",
                        "name": "職員",
                        "order": 0,
                        "is_active": 1,
                        "is_authority": 0,
                        "created_at": 1611740636,
                        "updated_at": 1611740636,
                        "attributes": [],
                        "children": []
                    },
                    {
                        "uuid": "2c854f39-6084-11eb-9032-0242ac150002",
                        "name": "学生",
                        "order": 0,
                        "is_active": 1,
                        "is_authority": 0,
                        "created_at": 1611740636,
                        "updated_at": 1611740636,
                        "attributes": [],
                        "children": []
                    },
                    {
                        "uuid": "2cd92c08-6084-11eb-9032-0242ac150002",
                        "name": "その他",
                        "order": 0,
                        "is_active": 1,
                        "is_authority": 0,
                        "created_at": 1611740636,
                        "updated_at": 1611740636,
                        "attributes": [],
                        "children": []
                    }
                ]
            }
        ]
    }
}


class GroupList extends React.Component {
    constructor(props) {
        super(props);
        let loading = false;

        let groupListArray = testTree.result.items;
        let groupListSearchIndexArray = generateList(groupListArray);
        if (!props.group.meta.fetch) {
            if (!props.group.error) {
                if (props.group.payload !== null && props.group.payload.hasOwnProperty("result") && props.group.payload.result.hasOwnProperty("items")) {
                    groupListArray = props.group.payload.result.items;
                    dataList.splice(0, dataList.length);
                    groupListSearchIndexArray = generateList(groupListArray)
                }
            }
        } else {
            loading = props.group.meta.fetch
        }
        this.state = {
            visible: props.visible,
            expandedKeys: [],
            checkedKeys: [],
            checkedRows: [],
            loading,
            searchValue: '',
            autoExpandParent: true,
            groupListArray,
            groupListSearchIndexArray,
            selectedKeys: [],
            selectedData: {
                name: "",
                uuid: "",
            },
            treeParentHeight: 0
        }
    }


    componentWillMount() {
        //this.props.searchGroup();
    }


    componentWillReceiveProps(nextProps) {

        const {group, groupCreate, groupUpdate, groupDelete, checkedKeys} = {...this.props};

        if (this.state.visible === false) {
            this.setState({
                expandedKeys: [],
                checkedKeys: [],
                checkedRows: [],
            });
        }

        this.setState({
            checkedKeys: nextProps.checkedKeys,
            visible: nextProps.visible,
        });

        if (nextProps.group !== group) {
            if (!nextProps.group.meta.fetch) {
                if (!nextProps.group.error) {
                    if (nextProps.group.payload !== null) {
                        dataList.splice(0, dataList.length);

                        const array = this.expandedNode(nextProps.group.payload.result.items);
                        this.setState({
                            loading: nextProps.group.meta.fetch,
                            groupListArray: array,
                            groupListSearchIndexArray: generateList(nextProps.group.payload.result.items)
                        });
                    }
                }
            } else {
                this.setState({
                    loading: nextProps.group.meta.fetch
                })
            }
        }


        if (nextProps.groupCreate !== groupCreate) {
            if (!nextProps.groupCreate.meta.fetch) {
                if (!nextProps.groupCreate.error) {
                    if (nextProps.groupCreate.payload !== null) {
                        this.search();
                        this.setState({
                            addGroupModalIsOpen: false,
                            loading: nextProps.groupCreate.meta.fetch
                        })
                    } else {
                        this.setState({
                            loading: nextProps.groupCreate.meta.fetch
                        })
                    }
                } else {
                    this.setState({
                        loading: nextProps.groupCreate.meta.fetch
                    })
                }
            } else {
                this.setState({
                    loading: nextProps.groupCreate.meta.fetch
                })
            }
        }

        if (nextProps.groupUpdate !== groupUpdate) {
            if (!nextProps.groupUpdate.meta.fetch) {
                if (!nextProps.groupUpdate.error) {
                    if (nextProps.groupUpdate.payload !== null) {
                        this.setState({
                            editGroupModalIsOpen: false,
                            loading: nextProps.groupUpdate.meta.fetch
                        })
                    } else {
                        this.setState({
                            loading: nextProps.groupUpdate.meta.fetch
                        })
                    }
                } else {
                    this.search();
                    this.setState({
                        loading: nextProps.groupUpdate.meta.fetch
                    })
                }
            } else {
                this.setState({
                    loading: nextProps.groupUpdate.meta.fetch
                })
            }
        }

        if (nextProps.groupDelete !== groupDelete) {
            if (!nextProps.groupDelete.meta.fetch) {
                if (!nextProps.groupDelete.error) {
                    if (nextProps.groupDelete.payload !== null) {
                        this.search();
                        this.setState({
                            loading: nextProps.groupDelete.meta.fetch
                        })
                    } else {
                        this.setState({
                            loading: nextProps.groupDelete.meta.fetch
                        })
                    }
                } else {
                    this.setState({
                        loading: nextProps.groupDelete.meta.fetch
                    })
                }
            } else {
                this.setState({
                    loading: nextProps.groupDelete.meta.fetch
                })
            }
        }
    }

    search = () => {
        this.props.searchGroup();
    }

    handleOk = () => {
        this.props.handleOk(this.state.checkedKeys, this.state.checkedRows);
        this.setState({
            confirmLoading: true,
        });
        setTimeout(() => {
            this.setState({
                confirmLoading: false,
            });
        }, 2000);
    };

    onSelect = (selectedKeys, info) => {
        this.setState({
            selectedKeys
        })
    };

    handleCancel = () => {
        this.setState({
            addGroupModalIsOpen: false,
            editGroupModalIsOpen: false
        })
    };

    createGroup = (fieldsValue, data) => {
        this.props.createGroup(
            data.uuid, fieldsValue
        )
    };

    sortGroup = (fieldsValue,data) => {
        this.props.updateGroup(
            data.uuid, fieldsValue
        )
    }

    editGroup = (fieldsValue, data) => {
        log.debug(data);
        const name  = fieldsValue.name;
        this.setState(state => ({
            groupListArray: changeNodeAtPath({
                treeData: state.groupListArray,
                path: state.selectedPath,
                getNodeKey,
                newNode: { ...data, name },
            }),
        }))
        this.props.updateGroup(
            data.uuid, fieldsValue
        )
    };



    deleteGroup = (data) => {
        log.debug(data);
        this.props.deleteGroup(data.uuid);
    };

    expandedNode = (array) => {
        let treeData = array;
        this.state.expandedKeys.forEach((path,index) => {
            const foundNode = getNodeAtPath({
                treeData,
                path,
                getNodeKey
            });
            if(foundNode){
                treeData = changeNodeAtPath({
                    treeData,
                    path,
                    getNodeKey,
                    newNode: {
                        ...foundNode.node,
                        expanded:true
                    },
                })
            }
        });
        log.debug(treeData)
        return treeData;
    };

    onDragEnter = (info) => {

    };

    render() {
        const {visible, confirmLoading, ModalText, searchValue, expandedKeys, autoExpandParent,groupListArray} = this.state;

        const treeParentHeight =  getVisibleNodeCount({treeData:groupListArray}) * 62 + 2;
        return (
            <div id="group-list-container" alt="グループ編集" className="tab-container">
                <Spin spinning={this.state.loading}>
                    <div style={{padding: "50px", textAlign: "center", display: this.state.loading ? "hi" : "none"}}>
                        <LoadingOutlined /> グループ情報を読み込み中...
                    </div>
                    <div style={{height: treeParentHeight, visible: !this.state.loading}}>
                        <SortableTree
                            onVisibilityToggle={(e) => {
                                const array = expandedKeys;
                                if(e.expanded){
                                    array.push(e.path);
                                    this.setState({
                                        expandedKeys: array
                                    })
                                }else{
                                    array.splice(expandedKeys.indexOf(e.path),1)
                                    this.setState({
                                        expandedKeys: array
                                    })
                                }
                            }}
                            onMoveNode={({treeData, node, nextParentNode, prevPath, prevTreeIndex, nextPath, nextTreeIndex}) => {
                                let processingArray = nextParentNode ? nextParentNode.children: this.state.groupListArray;
                                this.setState({
                                    processingArray
                                }, () => {
                                    processingArray.forEach((value, index) => {
                                        const fieldsValue = {};
                                        fieldsValue.name = value.name;
                                        fieldsValue.is_authority = value.is_authority === 1;
                                        fieldsValue.order = index;
                                        this.sortGroup(fieldsValue,value);
                                    })
                                })
                            }}
                            canDrop={(e) => (!e.nextParent && !e.prevParent || e.nextParent && e.prevParent && e.prevParent.uuid === e.nextParent.uuid)}
                            treeData={this.state.groupListArray}
                            onChange={groupListArray => {
                                log.debug(groupListArray)
                                this.setState({groupListArray})
                            }}
                            generateNodeProps={({node, path}) => {
                                return {
                                    title: (
                                        <div>
                                            <span className="title">{node.name}</span>
                                            <span style={{marginLeft: 20, alignLeft: true}}>
                                                {node.children.length !== 0 ?
                                                    <Tooltip placement="topLeft" title="グループ追加">
                                                        <Button
                                                            type="default"
                                                            shape="circle"
                                                            icon={<PlusOutlined />}
                                                            onClick={() => {
                                                                this.setState({
                                                                    addGroupModalIsOpen: true,
                                                                    selectedData: node,
                                                                    selectedPath: path
                                                                })
                                                            }}
                                                        />
                                                    </Tooltip>
                                                    :
                                                    null
                                                }
                                                <Tooltip placement="topLeft" title="グループ名編集">
                                                    <Button
                                                        type="default"
                                                        shape="circle"
                                                        icon={<EditOutlined />}
                                                        onClick={() => {
                                                            this.setState({
                                                                editGroupModalIsOpen: true,
                                                                selectedData: node,
                                                                selectedPath: path
                                                            })
                                                        }}
                                                    />
                                                </Tooltip>

                                                <Popconfirm
                                                    title={<span>グループを削除すると関連された部分からグループは全て削除されます。<br/>
                                                        <strong>グループに一人でもユーザが属す場合は削除できません</strong></span>}
                                                    onConfirm={() => this.deleteGroup(node)}
                                                    onCancel=""
                                                    placement="left"
                                                    okText="削除" cancelText="キャンセル"
                                                >
                                                    <Tooltip placement="topLeft" title="グループ削除">
                                                        <Button type="default" shape="circle" icon={<DeleteOutlined />}/>
                                                    </Tooltip>
                                                </Popconfirm>
                                            </span>
                                        </div>
                                    )
                                };
                            }}
                        />
                    </div>
                </Spin>
                <ModalAddEditGroup
                    onOk={(value)=>{
                        if(this.state.shouldCreate){
                            this.createGroup(value);
                        }else{
                            this.editGroup(value)
                        }
                    }}
                    onCancel={this.handleCancel}
                    visible={this.state.addGroupModalIsOpen}
                    data={this.state.selectedData}
                    is_authority={this.state.is_authority}
                    />

                <ModalAddGroup
                    onOk={this.createGroup}
                    onCancel={this.handleCancel}
                    visible={this.state.addGroupModalIsOpen}
                    data={this.state.selectedData}
                    is_authority={this.state.is_authority}
                />


            </div>
        );
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(GroupList)

