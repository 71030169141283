import React from 'react';
import {Tag,Tooltip} from 'antd'

export const TYPES_QUESTIONNAIRE_ANSWERED = "QUESTIONNAIRE/ANSWERED";
export const TYPES_QUESTIONNAIRE_REQUIRED = "QUESTIONNAIRE/REQUIRED";
export const TYPES_QUESTIONNAIRE_ANONYMOUS = "QUESTIONNAIRE/ANONYMOUS";
export const TYPES_QUESTIONNAIRE_PUBLIC = "QUESTIONNAIRE/PUBLIC";;
export const TYPES_QUESTIONNAIRE_PUBLIC_RESULT = "QUESTIONNAIRE/PUBLIC/RESULT";

export const TYPES_NOTIFICATION_PRIORITY = "NOTIFICATION/PRIORITY";

export const generate = (type, data) => {
    switch (type) {
        //記名・無記名　分別tag
        case TYPES_QUESTIONNAIRE_ANONYMOUS:
            switch (data) {
                case true:
                    return (
                        <Tooltip title={"アンケートは無記名で送信されます"}><Tag className="tag gray">無記名式</Tag></Tooltip>
                    );
                    break;
                case false:
                    return (
                        <Tooltip title={"アンケートは記名で送信されます"}><Tag className="tag option">記名式</Tag></Tooltip>
                    );
                    break;
                default:
                    return (
                        <span/>
                    );
                    break;
            }
        //公開・非公開
        case TYPES_QUESTIONNAIRE_PUBLIC:
            switch (data) {
                case true:
                    return (
                        <Tooltip title={"アンケートは公開されています"}><Tag className="tag normal">公開</Tag></Tooltip>
                    );
                    break;
                case false:
                    return (
                        <Tooltip title={"アンケートは非公開です"}><Tag className="tag emergency">非公開</Tag></Tooltip>
                    );
                    break;
                default:
                    return (
                        <span/>
                    );
                    break;
            }
        //回答の公開・非公開
        case TYPES_QUESTIONNAIRE_PUBLIC_RESULT:
            switch (data) {
                case true:
                    return (
                        <Tooltip title={"アンケートの回答結果は他の回答者に公開されます"}><Tag className="tag option">回答公開</Tag></Tooltip>
                    );
                    break;
                case false:
                    return (
                            <Tooltip title={"アンケートの回答結果は他の回答者に公開されません"}><Tag className="tag gray">回答非公開</Tag></Tooltip>
                    );
                    break;
                default:
                    return (
                        <span/>
                    );
                    break;
            }


        //回答済・未回答分別tag
        case TYPES_QUESTIONNAIRE_ANSWERED:
            switch (data) {
                case true:
                    return (
                        <Tag className="tag gray">回答済</Tag>
                    );
                case false:
                    return (
                        <Tag className="tag emergency">未回答</Tag>
                    );
                default:
                    return (
                        <span/>
                    );
            }
        //オプション　必須・任意
        case TYPES_QUESTIONNAIRE_REQUIRED:
            switch (data) {
                case 0:
                    return (
                        <Tag className="tag emergency">必須</Tag>
                    );
                    break;
                case 1:
                    return (
                        <Tag color="cyan">任意</Tag>
                    );
                    break;
                default:
                    return (
                        <span/>
                    );
                    break;
            }
            break;

        case TYPES_NOTIFICATION_PRIORITY:
            switch (data) {
                case 1:
                    return (
                        <span/>
                    );
                    break;
                    /*
                case 2:
                    return (
                        <Tag className="tag important">重要</Tag>
                    );
                    break;
                     */
                case 3:
                    return (
                        <Tag className="tag emergency">緊急</Tag>
                    );
                    break;
                default:
                    return (
                        <span/>
                    );
                    break;
            }
            break;
        default:
            return (
                <span/>
            );
            break;

    }
}
