import React from 'react'
import {connect, store} from 'react-redux'
import PropTypes from 'prop-types';
import {instanceOf} from 'prop-types';
import log from 'components/utils/Logger.js'

import moment from 'moment';
import 'moment/locale/ja';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Table, Tabs, Collapse, Card, Button, Row, Col, Modal } from 'antd';
import ReactGridLayout from 'react-grid-layout'
import * as Actions from 'redux/actions';
import ModuleQuestionnaireModalSingle from './ModuleQuestionnaireModalSingle';
import ForceModuleQuestionnaireModalList from './ForceModuleQuestionnaireModalList';
import ModuleQuestionnaireModalList from './ModuleQuestionnaireModalList'
import QuestionnaireResultModal from '../../../questionnaire/QuestionnaireResultModal';
import FroalaEditorView from 'react-froala-wysiwyg/FroalaEditorView';
import {Scrollbars} from 'react-custom-scrollbars';
import * as TagNodeGenerator from 'components/utils/TagNodeGenerator'

moment.locale('ja');

const TabPane = Tabs.TabPane;
const FormItem = Form.Item;
const Panel = Collapse.Panel;

const mapStateToProps = (state, props) => {
    return {
        screen: state.screen,
        questionnaire: state.questionnaire,
        questionnaireAnswer: state.questionnaireAnswer,
        session: state.session
    }
};



const mapDispatchToProps = dispatch => {
    return {
        searchQuestionnaire: (data, currentPageNum) => {
            dispatch(Actions.Connection.questionnaire.search.request(data, currentPageNum));
        },
        answerQuestionnaire: (uuid, data) => {
            dispatch(Actions.Connection.questionnaire.answer.search.request(uuid, data));
        },
        checkLoggedIn: () => {
            dispatch(Actions.Connection.check.request());
        }
    }
};

class ForceModuleQuestionnaire extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            singleModalIsOpen: false,
            forceModalIsOpen: false,
            listModalIsOpen: false,
            paramCategoryArray: [],
            resultQuestionnaireArray: [],
            resultModalIsOpen: false,
            detailData: null,
            parts: props.parts,
        };
    }

    componentWillMount() {
    }

    componentDidMount() {
    }

    componentWillReceiveProps(nextProps) {
        const pagination = {...this.state.pagination};
        const {questionnaire, questionnaireAnswer} = {...this.props};
        this.setState({
            parts: nextProps.parts,
            user: nextProps.session.payload.user
        });


        if (nextProps.session.payload.hasOwnProperty("user") && nextProps.session.payload.user && nextProps.session.payload.user.hasOwnProperty("has_force_questionnaire")) {
            this.setState({
                forceModalIsOpen: nextProps.session.payload.user.has_force_questionnaire
            });
        }


        if (nextProps.questionnaire !== questionnaire) {
            if (!nextProps.questionnaire.meta.fetch) {
                if (!nextProps.questionnaire.error) {
                    if (nextProps.questionnaire.payload !== null) {
                        pagination.total = nextProps.questionnaire.payload.result.page_total * nextProps.questionnaire.payload.result.page_size;
                        this.setState({
                            searchLoading: nextProps.questionnaire.meta.fetch,
                            resultQuestionnaireArray: nextProps.questionnaire.payload.result.items,
                            //通信後
                            pagination
                        })
                    }
                }
            } else {
                this.setState({
                    searchLoading: nextProps.questionnaire.meta.fetch
                })
            }
        }

        if (nextProps.questionnaireAnswer !== questionnaireAnswer) {
            if (!nextProps.questionnaireAnswer.meta.fetch) {
                if (!nextProps.questionnaireAnswer.error) {
                    if (nextProps.questionnaireAnswer.payload !== null) {
                        if (nextProps.questionnaireAnswer.payload.hasOwnProperty("result") &&
                            nextProps.questionnaireAnswer.payload.result.hasOwnProperty("after_message") &&
                            nextProps.questionnaireAnswer.payload.result.after_message) {
                            if (this.state.singleModalIsOpen) {
                                Modal.info({
                                    content: (
                                        <div>
                                            <p><FroalaEditorView
                                                model={nextProps.questionnaireAnswer.payload.result.after_message}/></p>
                                        </div>
                                    ),
                                    onOk() {
                                    },
                                });
                            }
                        }
                        this.setState({
                            singleModalIsOpen: false
                        });
                    }
                }
            } else {
                this.setState({
                    searchLoading: nextProps.questionnaire.meta.fetch
                })
            }
        }
    }


    handleRowClick = (record, index, event) => {
        this.setState({
            uuid: record.uuid,
            selectRowTitle: record.name,
        }, () => {
            this.setState({
                singleModalIsOpen: true
            })
        })
    };


    render() {
        return (
            <div>
                <ForceModuleQuestionnaireModalList
                    visible={this.state.forceModalIsOpen}
                    handleRowClick={(record, index, event) => {
                        this.handleRowClick(record, index, event);
                    }}
                    handleOk={() => {
                        if(this.props.exitForceModuleQuestionnaire !== undefined) {
                            this.props.exitForceModuleQuestionnaire();
                        }else{
                            this.props.checkLoggedIn();
                        }
                    }}
                />
                <ModuleQuestionnaireModalSingle
                    visible={this.state.singleModalIsOpen}
                    handleCancel={() => {
                        this.setState({
                            singleModalIsOpen: false
                        })
                    }}
                    handleOk={(uuid, data) => {
                        this.props.answerQuestionnaire(uuid, data);
                    }}
                    uuid={this.state.uuid}
                />
            </div>
        );
    }
}
ForceModuleQuestionnaire.propTypes = {
    exitForceModuleQuestionnaire: PropTypes.func
};

ForceModuleQuestionnaire.defaultProps = {
    exitForceModuleQuestionnaire: undefined
};


export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ForceModuleQuestionnaire)
