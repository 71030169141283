import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {Form} from "@ant-design/compatible";
import {Button} from "antd";
import {useSelector, useDispatch} from 'react-redux'
import * as Actions from "../../redux/actions";

LoginSso.propTypes = {};
const FormItem = Form.Item;

function LoginSso(props) {
    const dispatch = useDispatch();
    const [loading,setLoading] = useState(false);
    const loginSsoConnection = useSelector(state => state.loginSsoConnection);
    const checkLoginConnection = useSelector(state => state.checkLoginConnection);

    useEffect(()=>{
        setLoading(loginSsoConnection.meta.fetch || checkLoginConnection.meta.fetch);
    },[loginSsoConnection,checkLoginConnection])


    const handleSsoSubmit = (e) => {
        e.preventDefault();
        !loading  && dispatch(Actions.http.connection.auth.loginSso());
    }


    return (
        <div className="card login-form">
            <Form onSubmit={handleSsoSubmit}>
                <FormItem>
                    <Button type="primary" htmlType="submit" className="login-form-button"
                            loading={loading}>
                        SSOでログイン
                    </Button>
                </FormItem>

                <FormItem>
                    <Button type="primary" danger className="login-form-button"
                            onClick={() => {
                                window.location.href = "https://aft.auth.kansai-u.ac.jp/amserver/UI/Logout?goto=https://info.kansai-u.ac.jp/"
                            }}
                    >
                        SSOからログアウト
                    </Button>
                </FormItem>
            </Form>
        </div>
    );
}

export default LoginSso;