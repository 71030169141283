import React from 'react'
import {connect, store} from 'react-redux'
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Modal, Tabs, Input, Collapse, Radio, Tree, Spin } from 'antd';
import log from 'components/utils/Logger.js'
import * as Actions from '../../redux/actions';
import {generate} from "../../components/utils/TreeDataGenerator";

const TreeNode = Tree.TreeNode;
const Search = Input.Search;



const getParentKey = (uuid, tree) => {
    let parentKey;
    for (let i = 0; i < tree.length; i++) {
        const node = tree[i];
        if (node.children) {
            if (node.children.some(item => item.uuid === uuid)) {
                parentKey = node.uuid;
            } else if (getParentKey(uuid, node.children)) {
                parentKey = getParentKey(uuid, node.children);
            }
        }
    }
    return parentKey;
};


const dataList = [];
const generateList = (data) => {
    for (let i = 0; i < data.length; i++) {
        const node = data[i];
        const uuid = node.uuid;
        dataList.push({uuid, name:node.name});
        if (node.children) {
            generateList(node.children);
        }
    }
    return dataList;
};


const mapStateToProps = (state, props) => {
    return {
        screen: state.screen,
        group: state.group
    }
};

const mapDispatchToProps = dispatch => {
    return {
        searchGroup: () => {
            dispatch(Actions.AdminConnection.group.search.request());
        },
    }
};

class SelectTargetFormGroup extends React.Component {
    constructor(props) {
        super(props);
        let groupListArray = [];
        let groupListSearchIndexArray = [];
        if (!props.group.meta.fetch) {
            if (!props.group.error) {
                if (props.group.payload !== null && props.group.payload.hasOwnProperty("result") && props.group.payload.result.hasOwnProperty("items")) {
                    groupListArray = props.group.payload.result.items;
                    groupListSearchIndexArray = generateList(groupListArray)
                }
            }
        } else {
            this.setState({
                loading: props.group.meta.fetch
            })
        }
        this.state = {
            ModalText: 'Content of the modal',
            visible: props.visible,
            expandedKeys: [],
            checkedKeys: [],
            searchValue: '',
            autoExpandParent: true,
            groupListArray,
            groupListSearchIndexArray,
        }
    }

    componentWillMount(){
        this.props.searchGroup();
    }


    componentWillReceiveProps(nextProps) {

        const {group,checkedKeys} = {...this.props};

        log.debug(nextProps.checkedKeys);

        this.setState({
            checkedKeys: nextProps.checkedKeys
        })
        if (nextProps.group !== group) {
            if (!nextProps.group.meta.fetch) {
                if (!nextProps.group.error) {
                    if (nextProps.group.payload !== null) {
                        this.setState({
                            loading: nextProps.group.meta.fetch,
                            groupListArray: nextProps.group.payload.result.items,
                            groupListSearchIndexArray: generateList( nextProps.group.payload.result.items)
                        })
                    }
                }
            } else {
                this.setState({
                    loading: nextProps.group.meta.fetch
                })
            }
        }

    }

    showModal = () => {
        this.setState({
            visible: true,
        });
    }

    handleOk = () => {
        this.setState({
            ModalText: 'The modal will be closed after two seconds',
            confirmLoading: true,
        });
        setTimeout(() => {
            this.setState({
                visible: false,
                confirmLoading: false,
            });
        }, 2000);
    }

    handleCancel = () => {
        console.debug('Clicked cancel button');
        this.setState({
            visible: false,
        });
    }

    onChangeTab = (uuid) => {

    }

    //ここから

    onExpand = (expandedKeys) => {
        log.debug(expandedKeys);
        this.setState({
            expandedKeys,
            autoExpandParent: false,
        });
    }

    onChange = (e) => {
        const value = e.target.value;
        log.debug(value);
        log.debug(this.state.groupListSearchIndexArray);
        const expandedKeys = this.state.groupListSearchIndexArray.map((item) => {
            if (item.name.indexOf(value) > -1) {
                return getParentKey(item.uuid, this.state.groupListArray);
            }
            return null;
        }).filter((item, i, self) => item && self.indexOf(item) === i);
        log.debug(expandedKeys);
        this.setState({
            expandedKeys,
            searchValue: value,
            autoExpandParent: true,
        });

    };

    onSelect = (selectedKeys, info) => {
    };

    onCheck = (checkedKeys, info) => {
        this.setState({
            checkedKeys: checkedKeys
        });
        this.props.setObject(checkedKeys);
    };

    render() {
        const {visible, confirmLoading, ModalText, searchValue, expandedKeys, autoExpandParent} = this.state;

        return (
            <div id="group-tree-container" className="wrapper" alt="グループ">
                <div className="group-tree-container-title-wrapper">
                    <h2>対象グループ</h2>
                    <h3>キーワード検索</h3>
                    <Search style={{width: 250}} placeholder="キーワードを入力してください" onChange={this.onChange}/>
                </div>

                {this.state.loading ? <Spin/>
                    :
                    <Tree
                        checkable
                        checkedKeys={{checked:this.state.checkedKeys}}
                        onExpand={this.onExpand}
                        expandedKeys={expandedKeys}
                        autoExpandParent={true}
                        onSelect={this.onSelect}
                        onCheck={this.onCheck}>

                        {generate(this.state.groupListArray,searchValue)}

                        </Tree>
                }
            </div>
        );
    }
}


export default connect(
    mapStateToProps,
    mapDispatchToProps
)(SelectTargetFormGroup)

