import React from 'react'
import {connect, store} from 'react-redux'
import PropTypes from 'prop-types';
import {instanceOf} from 'prop-types';
import * as GlobalConfig from 'constants/GlobalConfig'
import moment from 'moment';
import 'moment/locale/ja';
import { EditOutlined, LineChartOutlined, PieChartOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Modal, Table, Tabs, Collapse, Card, Button } from 'antd';
import FroalaEditorView from 'react-froala-wysiwyg/FroalaEditorView';
import * as TagNodeGenerator from 'components/utils/TagNodeGenerator'
import * as Actions from 'redux/actions';
moment.locale('ja');

const mapStateToProps = (state, props) => {
    return {
        screen: state.screen,
        paramCategory: state.paramQuestionnaireCategory,
        questionnaire: state.questionnaireMore,
        questionnaireAnswer: state.questionnaireAnswer,
        session: state.session,
    }
};

const mapDispatchToProps = dispatch => {
    return {
        getCategory: () => {
            dispatch(Actions.Connection.questionnaire.category.parameter.request());
        },
        searchQuestionnaire: (data, currentPageNum) => {
            dispatch(Actions.Connection.questionnaire.searchMore.request(data, currentPageNum));
        }
    }
};

class ModuleQuestionnaireModalList extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            modalTitle: props.title,
            paramCategoryArray: [],
            resultQuestionnaireArray: [],
            pagination: {
                showTotal: (total, range) => {

                    return (`全${total}件中 ${range[0]}-${range[1]} 件`)
                },
                showSizeChanger: true,
                pageSizeOptions: GlobalConfig.PageSizeOptions,
                pageSize: 20,
                onShowSizeChange: this.onChangePageSize,
                total: 0,
            },
            name: "",
            loading: true
        };
    }

    onChangePageSize = (current, pageSize) => {
        const pager = {...this.state.pagination};
        //pageSizeの更新
        pager.pageSize = pageSize;
        this.setState({
            pagination: pager,
        });
    }

    componentWillReceiveProps(nextProps) {
        const {paramCategory, questionnaire, questionnaireAnswer} = {...this.props};

        this.setState({
            visible: nextProps.visible
        });
        if (nextProps.paramCategory !== paramCategory) {
            if (!nextProps.paramCategory.meta.fetch) {
                if (!nextProps.paramCategory.error) {
                    if (nextProps.paramCategory.payload !== null) {
                        this.setState({
                            paramCategoryArray: nextProps.paramCategory.payload.result.items,
                        })
                    }
                }
                this.setState({
                    loading: nextProps.paramCategory.meta.fetch
                })
            } else {
                this.setState({
                    loading: nextProps.paramCategory.meta.fetch
                })
            }
        }

        if (nextProps.questionnaire !== questionnaire) {
            if (!nextProps.questionnaire.meta.fetch) {
                if (!nextProps.questionnaire.error) {
                    if (nextProps.questionnaire.payload !== null) {
                        this.setState({
                            loading: nextProps.questionnaire.meta.fetch,
                            resultQuestionnaireArray: nextProps.questionnaire.payload.result.items,
                            pagination: {
                                ...this.state.pagination,
                                total: nextProps.questionnaire.payload.result.item_count
                            }
                        })
                    }else{

                    }
                }
                this.setState({
                    loading: nextProps.questionnaire.meta.fetch
                })
            } else {
                this.setState({
                    loading: nextProps.questionnaire.meta.fetch
                })
            }
        }
        if (nextProps.questionnaireAnswer !== questionnaireAnswer) {
            //回答後でmodalが上がっている場合のみ再検索
            if(this.state.visible) {
                this.search()
            }
        }

        if(nextProps.session && nextProps.session.hasOwnProperty("payload") && nextProps.session.payload.hasOwnProperty("user")){
            const name = nextProps.session.payload.user.name;
            this.setState({
                name
            })
        }



        if(this.props.visible !== nextProps.visible && nextProps.visible){
            this.search()
        }

    }


    handleTableChange = (pagination, filters, sorter) => {

        this.search(pagination);
    };

    search(value,category_uuid){
        let fieldsValue = {};
        let pagination;
        if(typeof value === "undefined"){
            pagination = { ...this.state.pagination };
        }else{
            pagination = value;
        }
        fieldsValue.page_size = pagination.pageSize;
        if(typeof category_uuid !== "undefined"){
            fieldsValue.category_uuid = category_uuid;
        }else{
            fieldsValue.category_uuid = this.state.currentCategory;
        }
        //ほぼそのまま検索
        this.props.searchQuestionnaire(fieldsValue,pagination.current);
    }

    handleOk = () => {

        this.props.handleOk();
    };

    handleCancel = () => {

        this.props.handleCancel();
    };

    render() {
        const {visible, searchLoading} = this.state;
        const columns = [{
            dataIndex: 'key',
            key: 'key',
            render: (text, record) => {

                const isAnsweredTag = TagNodeGenerator.generate(TagNodeGenerator.TYPES_QUESTIONNAIRE_ANSWERED,record.is_answered);

                let resultButton;
                // アンケートの結果公開可否と結果公開期間チェック
                if (record.is_public_result === true && moment().isBetween(moment.unix(record.publish_result_start_at), moment.unix(record.publish_result_end_at))) {
                    resultButton = <Button type="primary" size={"small"} icon={<LineChartOutlined />} onClick={() => this.props.handleOpenResultModal(record)}>集計結果を見る</Button>;
                }

                return (
                    <div className="module-cell module-questionnaire">
                        <div className="module-cell-head module-questionnaire-head">
                            <div className="date">{moment.unix(record.publish_start_at).format("YYYY.MM.DD")} - {moment.unix(record.publish_end_at).format("YYYY.MM.DD")}</div>
                            <div className="tags-wrapper">
                                {isAnsweredTag}
                            </div>
                        </div>
                        <div className="module-cell-body module-questionnaire-body">{record.name}</div>
                        <div className="publish-from-wapper">{record.publish_from}</div>
                        <FroalaEditorView model={record.content} />
                        <div style={{textAlign:"right",paddingTop:"5px"}}>
                            <Button type="primary" size={"small"} icon={<EditOutlined />} onClick={() => {this.props.handleRowClick(record)}}>回答する</Button>&nbsp;
                            {resultButton}
                        </div>
                    </div>
                );

            }
        }];


        return (
            <Modal
                className="common-modal"
                zIndex={900}
                style={{top: 20}}
                title={<span><PieChartOutlined />&nbsp;{this.state.name}さんへのアンケート</span>}
                visible={visible}
                maskClosable={false}
                onOk={this.handleOk}
                onCancel={this.handleCancel}
                footer={[
                    <Button key="back" size="large" onClick={this.handleCancel}>閉じる</Button>
                ]}>
                <div className='wrapper questionnaire-modal-list'>
                    <Table
                        loading={this.state.loading}
                        className="general-table"
                        locale={{
                            filterTitle: 'フィルタ',
                            filterConfirm: '確定',
                            filterReset: 'リセット',
                            emptyText: '該当するものはありません',
                        }}
                        pagination={this.state.pagination}
                        showHeader={false}
                        columns={columns} dataSource={this.state.resultQuestionnaireArray}
                        size="small"
                        onChange={this.handleTableChange}
                    />
                </div>
            </Modal>
        );
    }
}


ModuleQuestionnaireModalList.propTypes = {
    handleOk: PropTypes.func.isRequired,
    handleCancel: PropTypes.func.isRequired
};


export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ModuleQuestionnaireModalList)
