import React from 'react';
import {connect, store} from 'react-redux';
import {instanceOf} from 'prop-types';
import * as GlobalConfig from '../../constants/GlobalConfig';
import * as url from '../../constants/api';
import * as Actions from '../../redux/actions';
import moment from 'moment';
import 'moment/locale/ja';
import log from 'components/utils/Logger';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import {
    Table,
    Radio,
    Select,
    Row,
    Input,
    Col,
    Button,
    Tooltip,
    DatePicker,
    Collapse,
    Popconfirm,
    Checkbox,
    Tag,
    message,
} from 'antd';


moment.locale('ja');

const FormItem = Form.Item;
const Panel = Collapse.Panel;
const Option = Select.Option;
const RadioButton = Radio.Button;
const RadioGroup = Radio.Group;
const RangePicker = DatePicker.RangePicker;


const mapStateToProps = (state, props) => {
    return {
        passwordChange: state.passwordChange
    }
};

const mapDispatchToProps = dispatch => {
    return {
        changePassword: (data) => {
            dispatch(Actions.AdminConnection.password.change.request(data));
        }
    }
};


class MasterPassChange extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            confirmDirty: false,
        }
    }


    componentWillMount(){

    }

    componentWillReceiveProps(nextProps){
        const {passwordChange} = {...this.props};

        if (nextProps.passwordChange !== passwordChange) {
            if (!nextProps.passwordChange.meta.fetch) {
                if (!nextProps.passwordChange.error) {
                    if (nextProps.passwordChange.payload !== null) {
                        this.setState({
                            loading: nextProps.passwordChange.meta.fetch,
                            oldPassword: "",
                            newPassword: "",
                            confirm: ""
                        })
                        this.props.form.resetFields();
                        message.success("パスワードを変更しました", 2.0);
                    }else{
                        this.setState({
                            loading: nextProps.passwordChange.meta.fetch
                        })
                    }
                }else{
                    this.setState({
                        loading: nextProps.passwordChange.meta.fetch
                    })
                }
            } else {
                this.setState({
                    loading: nextProps.passwordChange.meta.fetch
                })
            }
        }

    }


    onSubmit = (e) => {
        e.preventDefault();
        this.props.form.validateFields((err, values) => {
            if (!err) {
                const fieldsValue = this.props.form.getFieldsValue();
                delete fieldsValue.confirm;

                this.props.changePassword(fieldsValue);
            }
        });
    };


    handleConfirmBlur = (e) => {
        const value = e.target.value;
        this.setState({ confirmDirty: this.state.confirmDirty || !!value });
    }
    checkPassword = (rule, value, callback) => {
        const form = this.props.form;
        if (value && value !== form.getFieldValue('newPassword')) {
            callback('パスワードが一致しません');
        } else {
            callback();
        }
    }
    checkConfirm = (rule, value, callback) => {
        const form = this.props.form;
        if (value && this.state.confirmDirty) {
            form.validateFields(['confirm'], { force: true });
        }
        callback();
    }

    render() {

        const {getFieldDecorator} = this.props.form;

        const formItemLayout = {
            labelCol: {
                xs: {span: 24},
                sm: {span: 4},
            },
            wrapperCol: {
                xs: {span: 24},
                sm: {span: 20},
            },
        };


        return (
            <div id="masterpass-container" alt="マスターパスの変更"  className="tab-container">

                <div className="card general-search">
                        <Form className="search-form" onSubmit={this.onSubmit}>
                            <FormItem
                                {...formItemLayout}
                                label="現在のパスワード">
                                {getFieldDecorator('oldPassword', {
                                    initialValue: this.state.oldPassword,
                                    rules: [{
                                        min: 8,
                                        required: true, message: '8文字以上で現在のパスワードを入力してください',
                                    }],
                                })(
                                    <Input  type="password" placeholder="現在のパスワードを入力してください"
                                           onChange={(e) => {
                                               this.setState({
                                                   oldPassword: e.target.value
                                               })
                                           }}/>
                                )}
                            </FormItem>
                            <input type="password" name="dummy1" value="" style={{visibility: "hidden", top: "-100px", left: "-100px"}} />
                            <FormItem
                                {...formItemLayout}
                                label="新しいパスワード"
                                hasFeedback
                            >
                                {getFieldDecorator('newPassword', {
                                    initialValue: this.state.newPassword,
                                    rules: [{
                                        min: 8,
                                        required: true, message: '8文字以上で新しいパスワードを入力してください',
                                    }, {
                                        validator: this.checkConfirm,
                                    }],
                                })(
                                    <Input autoComplete="newpassword" type="password" placeholder="新しいパスワードを入力してください"
                                           onChange={(e)=>{
                                               this.setState({
                                                   newPassword: e.target.value
                                               })
                                           }}/>
                                )}
                            </FormItem>

                            <input type="password" name="dummypass" style={{visibility: "hidden", top: "-100px", left: "-100px"}} />
                            <FormItem
                                {...formItemLayout}
                                label="新しいパスワード再入力"
                                hasFeedback
                            >
                                {getFieldDecorator('confirm', {
                                    initialValue: this.state.confirm,
                                    rules: [{
                                        min: 8,
                                        required: true, message: '8文字以上で新しいパスワードを再入力してください',
                                    }, {
                                        validator: this.checkPassword,
                                    }],
                                })(
                                    <Input  autoComplete="confirm" type="password" onBlur={this.handleConfirmBlur}　 placeholder="新しいパスワードを再度入力してください"
                                           onChange={(e)=>{
                                               this.setState({
                                                   confirm: e.target.value
                                               })
                                           }}/>
                                )}
                            </FormItem>



                            <div className="submit-container">

                                <Button type="primary" htmlType="submit" className="search-form-button">
                                    変更
                                </Button>
                            </div>

                        </Form>

                </div>


            </div>
        );
    }

}


export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Form.create()(MasterPassChange))
