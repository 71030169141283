import React from 'react'
import {connect, store} from 'react-redux'
import PropTypes from 'prop-types';
import {instanceOf} from 'prop-types';
import * as Actions from 'redux/actions'
import log from 'components/utils/Logger.js'
import * as TagNodeGenerator from 'components/utils/TagNodeGenerator'
import moment from 'moment';
import 'moment/locale/ja';
import { Modal, Button, Table, Tooltip, Spin } from 'antd';
import FroalaEditorView from 'react-froala-wysiwyg/FroalaEditorView';
import {withRouter} from "react-router-dom";

moment.locale('ja');

const mapStateToProps = (state, props) => {
    return {
        screen: state.screen,
    }
};

const mapDispatchToProps = dispatch => {
    return {
        requestLogout: () => {
            dispatch(Actions.Connection.logout.request());
        }
    }
};

class ModuleLoginMessage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            visible: props.visible,
            data: props.data
        }
    }

    componentDidMount() {
    }

    componentWillReceiveProps(nextProps) {
        this.setState({
            visible: nextProps.visible,
            data: nextProps.data
        });
    }


    handleOk = () => {
        this.props.history.push("/dashboard")
    };




    render() {
        const {data, visible} = this.state;

        const footer =[
            <Button key={"logout"} size="large" type="primary" style={{marginRight: 8 }} onClick={()=>{this.props.requestLogout()}}>ログアウト</Button>,
            <Button size="large" type="primary" key="back" onClick={this.handleOk}>インフォメーションシステムホーム画面へ</Button>
        ];

        const titleNode = (
            <div className="module-notification-single-title">
                <div className="title"><span>{data.title}</span></div>
            </div>
        );
        return (
            <Modal
                className="common-modal module-questionnaire-single"
                style={{top: 20}}
                title={titleNode}
                visible={visible}
                maskClosable={false}
                closable={false}
                onOk={this.handleOk}
                footer={footer}
            >
                <div className='wrapper'>
                    <div className="notification-preview">
                        <FroalaEditorView model={data.content}/>
                    </div>
                </div>

            </Modal>
        )
    }
}


export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(ModuleLoginMessage));