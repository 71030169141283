import React from 'react'
import {connect, store} from 'react-redux'
import {instanceOf} from 'prop-types';
import log from 'components/utils/Logger';
import moment from 'moment';
import 'moment/locale/ja';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Tabs, Collapse } from 'antd';
import ContentHeader from "../../components/ContentHeader";

import UsersList from "./UsersList";
import RoleList from './RoleList'
import GroupList from "./GroupList";
import AuthProfileList from "./AuthProfileList";
import ComingSoon from "../../components/ComingSoon";
moment.locale('ja');

const TabPane = Tabs.TabPane;
const FormItem = Form.Item;
const Panel = Collapse.Panel;

const mapStateToProps = (state, props) => {
    return {
    }
};

const mapDispatchToProps = dispatch => {
    return {
    }
};

class Users extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
        };
    }


    onChangeTab = (key) =>{
    };

    render() {
        return (
            <div className='container clearfix' id="notification">
                <ContentHeader title="利用者管理" breadCrumbItems={[
                    {title:"利用者管理",icon:"team",path:"/console/users/"}
                ]}/>
                <Tabs
                    className="content-tab"
                    defaultActiveKey="1"
                    onChange={this.onChangeTab}
                    animated={false}>

                    <TabPane tab="ユーザ管理" key="1">
                        <UsersList />
                    </TabPane>

                    <TabPane tab="グループ属性管理" key="2">
                        <GroupList />
                    </TabPane>


                    <TabPane tab="権限管理" key="3">
                        <RoleList />
                    </TabPane>
                    <TabPane tab="認証プロファイル管理" key="4">
                        <AuthProfileList />
                    </TabPane>
                    {/*
                    <TabPane tab="マスターパスワード変更" key="5">
                    </TabPane>

                    <TabPane tab="キャンパス属性管理" key="5">

                    </TabPane>
                    */}
                </Tabs>
            </div>
        );
    }
}


Users.propTypes = {

};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Users);
