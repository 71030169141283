import React from 'react'
import {connect, store} from 'react-redux'
import {instanceOf} from 'prop-types';
import * as Actions from '../../redux/actions'
import * as GlobalConfig from '../../constants/GlobalConfig';

import moment from 'moment';
import 'moment/locale/ja';
import {DeleteOutlined, EditOutlined, PlusCircleOutlined} from '@ant-design/icons';
import {Form} from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import {
    Table,
    Radio,
    Select,
    Input,
    Row,
    Col,
    Button,
    DatePicker,
    Tooltip,
    Popconfirm,
    Badge,
} from 'antd';

import CalendarCategoryFormModal from "./CalendarCategoryFormModal.js";

moment.locale('ja');

const FormItem = Form.Item;
const Option = Select.Option;
const RadioButton = Radio.Button;
const RadioGroup = Radio.Group;
const RangePicker = DatePicker.RangePicker;

const mapStateToProps = (state, props) => {
    return {
        screen: state.screen,
        calendarCategorySearch: state.calendarCategorySearch,
        calendarCategoryCreate: state.calendarCategoryCreate,
        calendarCategoryUpdate: state.calendarCategoryUpdate,
        calendarCategoryDelete: state.calendarCategoryDelete,
    }
};

const mapDispatchToProps = dispatch => {
    return {
        searchCalendarCategory: (data) => {
            dispatch(Actions.AdminConnection.calendar.category.search.request(data));
        },
        createCalendarCategory: (data) => {
            dispatch(Actions.AdminConnection.calendar.category.create.request(data));
        },
        updateCalendarCategory: (uuid, data) => {
            dispatch(Actions.AdminConnection.calendar.category.update.request(uuid, data));
        },
        deleteCalendarCategory: (data) => {
            dispatch(Actions.AdminConnection.calendar.category.delete.request(data));
        }
    }
};

class CalendarList extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            search_type: "simple",
            addModalIsOpen: false,
            editModalIsOpen: false,
            editCalendarCategoryData: null,
            searchLoading: false,
            resultCalendarCategoryArray: [],

            pagination: {
                showTotal: (total, range) => {

                    return (`全${total}件中 ${range[0]}-${range[1]} 件`)
                },
                showSizeChanger: true,
                pageSizeOptions: GlobalConfig.PageSizeOptions,
                pageSize: 20,
                onShowSizeChange: this.onChangePageSize
            }
        };

    }

    componentWillMount() {
        this.props.searchCalendarCategory("");
    }


    componentWillReceiveProps(nextProps) {

        // 一覧・検索
        if (nextProps.calendarCategorySearch !== this.props.calendarCategorySearch) {
            if (!nextProps.calendarCategorySearch.meta.fetch) {
                if (!nextProps.calendarCategorySearch.error) {
                    if (nextProps.calendarCategorySearch.payload !== null && nextProps.calendarCategorySearch.payload.hasOwnProperty("result")) {
                        this.setState({
                            searchLoading: nextProps.calendarCategorySearch.meta.fetch,
                            resultCalendarCategoryArray: nextProps.calendarCategorySearch.payload.result.items,
                        })
                    }
                }
            } else {
                this.setState({
                    searchLoading: nextProps.calendarCategorySearch.meta.fetch
                })
            }
        }


        // 新規作成ß
        if (nextProps.calendarCategoryCreate !== this.props.calendarCategoryCreate) {
            if (!nextProps.calendarCategoryCreate.meta.fetch) {
                if (!nextProps.calendarCategoryCreate.error) {
                    if (nextProps.calendarCategoryCreate.payload !== null) {
                        //再読込
                        const fieldsValue = this.props.form.getFieldsValue();
                        this.props.searchCalendarCategory(fieldsValue);

                        this.setState({
                            addModalIsOpen: false
                        })
                    }
                }
            } else {
                this.setState({
                    //loading: nextProps.questionnaireCategoryCreate.meta.fetch
                })
            }
        }

        // 編集
        if (nextProps.calendarCategoryUpdate !== this.props.calendarCategoryUpdate) {
            if (!nextProps.calendarCategoryUpdate.meta.fetch) {
                if (!nextProps.calendarCategoryUpdate.error) {
                    if (nextProps.calendarCategoryUpdate.payload !== null) {
                        //再読込
                        const fieldsValue = this.props.form.getFieldsValue();
                        this.props.searchCalendarCategory(fieldsValue);

                        this.setState({
                            editModalIsOpen: false
                        })
                    }
                }
            } else {
                this.setState({})
            }
        }

        // 削除
        if (nextProps.calendarCategoryDelete !== this.props.calendarCategoryDelete) {
            if (!nextProps.calendarCategoryDelete.meta.fetch) {
                if (!nextProps.calendarCategoryDelete.error) {
                    if (nextProps.calendarCategoryDelete.payload !== null) {
                        //再読込
                        const fieldsValue = this.props.form.getFieldsValue();
                        this.props.searchCalendarCategory(fieldsValue);

                    }
                }
            } else {
                this.setState({
                    //loading: nextProps.questionnaireCategoryCreate.meta.fetch
                })
            }
        }
    }


    onChangePageSize = (current, pageSize) => {
        const pager = {...this.state.pagination};
        //pageSizeの更新
        pager.pageSize = pageSize;
        this.setState({
            pagination: pager,
        });
    };


    onChangeRadio = (e) => {
        this.setState({
            search_type: e.target.value
        })
    };

    onSubmit = (e) => {
        e.preventDefault();

        const fieldsValue = this.props.form.getFieldsValue();
        this.props.searchCalendarCategory(fieldsValue);

        return false;
    };

    handleResetSearchForm = () => {
        this.props.form.resetFields();

        const fieldsValue = this.props.form.getFieldsValue();
        this.props.searchCalendarCategory(fieldsValue);

    };

    handleOpenAddCategoryModal = () => {
        this.setState({
            addModalIsOpen: true
        })
    };

    handleOpenEditCategoryModal = (value) => {

        this.setState({
            editCalendarCategoryData: value,
            editModalIsOpen: true
        })
    };

    onSelectChange = (selectedRowKeys) => {

        this.setState({selectedRowKeys});
    };

    handleTableChange = (pagination, filters, sorter) => {
    };


    createCategory = (fieldValue, data) => {
        this.props.createCalendarCategory(fieldValue);
    };

    updateCategory = (fieldValue, data) => {
        this.props.updateCalendarCategory(data.uuid, fieldValue);
    };

    deleteCategory = (data) => {
        this.props.deleteCalendarCategory(data.uuid);
    };


    render() {
        const {getFieldDecorator} = this.props.form;
        const formItemLayout = {
            labelCol: {
                xs: {span: 24},
                sm: {span: 4},
            },
            wrapperCol: {
                xs: {span: 24},
                sm: {span: 20},
            },
        };
        const columns = [
            {
                title: 'カテゴリ名称',
                dataIndex: 'title',
                key: 'title',
                width: "300px"
            },
            {
                title: 'CalDAV URI',
                dataIndex: 'url',
                key: 'url',
            },
            {
                title: '状態',
                dataIndex: 'is_living',
                key: 'is_living',
                width: "90px",
                style: "tag",
                render: (is_living, record) => {
                    if (is_living === null) {
                        return <Badge status="default" text="同期中"/>;
                    } else if (is_living === true) {
                        return <Badge status="processing" text="公開中"/>;
                    } else {
                        return <Badge status="error" text="エラー"/>;
                    }

                }
            },
            {
                title: "作成時間/更新時間",
                dataIndex: "updated_at",
                key: "update_at",
                width: "180px",
                render: (text, record) => {
                    const update_at = text ? moment.unix(text).format("YYYY/MM/DD HH:mm") : "更新なし";
                    const created_at = moment.unix(record.created_at).format("YYYY/MM/DD HH:mm");
                    return (
                        <div>
                            {created_at}<br/>
                            {update_at}
                        </div>
                    )
                }
            },
            {
                title: "",
                width: "88px",
                key: 'action',
                fixed: "right",
                render: (text, record) => {

                    return (
                        <Row type="flex" justify="space-around" align="middle">
                            <Col>
                                <Tooltip placement="topLeft" title="編集">
                                    <Button
                                        type="default" shape="circle" icon={<EditOutlined/>}
                                        onClick={() => this.handleOpenEditCategoryModal(record)}/>
                                </Tooltip>
                            </Col>
                            <Col>
                                <Popconfirm
                                    title={<span>学年暦カテゴリを削除するとカテゴリ内の全てのスケジュールの配信が停止されます。<br/>
                                    スケジュール情報は削除されませんが、復元には再設定が必要となります。</span>}
                                    onConfirm={() => this.deleteCategory(record)}
                                    onCancel=""
                                    placement="left"
                                    okText="削除" cancelText="キャンセル">
                                    <Tooltip placement="topLeft" title="削除">
                                        <Button type="default" shape="circle" icon={<DeleteOutlined/>}/>
                                    </Tooltip>
                                </Popconfirm>
                            </Col>
                        </Row>
                    );
                },
            }
        ];

        return (
            <div id="questionnaire-list-container" className="tab-container" alt="学年暦一覧">
                <div className="card general-search">
                    <Form className="search-form" onSubmit={this.onSubmit}>
                        <FormItem
                            {...formItemLayout}
                            label="カテゴリ名称"
                            layout="inline">
                            {getFieldDecorator('title')(
                                <Input placeholder="カテゴリ名称を入力してください"/>
                            )}
                        </FormItem>
                        <FormItem
                            {...formItemLayout}
                            label="CalDAV URI"
                            layout="inline">
                            {getFieldDecorator('url')(
                                <Input placeholder="CalDAV URIを入力してください"/>
                            )}
                        </FormItem>
                        <div className="submit-container">
                            <Button className="search-form-button" onClick={this.handleResetSearchForm}>
                                リセット
                            </Button>
                            <Button type="primary" htmlType="submit" className="search-form-button">
                                検索
                            </Button>
                        </div>
                    </Form>
                </div>
                <div className="table-header-wrapper">
                    <div className="button-container">
                        <Button type="primary" onClick={this.handleOpenAddCategoryModal} icon={<PlusCircleOutlined/>}>
                            新規作成
                        </Button>
                    </div>
                </div>
                <Table
                    rowKey="uuid"
                    className="general-table"
                    locale={{
                        filterTitle: 'フィルタ',
                        filterConfirm: '確定',
                        filterReset: 'リセット',
                        emptyText: '該当するものはありません',
                    }}
                    scroll={{x: "100%"}}
                    bordered={true}
                    loading={this.state.searchLoading}
                    onChange={this.handleTableChange}
                    pagination={this.state.pagination}
                    columns={columns} dataSource={this.state.resultCalendarCategoryArray}
                    size="middle"
                />
                <CalendarCategoryFormModal
                    isEditMode={false}
                    visible={this.state.addModalIsOpen}
                    paramCategoryArray={this.state.paramCategoryArray}

                    handleCancel={() => {
                        this.setState({
                            addModalIsOpen: false
                        })
                    }}
                    handleOk={this.createCategory}/>
                <CalendarCategoryFormModal
                    isEditMode={true}
                    visible={this.state.editModalIsOpen}
                    handleCancel={() => {
                        this.setState({
                            editModalIsOpen: false
                        })
                    }}
                    data={this.state.editCalendarCategoryData}
                    handleOk={this.updateCategory}/>
            </div>
        );
    }
}


export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Form.create()(CalendarList))
