import React, {useState, useContext, useEffect} from 'react';
import PropTypes from 'prop-types';
import {Button, Input,Form} from "antd";
import {getDefaultLayout} from "../../constants/GlobalConfig";
import {ListContext, SearchContext} from "./MyTargetEffectComponent";
import {SearchOutlined} from "@ant-design/icons";

const layout = getDefaultLayout();


MyTargetSearch.propTypes = {
    
};

function MyTargetSearch(props) {
    const [list, setList] = useContext(ListContext);
    const [search, setSearch] = useContext(SearchContext);
    const [loading, setLoading] = useState(false);
    const [form] = Form.useForm();

    useEffect(()=>{
        onReset();
    },[props.visible])

    function onFinish(values) {
        const params = {
            ...values,
            page: 1
        };
        setSearch(params);
    }

    function onReset() {
        form.resetFields();
        setSearch({})
    }


    return (
        <div className="card general-search">
            <Form {...layout} form={form} name="control-hooks-notification-search" onFinish={onFinish}>
                <Form.Item name="name" label="グループ名" initialValue={search.name}>
                    <Input placeholder="グループ名を入力してください" />
                </Form.Item>
                <div className="submit-container">
                    <Button className="mr-4" htmlType="button" onClick={onReset}
                            loading={loading}>
                        リセット
                    </Button>
                    <Button type="primary" htmlType="submit" loading={loading} icon={<SearchOutlined/>}>
                        検索
                    </Button>
                </div>
            </Form>
        </div>
    );
}

export default MyTargetSearch;