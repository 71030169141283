import React from 'react'
import {connect, store} from 'react-redux'
import * as Actions from '../../redux/actions'
import * as GlobalConfig from '../../constants/GlobalConfig';

import moment from 'moment';
import 'moment/locale/ja';
import {Form} from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import {
    Table,
    Radio,
    Select,
    Input,
    Row,
    Col,
    Button,
    DatePicker,
    Tooltip,
    Popconfirm,
} from 'antd';

import AuthProfileFormModal from "./AuthProfileFormModal.js";

moment.locale('ja');

const FormItem = Form.Item;
const Option = Select.Option;
const RadioButton = Radio.Button;
const RadioGroup = Radio.Group;
const RangePicker = DatePicker.RangePicker;

const mapStateToProps = (state, props) => {
    return {
        screen: state.screen,
        authProfileSearch: state.authProfileSearch,
        authProfileCreate: state.authProfileCreate,
        authProfileUpdate: state.authProfileUpdate,
        authProfileDelete: state.authProfileDelete,
    }
};

const mapDispatchToProps = dispatch => {
    return {
        searchAuthProfile: (data) => {
            dispatch(Actions.AdminConnection.authprofile.search.request(data));
        },
        createAuthProfile: (data) => {
            dispatch(Actions.AdminConnection.authprofile.create.request(data));
        },
        updateAuthProfile: (uuid, data) => {
            dispatch(Actions.AdminConnection.authprofile.update.request(uuid, data));
        },
        deleteAuthProfile: (data) => {
            dispatch(Actions.AdminConnection.authprofile.delete.request(data));
        }
    }
};

class AuthProfileList extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            search_type: "simple",
            addModalIsOpen: false,
            editModalIsOpen: false,
            editAuthProfileData: null,
            searchLoading: false,
            resultAuthProfileArray: [],

            pagination: {
                showTotal: (total, range) => {

                    return (`全${total}件中 ${range[0]}-${range[1]} 件`)
                },
                showSizeChanger: true,
                pageSizeOptions: GlobalConfig.PageSizeOptions,
                pageSize: 20,
                onShowSizeChange: this.onChangePageSize
            }
        };

    }

    componentWillMount(){
        //fixme FOR STUB VIEW
        //this.props.searchAuthProfile("");
    }



    componentWillReceiveProps(nextProps) {

        // 一覧・検索
        if(nextProps.authProfileSearch !== this.props.authProfileSearch) {
            if (!nextProps.authProfileSearch.meta.fetch) {
                if (!nextProps.authProfileSearch.error) {
                    if (nextProps.authProfileSearch.payload !== null && nextProps.authProfileSearch.payload.hasOwnProperty("result")) {
                        this.setState({
                            searchLoading: nextProps.authProfileSearch.meta.fetch,
                            resultAuthProfileArray: nextProps.authProfileSearch.payload.result.items,
                        })
                    }
                }
            } else {
                this.setState({
                    searchLoading: nextProps.authProfileSearch.meta.fetch
                })
            }
        }


        // 新規作成ß
        if(nextProps.authProfileCreate !== this.props.authProfileCreate) {
            if (!nextProps.authProfileCreate.meta.fetch) {
                if (!nextProps.authProfileCreate.error) {
                    if (nextProps.authProfileCreate.payload !== null) {
                        //再読込
                        const fieldsValue = this.props.form.getFieldsValue();
                        this.props.searchAuthProfile(fieldsValue);

                        this.setState({
                            addModalIsOpen: false
                        })
                    }
                }
            } else {
                this.setState({
                    //loading: nextProps.questionnaireCategoryCreate.meta.fetch
                })
            }
        }

        // 編集
        if(nextProps.authProfileUpdate !== this.props.authProfileUpdate) {
            if (!nextProps.authProfileUpdate.meta.fetch) {
                if (!nextProps.authProfileUpdate.error) {
                    if (nextProps.authProfileUpdate.payload !== null) {
                        //再読込
                        const fieldsValue = this.props.form.getFieldsValue();
                        this.props.searchAuthProfile(fieldsValue);

                        this.setState({
                            editModalIsOpen: false
                        })
                    }
                }
            } else {
                this.setState({

                })
            }
        }

        // 削除
        if(nextProps.authProfileDelete !== this.props.authProfileDelete) {
            if (!nextProps.authProfileDelete.meta.fetch) {
                if (!nextProps.authProfileDelete.error) {
                    if (nextProps.authProfileDelete.payload !== null) {
                        //再読込
                        const fieldsValue = this.props.form.getFieldsValue();
                        this.props.searchAuthProfile(fieldsValue);

                    }
                }
            } else {
                this.setState({
                    //loading: nextProps.questionnaireCategoryCreate.meta.fetch
                })
            }
        }
    }

    onChangePageSize = (current, pageSize) => {
        const pager = {...this.state.pagination};
        //pageSizeの更新
        pager.pageSize = pageSize;
        this.setState({
            pagination: pager,
        });
    };


    onChangeRadio = (e) => {
        this.setState({
            search_type: e.target.value
        })
    };

    onSubmit = (e) => {
        e.preventDefault();

        const fieldsValue = this.props.form.getFieldsValue();
        this.props.searchAuthProfile(fieldsValue);

        return false;
    };

    handleResetSearchForm = () => {
        this.props.form.resetFields();

        const fieldsValue = this.props.form.getFieldsValue();
        this.props.searchAuthProfile(fieldsValue);

    };

    handleOpenAddCategoryModal = () => {
        this.setState({
            addModalIsOpen: true
        })
    };

    handleOpenEditCategoryModal = (value) => {

        this.setState({
            editAuthProfileData: value,
            editModalIsOpen: true
        })
    };

    onSelectChange = (selectedRowKeys) => {

        this.setState({selectedRowKeys});
    };

    handleTableChange = (pagination, filters, sorter) => {
    };


    createCategory = (fieldValue, data) => {
        this.props.createAuthProfile(fieldValue);
    };

    updateCategory = (fieldValue, data) => {
        this.props.updateAuthProfile(data.uuid, fieldValue);
    };

    deleteCategory = (data) => {
        this.props.deleteAuthProfile(data.uuid);
    };


    render() {

        const {getFieldDecorator} = this.props.form;

        const formItemLayout = {
            labelCol: {
                xs: {span: 24},
                sm: {span: 4},
            },
            wrapperCol: {
                xs: {span: 24},
                sm: {span: 20},
            },
        };

        const columns = [{
            title: '認証プロファイル名称',
            dataIndex: 'title',
            key: 'title'
        }, {
            title: '認証方式',
            dataIndex: 'type',
            key: 'type'
        }, {
            title: '摘要',
            dataIndex: 'memo',
            key: 'memo'
        }, {
            title: "作成者/更新者",
            dataIndex: 'author',
            key: 'author',
            render: (text, record) => {
                const created_by_name = record.created_by ? record.created_by.name : "作成者なし";
                const updated_by_name = record.updated_by ? record.updated_by.name : "更新者なし";
                return (
                    <span>{created_by_name}<br/>{updated_by_name}</span>
                );
            }
        }, {
            title: "作成時間/更新時間",
            dataIndex: "updated_at",
            key: "update_at",
            render: (text, record) => {
                const update_at = text ? moment.unix(text).format("YYYY/MM/DD HH:mm") : "更新なし";
                const created_at = moment.unix(record.created_at).format("YYYY/MM/DD HH:mm");
                return (
                    <div>
                        {created_at}<br/>
                        {update_at}
                    </div>
                )
            }
            // todo 変更削除を復帰させる際に必要
            // }, {
            //     title: "",
            //     width: "88px",
            //     key: 'action',
            //     render: (text, record) => {
            //
            //         if(record.type === "local") {
            //             return (
            //                 <Row type="flex" justify="space-around" align="middle">
            //                     <Col>
            //                         <Button
            //                             type="default" shape="circle" icon="edit" disabled={true}
            //                             onClick={() => this.handleOpenEditCategoryModal(record)}/>
            //                     </Col>
            //                     <Col>
            //                         <Button type="default" shape="circle" icon="delete" disabled={true}/>
            //
            //                     </Col>
            //                 </Row>
            //             );
            //         }else{
            //             return (
            //                 <Row type="flex" justify="space-around" align="middle">
            //                     <Col>
            //                         <Tooltip placement="topLeft" title="編集">
            //                             <Button
            //                                 type="default" shape="circle" icon="edit"
            //                                 onClick={() => this.handleOpenEditCategoryModal(record)}/>
            //                         </Tooltip>
            //                     </Col>
            //                     <Col>
            //                         {
            //
            //
            //                         }
            //                         <Popconfirm
            //                             title={<span><strong>認証プロファイルを削除すると「{record.title}」プロファイルと連携している全てのユーザがログインできなくなります。</strong><br />
            //                             この操作は取り消すことができません。インフォメーションシステム全体において、重大な問題が生じる可能性があります。</span>}
            //                             onConfirm={() => this.deleteCategory(record)}
            //                             onCancel=""
            //                             placement="left"
            //                             okText="削除" cancelText="キャンセル">
            //                             <Tooltip placement="topLeft" title="削除">
            //                                 <Button type="default" shape="circle" icon="delete"/>
            //                             </Tooltip>
            //                         </Popconfirm>
            //                     </Col>
            //                 </Row>
            //             );
            //         }
            //
            //
            //
            //
            //     },
        }

        ];

        const stubData = [
            {
                title: "インフォメーションシステム認証",
                type: "local",
                memo: "インフォメーションシステムのみで利用するためのローカルユーザを管理します",
                created_by: {
                    name: "システム"
                },
                updated_by: {
                    name: "システム"
                },
                created_at: 0,
                updated_at: 0,

            },
            {
                title: "関西大学統合認証システム",
                type: "OpenAM",
                memo: "関西大学の統合認証システムです",
                created_by: {
                    name: "作成者"
                },
                updated_by: {
                    name: "更新者"
                },
                created_at: 1509866800,
                updated_at: 1509866823,

            }
        ];

        return (
            <div id="questionnaire-list-container" className="tab-container" alt="学年暦一覧">
                {/*
                <div className="card general-search">
                    <Form className="search-form" onSubmit={this.onSubmit}>
                        <FormItem
                            {...formItemLayout}
                            label="認証プロファイル名称"
                            layout="inline">
                            {getFieldDecorator('title')(
                                <Input placeholder="カテゴリ名称を入力してください"/>
                            )}
                        </FormItem>
                        <FormItem
                            {...formItemLayout}
                            label="認証方式"
                            layout="inline">
                            {getFieldDecorator('url')(
                                <Select placeholder="認証方式を選択してください" allowClear={true}>
                                    <Option key="type" value="ldap">
                                        <div className="select-title">LDAP(s)</div>
                                        <div className="select-description">LDAP及びLDAPs互換サーバとの認証連携方式</div>
                                    </Option>
                                </Select>
                            )}
                        </FormItem>
                        <FormItem
                            {...formItemLayout}
                            label="摘要"
                            layout="inline">
                            {getFieldDecorator('memo')(
                                <Input placeholder="摘要を入力してください"/>
                            )}
                        </FormItem>

                        <div className="submit-container">

                            <Button className="search-form-button" onClick={this.handleResetSearchForm}>
                                リセット
                            </Button>

                            <Button type="primary" htmlType="submit" className="search-form-button">
                                検索
                            </Button>

                        </div>
                    </Form>
                </div>

                <div className="table-header-wrapper">
                    <div className="button-container">
                        <Button type="primary" onClick={this.handleOpenAddCategoryModal} icon="plus-circle-o">
                            新規作成
                        </Button>
                    </div>
                </div>
                */}
                <Table
                    className="general-table"
                    locale={{
                        filterTitle: 'フィルタ',
                        filterConfirm: '確定',
                        filterReset: 'リセット',
                        emptyText: '該当するものはありません',
                    }}
                    bordered={true}
                    loading={this.state.searchLoading}
                    onChange={this.handleTableChange}
                    columns={columns} dataSource={stubData}
                    size="middle"
                />
                <AuthProfileFormModal
                    isEditMode={false}
                    visible={this.state.addModalIsOpen}
                    paramCategoryArray={this.state.paramCategoryArray}

                    handleCancel={() => {
                        this.setState({
                            addModalIsOpen: false
                        })
                    }}
                    handleOk={this.createCategory}/>
                <AuthProfileFormModal
                    isEditMode={true}
                    visible={this.state.editModalIsOpen}
                    handleCancel={() => {
                        this.setState({
                            editModalIsOpen: false
                        })
                    }}
                    data={this.state.editAuthProfileData}
                    handleOk={this.updateCategory}/>
            </div>
        );
    }
}


export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Form.create()(AuthProfileList))
