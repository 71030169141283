import React from 'react'
import PropTypes from 'prop-types';
import {connect, store} from 'react-redux'
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import {
    Modal,
    Radio,
    Select,
    Row,
    Table,
    Col,
    Input,
    Button,
    DatePicker,
    InputNumber,
    Tooltip,
    Checkbox,
} from 'antd';
import log from 'components/utils/Logger'
import * as Actions from 'redux/actions'
import moment from 'moment';
import 'moment/locale/ja';
import jaJP from 'antd/lib/date-picker/locale/ja_JP';

moment.locale('ja')
const FormItem = Form.Item;
const Option = Select.Option;
const RadioButton = Radio.Button;
const RadioGroup = Radio.Group;
const RangePicker = DatePicker.RangePicker;
const {TextArea} = Input;
const CheckboxGroup = Checkbox.Group;
const confirm = Modal.confirm;

const TYPE_ENUM = {
    LINK: 1,
    NOTIFICATION: 2,
    QUESTIONNAIRE: 3,
    CALENDAR: 4,
    FILESHARE: 5
};

const mapStateToProps = (state, props) => {
    return {
        screen: state.screen,
        fileShareGetSetting: state.fileShareGetSetting,
    }
};

const mapDispatchToProps = dispatch => {
    return {
        getAgreement: () => {
            dispatch(Actions.Connection.fileshare.sharedSetting.request());
        },
        setAgreement: () => {
            dispatch(Actions.Connection.fileshare.sharedSettingAgreement.request({agree:true}))
        }
    }
};


class FileShareAgreementModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            visible: props.visible
        }
    }


    componentWillReceiveProps(nextProps) {
        if(nextProps.visible !== this.props.visible && nextProps.visible){
            this.setState({
                visible: nextProps.visible
            },()=>{
                this.props.getAgreement();
            })
        }

        if(nextProps.fileShareGetSetting !== this.props.fileShareGetSetting) {
            if (!nextProps.fileShareGetSetting.meta.fetch) {
                if (!nextProps.fileShareGetSetting.error) {
                    if (nextProps.fileShareGetSetting.payload !== null) {
                        this.state.visible ?
                            this.showConfirm("規約同意",nextProps.fileShareGetSetting.payload.result.agreement_text)
                            :
                            undefined;
                    }
                }
            }

        }
    };


    showConfirm = (title,content) => {
        const val = this;
        const a = this.props;
        confirm({
            title: title,
            content: content,
            onOk() {
                a.setAgreement();
                val.onCancel();
            },
            onCancel() {
                val.onCancel();
            },
        });
    }

    onCancel = () => {
        this.props.onCancel();
    }

    onOK = () => {
    }

    render() {
        return (
            <div />
        );
    }

}


export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Form.create()(FileShareAgreementModal))


FileShareAgreementModal.propTypes = {
    visible: PropTypes.bool.isRequired
}