import React from 'react'
import PropTypes from 'prop-types';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import {
    Modal,
    Radio,
    Select,
    Popconfirm,
    Input,
    InputNumber,
    Button,
    Tooltip,
    Table,
    DatePicker,
    Tag,
    Row,
    Col,
} from 'antd';
import jaJP from 'antd/lib/date-picker/locale/ja_JP';
import log from 'components/utils/Logger';
import moment from 'moment';
import 'moment/locale/ja';

import * as GlobalConfig from '../../constants/GlobalConfig'
import SelectTargetForm from "../common/SelectTargetForm";

const FormItem = Form.Item;
const Option = Select.Option;
const RadioButton = Radio.Button;
const RadioGroup = Radio.Group;
const {TextArea} = Input;

const RangePicker = DatePicker.RangePicker;


class ModalAddGroup extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            visible: props.visible,
            data: props.data
        }
    }


    componentWillReceiveProps(nextProps) {
        this.setState({
            visible: nextProps.visible,
            data: nextProps.data
        })


        if(nextProps.visible && nextProps.visible !== this.props.visible) {
            this.setState({
                data: nextProps.data,
                is_authority: nextProps.is_authority
            })
        }
        if(!nextProps.visible){
            this.resetFormAll();
        }
    }

    resetFormAll = () => {
        this.props.form.resetFields();
        this.setState({
            selectedRowKeys: [],
            name:"",
        })
    };


    handleOk = () => {
        //バリデーション
        this.props.form.validateFields((err, values) => {
            if (!err) {
                const fieldsValue = this.props.form.getFieldsValue();
                fieldsValue.order = 0;
                const is_authority = this.state.data.is_authority;
                fieldsValue.is_authority = is_authority;
                this.props.onOk(fieldsValue,this.state.data)
            }
        });
    };


    handleCancel = () => {
        this.props.onCancel();
    };

    handleSelectTarget = (data, type) => {
        this.setState({
            selectTargetFormModalIsOpen: false,
            target_uuid: data.uuid
        })
    };

    changeMenuOrder = (value) => {
        this.setState({
            menu_order: value
        })
    };

    onSelectChange = (selectedRowKeys, selectedRow) => {
        this.setState({selectedRowKeys, selectedRow});
    };

    render() {
        const formItemLayout = {
            labelCol: {
                xs: {span: 24},
                sm: {span: 4},
            },
            wrapperCol: {
                xs: {span: 24},
                sm: {span: 20},
            },
        };

        const {getFieldDecorator} = this.props.form;


        return (
            <Modal
                className="common-modal select-target-form"
                title="グループの追加"
                visible={this.state.visible}
                maskClosable={false}
                onOk={this.handleOk}
                onCancel={this.handleCancel}>
                <div className='wrapper'>
                    <h3>既存のグループ「{this.state.data.name}」に下記の名称で新規グループを追加します</h3>
                    <Form className="search-form" onSubmit={this.onSubmit}>
                        <FormItem
                            {...formItemLayout}
                            label="グループ名称">
                            {getFieldDecorator('name', {
                                initialValue: this.state.name ? this.state.name : "",
                                rules: [{required: true}]
                            })(
                                <Input placeholder="グループ名称を入力してください"/>
                            )}
                        </FormItem>
                    </Form>
                </div>
            </Modal>
        );
    }
}

export default Form.create()(ModalAddGroup);
