import React from 'react'
import {connect, store} from 'react-redux'
import {instanceOf} from 'prop-types';
import log from '../../components/utils/Logger.js'

import moment from 'moment';
import 'moment/locale/ja';
import { AppstoreOutlined, BarsOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Tabs, Collapse } from 'antd';
import ContentHeader from "../../components/ContentHeader";

import MenuWebList from "./MenuWebList";
import MenuSmartPhoneList from "./MenuSmartPhoneList";
import ModulesList from "./ModulesList";
import * as Actions from "../../redux/actions";


moment.locale('ja');

const TabPane = Tabs.TabPane;
const FormItem = Form.Item;
const Panel = Collapse.Panel;

const mapStateToProps = (state, props) => {
    return {
    }
};

const mapDispatchToProps = dispatch => {
    return {
        searchModules: (data) => {
            dispatch(Actions.AdminConnection.modules.search.request(data));
        },
    }
};

class Services extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
        };
    }

    onChangeTab = (key) =>{
        this.setState({
            currentKey: key
        })
        this.props.searchModules()
    }

    render() {
        return (
            <div className='container clearfix' id="notification">
                <ContentHeader title="メニュー管理" breadCrumbItems={[
                    {title:"メニュー管理",icon:"appstore-o",path:"/services/"}
                ]}/>
                <Tabs
                    className="content-tab"
                    defaultActiveKey="1"
                    onChange={this.onChangeTab}
                    animated={true}>
                    <TabPane tab={<span><BarsOutlined />&nbsp;Webメニュー管理</span>} key="1">
                        <MenuWebList />
                    </TabPane>
                    <TabPane tab={<span><BarsOutlined />&nbsp;スマートフォンリンクメニュー管理</span>} key="2">
                        <MenuSmartPhoneList />
                    </TabPane>
                    <TabPane tab={<span><AppstoreOutlined />&nbsp;モジュール管理</span>} key="3" forceRender={false}>
                        <ModulesList currentKey={this.state.currentKey} />
                    </TabPane>
                </Tabs>
            </div>
        );
    }
}


Services.propTypes = {

};


export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Services)

