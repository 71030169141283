import React,{useRef,useEffect} from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux'
import * as Actions from "../../redux/actions";
import {loginSsoConnection} from "../../redux/reducers";

LoginEffectComponent.propTypes = {
};

function LoginEffectComponent(props) {
    const dispatch = useDispatch();
    const loginConnection = useSelector(state => state.loginConnection);
    const loginSsoConnection = useSelector(state => state.loginSsoConnection);
    const checkLoginConnection = useSelector(state => state.checkLoginConnection);

    const isFirstCheckRender = useRef(false)
    useEffect(()=> {
        isFirstCheckRender.current = true;
    },[]);

    useEffect(() => {
        if(loginConnection.meta.status === Actions.statusEnum.SUCCESS && loginConnection.payload.is_successful === true){
            //サインイン完了時
            dispatch(Actions.session({
                isAuthenticated: true,
                authenticatedFrom: "portal",
                user: loginConnection.payload.user,
                result: loginConnection.payload.result,
                gmt: loginConnection.payload.gmt
            }))
        }
        if(loginConnection.meta.status === Actions.statusEnum.FAILURE || loginConnection.meta.status === Actions.statusEnum.ERROR){

        }
        if(loginSsoConnection.meta.status === Actions.statusEnum.SUCCESS && loginSsoConnection.payload.is_successful === true){
            //サインイン完了時
            dispatch(Actions.session({
                isAuthenticated: true,
                authenticatedFrom: "portal",
                user: loginSsoConnection.payload.user,
                result: loginSsoConnection.payload.result,
                gmt: loginSsoConnection.payload.gmt
            }))
        }
        if(loginSsoConnection.meta.status === Actions.statusEnum.FAILURE || loginSsoConnection.meta.status === Actions.statusEnum.ERROR){

        }
    }, [loginConnection,loginSsoConnection,dispatch]);

    useEffect(() => {
        if(checkLoginConnection.meta.status === Actions.statusEnum.SUCCESS){
            dispatch(Actions.session({
                isAuthenticated: checkLoginConnection.payload.result.login === "portal",
                authenticatedFrom: checkLoginConnection.payload.result.login,
                user: checkLoginConnection.payload.user,
                result: checkLoginConnection.payload.result,
                gmt: checkLoginConnection.payload.gmt
            }));
        }
        if(checkLoginConnection.meta.status === Actions.statusEnum.FAILURE){
            dispatch(Actions.session({
                isAuthenticated: checkLoginConnection.payload.result.login === "portal",
                authenticatedFrom: checkLoginConnection.payload.result.login,
                user: checkLoginConnection.payload.user,
                result: checkLoginConnection.payload.result,
                gmt: checkLoginConnection.payload.gmt
            }));
        }
        if(checkLoginConnection.meta.status === Actions.statusEnum.ERROR){

        }
    },[checkLoginConnection,dispatch])



    return null;
}

export default LoginEffectComponent;