import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import * as GlobalConfig from "../../constants/GlobalConfig";

// idで検索できるように埋め込むscript用の名前を定義
const SCRIPT1_NAME = 'gtag';
const SCRIPT2_NAME = 'gtagScript';

/** gtag.js読み込み用関数 */
export const initializeGA = (googleAnalyticsId) => {
    // scriptが既にある場合は一度削除
    if(document.getElementById(SCRIPT2_NAME) != null) {
        document.getElementById(SCRIPT2_NAME)?.remove();
    }
    if(document.getElementById(SCRIPT1_NAME) != null){
        document.getElementById(SCRIPT1_NAME)?.remove();
    }

    // トラッキングID or 測定IDが空の場合は終了
    if (googleAnalyticsId === '') return;

    // gtag.jsをheadタグに埋め込み
    const script1 = document.createElement('script');
    script1.id = SCRIPT1_NAME;
    script1.src = `https://www.googletagmanager.com/gtag/js?id=${googleAnalyticsId}`;
    script1.async = true;
    document.head.appendChild(script1);

    // 実行用scriptをheadタグに埋め込み
    const script2 = document.createElement('script');
    script2.id = SCRIPT2_NAME;
    script2.text = `window.dataLayer = window.dataLayer || [];
  function gtag() { dataLayer.push(arguments); }
  gtag('js', new Date());
  gtag('config', '${googleAnalyticsId}');`;
    document.head.appendChild(script2);
};