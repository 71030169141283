import React from 'react'
import PropTypes from 'prop-types';
import {BarsOutlined, StarOutlined, UserOutlined} from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import {
    Modal,
    Radio,
    Select,
    Popconfirm,
    Input,
    InputNumber,
    Button,
    Tooltip,
    Table,
    DatePicker,
    Tag,
    Row,
    Col,
} from 'antd';
import jaJP from 'antd/lib/date-picker/locale/ja_JP';
import log from 'components/utils/Logger';
import moment from 'moment';
import 'moment/locale/ja';

import * as GlobalConfig from '../../constants/GlobalConfig'
import SelectTargetForm from "../common/SelectTargetForm";
import MyTargetModal from "../common/MyTargetModal";

const FormItem = Form.Item;
const Option = Select.Option;
const RadioButton = Radio.Button;
const RadioGroup = Radio.Group;
const {TextArea} = Input;

const RangePicker = DatePicker.RangePicker;


class MenuAddModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isEditMode: false,
            visible: props.visible,
            data: props.data,
            modulesArray: props.modulesArray,
            selectTargetFormModalIsOpen: false,
            pagination: {
                showTotal: (total, range) => {
                    return (`全${total}件中 ${range[0]}-${range[1]} 件`)
                },
                showSizeChanger: true,
                pageSize: 300,
                pageSizeOptions: GlobalConfig.PageSizeOptions,
                onShowSizeChange: this.onChangePageSize
            },
            selectedRowKeys: [],
            selectedRow: []
        }
    }


    componentWillReceiveProps(nextProps) {
        this.setState({
            visible: nextProps.visible,
            modulesArray: nextProps.modulesArray
        });

        if(nextProps.visible && nextProps.visible !== this.props.visible) {
            this.props.reloadModules();
            let selectedRowKeys = [];
            let selectedRow = [];
            let modulesArray = nextProps.modulesArray? nextProps.modulesArray : [];
            let modules = nextProps.data? nextProps.data.modules : [];
            const name = nextProps.data? nextProps.data.name : "";
            const description = nextProps.data ? nextProps.data.description: "";
            const start_at = nextProps.data ? nextProps.data.start_at : "";
            const end_at = nextProps.data ? nextProps.data.end_at : "";
            const range_picker = nextProps.data ? [moment.unix(start_at), moment.unix(end_at)] : undefined;
            const menu_order = nextProps.data? nextProps.data.order: 1;
            const target_uuid = nextProps.data? nextProps.data.target_uuid: "";
            const uuid = nextProps.data? nextProps.data.uuid: "";

            selectedRow = modules;
            selectedRowKeys = modules.map((value) => {
                return value.uuid;
            })

            this.setState({
                isEditMode: nextProps.isEditMode,
                visible: nextProps.visible,
                data: nextProps.data,
                modulesArray: nextProps.modulesArray,
                confirmLoading: false,
                selectedRowKeys,
                selectedRow,
                name,
                description,
                range_picker,
                menu_order,
                target_uuid,
                uuid
            })
        }
        if(!nextProps.visible){
            this.resetFormAll();
        }
    }

    resetFormAll = () => {
        this.props.form.resetFields();
        this.setState({
            selectedRowKeys: [],
            name:"",
            description: "",
            menu_order: 0,
            range_picker: undefined,
            data: undefined
        })
    };


    handleOk = () => {
        //バリデーション
        this.props.form.validateFields((err, values) => {
            if (!err) {
                const fieldsValue = this.props.form.getFieldsValue();
                const range_picker = fieldsValue.range_picker;
                if (typeof range_picker !== 'undefined') {
                    fieldsValue.start_at = range_picker[0].unix();
                    fieldsValue.end_at = range_picker[1].unix();
                    delete fieldsValue.range_picker;
                }

                fieldsValue.target_uuid = this.state.target_uuid;
                let uuidArray;
                if(!this.state.selectedRow){
                    uuidArray = [];
                }else{
                    uuidArray = this.state.selectedRow.map((value, index) => {
                        return value.uuid;
                    })
                }


                fieldsValue.modules = uuidArray;

                fieldsValue.order = this.state.menu_order;
                this.props.handleOk(fieldsValue, this.props.data);
            }
        });
    };


    handleCancel = () => {
        this.props.handleCancel();
    };

    handleSelectTarget = (data, type) => {
        log.debug(data);

        this.setState({
            selectTargetFormModalIsOpen: false,
            target_uuid: data.uuid
        })
    };
    changeMenuOrder = (value) => {
        this.setState({
            menu_order: value
        })
    }

    onSelectChange = (selectedRowKeys, selectedRow) => {
        console.log(selectedRowKeys)
        console.log(selectedRow)
        this.setState({selectedRowKeys, selectedRow});
    };

    render() {
        const formItemLayout = {
            labelCol: {
                xs: {span: 24},
                sm: {span: 4},
            },
            wrapperCol: {
                xs: {span: 24},
                sm: {span: 20},
            },
        };

        const columns = [
            {
                title: 'モジュール種別',
                dataIndex: 'type',
                key: 'type',
                width: "110px",
                style: "tag",
                render: (text, record) => {
                    //モジュール種別(1: リンクBOX 2: お知らせ 3: アンケート 4: 学年暦 5:ファイル共有  6:教務情報)
                    switch (text) {
                        case 1: {
                            return <Tag className="tag normal">リンクボックス</Tag>;
                        }
                        case 2: {
                            return <Tag className="tag normal">お知らせ</Tag>;
                        }
                        case 3: {
                            return <Tag className="tag normal">アンケート</Tag>;
                        }
                        case 4: {
                            return <Tag className="tag normal">学年暦</Tag>;
                        }
                        case 5: {
                            return <Tag className="tag normal">ファイル共有</Tag>;
                        }
                        case 6: {
                            return <Tag className={"tag normal"}>教務掲示情報</Tag>
                        }
                        default : {
                            return <Tag className="tag gray2">不明なモジュール</Tag>;
                        }
                    }
                },
            },
            {
                title: 'モジュール名称',
                dataIndex: 'name',
                key: 'name',
            },
            {
                title: "摘要",
                dataIndex: "description",
                key: "description"
            }
        ];


        const {getFieldDecorator} = this.props.form;
        let title = !this.state.isEditMode ? <span><BarsOutlined />&nbsp;メニューの新規作成</span> :
            <span><BarsOutlined />&nbsp;メニューの編集</span>;

        const {selectedRowKeys} = this.state;


        const rowSelection = {
            selectedRowKeys,
            onChange: this.onSelectChange,
            hideDefaultSelections: true
        };
        return (
            <Modal
                className="common-modal select-target-form"
                title={title}
                visible={this.state.visible}
                maskClosable={false}
                onOk={this.handleOk}
                confirmLoading={this.confirmLoading}
                onCancel={this.handleCancel}>

                <div className='wrapper'>
                    <Form className="search-form" onSubmit={this.onSubmit}>
                        <FormItem
                            {...formItemLayout}
                            label="メニュー名称">
                            {getFieldDecorator('name', {
                                initialValue: this.state.name ? this.state.name : "",
                                rules: [{required: true}]
                            })(
                                <Input placeholder="メニュー名称を入力してください"/>
                            )}
                        </FormItem>

                        <FormItem
                            {...formItemLayout}
                            label="摘要">
                            {getFieldDecorator('description', {
                                initialValue: this.state.description ? this.state.description : ""
                            })(
                                <Input placeholder="摘要を入力してください"/>
                            )}
                        </FormItem>

                        <FormItem
                            {...formItemLayout}
                            label="公開期間"
                        >
                            {getFieldDecorator('range_picker', {
                                initialValue: this.state.range_picker,
                                rules: [{type: 'array', message: '公開期間を選択してください', required: true}]
                            })(
                                <RangePicker
                                    locale={jaJP}
                                    showTime={{format: 'HH:mm'}}
                                    format="YYYY/MM/DD HH:mm"
                                    ranges={GlobalConfig.DatePickerRanges()}/>
                            )}
                        </FormItem>

                        <FormItem
                            {...formItemLayout}
                            label="並び順">
                            <InputNumber
                                min={1}
                                defaultValue={1}
                                placeholder="並び順を入力してください"
                                value={this.state.menu_order}
                                onChange={this.changeMenuOrder}/>
                        </FormItem>
                        <FormItem
                            {...formItemLayout}
                            label={
                                <span>
                                     表示対象選択&nbsp;&nbsp;
                                 </span>
                            }>
                            <>
                                <Button type="primary" className="button" icon={<UserOutlined />}
                                    onClick={() => this.setState({selectTargetFormModalIsOpen: true})}>
                                    表示対象を選択
                                </Button>
                                <span>　</span>
                                <Button type="primary" className="button" icon={<StarOutlined />}
                                        onClick={()=>{this.setState({myTargetModalIsOpen: true})}}>
                                    よく使う対象者から選択
                                </Button>
                            </>
                        </FormItem>
                        <Table
                            className="general-table"
                            locale={{
                                filterTitle: 'フィルタ',
                                filterConfirm: '確定',
                                filterReset: 'リセット',
                                emptyText: '該当するものはありません',
                            }}
                            rowKey={"uuid"}
                            rowSelection={rowSelection}
                            bordered={true}
                            size="middle"
                            loading={this.state.searchLoading}
                            onChange={null}
                            columns={columns} dataSource={this.state.modulesArray}
                            pagination={this.state.pagination}
                        />
                    </Form>
                </div>


                {/*通知対象者*/}
                <SelectTargetForm
                    visible={this.state.selectTargetFormModalIsOpen}
                    handleOk={this.handleSelectTarget}
                    handleCancel={() => this.setState({selectTargetFormModalIsOpen: false})}
                    self_uuid={this.state.target_uuid /*制限オブジェクトUUID（新規NULL)*/}
                    foreign_uuid={this.state.uuid /*非制限対象オブジェクトUUID（新規NULL)
                    設定種類 (1: お知らせ 2: アンケート 3: メニュー 4: モジュール 5: モジュールリンク*/}
                    type={3}
                />
                {/*通知対象者を保存済のから取得して処理する*/}
                <MyTargetModal
                    visible={this.state.myTargetModalIsOpen}
                    handleOk={(value)=>{
                        this.setState({
                            target_uuid: value.target_uuid,
                            myTargetModalIsOpen: false
                        },()=>{
                            this.setState({selectTargetFormModalIsOpen: true})
                        });
                    }}
                    handleCancel={()=>{
                        this.setState({myTargetModalIsOpen: false})
                    }} />

            </Modal>
        );
    }
}

export default Form.create()(MenuAddModal);
