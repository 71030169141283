import React from 'react'
import {connect, store} from 'react-redux'
import { PlusCircleOutlined } from '@ant-design/icons';
import {Button} from 'antd';
import SelectTargetIPAddressList from './SelectTargetIPAddressList'
import log from 'components/utils/Logger.js'
import AddIPModal from './AddIPModal'

export default class SelectTargetFormIPAddress extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            addIPModalIsOpen: false,
            networkData: props.networkData
        }
    }

    componentWillReceiveProps(nextProps){
        this.setState({
            networkData: nextProps.networkData
        })

    }


    onSetState(state) {
        this.setState(state)
    }

    handleOk = (value) => {
        this.setState({
            addIPModalIsOpen: false
        });
        this.props.setObject(value);
    };

    handleCancel = () => {
        this.setState({
            addIPModalIsOpen: false
        })
    };

    render() {

        return (
            <div id="group-tree-container" className="wrapper" alt="グループ">
                <div className="group-tree-container-title-wrapper title-wrapper">
                    <div className="title-with-description">
                        <h2>許可対象ネットワーク</h2>
                        <p>設定項目が空の場合は<strong>全てのネットワークからのアクセスが許可</strong>されます。</p>
                    </div>
                    <Button type="primary" className="button"
                            onClick={()=> this.onSetState({ addIPModalIsOpen : true })} icon={<PlusCircleOutlined />}>
                        対象ネットワークを編集
                    </Button>
                </div>
                <SelectTargetIPAddressList networkData={this.state.networkData}/>
                <AddIPModal
                    visible={this.state.addIPModalIsOpen}
                    handleOk={this.handleOk}
                    handleCancel={this.handleCancel}
                    networkData={this.state.networkData}
                />
            </div>
        );
    }
}
