import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import ContentHeader from "../../components/ContentHeader";
import MyTargetList from "./MyTargetList";
import MyTargetEffectComponent from "./MyTargetEffectComponent";
import {Button, Form, Input, Modal, Table} from "antd";
import {UserOutlined} from "@ant-design/icons";
import MyTargetSearch from "./MyTargetSearch";
import {getDefaultLayout} from "../../constants/GlobalConfig";

MyTargetEditModal.propTypes = {

};

function MyTargetEditModal(props) {
    const [visible, setVisible] = useState(false);
    const [loading, setLoading] = useState(true);
    const [form] = Form.useForm();
    const layout = getDefaultLayout()

    useEffect(() => {
        setVisible(props.visible)
    }, [props.visible]);

    const onFinish = (values) => {
        props.handleOk(values)
    };


    const handleCancel = () => {
        //cancel構文
        props.handleCancel();
    };


    return (
        <Modal
            className="common-modal select-target-form"
            title={<span><UserOutlined/> 対象者グループ保存</span>}
            visible={visible}
            maskClosable={false}
            confirmLoading={loading}
            onCancel={()=>{handleCancel()}}
            footer={[
                <Button key="back-my-target-edit" size="large" onClick={()=>{handleCancel()}}>保存せずに閉じる</Button>,
                <Button key="create-my-target-edit" type={"primary"} size="large" onClick={form.submit}>保存</Button>
            ]}>
            <div id="group-tree-container" className="wrapper" alt="お気に入り対象グループ">
                <Form {...layout} form={form} name="control-hooks" onFinish={onFinish}>
                    <Form.Item name="name" label="グループ名" rules={[{ required: true }]}>
                        <Input/>
                    </Form.Item>
                    <Form.Item name="description" label="概要" >
                        <Input />
                    </Form.Item>
                </Form>
            </div>
        </Modal>
    );
};

export default MyTargetEditModal;