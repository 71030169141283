import React from 'react'
import {connect, store} from 'react-redux'
import PropTypes from 'prop-types';
import {instanceOf} from 'prop-types';
import log from 'components/utils/Logger.js'

import moment from 'moment';
import 'moment/locale/ja';
import {
    InboxOutlined,
    LoadingOutlined,
    PushpinOutlined,
    ArrowsAltOutlined,
    MailOutlined,
    LikeOutlined
} from '@ant-design/icons';
import {Form} from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import {Table, Tabs, Collapse, Card, Button, Tooltip} from 'antd';
import ReactGridLayout from 'react-grid-layout'
import ModuleNotificationModalSingle from './ModuleNotificationModalSingle';
import ModuleNotificationModalList from './ModuleNotificationModalList';
import * as Actions from 'redux/actions';
import * as TagNodeGenerator from 'components/utils/TagNodeGenerator'

import {Scrollbars} from 'react-custom-scrollbars';

moment.locale('ja');

const TabPane = Tabs.TabPane;
const Panel = Collapse.Panel;

const mapStateToProps = (state, props) => {
    return {
        screen: state.screen,
        notificationModule: state.notificationModule,
    }
};

const mapDispatchToProps = dispatch => {
    return {
        getCategory: () => {
            dispatch(Actions.Connection.notification.category.parameter.request());
        },
        searchNotification: (data, currentPageNum, moduleUUID) => {
            dispatch(Actions.Connection.notification.module.request(data, currentPageNum, moduleUUID));
        }
    }
};

class ModuleNotification extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            searchLoading: false,
            singleModalIsOpen: false,
            listModalIsOpen: false,
            defaultCategory: "",
            resultNotificationArray: [],
            etag: 0,
            parts: props.parts
        };
    }

    componentWillReceiveProps(nextProps) {
        const pagination = {...this.state.pagination};
        if (!nextProps.notificationModule.meta.fetch) {
            if (!nextProps.notificationModule.error) {
                if (nextProps.notificationModule.payload !== null) {
                    if (nextProps.notificationModule.meta.module_id === this.props.data.uuid) {
                        pagination.total = nextProps.notificationModule.payload.page_total;
                        this.setState({
                            searchLoading: nextProps.notificationModule.meta.fetch,
                            resultNotificationArray: nextProps.notificationModule.payload.result.items,
                            //通信後
                            pagination
                        })
                    }
                }
            }
        } else {
            this.setState({
                searchLoading: nextProps.notificationModule.meta.fetch
            })
        }

        this.setState({
            parts: nextProps.parts
        })
    }


    componentWillMount() {
        this.search();
    }


    search = () => {
        if (this.state.parts) {
            const page_size = this.state.parts.item_num;
            const status = this.state.parts.unread_only ? "unread" : undefined;
            let category_uuid = "";
            this.state.parts.categories.forEach((value, index) => {
                category_uuid += value;
                if (this.state.parts.categories.length > index + 1) {
                    category_uuid += ",";
                }
            });
            this.props.searchNotification({
                status: status,
                page_size: page_size,
                category_uuid: category_uuid
            }, undefined, this.props.data.uuid);
        } else {
            this.props.searchNotification(undefined, undefined, this.props.data.uuid);
        }
    };


    onChangeTab = (key) => {

    };

    handleClickSeeMore = () => {
        this.setState({
            defaultCategory: "ALL"
        }, () => {
            this.setState({
                listModalIsOpen: true
            })
        })
    };


    handleClickPin = () => {
        this.setState({
            defaultCategory: "PIN"
        }, () => {
            this.setState({
                listModalIsOpen: true
            })
        })
    };

    handleRowClick = (record, index, event) => {
        this.setState({
            singleModalIsOpen: true,
            selectUuid: record.uuid,
            selectRowTitle: record.title
        })
    };


    render() {
        const titleNode = (
            <div>
                <span className="module-title"><MailOutlined/> {this.props.title}</span>
                <span className={"module-icon"} onClick={this.handleClickPin}>
                    <Tooltip title="ピン留めされたお知らせを表示" color={"#555555"}><PushpinOutlined className={"pin"}/></Tooltip>
                </span>
                {this.props.seeMore ?
                    <Button className="module-title-button" onClick={this.handleClickSeeMore}>すべて表示</Button> : <span/>}
            </div>
        )

        const columns = [{
            dataIndex: 'key',
            key: 'key',
            render: (text, record) => {
                const tagNode = TagNodeGenerator.generate(TagNodeGenerator.TYPES_NOTIFICATION_PRIORITY, record.priority);
                const haveThumb = record.images?.length > 0;


                return (
                    <div className={`module-cell module-notification withImage`}>
                        <div className={"module-cell-body-wrapper"}>
                            <div className="module-cell-head module-notification-head">
                                <div className="date">{moment.unix(record.publish_start_at).format('YYYY.MM.DD')}</div>
                                <div className="tags-wrapper">
                                    {tagNode}
                                </div>
                            </div>
                            <div className="module-cell-body module-notification-body">{record.title}</div>
                            <div className="publish-from-wrapper">
                                <span>{record.publish_from}</span>
                            </div>
                        </div>
                        <div className={`is-good-icon ${record.is_reacted_good ? "like": "unlike"}`} onClick={this.props.setLike}>気になる！ {record.good_count}</div>
                        <div className={'module-cell-image-wrapper'}>
                            {haveThumb &&
                                <img src={`${process.env.REACT_APP_FILE_DOMAIN}/file/thumb/${record.images[0].uuid}`}/>
                            }
                        </div>
                    </div>
                )
            }
    }];

    return (
        <Card className="module-card" title={titleNode}>
            <Scrollbars autoHide>
                <div className="module-inner module-notification">
                    <Table
                        className="general-table"
                        locale={{
                            filterTitle: 'フィルタ',
                            filterConfirm: '確定',
                            filterReset: 'リセット',
                            emptyText: this.state.searchLoading ?
                                <span><span><LoadingOutlined/></span>&nbsp;読み込み中</span> :
                                <span><span><InboxOutlined/></span>&nbsp;現在未読のお知らせはありません</span>,
                        }}
                        pagination={false}
                        bordered={true}
                        showHeader={false}
                        columns={columns}
                        dataSource={this.state.resultNotificationArray}
                        size="small"
                        onRow={(record, rowIndex) => {
                            return {
                                onClick: event => {
                                    this.handleRowClick(record)
                                }
                            };
                        }}
                    />
                </div>
            </Scrollbars>

            <ModuleNotificationModalList
                visible={this.state.listModalIsOpen}
                singleModalIsOpen={this.state.singleModalIsOpen}
                defaultCategory={this.state.defaultCategory}
                handleCancel={() => {
                    this.setState({
                        listModalIsOpen: false
                    })
                }}
                handleRowClick={(record, index, event) => {
                    this.handleRowClick(record, index, event);
                }}
                handleOk={() => {
                    this.setState({
                        listModalIsOpen: false
                    })
                }}
            />

            <ModuleNotificationModalSingle
                visible={this.state.singleModalIsOpen}
                handleCancel={() => {
                    this.props.getCategory();
                    this.search();
                    this.setState({
                        singleModalIsOpen: false
                    })
                }}
                handleOk={() => {
                    this.props.getCategory();
                    this.search();
                    this.setState({
                        singleModalIsOpen: false
                    })

                }}
                uuid={this.state.selectUuid}
                title={this.state.selectRowTitle}
            />

        </Card>
    );
    }
    }


    export default connect(
    mapStateToProps,
    mapDispatchToProps
    )(ModuleNotification)
