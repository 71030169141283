import log from 'components/utils/Logger'
export const API_DOMAIN = process.env.REACT_APP_API_DOMAIN;

export const API_LOGIN = "/auth/login";
export const API_SSO_LOGIN = "/auth/sso-login"
export const API_LOGOUT = "/auth/logout";
export const API_CHECK_LOGGED_IN = "/auth/check-logged-in";


//お知らせ
export const API_NOTIFICATION = "/information";
export const API_NOTIFICATION_CATEGORY = "/information-category";
//お知らせ管理者用
export const API_ADMIN_NOTIFICATION = "/admin/information";
export const API_ADMIN_NOTIFICATION_CATEGORY = "/admin/information-category";


//アンケート
export const API_QUESTIONNAIRE = "/questionnaire";
export const API_QUESTIONNAIRE_CATEGORY = "/questionnaire-category";
//アンケート管理者用
export const API_ADMIN_QUESTIONNAIRE = "/admin/questionnaire";
export const API_ADMIN_QUESTIONNAIRE_CATEGORY = "/admin/questionnaire-category";

export const API_ANSWER = "/answer";
export const API_ADMIN_ANSWER = "/admin/answer";


//イメージのポスト
export const API_FILE_POST = "/file/create";
export const API_THUMB_POST = '/file/create-with-thumb/create';


//学年暦
export const API_CALENDAR = "/calendar";
export const API_CALENDAR_CATEGORY = "/calendar-source";
//学年暦　管理者
export const API_ADMIN_CALENDAR = "/admin/calendar";
export const API_ADMIN_CALENDAR_CATEGORY = "/admin/calendar-source";

//ファイル管理　管理者
export const API_ADMIN_FILE_SHARE = "/admin/shared-item";
export const API_ADMIN_FILE_SHARE_RECURSIVE = "/admin/shared-item/recursive";
export const API_ADMIN_FILE_SHARE_DOWNLOAD = `${process.env.REACT_APP_FILE_DOMAIN}/admin/shared-item/multiple-download`;
export const API_ADMIN_FILE_SHARE_FOLDER = "/admin/shared-folder";
export const API_ADMIN_FILE_SHARE_FILE = "/admin/shared-file";
export const API_ADMIN_FILE_SHARE_SETTING = "/admin/shared-setting";
//ファイル共有　ユーザ
export const API_FILE_SHARE = "/shared-item";
export const API_FILE_SHARE_RECURSIVE = "/shared-item/recursive";
export const API_FILE_SHARE_AGREEMENT = "/shared-item/check-agreement";
export const API_FILE_SHARE_DOWNLOAD = `${process.env.REACT_APP_FILE_DOMAIN}/admin/shared-item/multiple-download`;
export const API_FILE_SHARE_FILE = "/shared-file";
export const API_FILE_SHARE_SETTING = "/shared-setting";
export const API_FILE_SHARE_SETTING_AGREEMENT = "/shared-setting/agree";

//ユーザ管理
export const API_ACTION_USER = "/user";
export const API_ADMIN_ACTION_USER = "/admin/user";

//ロール管理
export const API_ADMIN_ROLE = "/admin/portal-permission";


//グループ管理
export const API_GROUP = "/group";
export const API_ADMIN_GROUP = "/admin/group";


//SSO管理
export const API_SSO = "/sso";
export const API_ADMIN_SSO = "/admin/sso";


//ログインメッセージ
export const API_ADMIN_LOGIN_MESSAGE_SETTING_VIEW = "/admin/login-message-setting/view";
export const API_ADMIN_LOGIN_MESSAGE_SETTING_SAVE = "/admin/login-message-setting/save";

//メニュー
export const API_MENU = "/menu";
export const API_MENU_SP = "/menu-sp";
export const API_MODULES = "/module";

//メニュー管理管理者
export const API_ADMIN_MENU = "/admin/menu";
export const API_ADMIN_MENU_SP = "/admin/menu-sp";
export const API_ADMIN_MODULES = "/admin/module";


//認証プロファイル管理
export const API_AUTH_PROFILE = "/admin/auth-profile";
export const API_ADMIN_AUTH_PROFILE = "/admin/auth-profile";

//アクションログ
export const API_ACTION_LOG = "/action-log";
export const API_ADMIN_ACTION_LOG = "/admin/action-log";
export const API_ADMIN_ACTION_LOG_DOWNLOAD = `${process.env.REACT_APP_FILE_DOMAIN}/admin/action-log/download-csv/?`;

//権限
export const API_PERMISSION = "/permission";
export const API_ADMIN_PERMISSION = "/admin/permission";

//権限の対象
export const API_TARGET = "/target";
export const API_ADMIN_TARGET = "/admin/target";
export const API_ADMIN_MY_TARGET = "/admin/my-target";

//■スケジュールの対象
export const API_SCHEDULE = "/class-schedule";
//全体
export const API_SCHEDULE_ALL = `${API_SCHEDULE}/all`
//休講
export const API_SCHEDULE_CANCEL = `${API_SCHEDULE}/cancel`
//補講
export const API_SCHEDULE_EXTRA = `${API_SCHEDULE}/extra`
//授業変更
export const API_SCHEDULE_CHANGE = `${API_SCHEDULE}/change`

/*
■■パス
・すべて
https://[domain]/api/class-schedule/all
・休講
https://[domain]/api/class-schedule/cancel
・補講
https://[domain]/api/class-schedule/extra
・授業変更
https://[domain]/api/class-schedule/change
 */


//レイアウト
export const API_LAYOUT = "/menu-layout";
export const API_ADMIN_LAYOUT = "/admin/menu-layout";

//マスターパスワードの変更
export const API_ADMIN_CHANGE_MASTER_PASS = "/admin/master-password";

//license
export const API_LICENSE = process.env.PUBLIC_URL + '/LICENSE.txt';


export class GenerateJson {
    static generateLoginJson(id,pass){
        let requestObject = {};
        requestObject.user_id = id;
        requestObject.password = pass;
        return requestObject;
    }
}
