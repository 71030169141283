import React, {createContext, useEffect, useRef, useState} from 'react';
import PropTypes from 'prop-types';
import {useSelector} from "react-redux";
import {statusEnum} from "../../../../redux/actions";

EffectModuleCurriculum.propTypes = {};

export const ListContext = createContext([{}, () => {
}])
export const SearchContext = createContext([{}, () => {
}])

function EffectModuleCurriculum(props) {
    const [list, setList] = useState({
        curriculumList: [],
        curriculumListItemCount: 0,
        curriculumListPageTotal: 0,
        loading: false
    });
    const [searchRule, setSearchRule] = useState({});
    const scheduleConnection = useSelector(state => state.scheduleConnection);

    const isFirstRender = useRef(false)
    useEffect(()=>{
        isFirstRender.current = true;

    },[])

    useEffect(()=>{
        setList(preview => ({
            ...preview,
            loading: scheduleConnection.meta.fetch
        }));
        if (!isFirstRender.current && scheduleConnection.meta.status === statusEnum.SUCCESS) {
            setList(preview => ({
                ...preview,
                curriculumList: scheduleConnection.payload.result.items,
                curriculumListItemCount: scheduleConnection.payload.result.item_count,
                curriculumListPageTotal: scheduleConnection.payload.result.page_total
            }))
        }
        if(isFirstRender.current){
            isFirstRender.current = false;
        }
    },[scheduleConnection])


    useEffect(()=>{

    },[searchRule])


    return (
        <ListContext.Provider value={[list, setList]}>
            {props.children}
        </ListContext.Provider>
    );
}

export default EffectModuleCurriculum;