import React from 'react';
import {connect, store} from 'react-redux';
import {instanceOf} from 'prop-types';
import log from 'components/utils/Logger.js';

import * as Actions from 'redux/actions';
import moment from 'moment';
import 'moment/locale/ja';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import {
    Table,
    Tabs,
    Radio,
    Select,
    Input,
    Col,
    Button,
    Checkbox,
    DatePicker,
    Collapse,
    Tag,
} from 'antd';
import * as GlobalConfig from '../../constants/GlobalConfig';
import jaJP from 'antd/lib/date-picker/locale/ja_JP';
import * as url from "../../constants/api";
import DownloadCSVConfirm from "../common/DownloadCSVConfirm";
import queryString from 'query-string';

moment.locale('ja');

const TabPane = Tabs.TabPane;
const FormItem = Form.Item;
const Panel = Collapse.Panel;
const Option = Select.Option;
const RadioButton = Radio.Button;
const RadioGroup = Radio.Group;
const RangePicker = DatePicker.RangePicker;

const mapStateToProps = (state, props) => {
    return {
        screen: state.screen,
        actionLog: state.actionLog
    }
};

const mapDispatchToProps = dispatch => {
    return {
        searchLog: (data, currentPageNum) => {
            dispatch(Actions.AdminConnection.log.search.request(data, currentPageNum));
        },
    }
};

class LogSearchList extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            pagination: {
                showTotal: (total, range) => {

                    return (`全${total}件中 ${range[0]}-${range[1]} 件`)
                },
                showSizeChanger: true,
                pageSizeOptions: GlobalConfig.PageSizeOptions,
                pageSize: 20,
                onShowSizeChange: this.onChangePageSize,
                total: 0,
            },
            actionLogArray:[],
            loading:false
        }
    }

    componentWillReceiveProps(nextProps){
        const pagination = {...this.state.pagination};
        const {actionLog} = {...this.props};

        if (nextProps.actionLog !== actionLog) {
            if (!nextProps.actionLog.meta.fetch) {
                if (!nextProps.actionLog.error) {
                    if (nextProps.actionLog.payload !== null) {
                        pagination.total = nextProps.actionLog.payload.result.item_count;

                        this.setState({
                            loading: nextProps.actionLog.meta.fetch,
                            actionLogArray: nextProps.actionLog.payload.result.items,
                            pagination
                        })
                    }
                }
            } else {
                this.setState({
                    loading: nextProps.actionLog.meta.fetch
                })
            }
        }

    }

    componentWillMount(){
        this.props.searchLog("");
    }

    onChangePageSize = (current, pageSize) => {
        const pager = {...this.state.pagination};
        //pageSizeの更新
        pager.pageSize = pageSize;
        this.setState({
            pagination: pager,
        });
    };

    onChangeRadio = (e) => {
        this.setState({
            search_type: e.target.value
        })
    };

    search(value) {
        let fieldsValue = this.props.form.getFieldsValue();

        let pagination;
        if (typeof value === "undefined") {
            pagination = {...this.state.pagination};
        } else {
            pagination = value;
        }

        fieldsValue.page_size = pagination.pageSize;

        const range_picker = fieldsValue.range_picker;
        if (typeof range_picker !== 'undefined' && range_picker.length !== 0) {
            fieldsValue.created_start = range_picker[0].unix();
            fieldsValue.created_end = range_picker[1].unix();
            delete fieldsValue.range_picker;
        }

        this.props.searchLog(fieldsValue,pagination.current);

    }

    handleTableChange = (pagination, filters, sorter) => {
        this.search(pagination);
    };


    onSubmit = (e) => {
        e.preventDefault();
        this.search();
        return false;
    };

    handleResetSearchForm = () => {
        this.props.form.resetFields();
        this.search();
    };

    handleCsvLogDownload = (encoding) => {
        let fieldsValue = this.props.form.getFieldsValue();
        const range_picker = fieldsValue.range_picker;
        if (typeof range_picker !== 'undefined' && range_picker.length !== 0) {
            fieldsValue.created_start = range_picker[0].unix();
            fieldsValue.created_end = range_picker[1].unix();
            delete fieldsValue.range_picker;
        }
        fieldsValue.encoding = encoding;
        let query = queryString.stringify(fieldsValue);

        if(query === "encoding=sjis" || query === "encoding=utf8"){
            alert("検索条件無しでのダウンロードは、総件数や待機時間の事情から許可されません。検索条件を入力してください")
        }else{
            let urlString = `${url.API_DOMAIN}${url.API_ADMIN_ACTION_LOG}/download-csv/?${query}`;
            window.open(urlString);
        }
    };


    render() {
        const {getFieldDecorator} = this.props.form;
        const rangeConfig = {
            rules: [{type: 'array', message: '公開期間を選択してください'}],
        };

        const formItemLayout = {
            labelCol: {
                xs: {span: 24},
                sm: {span: 4},
            },
            wrapperCol: {
                xs: {span: 24},
                sm: {span: 20},
            },
        };

        let availableSearchForm = (
                    <Form className="search-form" onSubmit={this.onSubmit}>

                        <FormItem
                            {...formItemLayout}
                            label="ログ種別"
                        >
                            {/*FIXME ログ種別の入力*/}
                            {getFieldDecorator('action', {

                                }
                            )(
                                <Select placeholder="ログ種別を選択してください" allowClear>
                                    <Option key="from" value="login">
                                        <div className="select-title">ログイン</div>
                                    </Option>
                                    <Option key="from" value="logout">
                                        <div className="select-title">ログアウト</div>
                                    </Option>
                                    <Option key="from" value="login_fail">
                                        <div className="select-title">ログイン失敗</div>
                                    </Option>
                                </Select>
                            )}
                        </FormItem>

                        <FormItem
                            {...formItemLayout}
                            label="プラットフォーム"
                        >
                            {getFieldDecorator('platform')(
                                <Select placeholder="プラットフォームを選択してください" allowClear>
                                    <Option key="pc" value="pc">
                                        <div className="select-title">Web</div>
                                    </Option>
                                    <Option key="iOS" value="iOS">
                                        <div className="select-title">iOS</div>
                                    </Option>
                                    <Option key="Android" value="Android">
                                        <div className="select-title">Android</div>
                                    </Option>
                                </Select>
                            )}
                        </FormItem>

                        <FormItem
                            {...formItemLayout}
                            label="記録日時"
                        >
                            {getFieldDecorator('range_picker',{
                                rules: [{type: 'array', message: '検索期間を選択してください'}]
                            })(
                                <RangePicker
                                    locale={jaJP}
                                    showTime={{ format: 'HH:mm' }}
                                    format="YYYY/MM/DD HH:mm"
                                    ranges={GlobalConfig.DatePickerRanges()}/>
                            )}
                        </FormItem>

                        <FormItem
                            {...formItemLayout}
                            label="利用者ID"
                            layout="inline">
                            {getFieldDecorator('user_id')(
                                <Input placeholder="利用者IDを入力してください"/>
                            )}
                        </FormItem>

                        <FormItem
                            {...formItemLayout}
                            label="氏名"
                            layout="inline">
                            {getFieldDecorator('name')(
                                <Input placeholder="氏名を入力してください"/>
                            )}
                        </FormItem>

                        <FormItem
                            {...formItemLayout}
                            label="リモートアドレス"
                            layout="inline">
                            {getFieldDecorator('remote_address')(
                                <Input placeholder="リモートアドレスを入力してください"/>
                            )}
                        </FormItem>

                        <div className="submit-container">

                            <Button className="search-form-button" onClick={this.handleResetSearchForm}>
                                リセット
                            </Button>

                            <Button type="primary" htmlType="submit" className="search-form-button">
                                検索
                            </Button>

                        </div>
                    </Form>
            );


        const columns = [
            {
                title: 'ログ種別',
                dataIndex: 'action',
                key: 'action',
                width: "85px",
                style: "tag",
                render: (text, record) => {

                    switch(text) {
                        case "login": {
                            return <Tag className="tag normal">ログイン</Tag>;
                        }
                        case "login_fail": {
                            return <Tag className="tag emergency">ログイン失敗</Tag>;
                        }
                        case "logout": {
                            return <Tag  className="tag option">ログアウト</Tag>;
                        }

                        default : {
                            return <Tag className="tag gray2">不明な種別</Tag>;
                        }
                    }

                }
            },{
                title: 'プラットフォーム',
                dataIndex: 'platform',
                key: 'platform',
                width: "130px",
                render: (text, record) => {

                    switch(text) {
                        case "pc": {
                            return <Tag className="tag blue-gray">Web</Tag>;
                        }
                        case "iOS": {
                            return <Tag className="tag gray">iOS</Tag>;
                        }
                        case "Android": {
                            return <Tag className="tag gray">Android</Tag>;
                        }
                        case "sp": {
                            return <Tag className="tag gray">スマートフォン（その他）</Tag>;
                        }
                        default : {
                            return <Tag className="tag gray2">不明な種別</Tag>;
                        }
                    }

                }
            },
            {
                title: "記録日時",
                dataIndex: "created_at",
                key: 'created_at',
                render: (text,record) => {
                    const created_at = moment.unix(text).format("YYYY/MM/DD HH:mm");
                    return(
                        <div>{created_at}</div>
                    )
                }
            },{
                title: "利用者ID",
                dataIndex: "user_id",
                key:"user_id",
                render: (text,record) =>{
                    if(record.created_by) {
                        return (
                            <div>{record.created_by.user_id}</div>
                        )
                    }else{
                        return <div />
                    }
                }
            },{
                title: '氏名',
                dataIndex: 'name',
                key: 'name',
                render: (text,record) =>{
                    if(record.created_by) {
                        return (
                            <div>{record.created_by.name}</div>
                        )
                    }else{
                        return <div />
                    }
                }
            },{
                title: 'リモートアドレス',
                dataIndex: 'remote_address',
                key: 'remote_address',
            },{
                title: '摘要',
                dataIndex: 'description',
                key: 'description',
                render : (text,record) => {
                    //unicodeデコード
                    return text ? text.replace(/\\u([a-fA-F0-9]{4})/g, function(m0, m1) {
                        return String.fromCharCode(parseInt(m1, 16));
                    }): "";
                }
            },];
        return (
            <div id="notification-list-container" alt="ログインログ参照"  className="tab-container">
                <div className="card general-search">
                    {availableSearchForm}
                </div>

                <div className="table-header-wrapper">
                    <div className="button-container">
                        <DownloadCSVConfirm
                            visible={true}
                            handleOk={(encoding) => this.handleCsvLogDownload(encoding)}/>
                    </div>
                </div>
                <Table
                    className="general-table"
                    size="middle"
                    locale={{
                        filterTitle: 'フィルタ',
                        filterConfirm: '確定',
                        filterReset: 'リセット',
                        emptyText: '該当するものはありません',
                    }}
                    bordered={true}
                    loading={this.state.loading}
                    pagination={this.state.pagination}
                    onChange={this.handleTableChange}
                    columns={columns} dataSource={this.state.actionLogArray} />
            </div>
        );
    }
}


export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Form.create()(LogSearchList))
