import React from 'react';
import {connect, store} from 'react-redux';
import {instanceOf} from 'prop-types';
import log from 'components/utils/Logger.js';
import {withRouter,Link} from 'react-router-dom';
import * as Actions from '../../redux/actions'
import moment from 'moment';
import 'moment/locale/ja';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Tabs, Collapse, Row, Col, Modal, Button } from 'antd';

moment.locale('ja');

const TabPane = Tabs.TabPane;
const FormItem = Form.Item;
const Panel = Collapse.Panel;

const mapStateToProps = (state, props) => {
    return {
        screen: state.screen,
        session: state.session,
        license: state.license
    }
};

const mapDispatchToProps = dispatch => {
    return {
        getLicense: () => {
            dispatch(Actions.Connection.license.request());
        }
    }
};

class LicenseModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            license: "",
            visible: true,
            loading: false,
        };
    }

    componentWillMount(){
        this.setState({
            loading: true
        });
        this.props.getLicense();
    }

    componentWillReceiveProps(nextProps) {
        log.debug(nextProps.license);
        if (!nextProps.license.meta.fetch) {
            if (!nextProps.license.error) {
                if (nextProps.license.payload !== null) {
                    //再読込
                    this.setState({
                        license: nextProps.license.payload,
                        loading:false,
                    })
                }
            }
        } else {
            this.setState({
                license: "",
                loading:false
            })
        }
    }

    handleCancel= () =>{
        this.props.handleCancel();
    };

    render() {

        const footer = [
                <Button key="back" size="large" onClick={this.handleCancel}>閉じる</Button>]
        ;

        return (

            <Modal
                style={{top: 20}}
                className="common-modal select-target-form"
                title={<span>ライセンス情報</span>}
                visible={this.state.visible}
                maskClosable={false}
                loading={this.state.loading}
                onOk={this.handleCancel}
                onCancel={this.handleCancel}
                confirmLoading={this.state.confirmLoading}
                footer={footer}>


                <div className='wrapper plain-text' style={{overflowY:"scroll",maxHeight:"500px"}}>
                    {this.state.license}
                </div>

            </Modal>

        )

    }
}


LicenseModal.propTypes = {

};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(LicenseModal);