import React from 'react';
import {connect, store} from 'react-redux';
import {instanceOf} from 'prop-types';

import moment from 'moment';
import 'moment/locale/ja';
import { DeleteOutlined, EditOutlined, PlusCircleOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import {
    Table,
    Tag,
    Radio,
    Select,
    Row,
    Input,
    Col,
    Button,
    Tooltip,
    DatePicker,
    Collapse,
    Popconfirm,
} from 'antd';

import log from '../../components/utils/Logger.js'
import * as GlobalConfig from '../../constants/GlobalConfig'
import * as Actions from '../../redux/actions'
import ModuleAddEditModal from './ModuleAddEditModal';

moment.locale('ja');


const FormItem = Form.Item;
const Panel = Collapse.Panel;
const Option = Select.Option;
const RadioButton = Radio.Button;
const RadioGroup = Radio.Group;
const RangePicker = DatePicker.RangePicker;

const mapStateToProps = (state, props) => {
    return {
        screen: state.screen,

        menu:state.menu,
        modules:state.modules,
        modulesCreate: state.modulesCreate,
        modulesUpdate: state.modulesUpdate,
        modulesDelete: state.modulesDelete,

        sso: state.ssoSearch,
    }
};

const mapDispatchToProps = dispatch => {
    return {
        searchSSO: (data, currentPageNum) => {
            dispatch(Actions.AdminConnection.sso.search.request(data, currentPageNum));
        },
        searchModules: (data, currentPageNum) => {
            dispatch(Actions.AdminConnection.modules.search.request(data,currentPageNum));
        },
        createModules : (data) => {
            dispatch(Actions.AdminConnection.modules.create.request(data));
        },
        updateModules: (uuid, data) => {
            dispatch(Actions.AdminConnection.modules.update.request(uuid, data));
        },
        deleteModules: (uuid) => {
            dispatch(Actions.AdminConnection.modules.delete.request(uuid));
        }
    }
};

class ModulesList extends React.Component {
    constructor(props) {
        super(props);
        let ssoArray = [];
        this.state = {
            ssoArray,
            modulesArray: [],
            pagination: {
                showTotal: (total, range) => {
                    return (`全${total}件中 ${range[0]}-${range[1]} 件`)
                },
                showSizeChanger: true,
                pageSize: 20,
                pageSizeOptions: GlobalConfig.PageSizeOptions,
                onShowSizeChange: this.onChangePageSize,
                total : 0,
            },
            addModalIsOpen: false,
            editModalIsOpen: false
        };

    }

    componentWillMount(){
        this.props.searchModules();
        this.props.searchSSO({page_size:500});
    }

    componentWillReceiveProps(nextProps) {
        const pagination = {...this.state.pagination};
        const {paramCategory, sso,modules,modulesCreate,modulesUpdate,modulesDelete, ssoCreate, ssoUpdate, ssoDelete} = {...this.props};

        if (nextProps.sso !== sso) {
            if (!nextProps.sso.meta.fetch) {
                if (!nextProps.sso.error) {
                    if (nextProps.sso.payload !== null) {
                        this.setState({
                            ssoLoading: nextProps.sso.meta.fetch,
                            ssoArray: nextProps.sso.payload.result.items
                        },()=> {
                            this.setState({
                                searchLoading: this.state.ssoLoading || this.state.modulesLoading,
                            })
                        })
                    }
                } else {
                    this.setState({
                        ssoLoading: nextProps.sso.meta.fetch,
                    },()=> {
                        this.setState({
                            searchLoading: this.state.ssoLoading || this.state.modulesLoading,
                        })
                    });
                }
            } else {

                this.setState({
                    ssoLoading: nextProps.sso.meta.fetch,
                },()=> {
                    this.setState({
                        searchLoading: this.state.ssoLoading || this.state.modulesLoading,
                    })
                });
            }
        }
        if (nextProps.modules !== modules) {
            if (!nextProps.modules.meta.fetch) {
                if (!nextProps.modules.error) {
                    if (nextProps.modules.payload !== null) {

                        pagination.total = nextProps.modules.payload.result.item_count;
                        this.setState({
                            modulesLoading: nextProps.modules.meta.fetch,
                            modulesArray: nextProps.modules.payload.result.items,
                            pagination
                        },()=> {
                            this.setState({
                                searchLoading: this.state.ssoLoading || this.state.modulesLoading,
                                editModalIsOpen :false,
                            })
                        });
                    }
                } else {
                    this.setState({
                        modulesLoading: nextProps.modules.meta.fetch,
                    },()=> {
                        log.debug(this.state.modulesLoading,this.state.ssoLoading,"modules error");
                        this.setState({
                            searchLoading: this.state.ssoLoading || this.state.modulesLoading,
                        })
                    });
                }
            } else {
                this.setState({
                    modulesLoading: nextProps.modules.meta.fetch,
                },()=> {
                    log.debug(this.state.modulesLoading,this.state.ssoLoading,"modules fetch");
                    this.setState({
                        searchLoading: this.state.ssoLoading || this.state.modulesLoading,
                    })
                });
            }
        }
        if (nextProps.modulesCreate !== modulesCreate) {
            if (!nextProps.modulesCreate.meta.fetch) {
                if (!nextProps.modulesCreate.error) {
                    if (nextProps.modulesCreate.payload !== null) {
                        this.setState({
                            modulesLoading: nextProps.modulesCreate.meta.fetch,
                        },()=> {
                            this.setState({
                                addModalIsOpen :false,
                                searchLoading: this.state.ssoLoading || this.state.modulesLoading,
                            })
                        });
                        this.search();
                    }
                } else {
                    this.setState({
                        modulesLoading: nextProps.modulesCreate.meta.fetch,
                    },()=> {
                        this.setState({
                            addModalIsOpen :false,
                            searchLoading: this.state.ssoLoading || this.state.modulesLoading,
                        })
                    });
                }
            } else {
                this.setState({
                    modulesLoading: nextProps.modulesCreate.meta.fetch,
                },()=> {
                    this.setState({
                        addModalIsOpen :false,
                        searchLoading: this.state.ssoLoading || this.state.modulesLoading,
                    })
                });
            }
        }

        if(nextProps.modulesUpdate !== modulesUpdate) {
            if (!nextProps.modulesUpdate.meta.fetch) {
                if (!nextProps.modulesUpdate.error) {
                    if (nextProps.modulesUpdate.payload !== null) {
                        this.setState({
                            modulesLoading: nextProps.modulesUpdate.meta.fetch,
                        },()=> {
                            this.setState({
                                editModalIsOpen :false,
                                searchLoading: this.state.ssoLoading || this.state.modulesLoading,
                            })
                        });
                        this.search();
                    }
                }else{
                    this.setState({
                        modulesLoading: nextProps.modulesUpdate.meta.fetch,
                    },()=> {
                        this.setState({
                            editModalIsOpen :false,
                            searchLoading: this.state.ssoLoading || this.state.modulesLoading,
                        })
                    });
                }
            } else {
                this.setState({
                    modulesLoading: nextProps.modulesUpdate.meta.fetch,
                },()=> {
                    this.setState({
                        editModalIsOpen :false,
                        searchLoading: this.state.ssoLoading || this.state.modulesLoading,
                    })
                });
            }
        }

        if(nextProps.modulesDelete !== modulesDelete) {
            if (!nextProps.modulesDelete.meta.fetch) {
                if (!nextProps.modulesDelete.error) {
                    if (nextProps.modulesDelete.payload !== null) {
                        this.setState({
                            modulesLoading: nextProps.modulesDelete.meta.fetch,
                        },()=> {
                            this.setState({
                                searchLoading: this.state.ssoLoading || this.state.modulesLoading,
                            })
                        });
                        this.search();
                    }
                }else{
                    this.setState({
                        modulesLoading: nextProps.modulesDelete.meta.fetch,
                    },()=> {
                        this.setState({
                            searchLoading: this.state.ssoLoading || this.state.modulesLoading,
                        })
                    });
                }
            } else {
                this.setState({
                    modulesLoading: nextProps.modulesDelete.meta.fetch,
                },()=> {
                    this.setState({
                        searchLoading: this.state.ssoLoading || this.state.modulesLoading,
                    })
                });
            }
        }


        if(nextProps.currentKey !== this.props.currentKey){
            this.handleResetSearchForm();
        }
    }

    search(value) {
        this.setState({
            ssoLoading: true,
            modulesLoading: true
        })


        let fieldsValue = this.props.form.getFieldsValue();

        let pagination;
        if(typeof value === "undefined"){
            pagination = { ...this.state.pagination };
        }else{
            pagination = {...this.state.pagination,...value};
        }
        fieldsValue.page_size = pagination.pageSize;
        this.setState({
            pagination
        })
        //ほぼそのまま検索
        this.props.searchModules(fieldsValue,pagination.current);
        this.props.searchSSO({page_size:500});
    }

    onSubmit = (e) => {
        e.preventDefault();
        this.search();
        return false;
    };

    onChangePageSize = (current, pageSize) => {
        const pager = {...this.state.pagination};
        //pageSizeの更新
        pager.pageSize = pageSize;
        this.setState({
            pagination: pager,
        });
    };

    handleResetSearchForm = () => {
        this.props.form.resetFields();
        this.search();
        this.setState({
            pagination: {
                ...this.state.pagination,
                current: 1
            }
        })
    };

    handleOpenEditModal(record) {
        this.setState({
            data:record,
            editModalIsOpen: true,
        });
    };

    handleTableChange = (pagination, filters, sorter) => {
        this.search(pagination);
    };

    handleOpenAddModal() {
        this.setState({
            addModalIsOpen: true,
        });
    }

    handleDelete(record) {
        this.props.deleteModules(record.uuid);
    }


    render() {


        const {getFieldDecorator} = this.props.form;

        const formItemLayout = {
            labelCol: {
                xs: {span: 24},
                sm: {span: 4},
            },
            wrapperCol: {
                xs: {span: 24},
                sm: {span: 20},
            },
        };

        let availableSearchForm = (
            <Form className="search-form" onSubmit={this.onSubmit}>

                <FormItem
                    {...formItemLayout}
                    label="モジュール種別"
                    layout="inline">
                    {getFieldDecorator('type')(
                        <Select placeholder="モジュール種別を選択してください">
                            <Option key="from" value="1">
                                <div className="select-title">リンクボックス</div>
                                <div className="select-description">通常リンクやSSO連携先リンクを表示します</div>
                            </Option>
                            <Option key="from" value="2">
                                <div className="select-title">お知らせ</div>
                                <div className="select-description">全てまたはカテゴリ別のお知らせ情報を表示します</div>
                            </Option>
                            <Option key="from" value="3">
                                <div className="select-title">アンケート</div>
                                <div className="select-description">全てまたはカテゴリ別のアンケート一覧を表示します</div>
                            </Option>
                            <Option key="from" value="4">
                                <div className="select-title">学年暦</div>
                                <div className="select-description">全てまたはカテゴリ別の学年暦を表示します</div>
                            </Option>
                            <Option key="from" value="5">
                                <div className="select-title">ファイル共有</div>
                                <div className="select-description">指定されたフォルダのファイル共有を表示します</div>
                            </Option>
                        </Select>
                    )}
                </FormItem>

                <FormItem
                    {...formItemLayout}
                    label="モジュール名称"
                    layout="inline">
                    {getFieldDecorator('name')(
                        <Input placeholder="モジュール名称を入力してください"/>
                    )}
                </FormItem>

                <FormItem
                    {...formItemLayout}
                    label="摘要"
                    layout="inline">
                    {getFieldDecorator('description')(
                        <Input placeholder="摘要を入力してください"/>
                    )}
                </FormItem>

                <div className="submit-container">

                    <Button className="search-form-button" onClick={this.handleResetSearchForm}>
                        リセット
                    </Button>

                    <Button type="primary" htmlType="submit" className="search-form-button">
                        検索
                    </Button>

                </div>
            </Form>
        );

        let {sortedInfo, filteredInfo} = this.state;
        sortedInfo = sortedInfo || {};
        filteredInfo = filteredInfo || {};

        const columns = [
            {
                title: 'モジュール種別',
                dataIndex: 'type',
                key: 'type',
                width: "110px",
                style: "tag",
                render: (text, record) => {
                    //モジュール種別(1: リンクBOX 2: お知らせ 3: アンケート 4: 学年暦 5:ファイル共有  6:教務情報)
                    switch (text) {
                        case 1: {
                            return <Tag className="tag normal">リンクボックス</Tag>;
                        }
                        case 2: {
                            return <Tag className="tag normal">お知らせ</Tag>;
                        }
                        case 3: {
                            return <Tag className="tag normal">アンケート</Tag>;
                        }
                        case 4: {
                            return <Tag className="tag normal">学年暦</Tag>;
                        }
                        case 5: {
                            return <Tag className={"tag normal"}>ファイル共有</Tag>
                        }
                        case 6: {
                            return <Tag className={"tag normal"}>教務掲示情報</Tag>
                        }
                        default : {
                            return <Tag className="tag gray2">不明なモジュール</Tag>;
                        }
                    }　
                },
            }, {
                title: 'モジュール名称',
                dataIndex: 'name',
                key: 'name',
            }, {
                title: "摘要",
                dataIndex: "description",
                key:"description"
            },{
                title: "作成時間/更新時間",
                dataIndex: "updated_at",
                key: "update_at",
                width: "180px",
                render: (text, record) => {
                    const update_at = text ? moment.unix(text).format("YYYY/MM/DD HH:mm") : "更新なし";
                    const created_at = moment.unix(record.created_at).format("YYYY/MM/DD HH:mm");
                    return (
                        <div>
                            {created_at}<br/>
                            {update_at}
                        </div>
                    )
                }
            },{
                title: "",
                width: "85px",
                key: 'action',
                render: (text, record) => {

                    return (
                        <Row type="flex" justify="space-around" align="middle">
                            <Col>
                                <Tooltip placement="topLeft" title="編集">
                                    <Button type="default" shape="circle" icon={<EditOutlined />}
                                            onClick={() => this.handleOpenEditModal(record)}/>
                                </Tooltip>
                            </Col>
                            <Col>
                                <Popconfirm
                                    title={<span>サービスを削除するとサービス内に設定されているモジュール構成が削除されます。<br />
                                        <strong>削除すると復元することはできません。</strong></span>}
                                    onConfirm={() => this.handleDelete(record)}
                                    onCancel=""
                                    placement="left"
                                    okText="削除" cancelText="キャンセル">
                                    <Tooltip placement="topLeft" title="削除">
                                        <Button type="default" shape="circle" icon={<DeleteOutlined />} />
                                    </Tooltip>
                                </Popconfirm>
                            </Col>
                        </Row>
                    );
                }
            }
        ];


        return (
            <div id="notification-list-container" alt="SSO連携管理"  className="tab-container">
                <div className="card general-search">
                    {availableSearchForm}
                </div>

                <div className="table-header-wrapper">
                    <div className="button-container">
                        <Button type="primary" icon={<PlusCircleOutlined />}
                                onClick={() => this.handleOpenAddModal()}>
                            新規作成
                        </Button>
                    </div>
                </div>

                <Table
                    className="general-table"
                    locale={{
                        filterTitle: 'フィルタ',
                        filterConfirm: '確定',
                        filterReset: 'リセット',
                        emptyText: '該当するものはありません',
                    }}
                    bordered={true}
                    size="middle"
                    loading={this.state.searchLoading}
                    onChange={this.handleTableChange}
                    columns={columns} dataSource={this.state.modulesArray}
                    pagination={this.state.pagination}
                />

                <ModuleAddEditModal
                    isEditMode={false}
                    visible={this.state.addModalIsOpen}
                    handleCancel={() => {
                        this.setState({
                            addModalIsOpen: false
                        })
                    }}
                    handleOk={(fieldValue, data) => {
                        log.debug(fieldValue);
                        log.debug(data);

                        this.props.createModules(fieldValue);
                    }}
                    data={undefined}
                    ssoArray={this.state.ssoArray}
                />

                <ModuleAddEditModal
                    isEditMode={true}
                    visible={this.state.editModalIsOpen}
                    handleCancel={() => {
                        this.setState({
                            editModalIsOpen: false
                        })
                    }}
                    handleOk={(fieldValue, uuid) => {
                        log.debug(fieldValue);
                        this.props.updateModules(uuid, fieldValue);
                    }}
                    data={this.state.data}
                    ssoArray={this.state.ssoArray}
                />
            </div>
        );
    }

}


export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Form.create()(ModulesList))
