import React from 'react'
import PropTypes from 'prop-types';
import { LinkOutlined, MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Modal, Radio, Select, Input, Table, Row, Col, Button } from 'antd';
import * as GlobalConfig from '../../constants/GlobalConfig';
import log from 'components/utils/Logger';
import * as UUID from 'components/utils/UUID.js'

const FormItem = Form.Item;
const Option = Select.Option;
const RadioButton = Radio.Button;
const RadioGroup = Radio.Group;
const {TextArea} = Input;

class SSOFormModal extends React.Component {
    constructor(props) {
        super(props);
        const modalTitle = props.isEditMode ? "SSO連携先の編集" : "SSO連携先の新規作成";
        const name = props.data ? props.data.name : "";
        const url = props.data ? props.data.url : "";
        const memo = props.data ? props.data.memo : "";
        const type = props.data ? props.data.type : 1;

        const params = props.data ? props.data.params : [];
        const paramsUuidArray = props.data ? props.data.paramsUuidArray : [];
        const paramsObject = {};

        this.state = {
            modalTitle,
            name,
            url,
            type,
            memo,
            params,
            paramsUuidArray,
            paramsObject,
            isEditMode: false,
            visible: props.visible,
            data: props.data,
        }
    }

    componentWillReceiveProps(nextProps) {
        log.debug(nextProps.data);

        if (nextProps.visible && !this.props.visible && nextProps.isEditMode && nextProps.data) {
            const modalTitle = nextProps.isEditMode ? "SSO連携先の編集" : "SSO連携先の新規作成";
            const name = nextProps.data ? nextProps.data.name : "";
            const url = nextProps.data ? nextProps.data.url : "";
            const memo = nextProps.data ? nextProps.data.memo : "";
            const type = nextProps.data ? nextProps.data.type : 1;
            const params = nextProps.data ? nextProps.data.params : [];
            const paramsUuidArray = params.map((value, index) => {
                return value.uuid;
            });
            const paramsObject = {};
            params.forEach((value) => {
                paramsObject[value.uuid] = {
                    name: value.name,
                    value: value.value,
                    memo: value.memo
                }
            })

            this.setState({
                modalTitle,
                name,
                url,
                type,
                memo,
                params,
                paramsUuidArray,
                visible: nextProps.visible,
                data: nextProps.data,
                confirmLoading: false,

                paramsObject,
            })
        }
        this.setState({
            visible: nextProps.visible
        })


        if(!nextProps.visible){
            this.props.form.resetFields();
            this.setState({
                paramsObject:{},
                paramsUuidArray: [],
                params: []
            })
        }
    }

    remove = (uuid) => {
        log.debug(uuid);
        let paramsUuidArray = this.state.paramsUuidArray;
        paramsUuidArray.some((v, i) => {
            if (v === uuid) paramsUuidArray.splice(i, 1);
        });

        const paramsObject = this.state.paramsObject;
        delete paramsObject[uuid];
        this.setState({
            paramsUuidArray,
            paramsObject
        })
    };

    add = () => {
        const newUUID = UUID.generate();
        const keys = typeof this.state.paramsUuidArray === "undefined" ? [1] : this.state.paramsUuidArray;
        const nextKeys = keys.concat(newUUID);

        const paramsObject = this.state.paramsObject;
        paramsObject[newUUID] = {
            name: "",
            value: "",
            memo: ""
        }

        this.setState({
            paramsUuidArray: nextKeys,
            paramsObject
        });
    };


    handleOk = (fieldValue, data) => {
        this.setState({
            confirmLoading: true,
        });

        const array = this.state.paramsUuidArray.map((value) => {
            if(this.state.paramsObject[value]){
                if(this.state.paramsObject[value].name !== "" ){
                    return {
                        name: this.state.paramsObject[value].name,
                        value: this.state.paramsObject[value].value,
                        memo: ""
                    }
                }
            }
        })
        let postFieldValue = fieldValue;
        postFieldValue.params = array;


        this.props.handleOk(postFieldValue, data);
    };

    handleCancel = () => {
        this.props.handleCancel();
    };

    changeName = (e, k) => {
        log.debug(e, k);
        const paramsObject = this.state.paramsObject;
        paramsObject[k].name = e.target.value;

        this.setState({
            paramsObject
        })
    }

    changeValue(e, k) {
        log.debug(e, k);
        const paramsObject = this.state.paramsObject;
        paramsObject[k].value = e.target.value;

        this.setState({
            paramsObject
        })
    }

    render() {

        const formItemLayout = {
            labelCol: {
                xs: {span: 24},
                sm: {span: 4},
            },
            wrapperCol: {
                xs: {span: 24},
                sm: {span: 20},
            },
        };

        const {getFieldDecorator} = this.props.form;

        let title = this.state.isEditMode ?
            <span><LinkOutlined />&nbsp;SSO連携先の新規作成</span>
            :
            <span><LinkOutlined />&nbsp;SSO連携先の編集</span>;

        log.debug(this.state.paramsUuidArray);

        let paramsArray = this.state.paramsUuidArray.map((value, index) => {
            const object = this.state.paramsObject[value];
            log.debug(object);
            log.debug(value);
            return (
                <Row gutter={24}>
                    <Col span={11}>
                        <FormItem
                            {...formItemLayout}
                            label="Key"
                            layout="inline">

                            <Input placeholder="Key" value={object.name} onChange={(e) => this.changeName(e, value)}/>


                        </FormItem>
                    </Col>
                    <Col span={11}>
                        <FormItem
                            {...formItemLayout}
                            label="value"
                            layout="inline">
                            <Input placeholder="name" value={object.value}
                                   onChange={(e) => this.changeValue(e, value)}/>
                        </FormItem>
                    </Col>
                    <Col span={2}>
                        <MinusCircleOutlined
                            className="dynamic-delete-button"
                            disabled={index === 0}
                            onClick={() => this.remove(value)} />
                    </Col>
                </Row>
            );
        });

        let formAuthForm = (
            <div>
                <h2>FORM認証方式オプション</h2>
                <FormItem
                    {...formItemLayout}
                    label="リクエストメソッド"
                    layout="inline">
                    {getFieldDecorator('method', {
                        initialValue: typeof this.state.type !== "undefined" ? this.state.type : 1,
                        rules: [{
                            required: true, message: 'リクエストメソッドを選択してください',
                        }]
                    })(
                        <RadioGroup>
                            <Radio value={0}>GET</Radio>
                            <Radio value={1}>POST</Radio>
                        </RadioGroup>
                    )}
                </FormItem>
                <h3>送信パラメータ（クエリ）</h3>
                {paramsArray}
                <Button type="dashed" onClick={this.add} style={{width: '100%'}}>
                    <PlusOutlined /> 送信パラメータを追加
                </Button>
            </div>
        );


        let authForm = formAuthForm;


        return (
            <Modal
                className="common-modal select-target-form"
                title={title}
                style={{top: 20}}
                visible={this.state.visible}
                maskClosable={false}
                onOk={() => this.handleOk(this.props.form.getFieldsValue(), this.state.data)}
                confirmLoading={this.confirmLoading}
                onCancel={this.handleCancel}>

                <div className='wrapper'>
                    <Form className="search-form" onSubmit={this.onSubmit}>
                        <div>
                            <h2>SSO連携プロファイル全般項目</h2>
                            <FormItem
                                {...formItemLayout}
                                label="サイト名称"
                                layout="inline">
                                {getFieldDecorator('name', {
                                    initialValue: typeof this.state.name !== "undefined" ? this.state.name : "",
                                    rules: [{
                                        required: true, message: 'サイト名称を入力してください',
                                    }],
                                })(
                                    <Input placeholder="サイト名称を入力してください"
                                           onChange={(value) => {
                                               this.setState({
                                                   name: value.target.value
                                               })
                                           }}/>
                                )}
                            </FormItem>

                            <FormItem
                                {...formItemLayout}
                                label="連携先URI"
                                layout="endpoint">
                                {getFieldDecorator('url', {
                                    initialValue: typeof this.state.url !== "undefined" ? this.state.url : "",
                                    rules: [{
                                        required: true, message: '連携先URIを入力してください',
                                    }],
                                })(
                                    <Input placeholder="連携先URIを入力してください"/>
                                )}
                            </FormItem>

                            <FormItem
                                {...formItemLayout}
                                label="摘要"
                                layout="inline">
                                {getFieldDecorator('memo', {
                                    initialValue: typeof this.state.memo !== "undefined" ? this.state.memo : "",
                                })(
                                    <Input placeholder="摘要を入力してください"/>
                                )}
                            </FormItem>

                            <FormItem
                                {...formItemLayout}
                                label="連携方式"
                                layout="inline">
                                {getFieldDecorator('auth_type_input', {
                                    initialValue: typeof this.state.type !== "undefined" ? this.state.type : 1,
                                    rules: [{
                                        required: true, message: '連携方式を選択してください',
                                    }],
                                })(
                                    <Select placeholder="連携方式を選択してください">
                                        <Option key={1} value={1}>
                                            <div className="select-title">FORM認証</div>
                                            <div className="select-description">POSTまたはGETメソッドを使用してFORMデータを送信します</div>
                                        </Option>
                                    </Select>
                                )}
                            </FormItem>
                        </div>

                        {authForm}

                    </Form>


                </div>

            </Modal>
        );
    }
}


export default Form.create()(SSOFormModal)

SSOFormModal.propTypes = {
    visible: PropTypes.bool.isRequired,
    isEditMode: PropTypes.bool.isRequired,
    handleOk: PropTypes.func.isRequired,
    handleCancel: PropTypes.func.isRequired,
    data: PropTypes.object
};