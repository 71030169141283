import React from 'react';
import {connect, store} from 'react-redux';
import {instanceOf} from 'prop-types';
import * as GlobalConfig from '../../../constants/GlobalConfig';
import * as url from '../../../constants/api';
import * as Actions from '../../../redux/actions';
import CodeMirror from 'codemirror/lib/codemirror';
import moment from 'moment';
import 'moment/locale/ja';
import log from 'components/utils/Logger';
import {QuestionCircleOutlined} from '@ant-design/icons';
import {Form} from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import {
    Table,
    Radio,
    Select,
    Spin,
    Row,
    Input,
    Col,
    Button,
    Tooltip,
    InputNumber,
    DatePicker,
    Collapse,
    Popconfirm,
    Checkbox,
    Tag,
    Upload,
    message,
} from 'antd';

import SelectPermissionForm from '../../common/SelectPermissionForm';
import jaJP from "antd/lib/date-picker/locale/ja_JP";
// Require Editor JS files.
import 'froala-editor/js/froala_editor.pkgd.min.js';
import "froala-editor/js/languages/ja.js";
import "froala-editor/js/plugins/char_counter.min.js"
import "froala-editor/js/plugins/code_beautifier.min.js"
import "froala-editor/js/plugins/code_view.min.js"
import "codemirror/mode/htmlmixed/htmlmixed.js";
// Require Editor CSS files.
import 'froala-editor/css/froala_style.min.css';
import 'froala-editor/css/froala_editor.pkgd.min.css';
import 'froala-editor/css/themes/gray.min.css';
import "froala-editor/css/plugins/char_counter.min.css"
import "froala-editor/css/plugins/code_view.min.css"
import 'codemirror/lib/codemirror.css';
// Require Font Awesome.
import 'font-awesome/css/font-awesome.css';

import FroalaEditor from 'react-froala-wysiwyg';
import LoginMessagePreviewModal from "./LoginMessagePreviewModal";

moment.locale('ja')
const FormItem = Form.Item;
const Panel = Collapse.Panel;
const Option = Select.Option;
const RadioButton = Radio.Button;
const RadioGroup = Radio.Group;
const RangePicker = DatePicker.RangePicker;
const {TextArea} = Input;


const mapStateToProps = (state, props) => {
    return {
        loginMessageSettingView: state.loginMessageSettingView,
        loginMessageSettingSave: state.loginMessageSettingSave,
        session: state.session
    }
};

const mapDispatchToProps = dispatch => {
    return {
        getLoginMessage: () => {
            dispatch(Actions.AdminConnection.loginMessageSetting.view.request())
        },
        postLoginMessage: (data) => {
            dispatch(Actions.AdminConnection.loginMessageSetting.save.request(data))
        }
    }
};

class LoginMessageComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            is_show_message: false,
            title: "",
            content: "",
            is_public: false,
        };
        this.props.getLoginMessage();
    }

    componentWillMount() {
    }

    componentWillReceiveProps(nextProps) {
        if (this.props.loginMessageSettingView !== nextProps.loginMessageSettingView) {
            this.setState({
                loading: nextProps.loginMessageSettingView.meta.fetch
            })
            if (!nextProps.loginMessageSettingView.error && nextProps.loginMessageSettingView.payload !== null && nextProps.loginMessageSettingView.payload.hasOwnProperty("result")) {
                const is_show_message = nextProps.loginMessageSettingView.payload.result ? nextProps.loginMessageSettingView.payload.result.is_show_message : false;
                const title = nextProps.loginMessageSettingView.payload.result ? nextProps.loginMessageSettingView.payload.result.title : "";
                const content = nextProps.loginMessageSettingView.payload.result ? nextProps.loginMessageSettingView.payload.result.content : "";
                const is_public = nextProps.loginMessageSettingView.payload.result ? !(nextProps.loginMessageSettingView.payload.result.start_at === 0 && nextProps.loginMessageSettingView.payload.result.end_at === 2147483647) : false;
                const range_picker_publish = nextProps.loginMessageSettingView.payload.result ? [moment.unix(nextProps.loginMessageSettingView.payload.result.start_at), moment.unix(nextProps.loginMessageSettingView.payload.result.end_at)] : undefined;

                this.setState({
                    is_show_message,
                    title,
                    content,
                    is_public,
                    range_picker_publish
                })
            }

        }

        if (nextProps.loginMessageSettingSave !== this.props.loginMessageSettingSave) {
            this.setState({
                loading: nextProps.loginMessageSettingSave.meta.fetch,
            })
            if (!nextProps.loginMessageSettingSave.meta.fetch) {
                if (!nextProps.loginMessageSettingSave.error) {
                    if (nextProps.loginMessageSettingSave.payload !== null) {
                        message.success("ログイン後メッセージの設定を変更しました", 2.0);
                    }
                } else {
                    message.error("ログイン後メッセージの設定の変更に失敗しました", 2.0);
                }
            }
        }


    }


    onChangePublic = (value) => {
        this.setState({
            is_public: value.target.value,
            range_picker_publish: undefined,
        })
    };

    handleModelChange = (model) => {
        this.setState({
            content: model
        })
    };

    handleManualController = (initControls) => {
        this.setState({
            editor: initControls.getEditor()
        });
    }

    onPreview = () => {

        let latestContent = this.state.content; //codeViewの内容判定部分が曖昧な為 動的に最新を読む　 https://github.com/froala/react-froala-wysiwyg/issues/21
        if (this.state.editor.codeView && this.state.editor.codeView.isActive()) {
            latestContent = this.state.editor.codeView.get();
            this.setState({
                content: this.state.editor.codeView.get()
            }, () => {
                this.props.form.setFieldsValue({
                    content: this.state.content
                });
            })
        } else {
            this.props.form.setFieldsValue({
                content: this.state.content
            });
        }

        this.props.form.validateFields((err, values) => {
            if (!err) {
                const fieldsValue = this.props.form.getFieldsValue();
                if (this.state.is_public) {
                    const range_picker_publish = values.range_picker_publish;
                    if (typeof range_picker_publish !== 'undefined') {
                        fieldsValue.start_at = range_picker_publish[0].unix();
                        fieldsValue.end_at = range_picker_publish[1].unix();
                    }
                } else {
                    fieldsValue.start_at = 0;
                    fieldsValue.end_at = 2147483647;
                }

                fieldsValue.content = latestContent;

                delete fieldsValue.range_picker_publish;
                delete fieldsValue.is_public;

                this.setState({
                    previewData: fieldsValue,
                    previewModalIsOpen: true
                });

            }
        });
    }

    onSubmit = () => {
        let latestContent = this.state.content;//codeViewの内容判定部分が曖昧な為 動的に最新を読む　 https://github.com/froala/react-froala-wysiwyg/issues/21
        if (this.state.editor.codeView && this.state.editor.codeView.isActive()) {
            latestContent = this.state.editor.codeView.get();
            this.setState({
                content: this.state.editor.codeView.get()
            }, () => {
                this.props.form.setFieldsValue({
                    content: this.state.content
                });
            })
        } else {
            this.props.form.setFieldsValue({
                content: this.state.content
            });
        }

        this.props.form.validateFields((err, values) => {
            if (!err) {
                const fieldsValue = this.props.form.getFieldsValue();
                if (this.state.is_public) {
                    const range_picker_publish = values.range_picker_publish;
                    if (typeof range_picker_publish !== 'undefined') {
                        fieldsValue.start_at = range_picker_publish[0].unix();
                        fieldsValue.end_at = range_picker_publish[1].unix();
                    }
                } else {
                    fieldsValue.start_at = 0;
                    fieldsValue.end_at = 2147483647;
                }

                fieldsValue.content = latestContent;

                delete fieldsValue.range_picker_publish;
                delete fieldsValue.is_public;

                this.props.postLoginMessage(fieldsValue);
            }
        });
    };


    handleManualController = (initControls) => {
        this.setState({
            initControls
        });
        initControls.initialize();
    };


    render() {
        const {getFieldDecorator} = this.props.form;

        const formItemLayout = {
            labelCol: {
                xs: {span: 24},
                sm: {span: 4},
            },
            wrapperCol: {
                xs: {span: 24},
                sm: {span: 20},
            },
        };

        return (
            <div id="option-container" className="tab-container">
                <Spin spinning={this.state.loading}>
                    <div className="card general-search">
                        <Form className="search-form">
                            <FormItem
                                {...formItemLayout}
                                label="ログイン後メッセージ機能"
                                extra={
                                    <span>
                                        「有効にする」を選択すると、全てのユーザのログイン後に指定したメッセージが表示されるようになります。<br/>
                                    </span>
                                }>
                                {getFieldDecorator('is_show_message', {
                                        initialValue: this.state.is_show_message,
                                        rules: [{
                                            required: true,
                                            message: '緊急お知らせ機能を選択してください',
                                        }]
                                    }
                                )(
                                    <RadioGroup
                                        onChange={(e) => {
                                            this.setState({
                                                is_show_message: e.target.value
                                            })
                                        }}
                                        style={{marginBottom: "0px"}}>
                                        <Radio value={true}>有効にする</Radio>
                                        <Radio value={false}>無効にする</Radio>
                                    </RadioGroup>
                                )}
                            </FormItem>


                            <FormItem
                                {...formItemLayout}
                                label={
                                    <span>
                                     タイトル&nbsp;&nbsp;
                                        <Tooltip title={GlobalConfig.FormHelpTextOverride.iconText.NotificationTitle ?
                                            GlobalConfig.FormHelpTextOverride.iconText.NotificationTitle
                                            : "お知らせタイトルを入力してください。"}>
                                        <QuestionCircleOutlined/>
                                    </Tooltip>
                                 </span>
                                }
                                layout="inline"
                                extra={GlobalConfig.FormHelpTextOverride.bottomText.NotificationTitle ?
                                    GlobalConfig.FormHelpTextOverride.bottomText.NotificationTitle
                                    : ""}>
                                {getFieldDecorator('title', {
                                    initialValue: this.state.title,
                                    rules: [{
                                        required: true,
                                        message: GlobalConfig.Validation.NotificationTitleMessage ? GlobalConfig.Validation.NotificationTitleMessage : "",
                                    }],
                                })(
                                    <Input placeholder="タイトルを入力してください"/>
                                )}
                            </FormItem>


                            <FormItem
                                disabled={true}
                                {...formItemLayout}
                                label="内容">
                                {getFieldDecorator('content', {
                                    initialValue: this.state.content,
                                    rules: [{
                                        required: true,
                                        message: '内容を入力してください',
                                    }],
                                })(
                                    <FroalaEditor
                                        tag='textarea'
                                        config={{
                                            attribution: false,
                                            placeholderText: '内容を入力してください',
                                            language: 'ja',
                                            theme: 'knowledge-portal',
                                            heightMin: 200,
                                            colorsText:
                                                [
                                                    '#dc0000', '#004da0', '#f8b500', '#009d62', '#ec6c00', '#ffffff', '#314b57', 'REMOVE'
                                                ],
                                            colorsBackground: [
                                                '#dc0000', '#004da0', '#f8b500', '#009d62', '#ec6c00', '#ffffff', '#314b57', 'REMOVE'
                                            ],
                                            colorsHEXInput: false,
                                            pluginsEnabled: ['fullscreen', 'align', 'colors', 'lists', 'link', "url", "codeView", "codeBeautifier", "print"],
                                            toolbarButtons: ['fullscreen', 'bold', 'italic', 'underline', '|', 'color', '|', 'insertHR', 'insertLink', 'clearFormatting', '|',
                                                'help', 'html', '|', 'undo', 'redo', "|", "print"],
                                            linkEditButtons: ['linkOpen', 'linkStyle', 'linkEdit', 'linkRemove'],
                                            linkStyles: {
                                                default: "通常リンク",
                                                buttonLink: "ボタン型リンク"
                                            },
                                            linkMultipleStyles: false,
                                            charCounterCount: true,
                                            linkAlwaysBlank: true,
                                            codeBeautifierOptions: {
                                                end_with_newline: true,
                                                indent_inner_html: true,
                                                extra_liners: "['p', 'h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'blockquote', 'pre', 'ul', 'ol', 'table', 'dl']",
                                                brace_style: 'expand',
                                                indent_char: ' ',
                                                indent_size: 4,
                                                wrap_line_length: 0
                                            },
                                            codeMirror: CodeMirror,
                                            codeMirrorOptions: {
                                                indentWithTabs: true,
                                                lineNumbers: true,
                                                mode: 'text/html',
                                                tabMode: 'indent',
                                                tabSize: 4
                                            },
                                            linkList: GlobalConfig.FroalaLinkList,
                                            key: GlobalConfig.FroalaEditorLicenceKey,
                                            events: {
                                                initialized: () => {
                                                    const editor = this.state.initControls.getEditor();
                                                    this.setState({
                                                        editor
                                                    })
                                                }
                                            }
                                        }}
                                        model={this.state.content}
                                        onManualControllerReady={this.handleManualController}
                                        onModelChange={this.handleModelChange}
                                    />
                                )}
                            </FormItem>

                            <FormItem
                                {...formItemLayout}
                                label="表示期間を設定する"
                                extra="表示期間を設定しない場合、常にログイン後にメッセージが表示されます。">
                                {getFieldDecorator('is_public', {
                                        initialValue: typeof this.state.is_public !== "undefined" ? this.state.is_public : false,
                                        rules: [{
                                            required: true,
                                            message: '緊急お知らせ公開期間設定',
                                        }]
                                    }
                                )(
                                    <RadioGroup onChange={this.onChangePublic}>
                                        <Radio value={true}>設定する</Radio>
                                        <Radio value={false}>設定しない</Radio>
                                    </RadioGroup>
                                )}
                            </FormItem>
                            {this.state.is_public ?
                                <FormItem
                                    {...formItemLayout}
                                    label="表示期間設定">
                                    {getFieldDecorator('range_picker_publish', {
                                        initialValue: this.state.range_picker_publish,
                                        rules: [{type: 'array', required: true, message: '表示期間を選択してください'}]
                                    })(
                                        <RangePicker
                                            locale={jaJP}
                                            showTime={{format: 'HH:mm'}}
                                            format="YYYY/MM/DD HH:mm"
                                            ranges={GlobalConfig.DatePickerRanges()}/>
                                    )}
                                </FormItem>
                                :
                                null
                            }

                            <div className="submit-container">
                                <Button loading={this.state.loading} className="search-form-button"
                                        onClick={this.onPreview}>
                                    プレビュー
                                </Button>
                                <Button type="primary" loading={this.state.loading} className="search-form-button"
                                        onClick={this.onSubmit}>
                                    変更
                                </Button>
                            </div>
                        </Form>
                    </div>
                </Spin>
                <LoginMessagePreviewModal
                    visible={this.state.previewModalIsOpen}
                    data={this.state.previewData}
                    handleOk={() => {
                        this.setState({
                            previewModalIsOpen: false
                        })
                    }}
                />
            </div>
        );
    }

}


export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Form.create()(LoginMessageComponent))
