import React from 'react'
import {connect, store} from 'react-redux'
import {withRouter} from 'react-router-dom';
import PropTypes from 'prop-types';
import * as Actions from '../../../../redux/actions'
import * as GlobalConfig from '../../../../constants/GlobalConfig'
import {instanceOf} from 'prop-types';
import * as TagNodeGenerator from '../../../../components/utils/TagNodeGenerator'
import moment from 'moment';
import 'moment/locale/ja';
import {CopyToClipboard} from 'react-copy-to-clipboard';

import {
    CopyOutlined,
    DeleteOutlined,
    EditOutlined,
    ExceptionOutlined,
    FileAddOutlined,
    FileExcelOutlined,
    FileOutlined,
    FilePdfOutlined,
    FilePptOutlined,
    FileTextOutlined,
    FileWordOutlined,
    FolderOutlined,
    HddOutlined,
    PictureOutlined,
    SearchOutlined,
    UpOutlined,
} from '@ant-design/icons';

import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';

import {
    Modal,
    Table,
    Tabs,
    Radio,
    Select,
    Input,
    Col,
    Button,
    DatePicker,
    Collapse,
    Tooltip,
    Row,
    Popconfirm,
} from 'antd';

import log from '../../../../components/utils/Logger';

import FileShareAddFileModal from "../../../fileshare/FileShareAddFileModal";
import queryString from "query-string";
import isUUID from "is-uuid";
import jaJP from "antd/lib/date-picker/locale/ja_JP";
import fileSize from 'filesize';
import FileShareAgreementModal from "./FileShareAgreementModal";
import ModuleFileShareModalSearch from "./ModuleFileShareModalSearch";

moment.locale('ja');

const Search = Input.Search;
const TabPane = Tabs.TabPane;
const FormItem = Form.Item;
const size = fileSize.partial();

const mapStateToProps = (state, props) => {
    return {
        screen: state.screen,
        fileShareSearchSeeMore: state.fileShareSearchSeeMore,
        fileShareDelete: state.fileShareDelete,
        fileShareDownload: state.fileShareDownload,
        fileShareConfirmAgreement: state.fileShareConfirmAgreement
    }
};

const mapDispatchToProps = dispatch => {
    return {
        searchFile: (data, currentPageNum, uuid) => {
            dispatch(Actions.Connection.fileshare.seeMore.request(data, currentPageNum, uuid));
        },
        deleteFileShare: (uuid) => {
            dispatch(Actions.Connection.fileshare.delete.request(uuid));
        },
        downloadFile : (data) => {
            dispatch(Actions.Connection.fileshare.download.request(data));
        },
        uploadImages: (fileName, contentType, data) => {
            dispatch(Actions.Connection.images.request(fileName, contentType, data));
        }
    }
};

class ModuleFileShareModalList extends React.Component {

    constructor(props) {
        super(props);
        let currentUUID = props.currentUUID;
        this.state = {
            pagination: {
                showTotal: (total, range) => {
                    return (`全${total}件中 ${range[0]}-${range[1]} 件`)
                },
                showSizeChanger: true,
                pageSizeOptions: GlobalConfig.PageSizeOptions,
                pageSize: 20,
                onShowSizeChange: this.onChangePageSize,
                total: 0,
            },
            selectedRowKeys: [],
            loading: false,
            fileShareArray: [],
            openAddFileForm: false,
            folderMeta: null,
            currentUUID,
        };
    }

    componentDidMount(){
        if(this.props.isDirect) {
            this.search();
            if (this.props.location.search) {
                const parsed = queryString.parse(this.props.location.search);
                if (Object.prototype.hasOwnProperty.call(parsed, 'file_uuid')) {
                    window.open(`${process.env.REACT_APP_FILE_DOMAIN}/shared-file/view/${parsed.file_uuid}`, '_blank');
                }
            }
        }
    }

    componentWillReceiveProps(nextProps) {
        const pagination = {...this.state.pagination};
        const {fileShareSearchSeeMore,fileShareDelete,fileShareDownload,fileShareConfirmAgreement} = {...this.props};

        log.debug(nextProps.currentUUID);
        this.setState({
            visible: nextProps.visible,
        });

        if(this.props.visible !== nextProps.visible && nextProps.visible){
            this.setState({
                currentUUID: nextProps.currentUUID
            },()=>{
                this.search();

            })
        }

        if (nextProps.fileShareSearchSeeMore !== fileShareSearchSeeMore) {
            if (!nextProps.fileShareSearchSeeMore.meta.fetch) {
                if (!nextProps.fileShareSearchSeeMore.error) {
                    if (nextProps.fileShareSearchSeeMore.payload !== null) {

                        pagination.total = nextProps.fileShareSearchSeeMore.payload.result.item_count;
                        this.setState({
                            loading: nextProps.fileShareSearchSeeMore.meta.fetch,
                            fileShareArray: nextProps.fileShareSearchSeeMore.payload.result.items,
                            folderMeta: nextProps.fileShareSearchSeeMore.payload.result.folder_meta,
                            pagination
                        })
                    }
                } else {
                    if (nextProps.fileShareSearchSeeMore.payload !== null) {
                        if (nextProps.fileShareSearchSeeMore.payload.code === 412) {
                            this.setState({
                                agreementVisible: true
                            })
                        }
                    }
                }
            }
            this.setState({
                loading: nextProps.fileShareSearchSeeMore.meta.fetch
            })
        }
        if(nextProps.fileShareConfirmAgreement !== fileShareConfirmAgreement){
            if (!nextProps.fileShareConfirmAgreement.meta.fetch) {
                if (!nextProps.fileShareConfirmAgreement.error) {
                    this.setState({
                        agreementVisible: false
                    })
                    this.search();
                }
            }
            this.setState({
                loading: nextProps.fileShareConfirmAgreement.meta.fetch
            })
        }

        if (nextProps.fileShareDelete !== fileShareDelete) {
            if (!nextProps.fileShareDelete.meta.fetch) {
                if (!nextProps.fileShareDelete.error) {
                    if (nextProps.fileShareDelete.payload !== null) {
                        this.setState({
                            loading: nextProps.fileShareDelete.meta.fetch,
                        });
                        this.search();
                    }
                }
            }
            this.setState({
                loading: nextProps.fileShareDelete.meta.fetch
            })
        }
        if (nextProps.fileShareDownload !== fileShareDownload) {
            if (!nextProps.fileShareDownload.meta.fetch) {
                if (!nextProps.fileShareDownload.error) {
                    if (nextProps.fileShareDownload.payload !== null) {
                        this.setState({
                            loading: nextProps.fileShareDownload.meta.fetch,
                        })
                    }
                }
            }
            this.setState({
                loading: nextProps.fileShareDownload.meta.fetch
            })
        }

    }

    handleCancel = () => {
        this.props.handleCancel();
    };

    handleOpenAddFileForm = () => {
        this.setState({
            isFileEditMode: false,
            openAddFileForm: true
        })
    };
    handleOpenEditFileForm = (data) => {
        this.setState({
            data: data,
            isFileEditMode: true,
            openAddFileForm: true
        })
    };

    onChangePageSize = (current, pageSize) => {
        const pager = {...this.state.pagination};
        //pageSizeの更新
        pager.pageSize = pageSize;
        this.setState({
            pagination: pager,
        });
    };


    handleTableChange = (pagination, filters, sorter) => {
        this.setState({
            pagination
        },()=>{
            this.search(pagination);
        })
    };


    search = (value) => {
        let fieldsValue = this.props.form.getFieldsValue();
        let pagination;
        if (typeof value === "undefined") {
            pagination = {...this.state.pagination};
        } else {
            pagination = value;
        }
        fieldsValue.page_size = pagination.pageSize;
        const range_picker_start = fieldsValue.range_picker_start;
        if (typeof range_picker_start !== 'undefined') {
            fieldsValue.publish_start_at = range_picker_start.unix();
        }
        const range_picker_end = fieldsValue.range_picker_end;
        if (typeof range_picker_end !== 'undefined') {
            fieldsValue.publish_end_at = range_picker_end.unix();
        }
        delete fieldsValue.range_picker_start;
        delete fieldsValue.range_picker_end;
        this.setState({
            loading: true
        },()=>{
            this.props.searchFile(fieldsValue, pagination.current, this.state.currentUUID);
        })
    };


    moveDirectory = (uuid) => {
        this.setState({
            currentUUID: uuid
        }, () => {
            this.search();
        })
    };

    onSubmit = (e) => {
        e.preventDefault();
        this.search();
        return false;
    };


    handleResetSearchForm = () => {
        this.props.form.resetFields();
        this.search();
    };

    onChangeTab = (key) =>{
        const category_uuid = key === "ALL" ? "": key;
        const pagination = {...this.state.pagination};
        //pageSizeの更新
        pagination.current = 1;
        this.setState({
            currentCategory: category_uuid,
            pagination
        });
        this.search(pagination,category_uuid);
    };

    onSelectChange = (selectedRowKeys,selectedRowInfo) => {
        const selectedUUIDs = selectedRowInfo.map((value) => {
            return value.uuid;
        })
        this.setState({
            selectedRowKeys,
            selectedUUIDs
        });
    };
    setMultiRead = () => {
        this.props.setMultiRead(this.state.selectedUUIDs);
    }

    setMultiUnRead = () => {
        this.props.setMultiUnRead(this.state.selectedUUIDs);
    }


    handleSearch = (e) => {
        e.preventDefault();
        this.search();
    };

    handleDelete = (value) =>{
        this.props.deleteFileShare(value.uuid)
    };

    render() {
        const {visible, selectedRowKeys} = this.state;

        const columns = [{
            title: '氏名',
            dataIndex: 'name',
            className: 'file-share-list-row-filename',
            key: 'name',
            onCellClick: (record,event) => {
                if (!record.is_file) {
                    this.moveDirectory(record.uuid)
                }else{
                    window.open(`${process.env.REACT_APP_FILE_DOMAIN}/admin/shared-file/view/${record.uuid}`,'_blank');
                }
            },
            render: (text, record, index) => {
                let fileName = "";
                if (!record.is_file) {
                    fileName = (
                        <div className={"file-share-list-row"}>
                            <FolderOutlined style={{fontSize: 18, color: '#08c', marginRight: '5px'}} />{text}
                        </div>
                    );
                } else {
                    switch(record.extension.toLowerCase()) {
                        case 'pdf' :{
                            fileName = (
                                <div className={"file-share-list-row"}>
                                    <FilePdfOutlined style={{fontSize: 18, color: '#08c', marginRight: '5px'}} />{text}
                                </div>
                            );
                        }
                            break;
                        case 'doc' :
                        case 'docx' :{
                            fileName = (
                                <div className={"file-share-list-row"}>
                                    <FileWordOutlined style={{fontSize: 18, color: '#08c', marginRight: '5px'}} />{text}
                                </div>
                            );
                        }
                            break;
                        case 'xls' :
                        case 'xlsx' :{
                            fileName = (
                                <div className={"file-share-list-row"}>
                                    <FileExcelOutlined style={{fontSize: 18, color: '#08c', marginRight: '5px'}} />{text}
                                </div>
                            );
                        }
                            break;
                        case 'ppt' :
                        case 'pptx' :{
                            fileName = (
                                <div className={"file-share-list-row"}>
                                    <FilePptOutlined style={{fontSize: 18, color: '#08c', marginRight: '5px'}} />{text}
                                </div>
                            );
                        }
                            break;
                        case 'jpg' :
                        case 'jpeg' :
                        case 'png' :
                        case 'gif' :
                        case 'bmp' :{
                            fileName = (
                                <div className={"file-share-list-row"}>
                                    <PictureOutlined style={{fontSize: 18, color: '#08c', marginRight: '5px'}} />{text}
                                </div>
                            );
                        }
                            break;
                        case 'txt' :
                        case 'csv' : {
                            fileName = (
                                <div className={"file-share-list-row"}>
                                    <FileTextOutlined style={{fontSize: 18, color: '#08c', marginRight: '5px'}} />{text}
                                </div>
                            );
                        }
                            break;
                        default :{
                            fileName = (
                                <div className={"file-share-list-row"}>
                                    <FileOutlined style={{fontSize: 18, color: '#08c', marginRight: '5px'}} />{text}
                                </div>
                            );
                        }
                    }

                }
                return fileName
            }
        }, {
            title: "摘要",
            dataIndex: "description",
            key: 'description',
            render: (text, record) => {
                return (
                    <div>
                        {record.description}
                    </div>
                )
            }
        }, {
            title: 'サイズ',
            dataIndex: 'size',
            key: 'size',
            width: 90,
            render: (text, record, index) => {
                return (<div>{size(record.size)}</div>)
            }
        },{
            title: "管轄",
            dataIndex: "jurisdiction",
            key: 'jurisdiction',
            width: 140,
            render: (text, record) => {
                return (
                    <div>
                        {record.jurisdiction}
                    </div>
                )
            }
        },{
            title: '作成者/更新者',
            key: 'author',
            width: 150,
            render: (text, record) => {
                /*
                if(record.hasOwnProperty("updated_by") && record.updated_by){
                    return <div>{record.updated_by.name}</div>
                }else if(record.hasOwnProperty("created_by") && record.created_by){
                    return <div>{record.created_by.name}</div>
                }else{
                    return <div />
                }
                */
                const created_by_name = record.created_by ? record.created_by.name : "作成者なし";
                const updated_by_name = record.updated_by ? record.updated_by.name : "更新者なし";
                return (
                    <span>{created_by_name}<br/>{updated_by_name}</span>
                );
            }
        }, {
            title: "有効期限",
            key: 'term',
            width: 100,
            render: (text, record, index) => {
                if(record.publish_start_at === 0 && record.publish_end_at === 2147483647) {
                    return (<div>-</div>);
                }else{
                    return (
                        <div>
                            {moment.unix(record.publish_start_at).format("YYYY/MM/DD")}<br/>
                            {moment.unix(record.publish_end_at).format("YYYY/MM/DD")}
                        </div>
                    )
                }
            }
        }, {
            title: '作成時間/更新時間',
            key: 'date',
            width: 140,
            render: (text, record) => {
                return (
                    <div>
                        {moment.unix(record.created_at).format("YYYY/MM/DD HH:mm")}<br />
                        {moment.unix(record.updated_at).format("YYYY/MM/DD HH:mm")}
                    </div>
                )
            }
        }, {
            width: 90,
            key: 'action',
            render: (text, record) => {
                if (record.is_file) {
                    return (
                        <Row type="flex" justify="space-around" align="middle">
                            <Col span={8}>
                                <Tooltip placement="topLeft" title="ファイルURLをコピー">
                                    <CopyToClipboard text={`https://${window.location.host}${GlobalConfig.UserSubDirectory}/fileshare/${this.state.currentUUID}/?file_uuid=${record.uuid}`}>
                                    <Button
                                        type="default" shape="circle" icon={<CopyOutlined />}
                                        onClick={() =>{}
                                        }
                                    />
                                    </CopyToClipboard>
                                </Tooltip>
                            </Col>
                            <Col span={8}>
                                <Tooltip placement="bottom" title="編集">
                                    <Button
                                        type="default" shape="circle" icon={<EditOutlined />}
                                        onClick={() => {
                                            if (record.is_file) {
                                                this.handleOpenEditFileForm(record)
                                            } else {
                                                this.handleOpenEditFolderForm(record)
                                            }
                                        }}
                                    />
                                </Tooltip>
                            </Col>
                            <Col span={8}>
                                <Tooltip placement="bottom" title="削除">
                                    <Popconfirm
                                        title={<span>選択したファイルを削除します。</span>}
                                        onConfirm={() => {
                                            this.handleDelete(record);
                                        }}
                                        onCancel=""
                                        placement="left"
                                        okText="削除" cancelText="キャンセル">
                                        <Button
                                            type="default" shape="circle" icon={<DeleteOutlined />} />
                                    </Popconfirm>
                                </Tooltip>
                            </Col>
                        </Row>
                    );
                } else {
                    return (
                        <Row type="flex" justify="space-around" align="middle">
                            <Col span={8}>
                                <Tooltip placement="topLeft" title="フォルダURLをコピー">
                                    <CopyToClipboard text={`https://${window.location.host}${GlobalConfig.UserSubDirectory}/fileshare/${record.uuid}`}>
                                        <Button
                                            type="default" shape="circle" icon={<CopyOutlined />}
                                            onClick={() =>{}
                                            }
                                        />
                                    </CopyToClipboard>
                                </Tooltip>
                            </Col>
                        </Row>
                    );
                }
            }
        }];

        const rowSelection = {
            selectedRowKeys,
            onChange: this.onSelectChange,
            hideDefaultSelections: true
        };

        const {getFieldDecorator} = this.props.form;

        const formItemLayout = {
            labelCol: {
                xs: {span: 24},
                sm: {span: 4},
            },
            wrapperCol: {
                xs: {span: 24},
                sm: {span: 20},
            },
        };

        // let currentCategory = this.state.paramCategoryArray.filter((value) => {
        //     return value.hasOwnProperty(this.state.currentCategory);
        // });
        let currentCategory = [].filter((value) => {
            return value.hasOwnProperty(this.state.currentCategory);
        });



        let upParentFolder = <div />;
        if(this.state.folderMeta && this.state.folderMeta.parent){
            upParentFolder = (
                <div>
                    <Button onClick={()=>this.moveDirectory(this.state.folderMeta.parent.uuid)} icon={<UpOutlined />}>
                        上のフォルダへ
                    </Button>
                </div>
            )
        }else if(this.state.currentUUID !== undefined) {
            upParentFolder = (
                <div>
                    <Button onClick={() => this.moveDirectory()} icon={<UpOutlined />}>
                        上のフォルダへ
                    </Button>

                </div>
            )
        }

        return (
            <Modal
                zIndex={900}
                className="user-notification-modal"
                style={{top: 20}}
                title={<span><HddOutlined />&nbsp;ファイル共有</span>}
                visible={visible}
                maskClosable={false}
                onCancel={this.handleCancel}
                footer={[
                    <Button key="back" size="large" onClick={this.handleCancel}>閉じる</Button>
                ]}>
                {this.state.agreementVisible ?
                    <div id="general-alert-content">
                        <div className="explain-box">
                            <div className="explain-box-left">
                                <ExceptionOutlined className="icon" />
                            </div>
                            <div className="explain_box-right">
                                <div>
                                    <h3>規約に同意する必要があります</h3>
                                    <Button onClick={() =>
                                        this.setState({
                                            agreementModalIsOpen: true
                                        })} >
                                        規約に同意する
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </div>
                    :
                    <div className='wrapper'>
                        <h2>{currentCategory.name}</h2>
                        <p>{currentCategory.description}</p>


                        <div className="table-header-wrapper">
                            <div className="button-container">
                                <Button type="primary" onClick={this.handleOpenAddFileForm} icon={<FileAddOutlined />}>
                                    新しいファイル
                                </Button>
                            </div>
                        </div>

                        <Table
                            className="general-table"
                            rowSelection={rowSelection}
                            locale={{
                                filterTitle: 'フィルタ',
                                filterConfirm: '確定',
                                filterReset: 'リセット',
                                emptyText: '該当するものはありません',
                            }}
                            bordered={true}
                            columns={columns}
                            size="middle"
                            loading={this.state.loading}
                            onChange={this.handleTableChange}
                            pagination={this.state.pagination}
                            onRow={(record, rowIndex) => {
                                return {
                                    onClick: event => {
                                        this.props.handleRowClick(record)
                                    }
                                };
                            }}
                            dataSource={this.state.fileShareArray}
                            footer={() => {
                                if (selectedRowKeys.length !== 0) {
                                    return (
                                        <div className="button-container">
                                            <div className={"button-container-left"}>
                                                {upParentFolder}
                                            </div>
                                            {
                                            //todo 検索
                                            <div className={"button-container-right"}>
                                                <Button onClick={() => {this.setState({searchModalIsOpen : true})}} icon={<SearchOutlined />}>
                                                    検索
                                                </Button>
                                            </div>
                                            }
                                        </div>
                                    );
                                } else {
                                    return null;
                                }
                            }}
                            title={() => {
                                return (
                                    <div className="button-container">
                                        <div className={"button-container-left"}>
                                            {upParentFolder}
                                        </div>
                                        {
                                            //todo 検索
                                            <div className={"button-container-right"}>
                                                <Button onClick={() => {this.setState({searchModalIsOpen : true})}} icon={<SearchOutlined />}>
                                                    検索
                                                </Button>
                                            </div>
                                        }
                                    </div>
                                );
                            }}
                        />
                        <FileShareAddFileModal
                            data={this.state.data}
                            visible={this.state.openAddFileForm}
                            isEditMode={this.state.isFileEditMode}
                            folderMeta={this.state.folderMeta}
                            handleOk={() => {
                                this.setState({
                                    openAddFileForm: false
                                });
                                this.search();
                            }}
                            handleCancel={() => {
                                this.setState({
                                    openAddFileForm: false
                                })
                            }}
                        />
                    </div>
                }
                <FileShareAgreementModal
                    visible={this.state.agreementModalIsOpen}
                    onCancel={() => {
                        this.setState({
                            agreementModalIsOpen: false
                        })
                    }}
                />
                <ModuleFileShareModalSearch
                    visible={this.state.searchModalIsOpen}
                    currentUUID={this.state.currentUUID}
                    handleCancel={() => {
                        this.setState({
                            searchModalIsOpen: false
                        })
                    }}
                    handleMoveDirectory={(uuid) => {
                        this.moveDirectory(uuid)
                    }}
                    handleOk={() => {
                        this.setState({
                            searchModalIsOpen: false
                        })
                    }}
                />
            </Modal>
        );
    }
}


ModuleFileShareModalList.propTypes = {
    handleOk: PropTypes.func.isRequired,
    handleCancel: PropTypes.func.isRequired
};



export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Form.create()(withRouter(ModuleFileShareModalList)))
