import React, { Component } from 'react'
import { ExceptionOutlined } from '@ant-design/icons';

class NoContent extends Component {

    constructor(props) {
        super(props);
        this.state = {
        }
    }

    render() {
        return (
            <div id="no-content">
                <div className="explain-box">
                    <div className="explain-box-left">
                        <ExceptionOutlined className="no-content-icon" />
                    </div>
                    <div className="explain_box-right">
                        <div>
                        <h2>Contents not registered</h2>
                            <p>現在、表示できるコンテンツは登録されていません</p>
                        </div>
                    </div>
                </div>

            </div>
        );
    }
}

export default NoContent;