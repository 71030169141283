import React from 'react'
import PropTypes from 'prop-types';
import {connect, store} from 'react-redux'

import {
    AppstoreOutlined,
    DownCircleOutlined,
    FileOutlined,
    FolderOutlined,
    MinusCircleOutlined,
    PlusOutlined,
    QuestionCircleOutlined, StarOutlined,
    UpCircleOutlined,
    UpOutlined,
    UserOutlined,
} from '@ant-design/icons';

import {Form} from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';

import {
    Modal,
    Radio,
    Select,
    Row,
    Table,
    Col,
    Input,
    Button,
    DatePicker,
    InputNumber,
    Tooltip,
    Checkbox, Popconfirm,
} from 'antd';
import log from 'components/utils/Logger'
import * as Actions from 'redux/actions'
import SelectTargetForm from "containers/common/SelectTargetForm";
import * as UUID from 'components/utils/UUID.js'
import {paramQuestionnaireCategory} from "../../redux/reducers";
import * as GlobalConfig from 'constants/GlobalConfig'
import moment from 'moment';
import 'moment/locale/ja';
import jaJP from 'antd/lib/date-picker/locale/ja_JP';
import MyTargetModal from "../common/MyTargetModal";

moment.locale('ja')
const FormItem = Form.Item;
const Option = Select.Option;
const RadioButton = Radio.Button;
const RadioGroup = Radio.Group;
const RangePicker = DatePicker.RangePicker;
const {TextArea} = Input;
const CheckboxGroup = Checkbox.Group;

const TYPE_ENUM = {
    LINK: 1,
    NOTIFICATION: 2,
    QUESTIONNAIRE: 3,
    CALENDAR: 4,
    FILESHARE: 5,
    CURRICULUM: 6
};


const curriculumDefaultCategory = [{uuid: 1, name: "すべて"}, {uuid: 2, name: "休講"}, {uuid: 3, name: "補講"}, {
    uuid: 4,
    name: "授業変更"
}];

const mapStateToProps = (state, props) => {
    return {
        screen: state.screen,
        paramNotificationCategory: state.paramNotificationCategory,
        paramQuestionnaireCategory: state.paramQuestionnaireCategory,
        paramCalendarCategory: state.paramCalendarCategory,
        fileShareFetch: state.fileShareFetch
    }
};

const mapDispatchToProps = dispatch => {
    return {
        uploadImages: (fileName, contentType, data) => {
            dispatch(Actions.Connection.images.request(fileName, contentType, data));
        },
        getParamNotificationCategory: () => {
            dispatch(Actions.AdminConnection.notification.category.parameter.request());
        },
        getParamQuestionnaireCategory: () => {
            dispatch(Actions.AdminConnection.questionnaire.category.parameter.request());
        },
        getParamCalendarCategory: () => {
            dispatch(Actions.AdminConnection.calendar.category.parameter.request())
        },
        explorerFile: (data, currentPageNum, uuid) => {
            //todo AdminConnectionを使うべきかどうか
            dispatch(Actions.AdminConnection.fileshare.fetch.request(data, currentPageNum, uuid));
        },
    }
};


class ModuleAddEditModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isEditMode: false,
            visible: props.visible,
            data: props.data,
            ssoArray: props.ssoArray,

            //モジュールメイン
            module_type: undefined,
            module_target_uuid: "",
            module_uuid: "",
            module_description: "",
            module_order: 1,
            module_name: "",

            partsKeyArray: [],
            partsObject: {},


            notificationUnReadOnly: true,
            notificationItemNum: 10,
            notificationCheckedCategory: [],
            notificationUUID: undefined,

            questionnaireUnAnsweredOnly: true,
            questionnaireItemNum: 10,
            questionnaireCheckedCategory: [],
            questionnaireUUID: undefined,

            calendarUUID: undefined,

            paramCurriculumCategoryArray: curriculumDefaultCategory,
            paramNotificationCategoryArray: [],
            paramQuestionnaireCategoryArray: [],
            paramCalendarCategoryArray: [],

            fileShareArray: [],
            folderMeta: undefined,
            currentUUID: undefined,
            folderUUID: undefined,
            shared_folder_uuid: undefined
        }
    }


    componentWillMount() {
    }


    componentWillReceiveProps(nextProps) {
        this.setState({
            ssoArray: nextProps.ssoArray,
            visible: nextProps.visible,
            data: nextProps.data
        });

        log.debug(nextProps.data, "nextProps data");


        if (nextProps.visible && nextProps.visible !== this.props.visible) {
            this.props.getParamNotificationCategory();
            this.props.getParamQuestionnaireCategory();
            this.props.getParamCalendarCategory();
            const module_type = nextProps.data ? nextProps.data.type : undefined;
            const module_name = nextProps.data ? nextProps.data.name : "";
            const module_description = nextProps.data ? nextProps.data.description : "";
            const module_uuid = nextProps.data ? nextProps.data.uuid : "";
            const module_target_uuid = nextProps.data ? nextProps.data.target_uuid : "";
            const module_order = nextProps.data ? nextProps.data.order : 1;
            const module_parts = nextProps.data ? nextProps.data.parts : [];

            const partsObject = {};
            let partsKeyArray = [];

            switch (module_type) {
                case 1:
                    module_parts.forEach((value, index) => {
                        //uuidの生成
                        const uuid = UUID.generate();
                        //フォーム管理部のarray
                        partsKeyArray.push(uuid);
                        //オブジェクト管理部
                        const object = {};
                        object.uuid = value.uuid;
                        object.sso_uuid = value.sso_uuid;
                        object.target_uuid = value.target_uuid;
                        object.text = value.text;
                        object.open_new_window = value.open_new_window;
                        object.order = value.order;

                        if (value.url) {
                            if (value.url.match("/api/sso-link/")) {
                                object.url = "";
                                object.sso_uuid = value.url.substr(value.url.lastIndexOf('/') + 1);
                            } else {
                                object.url = value.url;
                                object.sso_uuid = undefined;
                            }
                        } else {
                            object.url = "";
                            object.sso_uuid = undefined;
                        }


                        object.start_at = value.start_at;
                        object.end_at = value.end_at;

                        if (value.start_at === 0 && value.end_at === 2147483647) {
                            object.enable_range = false;
                        } else {
                            object.enable_range = true
                        }
                        object.range_picker = [moment.unix(value.start_at), moment.unix(value.end_at)];


                        partsObject[uuid] = object;
                    });

                    break;
                case 2:
                    log.debug(module_parts);

                    const notificationItemNum = module_parts.item_num ? module_parts.item_num : 10;
                    const notificationUnReadOnly = module_parts.unread_only;
                    const notificationCheckedCategory = module_parts.categories ? module_parts.categories : [];
                    const notificationUUID = module_parts.uuid ? module_parts.uuid : undefined;
                    this.setState({
                        notificationItemNum,
                        notificationUnReadOnly,
                        notificationCheckedCategory,
                        notificationUUID
                    });
                    break;
                case 3:

                    const questionnaireItemNum = module_parts.item_num ? module_parts.item_num : 10;
                    const questionnaireUnAnsweredOnly = module_parts.unanswered_only;
                    const questionnaireCheckedCategory = module_parts.categories ? module_parts.categories : [];
                    const questionnaireUUID = module_parts.uuid ? module_parts.uuid : undefined;
                    this.setState({
                        questionnaireItemNum,
                        questionnaireUnAnsweredOnly,
                        questionnaireCheckedCategory,
                        questionnaireUUID
                    });
                    break;
                case 4:
                    const calendarCheckedCategory = module_parts.map((value) => {
                        //uuidの生成
                        return value.calendar_source_uuid;
                    });
                    this.setState({
                        calendarCheckedCategory
                    });
                    break;
                case 5:
                    log.debug(module_parts);
                    this.setState({
                        folderUUID: module_parts.uuid,
                        currentUUID: module_parts.shared_folder_uuid
                    }, () => {
                        this.explorerFile();
                    });
                    break;

                case 6:
                    const curriculumCheckedCategory = module_parts.map((value) => {
                        //uuidの生成
                        return value.type;
                    });
                    this.setState({
                        curriculumCheckedCategory
                    });
                    break;


                default :
                    break;
            }

            this.setState({
                module_type,
                module_name,
                module_description,
                module_uuid,
                module_target_uuid,
                module_order,
                module_parts,

                partsKeyArray,
                partsObject
            })
        }

        // カテゴリ一覧の取得
        if (!nextProps.paramNotificationCategory.meta.fetch) {
            if (!nextProps.paramNotificationCategory.error) {
                if (nextProps.paramNotificationCategory.payload !== null) {
                    if (nextProps.paramNotificationCategory.payload.hasOwnProperty("result") && nextProps.paramNotificationCategory.payload.result.hasOwnProperty("items")) {
                        this.setState({
                            loading: nextProps.paramNotificationCategory.meta.fetch,
                            paramNotificationCategoryArray: nextProps.paramNotificationCategory.payload.result.items,
                        })
                    }
                }
            } else {
                this.setState({
                    loading: nextProps.paramNotificationCategory.meta.fetch
                })
            }
        } else {
            this.setState({
                loading: nextProps.paramNotificationCategory.meta.fetch
            })
        }

        // カテゴリ一覧の取得
        if (!nextProps.paramQuestionnaireCategory.meta.fetch) {
            if (!nextProps.paramQuestionnaireCategory.error) {
                if (nextProps.paramQuestionnaireCategory.payload !== null) {
                    if (nextProps.paramQuestionnaireCategory.payload.hasOwnProperty("result") && nextProps.paramQuestionnaireCategory.payload.result.hasOwnProperty("items")) {
                        this.setState({
                            loading: nextProps.paramQuestionnaireCategory.meta.fetch,
                            paramQuestionnaireCategoryArray: nextProps.paramQuestionnaireCategory.payload.result.items,
                        })
                    }
                }
            } else {
                this.setState({
                    loading: nextProps.paramQuestionnaireCategory.meta.fetch
                })
            }
        } else {
            this.setState({
                loading: nextProps.paramQuestionnaireCategory.meta.fetch
            })
        }

        // カテゴリ一覧の取得
        if (!nextProps.paramCalendarCategory.meta.fetch) {
            if (!nextProps.paramCalendarCategory.error) {
                if (nextProps.paramCalendarCategory.payload !== null) {
                    if (nextProps.paramCalendarCategory.payload.hasOwnProperty("result") && nextProps.paramCalendarCategory.payload.result.hasOwnProperty("items")) {
                        this.setState({
                            loading: nextProps.paramCalendarCategory.meta.fetch,
                            paramCalendarCategoryArray: nextProps.paramCalendarCategory.payload.result.items,
                        })
                    }
                }
            } else {
                this.setState({
                    loading: nextProps.paramCalendarCategory.meta.fetch
                })
            }
        } else {
            this.setState({
                loading: nextProps.paramCalendarCategory.meta.fetch
            })
        }

        //ファイル一覧の取得
        const pagination = {...this.state.pagination};
        if (nextProps.fileShareFetch !== this.props.fileShareFetch) {
            if (!nextProps.fileShareFetch.meta.fetch) {
                if (!nextProps.fileShareFetch.error) {
                    if (nextProps.fileShareFetch.payload !== null) {
                        const fileShareArray = nextProps.fileShareFetch.payload.result.items.filter((value, index) => {
                            return !value.is_file
                        });
                        this.setState({
                            loading: nextProps.fileShareFetch.meta.fetch,
                            folderMeta: nextProps.fileShareFetch.payload.result.folder_meta,
                            shared_folder_uuid: nextProps.fileShareFetch.payload.result.folder_meta.uuid,
                            fileShareArray,
                            pagination
                        })
                        this.props.form.setFieldsValue({
                            shared_folder_uuid: nextProps.fileShareFetch.payload.result.folder_meta.uuid
                        })
                    }
                }
            } else {
                this.setState({
                    loading: nextProps.fileShareFetch.meta.fetch
                })
            }
        }
        if (!nextProps.visible) {
            this.setState({
                module_type: undefined,
                module_target_uuid: "",
                module_uuid: "",
                module_description: "",
                module_name: "",

                partsKeyArray: [],
                partsObject: {}
            })
            this.resetFormAll();
        }
    }


    resetFormAll = () => {
        this.props.form.resetFields();
        this.setState({
            //モジュールメイン
            module_type: undefined,
            module_target_uuid: "",
            module_uuid: "",
            module_description: "",
            module_order: 1,
            module_name: "",

            partsKeyArray: [],
            partsObject: {},

            notificationUnReadOnly: true,
            notificationItemNum: 10,
            notificationCheckedCategory: [],
            notificationUUID: undefined,

            questionnaireUnAnsweredOnly: true,
            questionnaireItemNum: 10,
            questionnaireCheckedCategory: [],
            questionnaireUUID: undefined,

            calendarUUID: undefined,
            paramCurriculumCategoryArray: curriculumDefaultCategory,
            paramNotificationCategoryArray: [],
            paramQuestionnaireCategoryArray: [],
            paramCalendarCategoryArray: [],
        })
    };

    handleOk = () => {
        this.setState({
            confirmLoading: true,
        });

        this.props.form.validateFields((err, values) => {
            log.debug(err);
            if (!err) {

                let postObject = {};
                let postArray = [];

                const object = this.state.partsObject;
                switch (this.state.module_type) {
                    case 1:
                        postArray = this.state.partsKeyArray.map((value, index) => {
                            if (!object[value].target_uuid) {
                                log.error("target_uuidの欠落");
                                return;
                            }
                            const parts = {};
                            parts.uuid = object[value].uuid;
                            parts.target_uuid = object[value].target_uuid;
                            parts.text = object[value].text;
                            parts.url = object[value].url;
                            parts.sso_uuid = object[value].sso_uuid;
                            parts.open_new_window = object[value].open_new_window;
                            parts.order = index;

                            parts.start_at = object[value].start_at;
                            parts.end_at = object[value].end_at;

                            return parts;
                        });
                        if (!this.state.module_target_uuid) {
                            log.error("target_uuidの欠落");
                            return;
                        }
                        postObject.type = this.state.module_type;
                        postObject.description = this.state.module_description;
                        postObject.order = this.state.module_order;
                        postObject.parts = postArray;
                        postObject.uuid = this.state.module_uuid;
                        postObject.name = this.state.module_name;
                        postObject.target_uuid = this.state.module_target_uuid;
                        log.debug(postObject);
                        this.props.handleOk(postObject, postObject.uuid);
                        break;
                    case 2:

                        if (!this.state.module_target_uuid) {
                            log.error("target_uuidの欠落")
                            return;
                        }
                        postObject.type = this.state.module_type;
                        postObject.description = this.state.module_description;
                        postObject.order = this.state.module_order;
                        postObject.uuid = this.state.module_uuid;
                        postObject.name = this.state.module_name;
                        postObject.target_uuid = this.state.module_target_uuid;

                        let notificationParts = {};
                        notificationParts.unread_only = this.state.notificationUnReadOnly;
                        notificationParts.item_num = this.state.notificationItemNum;
                        notificationParts.uuid = this.state.notificationUUID;
                        notificationParts.target_uuid = "kara";
                        notificationParts.categories = this.state.notificationCheckedCategory;


                        postObject.parts = notificationParts;
                        log.debug(postObject);

                        this.props.handleOk(postObject, postObject.uuid);
                        break;


                    case 3:

                        if (!this.state.module_target_uuid) {
                            log.error("target_uuidの欠落")
                            return;
                        }
                        postObject.type = this.state.module_type;
                        postObject.description = this.state.module_description;
                        postObject.order = this.state.module_order;
                        postObject.uuid = this.state.module_uuid;
                        postObject.name = this.state.module_name;
                        postObject.target_uuid = this.state.module_target_uuid;

                        let questionnaireParts = {};
                        questionnaireParts.unanswered_only = this.state.questionnaireUnAnsweredOnly;
                        questionnaireParts.item_num = this.state.questionnaireItemNum;
                        questionnaireParts.uuid = this.state.questionnaireUUID;
                        questionnaireParts.target_uuid = "kara";
                        questionnaireParts.categories = this.state.questionnaireCheckedCategory;


                        postObject.parts = questionnaireParts;
                        log.debug(postObject);

                        this.props.handleOk(postObject, postObject.uuid);
                        break;

                    case 4:

                        if (!this.state.module_target_uuid) {
                            log.error("target_uuidの欠落")
                            return;
                        }
                        postObject.type = this.state.module_type;
                        postObject.description = this.state.module_description;
                        postObject.order = this.state.module_order;
                        postObject.uuid = this.state.module_uuid;
                        postObject.name = this.state.module_name;
                        postObject.target_uuid = this.state.module_target_uuid;

                        const calendarParts = this.state.calendarCheckedCategory.map((value) => {
                            return {calendar_source_uuid: value}
                        });

                        postObject.parts = calendarParts;
                        log.debug(postObject);

                        this.props.handleOk(postObject, postObject.uuid);
                        break;
                    case 5:

                        if (!this.state.module_target_uuid) {
                            log.error("target_uuidの欠落")
                            return;
                        }
                        if (!this.state.folderMeta || !this.state.folderMeta.name) {
                            log.error("folder_metaの欠落")
                            return;
                        }
                        postObject.type = this.state.module_type;
                        postObject.description = this.state.module_description;
                        postObject.order = this.state.module_order;
                        postObject.uuid = this.state.module_uuid;
                        postObject.name = this.state.module_name;
                        postObject.target_uuid = this.state.module_target_uuid;
                        postObject.parts = {
                            uuid: this.state.folderUUID,
                            shared_folder_uuid: this.state.folderMeta.uuid
                        }
                        this.props.handleOk(postObject, postObject.uuid);
                        break;

                    case 6:
                        if (!this.state.module_target_uuid) {
                            log.error("target_uuidの欠落")
                            return;
                        }
                        postObject.type = this.state.module_type;
                        postObject.description = this.state.module_description;
                        postObject.order = this.state.module_order;
                        postObject.uuid = this.state.module_uuid;
                        postObject.name = this.state.module_name;
                        postObject.target_uuid = this.state.module_target_uuid;
                        const categoryParts = this.state.curriculumCheckedCategory.map((value) => {
                            return {type: value}
                        });
                        postObject.parts = categoryParts;

                        this.props.handleOk(postObject, postObject.uuid);
                        break;


                    default:
                        break;
                }

            }
        });
    };


    explorerFile = (value) => {
        let fieldsValue = {};
        let pagination;
        if (typeof value === "undefined") {
            pagination = {...this.state.pagination};
        } else {
            pagination = value;
        }
        fieldsValue.page_size = pagination.pageSize;

        this.props.explorerFile(fieldsValue, pagination.current, this.state.currentUUID);
    };


    handleCancel = () => {
        this.props.handleCancel();
    };

    remove = (k) => {
        const partsKeyArray = this.state.partsKeyArray;
        partsKeyArray.some(function (v, i) {
            if (v === k) partsKeyArray.splice(i, 1);
        });

        const partsObject = this.state.partsObject;
        delete partsObject[k];
        this.setState({
            partsKeyArray,
            partsObject
        })
    }

    add = () => {
        const uuid = UUID.generate();
        let partsKeyArray = this.state.partsKeyArray;
        partsKeyArray.push(uuid);
        const partsObject = this.state.partsObject;
        partsObject[uuid] = {
            open_new_window: true,
            enable_range: true
        }

        log.debug(partsKeyArray, "partsKeyArray");
        log.debug(partsObject);
        this.setState({
            partsKeyArray,
            partsObject
        })
    };

    sort = () => {

    };

    sort = (index, target) => {

        let partsKeyArray = this.state.partsKeyArray;
        const arrIndex = index + target;
        partsKeyArray.splice(arrIndex, 2, partsKeyArray[arrIndex + 1], partsKeyArray[arrIndex])

        this.setState({
            partsKeyArray
        });
    };

    handleSubmit = (e) => {
        e.preventDefault();

        log.debug(this.state, "submit");
    };


    openTargetModal = (key, fromMyTarget) => {
        //編集時にuuidが必要になるから      target-uuid
        //編集時にuuidが必要になるから本体の uuid
        let target_uuid;
        let foreign_uuid;
        log.debug(key);
        if (key !== "module_main") {
            let part = this.state.partsObject[key];
            target_uuid = fromMyTarget ? this.state.target_uuid : part.target_uuid;
            foreign_uuid = part.uuid;
        } else {
            target_uuid = fromMyTarget ? this.state.target_uuid : this.state.module_target_uuid;
            foreign_uuid = this.state.module_uuid;
        }

        this.setState({
            target_uuid,
            foreign_uuid,
            editingTargetKey: key
        }, () => {
            log.debug(this.state.editingTargetKey, "editingTargetKeyの変更")
            this.setState({
                targetModalIsOpen: true,
            })
        });
    }


    selectTarget = (data, type) => {
        log.debug(this.state.editingTargetKey, "editingTargetKeyの変更");
        log.debug(data);

        this.props.form.setFieldsValue({
            [this.state.editingTargetKey]: data.uuid,
        });

        if (this.state.editingTargetKey === "module_main" || !this.state.editingTargetKey) {
            this.setState({
                targetModalIsOpen: false,
                module_target_uuid: data.uuid,
                editingTargetKey: ""
            })
        } else {
            const partsObject = this.state.partsObject;
            partsObject[this.state.editingTargetKey].target_uuid = data.uuid;
            log.debug(partsObject);
            log.debug(this.state.editingTargetKey.target_uuid);
            this.setState({
                targetModalIsOpen: false,
                editingTargetKey: "",
                partsObject,
            });
        }


        log.debug(this.state.editingTargetKey, "editingTargetKeyの変更");
    }


    changeModuleType = (value) => {
        let module_type;
        switch (value) {
            case TYPE_ENUM.LINK:
                module_type = 1;
                break;

            case TYPE_ENUM.NOTIFICATION:
                module_type = 2;
                break;

            case TYPE_ENUM.QUESTIONNAIRE:
                module_type = 3;
                break;

            case TYPE_ENUM.CALENDAR:
                module_type = 4;
                break;

            case TYPE_ENUM.FILESHARE:
                module_type = 5;
                this.setState({
                    currentUUID: undefined
                }, () => {
                    this.explorerFile();
                });
                break;
            case TYPE_ENUM.CURRICULUM:
                module_type = 6;
                break;
        }
        this.setState({
            module_type
        })
    }
    changeModuleName = (e) => {
        this.setState({
            module_name: e.target.value
        })
    };
    changeModuleDescription = (e) => {
        log.debug(e.target.value);
        this.setState({
            module_description: e.target.value
        })
    }
    changeModuleOrder = (value) => {
        this.setState({
            module_order: value
        })
    }

    changePartsUri = (e, k) => {
        const partsObject = this.state.partsObject;
        partsObject[k].url = e.target.value;

        this.setState({
            partsObject
        })
    }

    changePartsSSO = (value, k) => {
        const partsObject = this.state.partsObject;
        partsObject[k].sso_uuid = value;

        this.setState({
            partsObject
        })
    }

    changePartsTitle = (e, k) => {
        const partsObject = this.state.partsObject;
        partsObject[k].text = e.target.value;

        this.setState({
            partsObject
        })
    }

    changeOpenNewWindow = (e, k) => {
        const partsObject = this.state.partsObject;
        partsObject[k].open_new_window = e.target.value;

        this.setState({
            partsObject
        })
    }


    changeEnableRange = (e, k) => {
        const partsObject = this.state.partsObject;
        partsObject[k].enable_range = e.target.value;

        if (e.target.value) {
            partsObject[k].range_picker = undefined;
            partsObject[k].start_at = undefined;
            partsObject[k].end_at = undefined;
        }

        this.setState({
            partsObject
        })
    }


    changeRange = (value, k) => {
        log.debug(value);
        log.debug(k);
        const partsObject = this.state.partsObject;
        partsObject[k].range_picker = value;
//        partsObject[k].

        if (typeof value !== 'undefined' && value.length !== 0) {
            partsObject[k].start_at = value[0].unix();
            partsObject[k].end_at = value[1].unix();
        } else {
            partsObject[k].start_at = undefined;
            partsObject[k].end_at = undefined;
        }

        this.setState({
            partsObject
        })
    }

    changeNotificationUnreadOnly = (value) => {
        this.setState({
            notificationUnReadOnly: value.target.value
        })
    }

    changeNotificationItemNum = (value) => {
        this.setState({
            notificationItemNum: value
        })
    }


    changeQuestionnaireUnAnsweredOnly = (value) => {
        this.setState({
            questionnaireUnAnsweredOnly: value.target.value
        })
    }

    changeQuestionnaireItemNum = (value) => {
        this.setState({
            questionnaireItemNum: value
        })
    }


    onChange = (checkedList) => {
        this.setState({
            checkedList,
            indeterminate: !!checkedList.length && (checkedList.length < paramQuestionnaireCategory.length),
            checkAll: checkedList.length === paramQuestionnaireCategory.length,
        });
    };

    onCheck = (checkedValue) => {
        log.debug(checkedValue)
        switch (this.state.module_type) {
            case 2:

                this.setState({
                    notificationCheckedCategory: checkedValue
                })

                break;
            case 3:
                this.setState({
                    questionnaireCheckedCategory: checkedValue
                })
                break;

            case 4:
                this.setState({
                    calendarCheckedCategory: checkedValue
                })

            case 6:
                this.setState({
                    curriculumCheckedCategory: checkedValue
                })
            default:
                break;
        }
    }


    render() {

        log.debug(this.state.partsObject);

        const {getFieldDecorator, getFieldValue} = this.props.form;
        const formItemLayout = {
            labelCol: {
                xs: {span: 24},
                sm: {span: 4},
            },
            wrapperCol: {
                xs: {span: 24},
                sm: {span: 20},
            },
        };
        const formItemLayoutWithOutLabel = {
            wrapperCol: {
                xs: {span: 24, offset: 0},
                sm: {span: 20, offset: 4},
            },
        };

        let title = !this.state.isEditMode ? <span><AppstoreOutlined/>&nbsp;モジュールの新規作成</span> :
            <span><AppstoreOutlined/>&nbsp;モジュールの編集</span>;


        const ssoList = this.state.ssoArray.map((k, index) => {
            return (
                <Option key={k.uuid} value={k.uuid}>
                    <div className="select-title">{k.name}</div>
                    <div className="select-description">{k.url}</div>
                </Option>
            )
        });

        const formItems = this.state.partsKeyArray.map((k, index) => {
            const object = this.state.partsObject[k];
            log.debug(object)
            return (
                <FormItem
                    {...(index === 0 ? formItemLayout : formItemLayoutWithOutLabel)}
                    label={index === 0 ? 'リンクの挿入' : ''}
                    key={k}>

                    <Row gutter={24}>

                        <Col span={6}>
                            <Input placeholder="リンクタイトル" value={object.text}
                                   onChange={(e) => this.changePartsTitle(e, k)}/>
                        </Col>

                        <Col span={6}>
                            <Input placeholder="URL" value={object.url} onChange={(e) => this.changePartsUri(e, k)}/>
                        </Col>

                        <Col span={1}>
                            OR
                        </Col>
                        <Col span={6}>
                            <Select placeholder="SSOリンクを選択してください" value={object.sso_uuid}
                                    onSelect={(value) => this.changePartsSSO(value, k)}
                                    showSearch
                                    optionFilterProp="children"
                                    filterOption={(input, option) => {
                                        return option.props.children[0].props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0 || option.props.children[1].props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
                                    }
                                    }
                            >
                                {ssoList}
                            </Select>
                        </Col>
                        <Col span={3}>
                            <FormItem
                            >
                                {getFieldDecorator(`${k}`, {
                                    initialValue: object.target_uuid,
                                    rules: [{required: true, message: '対象選択が必須です'}],
                                })(
                                    <>
                                        <Button type="primary" className="button" icon={<UserOutlined/>}
                                                onClick={() => this.openTargetModal(`${k}`)}>
                                            通知対象者を選択
                                        </Button>
                                        <span>　</span>
                                        <Button type="primary" className="button" icon={<StarOutlined/>}
                                                onClick={() => {
                                                    this.setState({
                                                        myTargetModalIsOpen: true,
                                                        editingTargetKey: `${k}`
                                                    })
                                                }}>
                                            よく使う対象者から選択
                                        </Button>
                                    </>
                                )}
                            </FormItem>
                        </Col>
                        <Col span={1}>
                            {this.state.partsKeyArray.length > 1 ? (
                                <MinusCircleOutlined
                                    className="dynamic-delete-button"
                                    disabled={this.state.partsKeyArray.length === 1}
                                    onClick={() => this.remove(k)}/>
                            ) : null}

                        </Col>

                    </Row>

                    <Row gutter={24}>
                        <Col span={22}>
                            <FormItem
                                {...formItemLayout}
                                label="新規タブ/ウィンドウ設定"
                                layout="inline">
                                <Row gutter={24}>
                                    <Col span={6}>
                                        <RadioGroup defaultValue={true} value={object.open_new_window}
                                                    onChange={(value) => this.changeOpenNewWindow(value, k)}>
                                            <Radio value={true}>新規タブ/ウィンドウで開く</Radio>
                                            <Radio value={false}>現在のタブ/ウィンドウで移動</Radio>
                                        </RadioGroup>
                                    </Col>
                                </Row>
                            </FormItem>


                            <FormItem
                                {...formItemLayout}
                                label="日付設定"
                                layout="inline">
                                <Row gutter={24}>
                                    <Col span={6}>
                                        <RadioGroup defaultValue={true}
                                                    value={object.enable_range}
                                                    onChange={(value) => this.changeEnableRange(value, k)}>
                                            <Radio value={true}>日付設定を行う</Radio>
                                            <Radio value={false}>日付設定を行わない</Radio>
                                        </RadioGroup>
                                    </Col>
                                    {object.enable_range ?
                                        <Col span={18}>
                                            <RangePicker
                                                locale={jaJP}
                                                value={object.range_picker}
                                                onChange={(value) => this.changeRange(value, k)}
                                                showTime={{format: 'HH:mm'}}
                                                format="YYYY/MM/DD HH:mm"
                                                ranges={GlobalConfig.DatePickerRanges()}/>
                                        </Col>
                                        :
                                        false}
                                </Row>
                            </FormItem>
                        </Col>
                        <Col span={1}>
                            <UpCircleOutlined
                                className="dynamic-sort-button"
                                disabled={index === 0}
                                onClick={() => {
                                    if (index !== 0) {
                                        this.sort(index, -1)
                                    }
                                }}/>
                            <DownCircleOutlined
                                className="dynamic-sort-button"
                                disabled={index === this.state.partsKeyArray.length - 1}
                                onClick={() => {
                                    if (index !== this.state.partsKeyArray.length - 1) {
                                        this.sort(index, 0)
                                    }
                                }
                                }/>
                        </Col>
                    </Row>

                </FormItem>
            );
        });

        const plainCurriculumOptions = this.state.paramCurriculumCategoryArray.map((value, index) => {
            return <Col key={value.uuid} span={8}><Checkbox value={value.uuid}>{value.name}</Checkbox></Col>
        })


        const plainNotificationOptions = this.state.paramNotificationCategoryArray.map((value, index) => {
            return <Col key={value.uuid} span={8}><Checkbox value={value.uuid}>{value.name}</Checkbox></Col>
        });


        const plainQuestionnaireOptions = this.state.paramQuestionnaireCategoryArray.map((value, index) => {
            return <Col key={value.uuid} span={8}><Checkbox value={value.uuid}>{value.name}</Checkbox></Col>
        });

        const plainCalendarOptions = this.state.paramCalendarCategoryArray.map((value, index) => {
            return <Col key={value.uuid} span={8}><Checkbox value={value.uuid}>{value.title}</Checkbox></Col>
        });


        const columns = [{
            title: '氏名',
            dataIndex: 'name',
            key: 'name',
            render: (text, record, index) => {
                let fileName = "";
                if (!record.is_file) {
                    fileName = (
                        <div className={"file-share-list-row"} onClick={() => {
                            this.setState({
                                currentUUID: record.uuid
                            }, () => {
                                this.explorerFile();
                            })
                        }
                        }>
                            <FolderOutlined style={{fontSize: 17, color: '#08c', marginRight: '5px'}}/>{text}
                        </div>
                    );
                } else {
                    fileName = (
                        <div className={"file-share-list-row"}>
                            <FileOutlined style={{fontSize: 17, color: '#08c', marginRight: '5px'}}/>{text}
                        </div>
                    );
                }
                return fileName
            }
        }, {
            title: "摘要",
            dataIndex: "description",
            key: 'description',
            render: (text, record) => {
                return (
                    <div>
                        {record.description}
                    </div>
                )
            }
        }, {
            title: '作成者/更新者',
            key: 'author',
            width: 100,
            render: (text, record) => {
                if (record.hasOwnProperty("updated_by") && record.updated_by) {
                    return <div>{record.updated_by.name}</div>
                } else if (record.hasOwnProperty("created_by") && record.created_by) {
                    return <div>{record.created_by.name}</div>
                } else {
                    return <div/>
                }
            }
        }, {
            title: "有効期限",
            key: 'term',
            width: 120,
            render: (text, record, index) => {
                return (
                    <div>
                        {moment.unix(record.publish_start_at).format("YYYY.MM.DD")}<br/>
                        {moment.unix(record.publish_end_at).format("YYYY.MM.DD")}
                    </div>
                )
            }
        }, {
            title: '更新日時',
            key: 'date',
            width: 120,
            render: (text, record) => {
                return (
                    <div>{moment.unix(record.updated_at).format("YYYY.MM.DD HH:mm")}</div>
                )
            }
        }
        ];


        let upParentFolder = <div/>;
        if (this.state.folderMeta && this.state.folderMeta.parent) {
            upParentFolder = (
                <div>
                    <Button onClick={() => {
                        this.setState({
                            currentUUID: this.state.folderMeta.parent.uuid
                        }, () => {
                            this.explorerFile();
                        })
                    }
                    }
                            icon={<UpOutlined/>}>
                        上のフォルダへ
                    </Button>
                </div>
            )
        } else {
            upParentFolder = (
                <div>
                    <Button onClick={() => {
                        this.setState({
                            currentUUID: undefined
                        }, () => {
                            this.explorerFile();
                        })
                    }
                    }
                            icon={<UpOutlined/>}>
                        上のフォルダへ
                    </Button>
                </div>
            )
        }


        //モジュール種別(1: リンクBOX 2: お知らせ 3: アンケート 4: 学年暦 5:ファイル共有  6:教務情報)
        return (
            <Modal
                className="common-modal select-target-form"
                title={title}
                visible={this.state.visible}
                maskClosable={false}
                onOk={this.handleOk}
                confirmLoading={this.confirmLoading}
                onCancel={this.handleCancel}>
                <div className='wrapper'>
                    <Form className="search-form" onSubmit={this.onSubmit}>
                        <FormItem
                            {...formItemLayout}
                            label="モジュール種別">
                            {getFieldDecorator('module_type', {
                                initialValue: this.state.module_type,
                                rules: [{required: true, message: 'モジュール種別が必須です'}],
                            })(
                                <Select placeholder="モジュール種別を選択してください"
                                        onChange={this.changeModuleType}>
                                    <Option key="linkbox" value={TYPE_ENUM.LINK}>
                                        <div className="select-title">リンクボックス</div>
                                        <div className="select-description">通常リンクやSSO連携先リンクを表示します</div>
                                    </Option>
                                    <Option key="notifications" value={TYPE_ENUM.NOTIFICATION}>
                                        <div className="select-title">お知らせ</div>
                                        <div className="select-description">全てまたはカテゴリ別のお知らせ情報を表示します</div>
                                    </Option>
                                    <Option key="questionnaires" value={TYPE_ENUM.QUESTIONNAIRE}>
                                        <div className="select-title">アンケート</div>
                                        <div className="select-description">全てまたはカテゴリ別のアンケート一覧を表示します</div>
                                    </Option>
                                    <Option key="calendars" value={TYPE_ENUM.CALENDAR}>
                                        <div className="select-title">学年暦</div>
                                        <div className="select-description">全てまたはカテゴリ別の学年暦を表示します</div>
                                    </Option>
                                    <Option key="fileshare" value={TYPE_ENUM.FILESHARE}>
                                        <div className="select-title">ファイル共有</div>
                                        <div className="select-description">指定したフォルダのファイルを表示します</div>
                                    </Option>
                                    <Option key="curriculum" value={TYPE_ENUM.CURRICULUM}>
                                        <div className="select-title">教務掲示情報</div>
                                        <div className="select-description">教務掲示を表示します</div>
                                    </Option>
                                </Select>
                            )}
                        </FormItem>

                        <FormItem
                            {...formItemLayout}
                            label="モジュール名称">
                            {getFieldDecorator('module_name', {
                                initialValue: this.state.module_name,
                                rules: [{required: true, message: 'モジュール名称が必須です'}],
                            })(
                                <Input placeholder="モジュール名称を入力してください"
                                       onChange={this.changeModuleName}/>
                            )}
                        </FormItem>
                        <FormItem
                            {...formItemLayout}
                            label="摘要">
                            <Input placeholder="摘要を入力してください"
                                   value={this.state.module_description}
                                   onChange={this.changeModuleDescription}/>
                        </FormItem>
                        <FormItem
                            {...formItemLayout}
                            label="表示対象者の絞込">
                            {getFieldDecorator('module_main', {
                                initialValue: this.state.module_target_uuid,
                                rules: [{required: true, message: '表示対象者の絞込が必須です'}],
                            })(
                                <>
                                    <Button type="primary" className="button" icon={<UserOutlined/>}
                                            onClick={() => this.openTargetModal("module_main")}>
                                        通知対象者を選択
                                    </Button>
                                    <span>　</span>
                                    <Button type="primary" className="button" icon={<StarOutlined/>}
                                            onClick={() => {
                                                this.setState({
                                                    myTargetModalIsOpen: true,
                                                    editingTargetKey: "module_main"
                                                })
                                            }}>
                                        よく使う対象者から選択
                                    </Button>
                                </>
                            )}
                        </FormItem>

                        <FormItem
                            {...formItemLayout}
                            label="並び順">
                            {getFieldDecorator('module_order', {
                                initialValue: this.state.module_order,
                                rules: [{required: true, message: '並び順の入力が必須です'}],
                            })(
                                <InputNumber
                                    min={1}
                                    placeholder="半角数字"
                                    onChange={this.changeModuleOrder}/>
                            )}
                        </FormItem>

                        {this.state.module_type === 1 ?
                            <div>
                                {/*リンクBOX*/}
                                {formItems}
                                <FormItem {...formItemLayoutWithOutLabel}>
                                    <Button type="dashed" onClick={this.add}>
                                        <PlusOutlined/> リンクを追加
                                    </Button>
                                </FormItem>
                            </div> : null}


                        {/*お知らせ*/}
                        {this.state.module_type === 2 ?
                            <div>
                                <FormItem
                                    {...formItemLayout}
                                    label={
                                        <span>
                                     未読/すべて&nbsp;&nbsp;
                                            <Tooltip title="表示内容を未読のみに限定するか否かを選択します">
                                        <QuestionCircleOutlined/>
                                    </Tooltip>
                                 </span>
                                    }>
                                    <RadioGroup onChange={this.changeNotificationUnreadOnly}
                                                value={this.state.notificationUnReadOnly}>
                                        <Radio value={true}>未読のみ</Radio>
                                        <Radio value={false}>すべて</Radio>
                                    </RadioGroup>
                                </FormItem>

                                <FormItem
                                    {...formItemLayout}
                                    label="取得件数">
                                    {getFieldDecorator('notificationItemNum', {
                                        initialValue: this.state.notificationItemNum,
                                        rules: [{required: true, message: '取得件数の入力が必須です'}],
                                    })(
                                        <InputNumber min={10} max={100}
                                                     onChange={this.changeNotificationItemNum}/>
                                    )}
                                </FormItem>

                                <FormItem
                                    {...formItemLayout}
                                    label="絞込カテゴリ">
                                    <div>
                                        <Checkbox.Group value={this.state.notificationCheckedCategory}
                                                        onChange={(value) => this.onCheck(value)}>
                                            <Row>
                                                {plainNotificationOptions}
                                            </Row>
                                        </Checkbox.Group>
                                    </div>
                                </FormItem></div>
                            :
                            null}

                        {/*アンケート*/}
                        {this.state.module_type === 3 ?
                            <div>
                                <FormItem
                                    {...formItemLayout}
                                    label={
                                        <span>
                                     未読/すべて&nbsp;&nbsp;
                                            <Tooltip title="表示内容を未回答のみに限定するか否かを選択します">
                                        <QuestionCircleOutlined/>
                                    </Tooltip>
                                 </span>
                                    }>
                                    <RadioGroup onChange={this.changeQuestionnaireUnAnsweredOnly}
                                                value={this.state.questionnaireUnAnsweredOnly}>
                                        <Radio value={0}>すべて</Radio>
                                        <Radio value={1}>未回答のみ</Radio>
                                    </RadioGroup>
                                </FormItem>

                                <FormItem
                                    {...formItemLayout}
                                    label="取得件数">
                                    {getFieldDecorator('取得件数', {
                                        initialValue: this.state.questionnaireItemNum,
                                        rules: [{required: true, message: '取得件数の入力が必須です'}],
                                    })(
                                        <InputNumber min={10} max={100}
                                                     onChange={this.changeQuestionnaireItemNum}/>
                                    )}
                                </FormItem>

                                <FormItem
                                    {...formItemLayout}
                                    label="絞込カテゴリ">
                                    <div>

                                        <div>
                                            <Checkbox.Group value={this.state.questionnaireCheckedCategory}
                                                            onChange={this.onCheck}>
                                                <Row>
                                                    {plainQuestionnaireOptions}
                                                </Row>
                                            </Checkbox.Group>
                                        </div>
                                    </div>
                                </FormItem>
                            </div>
                            :
                            null}


                        {/*学年暦*/}
                        {this.state.module_type === 4 ?
                            <div>
                                <FormItem
                                    {...formItemLayout}
                                    label="学年暦">
                                    <div>

                                        <div>
                                            <Checkbox.Group value={this.state.calendarCheckedCategory}
                                                            onChange={this.onCheck}>
                                                <Row>
                                                    {plainCalendarOptions}
                                                </Row>
                                            </Checkbox.Group>
                                        </div>
                                    </div>
                                </FormItem>
                            </div>
                            :
                            null}


                        {/*フォルダ*/}
                        {this.state.module_type === 5 ?
                            <div>
                                <FormItem
                                    {...formItemLayout}
                                    label="表示フォルダ">
                                    <div>
                                        {this.state.folderMeta && this.state.folderMeta.name ?
                                            <h3>{this.state.folderMeta.name}</h3>
                                            :
                                            <h3>未選択</h3>
                                        }
                                        {getFieldDecorator('shared_folder_uuid', {
                                            initialValue: this.state.shared_folder_uuid,
                                            rules: [{required: true, message: 'フォルダの選択が必須です'}],
                                        })(
                                            <Table
                                                className="general-table"
                                                size="middle"
                                                rowKey={"uuid"}
                                                locale={{
                                                    filterTitle: 'フィルタ',
                                                    filterConfirm: '確定',
                                                    filterReset: 'リセット',
                                                    emptyText: '該当するものはありません',
                                                }}
                                                bordered={true}
                                                loading={this.state.loading}
                                                pagination={this.state.pagination}
                                                onChange={this.handleTableChange}
                                                columns={columns}
                                                dataSource={this.state.fileShareArray}
                                                title={() => {
                                                    return (
                                                        <div className="button-container">
                                                            <div className={"button-container-left"}>
                                                                {upParentFolder}
                                                            </div>
                                                            <div className={"button-container-right"}>
                                                            </div>
                                                        </div>
                                                    )
                                                }}
                                            />
                                        )}
                                    </div>
                                </FormItem>
                            </div>
                            :
                            null}


                        {/*教務掲示情報*/}
                        {this.state.module_type === 6 ?
                            <div>
                                <FormItem
                                    {...formItemLayout}
                                    label="絞込カテゴリ">
                                    <div>
                                        <Checkbox.Group value={this.state.curriculumCheckedCategory}
                                                        onChange={(value) => this.onCheck(value)}>
                                            <Row>
                                                {plainCurriculumOptions}
                                            </Row>
                                        </Checkbox.Group>
                                    </div>
                                </FormItem></div>
                            :
                            null}

                        {
                            /*
                            //モジュール種別(1: リンクBOX 2: お知らせ 3: アンケート 4: 学年暦)

                            {
                                "type": 4,
                                "name": "\u30c6\u30b9\u30c8\u5b66\u5e74\u66a6",
                                "description": "\u30c6\u30b9\u30c8\u304b\u3089\u8ffd\u52a0\u3055\u308c\u305f\u5b66\u5e74\u66a6\u30e2\u30b8\u30e5\u30fc\u30eb\u3067\u3059",
                                "order": 4,
                                "parts": [
                            {
                                "calendar_source_uuid": "574929bd-c5eb-11e7-8aa7-0242ac130004"
                            }
                                ],
                                "target_uuid": "3920e1c0-c641-11e7-800e-0242ac130006"
                            }
                            */
                        }

                        {/*通知対象者*/}
                        <SelectTargetForm
                            visible={this.state.targetModalIsOpen ? this.state.targetModalIsOpen : false}
                            handleOk={this.selectTarget}
                            handleCancel={() => this.setState({
                                targetModalIsOpen: false
                            })}
                            // 編集時にuuiが必要になるからtarget-uuid
                            // 編集時にuuidが必要になるから本体のuuid
                            self_uuid={this.state.target_uuid}
                            foreign_uuid={this.state.foreign_uuid}
                            key={this.state.editingTargetKey}
                            type={5/*設定種類 (1: お知らせ 2: アンケート 3: メニュー 4: モジュール 5: モジュールリンク*/}
                        />

                        {/*通知対象者を保存済のから取得して処理する*/}
                        <MyTargetModal
                            visible={this.state.myTargetModalIsOpen}
                            handleOk={(value) => {
                                this.setState({
                                    target_uuid: value.target_uuid,
                                    myTargetModalIsOpen: false
                                }, () => {
                                    this.openTargetModal(this.state.editingTargetKey, true);
                                });
                            }}
                            handleCancel={() => {
                                this.setState({myTargetModalIsOpen: false})
                            }}/>

                    </Form>
                </div>

            </Modal>
        );
    }
}


export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Form.create()(ModuleAddEditModal))


ModuleAddEditModal.propTypes = {
    visible: PropTypes.bool.isRequired,
    isEditMode: PropTypes.bool.isRequired,
    handleOk: PropTypes.func.isRequired,
    handleCancel: PropTypes.func.isRequired,
    data: PropTypes.object
};