import React from 'react';
import moment from 'moment';
import {Card, Tooltip,} from 'antd';
import metadata from "../metadata.json"

// カスタムイメージ
import KpMenuLogo from '../images/skin/ku/menu-logo.png';
import KpMenuLogo2x from '../images/skin/ku/menu-logo@2x.png';
import KpMenuLogoSvg from '../images/skin/ku/menu-logo.svg';

import KpFooterLogo from '../images/skin/ku/footer-logo.png';
import KpFooterLogo2x from '../images/skin/ku/footer-logo@2x.png';
import KpFooterLogoSvg from '../images/skin/ku/footer-logo.svg';

import KpSpAd from '../images/skin/ku/login-ad.png';
import KpSpAd2x from '../images/skin/ku/login-ad@2x.png';

// バージョン情報
//generate-buildno.jsを前もって走らせることで自動インクリメントするように
export const getVersionName = () => {
    return `KnowledgePortal/Web ${metadata.buildMajor}.${metadata.buildMinor}.${metadata.buildRevision} ${metadata.buildDate} ${metadata.buildTag}`
}

// サービス名称
export const ServiceName = "関西大学インフォメーションシステム";
// サービス名称 htmltitle部分
export const ServiceTitle = "KANSAI UNIVERSITY Information system"

// サービス提供事業者名称
export const OwnerName = "関西大学";

// アクセス解析用 GoogleAnalytics TrackingID
export const GATag = "G-0VCN4CEMC3";

export const domain = "https://ku.portal.ac.jp";
// 管理画面サブディレクトリ
export const AdminSubDirectory = "/console";
// 利用者画面サブディレクトリ
export const UserSubDirectory = "/dashboard";

// リダイレクトページ
export const RedirectSubDirectory = "/redirect"

// 左側メニューロゴイメージ
export const MenuLogo = KpMenuLogo;
export const MenuLogo2x = KpMenuLogo2x;
export const MenuLogoSvg = KpMenuLogoSvg;

// フッターロゴイメージ
export const FooterLogo = KpFooterLogo;
export const FooterLogo2x = KpFooterLogo2x;
export const FooterLogoSvg = KpFooterLogoSvg;

// ログイン画面タイトル（インデックスされる唯一のページとなるため、タイトルはSEO対策を考慮すること）
export const LoginTitle = "関西大学インフォメーションシステム";

// アプリサポートサイト
export const SupportWeb = "https://kansai-u.portal.ac/support/";

// ログイン画面フォーム下に表示するアピール用エリア
export const LoginAd =
    <div>
        <a href={SupportWeb} target="_blank" rel="noopener noreferrer">
            <img src={KpSpAd}
                         srcSet={KpSpAd + ' 1x,' + KpSpAd2x + ' 2x'}
                         alt={OwnerName + 'スマートフォンアプリ提供中'}/>
        </a>
    </div>;


// ログイン画面フォームの「ログインでお困りの方」コンテンツ
export const LoginPasswordForget =
    <div>
        <Card>
        <h2>ログインでお困りの方へ</h2>
        <p>
            パスワードを忘れた場合は、学生証、教職員証又は入退室カードを各受付場所に持参して、手続きを行ってください。
        </p>
        <p>各キャンパスの情報メディアセンターにお越しください。<br/>
        ■ 千里山キャンパス：<a href={"https://www.itc.kansai-u.ac.jp/start/guide.html#itcenter"}>ITセンター4階利用相談コーナー</a><br/>
        ■ 高槻キャンパス：総合情報学部オフィス <br/>
        ■ 高槻ミューズキャンパス：ミューズオフィス<br/>
        ■ 堺キャンパス：PC教室受付 <br/>
        </p>
        </Card>
    </div>;


// ログインの強制切断用フォーム 403
export const LoginServerError =
    <div>
        <p>
            現在、インフォメーションシステムサーバが大変混み合っているため、ログインを制限しています。
            時間をあけて再度ログインしてください
        </p>
    </div>;


export const FooterLogoLink = "https://www.kansai-u.ac.jp/ja/";

//セッションエラー時のメッセージ
//loginCheckの返答値のerrors値が少しおかしいので、ここでSessionErrorMessageとする
export const SessionErrorMessage = "セッションタイムアウト";


// フッターに表示されるサービス提供事業者名称
export const OwnerInfoComponent =
    <span>
関西大学<br/>
〒564-8680 大阪府 吹田市 山手町 3丁目 3番 35号<br/>
TEL （06）6368-1121（大代表）
    </span>;



// フッターに表示されるバージョン情報コピーライト
export const CopyrightComponent =
    <span>
        {getVersionName()}<br/>
        Copyright &copy; KANSAI UNIVERSITY All rights reserved.
    </span>;


export const Validation = {
    NotificationTitleMaxCount: 100,
    NotificationTitleMessage: 'お知らせのタイトルを入力してください'
}

export const PageSizeOptions = ["10", "20", "50", "100"];


export const FormHelpTextOverride = {
    iconText: {
        NotificationCategory: "お知らせの「カテゴリ」は、正しく選択してください。",
        NotificationTitle: "機種依存文字（Ⅰ、Ⅱ、Ⅲ…、●、★など）は使用してはいけません。やむを得ず強調する場合は【　】の使用で統一してください。",
        NotificationPublish: "原則3カ月以内としてください。",
        NotificationPublishFrom: "配信元は受信者のお知らせ確認画面に表示されます。",
        NotificationTarget: "可能なかぎり絞込を行ってください。",
        QuestionnaireIsForced: "キャリアアンケート以外は強制回答の選択はできません",
        NotificationPush: "通知をオンにするとスマートフォンアプリへお知らせがプッシュ配信されます。「授業・履修・試験・学籍」カテゴリのうち、緊急を要する場合においても、設定可能です。"
    },
    bottomText: {
        NotificationCategory : "同一内容のお知らせを、カテゴリを変えて複数発信しないでください。",
        NotificationPublishFrom: "配信元をリストから選択するか、入力してください。",
        NotificationPublish: "イベント、締切又は対象期間が決まっているものは、最長でも期日の翌日までに設定してください。",
        NotificationTitle: "シンプルかつ内容がわかる表現で30字以内としてください。",
        Priority:"「緊急」は構成員の安全に関わる内容においてのみ使用してください。",
        IsSendNotification: "原則、カテゴリが「個人伝言」「危機事象・災害」のみ設定してください。",
        Target: "作成ボタンを押す前に、必ず通知対象者を確認してください。",
        Permission: "部署共有をする場合は、自部署を選択してください。"
    },
    attachHint: {// 添付ファイル
        Default:
            <div>サイズ制限 : 最大10MBまで,
                対応形式 : [
                <Tooltip title="jpg,jpeg,png,gif,bmp">画像</Tooltip>,
                <Tooltip title="pdf">PDF</Tooltip>,
                <Tooltip title="zip,lzh,gz">圧縮ファイル</Tooltip>,
                <Tooltip title="txt,csv">テキスト</Tooltip>,
                <Tooltip title="doc,docx,xls,xlsx,ppt,pptx">オフィスドキュメント</Tooltip>]
            </div>,//共通
        CatchEye:
            <div>サイズ制限 : 最大10MBまで,
                対応形式 : [
                    <Tooltip title="jpg,jpeg,png,gif,bmp">画像</Tooltip>
                ]
            </div>,//共通

    }
};

function getQuarter() {
    const nendo = moment().subtract(3, 'months').year();
    const nextNendo = nendo + 1;
    let obj = {};
    obj.quarter1 = {start: moment(nendo,"YYYY").quarter(2).startOf("quarter"), end: moment(nendo,"YYYY").quarter(2).endOf("quarter")}
    obj.quarter2 = {start: moment(nendo,"YYYY").quarter(3).startOf("quarter"), end: moment(nendo,"YYYY").quarter(3).endOf("quarter")}
    obj.quarter3 = {start: moment(nendo,"YYYY").quarter(4).startOf("quarter"), end: moment(nendo,"YYYY").quarter(4).endOf("quarter")}
    obj.quarter4 = {start: moment(nextNendo,"YYYY").quarter(1).startOf("quarter"), end: moment(nextNendo,"YYYY").quarter(1).endOf("quarter")}
    return obj;
}

// 日時選択時の共通選択肢
export const DatePickerRanges = () => {
    return ({
        '今日まで': [moment(), moment().endOf('day')],
        '明日まで': [moment(), moment().add(1, 'days').endOf('day')],
        '7日間': [moment(), moment().add(7, "days").endOf('day')],
        '30日間': [moment(), moment().add(30, "days").endOf('day')],
        '今月末まで': [moment(), moment().endOf('month')],
        '来月末まで': [moment(), moment().add(1, "months").endOf('month')],
        "年度末まで": [getQuarter().quarter1.start, getQuarter().quarter4.end],
        "無期限": [moment.unix(0),moment.unix(2147483647)]
    });
};

/**
 * API用のドメインを自身のホスト名から生成
 * @returns {string}
 */
export const generateApiDomain = (openWithBrowser) => {
    return "/"
};


// SSOFORM連携時の変数置換リスト
export const FormAuthVariableList = [
//    {key: "%%STR_NAME%%", description: "DESCRIPTION_TEXT"}
];

//Froalaのリンクのデフォルトリスト
export const FroalaLinkList = [
    {
        text: 'Google',
        href: 'http://google.com',
        target: '_blank',
        rel: 'nofollow'
    }
];


export const getDefaultLayout = (isLonglabel,isVertical) => {
    if(isVertical){
        return {
            labelCol: {span: 24},
            wrapperCol: {span: 24}
        }
    }else {
        return {
            labelCol: {
                sm: {span: 24},
                md: {span: 24},
                xl: {span: isLonglabel ? 6 : 4},
            },
            wrapperCol: {
                sm: {span: 24},
                md: {span: 24},
                xl: {span: isLonglabel ? 18 : 20},
            }
        }
    }
};


export const FroalaEditorLicenceKey = "Ne2C1sA3F4J4A13C10D8jF1QUg1Xc2OZE1ABVJRDRNGGUH1ITrA1C7A6D6E1D4B3E1B9D9==";
//wild card用のunlimitedに変更
