import React, {Component} from 'react';
import {connect, store} from 'react-redux';
import * as Actions from '../../redux/actions';
import {LoadingOutlined, LogoutOutlined, SelectOutlined} from '@ant-design/icons';
import { Menu, Button, Popconfirm, Spin } from 'antd';
import {withRouter, Link} from 'react-router-dom';
import log from '../../components/utils/Logger.js';
import 'moment/locale/ja';
import * as GlobalConfig from '../../constants/GlobalConfig';
import Account from './Account'
import ForceModuleQuestionnaire from "../services/modules/questionnaire/ForceModuleQuestionnaire";
import ModuleFileShareModalList from "../services/modules/fileshare/ModuleFileShareModalList";
import isUUID from "is-uuid";

const mapStateToProps = (state, props) => {
    return {
        screen: state.screen,
        menu: state.menu,
        session: state.session
    }
};

const mapDispatchToProps = dispatch => {
    return {
        requestLogout: () => {
            dispatch(Actions.Connection.logout.request());
        },
        getMenu: () => {
            dispatch(Actions.Connection.menu.search.request());
        }
    }
};

class DashboardMenu extends React.Component {
    constructor(props) {
        super(props);
        //メニュー用制御
        let path = props.location.pathname.replace(/\/$/, "");
        path = path.substr(path.lastIndexOf('/')+1);

        let currentUUID = undefined;
        let isFileOpener = false;
        if(props.location.pathname.match(/fileshare/)){
            currentUUID = path;
            log.debug(currentUUID);
            if (!isUUID.anyNonNil(currentUUID)) {
                currentUUID = undefined;
            }else{
                isFileOpener = true;
            }
        }

        this.state = {
            collapsed: false,
            pathName: path ? path : "/",
            menuListArray: [],
            loading: true,
            isFileOpener,
            currentUUID
        }
    }
    componentWillMount(){
        this.props.getMenu();
    }
    componentWillReceiveProps(nextProps) {
        log.debug(nextProps.paramMenu);
        if (nextProps.pathName !== this.props.pathName) {
            log.debug(nextProps.pathName.replace(GlobalConfig.UserSubDirectory, ""));
            this.setState({
                pathName: nextProps.pathName.replace(GlobalConfig.UserSubDirectory, "")
            })
        }

        if (nextProps.menu !== this.props.menu) {
            if (!nextProps.menu.meta.fetch) {
                if (!nextProps.menu.error) {
                    if (nextProps.menu.payload !== null) {
                        const menuObject = {};
                        const menuArray = nextProps.menu.payload.result.items.map((value,index) => {
                            menuObject[value.uuid] = value;
                            return value;
                        });
                        log.debug(this.state.pathName);
                        let pathName = this.state.pathName;
                        if(pathName === "dashboard" && menuArray.length !== 0){
                            pathName = nextProps.menu.payload.result.items[0].uuid;
                            log.debug(pathName);
                        }
                        this.setState({
                            loading: nextProps.menu.meta.fetch,
                            menuListArray: nextProps.menu.payload.result.items,
                            menuObject,
                            pathName
                        })
                    }
                }
            } else {
                this.setState({
                    loading: nextProps.menu.meta.fetch
                })
            }
        }

    }


    handleSelect = (item, key, selectedKeys) => {
        log.debug(this.state.menuListArray);
        let value = this.state.menuObject[item.key];
        //this.props.changeMenu(value);
        this.props.history.push(`${GlobalConfig.UserSubDirectory}/menu/${value.uuid}`)
    };


    render() {

        let menuComponent;
        if(this.state.menuListArray.length === 0) {
                menuComponent = null;
        }else{
            menuComponent =  this.state.menuListArray.map((value,index) => {
                return (
                    <Menu.Item key={value.uuid}>
                        <span>{value.name}</span>
                    </Menu.Item>
                );
            });
        }


        let portalAdminButton;
        if(this.props.session.payload.isAuthenticated){
            switch(this.props.session.payload.user.permission){
                case "admin":
                case "manage":
                    portalAdminButton = (
                        <Button type="secondary" className="login-form-button" icon={<SelectOutlined />} onClick={() => {
                            this.props.history.push(GlobalConfig.AdminSubDirectory)
                        }}>
                            インフォメーションシステム管理
                        </Button>
                    )
                    break;
                case "student":
                default:
                    portalAdminButton = null;
                    break;
            }
        }

        return (
            <div id="menu">
                <div className="header-logo">
                    <img src={GlobalConfig.MenuLogo}
                         srcSet={GlobalConfig.MenuLogoSvg + ' ,' +GlobalConfig.MenuLogo + ' 1x,'}
                         alt={GlobalConfig.ServiceName}
                         style={{padding:"4px"}}
                    />
                    <Account/>
                    {portalAdminButton}
                    <Popconfirm
                        title={
                            <span>
                            <strong>{GlobalConfig.ServiceName}からログアウトします。</strong><br/>
                                    よろしいですか？
                            </span>
                        }
                        onConfirm={() => {
                            this.props.requestLogout()
                        }}
                        onCancel=""
                        placement="left"
                        okText="ログアウト" cancelText="キャンセル">
                        <Button type="danger" className="login-form-button" icon={<LogoutOutlined />}>
                            <span>ログアウト</span>
                        </Button>
                    </Popconfirm>
                </div>


                {this.state.loading ?
                    <div style={{padding:"200px 10px",textAlign:"center",color:"white"}}>
                        <LoadingOutlined />&nbsp;読み込み中...</div>:
                    <Menu
                        defaultSelectedKeys={[this.state.pathName]}
                        onSelect={this.handleSelect}
                        mode="inline"
                        theme="dark"
                        inlineCollapsed={this.state.collapsed}>
                        {menuComponent}
                    </Menu>
                }

                {/* ModleFileShareModal Listの*/}
                <ModuleFileShareModalList
                    visible={this.state.isFileOpener}
                    isDirect={this.state.isFileOpener}
                    currentUUID={this.state.currentUUID}
                    handleCancel={() => {
                        this.setState({
                            isFileOpener: false
                        })
                    }}
                    handleOk={() => {
                        this.setState({
                            isFileOpener: false
                        })
                    }}
                />

            </div>
        );
    }
}

DashboardMenu.propTypes = {};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(DashboardMenu));
