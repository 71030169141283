import React from 'react'
import {connect, store} from 'react-redux'
import * as GlobalConfig from '../../constants/GlobalConfig';
import * as Actions from '../../redux/actions';
import { CloseOutlined, MinusCircleOutlined, PlusCircleOutlined, SearchOutlined } from '@ant-design/icons';
import {Button, Modal, Table, Row, Col, Tooltip, Popconfirm} from 'antd';
import AddUserModal from "./AddUserModal"
import log from 'components/utils/Logger.js'

import UserViewModal from "../users/UserViewModal";

const mapStateToProps = (state, props) => {
    return {
        screen: state.screen,
        group: state.group,
        userSearchTargetUser: state.userSearchTargetUser
    }
};

const mapDispatchToProps = dispatch => {
    return {
        searchUsers: (data,currentPageNum) => {
            dispatch(Actions.AdminConnection.user.searchTargetUser.request(data,currentPageNum));
        },

    }
};

class SelectTargetFormUsers extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            type: props.type,
            self_uuid: props.self_uuid,
            addUserModalIsOpen: false,
            viewUserModalIsOpen: false,
            viewModalUUID: null,
            userList: [],
            pagination: {
                showTotal: (total, range) => {

                    return (`全${total}件中 ${range[0]}-${range[1]} 件`)
                },
                showSizeChanger: true,
                pageSizeOptions: GlobalConfig.PageSizeOptions,
                pageSize: 20,
                onShowSizeChange: this.onChangePageSize,
            },
            loading: props.loading
        }
        this.search(undefined, props.self_uuid);
    }


    componentWillReceiveProps(nextProps) {
        const pagination = {...this.state.pagination};

        this.setState({
            type: nextProps.type,
            selectedRowKeys: nextProps.checkedKeys,
            selectedRows: nextProps.checkedRows,
            self_uuid: nextProps.self_uuid,
            loading: nextProps.loading
        });

        if (nextProps.userSearchTargetUser !== this.props.userSearchTargetUser) {
            if (!nextProps.userSearchTargetUser.meta.fetch) {
                if (!nextProps.userSearchTargetUser.error) {
                    if (nextProps.userSearchTargetUser.payload !== null) {
                        pagination.total = nextProps.userSearchTargetUser.payload.result.item_count;
                        this.setState(preview => ({
                            loading: nextProps.userSearchTargetUser.meta.fetch,
                            userSearchTargetUserListArray: nextProps.userSearchTargetUser.payload.result.items,
                            pagination: {
                                ...preview.pagination,
                                total: nextProps.userSearchTargetUser.payload.result.item_count
                            }
                        }))
                    }
                }else{
                    this.setState({
                        loading: nextProps.userSearchTargetUser.meta.fetch
                    })
                }
            } else {
                this.setState({
                    loading: nextProps.userSearchTargetUser.meta.fetch
                })
            }
        }

        //checkedKeysが変わった = 新規でユーザのuuidが増えたとか減ったとかあり得る。
        if(nextProps.etag !== this.props.etag){
            //Paginationのリセット
            this.setState(preview => ({
                pagination: {
                    ...preview.pagination,
                    current: 1,
                    showTotal: (total, range) => {
                        return (`全${total}件中 ${range[0]}-${range[1]} 件`)
                    },
                    showSizeChanger: true,
                    pageSizeOptions: GlobalConfig.PageSizeOptions,
                    pageSize: 20,
                    onShowSizeChange: this.onChangePageSize,
                },
            }),()=>{
                this.search(undefined, nextProps.self_uuid);
            });
        }

        if(nextProps.visible !== this.props.visible) {
            if (!nextProps.visible) {
                this.setState({
                    userSearchTargetUserListArray: []
                })
            }
        }


        if(nextProps.self_uuid !== this.props.self_uuid){
            this.search(undefined, nextProps.self_uuid);
        }


    }

    search(value,uuid) {
        let fieldsValue = {};
        let pagination;
        if (typeof value === "undefined") {
            pagination = {...this.state.pagination};
        } else {
            pagination = value;
        }
        fieldsValue.page_size = pagination.pageSize;
        if(this.state.type === "permission"){
            fieldsValue.user_permission_uuid = uuid;
        }else {
            fieldsValue.user_target_uuid = uuid;
        }
        //ターゲット絞込ができないと駄目なので
        if(uuid) {
            this.props.searchUsers(fieldsValue, pagination.current);
        }
    }

    onSetState(state) {
        this.setState(state)
    }

    onChangePageSize = (current, pageSize) => {
        this.setState(preview => ({
            pagination : {
                ...preview.pagination,
                pageSize
            }
        }))
    };

    handleTableChange = (pagination, filters, sorter) => {
        this.setState(preview => ({
            pagination : {
                ...preview.pagination,
                ...pagination
            }
        }),()=>{
            this.search(pagination,this.state.self_uuid);
        });
    };

    handleOk = (selectedRowKeys, selectedRows) => {
        this.setState({
            selectedRowKeys,
            selectedRows,
            addUserModalIsOpen: false
        });
        this.props.setObject(selectedRowKeys,selectedRows);
    };


    handleCancel = () => {
        this.setState({
            addUserModalIsOpen: false
        })
    };

    deleteRows = (record) => {
        this.props.deleteUsers(record.uuid);
    }

    deleteUserAll = () => {
        this.props.deleteUserAll();
    }

    addUserAll = (value) => {

        this.setState({
            addUserModalIsOpen: false
        });
        this.props.addUserAll(value);
    };

    addUserCSV = (value) => {

        this.setState({
            addUserModalIsOpen: false
        });
        this.props.addUserCSV(value);
    }

    handleOpenUserModal = (record) => {
        this.setState({
            viewUserModalIsOpen : true,
            viewModalUUID: record.uuid,
        });
    };


    render() {
        const columns = [
            {
                title: <span>学籍番号<br/>10桁コード</span>,
                dataIndex: 'personal_id',
                key: 'personal_id',
                width: "110px"
            }, {
                title: <span>学籍番号／<br/>教職員表示番号</span>,
                dataIndex: 'option_1',
                key: 'option_1',
                width: "110px"
            }, {
                title: '氏名',
                dataIndex: 'name',
                key: 'name',
            }, {
                title: '氏名(カナ/英)',
                dataIndex: 'name_kana',
                key: 'name_kana',
                width: "200px",
                render: (text, record) => {
                    return (
                        <div>
                            {record.name_kana}<br/>
                            {record.name_alphabet}
                        </div>
                    )
                }
            }, {
                title: "",
                width: "85px",
                key: 'action',
                render: (text, record) => {
                    return (
                        <Row type="flex" justify="space-around" align="middle">
                            <Col>
                                <Tooltip placement="topLeft" title="ユーザ情報">
                                    <Button type="default" shape="circle" icon={<SearchOutlined />} onClick={() => this.handleOpenUserModal(record)}/>
                                </Tooltip>
                            </Col>
                            <Col>
                                <Tooltip placement="topLeft" title="除外">
                                    <Button type="default" shape="circle" icon={<CloseOutlined />}
                                            onClick={() => this.deleteRows(record)}/>
                                </Tooltip>
                            </Col>
                        </Row>
                    );
                },
            }
        ];

        return (
            <div id="group-tree-container" className="wrapper" alt="グループ">
                <div className="group-tree-container-title-wrapper title-wrapper">
                    <h2>対象ユーザ</h2>
                    <Popconfirm
                        title={<span>ユーザリストからユーザすべてを削除します。<br/>
                                    <strong>削除すると復元することはできません。</strong></span>}
                        onConfirm={this.deleteUserAll}
                        onCancel=""
                        placement="left"
                        okText="削除" cancelText="キャンセル">
                        <Button type="primary" icon={<MinusCircleOutlined />}>
                            全て除外
                        </Button>
                    </Popconfirm>
                    &nbsp;
                    <Button type="primary" className="button"
                            onClick={() => this.onSetState({addUserModalIsOpen: true})} icon={<PlusCircleOutlined />}>
                        追加
                    </Button>

                </div>

                <Table
                    className="general-table"
                    locale={{
                        filterTitle: 'フィルタ',
                        filterConfirm: '確定',
                        filterReset: 'リセット',
                        emptyText: '該当するものはありません',
                    }}
                    pagination={this.state.pagination}
                    bordered={true}
                    size="middle"
                    rowKey={"uuid"}
                    loading={this.state.loading}
                    onChange={this.handleTableChange}
                    dataSource={this.state.userSearchTargetUserListArray}
                    columns={columns}/>

                <AddUserModal
                    visible={this.state.addUserModalIsOpen}
                    handleOk={this.handleOk}
                    handleCancel={this.handleCancel}
                    addUserAll={this.addUserAll}
                    addUserCSV={this.addUserCSV}
                />


                <UserViewModal
                    visible={this.state.viewUserModalIsOpen}
                    handleCancel={() => {
                        this.setState({
                            viewUserModalIsOpen: false
                        })
                    }}
                    handleOk={(fieldValue, data) => {
                        this.setState({
                            viewUserModalIsOpen: false
                        })
                    }}
                    uuid={this.state.viewModalUUID}
                />

            </div>
        );
    }
}


export default connect(
    mapStateToProps,
    mapDispatchToProps
)(SelectTargetFormUsers)
