import React from 'react'
import PropTypes from 'prop-types';
import { EnvironmentOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Modal, Radio, Input, Col, Select, Tooltip } from 'antd';

import log from 'components/utils/Logger.js';

import CidrCalculator from 'subnet-cidr-calculator';

import moment from 'moment';
import 'moment/locale/ja';

moment.locale('ja')

const {TextArea} = Input;
const FormItem = Form.Item;


class AddIPModal extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            visible: props.visible,

            value1octet: "0",
            value2octet: "0",
            value3octet: "0",
            value4octet: "0",
            valueSubnet: "24",
            cidrRangeString: "未入力",
            disabledNetworkRange: false,
            networkData: ""
        }
    }

    componentWillReceiveProps(nextProps) {

        this.setState({
            visible: nextProps.visible,
        })

        if(nextProps.visible !== this.props.visible){
            log.debug(nextProps.networkData);
           this.setState({
               networkData: nextProps.networkData
           })
        }

        if(!nextProps.visible){
            this.setState({
                networkData: ""
            })
        }
    }

    handleOk = () => {
        setTimeout(() => {
            this.setState({
                confirmLoading: false,
            });
        }, 2000);
        log.debug(this.state.networkData)
        this.props.handleOk(this.state.networkData);
    }

    handleCancel = () => {
        this.props.handleCancel();
    }


    render() {

        const {visible, confirmLoading} = this.state;


        return (
            <Modal
                className="common-modal select-target-form"
                title={<span><EnvironmentOutlined /> 許可対象ネットワークの変更</span>}
                visible={visible}
                onOk={this.handleOk}
                maskClosable={false}
                confirmLoading={confirmLoading}
                onCancel={this.handleCancel}>

                <div className='wrapper'>
                    <FormItem>
                            <TextArea
                                autosize
                                value={this.state.networkData}
                                onChange={(value) => {
                                    log.debug(value.target.value);
                                    this.setState({
                                        networkData: value.target.value
                                    })
                                }
                            }/>
                    </FormItem>

                    {/*
                    <FormItem{...formItemLayout}
                             label={
                                 <span>
                                     ネットワーク名称&nbsp;&nbsp;
                                    <Tooltip title="ネットワークに対するわかりやすい名称を入力してください。">
                                        <Icon type="question-circle-o" />
                                    </Tooltip>
                                 </span>
                             }
                             layout="inline">
                        {getFieldDecorator('networkName')(
                            <Input placeholder="ネットワーク名称を入力してください" />
                        )}
                    </FormItem>

                    <FormItem{...formItemLayout}
                             label={
                                 <span>
                                     指定形式&nbsp;&nbsp;
                                     <Tooltip title={
                                         <span>
                                             ネットワークを指定する方式を選択してください。<br />
                                             CIDR：ネットワークを範囲で指定<br />
                                             IPアドレス：単独のIPアドレスを指定
                                         </span>}>
                                        <Icon type="question-circle-o" />
                                    </Tooltip>
                                 </span>
                             }>
                        {getFieldDecorator('networkBlock', {
                                initialValue: "network"
                            }
                        )(
                            <RadioGroup onChange={this.onChangeRadio}>
                                <Radio value="network">CIDR（サブネット）</Radio>
                                <Radio value="ip">IPアドレス</Radio>
                            </RadioGroup>
                        )}
                    </FormItem>

                    <FormItem
                        {...formItemLayout}
                        label={
                            <span>
                                IPアドレス&nbsp;&nbsp;
                                <Tooltip title={<span>対象のネットワークIPアドレスを入力し、ネットワーク範囲を選択してください。</span>}>
                                    <Icon type="question-circle-o" />
                                    </Tooltip>
                                 </span>
                        }
                        layout="inline">
                        <Col span={3}>
                            <FormItem>
                                {getFieldDecorator('octet1', {
                                    rules: [{required: true,validator: this.handleCheckNum}],
                                    initialValue : {number:0}
                                })(
                                    <NumberInput/>
                                )}
                            </FormItem>
                        </Col>
                        <Col span={1}>
                            <span style={{display: 'inline-block', width: '100%', textAlign: 'center'}}>
                              .
                            </span>
                        </Col>
                        <Col span={3}>
                            <FormItem>
                                {getFieldDecorator('octet2', {
                                    rules: [{required: true,validator: this.handleCheckNum}],
                                    initialValue : {number:0}
                                })(
                                    <NumberInput/>
                                )}
                            </FormItem>
                        </Col>
                        <Col span={1}>
                            <span style={{display: 'inline-block', width: '100%', textAlign: 'center'}}>
                              .
                            </span>
                        </Col>
                        <Col span={3}>
                            <FormItem>
                                {getFieldDecorator('octet3', {
                                    rules: [{required: true,validator: this.handleCheckNum}],
                                    initialValue : {number:0}
                                })(

                                    <NumberInput/>
                                )}
                            </FormItem>
                        </Col>
                        <Col span={1}>
                            <span style={{display: 'inline-block', width: '100%', textAlign: 'center'}}>
                              .
                            </span>
                        </Col>
                        <Col span={3}>
                            <FormItem>
                                {getFieldDecorator('octet4', {
                                    rules: [{required: true,validator: this.handleCheckNum}],
                                    initialValue : {number:0}
                                })(
                                    <NumberInput/>
                                )}
                            </FormItem>
                        </Col>
                        <Col span={1}>
                            <span style={{display: 'inline-block', width: '100%', textAlign: 'center'}}>

                            </span>
                        </Col>
                        <Col span={6}>
                            <FormItem>
                            {getFieldDecorator('valueSubnet', {
                                rules: [{required: true}],
                                initialValue: this.state.valueSubnet
                            })(
                                <Select style={{ width: 160 }} disabled={this.state.disabledNetworkRange} onChange={this.handleCheckNetwork}>
                                    <Option value="1">/1 (128.0.0.0)</Option>
                                    <Option value="2">/2 (192.0.0.0)</Option>
                                    <Option value="3">/3 (224.0.0.0)</Option>
                                    <Option value="4">/4 (240.0.0.0)</Option>
                                    <Option value="5">/5 (248.0.0.0)</Option>
                                    <Option value="6">/6 (252.0.0.0)</Option>
                                    <Option value="7">/7 (254.0.0.0)</Option>
                                    <Option value="8">/8 (255.0.0.0)</Option>
                                    <Option value="9">/9 (255.128.0.0)</Option>
                                    <Option value="10">/10 (255.192.0.0)</Option>
                                    <Option value="11">/11 (255.224.0.0)</Option>
                                    <Option value="12">/12 (255.240.0.0)</Option>
                                    <Option value="13">/13 (255.248.0.0)</Option>
                                    <Option value="14">/14 (255.252.0.0)</Option>
                                    <Option value="15">/15 (255.254.0.0)</Option>
                                    <Option value="16">/16 (255.255.0.0)</Option>
                                    <Option value="17">/17 (255.255.128.0)</Option>
                                    <Option value="18">/18 (255.255.192.0)</Option>
                                    <Option value="19">/19 (255.255.224.0)</Option>
                                    <Option value="20">/20 (255.255.240.0)</Option>
                                    <Option value="21">/21 (255.255.248.0)</Option>
                                    <Option value="22">/22 (255.255.252.0)</Option>
                                    <Option value="23">/23 (255.255.254.0)</Option>
                                    <Option value="24">/24 (255.255.255.0)</Option>
                                    <Option value="25">/25 (255.255.255.128)</Option>
                                    <Option value="26">/26 (255.255.255.192)</Option>
                                    <Option value="27">/27 (255.255.255.224)</Option>
                                    <Option value="28">/28 (255.255.255.240)</Option>
                                    <Option value="29">/29 (255.255.255.248)</Option>
                                    <Option value="30">/30 (255.255.255.252)</Option>
                                    <Option value="31">/31 (255.255.255.254)</Option>
                                    <Option value="32">/32 (255.255.255.255)</Option>
                                </Select>
                            )}
                            </FormItem>
                        </Col>
                    </FormItem>

                    <FormItem{...formItemLayout}
                             label="ネットワーク範囲"
                             layout="inline">
                        <span>{this.state.cidrRangeString}</span>
                    </FormItem>
                    */}


                </div>

            </Modal>
        );
    }
}



export default Form.create()(AddIPModal)

AddIPModal.propTypes = {
    visible: PropTypes.bool,
    handleOk: PropTypes.func.isRequired,
    handleCancel: PropTypes.func.isRequired,
};