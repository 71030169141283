import React from 'react'
import {connect, store} from 'react-redux'
import PropTypes from 'prop-types';
import {instanceOf} from 'prop-types';
import * as Actions from 'redux/actions'
import log from 'components/utils/Logger.js'
import * as TagNodeGenerator from 'components/utils/TagNodeGenerator'
import moment from 'moment';
import 'moment/locale/ja';
import { ArrowRightOutlined, DownloadOutlined } from '@ant-design/icons';
import { Modal, Button, Table, Tooltip, Spin } from 'antd';
import renderHTML from 'react-render-html';
import FroalaEditorView from 'react-froala-wysiwyg/FroalaEditorView';

moment.locale('ja');

const mapStateToProps = (state, props) => {
    return {
        screen: state.screen,
        paramCategory: state.paramNotificationCategory
    }
};

const mapDispatchToProps = dispatch => {
    return {
    }
};

class NotificationPreviewModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: undefined,
            uuid: props.uuid,
            modalTitle: props.title,
            visible: props.visible,
        };
    }

    componentDidMount() {

    }

    componentWillReceiveProps(nextProps) {
        this.setState({
            modalTitle: nextProps.title,
            visible: nextProps.visible,
            data: nextProps.data
        });
    }

    handleOk = () => {
        this.props.handleOk();
    };

    handleCancel = () => {
        this.setState({
            data: undefined
        },()=>{
            this.props.handleCancel();
        })
    };


    render() {
        const {visible, confirmLoading, data} = this.state;
        let titleNode = (
            <div className="module-notification-single-title">
                <div className="title">　</div>
            </div>
        );
        const columns = [{
            title: '添付ファイル',
            dataIndex: "file_name",
            key: "file_name"
        }, {
            dataIndex: 'attachment',
            key: 'attachment',
            width: "50px",
            render: (text, record) => {

                return (
                    <Tooltip placement="topLeft" title="ファイルダウンロード">
                        <Button type="default" shape="circle" icon={<DownloadOutlined />}
                                onClick={() => window.open(`${process.env.REACT_APP_FILE_DOMAIN}/file/view/${record.uuid}`, '_blank')}/>
                    </Tooltip>
                );
            }
        }];

        if (typeof data !== "undefined") {
            const tagNode = TagNodeGenerator.generate(TagNodeGenerator.TYPES_NOTIFICATION_PRIORITY, data.priority);
            const publish_start = moment.unix(data.publish_start_at).format("YYYY.MM.DD HH:mm");
            const publish_end = moment.unix(data.publish_end_at).format("YYYY.MM.DD HH:mm");

            let publish_lastmod;
            if (typeof data.created_at === "undefined") {
                publish_lastmod = "プレビュー記事";
            } else {
                publish_lastmod = !!data.updated_at ? moment.unix(data.updated_at).format("最終更新 YYYY.MM.DD HH:mm") : moment.unix(data.created_at).format("最終更新 YYYY.MM.DD HH:mm");
            }

            let category;
            //previewの際はcategory情報を持っていないのでparamCategoryから検索
            if (data.category) {
                category = data.category.name;
            } else {
                this.props.paramCategory.payload.result.items.map((value, index) => {
                    if (value.uuid === data.category_uuid) {
                        category = value.name;
                    }
                })
            }

            titleNode = (
                <div className="module-notification-single-title">
                    <div className="title">{tagNode}<span>{data.title}</span></div>
                </div>
            );

            return (
                <Modal
                    className="common-modal module-notification-single"
                    style={{top: 20}}
                    title={titleNode}
                    visible={visible}
                    maskClosable={false}
                    onOk={this.handleOk}
                    onCancel={this.handleCancel}
                    footer={[
                        <Button key="back" size="large" onClick={this.handleCancel}>閉じる</Button>
                    ]}
                >
                    <div className="meta">
                        <div className="date">公開期間 : {publish_start} <ArrowRightOutlined /> {publish_end}</div>
                        <div className="category">カテゴリ : {category}</div>
                        <div className="publish-from">配信元 : {data.publish_from}</div>
                    </div>
                    <div className='wrapper'>
                        {data.images?.length > 0 &&
                            <img src={`${process.env.REACT_APP_FILE_DOMAIN}/file/view/${data.images[0].uuid}`}
                                 style={{marginBottom: 5}} />
                        }
                        <div className="notification-preview">
                            <FroalaEditorView model={data.content}/>
                        </div>

                        <div className="last-modified">
                            {publish_lastmod}
                        </div>

                        {this.props.data && this.props.data.files !== null ?
                            <div className="notification-preview-download">
                                <Table
                                    className="general-table"
                                    locale={{
                                        filterTitle: 'フィルタ',
                                        filterConfirm: '確定',
                                        filterReset: 'リセット',
                                        emptyText: '該当するものはありません',
                                    }}
                                    bordered={true}
                                    pagination={false}
                                    columns={columns} dataSource={this.props.data.files}
                                    size="middle"
                                />
                            </div> :
                            null}
                    </div>
                </Modal>
            );
        } else {
            return (
                <Modal
                    className="common-modal module-notification-single"
                    style={{top: 20}}
                    title={titleNode}
                    visible={visible}
                    maskClosable={false}
                    onOk={this.handleOk}
                    confirmLoading={confirmLoading}
                    onCancel={this.handleCancel}
                    footer={[
                        <Button key="back" size="large" onClick={this.handleCancel}>閉じる</Button>
                    ]}>
                    <Spin spinning={true}>
                        <div className='wrapper' style={{height: 200}} />
                    </Spin>
                </Modal>
            );
        }
    }
}


export default connect(
    mapStateToProps,
    mapDispatchToProps
)(NotificationPreviewModal)
