import React, { Component } from 'react'
import { InfoCircleOutlined } from '@ant-design/icons';
import { Button } from 'antd';

class ComingSoon extends Component {
    render() {
        return (
            <div id="coming-soon">
                <div className="explain-box">
                    <div className="explain-box-left">
                        <InfoCircleOutlined className="infomation-icon" />
                    </div>
                    <div className="explain_box-right">
                        <div>
                        <h2>ComingSoon...</h2>
                        <p>この機能は現在利用できません。<br />後日アップデートにて提供されます。</p>
                        </div>
                    </div>
                </div>

            </div>
        );
    }
}

export default ComingSoon;
