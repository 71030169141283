import React from 'react';
import {connect, store} from 'react-redux'
import PropTypes from 'prop-types';
import {
    InboxOutlined,
    NotificationOutlined,
    QuestionCircleOutlined,
    StarOutlined,
    UserOutlined
} from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import {
    Modal,
    Tabs,
    Radio,
    Select,
    Input,
    Tooltip,
    Row,
    Col,
    Button,
    DatePicker,
    Collapse,
    Upload, AutoComplete, Popconfirm,
} from 'antd';

import log from 'components/utils/Logger.js'
import * as Actions from 'redux/actions'
import * as GlobalConfig from '../../constants/GlobalConfig'
import moment from 'moment';
import 'moment/locale/ja';
import jaJP from 'antd/lib/date-picker/locale/ja_JP';
import SelectTargetForm from "containers/common/SelectTargetForm";
import SelectPermissionForm from "containers/common/SelectPermissionForm";
import ModuleNotificationModalSingle from 'containers/services/modules/notification/ModuleNotificationModalSingle'


// Require Editor JS files.
import 'froala-editor/js/froala_editor.pkgd.min.js';
import "froala-editor/js/languages/ja.js";

// Require Editor CSS files.
import 'froala-editor/css/froala_style.min.css';
import 'froala-editor/css/froala_editor.pkgd.min.css';

// Require Font Awesome.
import 'font-awesome/css/font-awesome.css';

// Require Editor JS files.
import "froala-editor/js/plugins/colors.min.js"
import "froala-editor/js/plugins/align.min.js"
import "froala-editor/js/plugins/font_size.min.js"
import "froala-editor/js/plugins/font_family.min.js"
import "froala-editor/js/plugins/char_counter.min.js"
import "froala-editor/js/plugins/code_beautifier.min.js"
import "froala-editor/js/plugins/code_view.min.js"
import "froala-editor/js/plugins/link.min.js"
import "codemirror/mode/htmlmixed/htmlmixed.js";
// Require Editor CSS files.
import "froala-editor/css/plugins/colors.min.css"
import 'froala-editor/css/froala_style.min.css';
import 'froala-editor/css/froala_editor.pkgd.min.css';
import 'froala-editor/css/themes/gray.min.css';
import "froala-editor/css/plugins/char_counter.min.css"
import "froala-editor/css/plugins/code_view.min.css"
import 'codemirror/lib/codemirror.css';


import FroalaEditor from 'react-froala-wysiwyg';
import NotificationPreviewModal from "./NotificationPreviewModal";
import MyTargetList from "../common/MyTargetList";
import MyTargetModal from "../common/MyTargetModal";


moment.locale('ja')
const TabPane = Tabs.TabPane;
const FormItem = Form.Item;
const Panel = Collapse.Panel;
const Option = Select.Option;
const Option2 = Select.Option;
const RadioButton = Radio.Button;
const RadioGroup = Radio.Group;
const RangePicker = DatePicker.RangePicker;


const mapStateToProps = (state, props) => {
    return {
        images: state.images,
        session: state.session,
        thumbnail: state.thumbnail
    }
};

const mapDispatchToProps = dispatch => {
    return {
        uploadImages: (fileName, contentType, data) => {
            dispatch(Actions.Connection.images.request(fileName, contentType, data));
        },
        uploadThumbnail: (fileName, contentType,data) => {
            dispatch(Actions.Connection.thumbnail.request(fileName,contentType,data));
        }
    }
};


class NotificationAddModal extends React.Component {
    constructor(props) {
        super(props);
        const modalTitle = props.isEditMode ? "お知らせの編集" : "お知らせの新規作成";
        const title = "";
        const category_uuid = undefined;
        const content = "";
        const publish_from = "";
        const publish_start_at = "";
        const publish_end_at = "";
        const priority = 1;
        const is_send_notification = false;
        const is_active = false;
        const range_picker = undefined;
        const fileList = [];
        const thumbnailList = [];
        const target_uuid = props.data.target_uuid;
        const permission_uuid = props.data.permission_uuid;

        this.state = {
            title,
            category_uuid,
            target_uuid,
            permission_uuid,
            content,
            publish_from,
            priority,
            is_send_notification,
            is_active,
            range_picker,
            fileList,
            thumbnailList,
            paramCategoryArray: props.paramCategoryArray,
            modalTitle: modalTitle,
            visible: props.visible,
            data: props.data,
            preFileList: [],
            myTargetModalIsOpen: false,
            selectTargetFormModalIsOpen: false,
            selectPermissionFormModalIsOpen: false,
            previewModalIsOpen: false,
            previewData: undefined,
            groupsArray:[],
            contentValid: "",
            contentValidMessage: "コンテンツ内容を入力してください"
        }
    }

    componentWillReceiveProps(nextProps) {
        const {fileList,thumbnailList} = this.state;
        //default
        this.setState({
            visible: nextProps.visible,
            paramCategoryArray: nextProps.paramCategoryArray,
        });

        // 所属部署リスト取得
        if(nextProps.session.payload.hasOwnProperty("user") && nextProps.session.payload.user.hasOwnProperty("groups") && nextProps.session.payload.user.groups ) {
            console.log(nextProps.session.payload.user.groups)
            this.setState({
                groupsArray: nextProps.session.payload.user.groups
            });
        }

        //data更新時差分
        if (nextProps.visible !== this.props.visible && !this.props.visible && nextProps.isEditMode) {
            const modalTitle = nextProps.isEditMode ? "お知らせの編集" : "お知らせの新規作成";

            const title = nextProps.data ? nextProps.data.title : "";
            const category_uuid = nextProps.data ? nextProps.data.category.uuid : "";
            const uuid = nextProps.data ? nextProps.data.uuid : "";
            const target_uuid = nextProps.data ? nextProps.data.target_uuid : "";
            const permission_uuid = nextProps.data ? nextProps.data.permission_uuid : "";
            const content = nextProps.data ? nextProps.data.content : "";
            const publish_from = nextProps.data ? nextProps.data.publish_from : "";
            const publish_start_at = nextProps.data ? nextProps.data.publish_start_at : "";
            const publish_end_at = nextProps.data ? nextProps.data.publish_end_at : "";
            const priority = nextProps.data ? nextProps.data.priority : 1;
            const is_send_notification = nextProps.data ? nextProps.data.is_send_notification : false;
            const is_active = nextProps.data ? nextProps.data.is_active : false;
            const range_picker = nextProps.data ? [moment.unix(publish_start_at), moment.unix(publish_end_at)] : undefined;
            const fileList = nextProps.data.files ? nextProps.data.files.map((value, index) => {
                log.debug(value);
                let constObject = value;
                constObject.name = constObject.file_name;
                constObject.uid = Math.floor(Math.random() * 10000);
                constObject.status = 'done';
                constObject.url = `${process.env.REACT_APP_FILE_DOMAIN}/file/view/${constObject.uuid}`;
                if(constObject.hasOwnProperty("mime_type") && constObject.mime_type.match("image")){
                    constObject.thumbUrl = `${process.env.REACT_APP_FILE_DOMAIN}/file/view/${constObject.uuid}`;
                }else{
                    constObject.thumbUrl = process.env.PUBLIC_URL + '/static/media/attach.svg';
                }
                return constObject
            }) : [];
            const thumbnailList = nextProps.data.images ? nextProps.data.images.map((value, index) => {
                let constObject = value;
                constObject.name = constObject.file_name;
                constObject.uid = Math.floor(Math.random() * 10000);
                constObject.status = 'done';
                constObject.url = `${process.env.REACT_APP_FILE_DOMAIN}/file/view/${constObject.uuid}`;
                if(constObject.hasOwnProperty("mime_type") && constObject.mime_type.match("image")){
                    constObject.thumbUrl = `${process.env.REACT_APP_FILE_DOMAIN}/file/view/${constObject.uuid}`;
                }else{
                    constObject.thumbUrl = process.env.PUBLIC_URL + '/static/media/attach.svg';
                }
                return constObject
            }) : [];
            this.setState({
                title,
                category_uuid,
                content,
                publish_from,
                priority,
                is_send_notification,
                is_active,
                range_picker,
                permission_uuid,
                target_uuid,
                uuid,
                fileList,
                thumbnailList
            })
        }
        //imagesアップデート差分
        if (nextProps.images !== this.props.images) {
            if (!nextProps.images.meta.fetch) {
                if (!nextProps.images.error) {
                    if (nextProps.images.payload !== null && nextProps.images.payload.hasOwnProperty("result")) {
                        //画像のアップロード
                        const map = fileList.some((value) => {
                            return value === nextProps.images.payload.result;
                        })
                        //OPTIMIZE fileListが単体ではない可能性
                        if (!map) {
                            let constObject = nextProps.images.payload.result;
                            constObject.name = constObject.file_name;
                            constObject.uid = Math.floor(Math.random() * 10000);
                            constObject.response = nextProps.images.payload;
                            constObject.status = 'done';
                            constObject.url = `${process.env.REACT_APP_FILE_DOMAIN}/file/view/${constObject.uuid}`;
                            if(constObject.hasOwnProperty("mime_type") && constObject.mime_type.match("image")){
                                constObject.thumbUrl = `${process.env.REACT_APP_FILE_DOMAIN}/file/view/${constObject.uuid}`;
                            }else{
                                constObject.thumbUrl = process.env.PUBLIC_URL + '/static/media/attach.svg';
                            }
                            let constArray = fileList.concat();
                            constArray[constArray.length] = constObject;
                            this.setState({
                                fileList: constArray
                            })
                        }
                    }
                }
            } else {
                this.setState({
                    loading: nextProps.images.meta.fetch
                })
            }
        }
        //thumbnail
        if (nextProps.thumbnail !== this.props.thumbnail) {
            if (!nextProps.thumbnail.meta.fetch) {
                if (!nextProps.thumbnail.error) {
                    if (nextProps.thumbnail.payload !== null && nextProps.thumbnail.payload.hasOwnProperty("result")) {
                        //画像のアップロード
                        const map = thumbnailList.some((value) => {
                            return value === nextProps.thumbnail.payload.result;
                        })
                        //OPTIMIZE thumbnailListが単体ではない可能性
                        if (!map) {
                            let constObject = nextProps.thumbnail.payload.result;
                            constObject.name = constObject.file_name;
                            constObject.uid = Math.floor(Math.random() * 10000);
                            constObject.response = nextProps.thumbnail.payload;
                            constObject.status = 'done';
                            constObject.url = `${process.env.REACT_APP_FILE_DOMAIN}/file/view/${constObject.uuid}`;
                            if(constObject.hasOwnProperty("mime_type") && constObject.mime_type.match("image")){
                                constObject.thumbUrl = `${process.env.REACT_APP_FILE_DOMAIN}/file/view/${constObject.uuid}`;
                            }else{
                                constObject.thumbUrl = process.env.PUBLIC_URL + '/static/media/attach.svg';
                            }
                            console.log(constObject);
                            this.setState({
                                thumbnailList: [constObject]
                            })
                        }
                    }
                }
            } else {
                this.setState({
                    loading: nextProps.thumbnail.meta.fetch
                })
            }
        }
        if (!nextProps.visible) {
            this.resetFormAll();
        }
    }

    componentDidMount() {

    }

    componentDidUpdate() {
        //update時に必要ない要素をすべて削除する
        let richEditorElements;
        if (this.props.isEditMode) {
            if (document.getElementById("edit-notification-form")) {
                richEditorElements = document.getElementById("edit-notification-form").getElementsByClassName("RichEditor-controls");
            }
        } else {
            if (document.getElementById("add-notification-form")) {
                richEditorElements = document.getElementById("add-notification-form").getElementsByClassName("RichEditor-controls");
            }
        }
        if (richEditorElements && richEditorElements.length !== 0) {
            Array.prototype.forEach.call(richEditorElements[0].children, (span, index) => {
                if ([1, 2, 3, 4, 5, 8].indexOf(index + 1) !== -1) {
                    span.style.display = 'none';
                }
            })
        }
    }


    resetFormAll = () => {
        this.props.form.resetFields();
        this.setState({
            content: "",
            selectedCategoryValue: undefined,
            fileList: [],
            confirmLoading: false,
            permission_uuid: "",
            target_uuid: ""
        })
    };

    handleOk = () => {
        //バリデーション
        this.props.form.setFieldsValue({
            content: this.state.content
        });

        this.props.form.validateFields((err, values) => {
            if (!err) {

                const fieldsValue = this.props.form.getFieldsValue();

                const range_picker = fieldsValue.range_picker;
                if (typeof range_picker !== 'undefined') {
                    fieldsValue.publish_start_at = range_picker[0].unix();
                    fieldsValue.publish_end_at = range_picker[1].unix();
                    delete fieldsValue.range_picker;
                }
                delete fieldsValue.push;

                if (this.state.fileList.length !== 0) {
                    const fileUuidArray = this.state.fileList.map((value, index) => {
                        return value.uuid;
                    });
                    fieldsValue.files = fileUuidArray;
                }
                if (this.state.thumbnailList.length !== 0) {
                    const thumbnailUuidArray = this.state.thumbnailList.map((value, index) => {
                        return value.uuid;
                    });
                    fieldsValue.images = thumbnailUuidArray;
                }
                //is_activeフラグは常にtrue
                fieldsValue.is_active = true;
                //エラーじゃない時
                this.setState({
                    confirmLoading: true,
                });
                this.props.handleOk(fieldsValue, this.props.data);
            }
        });
    };

    handleOpenPreview = () => {

        this.props.form.setFieldsValue({
            content: this.state.content
        });

        this.props.form.validateFields((err, values) => {
            if (!err) {
                const fieldsValue = this.props.form.getFieldsValue();

                const range_picker = fieldsValue.range_picker;
                if (typeof range_picker !== 'undefined') {
                    fieldsValue.publish_start_at = range_picker[0].unix();
                    fieldsValue.publish_end_at = range_picker[1].unix();
                    delete fieldsValue.range_picker;
                }
                delete fieldsValue.push;
                if (this.state.fileList.length !== 0) {
                    fieldsValue.files = this.state.fileList;
                }

                if (this.state.thumbnailList.length !== 0) {
                    const thumbnailUuidArray = this.state.thumbnailList.map((value, index) => {
                        return value.uuid;
                    });
                    fieldsValue.images = thumbnailUuidArray;
                }
                this.setState({
                    previewModalIsOpen: true,
                    previewData: fieldsValue
                })
            }
        });
    };


    handleCancel = () => {
        this.props.handleCancel(this.state.uuid);
    };


    receiveHtml = (content) => {
        this.props.form.setFieldsValue({
            content: content
        });
    };

    handleModelChange = (model) => {
        this.setState({
            content: model
        })
    };



    handleUpload = (file) => {
        const {fileList} = this.state;
        const fileReader = new FileReader();
        fileReader.onload = (event) => {
            this.props.uploadImages(file.name, file.type, event.target.result);
        }
        this.setState({
            uploading: true,
        });
        fileReader.readAsArrayBuffer(file);
    };

    handleUploadThumbnail = (file) => {
        const {thumbnailList} = this.state;
        const fileReader = new FileReader();
        fileReader.onload = (event) => {
            this.props.uploadThumbnail(file.name, file.type, event.target.result);
        }
        this.setState({
            uploading: true,
        });
        fileReader.readAsArrayBuffer(file);

    }


    handleSelectTarget = (data, type) => {
        log.debug(data);
        if (type === "target") {
            this.props.form.setFieldsValue({
                target_uuid: data.uuid
            })
            this.setState({
                selectTargetFormModalIsOpen: false,
                target_uuid: data.uuid
            })
        } else if (type === "permission") {
            this.props.form.setFieldsValue({
                permission_uuid: data.uuid
            })
            this.setState({
                selectPermissionFormModalIsOpen: false,
                permission_uuid: data.uuid
            })
        }

    }

    render() {

        const {visible, confirmLoading} = this.state;

        const formItemLayout = {
            labelCol: {
                xs: {span: 24},
                sm: {span: 4},
            },
            wrapperCol: {
                xs: {span: 24},
                sm: {span: 20},
            },
        };

        const {getFieldDecorator} = this.props.form;
        const id = this.props.isEditMode ? "edit-notification-form" : "add-notification-form";

        const categoryOptions = this.state.paramCategoryArray.map((value, index) => {
            return (
                <Option key={value.uuid} value={value.uuid}>
                    <div className="select-title">{value.name}</div>
                    <div className="select-description">{value.description}</div>
                </Option>
            )
        });

        const myGroupOptions = this.state.groupsArray.map((value,index) => {
            // is_authority がTrueのグループのみ表示させる
            if(value.is_authority == true) {
                return (
                    <Option2 key={value.uuid} value={value.name} >
                        <div className="select-title">{value.name}</div>
                        <div className="select-description">{value.full_name}</div>
                    </Option2>
                );
            }
        });

        const attachementFile = {
            onRemove: (file) => {
                this.setState(({fileList}) => {
                    const index = fileList.indexOf(file);
                    const newFileList = fileList.slice();
                    newFileList.splice(index, 1);
                    return {
                        fileList: newFileList,
                    };
                });
            },
            beforeUpload: (file) => {
                // this.setState(({ fileList }) => ({
                //     fileList: [...fileList, file],
                // }));
                this.handleUpload(file);
                return false;
            },
            handleChange: this.handleChange,
            fileList: this.state.fileList,
            listType: 'picture',
        };

        const thumbnailImage = {
            onRemove: (file) => {
                this.setState(({thumbnailList}) => {
                    const index = thumbnailList.indexOf(file);
                    const newThumbnailList = thumbnailList.slice();
                    newThumbnailList.splice(index, 1);
                    return {
                        thumbnailList: newThumbnailList,
                    };
                });
            },
            beforeUpload: (file) => {
                this.handleUploadThumbnail(file);
                return false;
            },
            handleChange: this.handleChange,
            fileList: this.state.thumbnailList,
            listType: 'picture',
        }



        return (
            <Modal
                className="common-modal"
                style={{top: 20}}
                title={<span><NotificationOutlined /> {this.state.modalTitle}</span>}
                visible={visible}
                maskClosable={false}
                onOk={this.handleOk}
                okText={this.props.isEditMode ? "上書き保存" : "作成"}
                cancelText="保存せずに閉じる"
                confirmLoading={confirmLoading}
                onCancel={this.handleCancel}
                footer={[
                    <Button key="back-nad" size="large" onClick={this.handleCancel}>保存せずに閉じる</Button>,
                    <Button key="preview-nad" size="large" onClick={this.handleOpenPreview}>プレビュー</Button>,
                    <Button key="create-nad" type={"primary"} size="large"
                            onClick={this.handleOk}>{this.props.isCopy ? "コピーして作成" : this.props.isEditMode ? "上書き保存" : "作成"}</Button>
                ]}
            >

                <div className='wrapper' id={id} ref={(div) => {

                    this.wrapperElement = div;
                }}>
                    <Form className="search-form" onSubmit={this.onSubmit}>
                        <FormItem
                            {...formItemLayout}
                            label={
                                <span>
                                     配信カテゴリ&nbsp;&nbsp;
                                    <Tooltip title={GlobalConfig.FormHelpTextOverride.iconText.NotificationCategory ?
                                        GlobalConfig.FormHelpTextOverride.iconText.NotificationCategory
                                        : "配信先のカテゴリを選択してください"}>
                                        <QuestionCircleOutlined />
                                    </Tooltip>
                                 </span>
                            }
                            extra={GlobalConfig.FormHelpTextOverride.bottomText.NotificationCategory ?
                                GlobalConfig.FormHelpTextOverride.bottomText.NotificationCategory
                                : ""}
                        >
                            {getFieldDecorator('category_uuid', {
                                initialValue: this.state.category_uuid,
                                rules: [{
                                    required: true,
                                    message: '配信カテゴリを選択してください',
                                }],
                            })(
                                <Select
                                    placeholder={"配信カテゴリを選択してください"}
                                    allowClear={true}>
                                    {categoryOptions}
                                </Select>
                            )}
                        </FormItem>

                        <FormItem
                            {...formItemLayout}
                            label={
                                <span>
                                     配信元&nbsp;&nbsp;
                                    <Tooltip title={GlobalConfig.FormHelpTextOverride.iconText.NotificationPublishFrom ?
                                        GlobalConfig.FormHelpTextOverride.iconText.NotificationPublishFrom
                                        : "配信元は受信者のお知らせ確認画面に表示されます。"}>
                                        <QuestionCircleOutlined />
                                    </Tooltip>
                                 </span>
                            }
                            extra={GlobalConfig.FormHelpTextOverride.bottomText.NotificationPublishFrom ?
                                GlobalConfig.FormHelpTextOverride.bottomText.NotificationPublishFrom
                                : ""}
                        >
                            <Row>
                                {getFieldDecorator('publish_from', {
                                    initialValue: this.state.publish_from,
                                    rules: [{
                                        required: true,
                                        message: '配信元を入力してください',
                                    }],
                                })(
                                    <AutoComplete
                                        placeholder={"配信元を入力してください"}>
                                        {myGroupOptions}
                                    </AutoComplete>
                                )}
                            </Row>
                        </FormItem>


                        <FormItem
                            {...formItemLayout}
                            label={
                                <span>
                                     タイトル&nbsp;&nbsp;
                                    <Tooltip title={GlobalConfig.FormHelpTextOverride.iconText.NotificationTitle ?
                                        GlobalConfig.FormHelpTextOverride.iconText.NotificationTitle
                                        : "お知らせタイトルを入力してください。"}>
                                        <QuestionCircleOutlined />
                                    </Tooltip>
                                 </span>
                            }
                            layout="inline"
                            extra={GlobalConfig.FormHelpTextOverride.bottomText.NotificationTitle ?
                                GlobalConfig.FormHelpTextOverride.bottomText.NotificationTitle
                                : ""}>
                            {getFieldDecorator('title', {
                                initialValue: this.state.title,
                                rules: [{
                                    required: true,
                                    message: GlobalConfig.Validation.NotificationTitleMessage ? GlobalConfig.Validation.NotificationTitleMessage: "",
                                }],
                            })(
                                <Input placeholder="タイトルを入力してください"/>
                            )}
                        </FormItem>

                        <FormItem
                            {...formItemLayout}
                            label={
                                <span>
                                     公開期間&nbsp;&nbsp;
                                    <Tooltip title={GlobalConfig.FormHelpTextOverride.iconText.NotificationPublish ?
                                        GlobalConfig.FormHelpTextOverride.iconText.NotificationPublish
                                        : "お知らせを公開する期間を選択してください。プッシュ配信は公開期間になるまで配信されません。"}>
                                        <QuestionCircleOutlined />
                                    </Tooltip>
                                 </span>
                            }
                            extra={GlobalConfig.FormHelpTextOverride.bottomText.NotificationPublish ?
                                GlobalConfig.FormHelpTextOverride.bottomText.NotificationPublish
                                : ""}
                            >
                            {getFieldDecorator('range_picker', {
                                initialValue: this.state.range_picker,
                                rules: [{type: 'array', message: '公開期間を選択してください', required: true}]
                            })(
                                <RangePicker
                                    locale={jaJP}
                                    showTime={{format: 'HH:mm'}}
                                    format="YYYY/MM/DD HH:mm"
                                    ranges={GlobalConfig.DatePickerRanges()}/>
                            )}
                        </FormItem>

                        <FormItem
                            {...formItemLayout}
                            label="内容">
                            {getFieldDecorator('content', {
                                initialValue: this.state.content,
                                rules: [{
                                    required: true,
                                    message: '内容を入力してください',
                                }],
                            })(
                                <Row>
                                    <Col span={24}>
                                        <FroalaEditor
                                            tag='textarea'
                                            config={{
                                                placeholderText: '内容を入力してください',
                                                language: 'ja',
                                                theme: 'knowledge-portal',
                                                heightMin: 200,
                                                colorsText:
                                                    [
                                                        '#dc0000', '#004da0', '#f8b500', '#009d62', '#ec6c00', '#ffffff', '#314b57', 'REMOVE'
                                                    ],
                                                colorsBackground: [
                                                    '#dc0000', '#004da0', '#f8b500', '#009d62', '#ec6c00', '#ffffff', '#314b57', 'REMOVE'
                                                ],
                                                colorsHEXInput: false,
                                                toolbarButtons: {
                                                    'moreText': {
                                                        'buttons': ['bold', 'italic', 'underline', 'textColor', 'backgroundColor'],
                                                        'buttonsVisible': 5
                                                    },
                                                    'moreParagraph': {
                                                        'buttons': [ '|','alignLeft', 'alignCenter', 'alignRight', '|', 'insertHR', 'insertLink', 'clearFormatting', '|'],
                                                        'buttonsVisible': 7
                                                    },
                                                    'moreMisc': {
                                                        'buttons': ['undo', 'redo', 'fullscreen', 'print', 'getPDF', 'spellChecker', 'selectAll', 'html', 'help'],
                                                        'align': 'right',
                                                        'buttonsVisible': 2
                                                    }
                                                },
                                                linkEditButtons: ['linkOpen', 'linkEdit', 'linkRemove'],
                                                pluginsEnabled: [
                                                    'fullscreen',
                                                    'align',
                                                    'colors',
                                                    'lists',
                                                    'link',
                                                    "url",
                                                    "print",
                                                ],
                                                codeBeautifierOptions: {
                                                    indent_char: ' ',
                                                    indent_size: 4,
                                                    wrap_line_length: 0
                                                },
                                                linkAlwaysBlank: true,
                                                attribution: false,
                                                linkList: GlobalConfig.FroalaLinkList,
                                                key: GlobalConfig.FroalaEditorLicenceKey,
                                            }}
                                            model={this.state.content}
                                            onModelChange={this.handleModelChange}
                                        />
                                    </Col>
                                </Row>
                            )}
                        </FormItem>

                        <FormItem
                            {...formItemLayout}
                            label="ファイル添付"
                        >
                            <div className="dropbox">
                                <Upload.Dragger
                                    name="files"
                                    {...attachementFile} >
                                    <p className="ant-upload-drag-icon">
                                        <InboxOutlined />
                                    </p>
                                    <p className="ant-upload-text">ファイルをドラッグ＆ドロップするか、下の「ファイルを選択」から選択してください</p>
                                    <div className="ant-upload-hint">
                                        {GlobalConfig.FormHelpTextOverride.attachHint.Default}
                                    </div>
                                    <Button type="primary" className="button">ファイルを選択</Button>
                                </Upload.Dragger>
                            </div>
                        </FormItem>




                        <FormItem
                            {...formItemLayout}
                            label="サムネイル"
                        >
                            <div className="dropbox">
                                <Upload.Dragger
                                    name="images"
                                    {...thumbnailImage} >
                                    <p className="ant-upload-drag-icon">
                                        <InboxOutlined />
                                    </p>
                                    <p className="ant-upload-text">ファイルをドラッグ＆ドロップするか、下の「ファイルを選択」から選択してください</p>
                                    <div className="ant-upload-hint">
                                        {GlobalConfig.FormHelpTextOverride.attachHint.CatchEye}
                                    </div>
                                    <Button type="primary" className="button">ファイルを選択</Button>
                                </Upload.Dragger>
                            </div>
                        </FormItem>


                        <FormItem
                            {...formItemLayout}
                            label={
                                <span>
                                     優先順位&nbsp;&nbsp;
                                    {/*}
                                    <Tooltip title={GlobalConfig.FormHelpTextOverride.iconText.NotificationPriority ?
                                        GlobalConfig.FormHelpTextOverride.iconText.NotificationPriority
                                        : ""}>
                                        <QuestionCircleOutlined />
                                    </Tooltip>
                                    */}
                                 </span>
                            }
                            extra={GlobalConfig.FormHelpTextOverride.bottomText.Priority ?
                                GlobalConfig.FormHelpTextOverride.bottomText.Priority
                                : ""}
                        >
                            {getFieldDecorator('priority', {
                                    initialValue: this.state.priority
                                }
                            )(
                                <RadioGroup>
                                    <Radio value={1}>通常</Radio>
                                    <Radio value={3}>緊急</Radio>
                                </RadioGroup>
                            )}
                        </FormItem>


                        <FormItem
                            {...formItemLayout}
                            label={
                                <span>
                                     スマホプッシュ配信&nbsp;&nbsp;
                                    <Tooltip title={GlobalConfig.FormHelpTextOverride.iconText.NotificationPush ?
                                        GlobalConfig.FormHelpTextOverride.iconText.NotificationPush
                                        : "通知をオンにするとスマートフォンアプリへお知らせがプッシュ配信されます。公開期間前に作成されたお知らせは公開開始日時になるまで配信されません。"}>
                                        <QuestionCircleOutlined />
                                    </Tooltip>
                                 </span>
                            }
                            extra={GlobalConfig.FormHelpTextOverride.bottomText.IsSendNotification ?
                                GlobalConfig.FormHelpTextOverride.bottomText.IsSendNotification
                                : ""}
                        >
                            {getFieldDecorator('is_send_notification', {
                                    initialValue: this.state.is_send_notification
                                }
                            )(
                                <RadioGroup>
                                    <Radio value={true}>配信する</Radio>
                                    <Radio value={false}>配信しない</Radio>
                                </RadioGroup>
                            )}
                        </FormItem>


                        <FormItem
                            {...formItemLayout}
                            label={
                                <span>
                                     通知対象者&nbsp;&nbsp;
                                    <Tooltip title={GlobalConfig.FormHelpTextOverride.iconText.NotificationTarget ?
                                        GlobalConfig.FormHelpTextOverride.iconText.NotificationTarget
                                        : "通知対象者を選択してください。"}>
                                        <QuestionCircleOutlined />
                                    </Tooltip>
                                 </span>
                            }
                            extra={GlobalConfig.FormHelpTextOverride.bottomText.Target ?
                                GlobalConfig.FormHelpTextOverride.bottomText.Target
                                : ""}
                        >

                            {getFieldDecorator('target_uuid', {
                                initialValue: this.state.target_uuid,
                                rules: [{required:true, message: '通知対象者を選択してください'}]
                            })(
                                <>
                                    <Button type="primary" className="button" icon={<UserOutlined />}
                                    onClick={() => this.setState({selectTargetFormModalIsOpen: true})}>
                                    通知対象者を選択
                                    </Button>
                                    <span>　</span>
                                    <Button type="primary" className="button" icon={<StarOutlined />}
                                    onClick={()=>{this.setState({myTargetModalIsOpen: true})}}>
                                    よく使う対象者から選択
                                    </Button>
                                </>
                            )}
                        </FormItem>

                        <FormItem
                            {...formItemLayout}
                            label="編集権限者"
                            extra={GlobalConfig.FormHelpTextOverride.bottomText.Permission ?
                                GlobalConfig.FormHelpTextOverride.bottomText.Permission
                                : ""}>

                            {getFieldDecorator('permission_uuid', {
                                initialValue: this.state.permission_uuid,
                                rules: [{required:true, message: '編集権限者を選択してください'}]
                            })(
                            <Button type="primary" className="button" icon={<UserOutlined />}
                                    onClick={() => this.setState({selectPermissionFormModalIsOpen: true})}>
                                編集権限者を選択
                            </Button>
                            )}
                        </FormItem>

                    </Form>
                </div>

                <NotificationPreviewModal
                    visible={this.state.previewModalIsOpen}
                    handleCancel={() => {
                        this.setState({
                            previewModalIsOpen: false
                        })
                    }}
                    handleOk={() => {
                    }}
                    data={this.state.previewData}
                />


                {/*通知対象者*/}
                <SelectTargetForm
                    visible={this.state.selectTargetFormModalIsOpen}
                    handleOk={this.handleSelectTarget}
                    handleCancel={() => this.setState({selectTargetFormModalIsOpen: false})}
                    self_uuid={this.state.target_uuid}
                    foreign_uuid={this.state.uuid}
                    type={1} //(1: お知らせ 2: アンケート 3: モジュールリンク)
                />

                {/*通知対象者を保存済のから取得して処理する*/}
                <MyTargetModal
                    visible={this.state.myTargetModalIsOpen}
                    handleOk={(value)=>{
                        this.setState({
                            target_uuid: value.target_uuid,
                            myTargetModalIsOpen: false
                        },()=>{
                            this.setState({selectTargetFormModalIsOpen: true});
                        });
                    }}
                    handleCancel={()=>{
                        this.setState({myTargetModalIsOpen: false})
                    }} />

                {/*編集権限者*/}
                <SelectPermissionForm
                    visible={this.state.selectPermissionFormModalIsOpen}
                    handleOk={this.handleSelectTarget}
                    handleCancel={() => this.setState({selectPermissionFormModalIsOpen: false})}
                    self_uuid={this.state.permission_uuid}
                    foreign_uuid={this.state.uuid}
                    type={1} //(1: お知らせ 2: アンケート 3: モジュールリンク)
                />


            </Modal>
        );
    }
}


export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Form.create()(NotificationAddModal));


NotificationAddModal.propTypes = {
    paramCategoryArray: PropTypes.array.isRequired,
    visible: PropTypes.bool.isRequired,
    handleOk: PropTypes.func.isRequired,
    handleCancel: PropTypes.func.isRequired,
    isEditMode: PropTypes.bool.isRequired,
    data: PropTypes.object
};


// Specifies the default values for props:
NotificationAddModal.defaultProps = {
    data: {}
};
