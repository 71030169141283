import React from 'react';
import {instanceOf} from 'prop-types';
import {connect} from 'react-redux'
import * as Actions from 'redux/actions'
import {
    withRouter,
    Route,
    Switch,
    Redirect
} from 'react-router-dom'

import log from 'components/utils/Logger.js'
import * as GlobalConfig from './constants/GlobalConfig';

import Notification from 'containers/notification/Notifcation'
import NotFound from 'components/NotFound'
import DashboardMenu from "./containers/menu/DashboardMenu";
import DashboardHome from "./containers/home/DashboardHome";
import ModuleWrapper from 'containers/services/ModuleWrapper'

import Footer from 'components/Footer'
import Message from 'containers/message/Message'

import Helmet from 'react-helmet'

import moment from 'moment';
import 'moment/locale/ja';
import ForceModuleQuestionnaire from "./containers/services/modules/questionnaire/ForceModuleQuestionnaire";
moment.locale('ja')

const mapStateToProps = (state, props) => {
    return {
        logout: state.logout,
        session: state.session,
        check: state.check
    }
};

const mapDispatchToProps = dispatch => {
    return {
        windowResize : (dataHeight,dataWidth) => {
            dispatch(Actions.Config.screen(dataHeight,dataWidth));
        },
        checkLoggedIn : () => {
            dispatch(Actions.Connection.check.request());
        }
    }
};


class Dashboard extends React.Component {
    constructor(props) {
        super(props);
        this.props.windowResize(document.body.clientHeight,document.body.clientWidth);
        window.addEventListener('resize', () => {
            this.props.windowResize(document.body.clientHeight,document.body.clientWidth);
        });
        this.state = {
            session: props.session,
            checked: false,
            loggedIn: false,
            menuDetail:{
            },
            modules: []
        }
        this.props.checkLoggedIn();
    }

    componentWillReceiveProps(nextProps) {
        if(!nextProps.check.meta.fetch) {
            if(!nextProps.check.error){
                if(nextProps.check.payload !== null) {
                    this.setState({
                        checked: true,
                        loggedIn: nextProps.check.payload.is_successful
                    })
                }
            }
        }
        if(nextProps.location.pathname !== this.props.location.pathname){
            this.setState({
                pathName : nextProps.location.pathname
            })
        }
    }


    componentWillMount(){
        document.body.classList.add('default-body');
    }

    componentWillUnmount(){
        document.body.classList.remove('default-body');
    }

    changeMenu = (menuDetail) => {
        log.debug(menuDetail);
        this.setState({
            menuDetail
        })
    }

    render() {
        const authenticatedComponent = (
            <div id="body-wrapper">
                <div id="app">
                    <ForceModuleQuestionnaire />
                    <DashboardMenu pathName={this.state.pathName} changeMenu={this.changeMenu} />
                    <div id="app-contents">
                        <Switch>
                            <Route exact path={`${GlobalConfig.UserSubDirectory}`} render={props => (
                                <div>
                                    <Helmet>
                                        <title>ダッシュボード - {GlobalConfig.ServiceTitle}</title>
                                    </Helmet>
                                    <DashboardHome match={props.match} isHome={true} />
                                </div>
                            )}/>
                            <Route exact path={`${GlobalConfig.UserSubDirectory}/menu/:uuid`} render={props => (
                                <div>
                                    <Helmet>
                                        <title>ダッシュボード - {GlobalConfig.ServiceTitle}</title>
                                    </Helmet>
                                    <DashboardHome match={props.match} isHome={false} />
                                </div>
                            )}/>
                            <Route exact path={`${GlobalConfig.UserSubDirectory}/notifications`} render={props => (
                                <div>
                                    <Helmet>
                                        <title>お知らせ管理 - {GlobalConfig.ServiceTitle}</title>
                                    </Helmet>
                                    <Notification/>
                                </div>
                            )}/>
                            <Route exact path={`${GlobalConfig.UserSubDirectory}/services`} render={props => (
                                <div>
                                    <Helmet>
                                        <title>サービステスト - {GlobalConfig.ServiceTitle}</title>
                                    </Helmet>
                                    <ModuleWrapper/>
                                </div>
                            )}/>

                            <Route path={`${GlobalConfig.UserSubDirectory}/fileshare`} render={props => (
                                <div>
                                    <Helmet>
                                        <title>ダッシュボード - {GlobalConfig.ServiceTitle}</title>
                                    </Helmet>
                                    <DashboardHome match={props.match} isHome={true} />
                                </div>
                            )}/>

                            <Route status={404}
                                   render={props => (
                                       <div>
                                           <Helmet>
                                               <title>ページが見つかりません - {GlobalConfig.ServiceTitle}</title>
                                               <meta name="robots" content="noindex" />
                                           </Helmet>
                                           <NotFound/>
                                       </div>
                                   )}
                            />
                        </Switch>
                    </div>
                </div>
                <Footer/>
                <Message />
            </div>
        );


        return (
            <Route render={props => {
                return (
                    this.state.checked ?(
                        !this.state.loggedIn ? (
                                <Redirect to={{
                                    pathname: '/login'
                                }}/>
                            ) :
                            (authenticatedComponent)
                        )
                        :
                        <Message />
                )
            }
            }/>
        );
    }
}


Dashboard.propTypes = {};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(Dashboard))