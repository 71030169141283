import React from 'react';
import {connect, store} from 'react-redux';
import {instanceOf} from 'prop-types';
import * as GlobalConfig from '../../constants/GlobalConfig';
import * as url from '../../constants/api';
import * as Actions from '../../redux/actions';
import moment from 'moment';
import 'moment/locale/ja';
import log from 'components/utils/Logger';
import { DeleteOutlined, EditOutlined, PlusCircleOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import {
    Table,
    Radio,
    Select,
    Row,
    Input,
    Col,
    Button,
    Tooltip,
    DatePicker,
    Collapse,
    Popconfirm,
    Checkbox,
    Tag,
} from 'antd';
import jaJP from 'antd/lib/date-picker/locale/ja_JP';
import DownloadCSVConfirm from '../../containers/common/DownloadCSVConfirm'
import RoleFormModal from './RoleFormModal';

moment.locale('ja');

const FormItem = Form.Item;
const Panel = Collapse.Panel;
const Option = Select.Option;
const RadioButton = Radio.Button;
const RadioGroup = Radio.Group;
const RangePicker = DatePicker.RangePicker;

const mapStateToProps = (state, props) => {
    return {
        screen: state.screen,
        role: state.role,
        roleCreate: state.roleCreate,
        roleUpdate: state.roleUpdate,
        roleDelete: state.roleDelete
    }
};

const mapDispatchToProps = dispatch => {
    return {
        searchRole: (data, currentPageNum) => {
            dispatch(Actions.AdminConnection.role.search.request(data, currentPageNum));
        },
        createRole: (data) => {
            dispatch(Actions.AdminConnection.role.create.request(data));
        },
        updateRole: (uuid,data) => {
            dispatch(Actions.AdminConnection.role.update.request(uuid,data));
        },
        deleteRole: (uuid) => {
            dispatch(Actions.AdminConnection.role.delete.request(uuid));
        }
    }
};

class RoleList extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            pagination: {
                showTotal: (total, range) => {
                    return (`全${total}件中 ${range[0]}-${range[1]} 件`)
                },
                showSizeChanger: true,
                pageSizeOptions: GlobalConfig.PageSizeOptions,
                pageSize: 20,
                onShowSizeChange: this.onChangePageSize,
                total:0,
            },
            roleListArray: [],
            loading:false,
            addModalIsOpen: false,
            editModalIsOpen: false,
            editModalUUID: null,
        }
    }


    componentWillMount(){
        this.search();
    }

    componentWillReceiveProps(nextProps){

        const {role,roleCreate,roleUpdate,roleDelete} = {...this.props};

        if (nextProps.role !== role) {
            if (!nextProps.role.meta.fetch) {
                if (!nextProps.role.error) {
                    if (nextProps.role.payload !== null) {

                        this.setState({
                            loading: nextProps.role.meta.fetch,
                            roleListArray: nextProps.role.payload.result.items,
                        })
                    }
                }
            } else {
                this.setState({
                    loading: nextProps.role.meta.fetch
                })
            }
        }


        if (nextProps.roleCreate !== roleCreate) {
            if (!nextProps.roleCreate.meta.fetch) {
                if (!nextProps.roleCreate.error) {
                    if (nextProps.roleCreate.payload !== null) {
                        this.search();
                        this.setState({
                            addModalIsOpen: false,
                            loading: nextProps.roleCreate.meta.fetch
                        })
                    }else{
                        this.setState({
                            loading: nextProps.roleCreate.meta.fetch
                        })
                    }
                }else{
                    this.setState({
                        loading: nextProps.roleCreate.meta.fetch
                    })
                }
            } else {
                this.setState({
                    loading: nextProps.roleCreate.meta.fetch
                })
            }
        }

        if (nextProps.roleUpdate !== roleUpdate) {
            if (!nextProps.roleUpdate.meta.fetch) {
                if (!nextProps.roleUpdate.error) {
                    if (nextProps.roleUpdate.payload !== null) {
                        this.search();
                        this.setState({
                            editModalIsOpen: false,
                            loading: nextProps.roleUpdate.meta.fetch
                        })
                    }else{
                        this.setState({
                            loading: nextProps.roleUpdate.meta.fetch
                        })
                    }
                }else{
                    this.setState({
                        loading: nextProps.roleUpdate.meta.fetch
                    })
                }
            } else {
                this.setState({
                    loading: nextProps.roleUpdate.meta.fetch
                })
            }
        }

        if (nextProps.roleDelete !== roleDelete) {
            if (!nextProps.roleDelete.meta.fetch) {
                if (!nextProps.roleDelete.error) {
                    if (nextProps.roleDelete.payload !== null) {
                        this.search();
                        this.setState({
                            loading: nextProps.roleDelete.meta.fetch
                        })
                    }else{
                        this.setState({
                            loading: nextProps.roleDelete.meta.fetch
                        })
                    }
                }else{
                    this.setState({
                        loading: nextProps.roleDelete.meta.fetch
                    })
                }
            } else {
                this.setState({
                    loading: nextProps.roleDelete.meta.fetch
                })
            }
        }


    }


    onChangePageSize = (current, pageSize) => {
        const pager = {...this.state.pagination};
        //pageSizeの更新
        pager.pageSize = pageSize;
        this.setState({
            pagination: pager,
        });
    };

    search(value) {
        let fieldsValue = this.props.form.getFieldsValue();
        let pagination;

        if (typeof value === "undefined") {
            pagination = {...this.state.pagination};
        } else {
            pagination = value;
        }

        fieldsValue.page_size = pagination.pageSize;

        this.props.searchRole(fieldsValue, pagination.current);

    }

    onSubmit = (e) => {
        e.preventDefault();
        this.search();
        return false;
    };

    handleResetSearchForm = () => {
        this.props.form.resetFields();
        this.search();
    };

    handleOpenAddModal = (e) => {
        e.preventDefault();
        this.setState({
            addModalIsOpen: true
        })
    };

    handleOpenEditModal = (value) => {
        log.debug(value);
        this.setState({
            data: value,
        }, ()=>{
            this.setState({
                editModalIsOpen: true,
            })
        });
    }

    handleTableChange = (pagination, filters, sorter) => {
        this.search(pagination);
    };


    roleAdd = (fieldValue,data) => {
        log.debug(fieldValue,data);
        this.props.createRole(fieldValue,data);
    };

    roleEdit = (fieldValue,data) => {
        log.debug(fieldValue);
        log.debug(data);
        this.props.updateRole(data.uuid,fieldValue);
    };
    roleDelete = (record) => {
        this.props.deleteRole(record.uuid);
    };





    render() {

        const {getFieldDecorator} = this.props.form;

        const formItemLayout = {
            labelCol: {
                xs: {span: 24},
                sm: {span: 4},
            },
            wrapperCol: {
                xs: {span: 24},
                sm: {span: 20},
            },
        };

        let availableSearchForm = (
            <Form className="search-form" onSubmit={this.onSubmit}>
                <FormItem
                    {...formItemLayout}
                    label="権限名"
                    layout="inline">
                    {getFieldDecorator('name')(
                        <Input placeholder="権限名を入力してください"/>
                    )}
                </FormItem>
                <div className="submit-container">
                    <Button className="search-form-button" onClick={this.handleResetSearchForm}>
                        リセット
                    </Button>
                    <Button type="primary" htmlType="submit" className="search-form-button">
                        検索
                    </Button>
                </div>
            </Form>
        );

        const columns = [
            {
                title: '権限グループ名称',
                dataIndex: 'name',
                key: 'name',
                width: "200px"
            },{
                title: '設定権限',
                dataIndex: 'type',
                key: 'type',
                render: (text,record) => {
                    //todo アンケート実施時はしばく
                    //const str = text ==="admin" ? "インフォメーションシステム管理権限" : "お知らせ/アンケート運用権限";
                    const str = text ==="admin" ? "インフォメーションシステム管理権限" : "お知らせ運用権限";
                    return (
                        str
                    )
                }
            },{
                title: '摘要',
                dataIndex: 'memo',
                key: 'memo'
            }, {
                title: "",
                width: "85px",
                key: 'action',
                fixed: 'right',
                render: (text, record) => {
                    return (
                        <Row type="flex" justify="space-around" align="middle">
                            <Col>
                                <Tooltip placement="topLeft" title="編集">
                                    <Button type="default" shape="circle" icon={<EditOutlined />}
                                            onClick={() => this.handleOpenEditModal(record)}/>
                                </Tooltip>
                            </Col>
                            <Col>
                                <Popconfirm
                                    title={
                                        <span>
                                            <strong>権限「{record.name}」の削除</strong><br />
                                            削除すると復元することはできません。<br />
                                            <strong>削除後に同一名の権限を再追加した場合でも別権限として登録されますので再度設定が必要となります。</strong>
                                        </span>
                                    }
                                    onConfirm={() => this.roleDelete(record)}
                                    onCancel=""
                                    placement="left"
                                    okText="削除" cancelText="キャンセル">
                                    <Tooltip placement="topLeft" title="削除">
                                        <Button type="default" shape="circle" icon={<DeleteOutlined />} />
                                    </Tooltip>
                                </Popconfirm>
                            </Col>
                        </Row>
                    );
                }
            }
        ];


        return (
            <div id="notification-list-container" alt="ユーザ検索"  className="tab-container">
                {/*
                <div className="card general-search">
                    {availableSearchForm}
                </div>
                */}
                <div className="table-header-wrapper">
                    <div className="button-container">
                        <Button type="primary"
                                icon={<PlusCircleOutlined />}
                                onClick={this.handleOpenAddModal}>
                            新規作成
                        </Button>
                    </div>
                </div>
                <Table
                    className="general-table"
                    rowKey="uuid"
                    locale={{
                        filterTitle: 'フィルタ',
                        filterConfirm: '確定',
                        filterReset: 'リセット',
                        emptyText: '該当するものはありません',
                    }}
                    bordered={true}
                    size="middle"
                    loading={this.state.loading}
                    onChange={this.handleTableChange}
                    columns={columns}
                    dataSource={this.state.roleListArray}
                />
                <RoleFormModal
                    modalTitle="新規作成"
                    visible={this.state.addModalIsOpen}
                    handleCancel={() => {
                        this.setState({
                            addModalIsOpen: false
                        })
                    }}
                    handleOk={(fieldValue,data) => {
                        // this.setState({
                        //     addModalIsOpen: false
                        // })
                        this.roleAdd(fieldValue,data);
                    }}
                />
                <RoleFormModal
                    modalTitle="編集"
                    visible={this.state.editModalIsOpen}
                    handleCancel={() => {
                        this.setState({
                            editModalIsOpen: false
                        })
                    }}
                    handleOk={(fieldValue, data) => {
                        // this.setState({
                        //     editModalIsOpen: false
                        // })
                        this.roleEdit(fieldValue,data);
                    }}
                    data={this.state.data}
                />

            </div>
        );
    }

}


export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Form.create()(RoleList))
