import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {Button, Col, Form, Input, Modal, Row, Select, Radio, Tooltip} from "antd";
import {QuestionCircleOutlined,TeamOutlined} from '@ant-design/icons';
import {getDefaultLayout} from "../../constants/GlobalConfig";
import {MinusCircleOutlined, PlusOutlined,ArrowRightOutlined,OrderedListOutlined} from "@ant-design/icons";

const {Option} = Select;
const formItemLayout = getDefaultLayout();

const codeArray = [
    {key: "KYOUTSUCSV_6_TAISYO_SYOUSAI_KUBUN", value: "[共通CSV] 対象者詳細区分コード"},
    {key: "KYOUTSUCSV_32_JINJI_SYOZOKU_CODE", value: "[共通CSV] 人事所属コード"},
    {key: "KYOUTSUCSV_18_SETTI_CODE", value: "[共通CSV] 設置コード"},
    {key: "KYOUTSUCSV_20_GAKUBU_KENKYUKA_SYOZOKU1", value: "[共通CSV] 所属1"},
    {key: "KYOUTSUCSV_21_GAKKA_SENKOU_SYOZOKU2", value: "[共通CSV] 所属2"},
    {key: "GAKUSEICSV_20_GAKUNEN_CODE", value: "[学事マテリアルビューCSV] 学年"},
    {key: "KYOUTSUCSV_33_SHIKAKU_CODE", value: "[共通CSV] 資格コード"},
    {key: "KYOUTSUCSV_34_YAKUSYOKU_CODE", value: "[共通CSV] 役職コード"},
    {key: "KYOUTSUCSV_35_SYOKUSYU_CODE", value: "[共通CSV] 職種コード"}
];

ModalAddEditGroup.propTypes = {};

function Object2Array(data) {
    const keyArray = Object.keys(data);
    return keyArray.map((value, index) => {
        const ary = data[value];
        const str = ary.join('\n');
        return {
            key: value,
            value: str
        }
    })
}

function ModalAddEditGroup(props) {

    const [name, setName] = useState("")
    const [data, setData] = useState(null);
    const [form] = Form.useForm();

    useEffect(() => {

    }, [])

    useEffect(() => {
        setName(props.shouldCreate ? "" : props.data.name)
        setData(props.data)
        form.setFieldsValue({
            name: props.shouldCreate ? "" : props.data.name,
            order: props.shouldCreate ? 0 : props.data.order,
            is_active: props.shouldCreate ? 1 : props.data.is_active,
            is_authority: props.shouldCreate ? 0 : props.data.is_authority,
            attribute: props.shouldCreate || !props.data.attributes ? [] : Object2Array(props.data.attributes)
        })
    }, [props.data, props.visible])


    const handleOk = () => {
        form.submit();
    }

    const handleCancel = () => {
        props.onCancel();
    }

    const onFinish = (values) => {
        const fieldsValue = values;
        const attributes = fieldsValue.attribute?.reduce((obj, item) => ({...obj, [item.key]: item.value}), {})

        fieldsValue.attributes = attributes;
        fieldsValue.order = props.shouldCreate ? 0 : data.order;

        delete fieldsValue.attribute;

        props.onOk(fieldsValue, data);
    }

    return (
        <Modal
            className="common-modal select-target-form"
            title={<span><TeamOutlined /> グループの{props.shouldCreate?"作成":"編集"}</span>}
            visible={props.visible}
            maskClosable={false}
            onOk={handleOk}
            onCancel={handleCancel}>
            <div className='wrapper'>
                {props.shouldCreate ? null :
                    <h3>既存のグループ「{name}」を変更します。</h3>
                }
                <Form
                    form={form}
                    className="search-form"
                    name={"group_form"}
                    onFinish={onFinish}>

                    <Form.Item
                        name={"name"}
                        {...formItemLayout}
                        rules={[{required: true, message: 'グループ名称を入力してください'}]}
                        label="グループ名称">
                        <Input placeholder="グループ名称を入力してください"/>
                    </Form.Item>

                    {props.shouldCreate ? null :
                        <Form.Item
                            {...formItemLayout}
                            label="グループ固有ID（UUID）">
                            <Input value={props.data.uuid} prefix={<TeamOutlined />}　disabled/>
                        </Form.Item>
                    }

                    {props.shouldCreate ? null :
                        <Form.Item
                            {...formItemLayout}
                            label="表示順">
                            <Input value={props.data.order} prefix={<OrderedListOutlined />} disabled/>
                        </Form.Item>
                    }

                    <Form.Item
                        label={
                            <span>
                            対象者選択画面に表示 &nbsp;&nbsp;
                                <Tooltip title="「非表示」とした場合、このグループは対象者選択画面において非表示となります（規定値：表示）"><QuestionCircleOutlined /></Tooltip>
                        </span>
                        }
                        name="is_active"
                        rules={[{required: true, message: '表示/非表示を選択してください'}]}
                        {...formItemLayout}>
                        <Radio.Group>
                            <Radio.Button value={1}>表示</Radio.Button>
                            <Radio.Button value={0}>非表示</Radio.Button>
                        </Radio.Group>
                    </Form.Item>

                    <Form.Item label={
                        <span>
                            利用者の所属として表示 &nbsp;&nbsp;
                            <Tooltip title="「表示」とした場合、このグループは利用者の所属情報として設定されます。グループに所属する利用者はお知らせ作成時などの発信元を指定する際に入力候補として表示されるようになります（規定値：非表示）"><QuestionCircleOutlined /></Tooltip>
                        </span>
                    }
                               name="is_authority"
                               rules={[{required: true, message: '表示/非表示を選択してください'}]}
                               {...formItemLayout}>
                        <Radio.Group>
                            <Radio.Button value={1}>表示</Radio.Button>
                            <Radio.Button value={0}>非表示</Radio.Button>
                        </Radio.Group>
                    </Form.Item>

                    <Form.List name={"attribute"}
                               {...formItemLayout}>
                        {(fields, {add, remove}) => (
                            <>
                                {fields.map((field, index) => (
                                    <Form.Item
                                        key={field.key}
                                        {...(index === 0 ? formItemLayout : formItemLayout)}
                                        label={`利用者連携条件 : ` + (index + 1)}>
                                        <Row gutter={[16, 0]}>
                                            <Col span={10}>
                                                <Form.Item
                                                    name={[field.name, 'key']}
                                                    fieldKey={[field.fieldKey, 'key']}
                                                    rules={[{required: true, message: '紐付元キーを選択'}]}>
                                                    <Select placeholder="紐付元キーを選択">
                                                        {codeArray.map(item => (
                                                            <Option key={item.key} value={item.key}>
                                                                <span>
                                                                    {item.value} <br/>
                                                                    {item.key}
                                                                </span>
                                                            </Option>
                                                        ))}
                                                    </Select>
                                                </Form.Item>
                                            </Col>
                                            <Col span={1}>
                                                <ArrowRightOutlined />
                                            </Col>
                                            <Col span={11}>
                                                <Form.Item
                                                    name={[field.name, 'value']}
                                                    fieldKey={[field.fieldKey, 'value']}
                                                    rules={[{required: true, message: '対象パラメータを入力'}]}>
                                                    <Input.TextArea placeholder="対象パラメータを入力（改行で複数指定）" rows={8}/>
                                                </Form.Item>
                                            </Col>
                                            <Col span={2}>
                                                <MinusCircleOutlined onClick={() => remove(field.name)}/>
                                            </Col>
                                        </Row>
                                    </Form.Item>
                                ))}
                                <Form.Item>
                                    <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined/>} disabled={props.selectedObjectIsFolderType}>
                                        利用者連携条件の追加（AND）
                                    </Button>
                                </Form.Item>
                            </>
                        )}
                    </Form.List>
                </Form>
            </div>
        </Modal>
    );
}

export default ModalAddEditGroup;