import React from 'react'
import {connect, store} from 'react-redux'
import {instanceOf} from 'prop-types';
import log from 'components/utils/Logger';
import * as Actions from '../../redux/actions';
import 'moment/locale/ja';
import SelectPermissionForm from "containers/common/SelectPermissionForm";
import { UserOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Modal, Input, Button, Radio, Row, Col, Checkbox } from 'antd';

const RadioGroup = Radio.Group;
const FormItem = Form.Item;


const mapStateToProps = (state, props) => {
    return {
        userData: state.userView
    }
};

const mapDispatchToProps = dispatch => {
    return {
        userView: (uuid) => {
            dispatch(Actions.AdminConnection.user.view.request(uuid));
        }
    }
};


class RoleFormModal extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            visible: props.visible,
            isEditMode: props.isEditMode,
            data: props.data,
            uuid: props.uuid ? props.uuid : "",
            permission_uuid: props.permission_uuid,
            checked_permission: []
        };
    }

    componentWillReceiveProps(nextProps){
        this.setState({
            isEditMode: nextProps.isEditMode,
            visible: nextProps.visible,
        });

        if(nextProps.data && nextProps.visible !== this.props.visible && nextProps.visible){
            this.setState({
                data: nextProps.data,
                uuid: nextProps.data.uuid,
                permission_uuid : nextProps.data.permission_uuid,
                type: nextProps.data.type,
                memo: nextProps.data.memo,
                name: nextProps.data.name
            });
        }

        if(!nextProps.visible){
            this.props.form.resetFields();
            this.setState({
                data: undefined,
                uuid: "",
                permission_uuid: "",
                checked_permission: []
            })

        }
    };



    handleOk = (fieldsValue,data) => {
        this.props.form.validateFields((err, values) => {
            if (!err) {
                log.debug(fieldsValue);
                log.debug(data);
                this.props.handleOk(fieldsValue,data);
            }
        });
    };

    handleCancel = () =>{
        this.props.handleCancel();
    };



    handleSelectPermission =  (data, type) => {
        this.props.form.setFieldsValue({
            permission_uuid: data.uuid
        });
        this.setState({
            permissionModalIsOpen: false,
            editablePermission: data,
            permission_uuid: data.uuid
        });
    };


    onCheck = (e) => {
        log.debug(e)
        this.setState({
            type: e.target.value
        })
    }

    render() {
        const {getFieldDecorator, getFieldValue} = this.props.form;
        const formItemLayout = {
            labelCol: {
                xs: {span: 24},
                sm: {span: 4},
            },
            wrapperCol: {
                xs: {span: 24},
                sm: {span: 20},
            },
        };
        log.debug(this.state.permission_uuid);
        return (
            <Modal
                className="common-modal select-target-form"
                title={<span><UserOutlined />&nbsp;{this.props.modalTitle}</span>}
                visible={this.state.visible}
                maskClosable={false}
                onOk={() => this.handleOk(this.props.form.getFieldsValue(),this.state.data)}
                onCancel={this.handleCancel}>
                <div className='wrapper'>
                    <FormItem
                        {...formItemLayout}
                        label="権限グループ名称">
                        {getFieldDecorator('name', {
                            initialValue: this.state.name,
                            rules: [{required: true, message: '権限グループ名称を入力してください'}],
                        })(
                            <Input placeholder="権限グループ名称を入力"
                                   onChange={(e) => {
                                       this.setState({
                                           name: e.target.value
                                       })
                                   }}/>
                        )}
                    </FormItem>


                    <FormItem
                        {...formItemLayout}
                        label="摘要">
                        {getFieldDecorator('memo', {
                            initialValue: this.state.memo
                        })(
                            <Input placeholder="摘要を入力"
                                   onChange={(e) => {
                                       this.setState({
                                           memo: e.target.value
                                       })
                                   }}/>
                        )}
                    </FormItem>


                    <FormItem
                        {...formItemLayout}
                        label="設定権限">
                        {getFieldDecorator('type', {
                            initialValue: this.state.type,
                            rules: [{required:true, message: '設定権限を選択してください'}]
                        })(
                            <RadioGroup  onChange={(value) => this.onCheck(value)}>
                                <Radio value="admin">インフォメーションシステム管理権限</Radio>
                                {/*
                                <Radio value="manage">お知らせ/アンケート運用権限</Radio>
                                */}
                                <Radio value="manage">お知らせ運用権限</Radio>
                            </RadioGroup>
                        )}
                    </FormItem>


                    <FormItem
                        {...formItemLayout}
                        label="権限対象者">
                        {getFieldDecorator('permission_uuid', {
                            initialValue: this.state.permission_uuid,
                            rules: [{required:true, message: '編集権限者を選択してください'}]
                        })(
                            <Button type="primary" className="button" icon={<UserOutlined />}
                                    onClick={() => this.setState({permissionModalIsOpen: true})}>
                                権限対象者を選択
                            </Button>
                        )}
                    </FormItem>

                    {/*編集権限者*/}
                    <SelectPermissionForm
                        visible={this.state.permissionModalIsOpen ? this.state.permissionModalIsOpen : false}
                        handleOk={this.handleSelectPermission}
                        handleCancel={() => this.setState({
                            permissionModalIsOpen: false
                        })}
                        // 編集時にuuiが必要になるからpermission-uuid
                        // 編集時にuuidが必要になるから本体のuuid
                        self_uuid={this.state.permission_uuid}
                        foreign_uuid={this.state.uuid}
                        type={2} //(1: お知らせ 2: アンケート 3: モジュールリンク 4:リンク　)
                    />

                </div>
            </Modal>
        );
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Form.create()(RoleFormModal));
