import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import ContentHeader from "../../components/ContentHeader";
import MyTargetList from "./MyTargetList";
import MyTargetEffectComponent from "./MyTargetEffectComponent";
import {Button, Modal, Table} from "antd";
import {UserOutlined} from "@ant-design/icons";
import MyTargetSearch from "./MyTargetSearch";

MyTargetModal.propTypes = {

};

function MyTargetModal(props) {
    const [visible, setVisible] = useState(false);
    const [loading, setLoading] = useState(true);
    const [group,setGroup] = useState(null);
    useEffect(() => {
        setVisible(props.visible)
        //初期化
        !props.visible && setGroup(null);
    }, [props.visible]);

    const handleOk = () => {
        //ok構文
        props.handleOk(group);
    };

    const handleCancel = () => {
        //cancel構文
        props.handleCancel();
    };


    return (
        <Modal
            className="common-modal select-target-form"
            title={<span><UserOutlined/> 対象者選択</span>}
            visible={visible}
            maskClosable={false}
            onOk={handleOk}
            forceRender={true}
            confirmLoading={loading}
            onCancel={handleCancel}
            footer={[
                <Button key="back-my-target" size="large" onClick={handleCancel}>キャンセル</Button>,
                <Button key="creat-my-target" type={"primary"} size="large" onClick={handleOk}>選択</Button>
            ]}>
            <MyTargetEffectComponent>
                <div id="my-target-list-container" className="tab-container" alt="お気に入り対象グループ一覧">
                    <MyTargetSearch visible={visible}/>
                    <MyTargetList setGroup={(value) => setGroup(value)} visible={visible}/>
                </div>
            </MyTargetEffectComponent>

        </Modal>
    );
}

export default MyTargetModal;