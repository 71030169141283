import React, {useEffect, useState} from 'react';
import { Form, Input, Button, Checkbox } from 'antd';
import {LockOutlined, QuestionCircleOutlined, UserOutlined} from "@ant-design/icons";
import {useDispatch, useSelector} from "react-redux";
import * as Actions from "../../redux/actions";
import {check, checkLoginConnection, login} from "../../redux/reducers";

LoginForm.propTypes = {

};
const FormItem = Form.Item;

function LoginForm(props) {
    const dispatch = useDispatch();
    const [form] = Form.useForm();
    const [loading,setLoading] = useState(false);

    const loginConnection = useSelector(state => state.loginConnection);
    const checkLoginConnection = useSelector(state => state.checkLoginConnection);

    useEffect(()=>{
        setLoading(loginConnection.meta.fetch || checkLoginConnection.meta.fetch);
    },[loginConnection,checkLoginConnection])


    const onFinish = (values) => {
        !loading  && dispatch(Actions.http.connection.auth.login(values))
    }

    return (
        <Form
            form={form}
            name="normal_login"
            onFinish={onFinish}
        >
            <Form.Item
                name="user_id"
                rules={[{required: true, message: '利用者IDを入力してください'}]}
            >
                <Input prefix={<UserOutlined className="site-form-item-icon" />} placeholder="利用者ID" />
            </Form.Item>
            <Form.Item
                name="password"
                rules={[{required: true, message: 'パスワードを入力してください'}]}
            >
                <Input
                    prefix={<LockOutlined className="site-form-item-icon" />}
                    type="password"
                    placeholder="パスワード"
                />
            </Form.Item>
            <Form.Item>
                <Button type="primary" htmlType="submit" className="login-form-button"
                        loading={loading}>
                    ログイン
                </Button>
                <a className="login-form-advice" href={"https://jmweb.jm.kansai-u.ac.jp/netw/attention.html"} target={"_blank"}>
                    <QuestionCircleOutlined /> ログインでお困りの方
                </a>
            </Form.Item>
        </Form>

    );
}

export default LoginForm;