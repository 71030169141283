import React from 'react'

import {connect, store} from 'react-redux'

import * as TagNodeGenerator from '../../components/utils/TagNodeGenerator'
import moment from 'moment';
import * as Actions from '../../redux/actions'
import 'moment/locale/ja';
import { ArrowRightOutlined, CaretRightOutlined, DownloadOutlined, LoadingOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import {
    Modal,
    Table,
    Tabs,
    Collapse,
    Card,
    Input,
    Button,
    Row,
    Col,
    Select,
    Radio,
    Tooltip,
    Upload,
    Spin,
} from 'antd';
import FroalaEditorView from 'react-froala-wysiwyg/FroalaEditorView';
import log from '../../components/utils/Logger';

//グラフ
import {BarChart, Bar, XAxis, YAxis, CartesianGrid, Legend, PieChart, Pie, Sector, Cell} from 'recharts';
import {Tooltip as PieChartTooltip} from 'recharts';
//グラフ終わり
import QuestionnaireResultModalFreeInput from "./QuestionnaireResultModalFreeInput";
import QuestionnaireResultModalFileInput from "./QuestionnaireResultModalFileInput";
import * as url from '../../constants/api';
import DownloadCSVConfirm from '../../containers/common/DownloadCSVConfirm'

moment.locale('ja');
const FormItem = Form.Item;
const Panel = Collapse.Panel;
const Option = Select.Option;
const RadioButton = Radio.Button;
const {TextArea} = Input;


const mapStateToProps = (state, props) => {
    return {
        screen: state.screen,
        questionnaireAnswerResult: state.questionnaireAnswerResult
    }
};

const mapDispatchToProps = dispatch => {
    return {
        uploadImages: (fileName, contentType, data) => {
            dispatch(Actions.Connection.images.request(fileName, contentType, data));
        },
        getAnswerResult: (uuid) => {
            dispatch(Actions.AdminConnection.questionnaire.answer.result.request(uuid));
        },
        getAnswerResultUser: (uuid) => {
            dispatch(Actions.Connection.questionnaire.answer.result.request(uuid));
        }
    }
};


////円グラフ//////////////////////////

const COLORS = ['#EA5532',
    '#F6AD3C',
    '#FFF33F',
    '#AACF52',
    '#00A95F',
    '#00ADA9',
    '#00AFEC',
    '#187FC4',
    '#4D4398',
    '#A64A97'
];
const RADIAN = Math.PI / 180;
const renderCustomizedLabel = ({cx, cy, midAngle, innerRadius, outerRadius, percent, index}) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);

    return (
        //何故か表示されないパーセント表示
        <text x={x} y={y} fill="white" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
            {`${(percent * 100).toFixed(0)}%`}
        </text>
    );
};

/////////棒グラフ////////////////////
class TinyBarChart extends React.Component {

    getInitialState() {
        return {
            data: [
                {name: 'Page A', pv: 2400, amt: 2400},
                {name: 'Page B', pv: 1398, amt: 2210},
                {name: 'Page C', pv: 9800, amt: 2290},
            ],
            activeIndex: 0,
        };
    }

    handleClick(data, index) {
        this.setState({
            activeIndex: index,
        });
    }

    render() {
        const {activeIndex, data} = this.state;
        const activeItem = data[activeIndex];

        return (
            <BarChart
                width={300}
                height={200}
                data={data}
                layout="vertical"
                margin={{top: 5, right: 30, left: 20, bottom: 5}}
            >
                <XAxis type="number"/>
                <YAxis type="category" dataKey="name"/>
                <CartesianGrid strokeDasharray="3 3"/>
                <Tooltip/>
                <Bar dataKey="pv"/>
            </BarChart>
        );
    }
}

/////////棒グラフ終わり////////////////////


class QuestionnaireResultModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: undefined,
            modalTitle: props.title,
            visible: props.visible,
            questionItemUuidArray: [],
            questionItemArray: [],
            answerState: {},
            loading: true,
            inputResultModalIsOpen2: false,
            inputResultModalIsOpen: false,
            isAdmin: props.isAdmin,

            isAnonymous: false
        };
    }

    componentWillReceiveProps(nextProps) {
        this.setState({
            visible: nextProps.visible,
            isAdmin: nextProps.isAdmin
        });

        if (nextProps.visible !== this.props.visible && nextProps.visible) {
            this.setState({
                loading: true
            })
            if (this.state.isAdmin) {
                this.props.getAnswerResult(nextProps.uuid);
            } else {
                this.props.getAnswerResultUser(nextProps.uuid);
            }
        }

        if (nextProps.questionnaireAnswerResult !== this.props.questionnaireAnswerResult) {
            if (!nextProps.questionnaireAnswerResult.meta.fetch) {
                if (!nextProps.questionnaireAnswerResult.error) {
                    if (nextProps.questionnaireAnswerResult.payload !== null && nextProps.questionnaireAnswerResult.payload.hasOwnProperty("result")) {
                        //questionnaireAnswerResult
                        log.debug(nextProps.questionnaireAnswerResult.payload);
                        const data = nextProps.questionnaireAnswerResult.payload.result;
                        const questionItemArray = nextProps.questionnaireAnswerResult.payload.result.items;
                        const isAnonymous = nextProps.questionnaireAnswerResult.payload.result.is_anonymous;
                        this.setState({
                            questionItemArray,
                            data,
                            isAnonymous
                        })
                    }
                }
            } else {
                this.setState({
                    loading: nextProps.questionnaireAnswerResult.meta.fetch
                })
            }
        }


        if (!nextProps.visible) {
            this.setState({
                data: undefined,
                questionItemArray: []
            })
        }

    };

    handleOk = () => {

    };

    handleCancel = () => {
        this.props.handleCancel();
    };


    handleSelect = (value, key) => {
        const {answerState} = this.state;
        answerState[key] = value;
    };


    handleCsvUserDownload = (encoding) => {

        let urlString = `${process.env.REACT_APP_FILE_DOMAIN}${url.API_ADMIN_ANSWER}/user-result-csv/${this.props.uuid}?encoding=${encoding}`;

        window.open(urlString);
    };


    render() {
        const formItemLayout = {
            labelCol: {
                xs: {span: 24},
                sm: {span: 4},
            },
            wrapperCol: {
                xs: {span: 24},
                sm: {span: 20},
            },
        };
        const {getFieldDecorator, setFieldsValue} = this.props.form;
        const {data, visible, confirmLoading, questionItemArray, uuid} = this.state;


        const columns = [{
            title: '添付ファイル',
            dataIndex: "file_name",
            key: "file_name"
        }, {
            dataIndex: 'attachment',
            key: 'attachment',
            width: "50px",
            render: (text, record) => {
                return (
                    <Tooltip placement="topLeft" title="ファイルダウンロード">
                        <Button type="default" shape="circle" icon={<DownloadOutlined />}
                                onClick={() => window.open(`${process.env.REACT_APP_FILE_DOMAIN}/file/view/${record.uuid}`, '_blank')}/>
                    </Tooltip>
                );
            }
        }];

        log.debug(questionItemArray);

        const elementItem = questionItemArray.map((itemValue, index) => {
            const commonElement = (
                <div className="question-form-questionnaire-header">
                    <h3>{itemValue.content}</h3>
                    <span>{itemValue.detail}</span>
                    {itemValue.file ?
                        <div className="notification-preview-download">
                            <Table
                                className="general-table"
                                locale={{
                                    filterTitle: 'フィルタ',
                                    filterConfirm: '確定',
                                    filterReset: 'リセット',
                                    emptyText: '該当するものはありません',
                                }}
                                bordered={true}
                                pagination={false}
                                columns={columns} dataSource={[itemValue.file]}
                                size="middle"
                            />
                        </div> :
                        null}
                </div>
            );


            log.debug(itemValue);

            let allAnswersCount = 0;
            switch (itemValue.type) {
                case 1:
                    //単一回答
                    //質問選択肢からpieChartに合う形式に変換
                    const options = itemValue.answer_candidates.map((value, index) => {
                        allAnswersCount = allAnswersCount + value.answer_count;
                        return {
                            name: `■選択肢${index + 1}`,
                            content: value.content,
                            value: value.answer_count ? value.answer_count : 0,
                            uuid: value.uuid
                        };
                    });

                    const element = options.map((value, index) => {
                        return (
                            <div className="aaaa">
                                <div><b>■選択肢{index + 1}：{value.content}</b></div>
                                <div>回答者数：{value.value}件</div>
                            </div>
                        )
                    });

                    return (
                        <div key={itemValue.uuid} className="question_form">
                            <div>{commonElement}</div>
                            <div className="answer-result">
                                <div className="answer-result-choice">
                                    <div className="aaaa">
                                        <b>全回答数： {itemValue.answer_count}件</b>
                                    </div>
                                    {element}
                                </div>
                                <div className="chart-box">

                                    {allAnswersCount !== 0 ?
                                        <PieChart width={200} height={200}>
                                            <Pie
                                                data={options}
                                                cx={100}
                                                cy={100}
                                                labelLine={false}
                                                label={renderCustomizedLabel}
                                                outerRadius={80}
                                            >
                                                {
                                                    //何故か表示されないパーセント表示
                                                    options.map((entry, index) => <Cell
                                                        fill={COLORS[index % COLORS.length]}/>)
                                                }
                                            </Pie>
                                            <PieChartTooltip/>
                                        </PieChart>
                                        :
                                        <span>回答は現在存在しません</span>
                                    }
                                </div>
                            </div>
                        </div>
                    );
                case 2:
                    //複数回答
                    const optionsMulti = itemValue.answer_candidates.map((value, index) => {
                        allAnswersCount = allAnswersCount + value.answer_count;
                        return {
                            name: `■選択肢${index + 1}`,
                            content: value.content,
                            value: value.answer_count ? value.answer_count : 0
                        };
                    });

                    const elementMulti = optionsMulti.map((value, index) => {
                        return (
                            <div className="aaaa">
                                <div><b>■選択肢{index + 1}：{value.content}</b></div>
                                <div>回答者数：{value.value}件</div>
                            </div>

                        )
                    });


                    return (
                        <div key={itemValue.uuid} className="question_form">
                            <div>{commonElement}</div>
                            <div className="answer-result">
                                <div className="answer-result-choice">
                                    <div className="aaaa">
                                        <b>全回答数： {itemValue.answer_count}件</b>
                                    </div>
                                    {elementMulti}
                                </div>
                                <div className="chart-box">
                                    {allAnswersCount !== 0 ?
                                        <PieChart width={200} height={200}>
                                            <Pie
                                                data={optionsMulti}
                                                cx={100}
                                                cy={100}
                                                labelLine={false}
                                                label={renderCustomizedLabel}
                                                outerRadius={80}
                                            >
                                                {
                                                    //何故か表示されないパーセント表示
                                                    optionsMulti.map((entry, index) => <Cell
                                                        fill={COLORS[index % COLORS.length]}/>)
                                                }
                                            </Pie>
                                            <PieChartTooltip/>
                                        </PieChart>
                                        :
                                        <span>回答は現在存在しません</span>
                                    }
                                </div>
                            </div>
                        </div>
                    );

                case 3:
                    //自由記述
                    return (
                        <div key={itemValue.uuid} className="question_form">
                            <div>{commonElement}</div>
                            <div className="answer-result">
                                <div className="answer-result-choice">
                                    <div className="aaaa">
                                        <b>全回答数： {itemValue.answer_count}件</b>
                                    </div>
                                </div>
                                <div className="answer-result-choice-right">
                                    <Button type="primary" icon={<CaretRightOutlined />} onClick={() => {
                                        this.setState({
                                            resultFreeForm: itemValue,
                                            resultFreeFormUuid: itemValue.uuid
                                        }, () => {
                                            this.setState({
                                                inputResultModalIsOpen: true
                                            })
                                        })
                                    }}>
                                        自由記述を見る
                                    </Button>
                                </div>
                            </div>
                        </div>
                    );
                case 4:
                    //添付ファイル
                    return (
                        <div key={itemValue.uuid} className="question_form">
                            <div>{commonElement}</div>
                            <div className="answer-result">
                                <div className="answer-result-choice">
                                    <div className="aaaa">
                                        <b>全回答数： {itemValue.answer_count} 件</b>
                                    </div>
                                </div>
                                <div className="answer-result-choice-right">
                                    <Button type="primary" icon={<CaretRightOutlined />} onClick={() => {
                                        this.setState({
                                            resultAttachment: itemValue,
                                            resultAttachmentUuid: itemValue.uuid
                                        }, () => {
                                            this.setState({
                                                inputResultModalIsOpen2: true
                                            })
                                        })
                                    }}>
                                        添付ファイルを見る
                                    </Button>
                                </div>
                            </div>
                        </div>
                    );
                default:
                    return (
                        <div key={itemValue.uuid} className="question_form">


                        </div>
                    );
            }

        });

        let titleNode = "";
        let mainElement = null;
        const footer =
            [
                <Button key="back" size="large" onClick={this.handleCancel}>閉じる</Button>,
            ]
        ;
        if (data) {
            const tagNode = TagNodeGenerator.generate(TagNodeGenerator.TYPES_QUESTIONNAIRE_PUBLIC, data.is_public);
            const tagNode2 = TagNodeGenerator.generate(TagNodeGenerator.TYPES_QUESTIONNAIRE_ANONYMOUS, data.is_anonymous);
            const tagNode3 = TagNodeGenerator.generate(TagNodeGenerator.TYPES_QUESTIONNAIRE_PUBLIC_RESULT, data.is_public_result);


            const publish_start = moment.unix(data.publish_start_at).format("YYYY.MM.DD HH:mm");
            const publish_end = moment.unix(data.publish_end_at).format("YYYY.MM.DD HH:mm");
            const perform_start = moment.unix(data.perform_start_at).format("YYYY.MM.DD HH:mm");
            const perform_end = moment.unix(data.perform_end_at).format("YYYY.MM.DD HH:mm");
            const publish_result_start = moment.unix(data.publish_result_start_at).format("YYYY.MM.DD HH:mm");
            const publish_result_end = moment.unix(data.publish_result_end_at).format("YYYY.MM.DD HH:mm");

            let publish_lastmod;
            if (typeof data.created_at === "undefined") {
                publish_lastmod = "プレビュー記事";
            } else {
                publish_lastmod = !!data.updated_at ? moment.unix(data.updated_at).format("最終更新 YYYY.MM.DD HH:mm") : moment.unix(data.created_at).format("最終更新 YYYY.MM.DD HH:mm");
            }

            let category;
            if (data.category) {
                category = data.category.name;
            } else {
                this.props.paramCategory.map((value, index) => {
                    if (value.uuid === data.category_uuid) {
                        category = value.name;
                    }
                })
            }

            titleNode = (
                <div className="module-single-title">
                    <div className="title">{tagNode}{tagNode2}{tagNode3}<span>{data.name}</span></div>
                </div>
            );


            // 編集権限者のみCSVダウンロードを許可する
            let downloadCsvButton = data.is_editable && this.state.isAdmin ?
                <div>
                    <h3>ユーザ毎の集計結果</h3>
                    <DownloadCSVConfirm
                        visible={true}
                        handleOk={(encoding) => this.handleCsvUserDownload(encoding)}/>
                </div> :
                <div></div>;

            mainElement = (
                <Modal
                    className="common-modal module-questionnaire-single"
                    style={{top: 20}}
                    title={titleNode}
                    visible={visible}
                    maskClosable={false}
                    onOk={this.handleOk}
                    confirmLoading={confirmLoading}
                    onCancel={this.handleCancel}
                    footer={footer}>


                    <div className="meta">
                        <div className="date">公開期間 : {perform_start} <ArrowRightOutlined /> {perform_end}</div>
                        <div className="date">実施期間 : {publish_start} <ArrowRightOutlined /> {publish_end}</div>
                        <div className="date">結果公開期間 : {publish_result_start} <ArrowRightOutlined /> {publish_result_end}</div>
                        <div className="category">配信元 : {data.publish_from} </div>
                        <div className="category">カテゴリ : {category} </div>
                    </div>

                    <div className='wrapper questionnaire-modal-single'>

                        <div className="question-modal-header">
                            <h3>{data.name}</h3>
                            <FroalaEditorView model={data.content}/>

                            {downloadCsvButton}

                        </div>


                        <Form>
                            {elementItem}
                        </Form>
                    </div>

                    <QuestionnaireResultModalFreeInput
                        isAdmin={this.state.isAdmin}
                        visible={this.state.inputResultModalIsOpen}
                        handleCancel={() => {
                            this.setState({
                                inputResultModalIsOpen: false
                            })
                        }}
                        handleOk={(fieldValue, data) => {
                            //this.props.updateNotification(data.uuid, fieldValue);
                            this.setState({
                                inputResultModalIsOpen: false
                            })
                        }}
                        isAnonymous={this.state.isAnonymous}
                        data={this.state.resultFreeForm}
                        uuid={this.state.resultFreeFormUuid}
                        list={this.state.resultAnswerFreeInput}
                    />
                    <QuestionnaireResultModalFileInput
                        isAdmin={this.state.isAdmin}
                        visible={this.state.inputResultModalIsOpen2}
                        handleCancel={() => {
                            this.setState({
                                inputResultModalIsOpen2: false
                            })
                        }}
                        handleOk={(fieldValue, data) => {
                            //this.props.updateNotification(data.uuid, fieldValue);
                            this.setState({
                                inputResultModalIsOpen2: false
                            })
                        }}
                        isAnonymous={this.state.isAnonymous}
                        data={this.state.resultAttachment}
                        uuid={this.state.resultAttachmentUuid}
                        list={this.state.resultAnswerAttach}
                    />
                </Modal>
            )
        } else {
            mainElement = (
                <Modal
                    title={"回答結果"}
                    className="common-modal module-questionnaire-single"
                    visible={visible}
                    maskClosable={false}
                    onOk={this.handleOk}
                    confirmLoading={confirmLoading}
                    onCancel={this.handleCancel}
                    footer={null}>

                    <div style={{padding: "200px 10px", textAlign: "center"}}>
                        <LoadingOutlined />&nbsp;読み込み中...
                    </div>
                </Modal>
            )
        }


        return (
            mainElement
        );
    }
}


export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Form.create()(QuestionnaireResultModal))
