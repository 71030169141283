import React from 'react';
import {connect, store} from 'react-redux';
import PropTypes from 'prop-types';
import * as Actions from '../../redux/actions'
import { LoadingOutlined, QuestionCircleOutlined, SearchOutlined, SolutionOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import {
    Modal,
    Radio,
    Input,
    Button,
    Table,
    Badge,
    Tooltip,
    Row,
    Col,
    Card,
    Progress,
    Popconfirm,
} from 'antd';

import * as url from '../../constants/api';
import * as GlobalConfig from '../../constants/GlobalConfig';

import UserViewModal from '../users/UserViewModal';

import DownloadCSVConfirm from '../../containers/common/DownloadCSVConfirm'

const FormItem = Form.Item;
const RadioGroup = Radio.Group;

const mapStateToProps = (state, props) => {
    return {
        screen: state.screen,
        notificationTarget: state.notificationTarget,

    }
};

const mapDispatchToProps = dispatch => {
    return {
        searchNotificationTarget: (uuid,data,page) => {
            dispatch(Actions.AdminConnection.notification.target.request(uuid,{...data,is_login_enabled:1},page));
        }
    }
};

class NotificationStatusModal extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            modalTitle: <span><SolutionOutlined />&nbsp;お知らせ公開対象者と配信状況の確認</span>,
            visible: props.visible,
            dataArray: [],
            totalUser: 0,
            sent_count: 0,
            unsent_count: 0,
            userModalIsOpen: false,
            searchLoading: false,
            total_count: null,
            // 通知用
            userUUID: null,
            pagination: {
                showTotal: (total, range) => {
                    return (`全${total}件中 ${range[0]}-${range[1]} 件`)
                },
                showSizeChanger: true,
                pageSizeOptions: GlobalConfig.PageSizeOptions,
                onShowSizeChange: this.onChangePageSize,
                total:0,
            }
        }
    }

    componentWillMount(){

    }


    componentWillReceiveProps(nextProps) {

        if(nextProps.visible === true && this.props.visible === false) {
            this.setState({
                total_count: null,
                pagination: {
                    showTotal: (total, range) => {
                        return (`全${total}件中 ${range[0]}-${range[1]} 件`)
                    },
                    showSizeChanger: true,
                    pageSizeOptions: GlobalConfig.PageSizeOptions,
                    onShowSizeChange: this.onChangePageSize,
                    total:0,
                    current: 1
                }
            })
        }

        // モーダル展開時にデータを取得する
        if(this.props.visible === false && nextProps.visible === true && nextProps.uuid !== null) {
            this.props.searchNotificationTarget(nextProps.uuid,);
        }

        // 一覧・検索
        if(nextProps.notificationTarget !== this.props.notificationTarget) {
            if (!nextProps.notificationTarget.meta.fetch) {
                if (!nextProps.notificationTarget.error) {
                    if (nextProps.notificationTarget.payload !== null && nextProps.notificationTarget.payload.hasOwnProperty("result")) {
                        this.setState({
                            searchLoading: nextProps.notificationTarget.meta.fetch,
                            dataArray: nextProps.notificationTarget.payload.result.items,
                            // プッシュ対象者数
                            target_count: nextProps.notificationTarget.payload.result.target_count,
                            // プッシュ送信者数
                            sent_count: nextProps.notificationTarget.payload.result.sent_count,
                            // プッシュ未送信者数
                            unsent_count: nextProps.notificationTarget.payload.result.unsent_count,
                            // 開封者者数
                            read_count: nextProps.notificationTarget.payload.result.read_count,
                            // 未開封者数
                            unread_count: this.state.total_count ? this.state.total_count - nextProps.notificationTarget.payload.result.read_count: nextProps.notificationTarget.payload.result.item_count- nextProps.notificationTarget.payload.result.read_count,
                            //対象合計者数
                            total_count : this.state.total_count ? this.state.total_count: nextProps.notificationTarget.payload.result.item_count,
                            pagination: {
                                ...this.state.pagination,
                                total: nextProps.notificationTarget.payload.result.item_count,
                            }
                        });
                    }
                }else{
                    // error

                }
            } else {
                this.setState({
                    searchLoading: nextProps.notificationTarget.meta.fetch,

                });

            }
        }

        this.setState ({
            visible: nextProps.visible,
            statusTargetUUID: nextProps.uuid,
        });

        if (!nextProps.visible) {
            this.props.form.resetFields();
        }
    }

    onChangePageSize = (current, pageSize) => {
        const pager = {...this.state.pagination};
        //pageSizeの更新
        pager.pageSize = pageSize;
        this.setState({
            pagination: pager,
        });
    };

    search(value) {

        let fieldsValue = this.props.form.getFieldsValue();

        let pagination;
        if (typeof value === "undefined") {
            pagination = {...this.state.pagination};
        } else {
            pagination = {
                ...this.state.pagination,
                ...value
            };
        }

        fieldsValue.page_size = pagination.pageSize;

        this.props.searchNotificationTarget(this.state.statusTargetUUID, fieldsValue, pagination.current);

    }

    handleTableChange = (pagination, filters, sorter) => {
        this.setState(preview=>({
            pagination: {
                ...preview.pagination,
                ...pagination
            }
        }))
        this.search(pagination);

    };

    onSubmit = (e) => {
        e.preventDefault();
        this.setState(preview=>({
            pagination: {
                ...preview.pagination,
                current: 1
            }
        }),()=>{
            this.search();

        })

        return false;
    };

    handleResetSearchForm = () => {
        this.props.form.resetFields();
        this.props.searchNotificationTarget(this.state.statusTargetUUID,);
    };

    handleOk = (fieldValue,data) => {
        this.setState({
            dataArray:[],
            totalUser: 0,
            sent_count: 0,
            unsent_count: 0,
        });
        //バリデーション
        this.props.form.validateFields((err, values) => {
            if (!err) {
                this.setState({
                    confirmLoading: true,
                });
                this.props.handleOk(fieldValue,data);
            }
        });

    };

    handleCancel = () => {
        this.setState({
            dataArray:[],
            totalUser: 0,
            sent_count: 0,
            unsent_count: 0,
        });
        this.props.handleCancel();
    };

    handleOpenUserModal = (record) => {
        this.setState({
            userModalIsOpen : true,
            userUUID:record.uuid,
        });
    };

    handleDownloadCSV = (encoding) => {
        let urlString = `${url.API_DOMAIN}${url.API_ADMIN_NOTIFICATION}/download-csv/${this.state.statusTargetUUID}/?encoding=${encoding}&is_login_enabled=1`;
        window.open(urlString);
    };


    render() {
        const {visible, confirmLoading} = this.state;
        const formItemLayout = {
            labelCol: {
                xs: {span: 24},
                sm: {span: 4},
            },
            wrapperCol: {
                xs: {span: 24},
                sm: {span: 20},
            },
        };

        const {getFieldDecorator} = this.props.form;

        const columns = [
            {
                title: '利用者ID',
                dataIndex: 'user_id',
                key: 'user_id',
                width: "110px",
            }, {
                title: <span>学籍番号<br/>10桁コード</span>,
                dataIndex: 'personal_id',
                key: 'personal_id',
                width: "110px"
            }, {
                title: <span>学籍番号／<br/>教職員表示番号</span>,
                dataIndex: 'option_1',
                key: 'option_1',
                width: "110px"
            }, {
                title: '氏名',
                dataIndex: 'name',
                key: 'name',
            },{
                title: '氏名(カナ/英)',
                dataIndex: 'name_kana',
                key: 'name_kana',
                render: (text, record) => {
                    return (
                        <div>
                            {record.name_kana}<br/>
                            {record.name_alphabet}
                        </div>
                    )
                }
            },{
                title: '開封状態',
                dataIndex: 'is_read',
                key: 'is_read',
                width: "85px",
                style: "tag",
                render: (text) => {
                    if (text === true) {
                        return <Badge status="success" text="開封済" />;
                    }
                    return <Badge status="default" text="未開封" />;
                }

            },{
                title: <span>プッシュ配信&nbsp;&nbsp;
                    <Tooltip title={
                        <span>
                            配信中：プッシュ配信を送信しています。<br/>
                            配信済：プッシュ配信が可能なユーザへの配信が完了しました。<br/>
                            対象外：スマートフォンでログインされていないユーザです。<br/>
                            配信しない：プッシュ配信を実施しない設定になっています。
                        </span>
                    }>
                    <QuestionCircleOutlined />
                    </Tooltip>
                    </span>,
                dataIndex: 'status',
                width: "110px",
                key: 'status',
                render: (status) => {
                        switch (status) {
                            case ("sending"): {
                                return <Badge status="processing" text="配信中" />;
                            }
                            case ("sent"): {
                                return <Badge status="success" text="配信済" />;
                            }
                            case ("excluded"): {
                                return <Badge status="default" text="対象外" />;
                            }
                            case ("no_send"): {
                                return <Badge status="default" text="配信しない" />;
                            }
                            case ("before_send"): {
                                return <Badge status="default" text="配信前" />;
                            }
                            default: {
                                return <Badge status="warning" text="不明" />;
                            }
                        }
                }
            },{
                title: "",
                width: "50px",
                key: 'action',
                render: (text, record) => {
                    return (
                        <Row type="flex" justify="space-around" align="middle">
                            <Col>
                                <Tooltip placement="topLeft" title="ユーザ情報">
                                    <Button type="default" shape="circle" icon={<SearchOutlined />} onClick={() => this.handleOpenUserModal(record)}/>
                                </Tooltip>
                            </Col>
                        </Row>
                    );
                },
            }
        ];

        let pushProgressPersent;
        if(this.state.unsent_count === 0) {
            pushProgressPersent = 100;
        }else{
            pushProgressPersent = Math.round((this.state.sent_count / this.state.target_count) * 100);
        }

        let readProgressPersent;
        if(this.state.read_count === 0) {
            readProgressPersent = 0;
        }else{
            readProgressPersent = Math.round((this.state.read_count / this.state.total_count) * 100);
        }

        let pushTotalUser = this.state.target_count;

        return (
            <Modal
                className="common-modal notification-status-modal"
                title={this.state.modalTitle}
                visible={visible}
                maskClosable={false}
                onOk={() => this.handleOk(this.props.form.getFieldsValue(),this.state.data)}
                confirmLoading={confirmLoading}
                onCancel={this.handleCancel}>

                <div className='wrapper'>

                    <div className="notification-status-modal-card-wrapper">
                        <Row gutter={16}>
                            <Col span={8}>
                                <Card title="公開対象者" bordered={false} className="notification-status-modal-card">
                                    {this.state.searchLoading ?
                                        <Row type="flex" justify="space-around" align="middle" style={{textAlign: "center"}}>
                                            <Col span={24}>
                                                <span><LoadingOutlined /> 読み込み中...</span>
                                            </Col>
                                        </Row>:
                                        <Row align="middle" type="flex">
                                            <Col span={24}>
                                                <div className="notification-status-modal-title">合計者数</div>
                                                <div className="notification-status-modal-value">{this.state.total_count}名</div>
                                            </Col>
                                        </Row>
                                    }
                                </Card>
                            </Col>
                            <Col span={8}>
                                <Card title="開封状況" bordered={false} className="notification-status-modal-card">
                                    {this.state.searchLoading ?
                                        <Row type="flex" justify="space-around" align="middle" style={{textAlign: "center"}}>
                                            <Col span={24}>
                                                <span><LoadingOutlined /> 読み込み中...</span>
                                            </Col>
                                        </Row>:
                                        <Row align="middle" type="flex">
                                            <Col span={12}><Progress type="circle" percent={readProgressPersent}
                                                                     width={90} strokeWidth={8}/>
                                            </Col>
                                            <Col span={12}>

                                                <div className="notification-status-modal-title">開封者数</div>
                                                <div className="notification-status-modal-value">{this.state.read_count}名</div>
                                                <div className="notification-status-modal-title">未開封者数</div>
                                                <div
                                                    className="notification-status-modal-value">{this.state.unread_count}名
                                                </div>

                                            </Col>
                                        </Row>
                                    }
                                </Card>

                            </Col>
                            <Col span={8}>
                                <Card title="プッシュ配信状況" bordered={false} className="notification-status-modal-card">
                                    {this.state.searchLoading ?
                                        <Row type="flex" justify="space-around" align="middle" style={{textAlign: "center"}}>
                                            <Col span={24}>
                                                <span><LoadingOutlined /> 読み込み中...</span>
                                            </Col>
                                        </Row>:
                                        <Row align="middle" type="flex">
                                            <Col span={12}><Progress type="circle" percent={pushProgressPersent}
                                                                     width={90} strokeWidth={8}/></Col>
                                            <Col span={12}>
                                                {/*
                                                <div className="notification-status-modal-title">配信完了者数</div>
                                                <div className="notification-status-modal-value">{this.state.sent_count}名</div>
                                                */}
                                                <div className="notification-status-modal-title">プッシュ配信対象者数<br/>（アプリ版利用者数）</div>
                                                <div className="notification-status-modal-value">{pushTotalUser}名</div>

                                            </Col>
                                        </Row>
                                    }
                                </Card>
                            </Col>
                        </Row>
                    </div>

                    <div className="card general-search">
                        <Form className="search-form" onSubmit={this.onSubmit}>

                            <FormItem
                                {...formItemLayout}
                                label="利用者ID"
                                layout="inline">
                                {getFieldDecorator('user_id')(
                                    <Input placeholder="利用者IDを入力してください"/>
                                )}
                            </FormItem>


                            <FormItem
                                {...formItemLayout}
                                label="学籍番号10桁コード"
                                layout="inline">
                                {getFieldDecorator('personal_id')(
                                    <Input placeholder="学籍番号10桁コードを入力してください"/>
                                )}
                            </FormItem>
                            <FormItem
                                {...formItemLayout}
                                label="日本語学籍番号（例：法２１−１）">
                                {getFieldDecorator('option_1')(
                                    <Input placeholder="日本語学籍番号（例：法２１−１）を入力してください"/>
                                )}
                            </FormItem>
                            <FormItem
                                {...formItemLayout}
                                label="教職員番号">
                                {getFieldDecorator('option_2')(
                                    <Input placeholder="教職員番号を入力してください" />
                                )}
                            </FormItem>

                            <FormItem
                                {...formItemLayout}
                                label="氏名"
                                layout="inline">
                                {getFieldDecorator('name')(
                                    <Input placeholder="氏名を入力してください"/>
                                )}
                            </FormItem>
                            {/*
                            <FormItem
                                {...formItemLayout}
                                label="開封状態"
                            >
                                {getFieldDecorator('is_read')(
                                    <RadioGroup>
                                        <Radio value={false}>未開封</Radio>
                                        <Radio value={true}>回答済</Radio>
                                    </RadioGroup>
                                )}
                            </FormItem>



                            <FormItem
                                {...formItemLayout}
                                label="プッシュ配信"
                            >
                                {getFieldDecorator('checked')(
                                    <RadioGroup>
                                        <Radio value={0}>配信中</Radio>
                                        <Radio value={1}>配信済</Radio>
                                        <Radio value={2}>対象外</Radio>
                                        <Radio value={3}>配信しない</Radio>
                                    </RadioGroup>
                                )}
                            </FormItem>
                            */}

                            <div className="submit-container">

                                <Button className="search-form-button" onClick={this.handleResetSearchForm}>
                                    リセット
                                </Button>

                                <Button type="primary" htmlType="submit" className="search-form-button">
                                    検索
                                </Button>

                            </div>
                        </Form>
                    </div>

                    <div className="table-header-wrapper">
                        <DownloadCSVConfirm
                            visible={true}
                            handleOk={(encoding)=> this.handleDownloadCSV(encoding)} />
                    </div>

                    <Table
                        className="general-table"
                        locale={{
                            filterTitle: 'フィルタ',
                            filterConfirm: '確定',
                            filterReset: 'リセット',
                            emptyText: '該当するものはありません',
                        }}
                        bordered={true}
                        size="middle"
                        loading={this.state.searchLoading}
                        onChange={this.handleTableChange}
                        columns={columns} dataSource={this.state.dataArray}
                        pagination={this.state.pagination}
                    />

                    <UserViewModal
                        visible={this.state.userModalIsOpen}
                        handleCancel={() => {
                            this.setState({
                                userModalIsOpen: false
                            })
                        }}
                        handleOk={() => {
                            this.setState({
                                userModalIsOpen: false
                            })
                        }}
                        uuid={this.state.userUUID}
                    />
                </div>

            </Modal>
        );
    }
}


export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Form.create()(NotificationStatusModal))

NotificationStatusModal.propTypes = {
    visible: PropTypes.bool.isRequired,
    handleOk: PropTypes.func.isRequired,
    handleCancel: PropTypes.func.isRequired,
    data: PropTypes.object
};