import React from 'react'
import PropTypes from 'prop-types';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import {Modal, Radio, Input, Button, InputNumber} from 'antd';

import log from 'components/utils/Logger.js'


const FormItem = Form.Item;
const { TextArea } = Input;

class NotificationCategoryFormModal extends React.Component {
    constructor(props) {
        super(props);

        const modalTitle = props.isEditMode? "カテゴリの編集":"カテゴリの新規作成";
        const category_title = props.data? props.data.name:"";
        const category_description = props.data? props.data.description:"";

        this.state = {
            modalTitle: modalTitle,
            visible: props.visible,
            data: props.data,
            title: category_title,
            description:category_description
        }
    }

    componentWillReceiveProps(nextProps) {

        const modalTitle = nextProps.isEditMode? "カテゴリの編集":"カテゴリの新規作成";
        const category_title = nextProps.data? nextProps.data.name:"";
        const category_description = nextProps.data? nextProps.data.description:"";
        const order = nextProps.data && nextProps.data.order ? nextProps.data.order: 0;
        this.setState({
            modalTitle: modalTitle,
            visible: nextProps.visible,
            data: nextProps.data,
            title: category_title,
            description:category_description,
            confirmLoading: false,
            order
        });


        if (!nextProps.visible) {
            this.resetFormAll()
        }
    }



    resetFormAll = () => {
        this.props.form.resetFields();
        this.setState({
            content: "",
            confirmLoading: false
        })
    };


    handleOk = (fieldValue,data) => {
        //バリデーション
        this.props.form.validateFields((err, values) => {
            if (!err) {
                this.setState({
                    confirmLoading: true,
                });
                fieldValue.is_for_guest = data && data.is_for_guest ? data.is_for_guest : false;
                this.props.handleOk(fieldValue,data);
            }
        });
    };

    handleCancel = () => {
        this.props.handleCancel();
    };

    render() {

        const {visible, confirmLoading} = this.state;

        const formItemLayout = {
            labelCol: {
                xs: {span: 24},
                sm: {span: 4},
            },
            wrapperCol: {
                xs: {span: 24},
                sm: {span: 20},
            },
        };


        const {getFieldDecorator} = this.props.form;


        return (
            <Modal
                className="common-modal select-target-form"
                title={this.state.modalTitle}
                visible={visible}
                maskClosable={false}
                onOk={() => this.handleOk(this.props.form.getFieldsValue(),this.state.data)}
                confirmLoading={confirmLoading}
                onCancel={this.handleCancel}>

                <div className='wrapper'>
                    <Form className="search-form" onSubmit={this.onSubmit}>
                        <FormItem
                            {...formItemLayout}
                            label="カテゴリ名称"
                            layout="inline">
                            {getFieldDecorator('name' ,{
                                initialValue: this.state.title,
                                rules: [{
                                    required: true, message: 'カテゴリ名称を入力してください',
                                }],
                            })(
                                <Input placeholder="カテゴリ名称を入力してください"/>
                            )}
                        </FormItem>

                        <FormItem
                            {...formItemLayout}
                            label="概要"
                            layout="inline">
                            {getFieldDecorator('description' ,{
                                initialValue: this.state.description
                            })(
                                <TextArea placeholder="概要を入力してください" autosize={{ minRows:5,maxRows: 12, }}　  />
                            )}
                        </FormItem>

                        <FormItem
                            {...formItemLayout}
                            label="並び順">
                            {getFieldDecorator('order', {
                                initialValue: this.state.order,
                                rules: [{required: true, message: '並び順の入力が必須です'}],
                            })(
                                <InputNumber
                                    min={1}
                                    placeholder="半角数字"/>
                            )}
                        </FormItem>
                    </Form>
                </div>

            </Modal>
        );
    }
}


export default Form.create()(NotificationCategoryFormModal)

NotificationCategoryFormModal.propTypes = {
    visible: PropTypes.bool.isRequired,
    isEditMode: PropTypes.bool.isRequired,
    handleOk: PropTypes.func.isRequired,
    handleCancel: PropTypes.func.isRequired,
    data: PropTypes.object
};