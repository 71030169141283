import React from 'react'
import {connect, store} from 'react-redux'
import {instanceOf} from 'prop-types';
import * as Actions from 'redux/actions'

import moment from 'moment';
import 'moment/locale/ja';
import ContentHeader from "../../components/ContentHeader";

import CalendarList from "./CalendarList";

moment.locale('ja');


const mapStateToProps = (state, props) => {
    return {
        screen: state.screen,
    }
};

const mapDispatchToProps = dispatch => {
    return {
    }
};

class CalendarComponent extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
        };
    }

    componentWillMount(){
    }

    componentWillReceiveProps(nextProps){
    }


    render() {


        return (
            <div className='container clearfix' id="notification">
                <ContentHeader title="学年暦管理" breadCrumbItems={[
                    {title:"学年暦管理",icon:"calendar",path:"/console/calendars/"}
                ]}/>
                <CalendarList/>
            </div>
        );
    }
}

CalendarComponent.propTypes = {

};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(CalendarComponent)
