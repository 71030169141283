import React, {Component} from 'react'
import * as GlobalConfig from '../constants/GlobalConfig';
import LicenseModal from '../containers/license/LicenseModal'

/**
 * フッター
 */
class Footer extends Component {

    constructor(props) {
        super(props);
        this.state = {
            visible: false
        }
    }

    openLicenseModal = () => {
        this.setState({
            visible: true
        })
    };

    closeLicenseModal = () => {
        this.setState({
            visible:false
        })

    };

    render() {

        return (
            <footer>
                <div className="footer-logo">
                    <a href={GlobalConfig.FooterLogoLink}
                       target="_blank"
                       rel="noreferrer noopener">
                        <img className="footer-logo"
                             src={GlobalConfig.FooterLogo}
                             srcSet={GlobalConfig.FooterLogoSvg + ' ,' +GlobalConfig.FooterLogo + ' 1x,' + GlobalConfig.FooterLogo2x + ' 2x'}
                             alt={GlobalConfig.OwnerName}/>
                    </a>
                </div>
                <div className="footer-main">
                    {GlobalConfig.OwnerInfoComponent}
                </div>
                <div className="footer-copyright">
                    {GlobalConfig.CopyrightComponent} | <span style={{cursor:"pointer"}} onClick={this.openLicenseModal}>ライセンス情報</span>
                </div>

                {this.state.visible ?
                 <LicenseModal handleCancel={this.closeLicenseModal}/>
                :
                    null
                }

            </footer>
        )
    }

}

Footer.propTypes = {};

Footer.defaultProps = {};

export default Footer;